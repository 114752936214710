import {
  compose,
  onlyUpdateForKeys,
  pure,
  withPropsOnChange,
} from 'react-recompose';
import {
  BaseCartesianChart,
  ShouldEnableReportLinkHOC,
} from './base-cartesian-chart';
import { connect } from 'react-redux';
import AreaPlot from './plots/AreaPlot';
import LinePlot from './Line/LinePlot';
import BarLineChartUtils from './BarLineChartUtils';
import NoDataIfHOC from './NoDataIfHOC';

class AreaLineChart extends BaseCartesianChart {
  constructor(props) {
    super(props, [
      { shelf: 'LINES', Plot: LinePlot, dataId: 'lineData' },
      { shelf: 'VALUES', Plot: AreaPlot, dataId: 'areaData' },
    ]);
  }
}

export default compose(
  pure,
  ShouldEnableReportLinkHOC,
  connect(
    BaseCartesianChart.mapStateToProps,
    BaseCartesianChart.mapDispatchToProps,
  ),
  withPropsOnChange(['queryResults', 'i18nPrefs'], props => {
    const areaData = BarLineChartUtils.transformResultToArea(
      props.queryResults,
      props.viz,
      props.i18nPrefs,
    );
    const lineData = BarLineChartUtils.transformResultToLine(
      props.queryResults,
      props.viz,
    );
    return { areaData, lineData, secondaryUsesPrimaryScale: true };
  }),
  onlyUpdateForKeys([
    'areaData',
    'lineData',
    'height',
    'width',
    'id',
    'enableReportLink',
  ]),
  NoDataIfHOC(props => props.areaData.LINES.length === 0),
)(AreaLineChart);
