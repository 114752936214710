import { css } from '@emotion/react';
import { MouseEvent, FunctionComponent } from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';
import { SugarIcon } from '../../../icons';
import { useDiscoverTheme } from '../../../common/emotion';

export interface ILinkButtonProps {
  href: string;
  target?: string;
  onClick?: (event: MouseEvent<Element, MouseEvent>) => void;
}

const AnchorWithStyles: any = styled.a`
  display: block;
  color: unset;
  ${(props: { isDashletMode?: boolean }) =>
    props.isDashletMode
      ? `
    position: relative;
    top: 1px;
  `
      : ''}
`;

export const LinkButton: FunctionComponent<ILinkButtonProps> = ({
  href,
  target,
  onClick: _onClick,
}: ILinkButtonProps) => {
  const { isMobile = false, isDashletMode = false } = useDiscoverTheme();
  return (
    <div className='mobile-icon-button'>
      <AnchorWithStyles
        href={href}
        target={target}
        onClick={event => {
          if (_.isFunction(_onClick)) {
            _onClick(event);
          }
        }}
        isDashletMode={isDashletMode}
      >
        <SugarIcon
          icon={'launch-lg'}
          css={css`
            ${isMobile
              ? `
            padding-top: 1px;
            padding-bottom: 1px;
          `
              : ''}
          `}
          hover
        />
      </AnchorWithStyles>
    </div>
  );
};
