import { Component } from 'react';

/**
  Simple static visualization.
  @prop {String} type one of: LINE_AREA, LINE_AREA_SELECTED, LINE_TREND, DUMMY
  @prop {String} any  All of props are passed to the visualization img tag
                      width, height, etc.
**/
class Visualization extends Component {
  constructor() {
    super();
    this.charts = {
      LINE_AREA: '/assets/images/sdd/viz-line-area.svg',
      LINE_AREA_SELECTED: '/assets/images/sdd/viz-line-area-selection.svg',
      LINE_TREND: '/assets/images/sdd/viz-line-trend.svg',
      COL_CATEGORY: '/assets/images/sdd/viz-col-category.svg',
      DASHBOARD: '/assets/images/sdd/viz-dashboard.svg',
      DUMMY: '/assets/images/sdd/viz-dummy-chart.svg',
    };
  }
  render() {
    const attrs = {
      ...this.props,
    };
    attrs.src = this.charts[this.props.type] || this.charts.DUMMY;
    return <img {...attrs} />;
  }
}
export default Visualization;
