import _ from 'lodash';
import { useCallback } from 'react';
import Axis, { LABEL_ORIENTATION } from '../../../common/d3/Axis';
import { AxesLabel } from './axes-label.component';
import { useOffscreenWidth } from './base-cartesian-chart.hook';
import { LabelToolTip } from './base-cartesian-chart.styles';

export const HorizontalAxis = ({
  onScroll,
  chartWidth,
  chartHeight,
  xLabelXLoc,
  hideAxisTickLabels,
  showAxesToolTipBottom,
  hideAxesToolTipBottom,
  onMouseOver,
  xAxisLabel,
  querySort,
  showAxisToolTipBottom,
  scale,
  ticks,
  height,
  orient,
  scrolling,
  textOrientation,
  mutedLine,
  wrapTextWidth,
  transform,
  tickSizeInner,
  chartPadding,
  isMobile,
  queryId,
  tickFormat,
  getOffscreenDimensionsAsync,
  showAxisBaseline,
  scrollPctLeft,
}) => {
  const offscreenWidth = useOffscreenWidth({ getOffscreenDimensionsAsync });
  let scrollLeft = Math.min(
    0,
    Math.max(-(offscreenWidth * scrollPctLeft), -offscreenWidth),
  );
  scrollLeft = _.isFinite(scrollLeft) ? scrollLeft : 0;
  const scrollCb = useCallback(
    scrollPctLeft => {
      onScroll(scrollPctLeft, 0);
    },
    [onScroll],
  );
  const adjustedYLabelLoc = isMobile
    ? textOrientation === LABEL_ORIENTATION.ANGLED
      ? xLabelXLoc + 5
      : xLabelXLoc - 5
    : xLabelXLoc;
  return (
    <Axis
      queryId={queryId}
      mutedLine={mutedLine}
      scrolling={scrolling}
      ticks={ticks}
      height={height}
      querySort={querySort}
      orient={orient}
      textOrientation={textOrientation}
      wrapTextWidth={wrapTextWidth}
      transform={transform}
      tickSizeInner={tickSizeInner}
      chartPadding={chartPadding}
      tickFormat={tickFormat}
      showAxisBaseline={showAxisBaseline}
      scrollable='true'
      onScroll={scrollCb}
      chartWidth={chartWidth}
      chartHeight={chartHeight}
      scrollPct={scrollPctLeft}
      scrollLeft={scrollLeft}
      hideAxisTickLabels={hideAxisTickLabels}
      onMouseOver={onMouseOver}
      scale={scale}
      isMobile={isMobile}
    >
      <AxesLabel
        onMouseEnter={showAxesToolTipBottom}
        onMouseLeave={hideAxesToolTipBottom}
        width={chartWidth}
        rotate={0}
        x={0}
        y={adjustedYLabelLoc}
        label={xAxisLabel}
        isMobile={isMobile}
      />
      <LabelToolTip
        show={showAxisToolTipBottom}
        height={44}
        width={chartWidth}
        arrow='bottom'
        xPos={0}
        y={0}
      >
        {xAxisLabel}
      </LabelToolTip>
    </Axis>
  );
};
