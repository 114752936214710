import { i18nUtils, messages } from '../../../i18n';
import { IAggregateConditionProps } from './aggregate-condition.interface';
import { DropdownGroup } from './aggregate-condition.styles';
import { FieldsDropDown } from '../FieldsDropDown';
import { AggregationDropdown } from '../../../components/aggregation-dropdown';
import { getAttribType } from '../utils';
import { makeDropdownOption } from '../../../ui/dropdowns/searchable-dropdown/searchable-dropdown.utils';
import { useAggregateCondition } from './aggregate-condition.hook';

export const AggregateCondition = ({ vizId }: IAggregateConditionProps) => {
  const {
    viz,
    onAggFieldChange,
    selectedAggregation,
    selectedField,
    onAggregationChange,
    field,
  } = useAggregateCondition({ vizId });

  return (
    <DropdownGroup>
      <FieldsDropDown
        viz={viz}
        field={{ ...field, name: '' }}
        selected={selectedField}
        onSelect={newSelectedField => {
          onAggFieldChange(newSelectedField);
        }}
        dropDownPrefix={'set-condition'}
      />
      <AggregationDropdown
        dataType={getAttribType(field)}
        defaultValue={makeDropdownOption(
          selectedAggregation ?? selectedField.defaultAggregation,
          i18nUtils.getAggregationDisplayText(
            selectedAggregation ?? selectedField.defaultAggregation,
          ),
        )}
        onSelect={agg => {
          onAggregationChange(agg);
        }}
        popperSx={{ zIndex: 1050 }}
      />
      <span>{messages.filters.valueIs}</span>
    </DropdownGroup>
  );
};
