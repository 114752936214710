import Const from '../actions/ActionConst';
import { composeResetReducer } from 'redux-reset-store';

const initialState = {
  newPasswordRequired: false,
  emailLogin: null,
  isLoggedIn: false,
};

export default composeResetReducer((state = initialState, action) => {
  switch (action.type) {
    // Login Reducers
    // note that LOGOUT is handled by redux-reset
    case Const.Login.SHOW_LOGIN_DIALOG:
      return {
        ...state,
      };

    case Const.Login.LOGOUT: {
      const { idmLogoutUrl, returnToUrl } = action;
      return { ...state, idmLogoutUrl, returnToUrl };
    }

    case Const.Login.LOGIN:
      return {
        ...state,
        emailLogin: state.emailLogin,
        isLoggedIn: true,
      };

    case Const.Login.RESET_LOGIN_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}, initialState);
