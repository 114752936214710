import React from 'react';
import TimeUtils from '../../../common/TimeUtils';
import AreaLineChart from '../../../discovery/charts/AreaLineChart';
import QueryResultUtils from '../../../discovery/charts/QueryResultUtils';
import { ChartSpecs } from '../../../discovery/ChartSpecs';
import * as shortId from 'shortid';
import { AutoSizer } from 'react-virtualized';
import { messages } from '../../../i18n';
import { useDiscoverThemeColors } from '../../../common/emotion';

const GamPercentChangeCard = ({
  up,
  pct,
  payload,
  condensed,
  title,
  targetName,
  inverted,
  date,
  valueFormatter,
  increment,
}) => {
  const { AnomolyPrimaryPlotLine, ValAreaFill } = useDiscoverThemeColors();
  let periodVal = '';
  switch (increment) {
    case 'DAY':
      periodVal = messages.days;
      break;
    case 'WEEK':
      periodVal = messages.weeks;
      break;
    case 'MONTH':
      periodVal = messages.months;
      break;
  }

  const periodShort = `15 ${periodVal}`;

  const data = QueryResultUtils.createResultsFromArray(
    payload.chartData,
    payload.columnInfo,
  );

  const viz = {
    id: shortId.generate(),
    layout: {
      LINES: [
        {
          name: 'M0',
          attributeType: 'NUMBER',
          defaultAggregation: 'Sum',
          hidden: false,
        },
      ],
      VALUES: [
        {
          name: 'M2',
          attributeType: 'NUMBER',
          defaultAggregation: 'Sum',
          hidden: false,
        },
        {
          name: 'M3',
          attributeType: 'NUMBER',
          defaultAggregation: 'Sum',
          hidden: false,
        },
      ],
      XAXIS: [
        {
          name: 'Axis',
          attributeType: 'NUMBER',
          defaultAggregation: 'Sum',
          hidden: false,
        },
      ],
    },
    options: {
      alignYAxesAtZero: 'true',
    },
  };

  return (
    <div className={`percent-changed-card${inverted ? ' inverted' : ''}`}>
      <span className={'title'}>
        <span title={title}>{title}</span>
      </span>
      {condensed && (
        <div className='card-date'>{TimeUtils.formatDate(date)}</div>
      )}
      <div className='card-body'>
        <div className='weighted-forecast-body-table'>
          <div className='labels'>
            <span>{messages.insightFeed.current}</span>
            <span>{targetName}</span>
            {condensed && <span>{messages.insightFeed.change}</span>}
          </div>
          <div className='values'>
            <span>{valueFormatter(payload.actual)}</span>
            <span>{valueFormatter(payload.expected)}</span>
            {condensed && (
              <span className={up ? 'pct-up' : 'pct-down'}>{pct}</span>
            )}
          </div>
        </div>
        <div className='area-chart'>
          <div className='vbox flex-fill preserve-ordering'>
            <div className='flex-fill'>
              <AutoSizer>
                {({ width, height }) => {
                  return (
                    <AreaLineChart
                      spec={ChartSpecs.area}
                      queryResults={data}
                      height={height}
                      width={width}
                      hideAxis
                      hideGrid
                      primaryPlotProps={{
                        hidePoints: true,
                        disableLineSmoothing: true,
                        className: 'mixed-area-line',
                        colorPalette: [AnomolyPrimaryPlotLine],
                        xAxisPadding: 0,
                      }}
                      secondaryPlotProps={{
                        className: 'mixed-area',
                        colorPalette: [ValAreaFill],
                        xAxisPadding: 0,
                      }}
                      secondaryUsesPrimaryScale
                      collapsedAxisRange // don't start axis at zero
                      fitAllData // ensure chart shows all data
                      alignYAxesAtZero='true'
                      shouldRotateAxisLabels={false}
                      viz={viz}
                      vizId={viz.id}
                      mainChartPadding={0}
                      xAxisPadding={0}
                      yAxisPadding={0}
                      disableScrolling
                      disableLegend
                      disableTooltips
                    />
                  );
                }}
              </AutoSizer>
            </div>
            <div className='axis'>
              <span>
                {messages.formatString(
                  messages.insightFeed.lastTimePeriodShort,
                  periodShort,
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

GamPercentChangeCard.defaultProps = {
  chartHeight: 90,
  chartWidth: 104,
  chartPadding: 4,
  includeArrowInTitle: false,
};

export default GamPercentChangeCard;
