import { FunctionComponent } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Tooltip } from '../../../components/ui/tooltip';
import { getLegendItemStyle } from '../viz-legend/utils';
import { EMPTY_STRING_DISPLAY, EMPTY_STRING_TOKEN } from '../../../common';
import { LegendShape } from '../legend-shape';

interface IProps {
  color: string;
  label: string;
  shape: string;
  opacity?: any;
  focused: boolean;
  onClick: any;
  isForcedMobile: boolean;
  isDashletMode: boolean;
}

export const ChartLegendItem: FunctionComponent<IProps> = ({
  color,
  label,
  shape,
  opacity,
  focused,
  onClick,
  isForcedMobile,
  isDashletMode,
}) => {
  const tooltipText =
    label === EMPTY_STRING_TOKEN ? EMPTY_STRING_DISPLAY : label;
  const placement = isDashletMode ? 'bottom' : 'top';
  return (
    <Tooltip
      id='legend-tooltip'
      key={`legend-item-${label}`}
      placement={placement}
      title={tooltipText}
      enterDelay={1000}
      arrow
    >
      <div
        id={`legend-item-${label}`}
        style={getLegendItemStyle({ isForcedMobile })}
        className={classnames('legend-item', { dim: !focused })}
        onClick={onClick}
      >
        <div className='legend-shape'>
          <LegendShape
            shape={shape}
            color={color}
            style={{ fillOpacity: _.isNil(opacity) ? 1 : opacity }}
          />
        </div>
        <div className='legend-label'>
          {label === EMPTY_STRING_TOKEN ? EMPTY_STRING_DISPLAY : label}
        </div>
      </div>
    </Tooltip>
  );
};
