import { messages } from '../../i18n';
import { clearLegendWrapper } from './legend-wrapper.hoc';
import { useOpenVizChartSpec } from '../../common/redux/selectors/viz-selector.hook';
import { css } from '@emotion/react';

export const ChartPlaceHolder = ({ children, vizId, hasError = false }) => {
  const chartSpec = useOpenVizChartSpec({ discoveryId: vizId });
  const { placeholderImage = '' } = chartSpec;

  return (
    <div className={`viz-placeholder${hasError ? ' error' : ''}`}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <img
          css={css`
            max-height: 150px;
          `}
          className={'chart-placeholder-img'}
          src={placeholderImage}
        />
        {children}
      </div>
    </div>
  );
};
export const EmptyFieldPlaceholder = ({ vizId }) => {
  const chartSpec = useOpenVizChartSpec({ discoveryId: vizId });
  const { requiredFields } = chartSpec;
  return (
    <ChartPlaceHolder vizId={vizId}>
      <p>
        {messages.baseChart.emptyFieldTextStart}
        <strong>{messages.baseChart.fieldList}</strong>
        {messages.baseChart.emptyFieldTextEnd}
        <br />
        {requiredFields && messages.baseChart.emptyFieldHintText}
      </p>
    </ChartPlaceHolder>
  );
};

export const LegendAwareChartPlaceholder: any = clearLegendWrapper(
  EmptyFieldPlaceholder,
);

export const ChartPlaceholderWithMessage: any = clearLegendWrapper(
  ChartPlaceHolder,
);
