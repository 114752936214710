import * as shortid from 'shortid';
import ActivityWidget from '../../../tenants/corvana/activity/ActivityWidget';
import { connect } from 'react-redux';
import { noop } from 'lodash';
import { isDashletUser as isDashletUserSelector } from '../../../common/redux/selectors/AccountSelectors';

const UnconnectedBaseInsightComponent = ({
  title,
  children,
  excluded,
  isDashletUser,
  onClick = noop,
}) => {
  const click = e => {
    !e.metaKey && !isDashletUser && onClick();
  };
  return (
    <ActivityWidget>
      <div key={shortid.generate()} onClick={click}>
        <div
          className={`feed-row insight-item${excluded ? ' excluded' : ''}`}
          title='View Details'
        >
          <div className='feed-row-left'>
            <div className='feed-row-body' />
            <div className={'top-3-insight inverted'}>
              <span className='title'>
                <span title={title}>{title}</span>
              </span>
              <div className='dynamic-insight-table-wapper'>
                <div className='dynamic-insight-table'>{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ActivityWidget>
  );
};

const BaseInsightComponent = connect(state => ({
  forceMobile: state.main.shouldForceMobile,
  isDashletUser: isDashletUserSelector(state.account),
}))(UnconnectedBaseInsightComponent);
export default BaseInsightComponent;
