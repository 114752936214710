import {
  URLs,
  setDashletModeOverride as _setDashletModeOverride,
} from '../common/Urls';
import _ from 'lodash';
import {
  DashletRouteDefinitions,
  DashletMode,
  IDashletRoute,
  DASHLET_MODE,
} from './dashlet.const';

export const setDashletModeOverride = (dashletMode: DashletMode) => {
  _setDashletModeOverride(dashletMode);
};

export const getDashletMode: () => DashletMode = () => {
  const _dashletMode = URLs.getDashletMode();
  if (_dashletMode) {
    return _dashletMode;
  }
  const { entryPoint } = URLs.getQueryParams();
  return _.findKey(
    DashletRouteDefinitions,
    _.overSome([
      _.matches({ entryPoint }),
      ({ route }: IDashletRoute) =>
        new RegExp(`^${route}`, 'i').test(location.pathname),
    ]),
  ) as any;
};

export const isInDashletReportMode = () =>
  getDashletMode() === DASHLET_MODE.REPORT;

export const isDashletMode: () => boolean = () => !!getDashletMode();
