import {
  Form,
  FormGroup,
  Checkbox,
} from '@sugar-discover/react-bootstrap-wrapper';
import { messages } from '../../../i18n';
import { DisableableSpan } from '../../../common/emotion';

export const ComboChartSettings = ({
  chartSpec,
  alignYAxesAtZero,
  setAlignYAxesAtZero,
}) => {
  if (chartSpec.id === 'column_line') {
    return (
      <div>
        <DisableableSpan className='viz-settings-header'>
          {messages.formatting.axis}
        </DisableableSpan>
        <Form>
          <FormGroup className='viz-settings-form-group'>
            <Checkbox
              checked={alignYAxesAtZero}
              onChange={(event: any) =>
                setAlignYAxesAtZero(event.target.checked)
              }
            >
              <span>{messages.formatting.lock0lineForXandYaxis}</span>
            </Checkbox>
          </FormGroup>
        </Form>
      </div>
    );
  } else {
    return null;
  }
};
