import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isNil } from 'lodash';
import moment from '../../../common/Moment';
import {
  DaysOfWeek,
  RelativeOperators,
  TimePeriods,
  createFilter,
  RelativeDateAnchorModes,
} from './relative-date-condition.utils';
import { useOpenDiscoveryPresentStateSelector } from '../../../common/redux/selectors/viz-selector.hook';
import { VIZ_SELECTORS } from '../../../common/redux/selectors/viz-selectors';
import { ACCOUNT_SELECTORS } from '../../../common/redux/selectors/AccountSelectors';
import Discover from '../../../common/redux/actions/DiscoverActions';
import { Condition, NOW } from '../Filter';

export const useRelativeDateCondition = (
  { vizId, filter, field },
  skipUpdate = false,
) => {
  if (isNil(filter)) {
    filter = createFilter(field);
  }

  const discovery = useOpenDiscoveryPresentStateSelector({
    discoveryId: vizId,
  });
  const useFiscalCalendar = useSelector(
    state =>
      VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting(state, {}) &&
      VIZ_SELECTORS.getActiveVizFiscalSetting(state, {}) === 'true',
  );

  const { dateTimeFormat } = useSelector(state =>
    ACCOUNT_SELECTORS.getDatetimeFormats(state),
  );

  const periodOperandFromFilter =
    filter.expression.left.operands.length > 0
      ? filter.expression.left.operands[1]
      : 'DAYS';

  const [operator, setOperator] = useState<string>(
    filter.expression.left.operator || RelativeOperators.past.key,
  );

  const [offset, setOffset] = useState(
    filter.expression.left.operands.length > 0
      ? filter.expression.left.operands[0]
      : '1',
  );
  const [periodOperand, setPeriodOperand] = useState(periodOperandFromFilter);
  const [includeFractionalPeriod, setIncludeFractionalPeriod] = useState(
    filter.expression.left.operands.length > 0
      ? filter.expression.left.operands[2] === 'true'
      : false,
  );
  const [anchorDate, setAnchorDate] = useState(
    filter.expression.left.operands.length === 4
      ? filter.expression.left.operands[3]
      : moment()
          .startOf('day')
          .format(moment.ISO8601),
  );
  const [anchorEnabled, setAnchorEnabled] = useState<boolean>(
    filter.expression.left.operands.length === 4 ||
      filter.expression.left.operands.length === 5,
  );
  const [anchorMode, setAnchorMode] = useState<string>(
    filter.expression.left.operands.length === 5
      ? RelativeDateAnchorModes.PREVIOUS
      : RelativeDateAnchorModes.EXACT,
  );
  const [anchorOffset, setAnchorOffset] = useState<string>(
    filter.expression.left.operands.length === 5
      ? filter.expression.left.operands[3]
      : '',
  );
  const [anchorDay, setAnchorDay] = useState<string>(
    filter.expression.left.operands.length === 5
      ? filter.expression.left.operands[4]
      : DaysOfWeek[0],
  );
  const [displayFractionalPeriod, setDisplayFractionalPeriod] = useState<
    string
  >(TimePeriods[periodOperandFromFilter]?.calendarPeriod ?? false);

  const dispatch = useDispatch();
  const setActiveFieldFilter = useCallback(
    filter => {
      dispatch(Discover.setActiveFieldFilter(filter));
    },
    [dispatch],
  );

  const changeFilter = useCallback(
    newState => {
      const timePeriod = TimePeriods[newState.periodOperand];
      newState.displayIncludeCurrentPeriod = timePeriod?.calendarPeriod;

      const f = cloneDeep(filter);
      f.expression.left = new Condition(newState.operator, [
        newState.offset,
        newState.periodOperand,
      ]);

      f.expression.left.operands.push(
        newState.includeFractionalPeriod ? 'true' : 'false',
      );

      if (newState.anchorEnabled) {
        if (newState.anchorDate) {
          f.expression.left.operands.push(
            moment(newState.anchorDate).format(moment.ISO8601),
          );
        }

        if (newState.anchorMode === RelativeDateAnchorModes.PREVIOUS) {
          f.expression.left.operands[3] = newState.anchorOffset || '1';
          f.expression.left.operands.push(newState.anchorDay);
        }
      }

      if (!skipUpdate) {
        setActiveFieldFilter(f);
      }
    },
    [filter, setActiveFieldFilter, skipUpdate],
  );

  const onOperatorChange = useCallback(
    operator => {
      if (operator) {
        changeFilter({
          offset,
          periodOperand,
          includeFractionalPeriod,
          anchorDate,
          anchorEnabled,
          anchorMode,
          anchorOffset,
          anchorDay,
          displayFractionalPeriod,
          operator: RelativeOperators[operator]?.key,
        });
        setOperator(RelativeOperators[operator]?.key);
      }
    },
    [
      anchorDate,
      anchorDay,
      anchorEnabled,
      anchorMode,
      anchorOffset,
      changeFilter,
      displayFractionalPeriod,
      includeFractionalPeriod,
      offset,
      periodOperand,
    ],
  );

  const onPeriodOperatorChange = useCallback(
    periodOperand => {
      if (periodOperand) {
        const displayFractionalPeriod =
          TimePeriods[periodOperand].calendarPeriod;
        changeFilter({
          operator,
          offset,
          includeFractionalPeriod,
          anchorDate,
          anchorEnabled,
          anchorMode,
          anchorOffset,
          anchorDay,
          periodOperand,
          displayFractionalPeriod,
        });
        setPeriodOperand(periodOperand);
        setDisplayFractionalPeriod(displayFractionalPeriod);
      }
    },
    [
      anchorDate,
      anchorDay,
      anchorEnabled,
      anchorMode,
      anchorOffset,
      changeFilter,
      includeFractionalPeriod,
      offset,
      operator,
    ],
  );

  const onIncludeFractionalPeriod = useCallback(
    includeFractionalPeriod => {
      changeFilter({
        operator,
        offset,
        periodOperand,
        anchorDate,
        anchorEnabled,
        anchorMode,
        anchorOffset,
        anchorDay,
        displayFractionalPeriod,
        includeFractionalPeriod,
      });
      setIncludeFractionalPeriod(includeFractionalPeriod);
    },
    [
      anchorDate,
      anchorDay,
      anchorEnabled,
      anchorMode,
      anchorOffset,
      changeFilter,
      displayFractionalPeriod,
      offset,
      operator,
      periodOperand,
    ],
  );

  const onOffsetChange = useCallback(
    offset => {
      changeFilter({
        operator,
        periodOperand,
        includeFractionalPeriod,
        anchorDate,
        anchorEnabled,
        anchorMode,
        anchorOffset,
        anchorDay,
        displayFractionalPeriod,
        offset,
      });
      setOffset(offset);
    },
    [
      anchorDate,
      anchorDay,
      anchorEnabled,
      anchorMode,
      anchorOffset,
      changeFilter,
      displayFractionalPeriod,
      includeFractionalPeriod,
      operator,
      periodOperand,
    ],
  );

  const onEnableAnchor = useCallback(
    event => {
      const anchorEnabled = event.target.checked;
      changeFilter({
        operator,
        offset,
        periodOperand,
        includeFractionalPeriod,
        anchorDate,
        anchorMode,
        anchorOffset,
        anchorDay,
        displayFractionalPeriod,
        anchorEnabled,
      });
      setAnchorEnabled(anchorEnabled);
    },
    [
      anchorDate,
      anchorDay,
      anchorMode,
      anchorOffset,
      changeFilter,
      displayFractionalPeriod,
      includeFractionalPeriod,
      offset,
      operator,
      periodOperand,
    ],
  );

  const onAnchorChanged = useCallback(
    event => {
      if (!anchorEnabled) {
        return;
      }
      let date =
        event === NOW
          ? NOW
          : event.currentTarget
          ? event.currentTarget.value
          : event.format(moment.ISO8601);
      if (date === 'now') {
        date = NOW;
      }
      changeFilter({
        operator,
        offset,
        periodOperand,
        includeFractionalPeriod,
        anchorEnabled,
        anchorMode,
        anchorOffset,
        anchorDay,
        displayFractionalPeriod,
        anchorDate: date,
      });
      setAnchorDate(date);
    },
    [
      anchorDay,
      anchorEnabled,
      anchorMode,
      anchorOffset,
      changeFilter,
      displayFractionalPeriod,
      includeFractionalPeriod,
      offset,
      operator,
      periodOperand,
    ],
  );

  const onAnchorModeChange = useCallback(
    anchorMode => {
      changeFilter({
        operator,
        offset,
        periodOperand,
        includeFractionalPeriod,
        anchorDate,
        anchorEnabled,
        anchorOffset,
        anchorDay,
        displayFractionalPeriod,
        anchorMode,
      });
      setAnchorMode(anchorMode);
    },
    [
      anchorDate,
      anchorDay,
      anchorEnabled,
      anchorOffset,
      changeFilter,
      displayFractionalPeriod,
      includeFractionalPeriod,
      offset,
      operator,
      periodOperand,
    ],
  );

  const onAnchorOffsetChange = useCallback(
    val => {
      const anchorOffset = val < 1 ? '' : val;
      changeFilter({
        operator,
        offset,
        periodOperand,
        includeFractionalPeriod,
        anchorDate,
        anchorEnabled,
        anchorMode,
        anchorDay,
        displayFractionalPeriod,

        anchorOffset,
      });
      setAnchorOffset(anchorOffset);
    },
    [
      anchorDate,
      anchorDay,
      anchorEnabled,
      anchorMode,
      changeFilter,
      displayFractionalPeriod,
      includeFractionalPeriod,
      offset,
      operator,
      periodOperand,
    ],
  );

  const onAnchorDayChange = useCallback(
    anchorDay => {
      changeFilter({
        operator,
        offset,
        periodOperand,
        includeFractionalPeriod,
        anchorDate,
        anchorEnabled,
        anchorMode,
        anchorOffset,
        displayFractionalPeriod,
        anchorDay,
      });
      setAnchorDay(anchorDay);
    },
    [
      anchorDate,
      anchorEnabled,
      anchorMode,
      anchorOffset,
      changeFilter,
      displayFractionalPeriod,
      includeFractionalPeriod,
      offset,
      operator,
      periodOperand,
    ],
  );

  return {
    dateTimeFormat,
    datasetId: discovery.dataset.id,
    useFiscalCalendar,
    relativeDatesState: {
      operator,
      offset,
      periodOperand,
      includeFractionalPeriod,
      anchorDate,
      anchorEnabled,
      anchorMode,
      anchorOffset,
      anchorDay,
      displayFractionalPeriod,
    },
    onOperatorChange,
    onPeriodOperatorChange,
    onIncludeFractionalPeriod,
    onOffsetChange,
    onEnableAnchor,
    onAnchorChanged,
    onAnchorModeChange,
    onAnchorOffsetChange,
    onAnchorDayChange,
  };
};
