import { Component } from 'react';
import * as d3 from 'd3';
import { event as currentEvent } from 'd3-selection';
import palette from './ColorPalette';
import _ from 'lodash';
import shortid from 'shortid';
import Util from '../Util';
import { compose, onlyUpdateForKeys, pure } from 'react-recompose';
import Discover from '../redux/actions/DiscoverActions';
import { connect } from 'react-redux';

import { Viz } from '../../discovery/VizUtil';
import ColorManager from './ColorManager';
import {
  SortFunctionManager,
  VIZ_SELECTORS,
} from '../redux/selectors/viz-selectors';
import {
  NULL_DISPLAY,
  TRENDLINE_TOGGLE_SELECTOR,
  TRENDLINE_STRATEGY,
  TRENDLINE_OPTION_SELECTOR,
} from '../../common/Constants';
import classnames from 'classnames';
import { withTheme } from '@emotion/react';
import { withDiscoverRouter } from '../utilities/router.hoc';
import { LabelManagerConsumerHOC } from './label-manager-provider';
import { ScrollContext } from '../../discovery/charts/base-cartesian-chart'; // adds registerLabel prop
import { getLinearRegression, getTrendlineIsVisible } from './utils/trendline';
import { withDiscoverOption } from '../../discovery/discovery-context/discovery.context';

export const BarUtils = {
  getBarPadding(numberOfBars = 1, customPadProps) {
    const config = [
      {
        range: [1, 1],
        barPadding: 0.4,
        barPaddingOuter: 0.4,
        groupPadding: 0,
        groupPaddingOuter: 0,
        expandRange: -1,
      },
      {
        range: [2, 5],
        barPadding: 0.25,
        barPaddingOuter: 0.25,
        groupPadding: 0.4,
        groupPaddingOuter: 0.4,
        expandRange: -1,
      },
      {
        range: [6, 10],
        barPadding: 0.15,
        barPaddingOuter: 0.15,
        groupPadding: 0.3,
        groupPaddingOuter: 0.3,
        expandRange: -1,
      },
      {
        range: [11, 20],
        barPadding: 0.15,
        barPaddingOuter: 0.15,
        groupPadding: 0.3,
        groupPaddingOuter: 0.3,
        expandRange: -1,
      },
      {
        range: [21, 30],
        barPadding: 0.15,
        barPaddingOuter: 0.15,
        groupPadding: 0.3,
        groupPaddingOuter: 0.3,
        expandRange: -1,
      },
      {
        range: [31, 40],
        barPadding: 0.15,
        barPaddingOuter: 0.15,
        groupPadding: 0.3,
        groupPaddingOuter: 0.3,
        expandRange: -1,
      },
      {
        range: [41, 50],
        barPadding: 0.15,
        barPaddingOuter: 0.15,
        groupPadding: 0.3,
        groupPaddingOuter: 0.3,
        expandRange: -1,
      },
      {
        range: [51, 60],
        barPadding: 0.15,
        barPaddingOuter: 0.15,
        groupPadding: 0.3,
        groupPaddingOuter: 0.3,
        expandRange: -1,
      },
      {
        range: [61, 100],
        barPadding: 0.15,
        barPaddingOuter: 0.15,
        groupPadding: 0.3,
        groupPaddingOuter: 0.3,
        expandRange: -1,
      },
    ];

    const getConfig = barCount => {
      return config.find(c =>
        _.inRange(barCount, c.range[0] - 1, c.range[1] + 1),
      );
    };

    // we need to determine the padding as it relates to the widths of the bars, not the viewable area
    let padding = getConfig(numberOfBars);
    if (_.isUndefined(padding)) {
      padding = {
        range: [0, 0],
        barPadding: 0.15,
        barPaddingOuter: 0.15,
        groupPadding: 0.2,
        groupPaddingOuter: 0.2,
        expandRange: -1,
      };
    }
    return { ...padding, ...customPadProps };
  },

  getSortedList(data, querySort) {
    const sorts = _.values(querySort);
    const columnSorts = _.filter(sorts, { shelfName: 'Columns' });
    const sortManager = new SortFunctionManager();
    columnSorts.map(sort => {
      const fun = item => {
        const field = item[sort.shelfName.toUpperCase()][sort.fieldName];
        const val = field.sort || field.value;
        if (val === NULL_DISPLAY) {
          return Number.NEGATIVE_INFINITY;
        }
        return val;
      };
      sortManager.addFunc(fun, sort.direction, sort.priority);
    });

    const sortedData = sortManager.order(data);
    return sortedData;
  },

  getDefaultScales(
    data,
    chartWidth,
    chartHeight,
    getY,
    getX,
    getX0,
    customPadProps,
    querySort,
  ) {
    const distinctGroups = BarUtils.getDistinctGroups(data, getX0);
    const padProps = BarUtils.getBarPadding(data.length, customPadProps);
    const {
      barPadding,
      barPaddingOuter,
      groupPadding,
      groupPaddingOuter,
      expandRange,
    } = padProps;

    const groupScaleDefault = d3
      .scaleBand()
      .domain(distinctGroups)
      .range([0, chartWidth]);

    if (distinctGroups.length > 1) {
      groupScaleDefault
        .paddingInner(groupPadding)
        .paddingOuter(groupPaddingOuter);
    } else {
      groupScaleDefault.paddingInner(barPadding).paddingOuter(barPaddingOuter);
    }

    const sortedData = this.getSortedList(data, querySort);

    const xScaleDefault = d3
      .scaleBand()
      .domain(sortedData.map(d => getX(d)))
      .range([
        0,
        distinctGroups.length < 1 ? chartWidth : groupScaleDefault.bandwidth(),
      ]);

    if (distinctGroups.length > 1) {
      xScaleDefault.paddingInner(barPadding).paddingOuter(0);
    } else {
      xScaleDefault.paddingInner(barPadding).paddingOuter(barPaddingOuter);
    }

    const min = d3.min(data, d => getY(d));
    const max = d3.max(data, d => getY(d));
    const yScaleDomain = Util.expandRange(
      min < 0 ? [min, Math.max(max, 0)] : [Math.min(min, 0), max],
      expandRange > -1 ? expandRange : 0.05,
    );

    const yScaleDefault = d3
      .scaleLinear()
      .range([chartHeight, 0])
      .domain(yScaleDomain)
      .nice();

    return {
      groupScaleDefault,
      xScaleDefault,
      yScaleDefault,
    };
  },

  getDistinctGroups: _.memoize((data, getGroupFromDataElement) => {
    const distinctGroups = data.reduce((accum, curr) => {
      const group = getGroupFromDataElement(curr);
      // add groups to the accumulator that aren't already there
      if (group != null && !_.some(accum, group)) {
        accum.push(group);
      }
      return accum;
    }, []);
    return distinctGroups || [];
  }),
};

const BarGroup = props => {
  return (
    <g
      className='bar-group'
      transform={`translate(${props.scale(props.value)}, 0)`}
    >
      {props.children}
    </g>
  );
};

class UnconnectedBars extends Component {
  static contextType = ScrollContext;

  labelClass = 'bars';
  labelDefaultHeight = 12;

  componentDidMount() {
    this.handleBarEvents();
  }
  componentDidUpdate() {
    this.handleBarEvents();
  }

  handleBarEvents() {
    const d3Data = this.getBarDataForD3();
    const onClick = d => {
      currentEvent.stopPropagation();
      // Toggle selection
      if (this.props.enableReportLink) {
        this.props.openReportLink(d.reportDetailInfo);
      } else {
        this.props.setFocusedData(d.COLUMNS);
      }
    };
    d3.select(this.container)
      .selectAll(`rect.${this.props.barClassName}`)
      .data(d3Data)
      .on('mouseout', () => {
        if (_.isFunction(this.props.onHover)) {
          currentEvent.stopPropagation();
          const [xRelToContainer, yRelToContainer] = d3.mouse(this.container);

          const [x, y] = d3.mouse(currentEvent.target);
          const bounds = currentEvent.target.getBoundingClientRect();
          if (x > 0 && x < bounds.width && y > 0 && y < bounds.height) {
            // Were still over ignore
          } else {
            this.props.onHover(null, xRelToContainer, yRelToContainer);
          }
        }
        d3.selectAll(`rect.${this.props.barClassName}`).classed('hover', false);
      })
      .on('mousemove', d => {
        if (_.isFunction(this.props.onHover)) {
          currentEvent.stopPropagation();
          if (this.props.showGlobalTooltip) {
            return;
          }
          const [xRelToContainer, yRelToContainer] = d3.mouse(this.container);
          this.props.onHover(d, xRelToContainer + 10, yRelToContainer + 10);
        }
        // lets 'hover' all bars that represent the same data
        d3.selectAll(`rect.${this.props.barClassName}`).each(function(data) {
          if (_.isEqual(d.COLUMNS, data.COLUMNS)) {
            d3.select(this).classed('hover', true);
          }
        });
      })
      .on('click', onClick)
      // :hover messes with D3 click listeners on mobile, but it doesn't
      // interfere with touchend. This broke mobile drill linking.
      // (https://sugarcrm.atlassian.net/browse/DSC-5288)
      .on('touchend', onClick);
  }

  filterDataForGroup(group) {
    const filtered = this.props.data.filter(d => {
      const g = this.props.getX0(d);
      return g === group;
    });
    return filtered || [];
  }

  getPaddingProps(dataLength) {
    const { chartPadding } = this.props;
    let padProps = BarUtils.getBarPadding(dataLength);
    if (!_.isEmpty(chartPadding)) {
      padProps = chartPadding;
    }

    return padProps;
  }

  getScales() {
    const {
      data,
      getX,
      getY,
      getX0,
      width,
      height,
      groupScale,
      xScale,
      yScale,
    } = this.props;

    const padProps = this.getPaddingProps(data?.length);

    const {
      groupScaleDefault,
      xScaleDefault,
      yScaleDefault,
    } = BarUtils.getDefaultScales(
      data,
      width,
      height,
      getY,
      getX,
      getX0,
      padProps,
      this.props.querySort,
    );

    return {
      group: _.isNil(groupScale) ? groupScaleDefault : groupScale,
      x: _.isNil(xScale) ? xScaleDefault : xScale,
      y: _.isNil(yScale) ? yScaleDefault : yScale,
    };
  }

  /**
   * When we have grouped data, we need to make sure the data that d3 selections use is in the grouped order.
   * Otherwise, we will get the wrong values on hover and anything else that might rely on it
   */
  getBarDataForD3() {
    const { data, getX0 } = this.props;
    const distinctGroups = BarUtils.getDistinctGroups(data, getX0);
    if (!_.isEmpty(distinctGroups)) {
      const barGroups = distinctGroups.reduce((bars, group) => {
        const groupBars = this.filterDataForGroup(group);
        return [...bars, ...groupBars];
      }, []);
      return barGroups;
    } else {
      return data;
    }
  }

  globalMouseMove() {
    // bars have no global tooltip
  }

  getFormatter(dValue, layout) {
    const valsLength = layout?.VALUES?.length ? layout.VALUES.length : 1;
    let formatterIdx = 0;
    if (valsLength > 1) {
      const possibleFormatterIdx = _.findIndex(layout.VALUES, [
        'name',
        Object.keys(dValue.VALUES)[0],
      ]);
      if (possibleFormatterIdx > 0) {
        formatterIdx = possibleFormatterIdx;
      }
    }
    return dValue.formatters[formatterIdx];
  }

  getPlotXPos(d) {
    const { data, getX, getX0, offsetX = 0 } = this.props;
    const { leftPct = 1, offscreenWidth = 0 } = this.context ?? {};
    const widthScrollOffset = leftPct * offscreenWidth;

    const { group: groupScale, x } = this.getScales();

    const isGroup =
      _.filter(data, datum => getX0(datum) === getX0(d))?.length > 1;

    const singleBarPosition = groupScale(getX0(d));
    const groupScaleBandwidth = groupScale.bandwidth();
    const multiBarXPosition =
      groupScale(getX0(d)) +
      x(getX(d)) -
      groupScaleBandwidth / 2 +
      x.bandwidth() / 2;

    const xPosition =
      (isGroup ? multiBarXPosition : singleBarPosition) - widthScrollOffset;

    return _.isNaN(xPosition) ? 0 : xPosition + offsetX; // should include horizontal scroll offset
  }

  getPlotYPos(d) {
    const { getY } = this.props;
    const { topPct = 0, offscreenHeight = 0 } = this.context ?? {};
    const heightScrollOffset = topPct * offscreenHeight;

    const { y: yScale } = this.getScales();

    const yVal = yScale(getY(d));
    return (_.isNaN(yVal) ? 0 : yVal) - heightScrollOffset;
  }

  getCustomFormatProps(datum) {
    const { customFormatProps: customFormatterProps, layout } = this.props;
    const valsLength = layout?.VALUES?.length ?? 1;
    let formatterIdx = 0;
    if (valsLength > 1) {
      const possibleFormatterIdx = _.findIndex(layout.VALUES, [
        'name',
        Object.keys(datum.VALUES)[0],
      ]);
      if (possibleFormatterIdx > 0) {
        formatterIdx = possibleFormatterIdx;
      }
    }

    return !_.isNil(customFormatterProps)
      ? customFormatterProps[_.keys(datum.VALUES)[formatterIdx]]
      : {};
  }

  getLabelLines(registeredLabelData) {
    const { i18nPrefs } = this.props;
    const {
      value,
      formatter,
      meta: { customFormatProps },
    } = registeredLabelData;
    const { group: groupScale, x } = this.getScales();
    const rectWidth = x.bandwidth();
    const barWidth = groupScale.bandwidth();

    const textLabel = Viz.formatNumberToFit(
      value,
      rectWidth,
      formatter,
      i18nPrefs,
      customFormatProps,
    );

    if (_.isEmpty(textLabel)) {
      throw new Error('formatted label is too wide for container');
    }

    const isNegative = value < 0;

    const multiLineArray = Viz.multiLineTSpans(textLabel, barWidth, isNegative);

    return multiLineArray.label;
  }

  renderMultilineText(registeredLabelData, d3TextElement) {
    const { showLabelsInsideBar, theme, vizId } = this.props;
    const {
      value,
      meta: { colorManagerValue = '' },
    } = registeredLabelData;
    let labelAsMultiLine = [];

    try {
      labelAsMultiLine = this.getLabelLines(registeredLabelData);
    } catch {
      return; // render nothing
    }

    const isNegative = value < 0;
    const labelTextColor = Viz.getContrastColor(
      ColorManager.getColor(vizId, colorManagerValue, theme),
      theme,
    );

    const maxTextHeight =
      _.max(
        _.map(labelAsMultiLine, labelObj => Viz.calcTextHeight(labelObj.text)),
      ) ?? 0;

    const yPosInsideBar = 18;
    const yPosOnTopOfBar =
      labelAsMultiLine.length > 1
        ? -maxTextHeight * labelAsMultiLine.length
        : -6;

    d3TextElement
      .attr('x', 0)
      .attr(
        'y',
        showLabelsInsideBar ? yPosInsideBar : isNegative ? 12 : yPosOnTopOfBar,
      )
      .style('fill', showLabelsInsideBar ? labelTextColor : '');

    d3TextElement.select('*').remove();

    _.forEach(labelAsMultiLine, textLineObj =>
      d3TextElement
        .append('tspan')
        .attr('text-anchor', 'middle')
        .attr('x', '50%')
        .attr('dx', '-50%')
        .attr('dy', `${textLineObj.dy}em`)
        .text(textLineObj.text),
    );
  }

  renderLabel(registeredLabelData, d3Selection) {
    const { theme } = this.props;
    const { focused = true } = registeredLabelData;

    if (_.isNil(d3Selection)) {
      return;
    }

    const d3TextSelection = d3Selection
      .append('text')
      .attr('fill', theme?.colors?.ChartLabelTextColor)
      .style('fill', theme?.colors?.ChartLabelTextColor)
      .style('font-weight', '300')
      .style('font-style', 'inherit')
      .style('font-size', '10px')
      .style('fill-opacity', focused ? '1' : '0.16');

    this.renderMultilineText(registeredLabelData, d3TextSelection);
  }

  registerBarLabel(group) {
    const {
      getY,
      getX,
      isSecondaryPlot,
      layout,
      registerLabel,
      data: defaultData,
      height: renderingContainerHeight,
      focusedData,
    } = this.props;

    const dataList = _.isNil(group)
      ? [defaultData]
      : this.filterDataForGroup(group);

    const registerLabelInfo = dataList
      .filter(val => this.filterByYVal(val))
      .map(datum => {
        const isFocused =
          _.isEmpty(focusedData) || _.some(focusedData, datum.COLUMNS);
        const formatter = this.getFormatter(datum, layout);
        return {
          value: getY(datum),
          anchor: {
            x: this.getPlotXPos(datum),
            y: this.getPlotYPos(datum),
          },
          isSecondaryPlot,
          formatter,
          renderLabel: (d, renderLabel) =>
            this.renderLabel({ formatter, ...d }, renderLabel),
          focused: isFocused,
          renderingContainerHeight,
          labelClass: this.labelClass,
          meta: {
            customFormatProps: this.getCustomFormatProps(datum),
            colorManagerValue: getX(datum),
          },
        };
      });

    _.isFunction(registerLabel) && registerLabel(registerLabelInfo);
  }

  filterByYVal(dValue) {
    const { getY, getY0 } = this.props;
    if (getY(dValue) === 0) {
      return false;
    } else if (_.isFunction(getY0)) {
      const possibleTargetValue = getY(dValue);
      const possibleActualValue = getY0(dValue);
      const bothSameSideOfZero =
        (possibleActualValue >= 0 && possibleTargetValue >= 0) ||
        (possibleActualValue < 0 && possibleTargetValue < 0);
      return bothSameSideOfZero
        ? Math.abs(getY(dValue)) >= Math.abs(getY0(dValue))
        : true;
    } else {
      return true;
    }
  }

  layoutLabels() {
    const { showLabels, data = [], getX0, resetLabelClass } = this.props;
    const distinctGroups = BarUtils.getDistinctGroups(data, getX0);
    const d3LabelContainer = d3.select(this.labelContainer);
    d3LabelContainer.selectAll('*').remove();
    _.isFunction(resetLabelClass) && resetLabelClass(this.labelClass);

    if (showLabels) {
      const labelData = !_.isEmpty(distinctGroups) ? distinctGroups : [data];
      labelData.forEach(d => this.registerBarLabel(d));
    }
  }

  render() {
    const {
      data,
      theme,
      className,
      barClassName,
      getX,
      getY,
      getX0,
      vizId,
      layout,
      focusedData,
      height: chartHeight,
      hasTrendline,
      trendlineStrategy,
    } = this.props;
    this.layoutLabels();

    const distinctGroups = BarUtils.getDistinctGroups(data, getX0);

    const { group: groupScale, x, y } = this.getScales();

    // seed the color palette with the distinct x values to avoid sparse color rendering
    const distinctXValues = data.reduce((accum, curr) => {
      accum.add(getX(curr, layout));
      return accum;
    }, new Set());
    distinctXValues.forEach(xValue =>
      ColorManager.getColor(vizId, xValue, theme),
    );

    const renderBars = group => {
      const groupData = _.isNil(group) ? data : this.filterDataForGroup(group);

      const bars = groupData.map((groupDatum, i) => {
        const colorName = getX(groupDatum, layout);
        const yVal = getY(groupDatum);
        if (!yVal) {
          return (
            <rect
              key={`bars-dval-${i}`}
              className={barClassName}
              fill={`${
                groupDatum.color
                  ? groupDatum.color
                  : ColorManager.getColor(vizId, colorName, theme)
              }`}
            />
          );
        }
        const isFocused =
          _.isEmpty(focusedData) || _.some(focusedData, groupDatum.COLUMNS);
        const id = shortid.generate();

        // Bar height for positive values is from zero or the lowest scale value whichever is higher
        // Height for negative values is the reverse, greatest scale value or zero whichever is lower
        let barHeight =
          yVal > 0
            ? Math.abs(y(Math.max(0, y.domain()[0])) - y(yVal))
            : Math.abs(y(Math.min(0, y.domain()[1])) - y(yVal));

        barHeight = _.isFinite(barHeight) ? barHeight : 0;

        let barY = yVal > 0 ? y(yVal) : y(yVal) - barHeight;

        if (yVal < 0) {
          // adjust bar to place below zero line
          barY++;
        }
        let rectWidth = x.bandwidth();
        rectWidth = _.isFinite(rectWidth) ? rectWidth : 0;
        const widthOffset = 0;
        return (
          <rect
            key={shortid.generate()}
            id={id}
            fill={`${
              groupDatum.color
                ? groupDatum.color
                : ColorManager.getColor(vizId, colorName, theme)
            }`}
            className={classnames(
              barClassName,
              { dim: !isFocused },
              groupDatum.className,
            )}
            width={rectWidth}
            x={x(getX(groupDatum)) + widthOffset / 2}
            y={barY}
            height={barHeight}
          />
        );
      });
      return bars;
    };

    let barGroups = [];
    if (!_.isEmpty(distinctGroups)) {
      barGroups = distinctGroups.map((d, i) => {
        return (
          <BarGroup key={`${d}-${i}`} scale={groupScale} value={d}>
            {renderBars(d)}
          </BarGroup>
        );
      });
    } else {
      barGroups = <g className={`bar-group`}>{renderBars()}</g>;
    }

    const trendLines = [];

    if (hasTrendline && trendlineStrategy === TRENDLINE_STRATEGY.LINEAR) {
      const relativeXPos = groupScale.bandwidth() / 2;
      const pointDataWithGroup = _.flatMap(distinctGroups, group => {
        const groupData = _.isNil(group)
          ? data
          : this.filterDataForGroup(group);
        return _.map(groupData, groupDatum => {
          const legendValue = getX(groupDatum, layout);
          const groupXPos = groupScale(group);
          const _y = y(getY(groupDatum));
          const isFocused =
            _.isEmpty(focusedData) || _.some(focusedData, groupDatum.COLUMNS);

          return {
            group: legendValue,
            isFocused,
            color: groupDatum.color,
            x: groupXPos + relativeXPos,
            y: _y,
          };
        });
      });

      const pointsByGroup = _.groupBy(pointDataWithGroup, 'group');

      _.forEach(_.toPairs(pointsByGroup), ([groupName, pointData]) => {
        const lineEnds = getLinearRegression({
          pointData,
          endMargin: groupScale.bandwidth() / 1.5,
        });

        const isTrendlineVisible = getTrendlineIsVisible({
          boundaryPoints: lineEnds,
          chartHeight,
        });

        const firstOfGroup = _.head(pointData);
        const fillColor =
          firstOfGroup?.color ||
          ColorManager.getColor(vizId, firstOfGroup?.group, theme);

        if (isTrendlineVisible && firstOfGroup?.isFocused) {
          const lineClassName = `trendline-${_.kebabCase(groupName)}`;

          trendLines.push(
            <path
              key={lineClassName}
              className={lineClassName}
              d={d3.line()(lineEnds)}
              stroke={fillColor}
              strokeWidth={2}
              strokeLinecap={'round'}
              strokeDasharray={'2,5'}
            />,
          );
        }
      });
    }

    return (
      <g
        className={`${className} captureMouseEvents`}
        ref={el => {
          this.container = el;
        }}
      >
        {barGroups}
        {trendLines}
      </g>
    );
  }
}

const Bars = compose(
  pure,
  withDiscoverRouter,
  withTheme,
  LabelManagerConsumerHOC,
  withDiscoverOption({
    option: TRENDLINE_OPTION_SELECTOR,
    defaultValue: TRENDLINE_STRATEGY.LINEAR,
  }),
  connect(
    (state, ownProps) => {
      const { i18nPrefs = {} } = state?.account?.currentUser;
      const vizFormatToggles = VIZ_SELECTORS.getCustomFormatToggles(state, {
        discoveryId: ownProps.discoveryId,
      });
      const trendlineOption = _.find(vizFormatToggles, {
        key: TRENDLINE_TOGGLE_SELECTOR,
      });
      const hasTrendline = !!trendlineOption?.on;

      return {
        showGlobalTooltip: state.main.controlDown,
        i18nPrefs,
        hasTrendline,
      };
    },
    (dispatch, ownProps) => ({
      openReportLink(reportDetailInfo) {
        dispatch(Discover.openReportLink(reportDetailInfo, ownProps.history));
      },
      setFocusedData(dataItem) {
        ownProps.focus();

        dispatch(Discover.setFocusedVizData(ownProps.vizId, dataItem));
      },
    }),
  ),
  onlyUpdateForKeys([
    'focusedData',
    'data',
    'showGlobalTooltip',
    'height',
    'width',
    'enableReportLink',
  ]),
)(UnconnectedBars);

Bars.defaultProps = {
  barClassName: 'bar',
  showLabels: false,
  showLabelsInsideBar: true,
  colorPalette: palette,
  focus: _.noop,
  focusedData: [],
  getX0: _.constant(null),
  i18nPrefs: {},
};

export default Bars;
