import { useRef, useState } from 'react';
import { NOW, TimestampFilterSubTypes } from '../Filter';
import { get, head, isNil, map, toNumber } from 'lodash';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import moment from '../../../common/Moment';
import { messages } from '../../../i18n';
import {
  IRelativeDateCondition,
  ITimePeriod,
} from './relative-date-condition.interface';
import {
  DatetimeTextbox,
  StyledFormGroup,
  StyledSearchableDropdown,
  StyledSelectDropdown,
} from './relative-date-condition.styles';
import { ISearchableDropdownOption } from '../../../ui/dropdowns/searchable-dropdown/searchable-dropdown.interfaces';
import {
  DaysOfWeek,
  getDisplayText,
  RelativeDateAnchorModes,
  RelativeOperators,
  TimePeriods,
} from './relative-date-condition.utils';
import { FormInput } from '../../../components/ui/form-input';
import { useRelativeDateCondition } from './relative-date-condition.hook';
import { FormatDateRange } from './format-date-range';
import { SelectItem } from '../../../ui/dropdowns/select-dropdown';

export const RelativeDateCondition = ({
  vizId,
  filter,
  field,
}: IRelativeDateCondition) => {
  const {
    dateTimeFormat,
    datasetId,
    useFiscalCalendar,
    relativeDatesState,
    onOperatorChange,
    onPeriodOperatorChange,
    onIncludeFractionalPeriod,
    onOffsetChange,
    onEnableAnchor,
    onAnchorChanged,
    onAnchorModeChange,
    onAnchorOffsetChange,
    onAnchorDayChange,
  } = useRelativeDateCondition({ vizId, filter, field });

  const datePickerElement = useRef(null);

  // Note: This is never set, but is used currently by the validation check.
  const [operand] = useState<string>();

  if (
    isNil(filter) ||
    filter.subType !== TimestampFilterSubTypes.RELATIVE_DATES
  ) {
    return null;
  }

  const periodOperatorMenuitems = () => {
    return Object.values(TimePeriods).map((timePeriod: ITimePeriod) => {
      const displayMessage = getDisplayText(timePeriod, useFiscalCalendar);
      return {
        value: timePeriod.key,
        label: get(messages, displayMessage, displayMessage),
      };
    });
  };

  const operatorOptions = () => {
    return Object.values(RelativeOperators).map(op => ({
      value: op.key,
      label: get(messages, op.displayText, op.displayText),
    }));
  };

  const getValidationState = (): 'success' | 'warning' | 'error' | null => {
    // NOTE: because operand is never set, validation state is always null.
    // We should determine if we want user feedback on this component and if so
    // fix this
    if (!isNil(operand) && operand.length > 0) {
      if (isNaN(toNumber(operand))) {
        return 'error';
      }
    }
  };

  const forceDatePickerClosed = newDate => {
    const current = moment(relativeDatesState.anchorDate);
    const dayDiff = newDate.diff(current, 'days');
    // don't auto-close it if the user is changing the time attributes.
    if (Math.abs(dayDiff) >= 1 || current.isSame(newDate)) {
      if (!isNil(datePickerElement?.current)) {
        datePickerElement.current.close();
      }
    }
  };

  const anchorModeOptions = map(RelativeDateAnchorModes, value => ({
    value,
    label: get(messages.filters, value.toLowerCase()),
  }));

  const anchorDayOptions = DaysOfWeek.map(value => ({
    value,
    label: get(messages.daysOfWeek, value.toLowerCase()),
  }));

  const timePickerElement = (
    <TimePickerPanel
      defaultOpenValue={moment().startOf('day')}
      showSecond={false}
    />
  );
  const calendar = (
    // https://github.com/react-component/calendar/issues/751
    // both the disabledTime and onSelect method calls are a bit off in the
    // upstream ts definition.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Calendar
      locale={enUS}
      dateInputPlaceholder={messages.filters.pleaseInput}
      formatter={'YYYY-MM-DD HH:mm:ss Z'}
      disabledTime={false}
      showDateInput={false}
      timePicker={timePickerElement}
      onSelect={(event, cause) => {
        let value;
        if (cause && cause.source === 'todayButton') {
          value = NOW;
        } else {
          value = event;
        }
        onAnchorChanged(value);
        forceDatePickerClosed(value);
      }}
    />
  );
  const selectedOperator = RelativeOperators[relativeDatesState.operator];
  const timePeriod = TimePeriods[relativeDatesState.periodOperand || 'DAYS'];
  const periodDisplay = getDisplayText(timePeriod, useFiscalCalendar);

  return (
    <div className='relative-date-condition'>
      <div className='condition'>
        <div>
          <StyledFormGroup className={getValidationState()}>
            <label className={'control-label'}>
              {messages.filters.showItemsWhenValueIs}
            </label>
            <StyledSearchableDropdown
              id='relative-date-operator-dropdown'
              onSelect={(options: ISearchableDropdownOption[]) => {
                const option = head(options);
                onOperatorChange(option?.value);
              }}
              title={
                <span className={'dropdown-title'}>
                  {get(
                    messages,
                    selectedOperator.displayText,
                    selectedOperator.displayText,
                  )}
                </span>
              }
              options={operatorOptions()}
              popperSx={{ zIndex: 1050 }}
            />
            <FormInput
              className='relative-date-value'
              type={'text'}
              onChange={event => {
                onOffsetChange(event?.target?.value);
              }}
              value={
                isNil(relativeDatesState.offset) ||
                relativeDatesState.operator === 'this'
                  ? ''
                  : relativeDatesState.offset
              }
              disabled={relativeDatesState.operator === 'this'}
            />
            <StyledSearchableDropdown
              id='relative-date-period-dropdown'
              onSelect={(options: ISearchableDropdownOption[]) => {
                const option = head(options);
                onPeriodOperatorChange(option?.value);
              }}
              title={
                <span className={'dropdown-title'}>
                  {get(messages, periodDisplay, periodDisplay)}
                </span>
              }
              options={periodOperatorMenuitems()}
              popperSx={{ zIndex: 1050 }}
            />
            {relativeDatesState.displayFractionalPeriod &&
              (relativeDatesState.operator === 'past' ||
                relativeDatesState.operator === 'next') && (
                <input
                  type='checkbox'
                  id='include-fractional-period'
                  checked={relativeDatesState.includeFractionalPeriod}
                  onChange={event =>
                    onIncludeFractionalPeriod(event?.target?.checked)
                  }
                />
              )}
            {relativeDatesState.displayFractionalPeriod &&
              (relativeDatesState.operator === 'past' ||
                relativeDatesState.operator === 'next') && (
                <label
                  className={'control-label'}
                  htmlFor='include-fractional-period'
                >
                  {messages.filters.includeCurrentPartialPeriod}
                </label>
              )}
          </StyledFormGroup>
        </div>
        <div>
          <StyledFormGroup
            className={`relative-date-row-2 ${getValidationState()}`}
          >
            <input
              type='checkbox'
              id='relative-date-anchor-chk'
              checked={relativeDatesState.anchorEnabled}
              onChange={event => onEnableAnchor(event)}
            />{' '}
            <label
              className={'control-label'}
              htmlFor='relative-date-anchor-chk'
            >
              {messages.filters.anchorRelativeTo}
            </label>{' '}
            <StyledSelectDropdown
              id='relative-date-anchor-mode-dropdown'
              className={!relativeDatesState.anchorEnabled && 'disabled'}
              title={get(
                messages.filters,
                relativeDatesState.anchorMode.toLowerCase(),
              )}
            >
              {anchorModeOptions.map(({ value, label }) => (
                <SelectItem
                  key={label}
                  onClick={() => onAnchorModeChange(value)}
                >
                  {label}
                </SelectItem>
              ))}
            </StyledSelectDropdown>
            {relativeDatesState.anchorMode ===
              RelativeDateAnchorModes.EXACT && (
              <DatePicker
                animation='slide-up'
                calendar={calendar}
                value={
                  relativeDatesState.anchorDate !== NOW
                    ? moment(relativeDatesState.anchorDate)
                    : moment()
                }
                placement='bottomLeft'
                prefixCls='corvana-calendar-picker'
                ref={datePickerElement}
                onChange={selectedDate => {
                  onAnchorChanged(selectedDate);
                }}
              >
                {() => {
                  return (
                    <DatetimeTextbox
                      placeholder={messages.filters.pleaseSelect}
                      key='numeric-condition-operand-key'
                      type={'text'}
                      disabled={!relativeDatesState.anchorEnabled}
                      value={
                        relativeDatesState.anchorDate
                          ? relativeDatesState.anchorDate !== NOW
                            ? moment(relativeDatesState.anchorDate).format(
                                dateTimeFormat,
                              )
                            : messages.filters.now
                          : ''
                      }
                    />
                  );
                }}
              </DatePicker>
            )}
            {relativeDatesState.anchorMode ===
              RelativeDateAnchorModes.PREVIOUS && (
              <>
                <FormInput
                  className='relative-date-value days-value'
                  type='number'
                  disabled={!relativeDatesState.anchorEnabled}
                  onChange={event => {
                    onAnchorOffsetChange(event.target.value);
                  }}
                  value={relativeDatesState.anchorOffset}
                />
                <StyledSelectDropdown
                  id='relative-date-anchor-days-dropdown'
                  className={!relativeDatesState.anchorEnabled && 'disabled'}
                  placeholder={messages.filters.dayOfWeek}
                  title={get(
                    messages.daysOfWeek,
                    relativeDatesState.anchorDay.toLowerCase(),
                  )}
                >
                  {anchorDayOptions.map(({ value, label }) => (
                    <SelectItem
                      key={label}
                      onClick={() => onAnchorDayChange(value)}
                    >
                      {label}
                    </SelectItem>
                  ))}
                </StyledSelectDropdown>
              </>
            )}
          </StyledFormGroup>
        </div>
        <div>
          <FormatDateRange
            useFiscalCalendar={useFiscalCalendar}
            datasetId={datasetId}
            relativeDatesState={relativeDatesState}
            dateTimeFormat={dateTimeFormat}
          />
        </div>
      </div>
    </div>
  );
};
