import { Component } from 'react';
import { Modal } from '@sugar-discover/react-bootstrap-wrapper';
import styled from '@emotion/styled';
import { CloseIcon } from '../../../icons';
import { ModalLabel } from '../../../views/VizSaveDialog/ui';
import { ErrorBoundaryComponentProps } from '../../../components/error-boundary';
import { ErrorBoundary } from 'react-error-boundary';

const ModalRoot = styled(Modal)`
  .modal-content {
    display: flex;
    flex-direction: column;
  }
  .modal-body {
    padding: ${props => (props.padding !== undefined ? props.padding : 15)}px;
    flex-grow: 1;
  }
`;
const ModalHeader = styled(Modal.Header)(
  ({
    minHeight,
    theme: {
      darkMode,
      colors: {
        TableHeaderBackgroundDark,
        ModalHeaderBackground,
        ContentText,
        BaseWhite,
      } = {},
    } = {},
  }) => `
  background-color: ${!darkMode && ModalHeaderBackground};
  color: ${darkMode ? BaseWhite : ContentText};
  padding: 10px;
  border: none;
  ${minHeight ? 'min-height: 66px;' : ''}
  border-bottom: 1px solid ${!darkMode && TableHeaderBackgroundDark};
`,
);

const ModalTitle = styled(Modal.Title)`
  margin: 0;
  line-height: 1.828571;

  display: flex;

  .modal-title {
    flex-grow: 1;
  }
`;

const CloseIconStyled = styled(CloseIcon)`
  align-self: center;
`;

class FullScreenDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      this.setState({ show: this.props.show });
    }
  }
  render() {
    return (
      <ModalRoot
        animation={false}
        enforceFocus={false}
        show={this.state.show}
        onHide={this.props.doCancel}
        dialogClassName={
          this.props.className
            ? `full-screen-dialog ${this.props.className}`
            : 'full-screen-dialog'
        }
        bsSize='large'
        padding={this.props.padding}
        keyboard={this.props.keyboard}
      >
        <ModalHeader minHeight={this.props.titleMinHeight}>
          <ModalTitle>
            <span className={'modal-title'}>
              <ModalLabel>{this.props.titlePanel}</ModalLabel>
            </span>
            <CloseIconStyled
              onClick={() => {
                this.setState({ show: false });
                this.props.doCancel();
              }}
              icon='close-xl'
            />
          </ModalTitle>
        </ModalHeader>
        <ErrorBoundary {...ErrorBoundaryComponentProps}>
          <Modal.Body>{this.props.children}</Modal.Body>
        </ErrorBoundary>
      </ModalRoot>
    );
  }
}

FullScreenDialog.defaultProps = {
  show: false,
  titleMinHeight: true,
  keyboard: true,
};

export default FullScreenDialog;
