import store from './common/redux';
import Main from './common/redux/actions/MainActions';

export const onWindowBlur = () => {
  // ensure state toggles base on 'pressed' keys are release
  store.dispatch(Main.setControlDown(false));
  store.dispatch(Main.setMetaDown(false));
};

export function onKeyDown(event) {
  let doPrevent = false;
  if (event.ctrlKey && event.shiftKey && /^o$/i.test(event.key)) {
    store.dispatch(Main.toggleAdvanced());
    event.preventDefault();
  } else if (event.ctrlKey) {
    store.dispatch(Main.setControlDown(true));
  } else if (event.metaKey) {
    store.dispatch(Main.setMetaDown(true));
  } else if (event.keyCode === 8) {
    const d = event.srcElement || event.target;
    if (
      (d.tagName.toUpperCase() === 'INPUT' &&
        (d.type.toUpperCase() === 'TEXT' ||
          d.type.toUpperCase() === 'PASSWORD' ||
          d.type.toUpperCase() === 'FILE' ||
          d.type.toUpperCase() === 'SEARCH' ||
          d.type.toUpperCase() === 'EMAIL' ||
          d.type.toUpperCase() === 'NUMBER' ||
          d.type.toUpperCase() === 'DATE')) ||
      d.tagName.toUpperCase() === 'TEXTAREA'
    ) {
      doPrevent = d.readOnly || d.disabled;
    } else {
      doPrevent = true;
    }
  }

  if (doPrevent) {
    event.preventDefault();
  }
}

export function onKeyUp(event) {
  if (event.key === 'Control') {
    store.dispatch(Main.setControlDown(false));
  } else if (event.key === 'Meta') {
    store.dispatch(Main.setMetaDown(false));
  }
}
