import { useCallback, useMemo, useRef, useState, memo } from 'react';

import _ from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

import { QueryError } from '../query-error';
import VizChartError from '../VizChartError';
import { LegendAwareChartPlaceholder } from './chart-placeholder.component';
import { LegendAwareChartValidationPanel } from './chart-validation-panel.component';
import { Chart } from './chart.component';
import { useVizChartState } from './viz-chart.hook';
import { ReQuery } from './requery.component';
import { LabelManagerProvider } from '../../common/d3/label-manager-provider';
import { MissingFieldPlaceholder } from './missing-field-placeholder';
import { IVizChartProps } from './viz-chart.interfaces';
import { ExportToExcelPlaceholder } from './export-to-excel-placeholder.component';
import { LogErrorBoundary } from '../../components/error-boundary';
import { SkeletonVizLoader } from '../../common/loaders/skeleton-viz-loader';

export const VizChart = memo<IVizChartProps>(
  ({
    vizId,
    width: overrideWidth = 0,
    height: overrideHeight = 0,
    isIsolated = false,
  }) => {
    const {
      useLiveQuery,
      liveQueryHasChanged,
      chartSpec,
      validation,
      hasChanged,
      forceQuery,
      vizLoading: _vizLoading,
      queryResults,
      shouldRunInitialQuery,
      secondaryQueryResults,
      queryError,
      viz,
      isAdvancedMode,
      dirty,
    } = useVizChartState({ vizId });
    const hasMissingFields =
      !_.isEmpty(viz.missingFields) || !_.isEmpty(viz.missingFilters);
    const showRequery =
      dirty &&
      hasChanged &&
      !useLiveQuery &&
      !liveQueryHasChanged &&
      validation.valid;
    const { id: chartSpecId } = chartSpec;
    const isFirstRender = useRef(true);
    const [vizChartDOM, setVizChartDOM] = useState();
    const vizChartRefCallback = useCallback(node => {
      // triggers update and sets chart DOM
      if (!_.isNil(node)) {
        setVizChartDOM(node);
      }
    }, []);

    // Don't remove this. We need this component to handle lifecycle events, displaying CorbotoLoading alone breaks things
    const vizLoading =
      !hasMissingFields &&
      (_vizLoading ||
        (_.isUndefined(_vizLoading) &&
          isFirstRender.current === true &&
          shouldRunInitialQuery));
    isFirstRender.current = false;
    const style: any = {};
    if (isIsolated) {
      style.height = '100%';
      style.width = '100%';
    }
    const chart = useMemo(() => {
      if (showRequery) {
        return <ReQuery onRefresh={() => forceQuery()} />;
      }
      if (!validation.valid && isAdvancedMode) {
        // only shown in advanced-mode with errors present
        return (
          <LegendAwareChartValidationPanel
            vizId={vizId}
            validation={validation}
            advanced={isAdvancedMode}
          />
        );
      } else if (!_.isNil(queryError)) {
        return (
          <QueryError queryError={queryError} isAdvancedMode={isAdvancedMode} />
        );
      } else if (!validation.valid || _.isEmpty(queryResults)) {
        return !hasMissingFields ? (
          <LegendAwareChartPlaceholder vizId={vizId} />
        ) : (
          <MissingFieldPlaceholder vizId={vizId} />
        );
      } else if (queryResults && !queryResults.loading) {
        const isOverrideDimensions =
          _.isNumber(overrideWidth) &&
          _.isNumber(overrideHeight) &&
          overrideWidth !== 0 &&
          overrideHeight !== 0;

        if (chartSpec.shouldDeferToExport(queryResults)) {
          return (
            <ExportToExcelPlaceholder
              vizId={viz?.id}
              queryResults={queryResults}
            />
          );
        }

        return (
          <div
            style={{
              height: overrideHeight || '100%',
              width: overrideWidth || '100%',
            }}
          >
            {isOverrideDimensions ? (
              <div
                style={{
                  height: overrideHeight,
                  width: overrideWidth,
                }}
              >
                <ErrorBoundary
                  fallbackRender={VizChartError}
                  onError={LogErrorBoundary}
                >
                  {chartSpecId && (
                    <Chart
                      key='chart'
                      spec={chartSpec}
                      viz={viz}
                      vizId={vizId}
                      queryResults={queryResults}
                      secondaryQueryResults={secondaryQueryResults}
                      height={overrideHeight}
                      width={overrideWidth}
                    />
                  )}{' '}
                </ErrorBoundary>
              </div>
            ) : (
              <AutoSizer>
                {({ width, height }) => (
                  <div
                    style={{
                      height: overrideHeight || height,
                      width: overrideWidth || width,
                    }}
                  >
                    <ErrorBoundary
                      fallbackRender={VizChartError}
                      onError={LogErrorBoundary}
                    >
                      {height > 0 && width > 0 && (
                        <Chart
                          key='chart'
                          spec={chartSpec}
                          viz={viz}
                          vizId={vizId}
                          queryResults={queryResults}
                          secondaryQueryResults={secondaryQueryResults}
                          height={height}
                          width={width}
                        />
                      )}
                    </ErrorBoundary>
                  </div>
                )}
              </AutoSizer>
            )}
          </div>
        );
      } else {
        // catch-all, if all else fails show the placeholder. but we really should never get here.
        return <LegendAwareChartPlaceholder vizId={vizId} />;
      }
    }, [
      showRequery,
      validation,
      isAdvancedMode,
      queryError,
      queryResults,
      forceQuery,
      vizId,
      hasMissingFields,
      overrideWidth,
      overrideHeight,
      chartSpec,
      chartSpecId,
      viz,
      secondaryQueryResults,
    ]);
    return vizLoading ? (
      <SkeletonVizLoader />
    ) : (
      <div
        ref={vizChartRefCallback}
        style={style}
        className={'viz-chart-container'}
      >
        <LabelManagerProvider chartContainer={vizChartDOM}>
          {chart}
        </LabelManagerProvider>
      </div>
    );
  },
);
