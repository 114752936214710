import {
  useCallback,
  useContext,
  useEffect,
  useState,
  createContext,
} from 'react';
import _ from 'lodash';
import * as d3 from 'd3';
import { useAccount } from '../../utilities/account';
import { IUserInfo } from '../../../account/interfaces';
import { useDiscoverTheme } from '../../emotion';
import {
  ILabelManagerContext,
  IRegisterLabelArguments,
} from './label-manager-provider.interface';
import {
  useManagedLabels,
  getUniqueLabelDataId,
  renderProvidedLabels,
  removeLabelsFromPane,
  labelManagerSecondaryPlotClass,
  labelManagerPrimaryPlotClass,
} from './label-manager-provider.utils';

// holds all the labels for all chart types, so that one layout strategy can run on them
export const LabelManagerContext = createContext<ILabelManagerContext>({
  labelData: [], // labels are in no particular order. just need them to be iterable
  registerLabel: _.noop,
  resetLabelClass: _.noop,
});

export const LabelManagerProvider = ({ chartContainer, children }) => {
  const [labelData, setLabelData] = useState<IRegisterLabelArguments[]>([]);

  const { colors } = useDiscoverTheme();

  const resetLabelClass = useCallback(labelClass => {
    setLabelData(prevLabelData =>
      _.reject(prevLabelData, {
        labelClass,
      }),
    );
  }, []);

  const registerLabel = useCallback((data: IRegisterLabelArguments[]) => {
    const validData = _.filter(
      data,
      datum =>
        datum?.focused &&
        !_.some(
          ['value', 'anchor.x', 'anchor.y'],
          attr =>
            _.isNaN(_.get(datum as any, attr)) ||
            _.isNil(_.get(datum as any, attr)),
        ),
    );

    if (!_.isEmpty(validData)) {
      setLabelData(prevLabelData => {
        const uniqLabelData = _.uniqBy(
          _.concat(prevLabelData, validData),
          getUniqueLabelDataId,
        );
        return uniqLabelData;
      });
    }
  }, []);

  const { currentUser = {} as IUserInfo } = useAccount();
  const { i18nPrefs = {} } = currentUser;

  const svg = chartContainer
    ? d3.select(chartContainer?.querySelector('svg'))
    : null;

  const managedLabelData = useManagedLabels(labelData, svg);

  const labelDataGroupedByPaneSelector = _.groupBy(managedLabelData, d =>
    _.get(d, 'isSecondaryPlot')
      ? `.${labelManagerSecondaryPlotClass}`
      : `.${labelManagerPrimaryPlotClass}`,
  );

  const contentTextColor = colors?.ContentText;

  useEffect(() => {
    _.forEach(
      [
        `.${labelManagerSecondaryPlotClass}`,
        `.${labelManagerPrimaryPlotClass}`,
      ],
      paneSelector => {
        if (!svg) {
          return;
        }

        const providedRenderingPane = svg.select(paneSelector)?.node();

        _.isEmpty(labelDataGroupedByPaneSelector[paneSelector]) &&
          !_.isNil(providedRenderingPane) &&
          removeLabelsFromPane(providedRenderingPane);
      },
    );

    _.forEach(
      _.toPairs<IRegisterLabelArguments[]>(labelDataGroupedByPaneSelector),
      ([paneSelector, managedLabels]) => {
        const providedRenderingPane = svg?.select(paneSelector)?.node();

        if (_.isNil(providedRenderingPane)) {
          return;
        }

        renderProvidedLabels(
          providedRenderingPane,
          managedLabels,
          contentTextColor,
          i18nPrefs,
        );
      },
    );
  }, [contentTextColor, i18nPrefs, labelDataGroupedByPaneSelector, svg]);

  return (
    <LabelManagerContext.Provider
      value={{
        registerLabel,
        labelData,
        resetLabelClass,
      }}
    >
      {children}
    </LabelManagerContext.Provider>
  );
};

export const useLabelManagerUtils = () => {
  const labelManagerContext = useContext(LabelManagerContext);
  const { registerLabel, resetLabelClass } = labelManagerContext;

  return {
    registerLabel,
    resetLabelClass,
  };
};

export const LabelManagerConsumerHOC = Component => props => {
  return (
    <LabelManagerContext.Consumer>
      {labelManagerContext => {
        const { registerLabel, resetLabelClass } = labelManagerContext;
        return (
          <Component
            registerLabel={registerLabel}
            resetLabelClass={resetLabelClass}
            {...props}
          />
        );
      }}
    </LabelManagerContext.Consumer>
  );
};
