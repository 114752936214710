import {
  useCallback,
  useState,
  createContext,
  ReactNode,
  useContext,
} from 'react';
import _, { every, isEmpty, isNil, isNumber } from 'lodash';
import {
  ChartTooltipContextValueType,
  IUseChartTooltipHookProps,
  TooltipData,
} from './chart-tooltip.interface';
import styled from '@emotion/styled';
import { ChartContext } from '../../viz-chart/chart.hook';

export const ChartTooltipContext = createContext<ChartTooltipContextValueType>(
  undefined,
);

export const TooltipContainer = styled.div`
  position: relative;
`;

const TooltipProviderContainer = ({
  value,
  children,
}: {
  value: any;
  children?: ReactNode;
}) => {
  return (
    <TooltipContainer>
      <ChartTooltipContext.Provider value={value}>
        {children}
      </ChartTooltipContext.Provider>
    </TooltipContainer>
  );
};

export const TOOLTIP_ID = 'chart-tooltip';

const DEFAULT_TOOLTIP_POSITION = {
  posX: 0,
  posY: 0,
};
/**
 * useChartTooltip is a utility hook that creates the context and provider for chart tooltips
 */
export const useChartTooltip = ({
  vizId,
  svgRef,
}: IUseChartTooltipHookProps) => {
  const { height: chartHeight, width: chartWidth } = useContext(ChartContext);
  const [tooltipData, _setTooltipData] = useState<TooltipData>({
    value: [],
    anchor: DEFAULT_TOOLTIP_POSITION,
  });

  const setTooltipData = useCallback((tooltipData?: TooltipData) => {
    if (
      isNil(tooltipData) ||
      isEmpty(tooltipData.value) ||
      !every([tooltipData?.anchor?.posX, tooltipData?.anchor?.posY], isNumber)
    ) {
      _setTooltipData({
        value: [],
        anchor: DEFAULT_TOOLTIP_POSITION,
      });
    }

    _setTooltipData(tooltipData);
  }, []);

  const svgArea = !_.isNil(svgRef) ? svgRef?.current : null;

  const providerValue = {
    vizId,
    container: svgArea,
    tooltipData: tooltipData?.value ?? [],
    anchor: tooltipData?.anchor ?? DEFAULT_TOOLTIP_POSITION,
    chartWidth,
    chartHeight,
  };
  return {
    provider: TooltipProviderContainer,
    providerValue,
    setTooltipData,
  };
};
