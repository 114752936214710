import { invertLuminance } from '../mixins';
import { lighten } from 'polished';
import * as Colors from './colors.styles';
import * as SugarColorPalette from '@sugarcrm/maple-syrup/build/color/js/sugarColorPalette';

export const ValidStatusTextColor = '#00ba83';

export const SugarPrimaryPalette = {
  LightBlue: SugarColorPalette.ColorBaseGray800,
  MediumBlue: SugarColorPalette.ColorBaseGray900,
  DarkBlue: SugarColorPalette.ColorBaseBlue800,
};

export const ContentText = SugarColorPalette.ColorBaseGray300;
export const ContentBackground = SugarColorPalette.ColorBaseGray900;

export const SugarSecondaryPalette = {
  LightOcean: SugarColorPalette.ColorBaseIndigo200,
  LightPacific: SugarColorPalette.ColorBaseCyan200,
  LightTeal: SugarColorPalette.ColorBaseTeal200,
  LightGreen: SugarColorPalette.ColorBaseEmerald200,
  LightArmy: SugarColorPalette.ColorBaseLime200,
  LightYellow: SugarColorPalette.ColorBaseYellow200,
  LightOrange: SugarColorPalette.ColorBaseOrange200,
  LightRed: SugarColorPalette.ColorBaseRed200,
  LightCoral: SugarColorPalette.ColorBaseFuschia200,
  LightPink: SugarColorPalette.ColorBasePink200,
  LightPurple: SugarColorPalette.ColorBaseViolet200,
  LightIndigo: SugarColorPalette.ColorBaseIndigo200,
  MediumOcean: SugarColorPalette.ColorBaseIndigo500,
  MediumPacific: SugarColorPalette.ColorBaseCyan500,
  MediumTeal: SugarColorPalette.ColorBaseTeal500,
  MediumGreen: SugarColorPalette.ColorBaseEmerald500,
  MediumArmy: SugarColorPalette.ColorBaseLime500,
  MediumYellow: SugarColorPalette.ColorBaseYellow400,
  MediumOrange: SugarColorPalette.ColorBaseOrange500,
  MediumRed: SugarColorPalette.ColorBaseRed500,
  MediumCoral: SugarColorPalette.ColorBaseFuschia500,
  MediumPink: SugarColorPalette.ColorBasePink500,
  MediumPurple: SugarColorPalette.ColorBaseViolet500,
  MediumIndigo: SugarColorPalette.ColorBaseIndigo400,
  DarkOcean: SugarColorPalette.ColorBaseIndigo800,
  DarkPacific: SugarColorPalette.ColorBaseCyan800,
  DarkTeal: SugarColorPalette.ColorBaseTeal800,
  DarkGreen: SugarColorPalette.ColorBaseEmerald800,
  DarkArmy: SugarColorPalette.ColorBaseLime800,
  DarkYellow: SugarColorPalette.ColorBaseYellow700,
  DarkOrange: SugarColorPalette.ColorBaseOrange800,
  DarkRed: SugarColorPalette.ColorBaseRed800,
  DarkCoral: SugarColorPalette.ColorBaseFuschia800,
  DarkPink: SugarColorPalette.ColorBasePink800,
  DarkPurple: SugarColorPalette.ColorBaseViolet800,
  DarkIndigo: SugarColorPalette.ColorBaseIndigo600,
};

export const SugarGrayPalette = {
  Black: SugarColorPalette.ColorBaseWhite,
  Gray95: SugarColorPalette.ColorBaseGray50,
  Gray90: SugarColorPalette.ColorBaseGray100,
  Gray80: SugarColorPalette.ColorBaseGray200,
  Gray70: SugarColorPalette.ColorBaseGray300,
  Gray60: SugarColorPalette.ColorBaseGray400,
  Gray50: SugarColorPalette.ColorBaseGray500,
  Gray40: SugarColorPalette.ColorBaseGray600,
  Gray30: SugarColorPalette.ColorBaseGray700,
  Gray20: SugarColorPalette.ColorBaseGray800,
  Gray10: SugarColorPalette.ColorBaseGray900,
  White: SugarColorPalette.ColorBaseBlack,
};

export const {
  Gray90,
  Gray80,
  Gray70,
  Gray60,
  Gray50,
  Gray40,
  Gray30,
  Gray20,
  Gray10,
  White,
} = SugarGrayPalette;

export const ActiveLinkText = SugarColorPalette.ColorBaseBlue300;

export const PanelBackground = SugarColorPalette.ColorBaseGray900;
export const TooltipBackgroundColor = SugarColorPalette.ColorBaseGray600;
export const TooltipTextColor = Colors.ContentBackground;
export const {
  BiggestMoverTopBullet,
  BiggestMoverBottomBullet,
  ContrastColorDark,
  ContrastColorLight,
  ButtonColorPrimary,
  DisabledPrimaryButtonColor,
  LoginButtonColor,
  LoginHoverColor,
  LoginDisabledColor,
  SuccessButtonColor,
  SuccessButtonBorderColor,
  SuccessButtonColorHover,
  SuccessButtonBorderColorHover,
  InfoButtonColor,
  InfoButtonBorderColor,
  InfoButtonColorHover,
  InfoButtonBorderColorHover,
  WarningButtonColor,
  WarningButtonBorderColor,
  WarningButtonColorHover,
  WarningButtonBorderColorHover,
  DangerButtonColor,
  DangerButtonBorderColor,
  DangerButtonColorHover,
  DangerButtonBorderColorHover,
  PipelineChangesForecastColor,
  PipelineChangesIncreasesColor,
  PipelineChangesDecreasesColor,
  PipelineChangesClosedWonColor,
} = Colors;

export const ContentBlack = SugarColorPalette.ColorBaseWhite;
export const { LightBlue, MediumBlue, DarkBlue } = SugarPrimaryPalette;
export const { MediumGreen, LightRed, DarkRed } = SugarSecondaryPalette;
export const DashletLibraryHighlightRowBackground =
  SugarColorPalette.ColorBaseBlue950;
export const TextInputBorderColor = SugarColorPalette.ColorBaseGray800;

export const TabHighlight = '#79ADE0';
export const ActiveTabTextColor = SugarColorPalette.ColorBaseGray100;
export const ActiveTabIndicator = SugarColorPalette.ColorBaseBlue600;
export const TabBackground = SugarColorPalette.ColorBaseGray950;
export const TabHighlightDisabled = SugarColorPalette.ColorBaseGray950;

export const BorderColor = SugarColorPalette.ColorBaseGray800;
export const DropdownOptionHoverBackground = SugarColorPalette.ColorBaseGray700;
export const MenuHoverBackgroundColor = SugarColorPalette.ColorBaseGray500;
export const TableRowBackgroundHover = SugarColorPalette.ColorBaseBlue950;
export const TableRowHover = SugarColorPalette.ColorBaseBlue950;
export const TableHeaderBackgroundDark = SugarColorPalette.ColorBaseBlue800;
export const PanelNavBackground = SugarColorPalette.ColorBaseBlue800;

export const DefaultButtonFillColor = MediumBlue;
export const DefaultButtonBorderColor = DefaultButtonFillColor;
export const DefaultButtonTextColor = Colors.ContentBackground;
export const DefaultButtonTextColorHover = DefaultButtonTextColor;
export const ButtonColorPrimaryHover = lighten(0.2, ButtonColorPrimary);
export const ButtonBorderColorPrimaryHover = ButtonColorPrimaryHover;
export const DefaultButtonFillColorHover = lighten(0.2, DefaultButtonFillColor);

export const TimePickerHeaderBackground = SugarColorPalette.ColorBaseGray900;
export const ActiveFilterTypeTextColor = SugarColorPalette.ColorBaseBlue300;
export const SelectedDateBackgroundColor = SugarColorPalette.ColorBaseBlue900;
export const SelectedDateRangeBackgroundColor =
  SugarColorPalette.ColorBaseBlue800;
export const TimePickerHeaderBorder = SugarColorPalette.ColorBaseGray800;
export const TimePickerHeaderText = SugarColorPalette.ColorBaseGray300;
export const DropdownButtonBackgroundColor = SugarColorPalette.ColorBaseGray900;
export const DropdownButtonBorderColor = SugarColorPalette.ColorBaseGray700;
export const ModalBackdropColor = SugarColorPalette.ColorBaseGray900;
export const SelectedDateTextColor = SugarColorPalette.ColorBaseGray300;
export const TimePickerBorder = SugarColorPalette.ColorBaseBlue800;
export const TimePickerHoverBackground = SugarColorPalette.ColorBaseBlue800;
export const SlicerTimePickerHeaderBackground =
  SugarColorPalette.ColorBaseGray900;

export const ModalFontColor = invertLuminance(Colors.ContentText);
export const ProfileContentBackground = SugarColorPalette.ColorBaseBlack;
export const SugarToastBackground = SugarColorPalette.ColorBaseGray950;

export const PrimaryColor = SugarColorPalette.ColorBaseBlue800;

export const BaseWhite = SugarColorPalette.ColorBaseWhite;
export const PivotWhite = SugarColorPalette.ColorBaseGray400;

export const NavIconSelected = SugarColorPalette.ColorBaseBlue300;

export const PrimaryButtonColor = SugarColorPalette.ColorBaseBlue400;
export const PrimaryButtonTextColor = SugarColorPalette.ColorBaseGray800;
export const PrimaryButtonColorHover = SugarColorPalette.ColorBaseBlue500;
export const PrimaryButtonColorActive = SugarColorPalette.ColorBaseBlue600;
export const PrimaryButtonColorDisabled = SugarColorPalette.ColorBaseBlue500;

export const SecondaryButtonColor = SugarColorPalette.ColorBaseGray900;
export const SecondaryButtonTextColor = SugarColorPalette.ColorBaseGray400;
export const SecondaryButtonBorderColor = SugarColorPalette.ColorBaseGray700;
export const SecondaryButtonTextColorHover = SugarColorPalette.ColorBaseGray400;
export const SecondaryButtonColorHover = SugarColorPalette.ColorBaseGray800;
export const SecondaryButtonTextColorDisabled =
  SugarColorPalette.ColorBaseGray600;
export const SecondaryButtonColorDisabled = SugarColorPalette.ColorBaseGray900;
export const SecondaryButtonBorderColorDisabled =
  SugarColorPalette.ColorBaseGray800;

export const IconPrimaryHoverColor = SugarColorPalette.ColorBaseGray400;
export const LinkButtonTextColor = SugarColorPalette.ColorBaseBlue300;
export const LinkButtonTextColorHover = SugarColorPalette.ColorBaseBlue500;
export const LinkButtonTextColorActive = SugarColorPalette.ColorBaseBlue600;
export const LinkButtonTextColorDisabled = SugarColorPalette.ColorBaseBlue500;

export const TableHeaderBackground = SugarColorPalette.ColorBaseGray800;
export const TableHeaderTextColor = SugarColorPalette.ColorBaseGray400;

export const DefaultIconColor = SugarColorPalette.ColorBaseGray500;
export const PlaceholderText = SugarColorPalette.ColorBaseGray500;

export const Strokes = SugarColorPalette.ColorBaseGray800;
export const PanelBorder = SugarColorPalette.ColorBaseGray800;

export const SuccessColor = SugarColorPalette.ColorBaseGreen500;
export const IconGreenColor = SugarColorPalette.ColorBaseGreen500;
export const CorvanaBrightBlue = SugarColorPalette.ColorBaseBlue200;
export const ModalHeaderBackground = SugarColorPalette.ColorBaseGray900;

export const TableBodyBackground = SugarColorPalette.ColorBaseGray900;
export const MediumBorder = SugarColorPalette.ColorBaseGray800;

export const RequiredTextColor = SugarColorPalette.ColorBaseRed300;
export const ErrorStatusTextColor = SugarColorPalette.ColorBaseRed300;
export const ErrorStatusBackground = SugarColorPalette.ColorBaseRed900;
export const TableTextErrorColor = ErrorStatusTextColor;
export const negativeColor = SugarColorPalette.ColorBaseRed300;
export const negativeBackgroundColor = SugarColorPalette.ColorBaseRed900;
export const errorBackground = SugarColorPalette.ColorBaseRed900;

export const HeaderGrey = SugarColorPalette.ColorBaseGray400;
export const ErrorTextColor = SugarColorPalette.ColorBaseRed300;
export const ContentTextColor = SugarColorPalette.ColorBaseGray300;

export const PivotCellBorder = SugarColorPalette.ColorBaseGray800;
export const TextCellColor = SugarColorPalette.ColorBaseGray300;
export const Clear = SugarColorPalette.ColorBaseGray900;

export const SlicerShadowColor = 'rgba(0, 0, 0, 0.1)';

export const PivotCellHighlight = SugarColorPalette.ColorBaseCyan950;
export const PivotCellHighlightHover = SugarColorPalette.ColorBaseCyan900;
export const SubtotalBackground = SugarColorPalette.ColorBaseGray800;
export const PivotTotals = SugarColorPalette.ColorBaseGray700;
export const PivotCell = SugarColorPalette.ColorBaseGray900;
export const PivotHeaderBackground = SugarColorPalette.ColorBaseGray950;
export const FieldPillBackgroundHover = SugarColorPalette.ColorBaseGray950;
export const FieldPillBackgroundHoverBorder =
  SugarColorPalette.ColorBaseGray900;
export const FieldPillBackgroundActive = SugarColorPalette.ColorBaseGray100;
export const FieldPillBackgroundActiveBorder =
  SugarColorPalette.ColorBaseGray300;
export const DatePreviewBox = SugarColorPalette.ColorBaseGray800;

export const ErrorColor = SugarColorPalette.ColorBaseRed500;
export const DangerColor = SugarColorPalette.ColorBaseRed500;
export const WarningColor = SugarColorPalette.ColorBaseYellow400;
export const InfoColor = SugarColorPalette.ColorBaseBlue600;
export const GrayIconColor = SugarColorPalette.ColorBaseGray600;
export const DisabledIconColor = SugarColorPalette.ColorBaseGray600;
export const FunnelBackgroundColor = SugarColorPalette.ColorBaseGray950;
export const ChartTooltipBackground = SugarColorPalette.ColorBaseGray600;
export const TooltipBackgroundVColor = SugarColorPalette.ColorBaseGray200;
export const TooltipBackgroundVColorText = SugarColorPalette.ColorBaseGray600;
export const ModalBackground = SugarColorPalette.ColorBaseGray950;
export const ModalBackgroundBorder = SugarColorPalette.ColorBaseGray800;
export const DropdownBorderColor = SugarColorPalette.ColorBaseGray800;
export const FilterSelectItemCheckbox = SugarColorPalette.ColorBaseGray700;
export const FilterToolBarIconStringHover = SugarColorPalette.ColorBaseBlue400;
export const TableBorderColor = SugarColorPalette.ColorBaseGray800;

export const SidebarNavBackground = SugarColorPalette.ColorBaseGray900;
export const SidebarNavNotch = SugarColorPalette.ColorBaseBlue800;
export const SidebarNavIcon = SugarColorPalette.ColorBaseGray400;
export const SidebarNavIconHover = SugarColorPalette.ColorBaseBlue300;
export const SidebarNavIconDisabled = SugarColorPalette.ColorBaseGray700;
export const SidebarNavNotchDisabled = SugarColorPalette.ColorBaseBlue950;
export const SidebarSubMenuBtnHover = SugarColorPalette.ColorBaseBlue300;
export const SidebarNavKebabHover = SugarColorPalette.ColorBaseBlue800;
export const SidebarNavExpandedKebab = SugarColorPalette.ColorBaseGray500;
export const SubMenuBorder = SugarColorPalette.ColorBaseBlue800;
export const SubMenuBackground = SugarColorPalette.ColorBaseGray900;
export const SubMenuItemHover = SugarColorPalette.ColorBaseGray800;
export const SubMenuItemText = SugarColorPalette.ColorBaseGray300;
export const SidebarNavOverlay = SugarColorPalette.ColorBaseBlack;
export const SidebarNavItemBackgroundHover = SugarColorPalette.ColorBaseGray800;
export const SidebarNavText = SugarColorPalette.ColorBaseGray300;
export const SidebarNavItemTextHover = SugarColorPalette.ColorBaseGray300;
export const SidebarNavSugar = SugarColorPalette.ColorBaseGray400;
export const SidebarNavSugarHover = SugarColorPalette.ColorBaseGray300;
export const AccountProfileSectionBackground =
  SugarColorPalette.ColorBaseGray800;
export const FromBackgroundGradientColor = SugarColorPalette.ColorBaseBlue900;
export const ToBackgroundGradientColor = SugarColorPalette.ColorBaseBlue600;
export const HeaderIconColor = SugarColorPalette.ColorBaseGray800;
export const ProfileHoverColor = SugarColorPalette.ColorBaseBlue800;
export const MainContentBoxShadowColor = SugarColorPalette.ColorBaseGray900;
