import BaseChartSpec, {
  ENABLE_REPORT_LINK,
  NON_NUMERIC_CALC,
  Validations,
  generateMeasuresFromMetrics,
} from '../../BaseChartSpec';
import { ShelfTypes } from '../../../discovery/interfaces';
import { LegendShapes } from '../legend-shape';
import { ChartSpecIds, Types } from '../../../common/Constants';
import LineSvgIcon from '../../../../images/sdd/viz/visualization-selection-line.svg';
import ListSvgLine from '../../../../images/sdd/viz/line-chart.svg';
import _ from 'lodash';
import {
  getDateTypesFromShelfAsOrdinals,
  getHardCodedOrdinalsFromShelfAsOrdinals,
} from '../ChartUtils';

class LineChartSpec extends BaseChartSpec {
  constructor() {
    super({
      id: 'line',
      name: 'line.chartName',
      placeholderImage: '/assets/images/sdd/chart/canvas-icon-line.svg',
      icon: <LineSvgIcon />,
      listIcon: <ListSvgLine />,
      legendShape: LegendShapes.LINE,
      shelves: {
        [ChartSpecIds.LINES]: {
          id: ChartSpecIds.LINES,
          name: 'line.linesShelf',
          aggregateMeasures: false,
          accepts: NON_NUMERIC_CALC,
          shelfType: ShelfTypes.SELECTION,
          isColumn: true,
        },
        [ChartSpecIds.XAXIS]: {
          id: ChartSpecIds.XAXIS,
          name: 'line.xAxisShelf',
          accepts: NON_NUMERIC_CALC,
          shelfType: ShelfTypes.SELECTION,
          groupNames: true,
        },
        [ChartSpecIds.VALUES]: {
          id: ChartSpecIds.VALUES,
          name: 'line.valuesShelf',
          accepts: [Types.ANY],
          isRequired: true,
          shelfType: ShelfTypes.MEASURE,
        },
        [ChartSpecIds.SLICER]: {
          id: ChartSpecIds.SLICER,
          name: 'chartSpecs.area.slicer',
          accepts: [Types.STRING, Types.TIMESTAMP],
          isRequired: false,
          shelfType: ShelfTypes.SLICER,
        },
      },
      supportsDifferentiatedNullHandling: true,
      supportsLegendSelection: true,
      supportsLayoutPanelSort: false,
      showTooltipIndicator: true,
      customFormatToggles: [ENABLE_REPORT_LINK],
    });
    this.validationRules = [
      Validations.HasLayout,
      Validations.OneOf([this.shelves.VALUES]),
      Validations.TypeSafe,
    ];
  }
  mapShelvesToQueryVariables(viz) {
    if (!this.validate(viz)) {
      return {
        attributeNames: [],
        measures: [],
      };
    }

    let attributeNames = (viz?.layout?.XAXIS ?? [])
      .map(g => g.name)
      .concat((viz?.layout?.LINES ?? []).map(c => c.name));

    attributeNames = attributeNames.concat(
      getDateTypesFromShelfAsOrdinals(viz.layout.XAXIS),
      getHardCodedOrdinalsFromShelfAsOrdinals(viz.layout.XAXIS),
      getDateTypesFromShelfAsOrdinals(viz.layout.LINES),
      getHardCodedOrdinalsFromShelfAsOrdinals(viz.layout.LINES),
    );

    const measures = generateMeasuresFromMetrics(viz);

    return {
      attributeNames: _.uniq(attributeNames),
      measures,
    };
  }
}

export default LineChartSpec;
