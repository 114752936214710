import { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { FeedbackQueries } from '../graphql/index';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { isReadOnly } from '../../common/redux/selectors/AccountSelectors';
import { compose } from 'react-recompose';
import { ThumbsDownIcon, ThumbsUpIcon } from '../../icons/icons/feedback-icon';
import { css } from '@emotion/react';

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackValue: _.isNil(props.feedback) ? 'Neutral' : props.feedback.value,
    };
  }

  submitFeedback(value) {
    const applyTimestamp = new Date().getTime();
    // Set to neutral if attempting same value
    if (this.state.feedbackValue === value) {
      value = 'Neutral';
    }
    this.setState({ feedbackValue: value });
    this.props.submitFeedback(value, applyTimestamp);
  }

  render() {
    const { isReadOnlyUser } = this.props;
    return (
      <div className='feedback'>
        {this.props.label && (
          <span className='feedback-label'>{this.props.label}</span>
        )}
        <ThumbsUpIcon
          styles={css`
            margin-right: 8px;
          `}
          disabled={isReadOnlyUser}
          submitted={this.state.feedbackValue === 'Positive'}
          onClick={() => this.submitFeedback('Positive')}
        />
        <ThumbsDownIcon
          styles={css`
            margin-right: 8px;
          `}
          disabled={isReadOnlyUser}
          submitted={this.state.feedbackValue === 'Negative'}
          onClick={() => this.submitFeedback('Negative')}
        />
      </div>
    );
  }
}

const FeedbackQuery = graphql(FeedbackQueries.get, {
  skip: ownProps => {
    return !_.isUndefined(ownProps.feedback);
  },
  options: ({ anomalyId }) => ({
    variables: {
      anomalyId,
    },
  }),
  props: ({ data }) => {
    if (data?.error) {
      console.error(data.error);
      return { feedback: null, loading: false };
    } else if (data?.feedback) {
      return { feedback: data.feedback, loading: false };
    } else {
      return { feedback: null, loading: true };
    }
  },
});

const SubmitFeedbackMutation = graphql(FeedbackQueries.submit, {
  options: ({ anomalyId }) => ({
    refetchQueries: [{ query: FeedbackQueries.get, variables: { anomalyId } }],
  }),
  props: ({ ownProps, mutate }) => ({
    submitFeedback(value, timestamp) {
      return mutate({
        variables: {
          anomalyId: ownProps.anomalyId,
          monitorId: ownProps.monitorId,
          value,
          timestamp,
        },
      });
    },
  }),
});

Feedback.propTypes = {
  monitorId: PropTypes.string.isRequired,
  anomalyId: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default compose(
  connect(({ account }) => ({
    isReadOnlyUser: isReadOnly(account),
  })),
  FeedbackQuery,
  SubmitFeedbackMutation,
)(Feedback);
