import React from 'react';
import { SugarIcon } from '../../sugar-icon';
import { ISugarIconProps } from '../../common';

export interface IChevronProps extends Omit<ISugarIconProps, 'icon'> {
  direction: 'right' | 'left' | 'up' | 'down';
}

export const Chevron = ({ direction = 'down', ...props }: IChevronProps) => {
  return <SugarIcon icon={`chevron-${direction}`} {...props} />;
};
