import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useDiscoverTheme, vbox } from '../../common/emotion';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export const DatasetSection = styled.div(() => {
  const {
    darkMode,
    colors: { PivotWhite, PanelBackground },
  } = useDiscoverTheme();

  return css`
    margin-bottom: 2em;
    width: 100%;
    background-color: ${darkMode ? PanelBackground : PivotWhite};
  `;
});

export const EditDatasetContent = styled.div`
  ${vbox()}
  flex-direction: initial;
  flex: 1;
`;

export const TabsWrapper = styled.div(() => {
  const {
    darkMode,
    colors: { Gray20, PivotWhite, TabBackground, PanelBorder },
  } = useDiscoverTheme();

  return css`
    background-color: ${darkMode ? TabBackground : PivotWhite};
    border-bottom: 1px solid ${darkMode ? PanelBorder : Gray20};
  `;
});

export const TabsStyled = styled(Tabs)(() => {
  const {
    darkMode,
    colors: { MediumBlue, ActiveTabIndicator },
  } = useDiscoverTheme();

  return css`
    min-height: 32px;

    & .MuiTabs-indicator {
      height: 4px;
      background: ${darkMode ? ActiveTabIndicator : MediumBlue};
    }
  `;
});

export const TabStyled = styled(Tab)(() => {
  const {
    darkMode,
    colors: {
      Gray90,
      LightBlue,
      SemiboldFontWeight,
      ContentText,
      ActiveTabTextColor,
    },
  } = useDiscoverTheme();

  return css`
    text-transform: none;
    font-size: 0.6875rem;
    line-height: 1.2;
    font-weight: ${SemiboldFontWeight};
    min-height: 32px;
    padding-top: 9px;
    padding-bottom: 9px;
    color: ${darkMode ? ContentText : Gray90};

    &:hover {
      background: ${LightBlue};
    }

    &.Mui-selected {
      color: ${ActiveTabTextColor};
    }
  `;
});

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
`;
