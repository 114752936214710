import { css } from '@emotion/react';

export const administrationStyles = css`
  #admin-tabs {
    margin: 20px 0 0 0;
  }
  #admin-tabs > .nav-tabs {
    margin-left: 20px;
    border: none;
  }
  #admin-tabs > .nav-tabs > li.active {
    z-index: 10;
  }
  #admin-tabs > .nav-tabs > li > a {
    padding: 8px 12px 4px 12px;
  }
`;
