import { css } from '@emotion/react';
import { useDiscoverThemeColors } from '../../common/emotion/theme';
import { ISugarIconProps } from '../common';
import { SugarIcon } from '../sugar-icon';
import _ from 'lodash';
import { Chevron } from './chevron';

export const EditCalcIcon = props => <SugarIcon icon={'quote-lg'} {...props} />;
export const EyeIconHide = props => <SugarIcon icon={'hide-lg'} {...props} />;
export const EyeIconShow = props => (
  <SugarIcon icon={'preview-lg'} {...props} />
);
export const StaticInsightsIcon = props => (
  <SugarIcon icon={'insights-lg'} {...props} />
);
export const SearchIcon = props => <SugarIcon icon={'search'} {...props} />;
export const SearchIconLg = props => (
  <SugarIcon icon={'search-lg'} {...props} />
);
export const SettingsCogIcon = props => (
  <SugarIcon icon={'settings'} {...props} />
);
export const TrashIcon = props => <SugarIcon icon={'trash-lg'} {...props} />;
export const CloseIcon = (props: Omit<ISugarIconProps, 'icon'>) => {
  return <SugarIcon icon={'close-lg'} hover {...props} />;
};
export const CloseIconSmall = (props: Omit<ISugarIconProps, 'icon'>) => {
  return <SugarIcon icon={'close'} hover {...props} />;
};
export const EditIcon = props => <SugarIcon icon={'edit-lg'} {...props} />;
export const AddCircle = props => (
  <SugarIcon icon={'add-circle-lg'} {...props} />
);
export const ProfileIcon = props => <SugarIcon icon={'user'} {...props} />;
export const HelpIcon = props => <SugarIcon icon={'help-lg'} {...props} />;
export const LogoutIcon = props => <SugarIcon icon={'logout'} {...props} />;
export const KebabIcon = ({
  isOpen,
  ...props
}: Omit<ISugarIconProps, 'icon'> & { isOpen?: boolean }) => {
  const { PrimaryColor } = useDiscoverThemeColors();
  return (
    <SugarIcon
      css={css`
        border-style: solid;
        border-color: ${isOpen ? PrimaryColor : 'transparent'};
        border-radius: 3px;
        border-width: 2px;
        padding-top: 2px;
        padding-bottom: 2px;
      `}
      color={isOpen ? PrimaryColor : undefined}
      icon={'kebab-lg'}
      {...props}
    />
  );
};
export const WarningIcon = props => (
  <SugarIcon icon={'warning-lg'} iconType={'danger'} {...props} />
);
export const AddIcon = props => <SugarIcon icon={'add-circle-lg'} {...props} />;
export { InsightsIcon } from './insights-icon';
export { FieldTypeIcon } from './field-type-icon';
export const AddLineIcon = props => (
  <SugarIcon icon={'add-line-lg'} {...props} />
);
export const DivideLineIcon = props => (
  <SugarIcon icon={'divide-line-lg'} {...props} />
);
export const SubtractLineIcon = props => (
  <SugarIcon icon={'subtract-line-lg'} {...props} />
);
export const LeftLineIcon = props => (
  <SugarIcon icon={'left-paren-line-lg'} {...props} />
);
export const MultiplyLineIcon = props => (
  <SugarIcon icon={'multiply-line-lg'} {...props} />
);
export const RightLineIcon = props => (
  <SugarIcon icon={'right-paren-line-lg'} {...props} />
);
export const CheckCircleIcon = props => (
  <SugarIcon icon={'check-circle-lg'} {...props} />
);
export const CheckIconSmall = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'check'} size={10} {...props} />
);
export const CheckIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'check'} {...props} />
);
export const CommentsIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'comment-lg'} {...props} />
);
export const DownChevron = (props: Omit<ISugarIconProps, 'icon'>) => (
  <Chevron direction={'down'} {...props} />
);
export const FormatIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'format-lg'} {...props} />
);
export const InfoIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'info'} {...props} />
);
export const InfoIconLg = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'info-lg'} {...props} />
);
export const LayoutIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'layout-lg'} {...props} />
);
export const PinIcon = (
  props: Omit<ISugarIconProps, 'icon'> & { pinned: boolean },
) => {
  return (
    <SugarIcon
      icon={props.pinned ? 'pin-fill-lg' : 'pin-lg'}
      {..._.omit(props, 'pinned')}
    />
  );
};
export const SaveIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'save-lg'} {...props} />
);
export const ShareIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'share-lg'} {...props} />
);
export const UndoIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'undo-lg'} {...props} />
);
export const RedoIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'redo-lg'} {...props} />
);
export const RefreshIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'refresh-lg'} {...props} />
);
export const FeedIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'activity-feed-xl'} {...props} />
);
export const DiscoverIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'discover-xl'} {...props} />
);
export const LibraryIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'knowledgebase-xl'} {...props} />
);
export const AccountIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'contact-xl'} {...props} />
);
export const CancelIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'remove'} {...props} />
);
export const HamburgerIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'hamburger-lg'} {...props} />
);
export const HomeIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'home-lg'} {...props} />
);
export const StatisticsIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'statistics-lg'} {...props} />
);
export const DatabaseIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'database-lg'} {...props} />
);
export const SugarLogoIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'sugar-logo-24'} {...props} />
);
export const PlusIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'plus'} {...props} />
);
export const PlusLgIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'plus-lg'} {...props} />
);
export const ListViewIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'list-view'} {...props} />
);
export const RightChevron = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'chevron-right'} {...props} />
);
export const LeftChevron = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'chevron-left'} {...props} />
);
export const Calendar = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'calendar'} {...props} />
);
export const FocusDrawerIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'focus-drawer'} {...props} />
);
export const ErrorIcon = (props: Omit<ISugarIconProps, 'icon'>) => (
  <SugarIcon icon={'error-lg'} {...props} />
);
