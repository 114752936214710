import { css } from '@emotion/react';

export const top3Styles = css`
  .segment-bar-container {
    display: table-cell;
    width: 100%;
  }
  .segment-bar-container > div.bar {
    height: 100%;
    width: 66%;
  }
`;
