import { css } from '@emotion/react';
import { font } from '../mixins';

import { bootstrapOverridesMobileStyles } from './bootstrap-overrides.styles';
import { reactVirtualizedMobileStyles } from './react-virtualized.styles';
import { loginMobileStyles } from './login.styles';
import { navMobileStyles } from './nav.styles';
import { modalMobileStyles } from './modal.styles';
import { activityFeedMobileStyles } from './activity-feed.styles';
import { settingsMobileStyles } from './settings.styles';
import { discoverMobileStyles } from './discover.styles';
import { pivotTableMobileStyles } from './pivot-table.styles';
import { svgColorize } from '../mixins';

export const mobileStyles = ({ isDashletMode, theme }) => {
  const {
    colors: {
      MediumFontWeight,
      ContentBackground,
      ContentText,
      RegularFontWeight,
      MobileIconButtonHover,
    },
  } = theme;
  return css`
    /* Safari mobile will zoom in on inputs on focus with text smaller than 16px.*/
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='tel'],
    textarea {
      font-size: 16px;
      /* remove the top shadow on the input elements*/
      -webkit-appearance: none;
    }
    #main {
      position: fixed;
      left: 0;
      width: 100%;
    }
    #mainDiv {
      cursor: pointer;
    }
    .mobile-icon-button {
      ${font({ size: '14px', weight: MediumFontWeight, color: ContentText })}
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      ${isDashletMode
        ? svgColorize({ color: MobileIconButtonHover })('&:hover')
        : ''}
      ${svgColorize({ color: MobileIconButtonHover })('')}

      .icon {
        max-height: 24px;
        max-width: 24px;
        height: 24px;
        width: 24px;
      }
    }
    .mobile-icon-button .badge-icon {
      ${font({
        size: '10px',
        weight: RegularFontWeight,
        color: ContentBackground,
      })}
    }
    .mobile-icon-button :first-of-type {
      margin-right: 4px;
    }
    ${bootstrapOverridesMobileStyles({ theme })};
    ${reactVirtualizedMobileStyles({ theme })};
    ${loginMobileStyles({ theme })};
    ${navMobileStyles({ theme })};
    ${modalMobileStyles({ theme, isDashletMode })};
    ${activityFeedMobileStyles({ theme })};
    ${settingsMobileStyles({ theme })};
    ${discoverMobileStyles({ isDashletMode, theme })};
    ${pivotTableMobileStyles};
  `;
};
