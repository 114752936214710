import { VizLayoutMapping } from './VizLayoutMapping';
import _ from 'lodash';
import { parseJSON } from '../common/Util';
import {
  IViz,
  ICustomFormatToggle,
  IBaseChartSpec,
  IActionListener,
  IVizBase,
  ILayout,
  IChartShelf,
  ValidationFunction,
  IQueryWithCalc,
  IMeasure,
  IQueryWithSubtotals,
  ChartType,
} from './interfaces';
import { IAnyAttribute } from '../datasets';
import { messages as appMessages } from '../i18n';
import { isLineChartWithMultipleFields } from './viz-config.util';
import {
  DEFAULT_EXCEL_ROW_RENDER_THRESHOLD,
  getFrontendUrl,
  Types,
} from '../common/Constants';
import URLs from '../common/Urls';
import { ISortedQueryData } from '../common/redux/selectors/viz-selectors';
import { Viz as VizUtils } from '../discovery/VizUtil';

const ENABLE_REPORT_LINK: ICustomFormatToggle = {
  name: 'enableReportLink',
  displayLabel: 'baseChart.enableReportLinkToggle',
  displayTooltip: 'baseChart.enableReportLinkToggleTooltip',
  type: 'reportLinking',
  requiresReportSelection: true,
  isAvailable: _.stubTrue,
  isDisabled: (viz: IViz) => {
    return isLineChartWithMultipleFields(viz.chartType, _.get(viz, 'layout'));
  },
};

export const generateMeasuresFromMetrics = (viz: IVizBase): IMeasure[] => {
  const customAggregations = parseJSON(viz.options.customAggregations);
  const valueShelves = VizUtils.getValueShelves(viz);
  const aggList = _.flatMap(valueShelves, item => {
    return _.map(viz?.layout[item?.id] ?? [], v => {
      const aggName = `SHELF_${v.name}`;
      const selectedAggregation =
        customAggregations[aggName] || v.defaultAggregation;
      return {
        attributeName: v.name,
        aggregation: selectedAggregation,
        resultSetFunction: 'NONE',
      };
    });
  });
  return aggList;
};

class ValidationError extends Error {
  /**
   *  'params' arg here is coerced to expect an empty array, as the types for the
   * default Error constructor specifies 0-1 arguments. I am not sure why params
   * is even here, as Error will just drop the rest on the floor, unless
   * something is monkey patching Error. Either way, I didn't want to introduce
   * any potentially breaking changes to the API
   *   --Rob
   */
  constructor(
    _validator = 'unknown',
    viz,
    message,
    _messages = [],
    _errorFields = [],
    ...params: []
  ) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message, ...params);
  }
}

function FieldError(viz, field, message) {
  // Pass remaining arguments (including vendor specific ones) to parent constructor
  this.message = message;

  this.field = field;
  this.viz = viz;
}
FieldError.prototype = new Error();

export const Validations: {
  HasLayout: ValidationFunction;
  OneOf: (shelves: IChartShelf[]) => ValidationFunction;
  TypeSafe: ValidationFunction;
  FieldLimit: ValidationFunction;
} = {
  HasLayout: (viz: IVizBase) => {
    if (_.isNil(viz.layout)) {
      throw new ValidationError(
        'HasLayout',
        viz,
        appMessages.baseChart.validationErrorHasLayout,
      ); // should never happen
    }
  },
  OneOf: (shelves: IChartShelf[]) => (viz: IVizBase): boolean => {
    for (const shelf of shelves) {
      const shelfOnViz = viz.layout[shelf.id];
      if (_.isNil(shelfOnViz)) {
        continue;
      }
      if (shelfOnViz.length > 0) {
        return true;
      }
    }
    throw new ValidationError(
      'OneOf',
      viz,
      appMessages.baseChart.validationErrorOneOf,
    );
  },
  FieldLimit: (viz: IViz, spec: IBaseChartSpec) => {
    _.forEach(Object.entries(viz.layout), ([key, shelf]) => {
      const shelfSpec = spec.shelves[key];
      if (_.isNil(shelfSpec)) {
        throw new Error(
          `Report has shelf definition which does not correspond to report type: ${key}`,
        );
      }
      if (shelfSpec.limits) {
        const isWithinLimits =
          shelf.length >= shelfSpec.limits.min &&
          shelf.length <= shelfSpec.limits.max;
        if (!isWithinLimits) {
          throw new ValidationError(
            'FieldLimit',
            viz,
            appMessages.formatString(
              appMessages.baseChart.validationErrorFieldLimit,
              {
                name: shelfSpec.name,
                min: shelfSpec.limits.min,
                max: shelfSpec.limits.max,
                length: shelf.length,
              },
            ),
          );
        }
      }
    });

    return true;
  },
  TypeSafe: (viz: IVizBase, spec: IBaseChartSpec): boolean => {
    const errorsWithFields = [];
    // Iterate over shelves and collect any field errors
    for (const [key, shelf] of Object.entries(viz.layout)) {
      if (_.isNil(key) || key === 'undefined') {
        // skip this one, the layout should never have an 'undefined'/null shelf
        continue;
      }
      try {
        const shelfSpec = spec.shelves[key];
        if (_.isNil(shelfSpec)) {
          throw new Error(
            appMessages.formatString(
              appMessages.baseChart.badShelfForReportTypeError,
              key,
            ) as string,
          );
        }
        for (const entry of shelf) {
          if (
            !shelfSpec.accepts.find(e => {
              return (
                e === entry.attributeType ||
                e === Types.ANY ||
                (e === Types.CALC && _.isNil(entry.calcType)
                  ? false
                  : e === entry.calcType)
              );
            })
          ) {
            throw new FieldError(
              viz,
              entry,
              appMessages.formatString(
                appMessages.baseChart.fieldError,
                entry.attributeType,
                shelfSpec.accepts,
              ),
            );
          }
        }
      } catch (e) {
        errorsWithFields.push(e);
      }
    }
    if (errorsWithFields.length > 0) {
      const fields = errorsWithFields
        .filter(e => e instanceof FieldError)
        .map((e: any) => e.field);
      throw new ValidationError(
        'TypeSafe',
        viz,
        appMessages.baseChart.validationErrorTypeSafe,
        errorsWithFields,
        fields,
      );
    }

    return true;
  },
};

const NON_NUMERIC_CALC = [
  Types.NUMBER,
  Types.STRING,
  Types.TIMESTAMP,
  Types.TIME_CALC,
  Types.STRING_CALC,
];

class BaseChartSpec implements IBaseChartSpec {
  id: ChartType;
  name: string;
  hidden: boolean;
  placeholderImage: string;
  canScroll: boolean;
  shelves: { [key: string]: IChartShelf };
  validationRules: ValidationFunction[];
  legendShape: string;
  icon: JSX.Element;
  requiredFields: boolean;
  listIcon: JSX.Element;
  supportsSummary: boolean;
  isToggleDisabled: (_string, _IViz) => boolean;
  customFormatToggles: ICustomFormatToggle[]; // customFormatToggles can also be supplied by IVizOptions
  supportsPriorPeriodMetrics: boolean;
  supportsConditionalFormatting: boolean;
  supportsLayoutPanelSort: boolean;
  supportsDifferentiatedNullHandling: boolean;
  actionListeners: IActionListener[];
  supportsLegendSelection: boolean;
  showTooltipIndicator: boolean;
  xAxisOrient: string;
  hideXAxisTickLabels: boolean;
  summaryOrientation: string;
  generateMeasuresFromMetrics: (_viz: IVizBase) => IMeasure[];
  mapCalcsToQuery?(viz: IVizBase): IQueryWithCalc;
  mapSubtotalsToQuery?(viz: IVizBase): IQueryWithSubtotals;
  errors?: string[] = [];
  isAdvancedModeOnly?: boolean;
  rowRenderThreshold?: number;

  constructor({
    id,
    name,
    hidden,
    placeholderImage,
    canScroll = true,
    shelves,
    validationRules,
    legendShape,
    icon,
    requiredFields = true,
    listIcon,
    supportsSummary = false,
    isToggleDisabled = _.stubFalse,
    customFormatToggles = [],
    supportsPriorPeriodMetrics = true,
    supportsConditionalFormatting = false,
    supportsLayoutPanelSort = true,
    supportsDifferentiatedNullHandling = false,
    actionListeners = [],
    supportsLegendSelection = false,
    showTooltipIndicator = false,
    xAxisOrient = 'bottom',
    hideXAxisTickLabels = false,
    summaryOrientation = 'top',
    isAdvancedModeOnly = false,
    rowRenderThreshold = DEFAULT_EXCEL_ROW_RENDER_THRESHOLD,
  }: Partial<IBaseChartSpec>) {
    this.id = id;
    this.name = name;
    this.hidden = hidden;
    this.placeholderImage = URLs.joinUrls(getFrontendUrl(), placeholderImage);
    this.canScroll = canScroll;
    this.shelves = shelves;
    this.validationRules = validationRules;
    this.legendShape = legendShape;
    this.icon = icon;
    this.requiredFields = requiredFields;
    this.listIcon = listIcon;
    this.supportsSummary = supportsSummary;
    this.isToggleDisabled = isToggleDisabled;
    this.customFormatToggles = customFormatToggles;
    this.supportsPriorPeriodMetrics = supportsPriorPeriodMetrics;
    this.supportsConditionalFormatting = supportsConditionalFormatting;
    this.supportsLayoutPanelSort = supportsLayoutPanelSort;
    this.supportsDifferentiatedNullHandling = supportsDifferentiatedNullHandling;
    this.actionListeners = actionListeners;
    this.supportsLegendSelection = supportsLegendSelection;
    this.showTooltipIndicator = showTooltipIndicator;
    this.xAxisOrient = xAxisOrient;
    this.hideXAxisTickLabels = hideXAxisTickLabels;
    this.summaryOrientation = summaryOrientation;
    this.generateMeasuresFromMetrics = generateMeasuresFromMetrics;
    this.isAdvancedModeOnly = isAdvancedModeOnly;
    this.rowRenderThreshold = rowRenderThreshold;
  }
  validate(viz) {
    for (const validation of this.validationRules) {
      try {
        validation(viz, this);
      } catch (e) {
        return {
          valid: false,
          error: e,
        };
      }
    }
    return {
      valid: true,
    };
  }

  shouldDeferToExport(queryResults: ISortedQueryData) {
    return (
      queryResults?.executeQuery?.results?.length > this.rowRenderThreshold
    );
  }

  validateFieldForShelf(
    field: IAnyAttribute,
    toShelfId: string,
    layout: ILayout,
    fromShelf?: IChartShelf | null | undefined,
  ) {
    const shelf = this.shelves[toShelfId];
    if (_.isNil(shelf) || _.isNil(field)) {
      return false;
    }
    if (field.aggregateMeasure && shelf.aggregateMeasures === false) {
      this.errors.push(appMessages.baseChart.errorAggMetricNotAllowedInGroups);
      return false;
    }
    if (_.isEmpty(field.ordinalAttribute) && shelf.requiresOrdinal === true) {
      this.errors.push(appMessages.baseChart.errorOrdinalFieldRequired);
      return false;
    }
    const shelfAcceptsField = _.some(shelf.accepts, t => {
      return (
        t === Types.ANY ||
        t === field.attributeType ||
        (t === Types.CALC && _.isNil(field.calcType)
          ? false
          : t === field.calcType)
      );
    });
    if (!shelfAcceptsField) {
      this.errors.push(
        appMessages.formatString(
          appMessages.baseChart.errorAttributeTypeNotAllowedInShelf,
          field.attributeType,
        ),
      );
    }
    const shelfFields = _.get(layout, toShelfId, []);

    const fieldAlreadyInShelf = _.some(shelfFields, { name: field.name });
    // user cannot move field to shelf that already contains the field.
    if (
      fieldAlreadyInShelf &&
      ((!_.isNil(fromShelf) && fromShelf?.id !== toShelfId) || // can only re-order within same shelf
        _.isNil(fromShelf)) // dragging from field list
    ) {
      this.errors.push(
        appMessages.formatString(
          appMessages.baseChart.duplicateFieldInShelf,
          field.name,
        ),
      );
      return false;
    }

    const limitNotExceeded =
      shelfFields.length < (shelf.limits ? shelf.limits.max : 1000);
    if (!limitNotExceeded) {
      this.errors.push(appMessages.baseChart.errorShelfLimitExceeded);
    }
    return shelfAcceptsField && limitNotExceeded;
  }

  validateFieldForShelfWithError(field, shelfId, layout, fromShelf) {
    this.errors = [];
    this.validateFieldForShelf(field, shelfId, layout, fromShelf);

    if (this.errors.length > 0) {
      return this.errors[0];
    }
    return appMessages.baseChart.noErrors;
  }

  copyLayoutToViz(viz, nextChartType = '') {
    if (_.isNil(viz.layout)) {
      // Initialize new layout

      viz.layout = {};
      _.keys(this.shelves)
        .filter(shelfName => !_.isNil(shelfName) && shelfName !== 'undefined')
        .forEach(key => {
          viz.layout[key] = [];
        });
    } else {
      // Map existing layout shelves
      const prevLayout = { ...viz.layout };
      delete viz.layout;
      viz.layout = VizLayoutMapping(
        prevLayout,
        viz.chartType,
        nextChartType ?? viz.chartType,
      );
    }
  }

  buildSecondaryQueryVariables(_viz, _vars): any {
    // noop for charts by default
  }

  /**
   * Discovery Redux Action listener. ChartSpec is notified when an action is performed on an Open Discovery.
   *
   * If dispatching other actions as a result of the one being observed make sure to use the 'reduxTransactionId' from
   * the referenced action. This will ensure that the undo/redo stack treats reactive actions as atomic with the
   * original.
   *
   * @param dispatch
   * @param discovery
   * @param action
   */
  onAction(dispatch, discovery, action) {
    this.actionListeners.forEach(act => act(dispatch, discovery, action));
  }
}

export default BaseChartSpec;
export { ValidationError, FieldError, NON_NUMERIC_CALC, ENABLE_REPORT_LINK };
