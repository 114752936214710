import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ErrorVariablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const PreFormattedVariables = styled('pre')`
  max-height: 300px;
  font-size: 10px;
  max-width: 800px;
  overflow: auto;
`;
export const StyledCopySqlButton = styled.div(
  ({
    theme: {
      colors: { ContentBackground, ModalButtonHoverFontColor } = {} as any,
    } = {},
  }: any) => css`
    .btn.loading-button {
      border-color: ${ContentBackground};
      background: transparent;
      color: ${ContentBackground};
    }
    .btn.loading-button:hover {
      border-color: ${ContentBackground};

      .loading-button-content {
        color: ${ModalButtonHoverFontColor};
      }
    }
  `,
);
