import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Root = styled.div(
  () => css`
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    height: 100%;
  `,
);

export const FlexRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

export const FlexSpaced = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SubTitleBar = styled.div`
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const IconArea = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  :not(:first-of-type) {
    margin-left: 16px;
  }
`;

export const NoMatches = styled.div(
  ({ theme: { colors: { TableHeaderTextColor } = {} } = {} }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    font-size: 12px;
    font-weight: 300;
    color: ${TableHeaderTextColor};
  `,
);

export * from './panels';
