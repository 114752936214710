import { Component } from 'react';
import { DragSource } from 'react-dnd';
import { DnDTypes } from '../common';
import _ from 'lodash';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import SimpleFieldPill from './SimpleFieldPill';
import { messages, i18nUtils } from '../i18n';
import { VIZ_SELECTORS } from '../common/redux/selectors/viz-selectors';
import { SugarIcon } from '../icons';
import { withTheme } from '@emotion/react';

const DragPreview = props => {
  const pillWidth = document.querySelector('.viz-field-pill')?.offsetWidth;
  const { field, shelf, isOverTarget, useFiscalCalendar } = props;
  const isOverTrash = isOverTarget(props.trashTargetId);
  const getTextWidth = () => {
    if (isOverTrash && !_.isNil(shelf)) {
      return `${parseInt(pillWidth) - 12}px`;
    } else {
      return pillWidth;
    }
  };

  const renderIcon = () => {
    // if hovering over the trash && we have a shelf defined, show the trash icon
    if (isOverTrash && !_.isNil(shelf)) {
      return (
        <SugarIcon
          icon={'trash'}
          hover
          hoverColor={props.theme?.colors?.MediumBlue}
          className={'viz-field-icon small'}
        />
      );
    } else {
      return '';
    }
  };

  const displayName = i18nUtils.translateFieldName(
    messages,
    field,
    useFiscalCalendar,
  );

  return (
    <div style={{ width: pillWidth }} className={'viz-field-pill drag-preview'}>
      <div style={{ width: getTextWidth() }} className={'viz-field-text'}>
        <div className={'viz-field-label'}>{displayName}</div>
      </div>
      {renderIcon()}
    </div>
  );
};
export const FieldPillDragPreview = connect((state, ownProps) => {
  const useFiscalCalendar =
    VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting(state, ownProps) &&
    VIZ_SELECTORS.getActiveVizFiscalSetting(state, ownProps) === 'true';
  return {
    trashTargetId: state.discover.dndTrashTargetId,
    useFiscalCalendar,
  };
})(withTheme(DragPreview));

class FieldPill extends Component {
  componentDidMount() {
    // hide the default drag preview
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  render() {
    return this.props.connectDragSource(
      <div className={`field-pill-drag-wrapper ${this.props.className ?? ''}`}>
        <SimpleFieldPill {...this.props} />
      </div>,
      { dropEffect: 'move' },
    );
  }
}

const fieldSource = {
  beginDrag(props) {
    return {
      field: props.field,
      shelf: props.shelf,
    };
  },
};
const collect = (connectComponent, monitor) => {
  return {
    connectDragSource: connectComponent.dragSource(),
    connectDragPreview: connectComponent.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

export default compose(DragSource(DnDTypes.VIZ_FIELD, fieldSource, collect))(
  FieldPill,
);
