import { Component } from 'react';
import { connect } from 'react-redux';
import Discover from '../common/redux/actions/DiscoverActions';
import { messages } from '../i18n';

import {
  ControlLabel,
  FormControl,
  FormGroup,
  Modal,
} from '@sugar-discover/react-bootstrap-wrapper';
import { ButtonContainer, PrimaryButton, SecondaryButton } from '../ui';
import { Table, TableColumn } from '../common/widgets/index';

class QueryToolDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { json: '' };
  }

  componentDidUpdate(prevProps) {
    // wipes out the state of the json string
    if (!this.props.show && prevProps.show) {
      this.setState({ json: '' });
    }
  }

  handleJsonChange(e) {
    this.setState({ json: e.target.value });
  }

  query() {
    console.log('Execute Query...');
  }

  renderQueryResults() {
    if (this.props.queryResults) {
      const tableHeaderColumns = [];
      for (let i = 0; i < this.props.queryResults.columnNames.length; i++) {
        tableHeaderColumns.push(
          <TableColumn key={`${i}`} dataField={i} width='150'>
            {this.props.queryResults.columnNames[i]}
          </TableColumn>,
        );
      }

      return (
        <Table
          className='dataTable queryToolTable'
          ref='table'
          keyField={'_'}
          data={this.props.queryResults.results}
          striped={false}
          hover
        >
          {tableHeaderColumns}
        </Table>
      );
    } else {
      return <div>{messages.library.clickToSeeResults}</div>;
    }

    // return <div>{JSON.stringify(this.props.queryResults)}</div>;
  }

  render() {
    return (
      <Modal
        animation={false}
        show={this.props.show}
        onHide={this.hideModal}
        dialogClassName='regular-modal'
        bsSize='large'
      >
        <Modal.Header>
          <Modal.Title>{messages.library.queryToolButtonLabel}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <FormGroup controlId='formEmail'>
              <ControlLabel>{messages.json}</ControlLabel>
              <FormControl
                className='jsonTextArea'
                componentClass='textarea'
                value={this.state.json}
                onChange={e => this.handleJsonChange(e)}
              />
            </FormGroup>
            {this.renderQueryResults()}
            <ButtonContainer>
              <SecondaryButton onClick={() => this.props.onClose()}>
                {messages.library.close}
              </SecondaryButton>
              <PrimaryButton
                onClick={() => this.props.executeQuery(this.state.json)}
              >
                {messages.library.query}
              </PrimaryButton>
            </ButtonContainer>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    queryResults: state.discover.queryResults,
  };
};

const mapDispatchToProps = function(dispatch) {
  return {
    executeQuery(json) {
      dispatch(Discover.executeQuery(json));
    },
  };
};

const ConnectedDialog = connect(
  mapStateToProps,
  mapDispatchToProps,
)(QueryToolDialog);

export default ConnectedDialog;
