import { Appearance } from '../../../account/interfaces';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { isDashletMode } from '../../../auth/dashlet.util';
import _ from 'lodash';
import URLs from '../../Urls';

export const getIsSystemDarkModeEnabled = state =>
  state?.main?.isSystemDarkModeEnabled;

const isValidAppearanceSetting = setting =>
  _.includes([Appearance.System, Appearance.Light, Appearance.Dark], setting);

export const getMangoDarkMode = () => {
  try {
    return (window as any).App?.utils?.isDarkMode();
  } catch {
    return;
  }
};

export const getUserAppearanceSetting = state => {
  const {
    userApperanceSetting,
    userApperanceOverride = _.get(URLs.getQueryParams(), 'appearance'),
  } = state?.main ?? {};
  if (isValidAppearanceSetting(userApperanceOverride)) {
    return userApperanceOverride;
  } else {
    return isValidAppearanceSetting(userApperanceSetting)
      ? userApperanceSetting
      : Appearance.System;
  }
};

export const useIsMobileOrDashletMode = () =>
  useSelector((state: any) => {
    const {
      main: { isMobile },
      dashlet: { isDashletMode: _isDashletMode },
    } = state ?? {};
    return isMobile || _isDashletMode;
  });

export const useIsMobileMode = () =>
  useSelector((state: any) => {
    const {
      main: { isMobile },
    } = state ?? {};

    return isMobile;
  });

export const getMobileMaxWidth = state => {
  let {
    main: { mobileMaxWidth },
    dashlet: { isDashletMode: _isDashletMode },
  } = state ?? {};
  mobileMaxWidth = _isDashletMode ? 10000 : mobileMaxWidth;
  return mobileMaxWidth;
};

export const getIsDarkModeEnabled = createSelector(
  [getIsSystemDarkModeEnabled, getUserAppearanceSetting],
  (isSystemDarkModeEnabled, userAppearanceSetting) => {
    if (isDashletMode()) {
      return getMangoDarkMode();
    } else {
      return (
        (isSystemDarkModeEnabled &&
          userAppearanceSetting === Appearance.System) ||
        userAppearanceSetting === Appearance.Dark
      );
    }
  },
);

export const useIsDarkModeEnabled = () => {
  const isDarkModeEnabled: boolean = useSelector(getIsDarkModeEnabled);
  return isDarkModeEnabled;
};

export const useIsAdvancedMode = () => {
  const isAdvancedMode: boolean = useSelector(
    (state: any) => state?.main?.advanced,
  );
  return isAdvancedMode;
};
