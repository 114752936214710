import { useSelector } from 'react-redux';
import { SlicerPanelContainer } from './slicer-panel.styles';
import { getSlicerNames } from '../../../common/redux/selectors/viz-selectors';
import { SlicerWidget } from '../slicer-widget';
import { AutoSizer } from 'react-virtualized';
import { memo, useCallback, useRef } from 'react';
import { isNil, min } from 'lodash';

const elementIsVisible = (el, container) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const {
    top: containerTop,
    left: containerLeft,
    bottom: containerBottom,
    right: containerRight,
  } = container.getBoundingClientRect();

  return (
    top >= containerTop &&
    left >= containerLeft &&
    bottom <= containerBottom &&
    right <= containerRight + Math.abs(container.offsetLeft)
  );
};

export const SlicerPanel = memo(() => {
  // We need this to be a ref and not state, otherwise the callbacks would
  // depend on state, which would be extremely messy.
  const panelRef = useRef(null);
  const openSlicerName = useRef(null);
  const lastSlicerElement = useRef(null);
  const slicerNames: string[] =
    useSelector(getSlicerNames as (state: any) => any) ?? [];

  const closeSlicer = useCallback(() => {
    if (lastSlicerElement?.current && panelRef?.current) {
      panelRef.current.style.overflowX = 'auto'; // make element scrollable. allow for below code to work

      const scrollLeft = min([
        lastSlicerElement.current.offsetLeft,
        -panelRef?.current?.offsetLeft,
      ]);

      panelRef.current.style.left = `0px`;
      panelRef.current.scrollLeft = scrollLeft;
    }
  }, []);

  const openSlicer = useCallback(
    (widgetWidth: number, containerWidth: number, hasSlicerOpen: boolean) => {
      if (!(lastSlicerElement?.current || panelRef?.current)) {
        return;
      }

      const offsetLeftMeasure = hasSlicerOpen
        ? -panelRef?.current?.offsetLeft
        : panelRef?.current?.scrollLeft;

      const isHangingLeft =
        lastSlicerElement.current.offsetLeft < offsetLeftMeasure;

      const isFullyVisible = elementIsVisible(
        lastSlicerElement?.current,
        panelRef?.current,
      );

      if (!isHangingLeft && isFullyVisible) {
        if (!hasSlicerOpen) {
          panelRef.current.style.left = `${-offsetLeftMeasure}px`;
        }

        panelRef.current.style.overflowX = 'visible';
        return;
      }

      let offsetLeft =
        lastSlicerElement.current.offsetLeft + widgetWidth - containerWidth;
      if (isHangingLeft) {
        offsetLeft = lastSlicerElement.current.offsetLeft;
      }

      panelRef.current.style.overflowX = 'visible';
      panelRef.current.style.left = `${-offsetLeft}px`;
    },
    [],
  );

  const onToggle = useCallback(
    (name, containerWidth, _isOpen, element, widgetWidth = 0) => {
      const hasSlicerOpen = !isNil(openSlicerName.current);

      if (_isOpen) {
        openSlicerName.current = name;
        lastSlicerElement.current = element;
      }

      if (openSlicerName.current === name) {
        if (!_isOpen) {
          // We only want to flag that this is closed if it is the
          // currently open one. Otherwise, it will have issues when
          // we close a widget by opening another (and the outside
          // click causes it to close).
          openSlicerName.current = null;
          closeSlicer();
        } else {
          openSlicer(widgetWidth, containerWidth, hasSlicerOpen);
        }
      }
    },
    [closeSlicer, openSlicer],
  );

  return (
    <AutoSizer>
      {({ width }) => {
        return (
          <SlicerPanelContainer
            id={'slicer-panel'}
            ref={panelRef}
            width={width}
          >
            {slicerNames.map(name => {
              return (
                <SlicerWidget
                  onToggle={(_isOpen, element, widgetWidth = 0) =>
                    onToggle(name, width, _isOpen, element, widgetWidth)
                  }
                  key={`slicer-${name}`}
                  name={name}
                />
              );
            })}
          </SlicerPanelContainer>
        );
      }}
    </AutoSizer>
  );
});
