import _ from 'lodash';

export function sortLegendData(querySorts, data) {
  const numLines = _(data)
    .map(d => _.get(d, 'info.linesData.length'))
    .max();
  const defaultSorts = _(0)
    .range(numLines)
    .map(lineNum => [
      item => {
        const res = _.get(item, `info.linesData[${lineNum}].sortValue`, -1);
        return res;
      },
      ['asc'],
    ])
    .value();
  const sortParams = _(querySorts)
    .filter(_.matches({ shelfName: 'Lines' }))
    .orderBy(['priority'], ['asc'])
    .map(({ direction, fieldName }) => {
      const boundaryVal = direction === 'asc' ? -1 : Infinity;
      const selector = val => {
        const { title, info: { linesData } = {} } = val;
        if (title) {
          return boundaryVal;
        }
        const sortVal = _(linesData)
          .chain()
          .find(_.matches({ attributeName: fieldName }))
          .get('sortValue', boundaryVal)
          .value();
        return sortVal;
      };
      return [selector, direction];
    })
    .concat(defaultSorts)
    .unzip()
    .value();
  return _.orderBy(data, ...sortParams);
}
