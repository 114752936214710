import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as Bootstrap from '@sugar-discover/react-bootstrap-wrapper';

export const Tabs = styled(Bootstrap.Tabs)(
  ({
    theme: {
      colors: {
        TabDefaultColor,
        CorvanaMediumBorder,
        TabHighlightColor,
        TabActiveColor,
      } = {},
    } = {},
  }) => css`
    .nav-tabs li a {
      border: none !important;
      background-color: transparent !important;
      padding: 10px 15px 2px 16px;
      color: ${TabDefaultColor};
      font-size: 14px;
    }
    .nav-tabs li.active {
      border-bottom: 2px solid ${TabHighlightColor};
      color: ${TabActiveColor};
    }

    .nav-tabs li.active a {
      color: ${TabActiveColor};
    }
    .nav-tabs li.disabled a {
      color: ${CorvanaMediumBorder};
    }
  `,
);
export const Tab = styled(Bootstrap.Tab)``;
