import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { compose, pure, withProps } from 'react-recompose';
import {
  ACCOUNT_SELECTORS,
  isAdmin,
} from '../common/redux/selectors/AccountSelectors';
import {
  DiscoverQueries,
  Monitors,
  PredefMonitors,
} from '../common/graphql/index';
import SectionLayout from '../common/hoc/SectionLayout';
import Discover from '../common/redux/actions/DiscoverActions';
import _, { get } from 'lodash';
import Util from '../common/Util';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Library from '../views/Library';
import MobileLibrary from '../views/MobileLibrary';
import { DiscoverTabs } from './discover-tabs';
import { VizRedirect } from './viz-redirect';
import { UpdateVizOptions } from '../common/graphql/util';
import { getIsReadOnlyUser } from '../common/redux/selectors/AccountSelectors';
import { isInDashletReportMode } from '../auth';
import { messages } from '../i18n';
import { SkeletonVizLoader } from '../common/loaders/skeleton-viz-loader';

const Discovery = props => {
  const {
    isMobile,
    match,
    displayDiscovery: _displayDiscovery,
    openDiscoveries,
    content: _content,
    openDiscovery,
    openVisualization: openViz,
    setDisplayDiscovery,
    loading,
  } = props;
  const [renderResult, setRenderResult] = useState(null);
  const renderOpen = useCallback(() => renderResult, [renderResult]);
  const openMonitor = useCallback(
    monitor => {
      openDiscovery(monitor);
    },
    [openDiscovery],
  );

  const openVisualization = useCallback(
    viz => {
      openViz(viz);
    },
    [openViz],
  );
  const displayDiscovery = match.params.openDiscoveryId;
  const hasDiscoveries = !_.isEmpty(openDiscoveries);
  const firstOpen = _.head(_.keys(openDiscoveries));
  const isOpen = !_.isNil(_.get(openDiscoveries, displayDiscovery));
  const content = useMemo(() => {
    const propsDiscovery = openDiscoveries[displayDiscovery];
    const content =
      _content?.find(d => d.id === displayDiscovery) ||
      (propsDiscovery ? propsDiscovery.present : null);
    return content;
  }, [_content, displayDiscovery, openDiscoveries]);
  useEffect(() => {
    // Get open discovery from url
    if (!displayDiscovery) {
      // see if we have it stored in redux
      if (_displayDiscovery) {
        setRenderResult(<VizRedirect vizId={_displayDiscovery} />);
        return;
      }

      if (hasDiscoveries) {
        // failing all select first open discovery
        // set the display discovery to the first one
        setDisplayDiscovery(firstOpen);
        setRenderResult(<VizRedirect vizId={firstOpen} />);
        return;
      }
      // we don't belong here
      setRenderResult(<Redirect to='/library' />);
      return;
    }
    // If the selected discovery isn't open, open it (deep link)
    if (displayDiscovery && !isOpen) {
      if (loading) {
        setRenderResult(<SkeletonVizLoader />);
        return;
      }
      if (!content) {
        setRenderResult(<Redirect to='/' />);
        return;
      }
      if (content.discoveryType === 'VISUALIZATION') {
        openVisualization(content);
      } else {
        openMonitor(content);
      }
      setRenderResult([]);
      return;
    } else if (displayDiscovery && displayDiscovery !== _displayDiscovery) {
      setDisplayDiscovery(_displayDiscovery || displayDiscovery);
    }

    setRenderResult(<DiscoverTabs />);
    return;
  }, [
    _displayDiscovery,
    content,
    displayDiscovery,
    firstOpen,
    hasDiscoveries,
    isOpen,
    loading,
    openMonitor,
    openVisualization,
    setDisplayDiscovery,
  ]);
  return (
    <div id='discover-content-panel'>
      <Switch>
        <Route path='/open/:openDiscoveryId?' render={renderOpen} />
        <Route
          path='/library'
          render={() => {
            if (isMobile) {
              return <MobileLibrary {...props} />;
            } else {
              return <Library {...props} />;
            }
          }}
        />
      </Switch>
    </div>
  );
};

const MonitorQuery = graphql(DiscoverQueries.DiscoveriesQuery, {
  skip: () => isInDashletReportMode(),
  props: (store: any) => {
    if (store.data.loading) {
      return { discoveriesLoading: true };
    }
    let content = [];
    if (store.data.monitors) {
      content = [
        ...store.data.monitors.map(m => {
          return { ...m, discoveryType: 'MONITOR', description: '' };
        }),
        ...store.data.visualizations.map(v => {
          const description = _.find(v.options, { key: 'description' });
          const dataset = _.find(store.data.datasets, { id: v.datasetId });
          return {
            ...v,
            discoveryType: 'VISUALIZATION',
            dataset,
            description: !_.isNil(description) ? description.value : '',
            datasetName: dataset?.name,
          };
        }),
      ];
    }
    return { content, discoveriesLoading: false };
  },
});

const DeleteMonitorMutation = PredefMonitors.DeleteMonitor(({ mutate }) => ({
  deleteMonitor(monitorId) {
    return mutate({
      variables: {
        monitor: monitorId,
      },
    });
  },
}));

const UpdateMonitorMutation = graphql(Monitors.UpdateMonitor, {
  options: {
    // Update Monitors query, we delete opportunistically but this makes sure the local state is consistent
    refetchQueries: [
      {
        query: Monitors.MonitorQuery,
      },
    ],
  },
  props: ({ mutate }) => ({
    updateMonitor(monitor) {
      const clearTypename = m => {
        const copy = { ...m };
        delete copy.__typename;
        return copy;
      };
      return mutate({
        variables: {
          monitorReq: {
            id: monitor.id,
            name: monitor.name,
            query: {
              datasetId: monitor.dataset.id,
              attributeNames: monitor.query.attributeNames,
              measures: monitor.query.measures.map(clearTypename),
              filters: monitor.query.filters.map(clearTypename),
              sorts: monitor.query.sorts.map(clearTypename),
            },
            measure: monitor.measure,
            threshold: monitor.threshold,
          },
        },
      });
    },
  }),
});
const DeleteVizMutation = graphql(DiscoverQueries.DeleteVisualizationMutation, {
  options: {
    update: (store, { data: { deleteVisualization } }: any) => {
      // https://www.apollographql.com/docs/react/caching/cache-interaction/#example-deleting-a-field-from-a-cached-object
      store.modify({
        id: store.identify(deleteVisualization),
        fields: (_fields, { DELETE }) => DELETE,
      });
    },
  },
  props: ({ ownProps, mutate }: any) =>
    ({
      deleteVisualization(id) {
        mutate({
          variables: {
            id,
          },
        })
          .then(() => {
            // Close discovery if in open discoveries
            if (get(ownProps.openDiscoveries, id)) {
              ownProps.closeDeletedDiscovery(id);
            }
          })
          .catch(error => {
            console.log('Error deleting discovery.', error);
          });
      },
    } as any),
});

const UpdateVizMutation = graphql(DiscoverQueries.UpdateVisualizationMutation, {
  options: UpdateVizOptions,
  props: ({ ownProps, mutate }: any) =>
    ({
      updateVisualization(viz, updateKey) {
        mutate({
          variables: {
            viz: Util.removeTypeNameRecursively(
              _.pick(viz, [
                'id',
                'name',
                'layout',
                'options',
                'private',
                'tags',
              ]),
            ),
          },
        })
          .then(({ data }) => {
            // Update open discovery
            const open = ownProps.openDiscoveries[viz.id];
            if (!_.isNil(open)) {
              if (updateKey === 'name') {
                ownProps.updateName(viz, viz.name);
              } else if (updateKey === 'description') {
                const entry = _.find(data.updateVisualization.options, {
                  key: 'description',
                });
                ownProps.updateVizSetting(viz.id, 'description', entry.value);
              } else if (updateKey === 'isPrivate') {
                ownProps.setVizAccess(
                  viz.id,
                  data.updateVisualization.isPrivate ? 'private' : 'public',
                );
              }
            }
          })
          .catch(error => {
            ownProps.setErrorToast(error.message);
          });
      },
    } as any),
});

const mapStateToProps = state => {
  const allOpen = state.discover.openDiscoveries;
  const activeId = state.discover.displayDiscovery;

  // add isDirty of the active viz as a prop to trigger a refresh of the widget if it changes (hide/show the blue indicator on viz save)
  let isDirty = false;
  if (!_.isEmpty(allOpen) && !_.isNil(allOpen[activeId])) {
    if (_.isNil(allOpen[activeId].present)) {
      console.warn(
        'Missing present state for active viz',
        activeId,
        allOpen[activeId],
      );
      isDirty = false;
    } else {
      isDirty = allOpen[activeId].present.dirty;
    }
  }
  return {
    openDiscoveries: allOpen,
    displayDiscovery: activeId,
    advanced: state.main.advanced,
    isMobile: state.main.isMobile,
    isActiveDirty: isDirty,
    pinnedDiscoveriesLoading: state.discover.pinnedDiscoveriesLoading,
    isAdmin: isAdmin(state.account),
    isReadOnly: getIsReadOnlyUser(state),
    currentUser: ACCOUNT_SELECTORS.getCurrentUser(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setErrorToast: msg => {
      dispatch(Discover.setSaveError(msg));
    },
    setShowAddMonitorDialog: value => {
      dispatch(Discover.setShowAddMonitorDialog(value));
    },
    openDiscovery: discovery => {
      dispatch(Discover.openDiscovery(discovery));
    },
    openVisualization: discovery => {
      dispatch(Discover.openVizualization(discovery, ownProps.history));
    },
    closeDeletedDiscovery: id => {
      dispatch(Discover.closeDeletedDiscovery(id));
    },
    showNewViz: () => {
      ownProps.history.push('/newviz');
    },
    updateName: (viz, newName) => {
      dispatch(Discover.updateVizName(viz.id, newName));
    },
    updateVizSetting: (id, key, value) => {
      dispatch(Discover.updateVizSetting(id, key, value));
    },
    setDisplayDiscovery: discoveryId => {
      // location provided by withRouter
      const { state = {} } = ownProps.location;
      dispatch(Discover.setDisplayDiscovery(discoveryId, state));
    },
    setVizAccess: (discoveryId, access) => {
      dispatch(Discover.setVizAccess(discoveryId, access));
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  connect(SectionLayout.mapStateToProps),
  SectionLayout('discover', messages.nonTranslated.discover, []),
  pure,
  DeleteMonitorMutation,
  MonitorQuery,
  UpdateMonitorMutation,
  DeleteVizMutation,
  UpdateVizMutation,
  withProps((props: any) => {
    return {
      loading: props.discoveriesLoading || props.pinnedDiscoveriesLoading,
    };
  }),
)(Discovery);
