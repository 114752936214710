import gql from 'graphql-tag';

const FeedbackQueries = {
  getList: gql`
    query feedbackList($monitorId: String!) {
      feedbackList(monitorId: $monitorId) {
        id
        value
        creator
      }
    }
  `,
  get: gql`
    query feedback($anomalyId: String!) {
      feedback(anomalyId: $anomalyId) {
        id
        value
        creator
        applyTimestamp
      }
    }
  `,

  submit: gql`
    mutation submitFeedback(
      $anomalyId: String!
      $monitorId: String!
      $value: FeedbackValue!
      $timestamp: Long!
    ) {
      submitFeedback(
        anomalyId: $anomalyId
        monitorId: $monitorId
        value: $value
        timestamp: $timestamp
      ) {
        id
        value
        creator
        applyTimestamp
      }
    }
  `,
};
export default FeedbackQueries;
