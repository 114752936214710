import {
  ReactNode,
  useContext,
  useEffect,
  useState,
  createContext,
} from 'react';
import { useGetDatasetAnnotations, useGetSugarCrmSchemas } from './graphql';
import {
  IDatasetSettingsContext,
  IDatasetSettingsProviderProps,
  IDatasetSettings,
} from './edit-dataset-settings.interfaces';
import { sanitizedDatasetAnnotations } from './edit-dataset-settings.util';
import { isNil, noop } from 'lodash';
import { parseAndCleanDatasetJSON } from '../../datasets/utils';

type Props = {
  children: ReactNode;
};

export const DatasetSettingsContext = createContext<IDatasetSettingsContext>({
  sugarCrmSchema: null,
  dryRunReport: null,
  dataset: null,
  datasetSettings: null,
  setDatasetSettings: noop,
});

export const DatasetSettingsContextProvider = ({
  dataset,
  children,
}: IDatasetSettingsProviderProps & Props) => {
  const { sugarCrmSchema } = useGetSugarCrmSchemas(dataset?.id);
  const { datasetCustomSettings } = useGetDatasetAnnotations(dataset?.id);

  const [datasetSettings, setDatasetSettings] = useState<IDatasetSettings>({
    datasetId: dataset?.id,
    annotations: sanitizedDatasetAnnotations(dataset),
    datasetAnnotations: datasetCustomSettings?.datasetAnnotations,
  });

  useEffect(() => {
    // graphql data may have not been available during construction
    if (
      isNil(datasetSettings.datasetAnnotations) &&
      !isNil(datasetCustomSettings?.datasetAnnotations)
    ) {
      const datasetJson = parseAndCleanDatasetJSON(
        datasetCustomSettings?.datasetAnnotations.datasetJson,
      );

      const datasetAnnotations = {
        ...datasetCustomSettings.datasetAnnotations,
        datasetJson,
      };

      setDatasetSettings(prevState => ({
        ...prevState,
        datasetAnnotations,
      }));
    }
  }, [
    datasetSettings.datasetAnnotations,
    datasetCustomSettings.datasetAnnotations,
  ]);

  const initialState = {
    dataset,
    sugarCrmSchema,
    datasetSettings,
    setDatasetSettings,
  };

  return (
    <DatasetSettingsContext.Provider value={initialState}>
      {children}
    </DatasetSettingsContext.Provider>
  );
};

export const useDatasetSettingsContext = () =>
  useContext(DatasetSettingsContext);
