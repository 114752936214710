import styled from '@emotion/styled';
import { css } from '@emotion/react';
import ListItemButton from '@mui/material/ListItemButton';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import { useDiscoverTheme } from '../../common/emotion/theme';

export const PaperStyled = styled(Paper)(() => {
  const {
    darkMode,
    colors: { MediumBorder, MediumBlue, PivotWhite },
  } = useDiscoverTheme();

  return css`
    box-shadow: none;
    border: 1px solid ${MediumBorder};
    background-color: ${darkMode ? MediumBlue : PivotWhite};
    background-image: unset;
  `;
});

export const ListItemButtonStyled = styled(ListItemButton)`
  font-size: 0.75rem;
  font-weight: 300;
  padding: 4px 8px 4px 14px;
`;

export const ListStyled = styled(List)<{ isShifted: boolean }>`
  > li > div[role='button'] {
    padding-left: ${props => props.isShifted && '24px'};
  }
`;
