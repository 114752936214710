import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DefaultFooter from './DefaultFooter';
import _ from 'lodash';
import { messages } from '../../../i18n';

const UnconnectedActivityFeedItem = ({
  isMobile,
  isDashletMode,
  referenceId,
  feedItem,
  monitorEvent,
  onHeightChanged,
  last,
  condensed,
  className,
  children,
  inverted,
}) => {
  // Get the specific children that we know we need
  const kids = Children.toArray(children).reduce((acc, child) => {
    // add the onHeightChange props to all of the children
    acc[child.type.role] = cloneElement(child, {
      onHeightChanged,
    });
    return acc;
  }, {});

  const footer = !_.isNil(kids.ActivityFeedItemFooter) ? (
    kids.ActivityFeedItemFooter
  ) : (
    <DefaultFooter
      {...{
        isMobile,
        isDashletMode,
        feedItem,
        monitorEvent,
        referenceId,
        condensed,
      }}
    />
  );
  return (
    <div
      className={`feed-row ${last ? 'last' : ''} ${
        inverted ? ' inverted' : ''
      } ${className}`}
    >
      <div className='feed-row-left'>
        <div className='feed-row-body'>
          {!condensed && kids.ActivityFeedItemHeader}
          {!condensed &&
            !_.isNil(kids.ActivityFeedItemBody) &&
            kids.ActivityFeedItemBody}
        </div>
        {kids.ActivityWidget}
      </div>
      {!isDashletMode && footer}
    </div>
  );
};
const ActivityFeedItem = connect(state => {
  return {
    isMobile: state.main.isMobile,
    isDashletMode: !!state.dashlet.isDashletMode,
  };
})(UnconnectedActivityFeedItem);

ActivityFeedItem.propTypes = {
  last: PropTypes.bool,
  condensed: PropTypes.bool,
  isDashletMode: PropTypes.bool,
  className: PropTypes.string,
  children: (props, propName, componentName) => {
    const children = props[propName];
    const requiredTypes = ['ActivityFeedItemHeader', 'ActivityWidget'];
    const supportedTypes = [
      ...requiredTypes,
      'ActivityFeedItemFooter',
      'ActivityFeedItemBody',
    ];
    const kids = Children.toArray(children).reduce((acc, child) => {
      acc[child.type.role] = child;
      return acc;
    }, {});
    const unsupportedChildren = Object.keys(kids).filter(k =>
      _.includes(supportedTypes, k),
    );
    if (_.isNil(kids.ActivityFeedItemHeader) || _.isNil(kids.ActivityWidget)) {
      return new Error(
        messages.formatString(
          messages.insightFeed.feedItemErrorMissingChildrenTypes,
          componentName,
          requiredTypes.join(' and '),
        ),
      );
    } else if (!_.isEmpty(unsupportedChildren)) {
      return new Error(
        messages.formatString(
          messages.insightFeed.feedItemErrorUnsupportedChildren,
          componentName,
          supportedTypes.join(', '),
        ),
      );
    }
  },
};
export default ActivityFeedItem;
