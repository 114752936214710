import { PureComponent } from 'react';
import classnames from 'classnames';
import { Form, FormGroup } from '@sugar-discover/react-bootstrap-wrapper';
import _ from 'lodash';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { HANDLE_NULL_AS } from '../../common/Constants';
import Discover from '../../common/redux/actions/DiscoverActions';
import { VIZ_SELECTORS } from '../../common/redux/selectors/viz-selectors';
import { messages } from '../../i18n';
import { DisableableDiv } from '../../common/emotion/utility-components.styles';
import {
  InfoLabel,
  StyledRadio,
  StyledSpan,
} from './null-handling-setting.styles';
class NullHandlingSetting extends PureComponent {
  constructor(props) {
    super(props);
    const selected = _.get(props, 'selected', HANDLE_NULL_AS.MISSING);
    this.state = {
      selected,
    };
  }

  onSelection(selected) {
    console.log(`${selected} selected`);
    this.setState({ selected });
    this.props.setNullHandlingSetting(selected, this.props.vizId);
  }

  render() {
    const missing = this.state.selected === HANDLE_NULL_AS.MISSING;
    return (
      <div
        className={classnames('null-handling-settings', this.props.className)}
      >
        <div className='viz-formatting-header'>
          <DisableableDiv className='viz-formatting-header-left'>
            {messages.formatting.nullValues}
          </DisableableDiv>
        </div>
        <div>
          <Form>
            <FormGroup className='viz-formatting-form-group'>
              <InfoLabel>{messages.formatting.treatNullsAs}</InfoLabel>
              <StyledRadio
                checked={missing}
                name={'nullHandling'}
                value={HANDLE_NULL_AS.MISSING}
                onChange={() => this.onSelection(HANDLE_NULL_AS.MISSING)}
              >
                <StyledSpan>{messages.formatting.missing}</StyledSpan>
              </StyledRadio>
              <StyledRadio
                checked={!missing}
                name={'nullHandling'}
                value={HANDLE_NULL_AS.ZERO}
                onChange={() => this.onSelection(HANDLE_NULL_AS.ZERO)}
              >
                <StyledSpan>{messages.formatting.zero}</StyledSpan>
              </StyledRadio>
            </FormGroup>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const options = VIZ_SELECTORS.getActiveVizOptions(state, ownProps);
  return {
    vizId: VIZ_SELECTORS.getDiscoveryId(state, ownProps),
    selected: _.get(options, 'nullHandling', HANDLE_NULL_AS.MISSING),
  };
};

const mapDispatchToProps = dispatch => ({
  setNullHandlingSetting: (value, vizId) => {
    dispatch(
      Discover.setSettingForViz({
        id: vizId,
        setting: 'nullHandling',
        value,
      }),
    );
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NullHandlingSetting,
);
