import FieldGroupPill from '../FieldGroupPill';
import { EditIcon } from '../../icons';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { LightFontWeight } from '../../components/ui/fonts';
import { font } from '../../common/emotion/mixins';
import FieldPill from '../FieldPill';

export const fieldListItemStyled = css`
  padding-left: 5px;
`;

// may not be needed anymore
export const FieldPillStyled = styled(FieldPill)`
  ${fieldListItemStyled}
  margin-left: 10px;

  .viz-field-pill {
    margin-left: 0;
  }
`;

// may not be needed anymore
export const FieldPillGroupStyled = styled(FieldGroupPill)`
  ${fieldListItemStyled}
`;

export const EditIconStyled = styled(EditIcon)`
  margin: 0 0.5em;
`;

export const VizFieldSearchContainer = styled.div(
  ({ theme: { colors: { PanelBorder, Strokes, ContentText } = {} } = {} }) => `
  position: relative;
  background: #fff;
  border: 1px solid ${PanelBorder};
  border-bottom: none;

  input {
    ${font({ size: '12px', weight: LightFontWeight })}
    margin-bottom: 0px;
    border-radius: 0px;
    box-shadow: none;
    padding-right: 24px;
    padding-left: 30px;
    border: none;
    width: 100%;
    padding-bottom: 3px;
  }

  input:focus {
    border-color: ${Strokes};
    box-shadow: none;
    color: ${ContentText};
  }

  hr {
    position: absolute;
    bottom: 5px;
    right: 0px;
    left: 0px;
    margin: 0px 8px;
  }

  .sicon-search {
    padding-bottom: 3px;
  }
`,
);
