import styled from '@emotion/styled';
import { Disableable, DisableableRadio } from '../../common/emotion';
import * as Fonts from '../../components/ui/fonts';

export const InfoLabel = Disableable(styled.div`
  font-size: 12px;
  font-weight: ${Fonts.RegularFontWeight};
`);

export const StyledRadio = styled(DisableableRadio)`
  margin: 0;

  input[type='radio'] {
    position: relative;
    margin-left: 0;
    margin-right: 8px;
  }
`;

export const StyledSpan = Disableable(styled.span`
  margin-top: 4px;
`);
