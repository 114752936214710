import Const from '../actions/ActionConst';
import { composeResetReducer } from 'redux-reset-store';
import { isNil } from 'lodash';
import { setTimeZone } from '../../../common/Moment';
import { IUserInfo } from '../../../account/interfaces';
import { setCurrentUser } from '../../Constants';

const initialState: IAccountReducer = {
  currentUser: {
    id: '',
    email: '',
    userName: '',
    fullName: '',
    profileImage: '',
    profileImageURL: '',
    tenant: '',
    defaultTenantId: '',
    roles: [],
    preferences: {},
    isServiceAccountEnabled: false,
  },
  isChangingPassword: false,
  passwordChanged: false,
  passwordChangeError: false,
};

export interface IAccountReducer {
  currentUser: IUserInfo;
  isChangingPassword?: boolean;
  passwordChanged?: boolean;
  shouldLogoutOtherSessions?: boolean;
  passwordChangeError?: boolean;
}

const reducer = (state = initialState, action): IAccountReducer => {
  const user = action.currentUser;
  switch (action.type) {
    case Const.Account.SET_CURRENT_USER:
      setTimeZone(user.timezone);
      return {
        ...state,
        currentUser: {
          id: user.id,
          email: user.email,
          userName: user.userName,
          fullName: user.fullName === 'Unknown' ? '' : user.fullName,
          profileImage: user.profileImage,
          profileImageURL: !isNil(user.profileImageURL)
            ? user.profileImageURL
            : state.currentUser.profileImageURL,
          roles: user.roles,
          tenant: user.tenant,
          preferences: user.preferences,
          i18nPrefs: user.i18nPrefs,
          timezone: user.timezone,
          idm: user.idm,
          locale: user.locale,
          pendoAccountId: user.pendoAccountId,
          pendoVisitorId: user.pendoVisitorId,
          isServiceAccountEnabled: user.isServiceAccountEnabled,
        },
      };
    case Const.Account.SET_CHANGING_PASSWORD:
      return {
        ...state,
        isChangingPassword: action.status,
      };
    case Const.Account.SET_PASSWORD_CHANGED:
      return {
        ...state,
        isChangingPassword: false,
        passwordChanged: action.passwordChanged,
      };
    case Const.Account.SET_PASSWORD_CHANGE_ERROR:
      return {
        ...state,
        isChangingPassword: false,
        passwordChanged: false,
        passwordChangeError: action.passwordChangeError,
      };
    case Const.Account.SET_CURRENT_USER_PREFERENCES: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          preferences: {
            ...action.userPreferences,
          },
        },
      };
    }
    case Const.Account.SHOULD_LOGOUT_OTHER_SESSIONS: {
      return {
        ...state,
        shouldLogoutOtherSessions: action.shouldLogoutOtherSessions,
      };
    }
  }

  return state;
};

export default composeResetReducer(
  (state = initialState, action) => {
    state = reducer(state, action);
    setCurrentUser(state?.currentUser);
    return state;
  },
  initialState,
  undefined,
);
