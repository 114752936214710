import { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { IDisableClickMenuItem } from './disable-click-menu-item.interface';

export const DisableClickMenuItem: FC<IDisableClickMenuItem> = ({
  disabled,
  onClick,
  children,
  key = undefined,
  title = undefined,
  id = undefined,
}) => {
  const dismissClick = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <MenuItem
      id={id}
      disabled={disabled}
      key={key}
      title={title}
      onClick={e => (disabled ? dismissClick(e) : onClick())}
    >
      {children}
    </MenuItem>
  );
};
