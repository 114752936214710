import {
  compose,
  onlyUpdateForKeys,
  pure,
  withPropsOnChange,
} from 'react-recompose';
import BarLineChartUtils from '../BarLineChartUtils';
import {
  BaseCartesianChart,
  ShouldEnableReportLinkHOC,
} from '../base-cartesian-chart';
import { connect } from 'react-redux';
import BarPlot from '../plots/BarPlot';
import LinePlot from '../Line/LinePlot';
import NoDataIfHOC from '../NoDataIfHOC';
import { LineChartUtils } from '../LineChartUtils';
import { isEmpty } from 'lodash';

class BarLine extends BaseCartesianChart {
  constructor(props) {
    super(props, [
      { shelf: 'COLUMNS', Plot: BarPlot, dataId: 'barData' },
      { shelf: 'LINES', Plot: LinePlot, dataId: 'lineData' },
    ]);
  }
}

export default compose(
  pure,
  ShouldEnableReportLinkHOC,
  connect(
    BaseCartesianChart.mapStateToProps,
    BaseCartesianChart.mapDispatchToProps,
  ),
  withPropsOnChange(['queryResults', 'i18nPrefs'], props => {
    const barData = BarLineChartUtils.transformResultToBar(
      props.queryResults,
      props.viz,
      props.i18nPrefs,
    );

    let lineData = [];

    if (!isEmpty(props.viz.layout.LINES)) {
      // if there are line metrics defined, make sure we process & sort the line data
      lineData = BarLineChartUtils.transformResultToLine(
        props.queryResults,
        props.viz,
        undefined,
        props.i18nPrefs,
      );
      lineData = LineChartUtils.sortData(
        lineData,
        props.viz,
        props.queryResults.executeQuery.querySort,
        'LINES',
        'XAXIS',
        'NONE',
      );
    }

    return { barData, lineData };
  }),
  onlyUpdateForKeys([
    'barData',
    'lineData',
    'height',
    'width',
    'id',
    'alignYAxesAtZero',
    'enableReportLink',
  ]),
  NoDataIfHOC(
    props => props.barData.length === 0 && props.lineData.LINES.length === 0,
  ),
)(BarLine);
