import { ReactNode, FC, useState } from 'react';
import { isFunction } from 'lodash';
import { ISugarIconProps, KebabIcon } from '../../icons';
import Menu from '@mui/material/Menu';
import { KabobIconContainerStyled } from './icon-dropdown.styles';
import { PopoverOrigin } from '@mui/material/Popover/Popover';

interface IconDropdownProps {
  id?: string;
  children: ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  iconSize?: number;
  IconComponent?: FC<Omit<ISugarIconProps, 'icon'> & { isOpen?: boolean }>;
  disablePortal?: boolean;
  slotProps?: any;
}

export const IconDropdown = ({
  id = 'kabob-dropdown',
  children,
  onOpen,
  onClose,
  iconSize = 16,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  IconComponent = KebabIcon,
  disablePortal = false,
  slotProps,
}: IconDropdownProps) => {
  const [open, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setIsOpen(false);
    if (isFunction(onClose)) {
      onClose();
    }
  };
  const handleOpen = () => {
    setIsOpen(true);
    if (isFunction(onOpen)) {
      onOpen();
    }
  };
  const handleToggle = e => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(anchorEl ? null : e.currentTarget);
    const toggledVal = !open;
    if (toggledVal) {
      handleOpen();
    } else {
      handleClose();
    }
  };

  return (
    <>
      <KabobIconContainerStyled
        key={'kabob-dropdown-control'}
        className={`kabob-dropdown ${open ? 'active' : 'inactive'}`}
        aria-describedby={id}
        onClick={handleToggle}
        role={'listbox'}
      >
        <IconComponent isOpen={open} hover size={iconSize} />
      </KabobIconContainerStyled>
      <Menu
        key={'kabob-dropdown-menu'}
        className={'kabob-dropdown-menu'}
        onClick={e => {
          e.stopPropagation();
          handleToggle(e);
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disablePortal={disablePortal}
        slotProps={slotProps}
      >
        {children}
      </Menu>
    </>
  );
};
