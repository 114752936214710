import { Component } from 'react';

import { PrimaryButton } from '../../ui';
import styled from '@emotion/styled';
import _, { filter, isEmpty, noop, map } from 'lodash';
import { Query } from '@apollo/client/react/components';
import {
  FlexRight,
  FlexSpaced,
  IconArea,
  SubTitleBar,
} from '../../components/ui';
import { TitleText } from '../../components/ui/text';
import { TextSearchField } from '../../components/ui/form';
import { RefreshIcon } from '../../components/ui/icons';
import { Tab, Tabs } from '../../components/ui/tabs';
import UITable from '../../components/UITable';
import AdminQueries from '../../common/graphql/AdminQueries';
import ProfileImageComponent from '../../components/ProfileImage';
import SearchCollection from '../../common/SearchCollection';
import { assignableRoles } from '../../common/Util';

import { messages } from '../../i18n';
import { SelectDropdown, SelectItem } from '../../ui/dropdowns/select-dropdown';

/*
  component:Administration
*/

const PANEL_ACTIVE = 'Active';

export const ContentContainer = styled.div`
  margin: 0 20px;
`;

const ContentContainerRoot = styled(ContentContainer)`
  margin: 0;
  height: 100%;
`;

const Root = styled.div`
  height: 100%;
`;

class UnconnectedAdministrationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      selectedUser: undefined,
      selectedType: undefined,
      showTab: 1,
      editUser: undefined,
    };

    this.refreshQuery = this.refreshQuery.bind(this);
  }

  changeSearch(search) {
    this.setState(() => {
      return { search };
    });
  }

  refreshQuery() {
    this.refetch();
  }

  switchToTab(key) {
    this.setState(() => {
      return {
        showTab: key,
      };
    });
  }

  /**
   * Opens a new tab to Cloud Settings
   */
  goToCloudSettings() {
    const win = window.open(this.props.idmAdminUrl, '_blank');
    win.focus();
  }

  /**
   * Calls the Sync Users graphql call
   */
  callSyncUsers() {
    this.props.syncUsers();
  }

  onRoleChange(rowData, newRole) {
    const updatedUser = {
      ...rowData,
      roles: [newRole],
    };
    this.props.updateUser(updatedUser);
  }

  render() {
    return (
      <Query query={AdminQueries.GetUsersQuery} pollInterval={5000}>
        {({ data, refetch }) => {
          this.refetch = refetch;
          const resultList = data ? data.getUsers || [] : [];
          const options = {
            keys: ['fullName', 'title', 'email'],
          };
          const searcher = new SearchCollection(resultList, options);
          const list =
            !this.state.search || this.state.search === ''
              ? resultList
              : searcher.search(this.state.search);

          const current = filter(list, { status: PANEL_ACTIVE });

          let buttonsOrManageUsers = null;
          // if this is an IDM Instance, and the user is also an IdmAdmin show the manage users buttons
          // if the user is not an IDM admin then do not show any buttons
          if (this.props.isIdmAdministrator) {
            buttonsOrManageUsers = [
              <PrimaryButton
                key={`admin-cloud-settings-button`}
                className=''
                onClick={() => this.goToCloudSettings()}
                sx={{ marginRight: '0.5rem' }}
              >
                {messages.formatString(
                  messages.administration.manageUsersInCloudSettingsButton,
                  messages.nonTranslated.cloudSettings,
                )}
              </PrimaryButton>,
              <IconArea key={`admin-icon-areas`}>
                <RefreshIcon
                  id='syncUsersBtn'
                  onClick={() => {
                    this.callSyncUsers();
                  }}
                />
              </IconArea>,
            ];
          }

          return (
            <Root className='Administration'>
              <ContentContainerRoot>
                <ContentContainer>
                  <SubTitleBar>
                    <TitleText>
                      {messages.administration.manageUsersTitle}
                    </TitleText>
                  </SubTitleBar>
                  <FlexSpaced>
                    <TextSearchField
                      placeholder={
                        messages.administration.searchUsersPlaceholder
                      }
                      onChange={this.changeSearch.bind(this)}
                    />
                    <FlexRight>{buttonsOrManageUsers}</FlexRight>
                  </FlexSpaced>
                </ContentContainer>
                <Tabs
                  id='admin-tabs'
                  activeKey={this.state.showTab}
                  onSelect={key => this.switchToTab(key)}
                >
                  <Tab
                    eventKey={1}
                    title={messages.formatString(
                      messages.administration.currentUsersTab,
                      current.length,
                    )}
                    disabled={current.length <= 0}
                  >
                    {renderTable({
                      props: this.props,
                      list: current,
                      type: PANEL_ACTIVE,
                      onRoleChange: this.onRoleChange.bind(this),
                    })}
                  </Tab>
                </Tabs>
              </ContentContainerRoot>
            </Root>
          );
        }}
      </Query>
    );
  }
}

UnconnectedAdministrationComponent.propTypes = {};

UnconnectedAdministrationComponent.defaultProps = {
  showToast: noop,
  closeToast: noop,
};

export default UnconnectedAdministrationComponent;

const ProfileImage = styled(ProfileImageComponent)`
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  border-radius: 20px;
  margin-right: 8px;
  margin-right: 8px;
`;
function renderTable({ list, type, onRoleChange }) {
  return (
    <UITable
      data={list}
      domain='administration'
      rootStyle={{ height: `calc(100vh - 265px)` }}
    >
      {({ Column, HeaderRendererWithSort, HorizontalCellInteractive }) => {
        const HorizontalCell = HorizontalCellInteractive;
        return [
          <Column
            label={messages.administration.tableNameColumn}
            key='fullName'
            headerRenderer={HeaderRendererWithSort}
            cellRenderer={({ rowData }) => {
              let profileImageUrl;
              try {
                if (!isEmpty(rowData.profileImage)) {
                  // get the most recent version of the profile image
                  profileImageUrl = `/api/image/${rowData.email}/profile.jpg?${rowData.lastModifiedDate}`;
                }
              } catch (e) {
                profileImageUrl = undefined;
              }

              return (
                <HorizontalCell>
                  <ProfileImage src={profileImageUrl} />
                  {rowData.fullName}
                </HorizontalCell>
              );
            }}
            dataKey='fullName'
            width={260}
          />,
          <Column
            label={messages.administration.tableEmailColumn}
            key='email'
            headerRenderer={HeaderRendererWithSort}
            cellRenderer={({ rowData }) => {
              return <HorizontalCell>{rowData.email}</HorizontalCell>;
            }}
            dataKey='email'
            width={300}
          />,
          <Column
            label={messages.administration.tableTitleColumn}
            key='title'
            headerRenderer={HeaderRendererWithSort}
            cellRenderer={({ rowData }) => {
              return <HorizontalCell>{rowData.title}</HorizontalCell>;
            }}
            dataKey='title'
            width={260}
          />,
          <Column
            label={messages.administration.tableRoleColumn}
            key='role'
            cellRenderer={({ rowData }) => {
              return (
                <HorizontalCell style={{ containerType: 'normal' }}>
                  <SelectDropdown
                    title={
                      messages.nonTranslated.idmUserRoles[_.head(rowData.roles)]
                    }
                    headerSx={{ width: '100%' }}
                  >
                    {map(assignableRoles, role => (
                      <SelectItem onClick={() => onRoleChange(rowData, role)}>
                        {messages.nonTranslated.idmUserRoles[role]}
                      </SelectItem>
                    ))}
                  </SelectDropdown>
                </HorizontalCell>
              );
            }}
            dataKey='role'
            width={160}
            disableSort
          />,
          type === null,
          <Column
            label=''
            key='actions'
            dataKey='actions'
            width={160}
            disableSort
          />,
        ];
      }}
    </UITable>
  );
}
