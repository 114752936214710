import { Component } from 'react';
import { connect } from 'react-redux';
import { VisualizationInsights } from '../visualization-insights';
import HeaderNavigation from '../../components/HeaderNavigation';
import SlideInOut from '../../components/SlideInOut';
import Discover from '../../common/redux/actions/DiscoverActions';
import { Labels } from '../../common/Constants';
import { isNil } from 'lodash';
import { messages } from '../../i18n';
import { compose, withHandlers, withProps, withState } from 'react-recompose';
import { URLs } from '../../common/Urls';
import _ from 'lodash';

class UnconnectedMobileInsights extends Component<{
  setPanelDetail;
  selectedDiscovery;
  onBack;
  buttonText;
  show;
}> {
  componentDidMount() {
    this.props.setPanelDetail();
  }

  render() {
    const { selectedDiscovery, onBack, buttonText, show } = this.props;
    return (
      <SlideInOut show={show}>
        <div className={'mobile-insights'}>
          <HeaderNavigation
            title={_.get(
              messages,
              Labels.DISCOVERY_INSIGHTS,
              Labels.DISCOVERY_INSIGHTS,
            )}
            left={({ LinkButton, Chevron }) => {
              return (
                <LinkButton onClick={onBack}>
                  <Chevron /> {buttonText}
                </LinkButton>
              );
            }}
          />
          {selectedDiscovery?.hasInsights && (
            <VisualizationInsights selectedDiscovery={selectedDiscovery} />
          )}
          {(isNil(selectedDiscovery) || !selectedDiscovery.hasInsights) && (
            <div className={'no-insights'}>{messages.noInsightsAvailable}</div>
          )}
        </div>
      </SlideInOut>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setPanelDetail: id => {
    dispatch(Discover.setPanelDetail(id, Labels.DISCOVERY_INSIGHTS));
  },
});

const mapStateToProps = (state, ownProps) => {
  const openDiscovery =
    state.discover.openDiscoveries[
      ownProps?.match?.params?.vizId || ownProps.vizId
    ];
  const { isDashletMode } = state.dashlet;
  return {
    isDashletMode,
    selectedDiscovery: openDiscovery?.present ?? null,
  };
};
export const MobileInsights = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps((ownProps: any) => {
    const buttonText = !isNil(ownProps.match?.params?.backText)
      ? ownProps.match?.params?.backText
      : ownProps.backText || messages.back;
    return {
      buttonText,
    };
  }),
  withState('show', 'setShow', true),
  withHandlers({
    setPanelDetail: ({ setPanelDetail, selectedDiscovery }) => () => {
      const id = selectedDiscovery?.id;
      if (id) {
        setPanelDetail(id);
      }
    },
    onBack: ({ setShow, isDashletMode, onClose }) => () => {
      // setting the show prop to false will unmount the stuff that is animated, triggering the 'leave' portion of the transition
      setShow(false);
      if (!isDashletMode) {
        setTimeout(() => URLs.goToLastOrHome(), 150);
      }
      if (_.isFunction(onClose)) {
        onClose();
      }
      // let the transition have a beat to do it's thing before we move on
    },
  }),
)(UnconnectedMobileInsights as any) as any;
