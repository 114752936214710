import { Component } from 'react';
import { Nav, NavItem, Tab } from '@sugar-discover/react-bootstrap-wrapper';
import SelectItemsSvg from '../../../images/sdd/icon_select_items.svg';
import SetConditionSvg from '../../../images/sdd/icon_set_condition.svg';
import TopBottomSvg from '../../../images/sdd/icon_top_bottom.svg';
import { createFilterForField, TimestampFilterSubTypes } from './Filter';
import { isEqual, isNil } from 'lodash';
import Discover from '../../common/redux/actions/DiscoverActions';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import TimestampCondition from './TimestampCondition';
import { RelativeDateCondition } from './relative-date-condition';
import TimestampPeriod from './TimestampPeriod';
import { messages } from '../../i18n';
import { IFilter } from '../../datasets/interfaces/';

const FilterNavItem = ({ children, svgIcon }) => {
  return (
    <div className='filter-nav-item'>
      <div className='filter-nav-icon'>{svgIcon}</div>
      <div className='filter-nav-content'>{children}</div>
    </div>
  );
};

interface IPropTypes {
  activeFilter: IFilter;
  filter: IFilter;
  vizId: string;
  field: any;
  changeFilter: (filter: IFilter) => never;
}

class DateFilter extends Component<IPropTypes, {}> {
  constructor(props) {
    super(props);
    const { filter } = props;
    const subType =
      isNil(filter) || isNil(filter.subType) ? '' : filter.subType;
    this.state = {
      [TimestampFilterSubTypes.SET_CONDITION]:
        subType === TimestampFilterSubTypes.SET_CONDITION ? props.filter : null,
      [TimestampFilterSubTypes.RELATIVE_DATES]:
        subType === TimestampFilterSubTypes.RELATIVE_DATES
          ? props.filter
          : null,
      [TimestampFilterSubTypes.PERIOD_TO_DATE]:
        subType === TimestampFilterSubTypes.PERIOD_TO_DATE
          ? props.filter
          : null,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      !isNil(this.props.activeFilter) &&
      !isEqual(
        prevProps.activeFilter?.subType,
        this.props.activeFilter?.subType,
      )
    ) {
      // keep track of the previous filter
      this.setState({
        [this.props.activeFilter?.subType]: this.props.activeFilter,
      });
    }
  }

  onTabChange(key) {
    let currentFilter = this.state[key];
    if (isNil(currentFilter)) {
      const f = createFilterForField(this.props.field);
      f.subType = key;
      currentFilter = f;
      this.setState({ [key]: f });
    }
    this.props.changeFilter(currentFilter);
  }

  render() {
    const { filter, field, vizId } = this.props;
    const activeKey = filter?.subType
      ? filter.subType
      : TimestampFilterSubTypes.SET_CONDITION;

    return (
      <Tab.Container
        id='timestamp-filter-tab-container'
        defaultActiveKey={activeKey}
        onSelect={key => {
          this.onTabChange(key);
        }}
      >
        <div className='filter-content timestamp-filter'>
          <div className='section-label'>
            {messages.filters.selectFilterType}
          </div>
          <div className='filter-panel filter-type'>
            <Nav bsStyle='pills'>
              <NavItem eventKey={TimestampFilterSubTypes.SET_CONDITION}>
                <FilterNavItem svgIcon={<SetConditionSvg />}>
                  {messages.filters.setACondition}
                </FilterNavItem>
              </NavItem>
              <NavItem eventKey={TimestampFilterSubTypes.RELATIVE_DATES}>
                <FilterNavItem svgIcon={<SelectItemsSvg />}>
                  {messages.filters.relativeDates}
                </FilterNavItem>
              </NavItem>
              <NavItem eventKey={TimestampFilterSubTypes.PERIOD_TO_DATE}>
                <FilterNavItem svgIcon={<TopBottomSvg />}>
                  {messages.filters.periodToDate}
                </FilterNavItem>
              </NavItem>
            </Nav>
          </div>
          <Tab.Content animation>
            <Tab.Pane eventKey={TimestampFilterSubTypes.SET_CONDITION}>
              <div className='section-label'>
                {messages.filters.setCondition}
              </div>
              <div className='filter-panel filter-condition'>
                {isEqual(activeKey, TimestampFilterSubTypes.SET_CONDITION) && (
                  <TimestampCondition
                    filter={filter}
                    field={field}
                    vizId={vizId}
                  />
                )}
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey={TimestampFilterSubTypes.RELATIVE_DATES}>
              <div className='section-label'>
                {messages.filters.setCondition}
              </div>
              <div className='filter-panel filter-condition'>
                {isEqual(activeKey, TimestampFilterSubTypes.RELATIVE_DATES) && (
                  <RelativeDateCondition
                    filter={filter}
                    field={field}
                    vizId={vizId}
                  />
                )}
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey={TimestampFilterSubTypes.PERIOD_TO_DATE}>
              <div className='section-label'>
                {messages.filters.setCondition}
              </div>
              <div className='filter-panel filter-condition'>
                {isEqual(activeKey, TimestampFilterSubTypes.PERIOD_TO_DATE) && (
                  <TimestampPeriod
                    filter={filter}
                    field={field}
                    vizId={vizId}
                  />
                )}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    activeFilter: isNil(state.discover.activeFilter)
      ? ownProps.filter
      : state.discover.activeFilter,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    changeFilter(filter) {
      dispatch(Discover.setActiveFieldFilter(filter));
    },
  };
};

export default compose<IPropTypes, any>(
  connect(mapStateToProps, mapDispatchToProps),
)(DateFilter);
