import { isNil } from 'lodash';
import { IAnyAttribute } from '../../../datasets';

export const getAttribType = (selectedField: IAnyAttribute) => {
  if (selectedField.attributeType?.startsWith('CALC')) {
    return isNil(selectedField.calcType)
      ? selectedField.attributeType
      : selectedField.calcType;
  } else {
    return selectedField.calcType;
  }
};
