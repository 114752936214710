import { useCallback, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

export const useEllipsisDetector = () => {
  const targetRef: any = useRef(null);
  const [isEllipsized, setIsEllipsized] = useState(false);
  const onResize = useCallback(() => {
    const _isEllipsized =
      targetRef.current?.scrollWidth > targetRef.current?.clientWidth;
    if (_isEllipsized !== isEllipsized) {
      setIsEllipsized(_isEllipsized);
    }
  }, [isEllipsized]);
  useResizeDetector({
    targetRef,
    onResize,
  });
  return {
    isEllipsized,
    ref: targetRef,
  };
};
