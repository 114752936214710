import moment from '../../../../common/Moment';
import { Moment } from 'moment';

export const MONTHDAYYEAR_NUM = 'MM/DD/YYYY';
export const MONTHDAYYEAR_SPELLED = 'MMMM DD, YYYY';
export const FiscalYearStartType: any = {
  START: 'start',
  END: 'end',
};

export const setTimeToZero = (datetime: Moment): Moment => {
  const zeroTime = datetime.utc().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return zeroTime;
};

export const getDefaultFiscalCalendarStartDate = (): Moment => {
  return setTimeToZero(
    moment().set({
      month: 0,
      date: 1,
    }),
  );
};

export const isValid = ({
  fiscalCalendarYearType,
  fiscalCalendarStartDate,
}: {
  fiscalCalendarYearType: string;
  fiscalCalendarStartDate: Moment;
}) => {
  const isDateValid = fiscalCalendarStartDate.isValid();
  const isYearTypeValid =
    fiscalCalendarYearType === FiscalYearStartType.START ||
    fiscalCalendarYearType === FiscalYearStartType.END;
  return isDateValid && isYearTypeValid;
};

export const isFiscalEqualToCalendar = ({
  fiscalCalendarStartDate,
  fiscalCalendarStartDefault,
}: {
  fiscalCalendarStartDate: Moment;
  fiscalCalendarStartDefault: Moment;
}) => {
  // fiscal calendar is based on Month and Day, not year or time (etc)
  return (
    fiscalCalendarStartDate.get('month') ===
      fiscalCalendarStartDefault.get('month') &&
    fiscalCalendarStartDate.get('date') ===
      fiscalCalendarStartDefault.get('date')
  );
};

export const getEndingGregorianDate = (gregorianStart: Moment): Moment => {
  const gregorianEnd = setTimeToZero(moment(gregorianStart))
    .subtract(1, 'day')
    .add(1, 'year');

  return gregorianEnd;
};
