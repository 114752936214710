import _ from 'lodash';
import { useRef } from 'react';

export const usePreviousValue = <T extends any = any>({
  value,
  defaultValue,
}: {
  value: T;
  defaultValue?: T;
}) => {
  const previous = useRef(defaultValue);
  const retVal = previous.current;
  previous.current = value;
  return retVal;
};

export const useHasValueChanged = <T extends any = any>({
  value,
  comparator = _.isEqual,
  defaultValue,
}: {
  value: T;
  comparator?: (prevVal: T, nextVal?: T) => boolean;
  defaultValue?: T;
}) => {
  const previous = usePreviousValue({ value, defaultValue });
  const hasChanged = !comparator(previous, value);
  return hasChanged;
};
