import { isEmpty, includes, every } from 'lodash';
import { Types } from '../../common';
import { IAnyAttribute } from '../../datasets';

export const isEditableCalcField = calcField => {
  const { attributeType, calculation, formula } = calcField;
  return (
    includes([Types.CALC, Types.STRING_CALC], attributeType) ||
    (attributeType === Types.TIMESTAMP && !isEmpty(calculation)) ||
    (attributeType === Types.BOOLEAN && !isEmpty(formula))
  );
};

export const isNotCalc = (field: IAnyAttribute) =>
  every([field.calculation, field.formula], isEmpty);

export enum FieldMenuActions {
  FILTER = 'FILTER',
  FILTER_AGGREGATE = 'FILTER_AGGREGATE',
  DESIGNATE_RUNTIME = 'DESIGNATE_RUNTIME',
  SORT_ASCENDING = 'SORT_ASCENDING',
  SORT_DESCENDING = 'SORT_DESCENDING',
  MOVE_UP = 'MOVE_UP',
  MOVE_DOWN = 'MOVE_DOWN',
  REMOVE = 'REMOVE',
  EDIT_CALC = 'EDIT_CALC',
  DELETE_CALC = 'DELETE_CALC',
  REMOVE_SORT = 'REMOVE_SORT',
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}
