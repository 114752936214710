import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Discover from '../../common/redux/actions/DiscoverActions';
import { Hierarchy } from '../../discovery/VizUtil';
import { messages } from '../../i18n';
import { VIZ_SELECTORS } from '../../common/redux/selectors/viz-selectors';
import { INestedListItem, NestedDropdown } from '../nested-dropdown';
import {
  DEFAULT_TIME_HIERARCHY_OPTIONS,
  FISCAL_HIERARCHY_LABEL_KEYS,
  getHierarchyNonHiddenAttributes,
  timeHierarchyOptions,
} from './time-hierarchy-menu-item.utils';
import { TimeHierarchyMenuItemProps } from './time-hierarchy-menu-item.interfaces';

export const TimeHierarchyMenuItem = ({
  field,
  discoveryId,
}: TimeHierarchyMenuItemProps) => {
  const dispatch = useDispatch();

  const { useFiscalCalendar } = useSelector(state => ({
    useFiscalCalendar:
      VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting(state, {}) &&
      VIZ_SELECTORS.getActiveVizFiscalSetting(state, {}) === 'true',
  }));

  const showTimeHierarchy = timeHierarchyFields => {
    dispatch(
      Discover.showTimeHierarchy(discoveryId, field, timeHierarchyFields),
    );
  };

  const removeTimeHierarchy = () => {
    dispatch(Discover.removeTimeHierarchy(discoveryId, field));
  };

  const itemSelected = eventKey => {
    const option = DEFAULT_TIME_HIERARCHY_OPTIONS[eventKey];
    if (!_.isNil(option)) {
      showTimeHierarchy(
        Hierarchy.createTimeCalcFields(field, option.attributes),
      );
    } else if (eventKey === 'SHOW_ALL_TIME_ATTRIBUTES') {
      // Show all non-hidden attributes
      showTimeHierarchy(
        Hierarchy.createTimeCalcFields(
          field,
          getHierarchyNonHiddenAttributes(),
        ),
      );
    } else {
      removeTimeHierarchy();
    }
  };

  const hideShowOptions = [
    [
      'HIDE_TIME_ATTRIBUTES',
      {
        text: messages.timeHierarchyMenuItem.hideTime,
        fiscalText: messages.timeHierarchyMenuItem.hideTime,
      },
    ],
    [
      'SHOW_ALL_TIME_ATTRIBUTES',
      {
        text: messages.timeHierarchyMenuItem.showAll,
        fiscalText: messages.timeHierarchyMenuItem.showAll,
        showDividerAfter: true,
      },
    ],
  ];

  const dropdownOptions = _.concat(hideShowOptions, timeHierarchyOptions);

  return (
    <NestedDropdown
      id={'time-hierarchy-submenu'}
      popperSx={{ width: 'auto' }}
      options={_.map<any, INestedListItem>(
        dropdownOptions,
        ([timeHierarchyKey, timeHierarchy]): INestedListItem => {
          const optionText =
            useFiscalCalendar &&
            _.includes(FISCAL_HIERARCHY_LABEL_KEYS, timeHierarchyKey)
              ? timeHierarchy.fiscalText
              : timeHierarchy.text;
          return {
            label: _.get(messages, optionText, optionText) as string,
            onClick: () => itemSelected(timeHierarchyKey),
            showDividerAfter: timeHierarchy.showDividerAfter,
          };
        },
      )}
      label={messages.timeHierarchyMenuItem.showTimeHierarchy}
    />
  );
};
