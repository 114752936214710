import { compose, withHandlers, withState } from 'react-recompose';
import * as shortid from 'shortid';
import _ from 'lodash';

const ActivityFeedItemBody = compose(
  withState('collapsed', 'setCollapsed', true),
  withHandlers({
    toggleBodyCollapse: ({
      collapsed,
      setCollapsed,
      onHeightChanged,
    }) => () => {
      setCollapsed(!collapsed);
      if (_.isFunction(onHeightChanged)) {
        console.log('HEIGHT CHANGED, refresh the component');
        onHeightChanged();
      }
    },
  }),
)(({ children, collapsed, toggleBodyCollapse }) => {
  return (
    <div className='feed-row-body'>
      <div
        className={`message${collapsed ? ' collapsed' : ''}`}
        key={shortid.generate()}
        onClick={toggleBodyCollapse}
      >
        {children}
      </div>
    </div>
  );
});
ActivityFeedItemBody.role = 'ActivityFeedItemBody';

export default ActivityFeedItemBody;
