import PropTypes from 'prop-types';

import ChevronLeftSVG from '../../../images/sdd/icon_left_chevron_large.svg';

const component = (props: any) => {
  const { children, icon, ...otherProps } = props;
  return (
    <div className='mobile-menu-button' {...otherProps}>
      <div className='mobile-menu-button__content'>{children}</div>{' '}
      {icon ? <ChevronLeftSVG className='mobile-menu-button__chevron' /> : null}
    </div>
  );
};

component.propTypes = {
  icon: PropTypes.bool,
};

component.defaultProps = {
  icon: true,
};

export default component;
