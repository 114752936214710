import { memo } from 'react';
import PromptDialog from '../common/widgets/dialogs/PromptDialog';
import { branch, compose, renderNothing, withState } from 'react-recompose';
import { connect } from 'react-redux';
import _, { head } from 'lodash';
import Discover from '../common/redux/actions/DiscoverActions';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { messages } from '../i18n';

const QueryWarning = styled.div`
  padding-bottom: 32px;
`;
const WarningBody = styled.div(
  ({ theme: { colors: { ContentText } = {} } = {} }) => css`
    text-align: center;
    color: ${ContentText};
  `,
);
const Warning = styled.div``;

const QueryWarningDialog = memo(props => {
  const onHide = () => {
    props.setVisible(false);

    // if there are items in the undo stack, undo the last action
    if (props.hasUndoItems) {
      props.undo();
    } else {
      // otherwise close the report
      props.close();
    }
  };

  const ignoreWarnings = () => {
    props.setVisible(false);
    props.vizQueryIgnoreWarnings(
      props.vizId,
      props.vizName,
      props.monitorEventId,
      props.variables,
      props.queryId,
    );
  };

  const removeFields = () => {
    props.setVisible(false);
    const fieldNames = props.queryWarnings.flatMap(
      warning => warning.attributeNames,
    );
    props.removeFields(fieldNames);
  };

  const renderWarnings = warnings => {
    if (!_.isEmpty(warnings)) {
      const warning = warnings[0];
      if (warning.type === 'WINDOW_COMPLEXITY') {
        const calcNames = warning.attributeNames.map(n => `'${n}'`).join(', ');
        const msg = messages.formatString(
          messages.queryWarningDialog.complexQuery,
          calcNames,
        );
        return <Warning>{msg}</Warning>;
      } else {
        return <Warning>{warning.message}</Warning>;
      }
    }
  };

  // for now, only support a single warning
  const warning = head(props.queryWarnings);
  const title =
    warning?.type === 'WINDOW_COMPLEXITY'
      ? messages.queryWarningDialog.complexReportQuery
      : props?.title;
  const removeFieldsLabel =
    warning?.type === 'WINDOW_COMPLEXITY'
      ? messages.queryWarningDialog.removeWindow
      : messages.queryWarningDialog.removeField;

  return (
    <PromptDialog
      show={props.show}
      doYes={() => removeFields()}
      yesText={removeFieldsLabel}
      doNo={() => {
        ignoreWarnings();
      }}
      noText={'Continue'}
      doCancel={() => onHide()}
      cancelText={props.hasUndoItems ? messages.cancel : messages.close}
      title={title}
    >
      <QueryWarning>
        <WarningBody>{renderWarnings(props.queryWarnings)}</WarningBody>
      </QueryWarning>
    </PromptDialog>
  );
});

QueryWarningDialog.defaultProps = {
  title: messages.queryWarningDialog.queryWarning,
  show: false,
};

export default compose(
  connect(
    (state, ownProps) => {
      const open = state.discover.openDiscoveries[ownProps.vizId];
      const warnings = _.get(open, 'present.vizQueryWarning.warnings', []);
      const variables = _.get(
        open,
        'present.vizQueryWarning.queryVariables',
        {},
      );
      return {
        queryWarnings: warnings,
        isAdvancedMode: state.main.advanced,
        show: !_.isEmpty(warnings),
        hasUndoItems: !_.isEmpty(open.past),
        queryId: open.present.viz.queryId,
        vizName: open.present.viz.name,
        monitorEventId: open.present.monitorEventId,
        variables,
      };
    },
    (dispatch, ownProps) => {
      return {
        undo: () => {
          dispatch(Discover.undoDiscovery(ownProps.vizId));
        },
        vizQueryIgnoreWarnings: (
          id,
          sourceName,
          monitorEventId,
          variables,
          queryId,
        ) => {
          dispatch(Discover.setVizLegendData(id, []));
          dispatch(
            Discover.vizQuery({
              id,
              sourceName,
              monitorEventId,
              variables,
              queryId,
              replaceNulls: true,
              secondaryQueryVariables: null,
              skipAnalyze: true,
            }),
          );
        },
        close: () => {
          dispatch(Discover.closeDiscovery(ownProps.vizId));
        },
        removeFields: fieldNames => {
          dispatch(Discover.removeFieldsByName(ownProps.vizId, fieldNames));
        },
      };
    },
  ),
  branch(props => {
    return !props.show;
  }, renderNothing),
  withState('visible', 'setVisible', true),
)(QueryWarningDialog);
