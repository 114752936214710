import styled from '@emotion/styled';
import { css } from '@emotion/react';
import * as Icons from '../Icons';

export const colorizeSVGPartial = (fromColor, toColor) => {
  return css`
    g[fill='${fromColor}'],
    line[fill='${fromColor}'],
    polyline[fill='${fromColor}'],
    polygon[fill='${fromColor}'],
    path[fill='${fromColor}'],
    circle[fill='${fromColor}'],
    use[fill='${fromColor}'],
    rect[fill='${fromColor}'] {
      fill: ${toColor};
    }

    g[stroke='${fromColor}'],
    line[stroke='${fromColor}'],
    polyline[stroke='${fromColor}'],
    polygon[stroke='${fromColor}'],
    path[stroke='${fromColor}'],
    rect[stroke='${fromColor}'],
    use[stroke='${fromColor}'],
    circle[stroke='${fromColor}'] {
      stroke: ${toColor};
    }
  `;
};

const transformSVGState = (
  fromColor,
  { colors: { SVGIconColor, SVGIconHoverColor } = {} } = {},
) => css`
  &:not([disabled]) {
    cursor: pointer;
    ${colorizeSVGPartial(fromColor, SVGIconColor)}
    &:hover {
      ${colorizeSVGPartial(fromColor, SVGIconHoverColor)}
    }
  }
  &[disabled] {
    opacity: 0.48;
  }
`;

const iconSize = css`
  height: 20px;
  width: 20px;
`;
const iconSizeMedium = css`
  height: 24px;
  width: 24px;
`;

const iconColor = ({
  theme: { colors: { ContentBackground, SVGIconColor } = {} } = {},
}) =>
  css`
    ${colorizeSVGPartial(ContentBackground, SVGIconColor)}
  `;

export const UserIcon = styled(Icons.UserIcon)(
  ({ theme }) => css`
    ${iconColor({ theme })}
  `,
);

export const CalendarIcon = styled(Icons.TimestampIcon)(
  ({ theme, size }) => css`
    width: ${size || 21}px;
    height: ${size || 21}px;
    ${transformSVGState(theme?.colors?.ToolIconColorSecondary, theme)}
  `,
);

export const AddIcon = styled(Icons.AddIcon)(
  ({ theme, size }) => css`
    width: ${size || 21}px;
    height: ${size || 21}px;
    ${transformSVGState(theme?.colors?.ToolIconColorSecondary, theme)}
  `,
);

export const SearchIcon = styled(Icons.SearchIcon)(
  ({ theme, em }) => css`
    ${iconColor({ theme })}
    ${iconSize}
    ${(em &&
      colorizeSVGPartial(
        theme?.colors?.TabDefaultColor,
        theme?.colors?.SVGIconHoverColor,
      )) ||
      ''}
  `,
);

export const ClearAllIcon = styled(Icons.ClearAllIcon)(
  ({ theme }) => css`
    ${iconColor({ theme })}
    height: 24px;
    width: 24px;
    cursor: pointer;
    ${colorizeSVGPartial(
      theme?.colors?.StandardButtonColor,
      theme?.colors?.SVGIconColor,
    )}
    &:hover {
      ${colorizeSVGPartial(
        theme?.colors?.StandardButtonColor,
        theme?.colors?.SVGIconHoverColor,
      )}
    }
    &[disabled] {
      opacity: 0.48;
    }
    &[disabled]:hover {
      opacity: 1;
    }
  `,
);

export const EditIcon = styled(Icons.EditIcon)(
  ({ theme }) => css`
    ${iconColor({ theme })}
    ${iconSizeMedium}
    ${colorizeSVGPartial(
      theme?.colors?.ChartLabelTextColor,
      theme?.colors?.SVGIconColor,
    )} 
    &:hover {
      cursor: pointer;
      ${colorizeSVGPartial(
        theme?.colors?.ChartLabelTextColor,
        theme?.colors?.SVGIconHoverColor,
      )}
    }
    &[disabled] {
      opacity: 0.48;
    }
    &[disabled]:hover {
      opacity: 1;
    }
  `,
);
export const RefreshIcon = styled(Icons.RefreshIcon)(
  ({ theme }) => css`
    ${transformSVGState(theme?.colors?.PanelIconColor, theme)}
  `,
);
export const TrashIcon = styled(Icons.DeleteIcon)(
  ({
    theme: {
      colors: { SVGIconColor, SVGIconHoverColor, ChartLabelTextColor } = {},
    } = {},
  }) => css`
    ${iconSizeMedium}

    &:not([disabled]) {
      cursor: pointer;
      ${colorizeSVGPartial(ChartLabelTextColor, SVGIconColor)}
      &:hover {
        ${colorizeSVGPartial(ChartLabelTextColor, SVGIconHoverColor)}
        opacity:1;
      }
    }
    &[disabled] {
      opacity: 0.48;
    }
  `,
);

export const UploadIcon = styled(Icons.UploadIcon)(
  ({ theme }) => css`
    ${transformSVGState(theme?.colors?.PanelIconColor, theme)}
  `,
);
