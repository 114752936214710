import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Switch } from 'react-router-dom';

export const Segue = ({
  className,
  classNames,
  timeout,
  location,
  children,
}: {
  className?;
  classNames?;
  timeout?;
  location?;
  children?;
}) => {
  timeout = timeout | 250;
  return (
    <TransitionGroup className={className}>
      <CSSTransition
        key={location.key}
        classNames={classNames}
        timeout={timeout}
      >
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};
