import { useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { isNil } from 'lodash';
import Tabs from '@mui/material/Tabs';
import Fade from '@mui/material/Fade';
import SetConditionSvg from '../../../../images/sdd/icon_set_condition.svg';
import TopBottomSvg from '../../../../images/sdd/icon_top_bottom.svg';
import { createFilterForField, AggregateFilterSubTypes } from '../Filter';
import { messages } from '../../../i18n';
import { IAggregateFilterProps } from './aggregate-filter.interface';
import { StyledTab } from './aggregate-filter.styles';
import TopBottomCondition from '../TopBottomCondition';
import { useFilterDialogContext } from '../filter-dialog/filter-dialog.context';
import StringCondition from '../StringCondition';

const FilterNavItem = ({ children, svgIcon }) => {
  return (
    <div className='filter-nav-item'>
      <div className='filter-nav-icon'>{svgIcon}</div>
      <div className='filter-nav-content'>{children}</div>
    </div>
  );
};

export const AggregateFilter = ({ vizId }: IAggregateFilterProps) => {
  const { field, filter, changeAggregateFilter } = useFilterDialogContext();
  const subType = isNil(filter) || isNil(filter.subType) ? '' : filter.subType;
  const [selectedTab, setSelectedTab] = useState(
    subType ? subType : AggregateFilterSubTypes.SET_CONDITION,
  );

  const onTabChange = (event, key) => {
    const currentFilter = createFilterForField(field);
    currentFilter.subType = key;

    changeAggregateFilter(currentFilter);
    setSelectedTab(key);
  };

  return (
    <div className='aggregate-filter'>
      <div className='filter-content string-filter'>
        <div className='section-label'>{messages.filters.selectFilterType}</div>
        <div className='filter-panel filter-type'>
          <Tabs
            value={selectedTab}
            onChange={onTabChange}
            aria-label='aggregate filter tabs'
            className='aggregate-filter-tabs'
            sx={{
              '& .MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            <StyledTab
              value={AggregateFilterSubTypes.SET_CONDITION}
              icon={
                <FilterNavItem svgIcon={<SetConditionSvg />}>
                  {messages.filters.setACondition}
                </FilterNavItem>
              }
            />
            <StyledTab
              value={AggregateFilterSubTypes.TOP_BOTTOM}
              icon={
                <FilterNavItem svgIcon={<TopBottomSvg />}>
                  {messages.filters.topBottom}
                </FilterNavItem>
              }
            />
          </Tabs>
        </div>
        <div className='tab-content'>
          <TransitionGroup exit={false}>
            {selectedTab === AggregateFilterSubTypes.SET_CONDITION && (
              <Fade key={AggregateFilterSubTypes.SET_CONDITION} timeout={500}>
                <div>
                  <div className='section-label'>
                    {messages.filters.setCondition}
                  </div>
                  <div className='filter-panel filter-condition'>
                    <StringCondition
                      filter={filter}
                      field={field}
                      vizId={vizId}
                    />
                  </div>
                </div>
              </Fade>
            )}
            {selectedTab === AggregateFilterSubTypes.TOP_BOTTOM && (
              <Fade key={AggregateFilterSubTypes.TOP_BOTTOM} timeout={500}>
                <div>
                  <div className='section-label'>
                    {messages.filters.setCondition}
                  </div>
                  <div className='filter-panel filter-condition'>
                    <TopBottomCondition
                      filter={filter}
                      field={field}
                      vizId={vizId}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};
