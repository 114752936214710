import { useQuery } from '@apollo/client';
import { DatasetQueries } from '../../../common/graphql';
import { find, isNil, isObject } from 'lodash';
import {
  IDatasetAnnotationsRequest,
  IDatasetJson,
  IDatasetSettings,
  ISugarCrmSchema,
} from '../edit-dataset-settings.interfaces';
import { parseAndCleanDatasetJSON } from '../../../datasets/utils';
import { useState } from 'react';

export const useGetSugarCrmSchemas = (
  datasetId: string,
): { sugarCrmSchema: ISugarCrmSchema } => {
  const { loading, data: { sugarCrmSchemas } = {} } = useQuery(
    DatasetQueries.GetSugarCrmSchemas,
    {
      skip: isNil(datasetId),
      fetchPolicy: 'network-only',
    },
  );

  const defaultSugarCrmSchema = { datasetId };

  if (loading === false && datasetId) {
    const sugarCrmSchema =
      find(sugarCrmSchemas, { datasetId }) ?? defaultSugarCrmSchema;
    return { sugarCrmSchema };
  }

  return {
    sugarCrmSchema: defaultSugarCrmSchema,
  };
};

export const useGetDatasetAnnotations = (
  datasetId: string,
): { datasetCustomSettings: IDatasetSettings } => {
  const { loading, data: { getSugarCrmDatasetAnnotations } = {} } = useQuery(
    DatasetQueries.GetSugarCrmDatasetCustomAnnotations,
    {
      variables: { id: datasetId },
      skip: isNil(datasetId),
      fetchPolicy: 'network-only',
    },
  );

  const [datasetJson, setDatasetJson] = useState<IDatasetJson>();
  const [isDatasetJsonValid, setDatasetJsonValid] = useState(false);

  if (
    loading === false &&
    isObject(getSugarCrmDatasetAnnotations) &&
    isNil(datasetJson)
  ) {
    try {
      setDatasetJson(
        parseAndCleanDatasetJSON(
          (getSugarCrmDatasetAnnotations as Partial<IDatasetAnnotationsRequest>)
            .datasetJson,
        ),
      );

      setDatasetJsonValid(true);
    } catch {
      console.log('could not parse datasetJson');
    }
  }

  if (isDatasetJsonValid) {
    return {
      datasetCustomSettings: {
        datasetId,
        datasetAnnotations: {
          ...getSugarCrmDatasetAnnotations,
          datasetJson,
        },
      },
    };
  }

  return {
    datasetCustomSettings: {
      datasetId,
    },
  };
};
