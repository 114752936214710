import Const from './ActionConst';
import { CommonActions } from './common.actions';

const Actions = {
  setCurrentUser: CommonActions.setCurrentUser,
  setCurrentUserPreferences(userPreferences) {
    return {
      type: Const.Account.SET_CURRENT_USER_PREFERENCES,
      userPreferences,
    };
  },
  setChangePasswordError: CommonActions.setChangePasswordError,
  shouldLogoutOtherSessions: CommonActions.shouldLogoutOtherSessions,
  logoutOtherSessions: CommonActions.logoutOtherSessions,
};

export default Actions;
