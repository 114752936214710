import { IAnnotation, IDataset } from '../../datasets/interfaces';
import { Moment } from 'moment';

export interface ISugarCrmModuleField {
  name: string;
  json: string;
}

export interface ISugarCrmModule {
  name: string;
  fields?: ISugarCrmModuleField[];
}

export interface ISugarCrmSchemaRelationship {
  name: string;
  relationshipType: string;
  lhsModule: string;
  lhsTable: string;
  lhsKey: string;
  rhsModule: string;
  rhsTable: string;
  rhsVname: string;
  rhsKey: string;
  joinTable: string;
  joinKeyLhs: string;
  joinKeyRhs: string;
  userField: string;
  primaryFlagColumn: string;
  primaryFlagDefault: boolean;
  primaryFlagSide: string;
  relationshipClass: string;
  relationshipFile: string;
  trueRelationshipType: string;
  relationshipRoleColumnValue: string;
  relationshipRoleColumn: string;
  relationshipRoleColumns: { name: string; value: string };
  workflow: boolean;
  reverse: string;
  dynamicSubpanel: boolean;
  fromStudio: boolean;
}

export interface ISugarCrmSchema {
  datasetId: string;
  modules?: ISugarCrmModule[];
  relationships?: ISugarCrmSchemaRelationship[];
}

export interface IDatasetAnnotationsBase {
  datasetId: string;
  systemName?: string;
  dryRun?: 'true' | 'false';
  objectIdAttrib?: string;
  closeDateAttrib?: string;
  createdDateAttrib?: string;
  fiscalCalendarStartDate?: string;
  fiscalCalendarYearType?: FiscalCalendarYearType;
  skipRefresh?: boolean;
}

export interface IDatasetAnnotations extends IDatasetAnnotationsBase {
  datasetJson?: IDatasetJson;
}

export interface IDatasetAnnotationsRequest extends IDatasetAnnotationsBase {
  datasetJson?: string;
}

export const FiscalCalendarYear = {
  START: 'start' as FiscalCalendarYearType,
  END: 'end' as FiscalCalendarYearType,
};

export type FiscalCalendarYearType = 'start' | 'end';

export interface IDryrunReport {
  id?: string;
  runTimeStamp?: number | Moment;
  dryRunNotes?: string | IDryrunNotes;
}

export interface IDryrunNotes {
  fieldCount?: string;
  objectCount?: string;
  snapshotCount?: string;
  firstSnapshotDate?: string;
  lastSnapshotDate?: string;
  historyRowsProcessed?: string;
  historyRowsBeforeStartDate?: string;
  objectsMissingHistory?: string;
  maxObjLifeInDays?: string;
  runtimeSeconds?: string;
}

export interface IDatasetSettings {
  datasetId: string;
  annotations?: IAnnotation[];
  datasetAnnotations?: IDatasetAnnotations;
}

export interface IJoiningTable {
  name?: string;
  alias?: string;
  parentName?: string;
  foreignKey?: string;
  filteredAttributes?: string[];
}

export interface IDatasetJson {
  primaryTable?: { name: string };
  joinedTables?: IJoiningTable[];
  snapshotOnly?: boolean;
  lastEntryDate?: string;
  globalStartDate?: string;
  useLastHistoryDate?: boolean;
  stageFields?: string[];
  lastEntryPeriodType?: string;
  lastEntryPeriodMonthEnd?: string;
  editMode?: 'FULL' | 'LIMITED';
}

export interface IDatasetSettingsProviderProps {
  dataset: IDataset;
}

export interface IDatasetSettingsProps extends IDatasetSettingsProviderProps {
  onClose: () => void;
  fiscalCalendarDisabled: boolean;
}

export interface IDatasetSettingsContext extends IDatasetSettingsProviderProps {
  sugarCrmSchema?: ISugarCrmSchema;
  dryRunReport?: IDryrunReport;
  datasetSettings: IDatasetSettings;
  setDatasetSettings: (value: IDatasetSettings) => void;
}
