import _ from 'lodash';
import { useRef, useState, useCallback } from 'react';
import { useResettableState } from '../../../common/utilities/resettable-state.hook';

export interface IColorRowPickerHookProps {
  selectedColor: string;
  onSelect: (color: string) => void;
}

export interface IColorRowPickerState {
  anchorEl: Element | null;
  ref;
  color: string;
  setSelectedColor: (selectedColor: string) => void;
  handleClose: () => void;
  onClick: () => void;
  open: boolean;
}

export const useColorPickerRowState: (
  props: IColorRowPickerHookProps,
) => IColorRowPickerState = ({ selectedColor: _initialColor, onSelect }) => {
  const ref = useRef(null);
  const initialColor = _.toLower(_initialColor);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedColor, _setSelectedColor] = useResettableState(initialColor);
  const setSelectedColor = useCallback(
    newColor => {
      newColor = _.toLower(newColor);
      _setSelectedColor(newColor);
      onSelect(newColor);
    },
    [_setSelectedColor, onSelect],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  return {
    anchorEl,
    ref: r => {
      ref.current = r;
      if (r) {
        setAnchorEl(ref.current);
      }
    },
    color: selectedColor,
    setSelectedColor,
    handleClose,
    onClick,
    open,
  };
};
