import { Component, ComponentClass } from 'react';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';
import { Condition, FilterTypes, NOW, StringFilterSubTypes } from '../Filter';
import {
  convertYearOperandLabel,
  FilterOperators,
  timestampOperators,
} from '../FilterOperators';
import { compose, withProps } from 'react-recompose';
import {
  isNil,
  isEmpty,
  includes,
  nth,
  map,
  isArray,
  isFunction,
  isEqual,
  range,
  toString,
  find,
  last,
  concat,
  head,
  join,
  get,
  omit,
} from 'lodash';
import moment, { globalDateWithoutTimeFormat } from '../../../common/Moment';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import { parse } from 'papaparse';
import { messages } from '../../../i18n';
import {
  IOperator,
  FilterDialogTypes,
} from '../../../datasets/interfaces/filter.interface';
import { connect } from 'react-redux';
import {
  getDynamicFieldConfig,
  VIZ_SELECTORS,
} from '../../../common/redux/selectors/viz-selectors';
import { FiscalCalendarYear } from '../../../views/edit-dataset-settings/edit-dataset-settings.interfaces';
import { convertSugarDateFormat, DynamicFieldConfig } from '../../../common';
import { FieldsDropDown } from '../FieldsDropDown';

import { ISearchableDropdownOption } from '../../../ui/dropdowns/searchable-dropdown';
import { makeDropdownOption } from '../../../ui/dropdowns/searchable-dropdown/searchable-dropdown.utils';
import {
  FormGroup,
  StyledFormControl,
  StyledSearchableDropdown,
} from './filter-condition.styles';
import {
  IFilterConditionProps,
  IFilterConditionState,
} from './filter-condition.interface';

class UnconnectedFilterCondition extends Component<
  IFilterConditionProps,
  IFilterConditionState
> {
  state: IFilterConditionState;
  datePickerElement: any;
  constructor(props: IFilterConditionProps) {
    super(props);
    moment().format(this.getFormat());
    let operands =
      isArray(props.condition.operands) && props.condition.operands.length > 0
        ? props.condition.operands
        : this.getDefaultOperandArr(props.condition.operator, props.filterType);
    let operator = isNil(props.condition.operator)
      ? props.defaultSelected
      : props.condition.operator;
    if (operator === 'currentUser') {
      operator = 'dynamicField';
    }
    const { dynamicField } = props;
    const fieldSlug = dynamicField?.fieldSlug;
    if (operator === 'dynamicField' && fieldSlug) {
      operands = [fieldSlug];
    }
    const operandText = this.getOperandText(operands);
    this.state = {
      operator,
      operands,
      operandText,
    };
    this.onOperandChange = this.onOperandChange.bind(this);
  }

  getOperandText(operands) {
    return isEmpty(operands)
      ? ''
      : operands
          .filter(o => !isEmpty(o))
          .map(o => (includes(o, ',') ? `"${o}"` : o))
          .join(', ');
  }

  getDefaultOperandArr(operator, filterType) {
    if (!isNil(operator) && !this.props.operators[operator].requiresOperand) {
      return [];
    }
    switch (filterType) {
      case 'DATE':
        return [
          moment()
            .startOf('day')
            .format(this.getFormat()),
        ];
      default:
        return [''];
    }
  }

  componentDidMount() {
    this._onChange(this.state.operator, this.state.operands);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(this.state.operator, prevState.operator) ||
      !isEqual(this.state.operands, prevState.operands)
    ) {
      this._onChange(this.state.operator, this.state.operands);
    }

    if (
      !isEqual(
        prevProps?.condition?.operator,
        this.props?.condition?.operator,
      ) &&
      (!isEqual(this.state.operator, this.props.condition?.operator) ||
        !isEqual(this.state.operands, this.props.condition?.operands))
    ) {
      let operator = isNil(this.props.condition?.operator)
        ? this.props.defaultSelected
        : this.props.condition?.operator;
      if (operator === 'currentUser') {
        operator = 'dynamicField';
      }
      let operands =
        isArray(this.props.condition?.operands) &&
        this.props.condition?.operands.length > 0
          ? this.props.condition?.operands
          : [];
      if (operator === 'dynamicField' && this.props.dynamicField?.fieldSlug) {
        operands = [this.props.dynamicField?.fieldSlug];
      }
      const newState = {
        operator,
        operands,
      };
      this.setState(newState);
    }
  }

  onInTermOperandChange(operandIdx, changingValue) {
    const operand = nth(this.state.operands, operandIdx);
    if (isNil(operand)) {
      return;
    }
    this.setState({
      operands: map(this.state.operands, (_operandValue, _operandIdx) => {
        if (_operandIdx === operandIdx) {
          return changingValue;
        }
        return _operandValue;
      }),
    });
  }

  onOperandChange(event) {
    const { filterType, filterSubType } = this.props;
    const value =
      event === NOW
        ? NOW
        : event.currentTarget
        ? event.currentTarget.value
        : event.format(this.getFormat());
    if (
      filterType === FilterTypes.STRING &&
      filterSubType === StringFilterSubTypes.SET_CONDITION
    ) {
      this.setState({ operandText: value });
      // support multiple values via comma separated list
      this.parseOperandValues(value);
    } else {
      const newOperands = [value];
      this.setState({ operands: newOperands });
    }
  }

  onFieldOperandChange(event) {
    this.setState({
      operands: [event.name],
    });
  }

  onFieldOperandValueChange(event) {
    this.setState({
      operands: [this.state.operands[0], event.currentTarget.value],
    });
  }

  async parseOperandValues(value) {
    try {
      const parsedValue = await this._parseOperandValues(value);

      if (value !== this.state.operandText) {
        // this is a result parsed from text that isn't there anymore, ignore it
        return;
      }

      if (!isEmpty(parsedValue) && isArray(parsedValue)) {
        this.setState({
          operands: parsedValue,
          operandText: value,
          parseError: undefined,
        });
      } else if (isEmpty(parsedValue)) {
        this.setState({ operands: [], operandText: '', parseError: undefined });
      }
    } catch (e) {
      this.setState({ operandText: value, parseError: e, operands: [] });
    }
  }

  _parseOperandValues(operandString) {
    if (isEmpty(operandString)) {
      return new Promise(resolve => resolve([]));
    } else {
      return new Promise((resolve, reject) => {
        parse(operandString, {
          delimiter: ',',
          quoteChar: '"',
          complete: ({ errors, data }) => {
            if (isEmpty(errors) && !isEmpty(data)) {
              const newOperands = data[0];
              resolve(newOperands);
            } else {
              reject(errors);
            }
          },
        });
      });
    }
  }

  getOperandValue() {
    const { operands } = this.state;
    const { filterType, filterSubType } = this.props;
    if (
      filterType === FilterTypes.STRING &&
      filterSubType === StringFilterSubTypes.SET_CONDITION
    ) {
      return this.state.operandText;
    } else {
      return !isNil(operands) && operands.length > 0 ? operands[0] : '';
    }
  }

  getFieldOperandValue() {
    const { operands } = this.state;
    return operands?.length > 1 ? operands[1] : '';
  }

  _onChange(operatorKey: string, operands) {
    const operator = this.props.operators[operatorKey];
    if (isFunction(this.props.onChange)) {
      this.props.onChange(
        this.createConditionsWithDefaults(operator, operands),
      );
    }
  }

  createOperandMenuItems(
    startIdx: number,
    endIdx: number,
    operandValue: string,
    selectIdx: number,
  ): {
    menuOptions: ISearchableDropdownOption;
    defaultValue?: ISearchableDropdownOption;
    title: string;
    onSelect: (_value: string) => void;
  } {
    return {
      menuOptions: map(range(startIdx, endIdx), _idx =>
        makeDropdownOption(`${_idx}`),
      ),
      defaultValue: makeDropdownOption(operandValue),
      title: toString(operandValue),
      onSelect: _operandValue =>
        this.onInTermOperandChange(selectIdx, _operandValue),
    };
  }

  createConditionsWithDefaults(
    operator: IOperator,
    operands: string[],
  ): Condition {
    let cleanOperands = [];
    if (
      operator.requiresFieldPicker &&
      operator.requiresOperand &&
      !isEmpty(operands)
    ) {
      cleanOperands = operands.length < 2 ? [...operands, ''] : operands;
    } else if (operator.requiresOperand) {
      cleanOperands = operands;
    }
    return new Condition(operator.key, cleanOperands);
  }

  isInTermOperator(operatorKey) {
    return find(
      [
        timestampOperators.inWeek,
        timestampOperators.inMonth,
        timestampOperators.inQuarter,
        timestampOperators.weekInQuarter,
        timestampOperators.inYear,
      ],
      { key: operatorKey },
    );
  }

  shouldUpdateOperands(
    prevOperatorKey: string,
    nextOperatorKey: string,
  ): boolean {
    const dynamicFieldKey = FilterOperators.forFilterType(FilterTypes.STRING)
      .dynamicField.key;
    return (
      !isEmpty(this.isInTermOperator(prevOperatorKey)) ||
      !isEmpty(this.isInTermOperator(nextOperatorKey)) ||
      isEqual(prevOperatorKey, dynamicFieldKey) ||
      isEqual(nextOperatorKey, dynamicFieldKey)
    );
  }
  onOperatorChange(nextOperatorKey: string) {
    const prevOperatorKey = this.state.operator;
    if (this.shouldUpdateOperands(prevOperatorKey, nextOperatorKey)) {
      const operands = this.getOperandDefaults(nextOperatorKey);
      const operandText = this.getOperandText(operands);
      this.setState({
        operator: nextOperatorKey,
        operands,
        operandText,
      });
    } else {
      this.setState({
        operator: nextOperatorKey,
      });
    }
  }

  getFormat() {
    return moment.ISO8601;
  }

  forceDatePickerClosed(newDate) {
    const current = moment(this.state.operands[0]);
    if (newDate === NOW) {
      newDate = moment();
    }
    const dayDiff = newDate.diff(current, 'days');
    // don't auto-close it if the user is changing the time attributes.
    if (Math.abs(dayDiff) >= 1 || current.isSame(newDate)) {
      this.datePickerElement.close();
    }
  }

  getOperandDefaults(operatorKey: string): any[] {
    switch (this.props.filterType) {
      case 'DATE': {
        const inTermOperator = this.isInTermOperator(operatorKey);

        if (inTermOperator) {
          switch (operatorKey) {
            case timestampOperators.inWeek.key: {
              return ['0', 'ALL'];
            }
            case timestampOperators.inMonth.key: {
              return ['1', 'ALL'];
            }
            case timestampOperators.inQuarter.key: {
              return ['1', 'ALL'];
            }
            case timestampOperators.weekInQuarter.key: {
              return ['1', '1', 'ALL'];
            }
            case timestampOperators.inYear.key: {
              return ['ALL'];
            }
          }
        }
        return this.getDefaultOperandArr(operatorKey, this.props.filterType);
      }
    }
    return [];
  }

  getFormatForInput() {
    const { condition, operators, i18nPrefs } = this.props;
    const disableTime =
      condition.operator && operators[condition.operator]?.disableTime;

    if (disableTime) {
      return (
        convertSugarDateFormat(i18nPrefs?.dateFormat) ||
        globalDateWithoutTimeFormat
      );
    }
    return moment.defaultFormat;
  }

  renderOperand() {
    const { id, operators } = this.props;
    const { operator, operands } = this.state;

    const currentOperator: IOperator = operators[operator];

    if (currentOperator?.requiresOperand && !currentOperator?.hideOperand) {
      let valueControl;
      // "Now()" or moment from value or instant moment if value is null
      let value =
        !isNil(operands) && operands.length > 0
          ? operands[0] === NOW
            ? operands[0]
            : moment(operands[0])
          : moment();

      switch (this.props.filterType) {
        case FilterTypes.BOOLEAN: {
          const current = join(operands as [], '');
          const boolOptions = map(['true', 'false'], boolName =>
            makeDropdownOption(boolName),
          );
          value = includes(map(boolOptions, 'value'), current.toLowerCase())
            ? current
            : '';

          valueControl = (
            <StyledSearchableDropdown
              id={`boolean-filter-dropdown`}
              onSelect={(options: ISearchableDropdownOption[]) => {
                const option = head(options);
                if (option) {
                  this.onFieldOperandChange({ name: option?.value });
                }
              }}
              title={<span className={'dropdown-title'}>{value}</span>}
              options={boolOptions}
              defaultValue={makeDropdownOption(value)}
              popperSx={{ zIndex: 1050 }}
            />
          );
          break;
        }
        case FilterTypes.DATE: {
          const inTermOperator = this.isInTermOperator(
            this.props.condition.operator,
          );
          if (inTermOperator) {
            // Note: backend takes 1-indexed values of month/quarter operands
            const operandDefaults = this.getOperandDefaults(
              this.props.condition.operator,
            );

            const yearOperand = toString(
              last(operands) ?? last(operandDefaults),
            );

            const yearOperandLabel = convertYearOperandLabel(yearOperand);

            let currentYear = parseInt(moment().format('YYYY'));

            if (this.props.fiscalCalendarYearType === FiscalCalendarYear.END) {
              currentYear += 1;
            }

            const yearOperands = concat(
              makeDropdownOption('ALL', messages.filters.allYears),
              map(range(currentYear, currentYear - 10, -1), _yearIdx => {
                const yearLabel = convertYearOperandLabel(_yearIdx);
                return makeDropdownOption(`${_yearIdx}`, yearLabel);
              }),
            );

            const inTermOperands: {
              menuOptions: ISearchableDropdownOption[];
              defaultValue?: ISearchableDropdownOption;
              title: string;
              onSelect: (_value: string) => void;
            }[] = [];

            switch (inTermOperator.key) {
              case timestampOperators.inWeek.key: {
                const defaultWeekOperand = head(operandDefaults);
                const weekOperand: number = parseInt(
                  head(operands) ?? defaultWeekOperand,
                );
                const weekOperandLabel: string = toString(weekOperand);
                inTermOperands.push({
                  menuOptions: map(range(1, 53), _weekIdx =>
                    makeDropdownOption(`${_weekIdx}`),
                  ),
                  defaultValue: makeDropdownOption(
                    weekOperand,
                    weekOperandLabel,
                  ),
                  title: weekOperandLabel,
                  onSelect: _operandValue =>
                    this.onInTermOperandChange(0, _operandValue),
                });

                inTermOperands.push({
                  menuOptions: yearOperands,
                  defaultValue: makeDropdownOption(
                    yearOperand,
                    yearOperandLabel,
                  ),
                  title: yearOperandLabel,
                  onSelect: _operandValue =>
                    this.onInTermOperandChange(1, _operandValue),
                });
                break;
              }
              case timestampOperators.inMonth.key: {
                const defaultMonthOperand = head(operandDefaults);
                const monthOperand: number =
                  head(operands) ?? defaultMonthOperand;
                const monthOperandLabel: string = moment()
                  .month(monthOperand - 1)
                  .format('MMMM');
                inTermOperands.push({
                  menuOptions: map(range(1, 13), _monthIdx => {
                    const monthLabel = moment()
                      .month(_monthIdx - 1)
                      .format('MMMM');
                    return makeDropdownOption(`${_monthIdx}`, monthLabel);
                  }),
                  defaultValue: makeDropdownOption(
                    monthOperand,
                    monthOperandLabel,
                  ),
                  title: monthOperandLabel,
                  onSelect: _operandValue =>
                    this.onInTermOperandChange(0, _operandValue),
                });

                inTermOperands.push({
                  menuOptions: yearOperands,
                  defaultValue: makeDropdownOption(
                    yearOperand,
                    yearOperandLabel,
                  ),
                  title: yearOperandLabel,
                  onSelect: _operandValue =>
                    this.onInTermOperandChange(1, _operandValue),
                });
                break;
              }
              case timestampOperators.inQuarter.key: {
                const defaultQuarterOperand = head(operandDefaults);
                const quarterOperand =
                  (head(operands) as string) ?? defaultQuarterOperand;

                inTermOperands.push({
                  menuOptions: map(range(1, 5), _quarterIdx => {
                    const quarterValue = _quarterIdx;
                    return makeDropdownOption(`${quarterValue}`);
                  }),
                  defaultValue: makeDropdownOption(quarterOperand),
                  title: toString(quarterOperand),
                  onSelect: _operandValue =>
                    this.onInTermOperandChange(0, _operandValue),
                });

                inTermOperands.push({
                  menuOptions: yearOperands,
                  defaultValue: makeDropdownOption(
                    yearOperand,
                    yearOperandLabel,
                  ),
                  title: yearOperandLabel,
                  onSelect: _operandValue =>
                    this.onInTermOperandChange(1, _operandValue),
                });
                break;
              }
              case timestampOperators.weekInQuarter.key: {
                const quarterOperand =
                  (head(operands) as string) ?? head(operandDefaults);
                const weekOperand =
                  (nth(operands, 1) as string) ?? nth(operandDefaults, 1);
                //Quarter
                inTermOperands.push(
                  this.createOperandMenuItems(1, 5, quarterOperand, 0),
                );
                inTermOperands.push(
                  this.createOperandMenuItems(1, 14, weekOperand, 1),
                );
                inTermOperands.push({
                  menuOptions: yearOperands,
                  defaultValue: makeDropdownOption(
                    yearOperand,
                    yearOperandLabel,
                  ),
                  title: yearOperandLabel,
                  onSelect: _operandValue =>
                    this.onInTermOperandChange(2, _operandValue),
                });
                break;
              }
              case timestampOperators.inYear.key: {
                inTermOperands.push({
                  menuOptions: yearOperands,
                  defaultValue: makeDropdownOption(
                    yearOperand,
                    yearOperandLabel,
                  ),
                  title: yearOperandLabel,
                  onSelect: _operandValue =>
                    this.onInTermOperandChange(0, _operandValue),
                });
                break;
              }
            }

            valueControl = map(
              inTermOperands,
              ({ menuOptions: _menuOptions, onSelect, title }, _idx) => {
                return (
                  <StyledSearchableDropdown
                    id={`${id}-interm-operand-dropdown-${_idx}`}
                    onSelect={(options: ISearchableDropdownOption[]) => {
                      const option = head(options);
                      if (isFunction(onSelect)) {
                        onSelect(option?.value);
                      }
                    }}
                    title={<span className={'dropdown-title'}>{title}</span>}
                    options={_menuOptions}
                    popperSx={{ zIndex: 1050 }}
                  />
                );
              },
            );
          } else {
            const disableTime =
              this.props.condition.operator &&
              operators[this.props.condition.operator]?.disableTime;
            const timePickerElement = !disableTime && (
              <TimePickerPanel
                defaultOpenValue={moment().startOf('day')}
                showSecond={false}
              />
            );
            if (value !== NOW && !value.isValid()) {
              value = moment().startOf('day');
            }
            const calendar = (
              <Calendar
                locale={enUS}
                dateInputPlaceholder={messages.filters.pleaseInput}
                formatter={this.getFormat()}
                showOk
                showDateInput={false}
                showToday
                timePicker={timePickerElement}
                // BUG: https://github.com/react-component/calendar/issues/751
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onSelect={(selectValue, cause) => {
                  let newValue;
                  if (cause && cause.source === 'todayButton') {
                    newValue = NOW;
                  } else {
                    newValue = selectValue;
                  }
                  this.onOperandChange(newValue);
                  this.forceDatePickerClosed(newValue);
                }}
              />
            );

            valueControl = (
              <DatePicker
                key={`date-picker`}
                animation='slide-up'
                calendar={calendar}
                value={value === NOW ? moment() : value}
                placement='bottomLeft'
                prefixCls='corvana-calendar-picker'
                ref={el => {
                  this.datePickerElement = el;
                }}
              >
                {() => {
                  return (
                    <StyledFormControl
                      className='datetime-textbox'
                      placeholder={messages.filters.pleaseSelect}
                      key='numeric-condition-operand-key'
                      type={'text'}
                      onChange={this.onOperandChange}
                      value={
                        value
                          ? value === NOW
                            ? messages.filters.now
                            : value.format(this.getFormatForInput())
                          : ''
                      }
                    />
                  );
                }}
              </DatePicker>
            );
          }
          break;
        }
        default:
          valueControl = (
            <StyledFormControl
              key='numeric-condition-operand-key'
              type={'text'}
              onChange={this.onOperandChange}
              value={this.getOperandValue()}
            />
          );
      }
      if (currentOperator?.requiresFieldPicker) {
        const fieldPicker = (
          <FieldsDropDown
            field={this.props.field}
            selected={{ name: this.state.operands[0] || '' }}
            dropDownPrefix={'filter-picker'}
            useAllFields={true}
            onSelect={newSelectedField => {
              this.onFieldOperandChange(newSelectedField);
            }}
          />
        );

        const parentLabel = (
          <span>
            {get(messages, 'filters.withParentField', 'with parent field')}
          </span>
        );

        valueControl = (
          <StyledFormControl
            key='string-condition-operand-key'
            type={'text'}
            onChange={event => {
              this.onFieldOperandValueChange(event);
            }}
            value={this.getFieldOperandValue()}
          />
        );

        const eqLabel = (
          <span>{get(messages, 'filters.eq', 'equal to').toLowerCase()}</span>
        );

        return [parentLabel, fieldPicker, eqLabel, valueControl];
      }
      return [valueControl];
    } else {
      return null;
    }
  }

  render() {
    const { id, operators, useFiscalCalendar } = this.props;
    const { operator } = this.state;
    const selectedOperator = operators[operator];
    let operatorLabel =
      useFiscalCalendar && !isNil(selectedOperator.fiscalDisplayText)
        ? selectedOperator.fiscalDisplayText
        : selectedOperator.displayText;
    if (selectedOperator.key === 'dynamicField') {
      operatorLabel = `filters.isCurrent${this.props.dynamicField?.sugarModule}`;
    }
    const operatorOptions = Object.values(operators).map((op: IOperator) => {
      let _operatorOptionLabel =
        useFiscalCalendar && !isNil(op.fiscalDisplayText)
          ? op.fiscalDisplayText
          : op.displayText;
      if (op.key === 'dynamicField') {
        _operatorOptionLabel = `filters.isCurrent${this.props.dynamicField?.sugarModule}`;
      }
      return makeDropdownOption(
        op.key,
        get(messages, _operatorOptionLabel, _operatorOptionLabel),
      );
    });

    return (
      <FormGroup>
        <StyledSearchableDropdown
          id={`${id}-dropdown`}
          onSelect={(options: ISearchableDropdownOption[]) => {
            const option = head(options);

            if (option) {
              this.onOperatorChange(option?.value);
            }
          }}
          title={
            <span className={'dropdown-title'}>
              {get(messages, operatorLabel, operatorLabel)}
            </span>
          }
          options={operatorOptions}
          forceDisableSearch={this.props.isFilterAggregateDialog}
          popperSx={{ zIndex: 1050 }}
        />
        {this.renderOperand()}
      </FormGroup>
    );
  }
}

export const FilterCondition = compose<IFilterConditionProps, any>(
  connect((state: any, ownProps: any) => {
    const useFiscalCalendar = VIZ_SELECTORS.isFiscalCalendarActive(
      state,
      ownProps,
    );
    const fiscalCalendarYearType = VIZ_SELECTORS.getActiveDatasetFiscalYearSetting(
      state,
      ownProps,
    );
    const dynamicField: DynamicFieldConfig = getDynamicFieldConfig(state);
    const { i18nPrefs = {} } = state?.account?.currentUser;

    return {
      dynamicField,
      useFiscalCalendar,
      fiscalCalendarYearType,
      i18nPrefs,
      isFilterAggregateDialog:
        state?.discover?.showFieldFilterDialog === FilterDialogTypes.AGGREGATE,
    };
  }),
  withProps((ownProps: any) => {
    let ops = FilterOperators.forFilterType(
      ownProps.filterType,
      ownProps.filterSubType,
    );
    if (!ownProps.dynamicField) {
      ops = omit(ops, 'dynamicField');
    }
    const first = Object.values(ops)[0]?.key;
    return {
      operators: ops,
      defaultSelected: first,
    };
  }),
)(UnconnectedFilterCondition as ComponentClass<IFilterConditionProps>);
