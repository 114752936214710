import component from './component.js';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'react-recompose';
import { TABLE_SELECTORS } from '../../common/redux/selectors/TableSelectors';
import _ from 'lodash';
import Main from '../../common/redux/actions/MainActions';
import { SortDirection } from 'react-virtualized';
import SearchCollection from '../../common/SearchCollection';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setSorting(sortBy, sortDirection) {
      dispatch(Main.setSorting(ownProps.domain, sortBy, sortDirection));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    sortBy: TABLE_SELECTORS.getSortBy(state, ownProps),
    sortDirection: TABLE_SELECTORS.getSortDirection(state, ownProps),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(['data', 'sortBy', 'sortDirection', 'search'], props => {
    const { sortBy, sortDirection, search, searchOptions, disableSort } = props;
    let data = [...props.data];
    /* Search */
    if (!_.isEmpty(search)) {
      const searcher = new SearchCollection(data, searchOptions);
      data = searcher.search(search);
    }
    /* Sort */
    if (!disableSort) {
      data = data.sort((a, b) => {
        for (let i = 0; i < sortBy.length; i++) {
          const key = sortBy[i];
          const dir = sortDirection[key];
          let valA = a[key];
          let valB = b[key];
          if (_.isString(valA)) {
            valA = _.toLower(valA);
            valB = _.toLower(valB);
          }
          if (_.isEqual(SortDirection.ASC, dir)) {
            if (valA > valB) {
              return 1;
            }
            if (valA < valB) {
              return -1;
            }
          } else {
            if (valA > valB) {
              return -1;
            }
            if (valA < valB) {
              return 1;
            }
          }
        }
      });
    }
    return {
      data,
    };
  }),
)(component);
