import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  FunctionComponent,
  HTMLAttributes,
} from 'react';
import { messages } from '../../../i18n';
import { isEmpty, isFinite, map, isNil } from 'lodash';
import shortid from 'shortid';
import { defaultRenderStrategy } from './chart-tooltip.util';
import { ChartTooltipContext } from './chart-tooltip.hook';
import classnames from 'classnames';
import { useReportLinkEnabled } from '../base-cartesian-chart';
import { IViz } from '../../interfaces';
import { useVizOptionSelector } from '../../../common/redux/selectors/viz-selector.hook';
import { ChartTooltipReportLinkNote } from './ChartToolTip';
import {
  KeyValueTooltipContentArg,
  BoundingRect,
  IViewDetailsButton,
} from './chart-tooltip.interface';

/**
 * makeFinite is a utility that fixes some bugs
 * @param num
 */
const makeFinite = num => (isFinite(num) ? num : 0);

/**
 * keyValueTooltipContentPattern is a utility for the common pattern for tooltips, listing rows of preformatted labels/values
 */
export const keyValueTooltipContentPattern = (
  rows: KeyValueTooltipContentArg[],
) => {
  return map(rows, ({ name = '', value = '' }) => {
    return (
      <div className='tooltip-entry'>
        <span className='chart-tooltip-label'>{name}</span>
        <span className='chart-tooltip-value'>{value}</span>
      </div>
    );
  });
};

/**
 * ChartTooltip (the functional component) is heavily derived from the class-based ChartTooltip.
 * It is not a fully-functional replacement - just enough to refactor the unnecessary complexity out.
 * @param props
 * @constructor
 */
export const ChartTooltip: FunctionComponent<HTMLAttributes<SVGGElement>> = ({
  className,
}) => {
  const {
    vizId,
    container = null,
    anchor: { posX: suggestedAnchorX = 0, posY: suggestedAnchorY = 0 } = {},
    tooltipId,
    tooltipData,
  } = useContext(ChartTooltipContext);

  const allowDrillLink = useReportLinkEnabled(vizId);
  const tooltipInnerContentRef = useRef<HTMLDivElement>(null);
  const tooltipOuterContentRef = useRef<SVGGElement>(null); // used for d3 to mouse events
  const [tooltipKey, setTooltipKey] = useState<string>('tooltip');
  const [showTooltip, setShowTooltip] = useState(false);

  const arrowHeight = 8;
  const tooltipArrowWidth = 6;
  const tooltipTextOuterPadding = 8;
  const tooltipPaddingX = 26;
  const tooltipRectRadius = 2;

  useEffect(() => {
    setTooltipKey(`tooltip-${shortid.generate()}`);
  }, []);

  const isDrillLinkingEnabled = useReportLinkEnabled(vizId) && allowDrillLink;
  const linkToReport: IViz | {} = useVizOptionSelector({
    discoveryId: vizId,
    option: 'linkToReport',
    defaultValue: {},
  });

  useEffect(() => {
    // eventually will have timeout based on inactivity here
    if (!isNil(tooltipData) && !isEmpty(tooltipData)) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [setShowTooltip, tooltipData]);

  const tooltipContent = keyValueTooltipContentPattern(tooltipData);

  const { width: mousableAreaWidth = 0, height: mousableAreaHeight = 0 } =
    container?.getBoundingClientRect() ?? {};

  const { current: _tooltipContentRef } = tooltipInnerContentRef ?? {};
  const { innerHTML: tooltipHTML = '' } = _tooltipContentRef ?? {};
  const hasContent = !isEmpty(tooltipHTML);

  const {
    width: tooltipInnerContentWidth = 0,
    height: tooltipInnerContentHeight = 0,
  } = useMemo<Partial<DOMRect>>(() => {
    if (showTooltip && hasContent) {
      // recalculate rect based on content
      return _tooltipContentRef?.getBoundingClientRect() ?? {};
    }
    return {};
  }, [_tooltipContentRef, hasContent, showTooltip]);

  const { tooltipRectHeight, tooltipRectWidth, tooltipWidth } = useMemo(() => {
    const tooltipRectWidth = makeFinite(
      tooltipInnerContentWidth + tooltipTextOuterPadding * 2,
    );
    const tooltipRectHeight = makeFinite(
      tooltipInnerContentHeight + tooltipTextOuterPadding * 2,
    );
    const tooltipWidth = makeFinite(
      tooltipRectWidth + tooltipTextOuterPadding * 2,
    );
    return {
      tooltipRectHeight,
      tooltipRectWidth,
      tooltipWidth,
    };
  }, [
    tooltipInnerContentWidth,
    tooltipInnerContentHeight,
    tooltipTextOuterPadding,
  ]);

  const {
    xPos,
    yPos,
    arrow: arrowDirection,
    arrowYOffset,
    downArrowXOffset,
    upArrowXOffset,
  } = useMemo(() => {
    return defaultRenderStrategy({
      width: mousableAreaWidth,
      height: mousableAreaHeight,
      x: suggestedAnchorX,
      y: suggestedAnchorY,
      arrowHeight,
      tooltipWidth,
      tooltipRectWidth,
      tooltipRectHeight,
      tooltipPaddingX,
      tooltipTextOuterPadding,
    });
  }, [
    mousableAreaWidth,
    mousableAreaHeight,
    suggestedAnchorX,
    suggestedAnchorY,
    arrowHeight,
    tooltipWidth,
    tooltipRectWidth,
    tooltipRectHeight,
    tooltipPaddingX,
    tooltipTextOuterPadding,
  ]);

  // remove from DOM if not visible
  if (!showTooltip) {
    return null;
  }

  return (
    <g>
      <g
        key={tooltipKey}
        id={tooltipId ?? ''}
        style={{ pointerEvents: 'none' }}
        ref={tooltipOuterContentRef}
        className={`chart-tooltip-container${hasContent ? '' : ' invisible'}`}
        transform={`translate(${xPos}, ${yPos})`}
      >
        <rect
          style={{ visibility: 'hidden' }}
          width={tooltipRectWidth}
          height={tooltipRectHeight}
        />
        <path
          className={`leftArrow ${
            arrowDirection === 'left' ? 'visible' : 'hidden'
          }`}
          d='M0 4 L6 0 L6 8 Z'
          transform={`translate(0, ${arrowYOffset})`}
        />
        <path
          className={`rightArrow ${
            arrowDirection === 'right' ? 'visible' : 'hidden'
          }`}
          d='M6 4 L0 0 L0 8 Z'
          transform={`translate(${tooltipRectWidth +
            tooltipArrowWidth}, ${arrowYOffset})`}
        />
        <path
          className={`downArrow ${
            arrowDirection === 'down' ? 'visible' : 'hidden'
          }`}
          d='M0 0 L4 6 L8 0 Z'
          transform={`translate(${tooltipRectWidth / 2 +
            tooltipArrowWidth / 2 +
            downArrowXOffset}, ${tooltipRectHeight})`}
        />
        <path
          className={`upArrow ${
            arrowDirection === 'up' ? 'visible' : 'hidden'
          }`}
          d='M0 8 L4 2 L8 8 Z'
          transform={`translate(${tooltipRectWidth / 2 +
            tooltipArrowWidth / 2 +
            upArrowXOffset}, ${0 - arrowHeight})`}
        />
        <rect
          className='tooltipRect'
          width={tooltipRectWidth}
          height={tooltipRectHeight}
          rx={tooltipRectRadius}
          ry={tooltipRectRadius}
          x={tooltipArrowWidth}
          y='0'
        />
        {/* Safari won't render a foreign object without height & width properties set */}
        <foreignObject width={tooltipRectWidth} height={tooltipRectHeight}>
          <div
            className={classnames(className, 'tooltip-content')}
            ref={tooltipInnerContentRef}
            style={{ paddingLeft: `${tooltipArrowWidth}px` }}
          >
            {tooltipContent}
            {isDrillLinkingEnabled && (
              <ChartTooltipReportLinkNote name={(linkToReport as IViz)?.name} />
            )}
          </div>
        </foreignObject>
      </g>
    </g>
  );
};

/**
 * ViewDetailsButton is an accessory to the main tooltip content
 * @param props: IViewDetailsButton
 * @constructor
 */
const _ViewDetailsButton: FunctionComponent<IViewDetailsButton> = props => {
  // remove the underscore when we use this feature
  const {
    anchor: { posX, posY },
    show,
  } = props;

  const childrenRef = useRef<HTMLDivElement>(null);

  // use d3 to detect onclick?
  const boundingRect = useMemo<BoundingRect>(() => {
    if (!isNil(childrenRef)) {
      return childrenRef?.current?.getBoundingClientRect();
    }
    return {};
  }, [childrenRef]);

  const {
    width: _viewDetailsWidth = 0,
    height: _viewDetailsHeight = 0,
  } = boundingRect;

  return (
    <g
      transform={`translate(${posX}, ${posY})`}
      className={`${show ? 'visible' : 'hidden'}`}
    >
      <foreignObject width={_viewDetailsWidth} height={_viewDetailsHeight}>
        <div className={'chart-tooltip-view-details'} ref={childrenRef}>
          {messages.chartTooltip.viewDetails}
        </div>
      </foreignObject>
    </g>
  );
};
