import _ from 'lodash';
import { messages } from '../../i18n';
import { useQueryError } from './query-error.hook';

export const QueryError = ({ queryError, isAdvancedMode = false }) => {
  const { errorMessage } = useQueryError();
  const renderVariables = () => {
    const { variables } = queryError.queryOptions;
    if (!_.isNil(variables)) {
      return (
        <div className='variables'>
          <strong>{messages.queryError.variables}</strong>
          <pre>{JSON.stringify(variables, undefined, 2)}</pre>
        </div>
      );
    }
  };

  return (
    <div className='query-error'>
      <h3>{messages.queryError.header}</h3>
      <p className='error-message'>{errorMessage}</p>
      {isAdvancedMode && renderVariables()}
    </div>
  );
};
