import { css } from '@emotion/react';

export const navMobileStyles = ({
  theme: {
    colors: {
      Black,
      ContentBackground,
      MediumBorder,
      BaseWhite,
      MainHeaderNavActive,
      DimText,
    },
  },
}) => css`
  #mainHeader {
    border-top: 1px solid ${MediumBorder};
  }
  #mainHeader .menu-items {
    margin: 0;
  }
  #mainHeader.feedActive {
    box-shadow: 0 0 6px 6px ${ContentBackground};
    z-index: 9999;
  }
  #mainHeader .navbar-nav li,
  #mainHeader .navbar-nav li:hover {
    border: none;
    background-color: ${BaseWhite};
    color: ${DimText};
    font-weight: 400;
    font-style: inherit;
    font-size: inherit;
    color: inherit;
  }
  #mainHeader .navbar-nav.menu-items li:not(.disabled):hover {
    border-top: none;
  }
  #mainHeader .navbar-nav li:not(.disabled):hover {
    background-color: ${ContentBackground};
    color: ${DimText};
    font-weight: 400;
    font-style: inherit;
    font-size: inherit;
    color: inherit;
  }
  #mainHeader .navbar-nav li.active:not(.disabled) {
    border-top: none;
    border-right: 4px solid ${MainHeaderNavActive};
    border-left: 4px solid ${MainHeaderNavActive};
  }
  .navbar-collapse {
    z-index: 2000;
    position: relative;
    background-color: ${ContentBackground};
  }
  .accountNavItem {
    display: none;
  }
  .menu-item.mobile {
    display: block;
  }
  .mainHeader__main-menu {
    display: none;
    z-index: 1020;
    position: absolute;
    right: 0;
    left: 0;
    top: 50px;
    background-color: ${ContentBackground};
    margin: 0 0;
    padding: 0;
    overflow: hidden;
    box-shadow: 0px -4px 20px ${Black};
  }
  .mainHeader__main-menu__list {
    margin: 0;
  }
  .navbar-header {
    display: none;
  }
  #mainDiv .vbox {
    flex-direction: column-reverse;
  }
  #mainDiv .vbox.preserve-ordering {
    flex-direction: column;
  }
  #mainHeader .container {
    padding: 0;
  }
  /*  MOBILE    */
  .MobileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin: 0px 30px;
  }
`;
