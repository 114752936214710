import { Component, ComponentClass } from 'react';
import _ from 'lodash';
import { compose } from 'react-recompose';
import { FormGroup, Radio } from '@sugar-discover/react-bootstrap-wrapper';
import {
  Condition,
  FilterTypes,
  LogicalOperators,
  LogicalOperatorType,
} from './Filter';
import { FilterCondition } from './filter-condition';
import { Tooltip } from '../../components/ui/tooltip';
import { messages } from '../../i18n';
import shortid from 'shortid';
import {
  FilterType,
  FilterSubType,
  IExpression,
  IExpressionSegment,
  IFilter,
  FilterDialogTypes,
} from '../../datasets/interfaces/filter.interface';
import styled from '@emotion/styled';
import { DisableableDiv } from '../../common/emotion';
import {
  decodeExpressionTree,
  insertIntoFilterExpression,
} from './filter.utils';
import { AddConditionIcon, RemoveConditionIcon } from '../../icons/icons';
import { css } from '@emotion/react';
import { connect } from 'react-redux';
import { AggregateCondition } from './aggregate-condition';
import { IAnyAttribute } from '../../datasets';
import { SCROLL_ROW_HEIGHT } from '../slicer';

interface IPropTypes {
  onChange: any;
  filterType: FilterType;
  filterSubType?: FilterSubType;
  expression: IExpression;
  field: IAnyAttribute;
  isFilterAggregateDialog: boolean;
  vizId?: string;
  filter?: IFilter;
}

interface ITrackedCondition extends IExpressionSegment {
  reactKey: string;
}

interface IStateTypes {
  expressionOperator?: LogicalOperatorType;
  conditions?: ITrackedCondition[];
  allowManyConditions?: boolean;
}

class FilterExp extends Component<IPropTypes, IStateTypes> {
  state: IStateTypes;

  constructor(props) {
    super(props);

    const conditionsFromExpression: IExpressionSegment[] = decodeExpressionTree(
      this.props.expression,
    );

    this.state = {
      conditions: _.map(conditionsFromExpression, _condition => ({
        ..._condition,
        reactKey: shortid.generate(),
      })),
      expressionOperator:
        _.get(props, 'expression.operator') ?? LogicalOperators.AND,
      allowManyConditions: _.includes(
        [FilterTypes.DATE, FilterTypes.STRING],
        props.filterType,
      ),
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState, this.state)) {
      this._onChange();
    }
  }

  onConditionChange(changingCondition) {
    const conditions = _.map(this.state.conditions, _condition => {
      if (_condition.reactKey === changingCondition.reactKey) {
        return changingCondition;
      }

      return _condition;
    });

    this.setState({
      conditions,
    });
  }

  removeCondition(conditionTrackingKey) {
    const conditions = _.reject(this.state.conditions, {
      reactKey: conditionTrackingKey,
    });

    this.setState({
      conditions,
    });
  }

  addCondition() {
    const emptyCondition = { ...new Condition(), reactKey: shortid.generate() };
    this.setState({
      conditions: [...this.state.conditions, emptyCondition],
    });
  }

  buildExpressionFromConditions(): IExpression | null {
    let expression: IExpression = null;
    const untrackedConditions: IExpressionSegment[] = _.map(
      this.state.conditions,
      _condition => _.omit(_condition, 'reactKey'),
    );
    _.forEach(untrackedConditions, (_condition: IExpressionSegment) => {
      expression = insertIntoFilterExpression(
        expression,
        _condition,
        this.state.expressionOperator,
      );
    });
    return expression;
  }

  _onChange() {
    const _expression = this.buildExpressionFromConditions();

    if (_.isFunction(this.props.onChange)) {
      this.props.onChange(_expression);
    }
  }

  operatorChanged(event) {
    this.setState({ expressionOperator: event.currentTarget.value });
  }

  renderCondition(trackedCondition: ITrackedCondition) {
    const { filterType, filterSubType, field } = this.props;
    const { conditions, allowManyConditions } = this.state;
    const isLast = _.last(conditions)?.reactKey === trackedCondition.reactKey;
    const supportsManyConditions = filterType !== FilterTypes.BOOLEAN;
    return (
      <ConditionWrapper
        key={trackedCondition.reactKey}
        isFilterAggregateDialog={this.props.isFilterAggregateDialog}
        style={{
          marginBottom: this.state.conditions?.length > 1 ? '0.75rem' : 0,
        }}
      >
        <FilterCondition
          id={`condition-${trackedCondition.reactKey}`}
          onChange={_untrackedCondition => {
            this.onConditionChange({
              ..._untrackedCondition,
              reactKey: trackedCondition.reactKey,
            });
          }}
          condition={_.omit(trackedCondition, 'reactKey')}
          filterType={filterType}
          filterSubType={filterSubType}
          field={field}
        />
        {supportsManyConditions && (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {conditions.length >= 2 && (
              <SvgIconButton
                image='remove'
                tooltip={messages.filters.removeConditionTooltip}
                onClick={() => {
                  this.removeCondition(trackedCondition.reactKey);
                }}
              />
            )}

            {((allowManyConditions && isLast) || conditions.length < 2) && (
              <SvgIconButton
                image='add'
                tooltip={messages.filters.addConditionTooltip}
                onClick={() => {
                  this.addCondition();
                }}
              />
            )}
          </span>
        )}
      </ConditionWrapper>
    );
  }

  render() {
    const { expressionOperator } = this.state;
    const isLogicalOpDisabled = this.state.conditions?.length <= 1;
    const showLogicalOpsSection =
      !_.isNil(expressionOperator) &&
      this.props.filterType !== FilterTypes.BOOLEAN;
    return (
      <>
        <TextHeaderSection
          style={{
            marginBottom: '1.5em',
          }}
          className='condition-inputs'
        >
          {showLogicalOpsSection && (
            <DisableableDiv disabled={isLogicalOpDisabled}>
              <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                {messages.filters.combineConditionsUsing}
              </div>
              <FormGroup
                className={'logical-operator-control'}
                style={{ display: 'inline-block', verticalAlign: 'middle' }}
              >
                <Radio
                  className={'logical-operator'}
                  name='logical-operator'
                  checked={expressionOperator === LogicalOperators.AND}
                  inline
                  onChange={e => this.operatorChanged(e)}
                  value={LogicalOperators.AND}
                  disabled={isLogicalOpDisabled}
                >
                  {messages.filters.and}
                </Radio>
                <Radio
                  className={'logical-operator'}
                  name='logical-operator'
                  checked={expressionOperator === LogicalOperators.OR}
                  inline
                  onChange={e => this.operatorChanged(e)}
                  value={LogicalOperators.OR}
                  disabled={isLogicalOpDisabled}
                >
                  {messages.filters.or}
                </Radio>
              </FormGroup>
            </DisableableDiv>
          )}
        </TextHeaderSection>
        <ConditionWrapper
          isFilterAggregateDialog={this.props.isFilterAggregateDialog}
          style={{
            alignItems:
              this.state.conditions?.length > 1 ? 'flex-start' : 'center',
          }}
        >
          <TextHeaderSection
            style={{
              height: `${SCROLL_ROW_HEIGHT}px`,
              lineHeight: `${SCROLL_ROW_HEIGHT}px`,
            }}
          >
            {this.props.isFilterAggregateDialog
              ? messages.filters.showItemsWhenThe
              : this.props.filterType === FilterTypes.STRING
              ? messages.filters.showItemsWhenTheValue
              : messages.filters.showItemsWhenValueIs}
          </TextHeaderSection>
          {this.props.isFilterAggregateDialog && (
            <AggregateCondition vizId={this.props.vizId} />
          )}
          <div className='condition'>
            {_.map(this.state.conditions, _condition => {
              return this.renderCondition(_condition);
            })}
          </div>
        </ConditionWrapper>
      </>
    );
  }
}

const conditionSwitchStyles = css`
  display: inline-block;
  vertical-align: middle;
  margin-left: 1em;
`;

const AddConditionStyled = styled(AddConditionIcon)`
  ${conditionSwitchStyles}
`;

const RemoveConditionStyled = styled(RemoveConditionIcon)`
  ${conditionSwitchStyles}
`;

const SvgIconButton = props => {
  const svg =
    props.image === 'add' ? (
      <AddConditionStyled
        onClick={() => {
          props.onClick();
        }}
      />
    ) : (
      <RemoveConditionStyled
        onClick={() => {
          props.onClick();
        }}
      />
    );

  return (
    <Tooltip placement='top' title={props.tooltip} enterDelay={750} arrow>
      {svg}
    </Tooltip>
  );
};

const mapStateToProps = state => {
  return {
    isFilterAggregateDialog:
      state?.discover?.showFieldFilterDialog === FilterDialogTypes.AGGREGATE,
  };
};

const FilterExpression = compose<IPropTypes, any>(
  (connect as any)(mapStateToProps),
)(FilterExp as ComponentClass<IPropTypes>);

export default FilterExpression;

const TextHeaderSection = styled.div``;

const ConditionWrapper = styled.div<{ isFilterAggregateDialog?: boolean }>(
  ({ isFilterAggregateDialog }) => {
    if (isFilterAggregateDialog) {
      return css`
        display: flex;
        align-items: start;
        gap: 0.75rem;
        margin-bottom: 1.8rem;

        ${TextHeaderSection} {
          margin-bottom: 1.8rem;

          &:not(.condition-inputs) {
            align-self: start;
            line-height: 28px;
          }
        }
      `;
    }
    return css`
      display: flex;
      align-content: center;
      gap: 0.75rem;
    `;
  },
);
