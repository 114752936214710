import _ from 'lodash';
import { Hierarchy } from './VizUtil';
import {
  NON_FRACTIONAL_PERCENT_FORMATTER,
  PriorPeriodTypes,
  Types,
} from '../common/Constants';
import { messages } from '../i18n';

const PriorPeriodConversions = [
  {
    attr: Hierarchy.TIME_ATTRIBUTES.MONTH,
    inAttr: Hierarchy.TIME_ATTRIBUTES.QTR,
    units: 3,
    alsoInclude: [
      Hierarchy.TIME_ATTRIBUTES.YEAR,
      Hierarchy.TIME_ATTRIBUTES.QTR,
    ],
  },
  {
    attr: Hierarchy.TIME_ATTRIBUTES.MONTH,
    inAttr: Hierarchy.TIME_ATTRIBUTES.YEAR,
    units: 12,
    alsoInclude: [Hierarchy.TIME_ATTRIBUTES.YEAR],
  },

  {
    attr: Hierarchy.TIME_ATTRIBUTES.QTR,
    inAttr: Hierarchy.TIME_ATTRIBUTES.YEAR,
    units: 4,
    alsoInclude: [Hierarchy.TIME_ATTRIBUTES.YEAR],
  },

  {
    attr: Hierarchy.TIME_ATTRIBUTES.WEEK_IN_QTR,
    inAttr: Hierarchy.TIME_ATTRIBUTES.QTR,
    units: 13,
    alsoInclude: [
      Hierarchy.TIME_ATTRIBUTES.YEAR,
      Hierarchy.TIME_ATTRIBUTES.QTR,
    ],
  },
  {
    attr: Hierarchy.TIME_ATTRIBUTES.WEEK_IN_QTR,
    inAttr: Hierarchy.TIME_ATTRIBUTES.YEAR,
    units: 52,
    alsoInclude: [Hierarchy.TIME_ATTRIBUTES.YEAR],
  },

  {
    attr: Hierarchy.TIME_ATTRIBUTES.WEEK,
    inAttr: Hierarchy.TIME_ATTRIBUTES.MONTH,
    units: 5,
    alsoInclude: [
      Hierarchy.TIME_ATTRIBUTES.YEAR,
      Hierarchy.TIME_ATTRIBUTES.MONTH,
    ],
  },
  {
    attr: Hierarchy.TIME_ATTRIBUTES.WEEK,
    inAttr: Hierarchy.TIME_ATTRIBUTES.QTR,
    units: 13,
    alsoInclude: [
      Hierarchy.TIME_ATTRIBUTES.YEAR,
      Hierarchy.TIME_ATTRIBUTES.QTR,
    ],
  },
  {
    attr: Hierarchy.TIME_ATTRIBUTES.WEEK,
    inAttr: Hierarchy.TIME_ATTRIBUTES.YEAR,
    units: 52,
    alsoInclude: [Hierarchy.TIME_ATTRIBUTES.YEAR],
  },
];

export const createPriorPeriodCalcField = (
  valueField,
  priorPeriodType,
  priorPeriodTimeAttribute,
  timeField,
  relatedTimeFields,
) => {
  const isSameAttribute =
    priorPeriodTimeAttribute.key === timeField.timeAttribute.key;
  let helperText;
  let { formatType } = valueField;

  if (priorPeriodType === PriorPeriodTypes.PCT_CHANGE_FROM) {
    formatType = NON_FRACTIONAL_PERCENT_FORMATTER.formatType;
  }

  if (isSameAttribute) {
    if (priorPeriodType === PriorPeriodTypes.VALUE_OF) {
      // Ex: Prior month ==> Pr Mo
      helperText = messages.formatString(
        messages.priorPeriod.abbreviationPriorTimePeriod,
        _.get(
          messages,
          priorPeriodTimeAttribute.abbreviation,
          priorPeriodTimeAttribute.abbreviation,
        ),
      );
    } else {
      // Ex: Change vs prior month ==> Chg vs Pr Mo
      helperText = messages.formatString(
        messages.priorPeriod.abbreviationPeriodTypeVsTimePeriod,
        _.get(
          messages,
          priorPeriodType.abbreviation,
          priorPeriodType.abbreviation,
        ),
        _.get(
          messages,
          priorPeriodTimeAttribute.abbreviation,
          priorPeriodTimeAttribute.abbreviation,
        ),
      );
    }
  } else {
    if (priorPeriodType === PriorPeriodTypes.VALUE_OF) {
      // Ex: Same month in prior quarter ==> Same Mo in Pr Qtr
      helperText = messages.formatString(
        messages.priorPeriod.abbreviationSameTimeAttributeInPriorTimePeriod,
        _.get(
          messages,
          timeField.timeAttribute.abbreviation,
          timeField.timeAttribute.abbreviation,
        ),
        _.get(
          messages,
          priorPeriodTimeAttribute.abbreviation,
          priorPeriodTimeAttribute.abbreviation,
        ),
      );
    } else {
      // Ex: Percent change month vs prior quarter ==> % Chg vs Same Mo in Pr Qtr
      helperText = messages.formatString(
        messages.priorPeriod
          .abbreviationPeriodTypeVsSameTimeAttributeInPriorTimePeriod,
        _.get(
          messages,
          priorPeriodType.abbreviation,
          priorPeriodType.abbreviation,
        ),
        _.get(
          messages,
          timeField.timeAttribute.abbreviation,
          timeField.timeAttribute.abbreviation,
        ),
        _.get(
          messages,
          priorPeriodTimeAttribute.abbreviation,
          priorPeriodTimeAttribute.abbreviation,
        ),
      );
    }
  }
  // name format: {Base Field Name} ({Prior Period Type Abbr}{Prior Period Time Field Type abbr.)
  const name = `${valueField.name} (${helperText})`;

  // let conversionUnits = 1
  let conversion;
  if (!isSameAttribute) {
    conversion = PriorPeriodConversions.find(
      con =>
        _.isEqual(con.attr, timeField.timeAttribute) &&
        _.isEqual(con.inAttr, priorPeriodTimeAttribute),
    );
  }
  const requiredFields = relatedTimeFields.filter(availableTimeField => {
    if (_.isNil(conversion)) {
      return false;
    }

    const idx = conversion.alsoInclude.findIndex(ta => {
      return _.isEqual(ta, availableTimeField.timeAttribute);
    });
    return availableTimeField.name !== timeField.name && idx !== -1;
  });

  return {
    name,
    attributeType: Types.PRIOR_PERIOD_CALC,
    defaultAggregation: valueField.defaultAggregation,
    formatType,
    priorPeriodType,
    priorPeriodTimeAttribute, // this may not be consumed anywhere
    timeField,
    valueField,
    priorPeriodConversion: conversion,
    requiredFields,
  };
};
