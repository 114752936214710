import { IQuery } from '../../../discovery';
import _ from 'lodash';

export enum ComparisonPeriodType {
  DAY,
  WEEK,
  MONTH,
  QUARTER,
  YEAR,
}

export const ComparisonPeriodTypeOptions = _.filter(
  ComparisonPeriodType,
  _.isString,
);

export interface IBaseComparisonPeriodConfig {
  lowerThreshold?: { [key: string]: number };
  upperThreshold?: { [key: string]: number };
  comparisonPeriodType?: string;
  comparisonPeriodNum?: number;
  inverted?: boolean;
  labels?: { [key: string]: string };
  annotations?: { [key: string]: string };
}

export interface IComparisonMonitorConfig extends IBaseComparisonPeriodConfig {
  query?: IQuery;
  id?: string;
  datasetId?: string;
  name?: string;
  titleText?: string;
  bodyText?: string;
  linkText?: string;
  smsText?: string;
  isDisabled?: boolean;
  icon?: string;
}

export interface IComparisonMonitorCreationArgs {
  name: string;
}
