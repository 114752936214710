import { useCallback, useEffect, useRef, useState, ChangeEvent } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import { messages } from '../../../../i18n';
import moment from '../../../../common/Moment';
import Calendar from 'rc-calendar';
import enUS from 'rc-calendar/lib/locale/en_US';
import { Moment } from 'moment';
import _, { isNil } from 'lodash';
import {
  DatePickerStyled,
  DatasetSectionTitle,
  DatePreviewBox,
  DatePreviewBoxData,
  DatasetDateSelectionContainer,
  CalendarFieldInputFormControl,
  FiscalDatasetSection,
  RadioStyled,
  FormControlLabelStyled,
  InputGroup,
} from './fiscal-calendar.styles';
import { IDatasetSettings } from '../..';
import { IPanelPropsInterface } from '..';
import {
  FISCAL_CALENDAR_START_DATE,
  FISCAL_CALENDAR_YEAR_TYPE,
} from '../../../../common';
import { useDatasetSettingsContext } from '../../edit-dataset-settings.context';
import {
  MONTHDAYYEAR_NUM,
  MONTHDAYYEAR_SPELLED,
  FiscalYearStartType,
  getDefaultFiscalCalendarStartDate,
  isValid,
  setTimeToZero,
  isFiscalEqualToCalendar,
  getEndingGregorianDate,
} from './fiscal-calendar.utils';
import { Calendar as CalendarIcon } from '../../../../icons';

interface IProps extends IPanelPropsInterface {
  isAppAdmin?: boolean;
  onChange: (val: IDatasetSettings) => void;
}

export const FiscalCalendarSettings = ({
  isAppAdmin = false,
  onChange,
}: IProps) => {
  const { dataset, datasetSettings } = useDatasetSettingsContext();

  const fiscalCalendarStartDateOriginal = _.find(datasetSettings?.annotations, {
    key: FISCAL_CALENDAR_START_DATE,
  })?.value;
  const fiscalCalendarYearTypeOriginal = _.find(datasetSettings?.annotations, {
    key: FISCAL_CALENDAR_YEAR_TYPE,
  })?.value;

  const [
    fiscalCalendarStartDateFormatted,
    setFiscalCalendarStartDateFormatted,
  ] = useState(fiscalCalendarStartDateOriginal);
  const [fiscalCalendarYearType, setFiscalCalendarYearType] = useState(
    fiscalCalendarYearTypeOriginal,
  );
  const datePickerElement = useRef(null);

  const calendar = (
    <Calendar
      locale={enUS}
      dateInputPlaceholder={messages.filters.pleaseInput}
      disabledTime={momentDate => momentDate}
      showDateInput={false}
      onSelect={() => {
        if (!isNil(datePickerElement?.current)) {
          datePickerElement.current.close();
        }
      }}
    />
  );

  const getFiscalStartOrDefault = useCallback((): Moment => {
    return isNil(fiscalCalendarStartDateFormatted)
      ? getDefaultFiscalCalendarStartDate() // default to Jan 1st
      : setTimeToZero(moment(fiscalCalendarStartDateFormatted));
  }, [fiscalCalendarStartDateFormatted]);

  const getFiscalCalendarStartOrDefault = useCallback((): Moment => {
    // set to default if no fiscal start date defined
    const fiscalCalendarStartDate = isNil(fiscalCalendarStartDateFormatted)
      ? getDefaultFiscalCalendarStartDate()
      : setTimeToZero(moment(fiscalCalendarStartDateFormatted));

    return fiscalCalendarStartDate;
  }, [fiscalCalendarStartDateFormatted]);

  const getFiscalStartYearTypeOrDefault = useCallback((): string => {
    return isNil(fiscalCalendarYearType)
      ? FiscalYearStartType.START
      : fiscalCalendarYearType;
  }, [fiscalCalendarYearType]);

  const doChange = useCallback(() => {
    const fiscalCalendarYearType: string = getFiscalStartYearTypeOrDefault();
    const fiscalCalendarStartDate: Moment = getFiscalCalendarStartOrDefault();
    const fiscalCalendarStartDefault: Moment = getDefaultFiscalCalendarStartDate();
    if (
      isValid({
        fiscalCalendarYearType,
        fiscalCalendarStartDate,
      })
    ) {
      let fiscalCalendarStartDateFormatted = getFiscalStartOrDefault().format(
        moment.ISO8601,
      );
      let fiscalCalendarYearType = getFiscalStartYearTypeOrDefault();

      // set fiscalCalendarStartDate to null before save if start date is Jan 1st
      if (
        isFiscalEqualToCalendar({
          fiscalCalendarStartDate,
          fiscalCalendarStartDefault,
        })
      ) {
        fiscalCalendarStartDateFormatted = null;
        fiscalCalendarYearType = null;
      }

      const fiscalAnnotations = [
        {
          key: FISCAL_CALENDAR_START_DATE,
          value: fiscalCalendarStartDateFormatted,
        },
        {
          key: FISCAL_CALENDAR_YEAR_TYPE,
          value: fiscalCalendarYearType,
        },
      ];

      // remove old fiscal calendar data
      let updatingAnnotations = _.reject(
        datasetSettings?.annotations,
        _annotation => {
          return (
            _annotation.key === FISCAL_CALENDAR_START_DATE ||
            _annotation.key === FISCAL_CALENDAR_YEAR_TYPE
          );
        },
      );

      // add fiscal annotation only if data is valid (all fields that have values)
      if (
        _.size(fiscalAnnotations) === 2 &&
        _.every(fiscalAnnotations, _annotation => _.get(_annotation, 'value'))
      ) {
        updatingAnnotations = _.concat(updatingAnnotations, fiscalAnnotations);
      }

      const datasetSettingsSaveRequest: IDatasetSettings = {
        datasetId: dataset?.id,
        annotations: updatingAnnotations,
      };
      onChange(datasetSettingsSaveRequest);
    }
  }, [
    dataset?.id,
    datasetSettings?.annotations,
    getFiscalCalendarStartOrDefault,
    getFiscalStartOrDefault,
    getFiscalStartYearTypeOrDefault,
    onChange,
  ]);

  const setFiscalDate = date => {
    const zeroTimedDate = setTimeToZero(date).format(moment.ISO8601);
    setFiscalCalendarStartDateFormatted(zeroTimedDate);
  };

  const getStartingGregorianDate = (): Moment => {
    return getFiscalStartOrDefault(); // alias
  };

  const getStartingFiscalDate = (): Moment => {
    const fiscalStartYearType = getFiscalStartYearTypeOrDefault();
    const gregorianStart = getStartingGregorianDate();
    if (fiscalStartYearType === FiscalYearStartType.END) {
      gregorianStart.set({
        year: getEndingGregorianDate(gregorianStart).get('year'),
      });
    }

    return gregorianStart;
  };

  const getEndingFiscalDate = (): Moment => {
    const fiscalStartYearType = getFiscalStartYearTypeOrDefault();
    const gregorianStart = getStartingGregorianDate();
    const endDate = moment(getEndingGregorianDate(gregorianStart));
    if (fiscalStartYearType === FiscalYearStartType.START) {
      endDate.set({
        year: gregorianStart.get('year'),
      });
    }

    return endDate;
  };

  const setFiscalYearType = yearType => {
    setFiscalCalendarYearType(yearType);
  };

  useEffect(() => {
    if (
      !_.isEqual(
        fiscalCalendarStartDateFormatted,
        fiscalCalendarStartDateOriginal,
      ) ||
      !_.isEqual(fiscalCalendarYearType, fiscalCalendarYearTypeOriginal)
    ) {
      doChange();
    }
  }, [
    doChange,
    fiscalCalendarStartDateFormatted,
    fiscalCalendarStartDateOriginal,
    fiscalCalendarYearType,
    fiscalCalendarYearTypeOriginal,
  ]);

  const radioGroupDisabled = isFiscalEqualToCalendar({
    fiscalCalendarStartDate: getFiscalCalendarStartOrDefault(),
    fiscalCalendarStartDefault: getDefaultFiscalCalendarStartDate(),
  });

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFiscalYearType((event.target as HTMLInputElement).value);
  };

  const gregorianStart = getStartingGregorianDate();
  const startGregorian = gregorianStart.format(MONTHDAYYEAR_SPELLED);
  const endGregorian = getEndingGregorianDate(gregorianStart).format(
    MONTHDAYYEAR_SPELLED,
  );
  const startFiscal = getStartingFiscalDate().format(MONTHDAYYEAR_SPELLED);
  const endFiscal = getEndingFiscalDate().format(MONTHDAYYEAR_SPELLED);

  return (
    <div>
      <FiscalDatasetSection>
        <DatasetDateSelectionContainer>
          <DatasetSectionTitle>
            {messages.editDatasetPanel.chooseFiscalYearStartDate}
          </DatasetSectionTitle>

          <DatePickerStyled
            animation='slide-up'
            calendar={calendar}
            value={getFiscalStartOrDefault()}
            placement='bottomLeft'
            prefixCls='corvana-calendar-picker'
            ref={datePickerElement}
            onChange={setFiscalDate}
          >
            {() => (
              <InputGroup>
                <CalendarFieldInputFormControl
                  disabled={!isAppAdmin}
                  className='datetime-textbox'
                  placeholder={messages.filters.pleaseSelect}
                  key='numeric-condition-operand-key'
                  value={getFiscalStartOrDefault().format(MONTHDAYYEAR_NUM)}
                />
                <CalendarIcon />
              </InputGroup>
            )}
          </DatePickerStyled>
        </DatasetDateSelectionContainer>
      </FiscalDatasetSection>

      <FiscalDatasetSection>
        <DatasetSectionTitle>
          {messages.editDatasetPanel.displayFiscalYearBasedOn}
        </DatasetSectionTitle>
        <RadioGroup
          name='fiscal-year-type'
          value={getFiscalStartYearTypeOrDefault()}
          onChange={handleRadioChange}
        >
          <FormControlLabelStyled
            value={FiscalYearStartType.START}
            disabled={!isAppAdmin || radioGroupDisabled}
            control={<RadioStyled />}
            label={messages.editDatasetPanel.startMonth}
          />
          <FormControlLabelStyled
            value={FiscalYearStartType.END}
            disabled={!isAppAdmin || radioGroupDisabled}
            control={<RadioStyled />}
            label={messages.editDatasetPanel.endMonth}
          />
        </RadioGroup>
      </FiscalDatasetSection>

      <FiscalDatasetSection>
        <DatasetSectionTitle>
          {messages.editDatasetPanel.preview}
        </DatasetSectionTitle>
        <DatePreviewBox>
          <DatasetSectionTitle>
            {messages.editDatasetPanel.dateRange}:
          </DatasetSectionTitle>
          <DatePreviewBoxData className='gregorian-dates'>
            {startGregorian} - {endGregorian}
          </DatePreviewBoxData>

          <DatasetSectionTitle>
            {messages.editDatasetPanel.fiscalCalendar}
          </DatasetSectionTitle>
          <DatePreviewBoxData className='fiscal-dates'>
            {startFiscal} - {endFiscal}
          </DatePreviewBoxData>
        </DatePreviewBox>
      </FiscalDatasetSection>
    </div>
  );
};
