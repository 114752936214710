import { useMemo } from 'react';
import { useDiscoverTheme } from '../../common/emotion/theme';
import { SugarIconColorType } from '../common';

export const useIconColor = ({
  iconType,
  color: _color,
  disabled = false,
}: {
  iconType: SugarIconColorType;
  color?: string;
  disabled?: boolean;
}) => {
  const {
    darkMode,
    colors: {
      ErrorColor,
      DangerColor,
      SugarGrayPalette: { Gray90 } = {} as any,
      InfoColor,
      PrimaryColor,
      SuccessColor,
      ToolIconColorSecondary,
      WarningColor,
      DefaultIconColor,
      GrayIconColor,
      DisabledIconColor,
    } = {} as any,
  } = useDiscoverTheme();

  const color = useMemo(() => {
    if (_color) {
      return _color;
    } else if (disabled) {
      return DisabledIconColor;
    }
    switch (iconType) {
      case 'error':
        return ErrorColor;
      case 'danger':
        return DangerColor;
      case 'warning':
        return WarningColor;
      case 'info':
        return InfoColor;
      case 'success':
        return SuccessColor;
      case 'primary':
        return PrimaryColor;
      case 'secondary':
        return ToolIconColorSecondary;
      case 'light':
        return GrayIconColor;
      case 'medium':
        return DefaultIconColor;
      case 'dark':
        return darkMode ? GrayIconColor : Gray90;
      default:
        return DefaultIconColor;
    }
  }, [
    _color,
    disabled,
    iconType,
    GrayIconColor,
    ErrorColor,
    DangerColor,
    WarningColor,
    InfoColor,
    SuccessColor,
    PrimaryColor,
    ToolIconColorSecondary,
    Gray90,
    DefaultIconColor,
    darkMode,
    DisabledIconColor,
  ]);

  return color;
};
