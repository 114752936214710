import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '../../ui/button';
import { SpinnerLoader } from './Status';

import CompleteSvg from '../../../images/sdd/icon_success_inverted.svg';
import { messages } from '../../i18n';
import { withTheme } from '@emotion/react';

const LoadingButton = props => {
  const {
    loading,
    loadingText,
    complete,
    completeText,
    iconSize,
    iconColor,
    children,
    className,
    ...otherProps
  } = props;
  const text = loading ? loadingText : complete ? completeText : children;
  const _onClick = () => {
    // don't let anyone click on the button while it's loading or when load is complete
    if (!props.loading && !props.complete && _.isFunction(props.onClick)) {
      props.onClick();
    }
  };

  return (
    <Button
      {...otherProps}
      className={`loading-button ${loading ? 'is-loading' : ''} ${
        complete ? 'is-complete' : ''
      } ${className ?? ''}`}
      onClick={() => {
        _onClick();
      }}
    >
      <div
        className={`loading-button-content ${loading ? 'is-loading' : ''} ${
          complete ? 'is-complete' : ''
        }`}
      >
        {props.loading && (
          <SpinnerLoader
            loading={loading}
            size={iconSize}
            color={iconColor || props.theme?.colors?.ContentBackground}
          />
        )}
        {props.complete && <CompleteSvg />}
        <span>{text}</span>
      </div>
    </Button>
  );
};

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  complete: PropTypes.bool,
  completeText: PropTypes.string,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
  bsStyle: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  block: PropTypes.any,
};
LoadingButton.defaultProps = {
  loading: false,
  loadingText: messages.loading,
  complete: false,
  completeText: messages.success,
  iconSize: 20,
};

export default withTheme(LoadingButton);
