import { messages } from '../../../i18n';
import {
  ErrorFallbackBase as ErrorFallback,
  Info,
  ErrorIcon,
} from './default-error-fallback.styes';
import { useDiscoverTheme } from '../../../common/emotion';
import { MediumRed as IconColorDefault } from '../../../common/emotion/theme/colors.styles';
import { FallbackProps } from 'react-error-boundary/dist/declarations/src/types';

export const FallBackMessage = ({ iconColor = IconColorDefault }) => [
  <ErrorIcon
    color={iconColor}
    hoverColor={iconColor}
    key={'icon'}
    hover
    size={24}
  />,
  <div key={'title'}>{messages.errorBoundary.title} </div>,
  <Info key={'info'}>
    <span>{messages.errorBoundary.info}</span>
  </Info>,
];

export const DefaultErrorFallback = (_props: FallbackProps) => {
  const { colors: { MediumRed } = {} } = useDiscoverTheme();
  return (
    <ErrorFallback>
      <FallBackMessage iconColor={MediumRed} />
    </ErrorFallback>
  );
};
