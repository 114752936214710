import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Modal } from '@sugar-discover/react-bootstrap-wrapper';
import { IDiscoverEmotionTheme } from '../../../common/emotion';

export const MobileSlicerContainer = styled.div(
  ({ theme: { viewWidth, viewHeight } }: { theme?: IDiscoverEmotionTheme }) =>
    css`
      overflow: hidden;
      width: ${viewWidth};
      height: calc(${viewHeight} - 60px);
      top: 0;
      left: 0;
      padding-top: 10px;
    `,
);

export const MobileSlicerRowContainer = styled.div(
  ({
    theme: { viewWidth, isDashletMode },
  }: {
    theme?: IDiscoverEmotionTheme;
  }) => css`
    width: calc(${viewWidth} - ${isDashletMode ? 26 : 100}px);
    padding-left: 10px;
  `,
);

export const MobileSlicerModal = styled(Modal)(
  ({
    theme: {
      viewHeight = 0,
      viewWidth = 0,
      colors: { ContentBackground } = {} as any,
    } = {},
  }: any) =>
    css({
      '&.modal': {
        zIndex: 81,
      },
      '.modal-dialog': {
        margin: 0,
        backgroundColor: ContentBackground,
        height: viewHeight,
        width: viewWidth,
        '.modal-content': {
          height: viewHeight,
          width: viewWidth,
          paddingRight: 0,
          border: 0,
          boxShadow: 'none',
          '.transition-wrapper>div': {
            width: '100%',
          },
          '.header-navigation': {
            width: viewWidth,
          },
        },
      },
    }),
);
