import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { font } from '../../../common/emotion/mixins';
import { useDiscoverTheme } from '../../../common/emotion';

export const LegendPanel = styled.aside(
  (props: any) => css`
    ${props.isMobile ? `` : `height: 100%;`}
  `,
);

export const LegendContent = ({ children, className }) => {
  const { colors: { ContentText, LightFontWeight } = {} } = useDiscoverTheme();

  return (
    <div
      className={className}
      css={css`
        ${font({ size: '12px', weight: LightFontWeight, color: ContentText })}
        display: flex;
        flex-direction: column;
        padding: 8px;
        user-select: none;
        height: 100%;
        min-width: 80px;
      `}
    >
      {children}
    </div>
  );
};
