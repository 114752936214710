import { includes } from 'lodash';
import { ITimePeriod } from './relative-date-condition.interface';
import { FilterOperators } from '../FilterOperators';
import {
  FilterTypes,
  createFilterForField,
  TimestampFilterSubTypes,
} from '../Filter';

export const RelativeOperators = FilterOperators.forFilterType(
  FilterTypes.DATE,
  TimestampFilterSubTypes.RELATIVE_DATES,
);

export const TimePeriods = {
  MINUTES: {
    key: 'MINUTES',
    moment_val: 'minute',
    display: 'filters.minutes',
    format: 'YYYY-MM-DD HH:mm:ss',
  },
  HOURS: {
    key: 'HOURS',
    moment_val: 'hour',
    display: 'filters.hours',
    format: 'YYYY-MM-DD HH:mm:ss',
  },
  DAYS: {
    key: 'DAYS',
    moment_val: 'day',
    display: 'filters.days',
    format: 'YYYY-MM-DD HH:mm:ss',
  },
  WEEKS: {
    key: 'WEEKS',
    moment_val: 'week',
    display: 'filters.weeks',
    format: 'YYYY-MM-DD HH:mm:ss',
  },
  CALENDAR_WEEKS: {
    key: 'CALENDAR_WEEKS',
    moment_val: 'isoWeek',
    display: 'filters.calendarWeeks',
    fiscalDisplay: 'filters.fiscalWeeks',
    format: 'YYYY-MM-DD HH:mm:ss',
    calendarPeriod: true,
  },
  MONTHS: {
    key: 'MONTHS',
    moment_val: 'month',
    display: 'filters.months',
    format: 'YYYY-MM-DD HH:mm:ss',
  },
  CALENDAR_MONTHS: {
    key: 'CALENDAR_MONTHS',
    moment_val: 'month',
    display: 'filters.calendarMonths',
    fiscalDisplay: 'filters.fiscalMonths',
    format: 'YYYY-MM-DD',
    calendarPeriod: true,
  },
  CALENDAR_QUARTERS: {
    key: 'CALENDAR_QUARTERS',
    moment_val: 'quarter',
    display: 'filters.calendarQuarters',
    fiscalDisplay: 'filters.fiscalQuarters',
    format: 'YYYY-MM-DD',
    calendarPeriod: true,
  },
  YEARS: {
    key: 'YEARS',
    moment_val: 'year',
    display: 'filters.years',
    format: 'YYYY-MM-DD HH:mm:ss',
  },
  CALENDAR_YEARS: {
    key: 'CALENDAR_YEARS',
    moment_val: 'year',
    display: 'filters.calendarYears',
    fiscalDisplay: 'filters.fiscalYears',
    format: 'YYYY',
    calendarPeriod: false,
  },
};

export const FISCAL_CALENDAR_FIELDS = [
  'CALENDAR_MONTHS',
  'CALENDAR_QUARTERS',
  'CALENDAR_YEARS',
  'CALENDAR_WEEKS',
];

export const DaysOfWeek = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

export const getDisplayText = (
  timePeriod: ITimePeriod,
  useFiscalCalendar: boolean,
) => {
  const { key, display } = timePeriod;
  let displayMessage = display;
  if (useFiscalCalendar && includes(FISCAL_CALENDAR_FIELDS, key)) {
    displayMessage = timePeriod.fiscalDisplay;
  }
  return displayMessage;
};

export enum RelativeDateAnchorModes {
  EXACT = 'EXACT',
  PREVIOUS = 'PREVIOUS',
}

export const createFilter = field => {
  const filter = createFilterForField(field);
  filter.expression.left.operator = 'IN_PAST';
  filter.expression.left.operand = [1];

  filter.subType = TimestampFilterSubTypes.RELATIVE_DATES;
  return filter;
};
