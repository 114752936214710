import StackLineSvgIcon from '../../../../images/sdd/viz/visualization-selection-stacked-line.svg';
import ListSvgStackLine from '../../../../images/sdd/viz/stacked-line.svg';
import BaseChartSpec, {
  NON_NUMERIC_CALC,
  Validations,
  ENABLE_REPORT_LINK,
} from '../../BaseChartSpec';
import { ShelfTypes } from '../../../discovery/interfaces';
import { LegendShapes } from '../legend-shape';
import { ChartSpecIds, Types } from '../../../common/Constants';
import _ from 'lodash';
import { getDateTypesFromShelfAsOrdinals } from '../ChartUtils';
import { parseJSON } from '../../../common/Util';

class StackLineSpec extends BaseChartSpec {
  constructor() {
    super({
      id: 'stack_line',
      name: 'stackedLine.chartName',
      placeholderImage: '/assets/images/sdd/chart/canvas-icon-stacked-line.svg',
      icon: <StackLineSvgIcon />,
      listIcon: <ListSvgStackLine />,
      legendShape: LegendShapes.SQUARE,
      shelves: {
        [ChartSpecIds.STACK]: {
          id: ChartSpecIds.STACK,
          name: 'stackedLine.stackShelf',
          aggregateMeasures: false,
          accepts: NON_NUMERIC_CALC,
          shelfType: ShelfTypes.SELECTION,
        },
        [ChartSpecIds.VALUES]: {
          id: ChartSpecIds.VALUES,
          name: 'stackedLine.valuesShelf',
          accepts: [Types.ANY],
          shelfType: ShelfTypes.MEASURE,
          isColumn: true,
        },
        [ChartSpecIds.LINES]: {
          id: ChartSpecIds.LINES,
          name: 'stackedLine.linesShelf',
          accepts: [Types.ANY],
          shelfType: ShelfTypes.MEASURE,
          includeInSortAggregation: false,
        },
        [ChartSpecIds.XAXIS]: {
          id: ChartSpecIds.XAXIS,
          name: 'stackedLine.xAxisShelf',
          accepts: NON_NUMERIC_CALC,
          shelfType: ShelfTypes.SELECTION,
          isRequired: true,
          groupNames: true,
        },
        [ChartSpecIds.SLICER]: {
          id: ChartSpecIds.SLICER,
          name: 'chartSpecs.area.slicer',
          accepts: [Types.STRING, Types.TIMESTAMP],
          isRequired: false,
          shelfType: ShelfTypes.SLICER,
        },
      },
      supportsDifferentiatedNullHandling: true,
      supportsLegendSelection: true,
      showTooltipIndicator: true,
      customFormatToggles: [
        {
          name: 'asPercentage',
          displayLabel: 'stackedLine.asPercentageToggle',
          type: 'property',
          isAvailable: _.stubTrue,
        },
        ENABLE_REPORT_LINK,
      ],
    });
    this.validationRules = [
      Validations.HasLayout,
      Validations.OneOf([this.shelves.XAXIS]),
      Validations.OneOf([this.shelves.LINES, this.shelves.VALUES]),
      Validations.TypeSafe,
    ];
  }
  mapShelvesToQueryVariables(viz) {
    if (!this.validate(viz)) {
      return {
        attributeNames: [],
        measures: [],
      };
    }
    // we want all fields in the STACK shelf plus any ordinalAttributes for those fields
    const stackAttributes = viz.layout.STACK.map(g => g.name);
    const ordinalAttributes = viz.layout.STACK.filter(
      g => !_.isEmpty(g.ordinalAttribute),
    ).map(g => g.ordinalAttribute);
    let attributeNames = viz.layout.XAXIS.map(c => c.name)
      .concat(stackAttributes)
      .concat(ordinalAttributes);

    const customAggregations = parseJSON(viz.options.customAggregations);

    const lineMeasures = viz.layout.LINES.map(v => {
      const aggName = `SHELF_${v.name}`;
      const selectedAggregation =
        customAggregations[aggName] || v.defaultAggregation;
      return {
        attributeName: v.name,
        aggregation: selectedAggregation,
        resultSetFunction: 'NONE',
      };
    });
    const valuesMeasures = viz.layout.VALUES.map(v => {
      const aggName = `SHELF_${v.name}`;
      const selectedAggregation =
        customAggregations[aggName] || v.defaultAggregation;
      return {
        attributeName: v.name,
        aggregation: selectedAggregation,
        resultSetFunction: 'NONE',
      };
    });

    attributeNames = attributeNames.concat(
      getDateTypesFromShelfAsOrdinals(viz.layout.XAXIS),
    );

    return {
      attributeNames: _.uniq(attributeNames),
      measures: [...lineMeasures, ...valuesMeasures],
    };
  }

  mapSubtotalsToQuery(viz) {
    let subtotals;

    // if we have fields in STACK shelf, we are aggregating lines and stacks at different granularity
    if (!_.isEmpty(viz.layout.STACK) && !_.isEmpty(viz.layout.LINES)) {
      // Lines should only be based on the x-axis (subtotals of the attributes without regard to the the stacks)
      let subtotalsAttributeNames = viz.layout.XAXIS.map(attr => attr.name);
      subtotalsAttributeNames = subtotalsAttributeNames.concat(
        getDateTypesFromShelfAsOrdinals(viz.layout.XAXIS),
      );
      subtotals = { attributeNames: subtotalsAttributeNames };
    }
    return {
      subtotals,
    };
  }
}

export default StackLineSpec;
