import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IDiscoverEmotionTheme } from '../../../common/emotion';

export const CombinedFieldTypeContainer = styled.div<{
  size: number;
  left: number;
}>`
  ${({ size = 24, left = 12 }) => {
    const spaceBetween = 0;
    return css`
      width: ${size}px;
      max-width: ${size}px;
      margin-right: 6px;
      text-align: center;
      display: grid;
      grid-template-columns: [left] ${left}px [middle] ${size / 2}px [right];
      grid-template-rows: 1fr;
      grid-column-gap: ${spaceBetween}px;

      .left {
        grid-column-start: left;
        grid-column-end: middle;
        max-width: 100%;
      }
      .right {
        grid-column-start: middle;
        grid-column-end: right;
        max-width: 100%;
      }
    `;
  }}
`;

export const TextIcon = styled.span(
  ({
    theme: { colors: { IconGreenColor } = {} as any } = {} as any,
  }: {
    theme?: IDiscoverEmotionTheme;
  }) => css`
    color: ${IconGreenColor};
    font-size: 10px;
    margin: 0 4px 0 2px;
    font-family: monospace;
    text-align: center;
    letter-spacing: 0.24px;
    width: 24px;
  `,
);
