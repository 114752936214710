import { useCallback } from 'react';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { withTheme } from '@emotion/react';
import { IDiscoverSubMenuProps } from '../interfaces';
import Discover from '../../../common/redux/actions/DiscoverActions';
import { IconByChartType } from '../util';
import { SubMenuComponent } from '../sub-menu';
import { messages } from '../../../i18n';

export const DiscoverSubMenuComponent = ({
  openDiscoveries,
  setDisplayDiscovery,
  setOpenedSubMenu,
  unsetOpenedSubMenu,
}: IDiscoverSubMenuProps) => {
  const handleDiscoverSubMenuClick = id => {
    setDisplayDiscovery(id);
  };

  const setDiscoverOpenedSubMenu = useCallback(() => {
    setOpenedSubMenu('discover');
  }, [setOpenedSubMenu]);

  return (
    <SubMenuComponent
      setOpenedSubMenu={setDiscoverOpenedSubMenu}
      unsetOpenedSubMenu={unsetOpenedSubMenu}
      collapsedTitle={messages.nonTranslated.discover}
      menuItems={Object.entries(openDiscoveries).map(([key, { present }]) => ({
        key,
        icon: present?.viz?.chartType && IconByChartType[present.viz.chartType],
        label: present.name,
        handleOnItemClick: () => handleDiscoverSubMenuClick(present.id),
      }))}
    />
  );
};

const mapStateToProps = function(state) {
  const {
    discover: { openDiscoveries },
  } = state;

  return {
    openDiscoveries,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDisplayDiscovery: id => {
      dispatch(Discover.setDisplayDiscovery(id));
    },
  };
};

export const DiscoverSubMenu = compose<IDiscoverSubMenuProps, {}>(
  connect(mapStateToProps, mapDispatchToProps),
  withTheme,
)(DiscoverSubMenuComponent);
