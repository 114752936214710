import styled from '@emotion/styled';
import { LightFontWeight, MediumFontWeight } from './fonts';
import _ from 'lodash';
import { Tooltip } from '../../components/ui/tooltip';
import { messages } from '../../i18n';

const StyledDiv = styled.div`
  min-height: ${props => (props.isMobile ? '40px' : '48px')};
  padding: 8px ${props => (props.isMobile ? '8px' : '24px')};
  display: flex;
  align-items: center;
  font-size: ${props => (props.isMobile ? '11px' : '14px')};
  strong {
    font-weight: bold;
    margin-left: 5px;
  }
`;

export const ChartSummaryItem = props => {
  return (
    <Tooltip
      id='funnel-summary-tooltip'
      placement={props.tooltipPlacement || 'bottom'}
      enterDelay={500}
      title={props.tooltip}
      arrow
    >
      <StyledDiv>{props.children}</StyledDiv>
    </Tooltip>
  );
};

export const ChartSummaryCard = props => {
  const ValueLabel = styled.div`
    font-size: ${props.isMobile ? '12px' : '20px'};
    display: flex;
    justify-content: center;
    white-space: nowrap;
  `;
  const PaddedDiv = styled.div`
    font-weight: ${LightFontWeight};
    white-space: nowrap;
    padding: 0 ${props.isMobile ? '4px' : '8px'};
  `;
  const MetricDiv = styled.div`
    font-weight: ${MediumFontWeight};
    white-space: nowrap;
    display: inline-block;
  `;
  const LabelDiv = styled.div`
    display: flex;
    justify-content: center;
    font-size: ${props.isMobile ? '11px' : '14px'};
    font-weight: ${LightFontWeight};
    white-space: nowrap;
  `;
  const renderMetric = () => {
    if (!_.isNil(props.selectionMetric)) {
      return (
        <ValueLabel>
          <MetricDiv>{props.selectionMetric}</MetricDiv>
          <PaddedDiv>{messages.funnel.vs}</PaddedDiv>
          <MetricDiv>
            {props.overallMetric} {messages.funnel.overall}
          </MetricDiv>
        </ValueLabel>
      );
    } else {
      return (
        <ValueLabel>
          <MetricDiv>{props.overallMetric}</MetricDiv>
        </ValueLabel>
      );
    }
  };
  return (
    <div>
      {renderMetric()}
      <LabelDiv>{props.label}</LabelDiv>
    </div>
  );
};

export const ChartSummary = props => {
  let Summary;
  if (props.isMobile) {
    Summary = styled.div`
      display: flex;
      justify-content: center;
    `;
  } else {
    Summary = styled.div`
      display: flex;
      justify-content: center;
      position: relative;
      left: ${props.offsetLeft}px;
      width: ${props.width}px;
    `;
  }
  return (
    <Summary className={props.className || 'chart-summary'}>
      {props.children}
    </Summary>
  );
};
