import { withTheme } from '@emotion/react';
import { useState, useEffect, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose, shouldUpdate } from 'react-recompose';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { SUGARCRM_WEBSITE } from '../../common/Constants';
import { getIsReadOnlyUser } from '../../common/redux/selectors/AccountSelectors';
import { HELP_LINK } from '../../common/Constants';
import { messages } from '../../i18n';
import {
  HomeIcon,
  HelpIcon,
  HamburgerIcon,
  StatisticsIcon,
  DatabaseIcon,
  SugarLogoIcon,
  PlusLgIcon,
} from '../../icons';
import { IMainNavigationProps } from './interfaces';
import {
  SidebarToggle,
  Sidebar,
  SidebarNav,
  SidebarNavItem,
  TooltipInner,
  SidebarNavLink,
  Overlay,
} from './main-navigation.styles';
import Util from '../../common/Util';
import { Tooltip } from '../../components/ui/tooltip';
import { DiscoverSubMenu } from './discover-sub-menu';

const NavTooltip = ({ title, isExpanded = false, children }) =>
  isExpanded ? (
    <TooltipInner>{children}</TooltipInner>
  ) : (
    <Tooltip placement='right' arrow title={title}>
      <TooltipInner data-testid='tooltip'>{children}</TooltipInner>
    </Tooltip>
  );

const MainNavigationComponent = ({
  openDiscoveries,
  isReadOnly,
  appUrl = '',
}: IMainNavigationProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [openedSubMenu, setOpenedSubMenu] = useState('');
  const [isCollapsing, setIsCollapsing] = useState(false);
  const discoverEnabled = Object.keys(openDiscoveries).length > 0;

  const menuItemSelected = (e, disabled = false) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    setIsExpanded(false);
  };

  const toggleSidebar = () => setIsExpanded(prevState => !prevState);

  const imgSrc = Util.assetUrl({
    appUrl,
    path: 'sugarcrm_logo_white.svg',
  });

  useEffect(() => {
    let timeout;
    if (!isExpanded) {
      setIsCollapsing(true);
      timeout = setTimeout(() => setIsCollapsing(false), 300);
    }
    return () => clearTimeout(timeout);
  }, [isExpanded]);

  const unsetOpenedSubMenu = useCallback(() => {
    setOpenedSubMenu('');
  }, []);

  const renderContent = () => (
    <Sidebar
      id='mainNav'
      isExpanded={isExpanded}
      isCollapsing={isCollapsing}
      isSubMenuOpened={openedSubMenu !== ''}
    >
      <SidebarToggle onClick={toggleSidebar} data-testid='sidebar-toggle-btn'>
        <NavTooltip title={messages.nav.openSideBar} isExpanded={isExpanded}>
          <HamburgerIcon hover />
        </NavTooltip>
      </SidebarToggle>
      <SidebarNav>
        <SidebarNavItem isSubMenuOpened={openedSubMenu === 'library'}>
          <SidebarNavLink
            to='/library'
            activeClassName='active'
            onClick={e => menuItemSelected(e)}
            data-testid='home-menu-item'
            className={`${openedSubMenu === 'library' ? 'active' : ''}`}
          >
            <NavTooltip title={messages.nav.home} isExpanded={isExpanded}>
              <HomeIcon hover />
            </NavTooltip>
            <span>{messages.nav.home}</span>
          </SidebarNavLink>
        </SidebarNavItem>
        <SidebarNavItem isSubMenuOpened={openedSubMenu === 'library'}>
          <SidebarNavLink
            to='/newviz'
            activeClassName='active'
            onClick={e => menuItemSelected(e)}
            data-testid='quick-create-menu-item'
          >
            <NavTooltip
              title={messages.nav.quickCreate}
              isExpanded={isExpanded}
            >
              <PlusLgIcon hover />
            </NavTooltip>
            <span>{messages.nav.quickCreate}</span>
          </SidebarNavLink>
        </SidebarNavItem>
      </SidebarNav>
      <SidebarNav>
        <SidebarNavItem isSubMenuOpened={openedSubMenu === 'discover'}>
          <SidebarNavLink
            to='/open'
            activeClassName='active'
            disabled={!discoverEnabled}
            onClick={e => menuItemSelected(e, !discoverEnabled)}
            data-testid='discover-menu-item'
            className={`${openedSubMenu === 'discover' ? 'active' : ''}`}
          >
            <NavTooltip
              title={messages.nonTranslated.discover}
              isExpanded={isExpanded}
            >
              <StatisticsIcon hover />
            </NavTooltip>
            <span>{messages.nonTranslated.discover}</span>
          </SidebarNavLink>
          {discoverEnabled && (
            <DiscoverSubMenu
              {...{
                setOpenedSubMenu,
                unsetOpenedSubMenu,
              }}
            />
          )}
        </SidebarNavItem>
        {!isReadOnly && (
          <SidebarNavItem>
            <SidebarNavLink
              to='/datasets'
              activeClassName='active'
              onClick={e => menuItemSelected(e)}
              data-testid='datasets-menu-item'
            >
              <NavTooltip title={messages.nav.datasets} isExpanded={isExpanded}>
                <DatabaseIcon hover />
              </NavTooltip>
              <span>{messages.nav.datasets}</span>
            </SidebarNavLink>
          </SidebarNavItem>
        )}
      </SidebarNav>
      <SidebarNav>
        <SidebarNavItem>
          <a href={HELP_LINK} target='_blank' rel='noopener noreferrer'>
            <NavTooltip title='Help' isExpanded={isExpanded}>
              <HelpIcon hover />
            </NavTooltip>
            <span>{messages.nav.viewDocumentation}</span>
          </a>
        </SidebarNavItem>
        <SidebarNavItem className='sidebar-sugarcrm-logo'>
          <a href={SUGARCRM_WEBSITE} target='_blank' rel='noopener noreferrer'>
            <NavTooltip
              title={messages.nav.viewSugarCrmWebsite}
              isExpanded={isExpanded}
            >
              <Fragment>
                <SugarLogoIcon hover size={16} />
              </Fragment>
            </NavTooltip>
            <img src={imgSrc} />
          </a>
        </SidebarNavItem>
      </SidebarNav>
    </Sidebar>
  );

  return (
    <Fragment>
      {renderContent()}
      {isExpanded && (
        <Overlay data-testid='overlay' onClick={() => setIsExpanded(false)} />
      )}
    </Fragment>
  );
};

const mapStateToProps = function(state) {
  const {
    main: { appUrl },
    discover: { openDiscoveries },
  } = state;

  return {
    openDiscoveries,
    isReadOnly: getIsReadOnlyUser(state),
    appUrl,
  };
};

export const MainNavigation = compose<IMainNavigationProps, {}>(
  withRouter,
  connect(mapStateToProps),
  withTheme,
  shouldUpdate((curr: any, next: any) => {
    return (
      !_.isEqual(
        Object.keys(curr.openDiscoveries),
        Object.keys(next.openDiscoveries),
      ) || !_.isEqual(curr.location.pathname, next.location.pathname)
    );
  }),
)(MainNavigationComponent);
