import { useDiscoverTheme } from '../../common/emotion';

export const useDataTypeListItemSx = ({ hideFormats }) => {
  const { colors: { Green } = {} } = useDiscoverTheme();

  const itemSx = {
    margin: '0.2rem 0',
    marginTop: '0.2rem',
    justifyContent: 'space-between',
  };

  if (hideFormats) {
    itemSx['.format-example'] = {
      display: 'none',
    };
    itemSx['&:hover'] = {
      '.format-type': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        flexShrink: 2,
      },
      '.format-example': {
        display: 'inline-block',
        flexShrink: 1,
        paddingLeft: '1rem',
        color: Green,
        whiteSpace: 'nowrap',
      },
    };
  }

  const firstItemSx = { ...itemSx, marginTop: '0' };
  const lastItemSx = { ...itemSx, marginBottom: '0' };

  return {
    itemSx,
    firstItemSx,
    lastItemSx,
  };
};

export const titleStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflow: 'hidden',
};

export const titleTextStyle = { overflow: 'hidden', textOverflow: 'ellipsis' };

export const headerSx = {
  overflow: 'hidden',
  maxWidth: '100%',
  width: 'auto',
  '& > .title': {
    flexShrink: 2,
    overflow: 'hidden',
    '& > span': {
      textOverflow: 'ellipse',
      overflow: 'hidden',
    },
  },
  '& > .caret': {
    flexShrink: 1,
    marginLeft: '0.3rem',
    marginRight: '0.1rem',
  },
};
