import { css, SerializedStyles } from '@emotion/react';
import URLs from '../../common/Urls';
import { SugarIconType } from '.';
import { getIconSize } from './icon-size.util';
import unicodesMap from './unicodesMap.json';
import * as SugarIconFontFamilies from '@sugarcrm/maple-syrup/build/sugaricons/css/icons.css';
import { map, replace } from 'lodash';

export const sicon = css`
  display: inline-block;
  font: normal normal normal 1em/1 'SugarIcons';
  color: inherit;
  flex-shrink: 0;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const siconContentMixin = ({
  icon,
  size,
}: {
  icon: SugarIconType;
  size?: number;
}) => {
  return css`
    content: '\\${(unicodesMap[`sicon-${icon}`] ?? '0').toString(16)}';
    font-size: ${getIconSize({ icon, size })}px;
  `;
};

export const siconSelectorMixin = ({
  icon,
  size,
  selector,
  styles = css``,
}: {
  selector?: string;
  icon: SugarIconType;
  size?: number;
  styles?: string | SerializedStyles;
}) => css`
  ${selector}::before {
    ${sicon}
    ${siconContentMixin({ icon, size })}
    ${styles}
  }
`;

export const sugarIconStyles = ({ appUrl }) => css`
  ${SugarIconFontFamilies}

  /*
   override font-family to use our public endpoint
   */
  @font-face {
    font-family: 'SugarIcons';
    src: url(${URLs.joinUrls(appUrl, '/assets/fonts/SugarIcons.eot')});
    src: url(${URLs.joinUrls(appUrl, '/assets/fonts/SugarIcons.eot')})
        format('embedded-opentype'),
      url(${URLs.joinUrls(appUrl, '/assets/fonts/SugarIcons.woff2')})
        format('woff2'),
      url(${URLs.joinUrls(appUrl, '/assets/fonts/SugarIcons.woff')})
        format('woff'),
      url(${URLs.joinUrls(appUrl, '/assets/fonts/SugarIcons.ttf')})
        format('truetype'),
      url(${URLs.joinUrls(appUrl, '/assets/fonts/SugarIcons.svg')})
        format('svg');
    font-weight: normal;
    font-style: normal;
  }

  /*------------------------
    base class definition
  -------------------------*/
  .sicon {
    ${sicon};
  }
  /*------------------------
    change icon size
  -------------------------*/
  /* relative units */
  .sicon-sm {
    font-size: 0.8em;
  }
  .sicon-lg {
    font-size: 1.2em;
  }
  /* absolute units */
  .sicon-16 {
    font-size: 16px;
  }
  .sicon-32 {
    font-size: 32px;
  }
  /*------------------------
    spinning icons
  -------------------------*/
  .sicon-is-spinning {
    animation: sicon-spin 1s infinite linear;
  }
  @keyframes sicon-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /*------------------------
    rotated/flipped icons
  -------------------------*/
  .sicon-rotate-90 {
    transform: rotate(90deg);
  }
  .sicon-rotate-180 {
    transform: rotate(180deg);
  }
  .sicon-rotate-270 {
    transform: rotate(270deg);
  }
  .sicon-flip-y {
    transform: scaleY(-1);
  }
  .sicon-flip-x {
    transform: scaleX(-1);
  }
  /*------------------------
    icons
  -------------------------*/
  ${map(
    unicodesMap,
    (unicode, icon) => css`
      .${icon}::before {
        ${siconContentMixin({
          icon: replace(icon, /^sicon-/, '') as SugarIconType,
        })}
      }
    `,
  )}
`;
