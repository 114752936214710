import { useState, useCallback } from 'react';
import { validateColor } from '../common/color.util';
import _ from 'lodash';
import { useResettableState } from '../../../common/utilities/resettable-state.hook';

export interface IColorPickerHookProps {
  color: string;
  setColor: (color: string) => void;
}

export interface IColorPickerState {
  isValid: boolean;
  inputColor: string;
  onChange: (event) => void;
}

export const useColorPickerState: (
  props: IColorPickerHookProps,
) => IColorPickerState = ({ color, setColor }) => {
  const [inputColor, setInputColor] = useResettableState<string>(
    _.trimStart(color, '#'),
  );
  const [isValid, setIsValid] = useState(true);

  const onChange = useCallback(
    (event: any) => {
      const newValue = event.target.value;
      const valid = validateColor(newValue);
      setIsValid(valid);
      if (newValue !== inputColor) {
        setInputColor(newValue);
        if (valid && `#${newValue}` !== color) {
          setColor(`#${newValue}`);
        }
      }
    },
    [color, inputColor, setColor, setInputColor],
  );

  return {
    isValid,
    inputColor,
    onChange,
  };
};
