import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { VIZ_SELECTORS } from '../../../../common/redux/selectors/viz-selectors';
import {
  TimePeriods,
  getDisplayText,
} from '../../../filter/relative-date-condition';
import { messages } from '../../../../i18n';
import { joinTwoStringsWithSpace } from '../../../../common/Util';
import { ISlicerOption, TimestampSlicerTypes } from '../../interfaces';
import { DATE_FORMATTER } from '../../../../common/Constants';
import { useAccount } from '../../../../common/utilities/account';
import moment from '../../../../common/Moment';

export const MONTHDAYYEAR_NUM = 'MM/DD/YY';

interface IStatusStringProps {
  isTimestampSlicer: boolean;
  options: ISlicerOption[];
}

interface IStatusStringRes {
  timestampStatusString: string;
}

export const useStatusString = ({
  isTimestampSlicer,
  options,
}: IStatusStringProps): IStatusStringRes => {
  const { currentUser: { i18nPrefs = {} } = {} } = useAccount();
  const { useFiscalCalendar } = useSelector(state => ({
    useFiscalCalendar:
      VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting(state, {}) &&
      VIZ_SELECTORS.getActiveVizFiscalSetting(state, {}) === 'true',
  }));

  const timestampStatusString = useMemo(() => {
    const generateStringForRangeType = () => {
      const timePeriod = TimePeriods[options[1].option];
      const displayMessage = getDisplayText(timePeriod, useFiscalCalendar);

      return joinTwoStringsWithSpace(
        _.head(options).option,
        _.get(messages, displayMessage, displayMessage),
      );
    };

    if (isTimestampSlicer) {
      const type = _.last(options).option;
      const slicerTypes = {
        [TimestampSlicerTypes.RANGE]: () => generateStringForRangeType(),
        [TimestampSlicerTypes.SINCE]: () => {
          return `${messages.slicer.since} ${DATE_FORMATTER.format(
            _.head(options).option,
            i18nPrefs,
          )}`;
        },
        [TimestampSlicerTypes.BETWEEN]: () => {
          const startOfEndDay = moment(_.last(_.dropRight(options))?.option)
            .startOf('day')
            .format(moment.ISO8601);
          return `${messages.slicer.between} ${DATE_FORMATTER.format(
            _.head(options).option,
            i18nPrefs,
          )}-${DATE_FORMATTER.format(startOfEndDay, i18nPrefs)}`;
        },
      };

      if (_.isNil(slicerTypes[type])) {
        return generateStringForRangeType();
      }

      return slicerTypes[type]();
    }

    return '';
  }, [isTimestampSlicer, options, useFiscalCalendar, i18nPrefs]);

  return {
    timestampStatusString,
  };
};
