import { Component } from 'react';
import { withTheme } from '@emotion/react';
import { Spinner } from '../../../common/widgets/Status';
import { IDiscoverEmotionTheme } from '../../emotion';
import { LoaderDiv } from './corboto-loading.styles';

interface IProps {
  size?: number;
  text?: string;
  theme?: IDiscoverEmotionTheme;
}

class CorbotoLoadingClass extends Component<IProps> {
  static defaultProps = {
    size: 64,
  };

  render() {
    return (
      <LoaderDiv id='loader'>
        <div
          style={{
            minWidth: `${this.props.size}px`,
            minHeight: `${this.props.size}px`,
          }}
        >
          <Spinner
            size={this.props.size}
            color={this.props.theme.colors?.CorbotoLoadingColor}
          >
            {this.props.text && <div>{this.props.text}</div>}
          </Spinner>
        </div>
      </LoaderDiv>
    );
  }
}

export const CorbotoLoading = withTheme(CorbotoLoadingClass);
