import { useDiscoverTheme } from '../../../common/emotion';
import { FieldTypeIcon } from '../../../icons';
import { FilterToolBarIconWrapper } from './filter-toolbar-icon.styles';

export const FilterToolBarIcon = ({ fieldType }) => {
  const {
    darkMode,
    colors: { FilterToolBarIconStringHover },
  } = useDiscoverTheme();
  const icon = (
    <FieldTypeIcon
      fieldType={fieldType}
      hover
      hoverColor={FilterToolBarIconStringHover}
    />
  );
  return darkMode ? (
    <FilterToolBarIconWrapper>{icon}</FilterToolBarIconWrapper>
  ) : (
    icon
  );
};
