import { Component } from 'react';

class GlassPane extends Component {
  render() {
    return (
      <div className={`glass-pane ${this.props.show ? '' : 'hidden'}`}>
        {this.props.content && this.props.content}
      </div>
    );
  }
}
GlassPane.defaultProps = {
  show: false,
  content: null,
};
export default GlassPane;
