import ListSkeletonWhiteMode from '../../../images/sdd/list_skeleton_white_mode.svg';
import ListSkeletonDarkMode from '../../../images/sdd/list_skeleton_dark_mode.svg';
import { useDiscoverTheme } from '../../common/emotion';
import { SkeletonWrapper } from './table-skeleton.styles';

export const TableSkeleton = () => {
  const { darkMode } = useDiscoverTheme();

  return (
    <SkeletonWrapper>
      {darkMode ? <ListSkeletonDarkMode /> : <ListSkeletonWhiteMode />}
    </SkeletonWrapper>
  );
};
