import Const from '../actions/ActionConst';
import { composeResetReducer } from 'redux-reset-store';

const initialState = {
  datasetPage: 'datasetList', // datasetList, selectType, fileProperties
  datasetSelectedType: '',
  uploadStatus: 'dropzone', // dropzone, loading, complete, generalUploadError, fileTypeError
  showUploadError: false,
  uploadErrorMessage: '',
  fileMetadata: {},
  columnMetadata: [],
  fileData: [],
  datasets: [],
  datasetAddedId: null,
  showPropsChangeError: false,
  propsChangeErrorMessage: '',
  showSalesforceLogin: false,
  salesforce: {},
  sugarcrm: {},
  editDataset: null,
};

export default composeResetReducer((state = initialState, action) => {
  switch (action.type) {
    case Const.Dataset.SET_DATASETS:
      return {
        ...state,
        datasetPage: 'datasetList',
        uploadStatus: 'dropzone',
        datasets: action.datasets,
      };

    case Const.Dataset.SET_DATASET_PAGE:
      return { ...state, datasetPage: action.page, uploadStatus: 'dropzone' };

    case Const.Dataset.SET_DATASET_ADDED:
      return { ...state, datasetAddedId: action.datasetId };

    case Const.Dataset.SET_SHOW_PROPS_CHANGE_ERROR:
      return {
        ...state,
        showPropsChangeError: action.showPropsChangeError,
        propsChangeErrorMessage: action.propsChangeErrorMessage,
      };
    case Const.Dataset.GET_ASSOCIATED_CONTENT_FULFILLED:
      return {
        ...state,
        linkedContent: action.linkedContent,
        linkedContentForDatasetId: action.linkedContentForDatasetId,
      };
    case Const.Dataset.EDIT_DATASET: {
      return {
        ...state,
        editDataset: action.datasetId,
        editDatasetPreviewMode: !action.editable,
      };
    }
    case Const.Dataset.IS_EDITING_DATASET_SETTINGS: {
      const {
        datasetId,
        isEditingDatasetSettings,
        isFinalizingAddDataset,
      } = action;
      return {
        ...state,
        datasetId,
        isEditingDatasetSettings,
        isFinalizingAddDataset,
      };
    }
    default:
      return state;
  }
}, initialState);
