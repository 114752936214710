import pick from 'lodash/pick';
import values from 'lodash/values';
import find from 'lodash/find';
import every from 'lodash/every';
import words from 'lodash/words';
import includes from 'lodash/includes';

const defaultOptions = {
  type: 'string',
  keys: [],
};

export default class SearchCollection {
  constructor(list, options) {
    this.options = { ...defaultOptions, ...options };
    this.list = list || [];
    this.tokenizedSearch = tokenizedSearch;
    this.stringSearch = stringSearch;
  }

  search(str) {
    const searchFunc = this[`${this.options.type}Search`](str);
    const result = this.list.reduce((acc, obj) => {
      const vals = values(pick(obj, this.options.keys));
      const filteredValues = find(vals, item => {
        return searchFunc(item);
      });
      if (filteredValues) {
        acc.push(obj);
      }
      return acc;
    }, []);
    return result;
  }
}

const tokenizedSearch = search => {
  const tokens = words(search.toLowerCase());
  return source => {
    source = source.toLowerCase();
    return !!every(tokens, token => {
      return includes(source, token);
    });
  };
};

const stringSearch = search => {
  search = search.toLowerCase();
  return source => {
    source = source.toLowerCase();
    return includes(source, search);
  };
};
