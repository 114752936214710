import StackBarPlot from '../StackBar/plot';
import StackChartUtils from '../StackChartUtils';
import Funnel, { getStackOffset } from '../../../common/d3/Funnel';
import { DATA_FORMATTER } from '../../../common/Constants';
import {
  isEmpty,
  head,
  last,
  isNil,
  isNumber,
  get,
  map,
  filter,
  find,
  capitalize,
  includes,
} from 'lodash';
import palette from '../../../common/d3/ColorPalette';
import { ChartSummaryItem } from '../../../components/ui/plot';
import * as d3 from 'd3';
import { ChartSummary, ChartSummaryCard } from '../../../components/ui/plot';
import { OUTSIDE_LABEL_WIDTH } from '../../../common/d3/Stacks';
import { messages } from '../../../i18n';
import styled from '@emotion/styled';
import { font } from '../../../common/emotion/mixins';
import { css } from '@emotion/react';
import {
  DEFAULT_CREATED_DATE_ATTRIB_NAME,
  getCloseDateAttrib,
  getCreatedDateAttrib,
} from './chartSpec';
import { tooltipOpacity } from '../../../components/ui/tooltip';

const ChartTooltipEntry = styled.div(
  ({
    theme: { colors: { TooltipTextColor, LightFontWeight } = {} } = {},
  }) => css`
    white-space: normal;

    .chart-tooltip-label-fw {
      max-width: 20em;
      min-width: 20em;
      display: inline-block;
      ${font({
        size: '12px',
        weight: LightFontWeight,
        color: TooltipTextColor,
      })}
      fill: ${TooltipTextColor};
      opacity: ${tooltipOpacity};
    }
  `,
);

const FunnelTooltipContent = ({ message }) => {
  return (
    <ChartTooltipEntry key={`funnel-tooltip`} className='tooltip-entry'>
      <span className='chart-tooltip-label-fw'>{message}</span>
    </ChartTooltipEntry>
  );
};

class FunnelPlot extends StackBarPlot {
  createdDateAnnotation = '';
  closeDateAnnotation = '';
  constructor(parameters) {
    super(parameters);
    this.hideGrid = true;
    this.useNiceScale = false;
    // Adjust chart height to fit available chart summary contents
    this.height -= this.getChartSummaryHeight();
    this.createdDateAnnotation =
      getCreatedDateAttrib(this.viz, DEFAULT_CREATED_DATE_ATTRIB_NAME) ?? '';
    this.closeDateAnnotation = getCloseDateAttrib(this.viz) ?? '';
  }

  // eslint-disable-next-line lodash/prefer-constant
  getType() {
    return 'funnel';
  }

  getBarPadding() {
    return {
      range: [1, 9999],
      barPadding: 0.5,
      barPaddingOuter: 0,
      groupPadding: 0,
      groupPaddingOuter: 0,
    };
  }

  getPreferredHeight() {
    let adjustedChartHeight = this.height;
    let adjustedYScale = this.scales.yScaleDefault;
    while (
      this.layout[this.valuesName].length > 0 &&
      adjustedYScale.bandwidth() < 18
    ) {
      const currentHeight = adjustedYScale.bandwidth();
      adjustedChartHeight = (18 / currentHeight) * adjustedChartHeight;
      adjustedYScale = this.createScales(this.width, adjustedChartHeight)
        .yScaleDefault;
    }
    return adjustedChartHeight;
  }

  getTooltip(dataItem, formatters, globalTooltip, useFiscalCalendar) {
    // only show tooltipInfoMessage for funnel
    return dataItem?.tooltipInfoMessage ? (
      <FunnelTooltipContent message={dataItem?.tooltipInfoMessage} />
    ) : (
      StackBarPlot.prototype.getTooltip(
        dataItem,
        formatters,
        globalTooltip,
        useFiscalCalendar,
      )
    );
  }

  getChartSummary() {
    const parts = [];
    if (!isEmpty(this.data)) {
      const firstDatum = head(this.data);
      const lastDatum = last(this.data);

      const firstTotal = StackChartUtils.getStackTotals(firstDatum.VALUES)
        .positive;
      const lastTotal = StackChartUtils.getStackTotals(lastDatum.VALUES)
        .positive;

      const getSelectedStackTotal = values => {
        const filtered = values.filter(v => {
          // no need to filter out any stack items if there is no focused data
          if (isEmpty(this.focusedData)) {
            return true;
          }
          // only return the stack items that are matches for the focused data
          return !isNil(
            this.focusedData.find(fd => fd.stackName === v.stackName),
          );
        });
        return StackChartUtils.getStackTotals(filtered).positive;
      };

      // determine the average cycle time
      let selectedCycleData = null;
      if (!isEmpty(this.focusedData)) {
        selectedCycleData = lastDatum.VALUES.filter(v => {
          return !isNil(
            this.focusedData.find(fd => fd.stackName === v.stackName),
          );
        }).reduce(
          (acc, v) => {
            acc.count += isNumber(v.cycleTimeCount) ? v.cycleTimeCount : 0;
            acc.sum += isNumber(v.cycleTimeSum) ? v.cycleTimeSum : 0;
            return acc;
          },
          { count: 0, sum: 0 },
        );
      }

      const firstSelectionTotal = getSelectedStackTotal(firstDatum.VALUES);
      const lastSelectionTotal = getSelectedStackTotal(lastDatum.VALUES);

      const conversionRate = lastTotal / firstTotal;
      const selectedConversionRate = lastSelectionTotal / firstSelectionTotal;

      const conversionRateFormatted = DATA_FORMATTER.WHOLE_PERCENT.format(
        conversionRate,
        this.i18nPrefs,
      );
      const selectedConversionRateFormatted = DATA_FORMATTER.WHOLE_PERCENT.format(
        selectedConversionRate,
        this.i18nPrefs,
      );

      const firstTotalFormatted = DATA_FORMATTER.WHOLE_NUMBER.format(
        firstTotal,
        this.i18nPrefs,
      );

      const lastTotalFormatted = DATA_FORMATTER.WHOLE_NUMBER.format(
        lastTotal,
        this.i18nPrefs,
      );

      const isSelectionMode =
        !isEmpty(this.focusedData) &&
        this.focusedData.length !== firstDatum.VALUES.length;

      if (this.hasFormatToggle('conversionRate')) {
        parts.push(
          <ChartSummaryItem
            key='conversionRate'
            isMobile={this.isMobile}
            tooltip={messages.formatString(
              messages.funnel.totalFunnelConversionTooltip,
              conversionRateFormatted,
              lastTotalFormatted,
              firstTotalFormatted,
              <span style={{ fontWeight: 'bold' }}>{lastDatum.NAME}</span>,
            )}
            tooltipPlacement={'top'}
          >
            <ChartSummaryCard
              selectionMetric={
                isSelectionMode ? selectedConversionRateFormatted : undefined
              }
              overallMetric={conversionRateFormatted}
              label={messages.funnel.conversionRateToggle}
              isMobile={this.isMobile}
            />
          </ChartSummaryItem>,
        );
      }
      if (this.hasFormatToggle('avgConversionDays')) {
        const daysFormatted = DATA_FORMATTER.WHOLE_NUMBER.format(
          lastDatum.AVG_CONVERSION_DAYS,
          this.i18nPrefs,
        );
        const metric = isNumber(lastDatum.AVG_CONVERSION_DAYS)
          ? `${daysFormatted}d`
          : '-';
        const showDays = get(selectedCycleData, 'count', 0) !== 0;
        const selectedMetric = showDays
          ? `${DATA_FORMATTER.WHOLE_NUMBER.format(
              selectedCycleData.sum / selectedCycleData.count,
              this.i18nPrefs,
            )}d`
          : '-';
        parts.push(
          <ChartSummaryItem
            key='avgConversionDays'
            isMobile={this.isMobile}
            tooltip={messages.formatString(
              messages.funnel.totalFunnelCycleTimeTooltip,
              lastTotalFormatted,
              <span style={{ fontWeight: 'bold' }}>{lastDatum.NAME}</span>,
              daysFormatted,
              this.createdDateAnnotation,
              this.closeDateAnnotation,
            )}
            tooltipPlacement={'top'}
          >
            <ChartSummaryCard
              selectionMetric={
                isSelectionMode && selectedMetric ? selectedMetric : undefined
              }
              overallMetric={metric}
              label={messages.funnel.avgConversionDaysToggle}
              isMobile={this.isMobile}
            />
          </ChartSummaryItem>,
        );
      }
    }
    const className = parts.length > 0 ? 'chart-summary' : '';
    const isAvgDaysInStage = this.hasFormatToggle('avgDaysInStage');
    const avgDaysChipSize = isAvgDaysInStage
      ? getStackOffset(isAvgDaysInStage, this.isMobile)
      : 0;
    const offsetLeft =
      this.getYAxisWidth() + this.chartPadding + avgDaysChipSize;
    const labelsOutside = get(this.data, '0')?.VALUES?.length > 1;
    const w =
      this.showDataLabels && labelsOutside
        ? this.width - avgDaysChipSize - OUTSIDE_LABEL_WIDTH
        : this.width - avgDaysChipSize;
    return (
      <ChartSummary
        isMobile={false}
        className={className}
        offsetLeft={offsetLeft}
        width={w}
      >
        {parts}
      </ChartSummary>
    );
  }

  getYAxisWidthAdjustment() {
    const isAvgDaysInStage = this.hasFormatToggle('avgDaysInStage');
    const offset = getStackOffset(isAvgDaysInStage, false);
    return offset / 2;
  }

  getChartSummaryHeight() {
    if (
      this.hasFormatToggle('conversionRate') ||
      this.hasFormatToggle('avgConversionDays')
    ) {
      return this.isMobile ? 48 : 64;
    } else {
      return 0;
    }
  }

  // eslint-disable-next-line lodash/prefer-constant
  getScaleBuffer() {
    return 0;
  }

  hasFormatToggle(toggleName) {
    const toggles = this.customFormatToggles || [];
    const toggle = toggles.find(t => t.key === toggleName);
    return isNil(toggle) ? false : toggle.on;
  }

  _getColorPalette() {
    return palette;
  }

  getVisibleData() {
    const funnelStageVisibilityOptions = JSON.parse(
      get(this.viz?.options, 'funnelStageVisibility', '[]'),
    );
    const visibleFunnelStageLabels = map(
      filter(funnelStageVisibilityOptions, _option => _option?.isVisible),
      'label',
    );

    if (isEmpty(visibleFunnelStageLabels)) {
      return this.data;
    }

    const visibleData = filter(this.data, _funnelData => {
      return includes(visibleFunnelStageLabels, _funnelData.XAXIS);
    });

    return visibleData;
  }

  getDistinctGroups() {
    const xAxisValues = this.getVisibleData().map(d => d.XAXIS);
    return xAxisValues.length;
  }

  annotatedFunnelData() {
    const funnelSeriesData = this.getVisibleData() ?? [];
    return map(funnelSeriesData, (_series, _seriesIdx) => {
      const stageData = map(_series.VALUES ?? [], _stageData => {
        const prevFunnelStage = head(funnelSeriesData);
        const prevStage = find(prevFunnelStage.VALUES, {
          sort: _stageData.sort,
        });
        const fieldName = _series.IS_COLOR_STACK
          ? _stageData.valueColName
          : _stageData.stackName;
        return {
          ..._stageData,
          STACK_TOTAL_RECORDS: get(prevStage, fieldName, 0),
        };
      });

      return {
        ..._series,
        VALUES: stageData,
      };
    });
  }

  getComponent(props) {
    const customToggles = this.customFormatToggles.reduce((all, toggle) => {
      all[`is${capitalize(toggle.key)}`] = toggle.on;
      return all;
    }, {});

    if (customToggles.isAvgdaysinstage) {
      const origScale = this.xScale;

      // adjust if average days in stage is on
      const isAvgDaysInStage = this.hasFormatToggle('avgDaysInStage');
      const offset = isAvgDaysInStage
        ? getStackOffset(isAvgDaysInStage, this.isMobile)
        : 0;
      let displayableWidth = origScale.range()[1] - offset;
      const labelsOutside = get(this.data, '0')?.VALUES?.length > 1;

      if (this.showDataLabels && labelsOutside && !this.isMobile) {
        displayableWidth -= OUTSIDE_LABEL_WIDTH;
      }
      const x = d3
        .scaleLinear()
        .range([0, displayableWidth])
        .domain(origScale.domain());

      this.xScale = x;
    }

    // funnel has the same props as the Stacks for the most part
    return (
      <Funnel
        ref={stacks => (this.stacks = stacks)}
        isHorizontal
        isMobile={this.isMobile}
        {...props}
        {...customToggles}
        xScale={this.xScale}
        yScale={this.yScale}
        groupScale={this.scales.groupScaleDefault}
        getX={d => StackChartUtils.getY(d)}
        getY={d => StackChartUtils.getX(d)}
        getStack={d => StackChartUtils.getStack(d)}
        height={this.height}
        width={this.width}
        data={this.annotatedFunnelData()}
        chartPadding={this.presetPadding}
        showLabelsInsideBar={false}
        globalMouseTooltipEnabled={false}
        vizId={this.vizId}
        onHover={(data, x, y) => this.hoverFunction(data, this.getType(), x, y)}
        nullHandling={this.nullHandling}
        focusedData={this.focusedData}
        i18nPrefs={this.i18nPrefs}
        customFormatProps={this.customFormatProps}
      />
    );
  }
}

export default FunnelPlot;
