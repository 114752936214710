import StackChartUtils from '../StackChartUtils';
import {
  compose,
  onlyUpdateForKeys,
  pure,
  withPropsOnChange,
} from 'react-recompose';
import {
  BaseCartesianChart,
  ShouldEnableReportLinkHOC,
} from '../base-cartesian-chart';
import { connect } from 'react-redux';
import { LineChartUtils } from '../LineChartUtils';
import StackPlot from '../plots/StackPlot';
import LinePlot from '../Line/LinePlot';
import { isEmpty, isNil, sortBy, toPairs, values, includes } from 'lodash';
import NoDataIfHOC from '../NoDataIfHOC';
import { Viz } from '../../VizUtil';
import { join as joinChartData } from '../ChartUtils';
import BarLineChartUtils from '../BarLineChartUtils';
import { TOTALS_FLAG } from '../pivot/QueryPivotUtils';
import { withTheme } from '@emotion/react';

class StackLineChart extends BaseCartesianChart {
  constructor(props) {
    super(props, [
      { shelf: 'VALUES', Plot: StackPlot, dataId: 'stackData' },
      { shelf: 'LINES', Plot: LinePlot, dataId: 'lineData' },
    ]);
  }
}

export default compose(
  pure,
  ShouldEnableReportLinkHOC,
  connect(
    BaseCartesianChart.mapStateToProps,
    BaseCartesianChart.mapDispatchToProps,
  ),
  withTheme,
  withPropsOnChange(['queryResults', 'i18nPrefs'], props => {
    const firstMeasureIndex = props.queryResults.executeQuery.columnInfo.findIndex(
      ci => ci.columnType === 'MEASURE',
    );
    const stackLineResults = props.queryResults.executeQuery.results;

    let stackResults = props.queryResults;
    let lineResults = props.queryResults;
    const lineColumnInfo = props.queryResults.executeQuery.columnInfo;
    const lineColumnNames = props.queryResults.executeQuery.columnNames;

    if (!isEmpty(props.viz.layout.LINES) && !isEmpty(props.viz.layout.STACK)) {
      const aggregateColumnIndexes = new Set();

      // need to filter out any results that have __ALL__ as a value of an attribute column. Those are summary values used for lines
      const splitResults = stackLineResults.reduce(
        (res, result) => {
          // which ones are the attributes?
          const nonMeasures = result.filter(
            (r, idx) => idx < firstMeasureIndex,
          );
          if (includes(nonMeasures, '__ALL__')) {
            // this is a summary row, use it only for lines
            res.summaryResults.push(
              result.filter(cell => cell !== TOTALS_FLAG),
            );
            result.forEach((cell, idx) => {
              if (cell === TOTALS_FLAG) {
                aggregateColumnIndexes.add(idx);
              }
            });
          } else {
            res.stackResults.push(result);
          }
          return res;
        },
        { stackResults: [], summaryResults: [] },
      );
      stackResults = {
        executeQuery: {
          columnNames: props.queryResults.executeQuery.columnNames,
          columnInfo: props.queryResults.executeQuery.columnInfo,
          results: splitResults.stackResults,
          querySort: props.queryResults.executeQuery.querySort,
        },
      };
      const aggIndexes = Array.from(aggregateColumnIndexes);
      lineResults = {
        executeQuery: {
          columnNames: lineColumnNames.filter((n, idx) =>
            isNil(aggIndexes.find(i => i === idx)),
          ),
          columnInfo: lineColumnInfo.filter((n, idx) =>
            isNil(aggIndexes.find(i => i === idx)),
          ),
          results: splitResults.summaryResults,
        },
      };
    }

    const customFormatToggles = Viz.getCustomFormatTogglesFromViz(props.viz);
    const legendData = StackChartUtils.getUniqueStackNames({
      queryResults: stackResults,
      layout: props.viz.layout,
    });
    let stackData = StackChartUtils.transformResult(
      stackResults,
      props.viz,
      customFormatToggles,
      'VALUES',
      true,
      {},
      props.i18nPrefs,
    ); // modify here

    const axisFields = props.viz.layout.XAXIS.map(f => f.name);

    const stackAxisInfo = stackData.map(sd => {
      const axisValues = toPairs(sd.unformattedTooltipInfo)
        .filter(([key]) => {
          // just get the keys/values for fields in x-axis shelf
          return includes(axisFields, key);
        })
        .map(([key, value]) => {
          return { value, attributeName: key };
        });
      return {
        axisLabel: sd.XAXIS,
        axisValues,
        axisTotal: sd.VALUES.reduce((total, data) => {
          total += data.top - data.bottom;
          return total;
        }, 0),
      };
    });

    let lineData = [];
    if (!isEmpty(props.viz.layout.LINES)) {
      if (!isEmpty(stackAxisInfo)) {
        lineResults = LineChartUtils.syncXaxisData(
          lineResults,
          props.viz,
          stackAxisInfo,
          stackResults,
        );
      }
      // if there are line metrics defined, make sure we process & sort the line data
      lineData = BarLineChartUtils.transformResultToLine(
        lineResults,
        props.viz,
        'column_line',
        props.i18nPrefs,
      );
      lineData = LineChartUtils.sortData(
        lineData,
        props.viz,
        props.queryResults.executeQuery.querySort,
        'LINES',
        'XAXIS',
        'NONE',
      );
    }

    // If the only x-axis sort is applied to a Line Metric field, then the stack sort needs to get updated to match it
    const { querySort } = props.queryResults.executeQuery;
    const lineMetricSorts = values(querySort).filter(
      s => s.shelfName.toUpperCase() === 'LINE METRICS',
    );

    if (lineMetricSorts.length > 0) {
      const sortedStackData = sortBy(stackData, stack => {
        const positionInLineData = lineData.AXIS.findIndex(axisParts => {
          return joinChartData(axisParts) === stack.XAXIS;
        });
        return positionInLineData;
      });
      stackData = sortedStackData;
    }

    return { stackData, lineData, legendData };
  }),
  onlyUpdateForKeys([
    'stackData',
    'lineData',
    'height',
    'width',
    'id',
    'enableReportLink',
  ]),
  NoDataIfHOC(
    props => props.stackData.length === 0 && props.lineData.LINES.length === 0,
  ),
)(StackLineChart);
