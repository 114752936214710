import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { forEach } from 'lodash';

// from https://eymas.medium.com/react-hooks-useobserve-use-resizeobserver-custom-hook-45ec95ad9844
export const useObserver = ({ callback, element }) => {
  const observer = useRef<ResizeObserver>(null);

  const observe = useMemo(
    () => () => {
      if (element && observer.current) {
        observer.current.observe(element, { box: 'border-box' });
      }
    },
    [element, observer],
  );

  useEffect(() => {
    // if we are already observing old element
    if (observer?.current && element) {
      observer.current.unobserve(element);
    }

    if (element) {
      observer.current = new ResizeObserver(callback);
      observe();
    }

    return () => {
      if (observer?.current && element) {
        observer.current.unobserve(element);
      }
    };
  }, [callback, element, observe]);
};

export const useResizeObserver = (
  element: HTMLElement,
): DOMRectReadOnly | undefined => {
  const [containerRect, setContainerRect] = useState<DOMRectReadOnly>();

  const resizeCallback = useCallback((entries: ResizeObserverEntry[]) => {
    forEach(entries, entry => {
      setContainerRect(entry?.contentRect);
    });
  }, []);
  useObserver({ callback: resizeCallback, element });

  return (
    containerRect ??
    DOMRectReadOnly.fromRect({ x: 0, y: 0, width: 0, height: 0 })
  );
};
