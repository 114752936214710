import { isEqual, pick, flatten, omit, identity } from 'lodash/fp';

export const isEqualWithTransform = <
  Tbefore extends any = any,
  Tafter extends any = Tbefore
>(
  transformFunction: (obj: Tbefore) => Tafter = identity,
) => (obj1: Tbefore, obj2: Tbefore) => {
  return isEqual(transformFunction(obj1), transformFunction(obj2));
};

export const isEqualByProps = <T extends any = any>(
  ...props: (string | string[])[]
) => {
  return isEqualWithTransform(pick(flatten(props)) as any) as (
    val1: T,
    val2: T,
  ) => boolean;
};

export const isEqualWithoutProps = <T extends any = any>(
  ...props: (string | string[])[]
) => {
  return isEqualWithTransform(omit(flatten(props)) as any) as (
    val1: T,
    val2: T,
  ) => boolean;
};
