import { css } from '@emotion/react';
import { lighten } from 'polished';
import { svgColorize } from './mixins';

export const iconStyles = ({
  theme: {
    colors: { BaseWhite, DefaultIconColor, PrimaryColor, TabHighlightDisabled },
  },
}) => {
  const focusColor = BaseWhite;
  const activeColor = lighten('10%', focusColor);
  return css`
    .Icon {
      min-width: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      font-size: 10px;
      text-transform: capitalize;

      color: ${DefaultIconColor};
      user-select: none;
      background-position: center;
      transition: background 0.5s;
    }
    ${svgColorize({ color: DefaultIconColor })('.Icon')}

    .Icon:hover {
      background: ${focusColor}
        radial-gradient(circle, transparent 1%, ${focusColor} 1%) center /
        15000%;
    }
    .Icon:active {
      background-color: ${activeColor};
      background-size: 100%;
      transition: background 0s;
    }
    .Icon svg {
      width: 24px;
      height: 24px;
    }
    .Icon.default {
      color: ${DefaultIconColor};
    }

    ${svgColorize({ color: DefaultIconColor })('.Icon.default')}

    .Icon.selected {
      color: ${PrimaryColor};
    }

    ${svgColorize({ color: PrimaryColor })('.Icon.selected')}

    .Icon.disabled {
      color: ${TabHighlightDisabled};
    }

    ${svgColorize({ color: TabHighlightDisabled })('.Icon.disabled')}

    .Icon.interactive {
      cursor: pointer;
    }
  `;
};
