import { PrimaryButton } from '../../ui';
import { messages } from '../../i18n';

export const ReQuery = ({ onRefresh }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <p className='viz-chart-error-message'>
        {messages.discoveryToolbar.liveQueryUpdateMessage}
      </p>
      <PrimaryButton onClick={onRefresh}>
        {messages.discoveryToolbar.liveQueryApplyChanges}
      </PrimaryButton>
    </div>
  );
};
