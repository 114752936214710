import { Component, CSSProperties, Fragment } from 'react';
import _ from 'lodash';
import { ChartSpecs } from '../../discovery/ChartSpecs';
import { Column, SortDirection, Table } from 'react-virtualized';
import { compose, withProps } from 'react-recompose';
import { connect } from 'react-redux';
import Dashlet from '../../common/redux/actions/DashletActions';
import MainActions from '../../common/redux/actions/MainActions';
import { TABLE_SELECTORS } from '../../common/redux/selectors/TableSelectors';
import { MonitorQuery } from '../graphql';
import { TextSearchField } from '../../components/ui/form';
import SearchCollection from '../../common/SearchCollection';
import { IfTrue } from '../../components/ui/conditional';
import { HeaderCellRendererWithSortIndicator } from '../../views/DatasetPreviewTable/ui/index';
import { CenteredSpinner } from '../../common/widgets/Status';
import { Checkbox } from '@sugar-discover/react-bootstrap-wrapper';
import { withTheme } from '@emotion/react';
import { withDiscoverRouter } from '../../common/utilities/router.hoc';
import { ViewportDimensionsInjector } from '../../common/utilities/dimensions.hook';
import { Alert, AlertTitle } from '@mui/material';
import { SugarIcon } from '../../icons';
import { FilterWidget } from '../../discovery/filter-widget/filter-widget.component';
import { messages } from '../../i18n';
import {
  EmptyView,
  FilterWidgetItem,
  HeaderCell,
  ListHeader,
  Root,
  TagWidgetWrapper,
  TextSearchFieldWrapper,
} from './library-dashlet.styles';
import { FilterWidgetContextProvider } from '../../discovery/filter-widget/filter-widget.context';
import { TableSkeleton } from '../../common/loaders/table-skeleton';

const DEFAULT_SEARCHBAR_HEIGHT = 30;

const TableHeaders = {
  chartType: 'table.chartTypeLabel',
  name: 'name',
  actions: 'mobile.libraryActions',
  tags: 'tags.tags',
  dataset: 'dataset',
};

const columns = [
  { dataKey: 'chartType', getWidth: width => width * 0.1 },
  { dataKey: 'name', getWidth: width => width * 0.6 },
  { dataKey: 'tags', getWidth: width => width * 0.2 },
  { dataKey: 'dataset', getWidth: width => width * 0.2 },
];

class UnconnectedLibraryDashlet extends Component<
  {
    setSearch;
    search;
    advanced: boolean;
    openDiscovery;
    sortBy;
    isDashletSaving;
    dashletError;
    sortedList;
    vizId;
    clearDashletError;
    showReAuthenticate;
    searchbarHeight;
    tokenDiagnosticsLoading;
    theme;
    hasTokenErrors;
    dashletId;
    setMockFinalizeFail;
    mockFinalizeFail: boolean;
    tags: string[];
    setSearchTags;
    setSearchChartTypes;
    setSearchDatasets;
    chartTypes;
    searchDatasets;
    loading: boolean;
  },
  {
    sortedList;
    sortBy;
    sortDirection;
    vizId;
    width: number;
    height: number;
  }
> {
  _onResize;
  constructor(props) {
    super(props);
    const { sortedList, sortBy, sortDirection } = props;
    this.state = {
      sortedList,
      sortBy,
      sortDirection,
      vizId: props.vizId,
      ...this.getSize(),
    };
    this._onResize = () => {
      this.setState(this.getSize());
    };
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {
    this._onResize();
    window.addEventListener('resize', this._onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize);
  }

  getSize() {
    const { innerWidth, innerHeight } = window;
    return {
      width: innerWidth,
      height: innerHeight,
    };
  }

  componentDidUpdate(prevProps) {
    const { vizId } = prevProps;
    if (vizId !== this.state.vizId) {
      this.setState({ vizId });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({ sortedList: this.props.sortedList });
    }
  }

  getDatum(list, index) {
    return list[index % list.length];
  }

  onSearch(search) {
    this.props.setSearch(search);
  }

  sort({ sortBy, sortDirection }) {
    const sortedList = this.sortList({ sortBy, sortDirection });
    this.setState({ sortBy, sortDirection, sortedList });
  }

  sortList({ sortBy, sortDirection }) {
    const list = this.state.sortedList;
    const sortedList = _.sortBy(list, [sortBy]);
    return sortDirection === SortDirection.DESC
      ? sortedList.reverse()
      : sortedList;
  }

  open(content) {
    const { discoveryType } = content;
    switch (discoveryType) {
      case 'MONITOR':
        this.props.openDiscovery(content);
        break;
      case 'VISUALIZATION':
        this.props.openDiscovery(content);
        break;
    }
    this.setState({ vizId: content?.id });
  }

  onSetTags(tags) {
    this.props.setSearchTags(tags);
  }

  onSetSearchChartType(chartTypes: string[]) {
    this.props.setSearchChartTypes(chartTypes);
  }

  onSetDatasets(datasets: string[]) {
    this.props.setSearchDatasets(datasets);
  }

  /*@NOTE: componentize this rendering because it shares
   many things with MobileLibrary and possibly Library
   */
  render() {
    const {
      search,
      isDashletSaving,
      dashletError,
      tokenDiagnosticsLoading,
      advanced,
      setMockFinalizeFail,
      mockFinalizeFail,
      searchbarHeight = DEFAULT_SEARCHBAR_HEIGHT,
      tags,
      chartTypes,
      searchDatasets,
      loading,
    } = this.props;

    if (tokenDiagnosticsLoading || isDashletSaving) {
      return <CenteredSpinner />;
    }
    /* Search */
    let searchedData = [...this.state.sortedList];
    if (!_.isEmpty(search)) {
      const searcher = new SearchCollection(searchedData, {
        keys: ['name'],
      });
      searchedData = searcher.search(search);
    }
    const filteredContent = _.filter(
      searchedData,
      row =>
        (_.isEmpty(tags) || !_.isEmpty(_.intersection(row.tags, tags))) &&
        (_.isEmpty(chartTypes) || _.includes(chartTypes, row.chartType)) &&
        (_.isEmpty(searchDatasets) ||
          _.includes(searchDatasets, row.datasetName)),
    );

    const specs = {
      headerRowHeight: 40,
      rowHeight: 56,
      rowCount: filteredContent.length,
    };

    const rowGetter = ({ index }) =>
      loading ? {} : this.getDatum(filteredContent, index);

    const headerCellRendererWithSortIndicator = ({
      dataKey,
      sortDirection,
    }) => {
      return (
        <HeaderCell style={{ paddingLeft: 10 }} className='header-cell'>
          <HeaderCellRendererWithSortIndicator
            allowSortableIcon={false}
            label={_.get(
              messages,
              TableHeaders[dataKey],
              TableHeaders[dataKey],
            )}
            dataKey={dataKey}
            sortBy={this.state.sortBy}
            sortDirection={sortDirection}
          />
        </HeaderCell>
      );
    };

    const getStyleFromRowdata = (rowData, isHeader = false) => {
      const isSelected = rowData?.id && rowData?.id === this.state.vizId;
      const style: CSSProperties = {
        WebkitTapHighlightColor: this.props.theme?.colors
          ?.DashletLibraryHighlightRowBackground,
      };
      if (isSelected && !isHeader) {
        style.backgroundColor = this.props.theme?.colors?.DashletLibraryHighlightRowBackground;
      }
      return style;
    };

    const cellRenderer = ({ dataKey, cellData, rowData }) => {
      let content = <span />;
      const style: CSSProperties = {
        padding: 10,
        ...getStyleFromRowdata(rowData),
      };
      if (loading) {
        return (
          <div className='body-cell' style={style}>
            <TableSkeleton />
          </div>
        );
      }

      switch (dataKey) {
        case 'chartType':
          if (rowData.discoveryType === 'VISUALIZATION') {
            const spec = ChartSpecs[cellData];
            content = _.isNil(spec) ? <span /> : spec.listIcon;
          }
          break;
        case 'name':
          content = cellData;
          break;
        case 'tags':
          content = _.isEmpty(cellData) ? '-' : cellData.join(', ');
          break;
        case 'dataset':
          content = cellData.name;
          break;
        case 'actions':
          content = (
            <div
              onClick={() => this.open(rowData)}
              style={{
                display: 'flex',
                width: '2rem',
                height: '2rem',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
              }}
            >
              <SugarIcon hover icon={'launch'} />
            </div>
          );
          break;
      }
      return (
        <div className='body-cell' style={style}>
          {content}
        </div>
      );
    };

    const getRowStyle = args => {
      const rowData = rowGetter(args);
      const isHeader = args.index === -1;
      const style = getStyleFromRowdata(rowData, isHeader);
      return style;
    };
    const searchbarMargin = 8;
    const id = 'tag-widget-library-dashlet';

    return (
      <Root className='library' id={id}>
        {dashletError && (
          <Alert
            severity='error'
            onClose={this.props.clearDashletError}
            icon={false}
          >
            <AlertTitle>Failed to save Dashlet selection</AlertTitle>
            <p>{dashletError}</p>
          </Alert>
        )}
        {advanced && (
          <div
            style={{
              backgroundColor: mockFinalizeFail && 'red',
            }}
          >
            <Checkbox
              checked={mockFinalizeFail}
              onChange={(event: any) => {
                setMockFinalizeFail(event.target.checked);
              }}
            >
              <span>Mock finalization failure</span>
            </Checkbox>
            {mockFinalizeFail && (
              <div>
                WARNING -- this assignment WILL fail. This setting is for
                testing purposes only.
              </div>
            )}
          </div>
        )}
        <ListHeader>
          <TextSearchFieldWrapper>
            <TextSearchField
              isMobile
              fontSize='12px'
              height={searchbarHeight}
              style={{ width: '100%' }}
              placeholder='Search by name'
              value={this.props.search}
              onChange={this.onSearch}
            />
          </TextSearchFieldWrapper>
          <TagWidgetWrapper>
            <FilterWidgetContextProvider>
              <FilterWidgetItem>
                <FilterWidget
                  placeholder={messages.tags.searchPlaceholder}
                  disableAdd={true}
                  disableSelectAll={false}
                  onSave={t => this.onSetTags(t)}
                  isDashletTag={true}
                />
              </FilterWidgetItem>
              <FilterWidgetItem>
                <FilterWidget
                  placeholder={messages.library.searchChartTypePlaceholder}
                  disableAdd={true}
                  disableSelectAll={false}
                  onSave={t => this.onSetSearchChartType(t)}
                  isDashletTag={true}
                  widgetType='chartTypes'
                />
              </FilterWidgetItem>
              <FilterWidgetItem>
                <FilterWidget
                  placeholder={messages.library.searchDatasetsPlaceholder}
                  disableAdd={true}
                  disableSelectAll={false}
                  onSave={t => this.onSetDatasets(t)}
                  isDashletTag={true}
                  widgetType='datasets'
                />
              </FilterWidgetItem>
            </FilterWidgetContextProvider>
          </TagWidgetWrapper>
        </ListHeader>
        <div style={{ flex: 1 }}>
          <IfTrue value={!loading && specs.rowCount <= 0}>
            <EmptyView>
              <span>No matches</span>
            </EmptyView>
          </IfTrue>
          <IfTrue value={loading || specs.rowCount > 0}>
            <ViewportDimensionsInjector>
              {({ width, height }) => (
                <Fragment>
                  <Table
                    height={height - searchbarHeight - searchbarMargin * 2 - 51}
                    headerHeight={specs.headerRowHeight}
                    rowCount={loading ? 14 : specs.rowCount}
                    rowGetter={rowGetter}
                    rowHeight={specs.rowHeight}
                    width={width - 20}
                    sort={this.sort.bind(this)}
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                    rowStyle={getRowStyle}
                    onRowClick={({ rowData }) => {
                      this.open(rowData);
                    }}
                  >
                    {columns.map(({ dataKey, getWidth }) => (
                      <Column
                        key={dataKey}
                        dataKey={dataKey}
                        headerRenderer={headerCellRendererWithSortIndicator}
                        cellRenderer={cellRenderer}
                        width={getWidth(width)}
                      />
                    ))}
                  </Table>
                </Fragment>
              )}
            </ViewportDimensionsInjector>
          </IfTrue>
        </div>
      </Root>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    main: { advanced },
    dashlet: {
      discoveries,
      isDashletSaving,
      dashletError,
      dashletId,
      mockFinalizeFail,
    },
  } = state;
  return {
    advanced,
    dashletId,
    mockFinalizeFail,
    search: TABLE_SELECTORS.getSearch(state, ownProps),
    tags: TABLE_SELECTORS.getSearchTags(state, ownProps),
    discoveries,
    isDashletSaving,
    dashletError,
    chartTypes: TABLE_SELECTORS.getSearchChartTypes(state, ownProps),
    searchDatasets: TABLE_SELECTORS.getSearchDatasets(state, ownProps),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setMockFinalizeFail: mockFinalizeFail => {
      dispatch(Dashlet.setMockFinalizeFail(mockFinalizeFail));
    },
    showReAuthenticate: () => {
      dispatch(MainActions.showReAuthenticate());
    },
    clearDashletError: () => {
      dispatch(Dashlet.clearDashletError());
    },
    openDiscovery: discovery => {
      dispatch(Dashlet.openDiscovery(discovery, ownProps.dashletId));
    },
    closeDiscovery: () => {
      dispatch(Dashlet.closeDiscovery());
    },
    setAvailableDiscoveries: discoveries => {
      dispatch(Dashlet.setAvailableDiscoveries(discoveries));
    },
    setSearch: search => {
      dispatch(MainActions.setSearch(ownProps.domain, search));
    },
    setSearchTags: tags => {
      dispatch(MainActions.setSearchTags(ownProps.domain, tags));
    },
    setSearchChartTypes: chartTypes => {
      dispatch(MainActions.setSearchChartTypes(ownProps.domain, chartTypes));
    },
    setSearchDatasets: datasets => {
      dispatch(MainActions.setSearchDatasets(ownProps.domain, datasets));
    },
  };
};

export const LibraryDashlet = compose(
  withDiscoverRouter,
  connect(mapStateToProps, mapDispatchToProps),
  MonitorQuery,
  withTheme,
  withProps(
    (props: {
      content: any[];
      discoveriesLoading: boolean;
      pinnedDiscoveriesLoading: boolean;
    }) => {
      const { content, discoveriesLoading, pinnedDiscoveriesLoading } = props;
      const sortedList = _(content)
        .sortBy(['name'])
        .reject('isPrivate')
        .value();
      return {
        sortedList,
        sortBy: 'name',
        sortDirection: 'ASC',
        domain: 'library',
        loading: discoveriesLoading ?? pinnedDiscoveriesLoading,
      };
    },
  ),
)(UnconnectedLibraryDashlet) as any;
