import _ from 'lodash';
import { IViz } from '../../interfaces';
import { Viz as VizUtil } from '../../VizUtil';
import { IAttribute } from '../../../datasets/interfaces';
import { ITimePeriod, TimePeriods } from '../../filter/relative-date-condition';
import { SelectStatus, TimestampSlicerTypes } from '../interfaces';

export const validateSlicers = (
  viz: IViz,
  slicers: IAttribute[] = [],
  slicerSelections: any[] = [],
): boolean => {
  const allFields = VizUtil.getAllAvailableFields(viz);
  const slicerNames = _.map(slicers, 'name');

  const hasIncludedField = fieldName => {
    return _.includes(_.map(allFields, 'name'), fieldName);
  };

  const areValidSlicerNames = _.every(slicerNames, hasIncludedField);

  const slicerSelectionFieldNames: string[] = _.uniq(
    _.map(slicerSelections, 'name'),
  );

  const areValidSlicerSelections = _.every(
    slicerSelectionFieldNames,
    (_fieldName: string) => {
      return (
        _.includes(slicerNames, _fieldName) && hasIncludedField(_fieldName)
      );
    },
  );

  return areValidSlicerNames && areValidSlicerSelections;
};

export const defaultTimestampSlicerOptions = [
  { option: '7', isSelected: true },
  { option: 'DAYS', isSelected: true },
  { option: 'false', isSelected: true },
  { option: TimestampSlicerTypes.RANGE, isSelected: true },
];

export const commonRangeOptions: (ITimePeriod & { value: string })[] = [
  { ...TimePeriods.DAYS, value: '7', display: 'filters.commonTimeFrameDays' },
  { ...TimePeriods.WEEKS, value: '1', display: 'filters.week' },
  { ...TimePeriods.MONTHS, value: '1', display: 'filters.month' },
];

export const joinBreadcrumbs = (
  name: string,
  breadcrumbs: string[] = [],
): string => [name].concat(breadcrumbs).join(' > ');

export const isStatusValid = (selectedStatus: string): boolean => {
  const validStatuses = [SelectStatus.SOME, SelectStatus.ALL];
  return _.includes(validStatuses, selectedStatus);
};

export const isIndeterminateStatus = (selectedStatus: string): boolean =>
  selectedStatus === SelectStatus.SOME;
