import {
  Component,
  FunctionComponentElement,
  Children,
  cloneElement,
} from 'react';
import _ from 'lodash';
import { compose, withProps } from 'react-recompose';
import { IMonitorEvent } from '../interfaces';
import { graphql } from '@apollo/client/react/hoc';
import Dataset from '../../common/graphql/DatasetQueries';

import { getDatasetRelatedInfo } from '../monitor.utils';
import {
  ActivityFeedItem,
  ActivityFeedItemBody,
  ActivityFeedItemHeader,
  ActivityWidget,
  PercentChangeCard,
  IActivityFeedItem,
} from '../../tenants/corvana/activity';
import {
  baseProps,
  CommonComponentHoc,
} from '../../tenants/corvana/Components';
import {
  IComparisonPeriodMonitorConfigPayload,
  IComparisonPeriodMonitorEventPayload,
} from './interfaces';
import {
  getTitleTemplates,
  getBodyTemplates,
} from './comparison-monitor.templates';
import { messages } from '../../i18n';
import { IDataset } from '../../datasets';
import { IInternationalizationPreferences } from '../../account/interfaces';
import { ACCOUNT_SELECTORS } from '../../common/redux/selectors/AccountSelectors';
import { connect } from 'react-redux';
import shortid from 'shortid';
import { TemplateProps } from '../../common/templating/template-props.hoc';

type PropsWithMonitorConfig = {
  monitorConfig: IComparisonPeriodMonitorConfigPayload;
  pct: string;
  visualizations: any[];
  up: boolean;
  period: string;
};

type PropsWithPayload = {
  payload: IComparisonPeriodMonitorEventPayload;
};

type PropsWithDataset = {
  dataset: IDataset;
  dataFormatters: any;
  i18nPrefs: IInternationalizationPreferences;
};

export interface ILayoutSegments {
  titleText: string;
  bodyText: string;
  targetReport: string;
  linkText: string;
}

export class UnconnectedComparisonMonitorActivityFeedItem extends Component<
  PropsWithMonitorConfig &
    PropsWithPayload &
    ILayoutSegments & {
      feedItem: IActivityFeedItem;
      monitorEvent: IMonitorEvent;
      referenceId: string;
      condensed: any;
      className: string;
      history: any;
      onHeightChanged;
    }
> {
  render() {
    let {
      feedItem,
      monitorEvent,
      referenceId,
      payload,
      condensed,
      className,
      onHeightChanged,
      children,
      titleText,
      bodyText,
      monitorConfig: { comparisonPeriodType: previousPeriod, inverted },
      metricName,
      attrName,
      attrValue,
      period,
      pct,
      date,
      barData,
      last,
      up,
      dataset,
      dataFormatters,
    } = this.props as any;

    const { valueFormatter, customFormatter } = getDatasetRelatedInfo(
      dataset,
      metricName,
      dataFormatters,
    );

    // reformat the prev and current bar labels based on the correct formatter.
    barData[0].formatters = [valueFormatter, customFormatter];
    barData[1].formatters = [valueFormatter, customFormatter];

    switch (previousPeriod) {
      case 'WEEK':
        period = messages.insightFeed.weekOverWeek;
        break;
      case 'MONTH':
        period = messages.insightFeed.monthOverMonth;
        break;
    }

    // Get the specific children that we know we need
    const kids: {
      ActivityFeedItemHeader: FunctionComponentElement<any>;
      ActivityFeedItemBody: FunctionComponentElement<{
        monitorEvent: any;
        feedItem: any;
      }>;
      ActivityWidget: FunctionComponentElement<any>;
      [key: string]: FunctionComponentElement<any>;
    } = Children.toArray(children).reduce((acc, child: any) => {
      acc[child.type.role] = cloneElement(child, {
        onHeightChanged,
        key: shortid.generate(),
      });
      return acc;
    }, {}) as any;

    // Support overriding of the default header by tenanted modules
    const renderHeader = () => {
      if (!_.isNil(kids?.ActivityFeedItemHeader)) {
        return kids.ActivityFeedItemHeader;
      } else {
        return (
          <ActivityFeedItemHeader
            {...{ indicatorIcon: up ? 'up' : 'down', feedItem }}
          >
            {titleText}
          </ActivityFeedItemHeader>
        );
      }
    };

    // Support overriding of the default body by tenanted modules
    const renderBody = () => {
      if (!_.isNil(kids?.ActivityFeedItemBody)) {
        return kids.ActivityFeedItemBody;
      } else {
        const BodyComp = ActivityFeedItemBody as any;
        return (
          <BodyComp monitorEvent={monitorEvent} feedItem={feedItem}>
            {bodyText}
          </BodyComp>
        );
      }
    };

    // Support overriding of the default widget by tenanted modules
    const renderWidget = () => {
      if (_.isNil(dataset)) {
        // PctChange requires the final barData prior to rendering,
        // which is only fully populated when the dataset is available.
        return <ActivityWidget />;
      }
      if (!_.isNil(kids) && !_.isNil(kids.ActivityWidget)) {
        return kids.ActivityWidget;
      } else {
        const title = _.startCase(metricName);
        let subtitle = null;
        if (condensed && attrName) {
          subtitle = `(${attrName}=${attrValue})`;
        }
        const PctChange = PercentChangeCard as any;
        return (
          <ActivityWidget>
            <PctChange
              {...{
                up,
                attrValue,
                attrName,
                period,
                pct,
                date,
                payload: {
                  ...payload,
                  previousPeriod,
                },
                barData,
                condensed,
                title,
                subtitle,
                inverted,
              }}
            />
          </ActivityWidget>
        );
      }
    };

    return (
      <div key={monitorEvent.id}>
        <ActivityFeedItem
          {...{
            referenceId,
            monitorEvent,
            feedItem,
            onHeightChanged,
            last,
            condensed,
            className,
            inverted,
          }}
        >
          {renderHeader()}
          {renderBody()}
          {renderWidget()}
        </ActivityFeedItem>
      </div>
    );
  }
}

const withMetricsProps = ({
  monitorConfig,
  payload,
  dataset,
  dataFormatters,
  i18nPrefs,
}: PropsWithMonitorConfig & PropsWithPayload & PropsWithDataset) => {
  const metricName = _.head(monitorConfig.query.measures)?.attributeName;
  const { valueFormatter, customFormatter } = getDatasetRelatedInfo(
    dataset,
    metricName,
    dataFormatters,
  );
  return {
    metricName,
    percentChange: payload?.percentChange,
    currentPeriodValue: valueFormatter.format(
      payload?.currentValue,
      i18nPrefs,
      customFormatter,
    ),
    previousPeriodValue: valueFormatter.format(
      payload?.previousValue,
      i18nPrefs,
      customFormatter,
    ),
  };
};

const withTextProps = ({
  monitorConfig: {
    labels: { titleText, bodyText },
  },
}) => ({
  titleText,
  bodyText,
});

const ComparisonMonitorActivityFeedItem = compose(
  withProps(({ feedItem: { monitorEvent, referenceId } }) => ({
    monitorEvent,
    referenceId,
    monitorConfig: JSON.parse(monitorEvent?.monitor?.config ?? null),
    payload: JSON.parse(monitorEvent?.payload ?? null),
    monitorName: monitorEvent?.monitor?.name,
  })),
  connect(state => ({
    dateTimeFormats: ACCOUNT_SELECTORS.getDatetimeFormats(state),
  })),
  CommonComponentHoc,
  graphql(Dataset.DatasetDetail, {
    options: props => ({
      variables: { id: (props as any).monitorEvent?.monitor?.dataset?.id },
    }),
    props: (ownProps: any) => {
      return { dataset: ownProps?.data?.dataset };
    },
  }),
  withProps(withMetricsProps),
  withProps(withTextProps),
  TemplateProps({
    titleText: getTitleTemplates,
    bodyText: getBodyTemplates,
  }),
)(UnconnectedComparisonMonitorActivityFeedItem);

const ComparisonMonitorInsightComponent = compose(
  withProps(() => ({ className: 'insight-item' })),
  connect(state => ({
    dateTimeFormats: ACCOUNT_SELECTORS.getDatetimeFormats(state),
  })),
  withProps(({ monitorEvent, referenceId }) => ({
    monitorEvent,
    referenceId,
    monitorConfig: JSON.parse(monitorEvent?.monitor?.config ?? null),
    payload: JSON.parse(monitorEvent?.payload ?? null),
    monitorName: monitorEvent?.monitor?.name,
  })),
  withProps(withMetricsProps),
  withProps(withTextProps),
  baseProps,
)(UnconnectedComparisonMonitorActivityFeedItem);

const comparisonMonitorSupportFunc = event => {
  return (
    event?.referenceType === 'MonitorEvent' &&
    event?.activityType === 'comparison'
  );
};

const comparisonMonitorInsightSupportFunc = ({
  referenceType,
  monitorEvent,
}) => {
  return (
    referenceType === 'MonitorEvent' &&
    ['comparison'].includes(monitorEvent?.eventType)
  );
};

(ComparisonMonitorActivityFeedItem as any).supports = comparisonMonitorSupportFunc;
(ComparisonMonitorInsightComponent as any).supports = comparisonMonitorInsightSupportFunc;

export { ComparisonMonitorActivityFeedItem, ComparisonMonitorInsightComponent };
