import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.65rem;
  span {
    display: block;
  }
`;

export const ProfileDropdownRoot = styled.div(
  ({ theme: { colors: { BaseWhite } } = {} }) => css`
    display: flex;
    align-items: center;
    height: 100%;

    & > span.tenant-name {
      color: ${BaseWhite};
    }
  `,
);

export const ModernProfileDropdownRoot = styled(ProfileDropdownRoot)`
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  height: 80%;
`;

export const HeaderBar = styled.div`
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > img {
    height: 28px;
  }
`;
