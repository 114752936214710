import { css } from '@emotion/react';

export const pivotTableMobileStyles = css`
  .pivot-table-wrapper {
    height: 100%;
    margin: 0;
  }
  .pivot-cell {
    padding: 4px;
  }
`;
