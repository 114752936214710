import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { ellipsis } from './../../common/emotion/mixins';
import { SubMenu, SubMenuTitle } from './sub-menu/sub-menu.styles';

const sidebarExpandedWidth = '15rem';
const sidebarCollapsedWidth = '3.75rem';
const navItemHeight = '2.5rem';

export const SidebarToggle = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  transform: translateX(11.25rem);
  transition: transform 300ms ease-in-out;
  width: ${sidebarCollapsedWidth};

  &:hover {
    background-color: ${({ theme }) => theme.colors.DarkBlue};
    .sicon,
    .sicon:hover {
      color: ${({ theme }) => theme.colors.NavIconSelected};
    }
  }
`;

export const SidebarNavItem = styled.li<{ isSubMenuOpened?: boolean }>`
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  width: ${sidebarCollapsedWidth};
  transform: translateX(11.25rem);
  transition: transform 300ms ease-in-out;

  &:hover {
    > a {
      background-color: ${({ theme }) => theme.colors.DarkBlue};
    }
    ${SubMenu} {
      visibility: visible;
    }
    .sicon,
    .sicon:hover {
      color: ${({ theme }) => theme.colors.NavIconSelected};
    }
  }
  ${props =>
    props.isSubMenuOpened &&
    css`
      ${SubMenu} {
        visibility: visible;
      }
    `};
`;

export const TooltipInner = styled.div`
  display: flex;
  align-items: center;
  height: ${navItemHeight};
  width: ${sidebarCollapsedWidth};

  .sicon {
    width: ${sidebarCollapsedWidth};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sicon,
  .sicon:hover {
    color: ${({ theme }) => theme.colors.PivotWhite};
  }
`;

export const SidebarNav = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  border-top: 1px solid
    ${({ theme }: any) =>
      theme?.darkMode ? theme?.colors?.MediumBlue : theme?.colors?.LightBlue};
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;

  &:last-child {
    margin-top: auto;
  }

  a span {
    ${ellipsis()}
  }

  > li > a {
    padding: 0 1rem 0 0;
    font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${({ theme }) => theme.colors.PivotWhite};
    display: flex;
    align-items: center;
    height: ${navItemHeight};

    &:focus,
    &:hover {
      text-decoration: none;
    }

    &.active {
      background-color: ${({ theme }) => theme.colors.DarkBlue};
      .sicon,
      .sicon:hover {
        color: ${({ theme }) => theme.colors.NavIconSelected};
      }
    }
  }
`;

export const SidebarNavLink = styled(NavLink)<{ disabled?: boolean }>`
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${props.theme.colors.DarkBlue};

      .sicon,
      .sicon:hover {
        color: ${props.theme.colors.NavIconSelected};
      }
    `};
`;

type SidebarProps = {
  isExpanded: boolean;
  isCollapsing: boolean;
  isSubMenuOpened: boolean;
};

export const Sidebar = styled.nav<SidebarProps>`
  background-color: ${({ theme }: any) =>
    theme?.darkMode ? theme?.colors?.LightBlue : theme?.colors?.MediumBlue};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100%;
  width: ${sidebarExpandedWidth};
  transition: transform 300ms ease-in-out;
  transform: translateX(${props => (props.isExpanded ? 0 : -11.25)}rem);
  z-index: 1050;
  display: flex;
  flex-direction: column;
  overflow: ${props => !props.isSubMenuOpened && 'hidden'};

  .sidebar-sugarcrm-logo {
    img {
      height: 16px;
      margin-left: 22px;
      transition-property: all;
      transition-delay: 300ms;
      visibility: hidden;
      position: absolute;
    }
  }

  ${props =>
    props.isCollapsing &&
    css`
      ${SidebarToggle},
      ${SidebarNavItem} {
        width: 100%;
      }
    `};

  ${props =>
    props.isExpanded &&
    css`
      ${SidebarToggle},
      ${SidebarNavItem} {
        width: 100%;
        transform: translateX(0);
      }

      .sidebar-sugarcrm-logo {
        .sicon {
          display: none;
        }
        img {
          transition-delay: 0ms;
          visibility: visible;
        }
      }

      ${SubMenuTitle} {
        display: none;
      }
    `};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.Black};
  opacity: 0.4;
  z-index: 1049;
`;
