import { MouseEvent } from 'react';
import _ from 'lodash';
import {
  PIVOT_TABLE,
  IFormatProto,
  DATA_FORMATTER,
  DATA_TYPE_FORMAT,
  FormatProto,
  Types,
  DATE_FORMATTER,
  ShelfName,
} from '../../../common/Constants';
import { requiresOrdinal } from '../ChartUtils';
import { scaleLinear, ScaleLinear } from 'd3';
import { Viz } from '../../VizUtil';
import { getAllLinksEnabledInReport } from '../../../components/PivotDrillLinks/utils';
import { DefaultFontName } from '../../../components/ui/fonts';
import {
  IViz,
  IPropsWithViz,
  ISorting,
  IPropsWithSort,
  IHighlight,
  IMetric,
  ID3ColorScales,
} from '../../interfaces';
import {
  IAttribute,
  IAnyAttribute,
  ITimeCalcAttribute,
} from '../../../datasets';
import { ICustomFormatProps } from '../../custom-format-modal';
import { isDashletMode } from '../../../auth';
import { FocusDrawerIcon } from '../../../icons/icons';

export const RECORD_COUNT_HEIGHT_DIFF = 14; // the diff in pixels between a normal row and a row with record counts

export const LinkFormatter = _.assign(new FormatProto(), {
  dataType: 'Link',
  formatType: 'Link',
  formatExample: _.constant('<a href=...>Label</a>'),
  format: value => {
    const [label, link, tooltip] = value.split('||');
    if (!link) {
      return value;
    }
    const linkParams = link.split('/');
    const _isDashletMode = isDashletMode();
    let onClick = undefined;

    if (_isDashletMode) {
      onClick = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const { origin: windowOrigin } = new URL(window.location.href);
        const { origin: linkOrigin } = new URL(link);
        if (windowOrigin === linkOrigin) {
          window.open(link, '_blank', 'noopener,noreferrer');
        } else {
          console.error('link URL and window URL are incompatible');
        }
      };
    }
    return (
      <div className='link-container'>
        <a
          className={'app-link'}
          href={link}
          target={'_blank'}
          title={tooltip}
          rel='noreferrer'
          onClick={onClick}
        >
          {label}
        </a>
        {_isDashletMode && (
          <FocusDrawerIcon
            hover
            onClick={() => {
              if (_.isFunction((window as any)?.App?.utils?.openFocusDrawer)) {
                (window as any)?.App?.utils?.openFocusDrawer(
                  (window as any)?.App?.sideDrawer,
                  _.replace(_.nth(linkParams, -2), '#', ''),
                  _.last(linkParams),
                );
              }
            }}
          />
        )}
      </div>
    );
  },
  formatSmall: value => `link: ${value}`,
  formatText: value => {
    const label = value.split('||')[0];
    return label;
  },
});

const Utils = {
  numValues(props: IPropsWithViz): number {
    return props.viz.layout.VALUES.length;
  },
  numRows(props: IPropsWithViz): number {
    return props.viz.layout.ROWS.length;
  },

  numRowsAndOrdinals(props: IPropsWithViz): number {
    const num = props.viz.layout.ROWS.reduce((accum, curr) => {
      return accum + (curr.ordinalAttribute || requiresOrdinal(curr) ? 2 : 1);
    }, 0);
    return num;
  },

  numColumns(props: IPropsWithViz): number {
    return props.viz.layout.COLUMNS.length;
  },

  hasValues(props: IPropsWithViz): boolean {
    return this.numValues(props) > 0;
  },

  hasColumns(props: IPropsWithViz): boolean {
    return this.numColumns(props) > 0;
  },

  hasRows(props: IPropsWithViz): boolean {
    return this.numRows(props) > 0;
  },

  isPivotLayout(props: IPropsWithViz): boolean {
    return (
      this.hasValues(props) || (this.hasColumns(props) && this.hasRows(props))
    );
  },

  findFieldByName(props: IPropsWithViz, name: string): IAttribute {
    const { ROWS, COLUMNS, VALUES } = props.viz.layout;

    let found = [...ROWS, ...COLUMNS, ...VALUES].find(r => r.name === name);
    if (!found) {
      const targets =
        props.viz.options.selectedTargets &&
        JSON.parse(props.viz.options.selectedTargets);
      found = _.find(targets, { name });
    }
    return found;
  },

  getIndexInShelf(props: IPropsWithViz, name: string): number {
    const field = this.findFieldByName(props, name);
    const shelf = this.findShelf(props, name);

    return props.viz.layout[shelf].indexOf(field);
  },

  findShelf(props: IPropsWithViz, name: string): ShelfName {
    const targets: IAnyAttribute[] =
      props.viz.options &&
      props.viz.options.selectedTargets &&
      JSON.parse(props.viz.options.selectedTargets);
    for (const shelf of [
      'ROWS',
      'COLUMNS',
      'VALUES',
      'SLICER',
    ] as ShelfName[]) {
      if (_.some(props.viz.layout[shelf], { name })) {
        return shelf;
      } else if (targets && _.some(targets, { name })) {
        // target maps to values
        return 'VALUES';
      }
    }
  },

  getHighlight(props: IPropsWithSort, group: string): IHighlight {
    return props.sorting.highlight && props.sorting.highlight[group];
  },

  upgradeSortingProps(sorting: ISorting): ISorting {
    // highlight came in late
    sorting.highlight = sorting.highlight || {};

    return sorting;
  },

  /*
   * Pass thru Sorting property and make sure definitions still reference fields in the right place.
   * Order sorting based on current layout to ensure hierarchy is maintained
   */
  verifyAndOrderSortingFields(props: IPropsWithSort & IPropsWithViz): ISorting {
    let { sorting } = props;
    sorting = this.ensureHierarchyAllSorted(sorting, props);

    if (_.isArray(sorting.COLUMNS)) {
      sorting.COLUMNS = sorting.COLUMNS.filter(col => {
        if (!col.path) {
          // older report, clear field from sorting
          return false;
        }
        const field = Utils.findFieldByName(props, col.path[0]);
        const shelf =
          Utils.isPivotLayout(props) || Utils.hasRows(props)
            ? 'COLUMNS'
            : 'ROWS';
        return field && shelf === Utils.findShelf(props, col.path[0]);
      }).sort((col1, col2) => {
        const shelf1 = Utils.findShelf(props, col1.path[0]);
        const shelf2 = Utils.findShelf(props, col2.path[0]);
        if (shelf1 !== shelf2) {
          // different shelves, sort by shelf before sorting field
          // values is always last to sort
          if (shelf1 === 'VALUES') {
            return 1;
          }
          if (shelf2 === 'VALUES') {
            return -1;
          }
          // If not values it'll be rows or columns, don't care
          return 0;
        }
        const idx1 = Utils.getIndexInShelf(props, col1.path[0]);
        const idx2 = Utils.getIndexInShelf(props, col2.path[0]);
        return idx1 - idx2;
      });
    } else {
      sorting.COLUMNS = [];
    }
    if (_.isArray(sorting.ROWS)) {
      sorting.ROWS = sorting.ROWS.filter(col => {
        if (!col.path) {
          // older report, clear field from sorting
          return false;
        }
        const field = Utils.findFieldByName(props, col.path[0]);
        const shelves =
          Utils.isPivotLayout(props) || Utils.hasRows(props)
            ? ['ROWS', 'VALUES']
            : ['COLUMNS'];
        const attrShelf = Utils.findShelf(props, col.path[0]);

        return field && _.includes(shelves, attrShelf);
      }).sort((col1, col2) => {
        if (!Utils.isPivotLayout(props)) {
          // Tabular View. We don't enforce ordering or sort in tabular view
        } else {
          const shelf1 = Utils.findShelf(props, col1.path[0]);
          const shelf2 = Utils.findShelf(props, col2.path[0]);

          const idx1 = Utils.getIndexInShelf(props, col1.path[0]);
          const idx2 = Utils.getIndexInShelf(props, col2.path[0]);
          if (shelf1 !== shelf2) {
            // different shelves, sort by shelf before sorting value
            if (shelf1 === 'VALUES') {
              // value and last level sort are peers. If we're comparing to the last level return the natural sort
              if (idx2 === props.viz.layout[shelf2].length - 1) {
                return 0;
              }
              // comparing to a level above last, value will always be after
              return 1;
            } else if (shelf2 === 'VALUES') {
              // value and last level sort are peers. If we're comparing to the last level return the natural sort
              if (idx1 === props.viz.layout[shelf1].length - 1) {
                return 0;
              }
              // comparing to a level above last, value will always be after
              return -1;
            }
            // If not values it'll be rows or columns, don't care
            return 0;
          }
          return idx1 - idx2;
        }
      });
    } else {
      sorting.ROWS = [];
    }

    sorting = this.ensureHierarchySortingIsHonored(sorting, props);

    return sorting;
  },

  ensureHierarchyAllSorted(sorting: ISorting, props: IPropsWithViz): ISorting {
    const returnSorting = { ...sorting };

    ['COLUMNS', 'ROWS'].forEach(shelfName => {
      if (!props.viz.layout[shelfName]) {
        return;
      }
      if (!returnSorting[shelfName]) {
        returnSorting[shelfName] = [];
      }
      // Make sure all columns and rows have a sort definition
      props.viz.layout[shelfName].forEach(attr => {
        if (
          !returnSorting[shelfName].find(
            existing => existing.path[0] === attr.name,
          )
        ) {
          returnSorting[shelfName].push({
            direction: 'asc',
            path: [attr.name],
          });
        }
      });
    });
    return returnSorting;
  },

  ensureHierarchySortingIsHonored(
    sorting: ISorting,
    props: IPropsWithViz,
  ): ISorting {
    const returnSort = {
      COLUMNS: [],
      ROWS: [],
      VALUES: [],
      highlight: {},
    };

    if (Utils.isPivotLayout(props) || Utils.hasRows(props)) {
      ['COLUMNS', 'ROWS'].forEach(shelfName => {
        // Some sorts remove others, collecting them in this array to skip over them later
        const removedSorts = [];
        sorting[shelfName].forEach(sortDefinition => {
          const name = sortDefinition.path[0];
          if (_.includes(removedSorts, name)) {
            // Previous definition knocked this one out, ignore it
            return;
          }

          const shelf = Utils.findShelf(props, name);

          // We can only ever sort by one value at a time, clear out any others
          if (shelf === 'VALUES') {
            returnSort[shelfName] = _.reject(
              returnSort[shelfName],
              x =>
                x.path.length >
                1 /* only value sorts have a path greater than 1 */,
            );
          }

          // Add to the return sorting
          returnSort[shelfName].push(sortDefinition);

          if (shelf !== 'VALUES') {
            // Ensure the rest of the hierarchy above us has a sort definition in play, if not create it
            const levelIdx = props.viz.layout[shelf].findIndex(
              x => x.name === name,
            );

            props.viz.layout[shelf]
              .slice(0, Math.max(0, levelIdx))
              .reverse()
              .forEach(attr => {
                if (
                  !returnSort[shelfName].find(x =>
                    _.isEqual(x.path, [attr.name]),
                  )
                ) {
                  returnSort[shelfName].unshift({
                    direction: 'asc',
                    path: [attr.name],
                  });
                }
              });
          }

          if (shelf === 'VALUES') {
            // value row sort
            // Sort all levels in the hierarchy except the last, which values is a peer of.
            props.viz.layout.ROWS.slice(
              0,
              Math.max(0, props.viz.layout.ROWS.length - 1),
            ).forEach(attr => {
              if (
                !returnSort[shelfName].find(x => _.isEqual(x.path, [attr.name]))
              ) {
                returnSort[shelfName].unshift({
                  direction: 'asc',
                  path: [attr.name],
                });
              }
            });

            if (props.viz.layout.ROWS.length > 0) {
              // If there's a sort of the last level, clear it out so the value sort can have an effect.
              const lastLevel = _.last(props.viz.layout.ROWS).name;
              returnSort[shelfName] = _.reject(returnSort[shelfName], x =>
                _.isEqual(x.path, [lastLevel]),
              );
              removedSorts.push(lastLevel);
            }
          }
        });
      });
    } else {
      // column sort
      return sorting;
    }
    return returnSort;
  },

  /**
   * Takes a 2 dimensional table and returns a 3 dimensional table with repeated elements pivoted to the Z
   *
   * The rules for which repeated cells to collapse are based on the hierarchy of row values.
   * The following array will collapse the repeated values in the second row based on the parent row's value
   *
   * Given:
   * [T,T,V,G,G,G]
   * [X,X,X,Z,Z,Z]
   * [A,A,A,A,B,A]
   *
   * Returns:
   * [[T,2],[V,1],[G,3]]
   * [[X,2],[X,1],[Z,3]]
   * [[A,3], [A,1], [B,1], [A,1]]
   *
   * @param rawArry
   * @returns {Array}
   */
  collapseRepeated(
    rawArry: {
      columnData: {
        value: number | string;
        columnMeta?: {
          [fieldName: string]: string;
        };
      }[];
    }[],
  ): (string | number)[][][] {
    let prevLine;
    const collapsedData = rawArry.map(row => {
      const reformatted = row?.columnData?.reduce(
        (acc, item) => {
          if (prevLine && prevLine.length > 1) {
            const above = prevLine[acc.prevLineIndex];
            if (acc.current[1] === above[1] || above[1] === acc.count) {
              acc.force = true;
              acc.count = 0;
              acc.prevLineIndex++;
            }
          }

          if (
            (acc.current.length > 1 && acc.current[0]?.value !== item?.value) ||
            acc.force
          ) {
            acc.current = [item, 0]; // make new
            acc.list.push(acc.current);
            acc.force = false;
          }

          acc.current[1]++;
          acc.count++;
          return acc;
        },
        {
          current: [
            {
              value: undefined,
            },
            0,
          ] as [any, number], // ref
          force: false,
          prevLineIndex: 0,
          count: 0,
          list: [],
        },
      );
      prevLine = reformatted?.list;
      return reformatted?.list;
    });
    return collapsedData;
  },

  /**
   * Rotate the given table by the specified angle.
   */
  rotateTable(
    inputTable: {
      type: 'data' | 'rowSubtotal' | 'rowTotal';
      rowMeta: {
        [fieldName: string]: string;
      }[];
      columnData: {
        value: number | string;
        columnMeta?: {
          [fieldName: string]: string;
        };
      }[];
    }[],
    degree: number,
  ): {
    columnData: {
      value: number | string;
      columnMeta?: {
        [fieldName: string]: string;
      };
    }[];
  }[] {
    const returnVal = [];

    if (inputTable.length === 0) {
      return returnVal;
    }
    switch (degree) {
      case -90: {
        for (let i = 0; i < inputTable[0].columnData.length; i++) {
          for (let z = 0; z < inputTable.length; z++) {
            if (!returnVal[i]) {
              returnVal[i] = { columnData: [] };
            }
            returnVal[i].columnData[z] = inputTable[z].columnData[i];
          }
        }
        break;
      }
      case 90: {
        for (let i = 0; i < inputTable[0].columnData.length; i++) {
          for (let z = inputTable.length - 1; z >= 0; z--) {
            if (!returnVal[i]) {
              returnVal[i] = { columnData: [] };
            }
            returnVal[i].columnData[z] = inputTable[z].columnData[i];
          }
        }
        break;
      }
      default: {
        throw new Error(
          `rotateTable currently doesn't support degree: ${degree}`,
        );
      }
    }

    return returnVal;
  },

  getPivotCellClasses(
    col: number,
    row: number,
    colCount: number,
    rowCount: number,
  ): string {
    const cl = ['pivot-cell'];
    if (col === 0) {
      cl.push('first-col');
    }
    if (row === 0) {
      cl.push('first-row');
    }
    if (col === colCount - 1) {
      cl.push('last-col');
    }
    if (row === rowCount - 1) {
      cl.push('last-row');
    }
    return cl.join(' ');
  },

  getPivotCellDisplayValue(field: IAnyAttribute): string {
    let value = field.name;
    // Show parent field name for exact date time attributes
    if (
      !_.isNil((field as ITimeCalcAttribute).timeAttribute) &&
      _.includes(
        ['EXACT_DATE', 'DATE'],
        (field as ITimeCalcAttribute).timeAttribute.key,
      )
    ) {
      value = field.parentField.name;
    }
    return value;
  },

  getColorScale(metric: IMetric): ID3ColorScales {
    const { min, median, max, colorScale } = metric;
    let lowerScale: ScaleLinear<number, number>;
    let upperScale: ScaleLinear<number, number>;
    const { colors } = PIVOT_TABLE.COLOR_SCALES[colorScale];
    if (colors.length === 2) {
      lowerScale = scaleLinear()
        .domain([min, max])
        .range(_.slice(colors, 0, 2));
      upperScale = lowerScale;
    } else if (colors.length === 3) {
      lowerScale = scaleLinear()
        .domain([min, median])
        .range(_.slice(colors, 0, 2));
      upperScale = scaleLinear()
        .domain([median, max])
        .range(_.slice(colors, 1, 3));
    }
    return { lowerScale, upperScale };
  },

  /**
   * Get the actual current style by creating a transient element and capturing the computed style.
   * Note: cascading dependant styles cannot be properly computed outside of their context (parent elements)
   */
  getComputedStyle(clazz: string, prop: string, defaultVal: string): string {
    const test = document.createElement('div');

    if (!test) {
      return `12px ${DefaultFontName}`;
    }

    test.setAttribute('class', clazz);
    test.style.position = 'absolute';
    document.body.appendChild(test);
    const style = window.getComputedStyle(test)[prop] || defaultVal;
    test.remove();
    return style;
  },

  getDataCustomFormatters: (
    viz: IViz,
  ): { [key: string]: ICustomFormatProps } => {
    return Viz.getDataCustomFormatters(viz);
  },

  getDataFormatters: (viz: IViz): { [key: string]: IFormatProto } => {
    const fields = Viz.getAllFieldsInPlay(viz?.layout);
    const links = getAllLinksEnabledInReport(viz, fields);
    const metricFields = Viz.getAllMetricFieldsInPlay(viz);

    return fields.reduce((accum, current) => {
      if (links[current.name]) {
        accum[current.name] = LinkFormatter;
      } else {
        const isMetricField = _.some(metricFields, _.pick(current, 'name'));

        // if it is a metric and its default aggregation is count or count distinct, force the format to whole number
        if (
          isMetricField &&
          (current.defaultAggregation === 'Count' ||
            current.defaultAggregation === 'Count (Distinct)')
        ) {
          accum[current.name] = DATA_FORMATTER.WHOLE_NUMBER;
          return accum;
        }
        const formatter = DATA_TYPE_FORMAT.getFormatterByName(
          current.formatType,
        );
        if (formatter) {
          accum[current.name] = formatter;
        } else if (current.attributeType === Types.TIME_CALC) {
          if ((current as any).fieldListDisplayName === 'Date') {
            accum[current.name] = DATE_FORMATTER;
          } else {
            accum[current.name] = DATA_FORMATTER.STRING;
          }
        } else {
          accum[current.name] = DATA_TYPE_FORMAT.getDefaultFormatterForType(
            current.attributeType,
          );
        }
      }
      return accum;
    }, {});
  },

  getParentColumns: (
    columnIndex: number,
    colHeaderData: any[],
    viz: IViz,
  ): any[] => {
    const justHeaders = _.reverse(
      colHeaderData.slice(0, viz.layout.COLUMNS.length),
    );
    const parents = justHeaders.map(headerRow => {
      let actualCount = 0;

      if (columnIndex < 0) {
        const totalCount = headerRow.reduce(
          (accum, curr) => accum + curr.length,
          0,
        );
        columnIndex += totalCount;
      }

      for (let y = 0; y < headerRow.length; y++) {
        actualCount += headerRow[y][1];
        if (actualCount > columnIndex) {
          return headerRow[y][0];
        }
      }
      throw new Error(
        `Could not find parent header for columnIndex: ${columnIndex}`,
      );
    });
    return parents;
  },
};
export default Utils;
