import _ from 'lodash';

const makeFinite = num => (_.isFinite(num) ? num : 0);

export interface ITooltipRenderStrategyOpts {
  width: number;
  height: number;
  x: number;
  xPos?: number;
  y: number;
  arrowHeight: number;
  tooltipWidth: number;
  tooltipRectWidth: number;
  tooltipRectHeight: number;
  tooltipPaddingX: number;
  tooltipTextOuterPadding: number;
  arrow?: string;
  viewDetailsWidth?: number;
  viewDetailsHeight?: number;
}

export type RenderStrategyReturnVars = {
  xPos: number;
  yPos: number;
  arrow: string;
  arrowYOffset: number;
  downArrowXOffset: number;
  upArrowXOffset: number;
  arrowHeight?: number;
  xPosDetailsButton?: number;
  yPosDetailsButton?: number;
  viewDetailsWidth?: number;
  viewDetailsHeight?: number;
};

export const defaultRenderStrategy = ({
  width,
  height,
  x,
  xPos,
  y,
  arrowHeight,
  tooltipWidth,
  tooltipRectWidth,
  tooltipRectHeight,
  tooltipPaddingX,
  tooltipTextOuterPadding,
  arrow,
}: ITooltipRenderStrategyOpts): RenderStrategyReturnVars => {
  let _xPos = 0,
    _yPos = 0,
    _arrow,
    arrowYOffset = 0,
    downArrowXOffset = 0,
    upArrowXOffset = 0;

  arrowYOffset = makeFinite(tooltipRectHeight / 2 - arrowHeight / 2);
  downArrowXOffset = 0;
  upArrowXOffset = 0;

  // default position is the the right of the [x,y] coords given
  _arrow = 'left';
  _xPos = makeFinite(x + tooltipPaddingX);
  _yPos = makeFinite(y - tooltipTextOuterPadding - tooltipRectHeight / 2);

  const leftRightTooltipWillFitVertically = () => {
    const above = y - (tooltipRectHeight + tooltipPaddingX) / 2 >= 0;
    const below = y + tooltipRectHeight / 2 <= height;
    return above && below;
  };
  const canRenderRight = () => {
    const hasHSpace = x + tooltipWidth + tooltipPaddingX <= width;
    return hasHSpace && leftRightTooltipWillFitVertically();
  };

  const canRenderLeft = () => {
    const hasHSpace = x - tooltipWidth - tooltipPaddingX >= 0;
    return hasHSpace && leftRightTooltipWillFitVertically();
  };

  const canRenderAbove = () => y - tooltipRectHeight - tooltipPaddingX >= 0;
  const canRenderBelow = () =>
    y + tooltipRectHeight + tooltipPaddingX <= height;

  if (canRenderRight()) {
    // use the default x & y positioning already set
  } else if (canRenderLeft()) {
    _arrow = 'right';
    _xPos = x - (tooltipWidth + tooltipPaddingX);
  } else if (canRenderAbove()) {
    _arrow = 'down';
    _yPos = y - tooltipRectHeight - tooltipPaddingX;

    const left = x - tooltipWidth / 2;
    const right = x + tooltipWidth / 2;

    _xPos = Math.max(0, left);
    if (right >= width) {
      _xPos = Math.max(0, width - tooltipWidth);
      downArrowXOffset = Math.min(left - _xPos, tooltipRectWidth / 2 - 8);
    } else if (left <= 0) {
      downArrowXOffset = Math.max(
        x - tooltipRectWidth / 2,
        tooltipRectWidth / -2 + 6,
      );
    }
  } else if (canRenderBelow()) {
    _arrow = 'up';
    _yPos = y + tooltipPaddingX;

    const left = x - tooltipWidth / 2;
    const right = x + tooltipWidth / 2;

    _xPos = Math.max(0, left);
    if (right >= width) {
      _xPos = Math.max(0, width - tooltipWidth);
      upArrowXOffset = Math.min(left - _xPos, tooltipRectWidth / 2 - 8);
    } else if (left <= 0) {
      upArrowXOffset = Math.max(
        x - tooltipRectWidth / 2,
        tooltipRectWidth / -2 + 6,
      );
    }
  } else {
    _xPos = 0;
    _yPos = (height - tooltipRectHeight) / 2;
    _arrow = 'none';
  }

  if (arrow) {
    _arrow = arrow;
  }
  if (xPos) {
    _xPos = xPos;
  }

  return {
    xPos: _xPos,
    yPos: _yPos,
    arrow: _arrow,
    arrowYOffset,
    downArrowXOffset,
    upArrowXOffset,
  };
};

export const viewDetailsRenderStrategy = ({
  width: chartWidth,
  height: chartHeight,
  x: tooltipAnchorXPos,
  y: tooltipAnchorYPos,
  arrowHeight,
  tooltipPaddingX,
  tooltipRectWidth,
  tooltipRectHeight,
  tooltipTextOuterPadding,
  viewDetailsWidth,
  viewDetailsHeight,
}: ITooltipRenderStrategyOpts): RenderStrategyReturnVars => {
  let xPos = tooltipAnchorXPos,
    yPos = tooltipAnchorYPos,
    arrow = 'left',
    arrowYOffset = 0,
    _viewDetailsWidth = 0,
    _viewDetailsHeight = 0;

  const downArrowXOffset = 0,
    upArrowXOffset = 0,
    viewDetailsPaddingX = 20,
    viewDetailsPaddingY = 0;

  arrowYOffset = makeFinite(tooltipRectHeight / 2 - arrowHeight / 2);

  _viewDetailsWidth = makeFinite(viewDetailsWidth);
  _viewDetailsHeight = makeFinite(viewDetailsHeight + viewDetailsPaddingY * 2);

  let xPosDetailsButton = makeFinite(
    tooltipAnchorXPos - tooltipPaddingX - viewDetailsWidth,
  );
  let yPosDetailsButton = makeFinite(tooltipAnchorYPos - viewDetailsHeight / 2);
  yPos = makeFinite(tooltipAnchorYPos - tooltipRectHeight / 2);

  const isAnchorPositionOnLeft = () => tooltipAnchorXPos <= chartWidth / 2;
  const isAnchorPositionOnRight = () => !isAnchorPositionOnLeft();
  const isAnchorPositionOnTop = () => tooltipAnchorYPos <= chartHeight / 2;

  const canRenderRight = () =>
    tooltipAnchorXPos + tooltipRectWidth + tooltipPaddingX * 2 <= chartWidth;
  const canRenderAbove = () => tooltipAnchorYPos - tooltipRectHeight / 2 >= 0;
  const canRenderBelow = () =>
    tooltipAnchorYPos + tooltipRectHeight / 2 <= chartHeight;

  const canViewDetailsRenderLeft = () =>
    tooltipAnchorXPos - (viewDetailsWidth + viewDetailsPaddingX * 2) >= 0;
  const canViewDetailsRenderRight = () =>
    tooltipAnchorXPos + (viewDetailsWidth + viewDetailsPaddingX * 2) <=
    chartWidth;

  const fitTooltipButtonAtEdge = () => {
    if (isAnchorPositionOnRight()) {
      arrow = 'right';
      xPos = tooltipAnchorXPos - tooltipRectWidth - tooltipPaddingX;
      xPosDetailsButton =
        tooltipAnchorXPos - viewDetailsWidth - tooltipPaddingX + 5;
    } else {
      arrow = 'left';
      xPos = tooltipAnchorXPos + tooltipPaddingX;
      xPosDetailsButton = tooltipAnchorXPos + tooltipPaddingX + 5;
    }

    if (isAnchorPositionOnTop()) {
      yPos = tooltipAnchorYPos - tooltipTextOuterPadding * 2;
      arrowYOffset = tooltipTextOuterPadding * 2;
      yPosDetailsButton =
        tooltipAnchorYPos + tooltipRectHeight + viewDetailsPaddingY;
    } else {
      yPos =
        tooltipAnchorYPos - tooltipRectHeight + tooltipTextOuterPadding * 2;
      yPosDetailsButton =
        tooltipAnchorYPos -
        tooltipRectHeight -
        viewDetailsHeight -
        viewDetailsPaddingY;
      arrowYOffset = tooltipRectHeight - tooltipTextOuterPadding * 2;
    }
  };

  if (!canRenderAbove() || !canRenderBelow()) {
    fitTooltipButtonAtEdge();
  } else if (canRenderRight()) {
    // use the default x & y positioning already set
    xPos = tooltipAnchorXPos + tooltipPaddingX;
    if (!canViewDetailsRenderLeft()) {
      fitTooltipButtonAtEdge();
    }
  } else {
    arrow = 'right';
    xPos = tooltipAnchorXPos - tooltipRectWidth - tooltipPaddingX;
    xPosDetailsButton = tooltipAnchorXPos + viewDetailsPaddingX;
    if (!canViewDetailsRenderRight()) {
      fitTooltipButtonAtEdge();
    }
  }

  return {
    xPos,
    yPos,
    arrow,
    arrowYOffset,
    downArrowXOffset,
    upArrowXOffset,
    arrowHeight,
    xPosDetailsButton,
    yPosDetailsButton,
    viewDetailsWidth: _viewDetailsWidth,
    viewDetailsHeight: _viewDetailsHeight,
  };
};
