import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { css } from '@emotion/react';

export const I18nSettingsBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  justify-content: space-between;
  align-items: flex-start;
`;

export const I18nSettingsBoxItem = styled(Box)(
  ({
    theme: {
      colors: { ContentText },
    },
  }) => css`
    flex: 1 1 40%;
    & span.title {
      color: ${ContentText};
    }
  `,
);
