import { DiscoverModalContainerContext } from '@sugar-discover/react-bootstrap-wrapper';
import {
  RefObject,
  useContext,
  useRef,
  useEffect,
  MouseEvent,
  useCallback,
  useMemo,
} from 'react';
import { isFunction } from 'lodash';

export const useOutsideClick = <T extends HTMLElement = HTMLElement>(
  callback: (e: MouseEvent) => void,
  isElementVisible: boolean,
): RefObject<T> => {
  const ref = useRef<T>();

  const contextWindow = useContext(DiscoverModalContainerContext);

  const container = useMemo(
    () => (contextWindow ?? document.body) as HTMLElement,
    [contextWindow],
  );

  const handleClick = useCallback(
    event => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    },
    [callback],
  );

  useEffect(() => {
    if (
      !isFunction(container?.addEventListener) ||
      !isFunction(container?.removeEventListener)
    ) {
      return;
    }

    if (isElementVisible) {
      container?.addEventListener('click', handleClick, true);
    } else {
      container?.removeEventListener('click', handleClick, true);
    }

    return () => {
      isFunction(container?.removeEventListener) &&
        container?.removeEventListener('click', handleClick, true);
    };
  }, [callback, ref, isElementVisible, container, handleClick]);

  return ref;
};
