import styled from '@emotion/styled';
import { IPanel } from '../ui/interfaces';
import { css } from '@emotion/react';
import {
  useIconColor,
  CloseIcon,
  SugarIconColorType,
  ToastIcon,
} from '../../icons';
import _ from 'lodash';

const Panel = styled('div')(
  ({
    type: iconType,
    theme: {
      colors: { FormControlShadowColor, ContentBackground } = {} as any,
    } = {},
  }: any) => {
    const iconColor = useIconColor({ iconType });
    return css`
      min-width: 273px;
      min-height: 40px;
      width: 100%;
      font-size: 14px;
      box-shadow: 0px 0px 8px 0px ${FormControlShadowColor};
      display: inline-flex;
      align-items: center;
      padding-left: 8px;
      border-left: 8px solid ${iconColor};
      border-radius: 3px;
      position: relative;
      padding-right: 40px;
      background-color: ${ContentBackground};
    `;
  },
);

const ClearIcon = styled(CloseIcon)`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 11;
  cursor: pointer;
`;

const ContentArea = styled('div')(
  ({
    theme: {
      colors: {
        InfoColorHeaderText,
        ContentTextColor,
        ActiveLinkText,
      } = {} as any,
    } = {},
  }: any) => css`
    padding: 10px 0;
    max-width: 90%;
    h1,
    h2,
    h3,
    h4 {
      color: ${InfoColorHeaderText};
      font-size: 16px;
      font-weight: 500;
      margin: 0 0 0 0;
      padding: 4px 0;
      strong {
        font-weight: 700;
      }
    }
    p {
      color: ${ContentTextColor};
      margin: 0;
      font-size: 14px;
      padding: 4px 0;
    }
    a {
      color: ${ActiveLinkText};
    }
    margin-left: 8px;
  `,
);

export const GenericPanel = (props: IPanel) => {
  const { children, onClickClear, type } = props;

  return (
    <Panel className='generic-panel' type={type as SugarIconColorType}>
      <ToastIcon iconType={type as SugarIconColorType} />
      <ContentArea>
        {children && <div className='children'> {children} </div>}
      </ContentArea>
      {onClickClear && (
        <ClearIcon
          className={'clearToastMessage'}
          size={12}
          onClick={() => {
            if (_.isFunction(onClickClear)) {
              onClickClear();
            }
          }}
        />
      )}
    </Panel>
  );
};

export const InfoPanel = props => <GenericPanel type={'info'} {...props} />;

export default InfoPanel;
