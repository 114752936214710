import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createFilterForField, FilterTypes } from './Filter';
import Discover from '../../common/redux/actions/DiscoverActions';
import FilterExpression from './FilterExpression';
import { messages } from '../../i18n';
import { IFilter } from '../../datasets/interfaces/filter.interface';

interface IBooleanFilterProps {
  filter: IFilter;
  field: any;
}

export const BooleanFilter = (props: IBooleanFilterProps) => {
  const dispatch = useDispatch();
  const filter = props.filter ?? createFilterForField(props.field);
  const onExpressionChanged = useCallback(
    expression => {
      dispatch(
        Discover.setActiveFieldFilter({
          ...filter,
          expression: { ...expression },
        }),
      );
    },
    [dispatch, filter],
  );
  return (
    <div className='filter-content numeric-filter'>
      <div className='section-label'>{messages.filters.setCondition}</div>
      <div className='filter-panel filter-condition'>
        <div className='numeric-condition'>
          <FilterExpression
            expression={filter.expression}
            onChange={onExpressionChanged}
            filterType={FilterTypes.BOOLEAN}
          />
        </div>
      </div>
    </div>
  );
};
