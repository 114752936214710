import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { KabobIconContainerStyled } from '../icon-dropdown/icon-dropdown.styles';
import MenuList from '@mui/material/MenuList';

export const ModernIconContainerStyled = styled(KabobIconContainerStyled)`
  height: 12px;
  width: 12px;
  &:hover {
    background-color: transparent;
    & > .sicon {
      color: inherit;
    }
  }
`;

export const ProfileIconWrapper = styled.div(
  ({
    theme: {
      colors: { PrimaryButtonColor },
    },
  }) => css`
    background-color: ${PrimaryButtonColor};
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    & .sicon {
      color: #ffffff;
    }
  `,
);

export const ProfileIconHoverContainer = styled.div(
  ({
    theme: {
      colors: { ProfileHoverColor },
    },
  }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    border-radius: 32px;
    &:hover {
      background-color: ${ProfileHoverColor};
      cursor: pointer;
    }
  `,
);

export const ProfileSubMenuWrapper = styled.div`
  display: flex;
`;

export const ProfileWrapperIcon = styled.div`
  padding-left: 5px;
  & .sicon {
    color: #ffffff;
  }
`;

export const ProfileSubMenuListStyled = styled(MenuList)<{ open: boolean }>`
  padding: 0.375rem;
  position: absolute;
  right: 84px;
  top: 56px;
  background-color: ${({ theme }) => theme.colors.SubMenuBorder};
  border-radius: 8px;
  border: none;
  width: 210px;
  overflow: hidden;
  overflow-y: auto;
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  max-height: 22rem;
  z-index: 1060;
`;
