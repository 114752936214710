import { Component } from 'react';
import _ from 'lodash';
import { pure } from 'react-recompose';

class CorvanaCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked });
    }
  }
  _onChange(e) {
    this.setState({ checked: e.currentTarget.checked });
    if (_.isFunction(this.props.onChange)) {
      this.props.onChange(e);
    }
  }
  render() {
    const { children, value, disabled } = this.props;
    const { checked } = this.state;
    let icon = '';
    if (!disabled) {
      if (_.isNil(checked)) {
        icon = 'glyphicon-minus';
      } else if (checked) {
        icon = 'glyphicon-ok';
      }
    }

    return (
      <div className={`custom-checkbox${disabled ? ' disabled' : ''}`}>
        <label>
          <input
            disabled={disabled}
            onChange={e => this._onChange(e)}
            value={value}
            checked={checked}
            type='checkbox'
            className='hidden'
          />
          <div className={`check-widget glyphicon ${icon}`} />
          <span
            className='checkbox-label'
            title={_.isString(children) ? children : ''}
          >
            {children}
          </span>
        </label>
      </div>
    );
  }
}
CorvanaCheckbox.defaultProps = {
  checked: false,
  disabled: false,
};

export default pure(CorvanaCheckbox);
