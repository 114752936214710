import { css } from '@emotion/react';

export const settingsStyles = ({
  theme: {
    colors: { Gray20 },
  },
}) => css`
  .header-navigation {
    background-color: ${Gray20};
  }
`;
