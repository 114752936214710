import { Component } from 'react';
import ReactDOM from 'react-dom';
import { Tooltip } from '../../components/ui/tooltip';
import { HelpIcon } from '../../icons';
import { withTheme } from '@emotion/react';

class Info extends Component {
  constructor() {
    super();
    this.state = { placement: 'right' };
  }

  /**
   * Display the tooltip on the right if room allows, otherwise move to the left
   */
  calculatePlacement() {
    const el = ReactDOM.findDOMNode(this);
    const { right, width } = el.getBoundingClientRect();
    const hasRoom = document.body.offsetWidth - (right + width) > 150;

    this.setState({ placement: hasRoom ? 'right' : 'left', isHovered: true });
  }
  randomize() {}
  render() {
    return (
      <Tooltip
        id='tooltip'
        arrow
        placement={this.state.placement}
        title={this.props.detail}
        onOpen={() => {
          this.calculatePlacement();
        }}
        onClose={() => this.setState({ isHovered: false })}
      >
        <div className='info-icon'>
          <HelpIcon
            size={16}
            color={
              this.state.isHovered
                ? this.props.theme?.colors?.PrimaryColor
                : undefined
            }
          />
        </div>
      </Tooltip>
    );
  }
}

Info.defaultProps = {
  detail: (
    <span>
      <strong>'Holy guacamole!'</strong> Check this info.
    </span>
  ),
};

export default withTheme(Info);
