import ColLineSvgIcon from '../../../../images/sdd/viz/visualization-selection-combo.svg';
import ListSvgCombo from '../../../../images/sdd/viz/line-column-combo.svg';
import BaseChartSpec, {
  ENABLE_REPORT_LINK,
  NON_NUMERIC_CALC,
  Validations,
  generateMeasuresFromMetrics,
} from '../../BaseChartSpec';
import { ShelfTypes } from '../../../discovery/interfaces';
import { LegendShapes } from '../legend-shape';
import {
  getDateTypesFromShelfAsOrdinals,
  getHardCodedOrdinalsFromShelfAsOrdinals,
} from '../ChartUtils';
import _ from 'lodash';
import { ChartSpecIds, Types } from '../../../common/Constants';

class ColumnLineSpec extends BaseChartSpec {
  constructor() {
    super({
      id: 'column_line',
      name: 'columnLine.chartName',
      placeholderImage: '/assets/images/sdd/chart/canvas-icon-combo.svg',
      icon: <ColLineSvgIcon />,
      listIcon: <ListSvgCombo />,
      legendShape: LegendShapes.SQUARE,
      shelves: {
        [ChartSpecIds.COLUMNS]: {
          id: ChartSpecIds.COLUMNS,
          name: 'columnLine.columnsShelf',
          accepts: [Types.ANY],
          shelfType: ShelfTypes.MEASURE,
          isColumn: false,
        },
        [ChartSpecIds.LINES]: {
          id: ChartSpecIds.LINES,
          name: 'columnLine.linesShelf',
          accepts: [Types.ANY],
          shelfType: ShelfTypes.MEASURE,
          isColumn: false,
        },
        [ChartSpecIds.XAXIS]: {
          id: ChartSpecIds.XAXIS,
          name: 'columnLine.xAxisShelf',
          accepts: NON_NUMERIC_CALC,
          isRequired: true,
          shelfType: ShelfTypes.SELECTION,
        },
        [ChartSpecIds.SLICER]: {
          id: ChartSpecIds.SLICER,
          name: 'chartSpecs.area.slicer',
          accepts: [Types.STRING, Types.TIMESTAMP],
          isRequired: false,
          shelfType: ShelfTypes.SLICER,
        },
      },
      customFormatToggles: [ENABLE_REPORT_LINK],
      supportsDifferentiatedNullHandling: true,
      supportsLegendSelection: true,
      showTooltipIndicator: true,
    });
    this.validationRules = [
      Validations.HasLayout,
      Validations.OneOf([this.shelves.XAXIS]),
      Validations.TypeSafe,
    ];
  }
  mapShelvesToQueryVariables(viz) {
    if (!this.validate(viz)) {
      return {
        attributeNames: [],
        measures: [],
      };
    }
    let attributeNames = viz.layout.XAXIS.map(g => g.name);

    // supplement the query with ordinals for sorting
    attributeNames = attributeNames.concat(
      getDateTypesFromShelfAsOrdinals(viz.layout.XAXIS),
      getHardCodedOrdinalsFromShelfAsOrdinals(viz.layout.XAXIS),
    );

    return {
      attributeNames: _.uniq(attributeNames),
      measures: generateMeasuresFromMetrics(viz),
    };
  }
}

export default ColumnLineSpec;
