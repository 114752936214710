// other types of regression might be supported in the future
import { regressionLinear } from 'd3-regression';
import { map, min, max, head, last } from 'lodash';
import {
  IGetRegressionVisible,
  ILinearRegressionInput,
} from './trendline.interfaces';

export const getLinearRegression = ({
  pointData,
  endMargin = 0,
}: ILinearRegressionInput) => {
  const lr = regressionLinear()
    .x(d => d.x)
    .y(d => d.y)(pointData);

  const xValues = map(pointData, 'x');
  const leftMostX = min(xValues) - endMargin;
  const rightMostX = max(xValues) + endMargin;

  return map([leftMostX, rightMostX], _x => [
    _x,
    lr.predict(_x), // y = mx + b
  ]);
};

const CHART_GUTTER_PADDING = 20;
const SLOPE_MARGIN = 5;

export const getTrendlineIsVisible = ({
  boundaryPoints = [],
  chartHeight = 0,
}: IGetRegressionVisible) => {
  const yVals = map(boundaryPoints, last);
  const maxYVal = max(yVals);
  const minYVal = min(yVals);
  const svgtHeightGutter = chartHeight - CHART_GUTTER_PADDING;

  // currently using linear regression, so calculate slope
  const slope = last(last(boundaryPoints)) - head(last(boundaryPoints));
  const isSlopeInThreshold = slope > SLOPE_MARGIN || slope < -SLOPE_MARGIN;
  const doesLineCrossBoundary =
    minYVal > svgtHeightGutter && maxYVal > svgtHeightGutter;

  return isSlopeInThreshold || !doesLineCrossBoundary;
};
