import ActivityFeedItemFooter from './ActivityFeedItemFooter';
import Feedback from '../../../common/widgets/Feedback';
import Info from '../../../common/widgets/info';
import _ from 'lodash';
import { messages } from '../../../i18n';

const DefaultFooter = ({
  isMobile,
  isDashletMode,
  monitorEvent,
  condensed,
  feedItem,
}) => {
  if (isMobile) {
    return (
      <ActivityFeedItemFooter>
        {!isDashletMode && (
          <div className='feedback-container'>
            <Feedback
              monitorId={monitorEvent.monitor.id}
              anomalyId={monitorEvent.id}
              feedback={feedItem?.feedback}
            />
            <Info
              detail={messages.formatString(
                messages.insightFeed.feedbackDetail,
                messages.nonTranslated.sugarDiscover,
              )}
            />
          </div>
        )}
      </ActivityFeedItemFooter>
    );
  } else {
    return (
      <ActivityFeedItemFooter>
        <div className='actions'>
          <div className='feedback-container'>
            <Feedback
              monitorId={monitorEvent.monitor.id}
              anomalyId={monitorEvent.id}
              label={!condensed ? messages.insightFeed.wasItUseful : ''}
              feedback={feedItem?.feedback || monitorEvent?.feedback}
            />
            <Info
              detail={messages.formatString(
                messages.insightFeed.feedbackDetail,
                messages.nonTranslated.sugarDiscover,
              )}
            />
          </div>
        </div>
      </ActivityFeedItemFooter>
    );
  }
};
export default DefaultFooter;
