import _ from 'lodash';
import moment from '../../common/Moment';
import {
  FilterTypes,
  NOW,
  StringFilterSubTypes,
  TimestampFilterSubTypes,
  TimestampFilterPeriods,
} from './Filter';
import { FilterOperators } from './FilterOperators';
import {
  IExpression,
  IExpressionSegment,
  IFilter,
} from '../../datasets/interfaces/filter.interface';
import { IViz } from '../interfaces/viz.interface';
import { Viz } from '../VizUtil';

const validateNumericFilter = filter => {
  const { left, operator, right } = filter.expression;
  if (_.isNil(left) || _.isNil(left.operator)) {
    return false;
  }
  // If the left condition requires an operand, it has to be a number
  if (
    FilterOperators.forFilterType(filter.type, filter.subType)[left.operator]
      ?.requiresOperand
  ) {
    if (_.isEmpty(left.operands[0]) || isNaN(_.toNumber(left.operands[0]))) {
      return false;
    }
  }
  if (!_.isNil(operator) && !_.isNil(right)) {
    if (_.isNil(right.operator)) {
      return false;
    }
    if (
      FilterOperators.forFilterType(FilterTypes.NUMERIC)[right.operator]
        ?.requiresOperand
    ) {
      if (
        _.isEmpty(right.operands[0]) ||
        isNaN(_.toNumber(right.operands[0]))
      ) {
        return false;
      }
    }
  }
  return true;
};

const validateBooleanFilter = filter => {
  const { left } = filter.expression;
  const input = _.join(left?.operands as [], '');
  return input?.toLowerCase() === 'false' || input?.toLowerCase() === 'true';
};

const BaseConditionFilter = func => filter => {
  const { left, operator, right } = filter.expression;
  if (_.isNil(left) || _.isNil(left.operator)) {
    return false;
  }

  const operators = FilterOperators.forFilterType(filter.type, filter.subType);
  // if non-leaf node
  if (!_.isNil((left as IExpression).left)) {
    if (
      !BaseConditionFilter(func)({
        ...filter,
        expression: left,
      })
    ) {
      return false;
    }
  } else if (operators[left.operator]?.requiresOperand) {
    if (
      _.isNil((left as IExpressionSegment).operands[0]) ||
      func((left as IExpressionSegment).operands[0])
    ) {
      return false;
    }
  }
  // right expressions are only IExpressionSegments
  if (!_.isNil(operator) && !_.isNil(right)) {
    if (_.isNil(right.operator)) {
      return false;
    }
    if (operators[right.operator]?.requiresOperand) {
      if (_.isNil(right.operands[0]) || func(right.operands[0])) {
        return false;
      }
    }
  }
  return true;
};

const validateStringConditionFilter = BaseConditionFilter(
  x => _.trim(x).length === 0,
);

const validateRelativeTimestampFilter = filter => {
  const { left } = filter.expression;

  // Verifies minimum length and valid number for relative value
  if (
    _.isNil(left.operator) ||
    left.operands.length < 3 ||
    _.isNaN(_.toNumber(left.operands[0]))
  ) {
    return false;
  }
  // Anchor is optional 4th param. Verify it's either NOW() or a valida date
  if (
    left.operands.length === 4 &&
    left.operands[3] !== NOW &&
    !moment(left.operands[3]).isValid()
  ) {
    return false;
  }
  return true;
};

const validateTimestampFilter = (filter: IFilter): boolean => {
  switch (filter.subType) {
    case TimestampFilterSubTypes.RELATIVE_DATES:
      return validateRelativeTimestampFilter(filter);
    case TimestampFilterSubTypes.SET_CONDITION:
      return BaseConditionFilter(x => {
        // should have field here to validate quarter, month, or week
        const possibleInteger = _.toInteger(x);
        const isMonthQuarterOrWeekIndex =
          _.isNumber(possibleInteger) &&
          possibleInteger >= 1 &&
          possibleInteger <= 53;
        const isNow = x === NOW;
        return !(moment(x).isValid() || isMonthQuarterOrWeekIndex || isNow);
      })(filter);
    case TimestampFilterSubTypes.PERIOD_TO_DATE:
      return BaseConditionFilter(x => _.isNil(TimestampFilterPeriods[x]))(
        filter,
      );
  }
};

const validateStringTopBottom = filter => {
  const passesNumericValidation = validateNumericFilter(filter);
  return (
    passesNumericValidation &&
    !_.isNil(filter.aggregationContext) &&
    !_.isNil(filter.aggregationContext.field) &&
    !_.isNil(filter.aggregationContext.aggregation)
  );
};

export const validateFilter = (filter: IFilter, field?): boolean => {
  if (
    _.includes(
      ['dynamicField', 'currentUser'],
      filter?.expression?.left?.operator,
    )
  ) {
    return true;
  }
  if (_.isNil(filter)) {
    return false;
  }
  if (_.isNil(FilterTypes[filter.type])) {
    return false;
  }
  if (_.isNil(filter.field)) {
    return false;
  }
  if (_.isNil(filter.expression)) {
    return false;
  }
  if (_.isNil(filter.expression.left)) {
    return false;
  }

  switch (filter.type) {
    case FilterTypes.BOOLEAN:
      return validateBooleanFilter(filter);
    case FilterTypes.NUMERIC:
      return field?.calcType === FilterTypes.BOOLEAN
        ? validateBooleanFilter(filter)
        : validateNumericFilter(filter);
    case FilterTypes.STRING:
      if (filter.subType === StringFilterSubTypes.SELECT_ITEMS) {
        // these are always valid
        return true;
      } else if (filter.subType === StringFilterSubTypes.SET_CONDITION) {
        return validateStringConditionFilter(filter);
      } else {
        return validateStringTopBottom(filter);
      }
    case FilterTypes.DATE:
      return validateTimestampFilter(filter);
    default:
      return false;
  }
};

export const validateFilters = (viz: IViz, filters: IFilter[]) => {
  let valid = true;
  const allFields = Viz.getAllAvailableFields(viz);
  filters.forEach(
    (filter: IFilter) =>
      // Ensure that the filter format is valid and the filter field is in the dataset
      (valid =
        valid &&
        validateFilter(filter) &&
        !_.isNil(_.find(allFields, { name: filter.field }))),
  );
  return valid;
};
