import styled from '@emotion/styled';
import { ModalPortal } from '../../common/widgets/dialogs/modal-portal/modal-portal.component';

export const StyledModalPortal = styled(ModalPortal)`
  .confirm-logout-dialog {
    width: 430px;
  }
`;

export const ModalBody = styled.div`
  font-size: 12px;
  padding: 0.4rem 1rem 1rem;
`;

export const UnsavedList = styled.ul`
  margin: 0.6rem 0 0;
  padding-left: 1rem;
`;
