import { useCallback, useRef, useState } from 'react';
import { get, sortBy, map, noop, isFunction, forEach, merge } from 'lodash';
import classnames from 'classnames';
import { DATA_TYPE_FORMAT } from '../../common/Constants';
import { messages } from '../../i18n';
import { AttributeTypeIcon } from './data-type-selector.util';
import { SelectDropdown, SelectItem } from '../../ui/dropdowns/select-dropdown';
import { useObserver } from '../../common/utilities/resize-observer';
import {
  headerSx,
  titleStyle,
  titleTextStyle,
  useDataTypeListItemSx,
} from './data-type-selector.styles';
import { IUserInfo } from '../../account/interfaces';
import { useAccount } from '../../common/utilities/account';

export const DataTypeSelector = ({
  id,
  dataType,
  formatType,
  onSelect = noop,
  className,
  showDataTypeIcon,
  isCalc,
  currencySymbol, // can be found via datasetId if passed
  disablePortal,
  headerSx: providedHeaderSx = {},
  popperSx,
}) => {
  const [hideFormats, setHideFormats] = useState(true);
  const dropdownRef = useRef();
  const { currentUser = {} as IUserInfo } = useAccount();
  const { i18nPrefs = {} } = currentUser;

  const { itemSx, firstItemSx, lastItemSx } = useDataTypeListItemSx({
    hideFormats,
  });

  const resizeCallback = useCallback((entries: ResizeObserverEntry[]) => {
    forEach(entries, entry => {
      setHideFormats(entry?.borderBoxSize[0]?.inlineSize < 170);
    });
  }, []);
  useObserver({ callback: resizeCallback, element: dropdownRef?.current });

  // sort descending
  const formats = sortBy(
    DATA_TYPE_FORMAT.getFormattersByDataType(dataType),
    'grouping',
  );

  const selected = DATA_TYPE_FORMAT.getFormatterByName(formatType);
  const menuItems = map(formats, (df, idx) => {
    let sx = itemSx;
    if (idx === 0) {
      sx = firstItemSx;
    } else if (idx === formats.length - 1) {
      sx = lastItemSx;
    }
    return (
      <SelectItem
        key={`${id}-${df.formatType}`}
        className={classnames({ selected: formatType === df.formatType })}
        onClick={() =>
          isFunction(onSelect) &&
          onSelect(DATA_TYPE_FORMAT.getFormatterByName(df.formatType))
        }
        sx={sx}
      >
        <div className={'format-type'}>
          {get(messages, df.displayName, df.displayName)}
        </div>
        {/* show the format as tooltip */}
        <div className={'format-example'}>
          {df.formatExample(currencySymbol, i18nPrefs)}
        </div>
      </SelectItem>
    );
  });

  const title = (
    <div style={titleStyle}>
      {showDataTypeIcon && (
        <AttributeTypeIcon dataType={dataType} isCalc={isCalc} />
      )}
      <span style={titleTextStyle}>
        {get(messages, selected.displayName, selected.displayName)}
      </span>
    </div>
  );

  return menuItems?.length === 1 ? (
    title
  ) : (
    <SelectDropdown
      id={id}
      ref={dropdownRef}
      className={classnames('data-type-dropdown', className)}
      title={title}
      headerSx={merge(headerSx, providedHeaderSx)}
      disablePortal={disablePortal}
      popperSx={popperSx}
    >
      {menuItems}
    </SelectDropdown>
  );
};
