import PivotConditionalFormatting from '../../../components/PivotConditionalFormatting';

export const ConditionalFormattingSettings = ({
  chartSpec,
  chartType,
  vizId,
}) => {
  if (chartSpec.supportsConditionalFormatting && chartType === 'pivot') {
    return <PivotConditionalFormatting vizId={vizId} />;
  } else {
    return null;
  }
};
