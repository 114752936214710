import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { spinAnim } from '../../common/widgets/Status';

export const HistoryItemWrapper = styled.div(
  ({ theme: { colors: { LightBlue } = {} as any } = {} as any }) => css`
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &:hover {
      background-color: ${LightBlue};
    }

    &.selected {
      background-color: ${LightBlue};
      cursor: not-allowed;
    }
  `,
);

export const CommitMessage = styled.div(
  ({ theme: { colors: { ContentBlack } = {} as any } = {} as any }) => css`
    color: ${ContentBlack};
    font-size: 12px;
    max-width: calc(100% - 15px);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: wrap;
    line-height: 1rem;
  `,
);

export const UnsavedCommitMessage = styled(CommitMessage)`
  font-style: italic;
  width: 100%;
`;

export const Bullet = styled.span(
  ({ theme: { colors: { Gray70 } = {} as any } = {} as any }) => css`
    height: 7px;
    width: 7px;
    border-radius: 50%;
    border: 1px solid ${Gray70};
    background-color: transparent;
    position: absolute;
    top: calc(1.4rem - 3px);
    left: 55%;
    transform: translate(-50%, -50%);
  `,
);

export const FetchLoader = styled(Bullet)`
  background-color: transparent;
  height: 16px;
  width: 16px;
  border-width: 2px;
  top: calc(1.4rem - 11px);
  left: calc(55% - 8px);
  border-bottom-color: transparent;
  animation: ${spinAnim} 0.75s linear 0s infinite normal both running;
`;

export const BulletItem = styled.div`
  min-width: 30px;
  position: relative;
  flex: 0 0 auto;
  align-self: stretch;
`;

const Connector = styled.span(
  ({ theme: { colors: { Gray60 } = {} as any } = {} as any }) => css`
    height: 100%;
    width: 0;
    border-right: 1px solid ${Gray60};
    position: absolute;
    top: 0;
    left: 55%;
    transform: translateX(-50%);
  `,
);

export const BottomConnector = styled(Connector)`
  height: calc(100% - 1.25rem - 3px);
  top: calc(1.25rem + 3px);
`;

export const TopConnector = styled(Connector)`
  height: calc(1.25rem - 3px);
`;

export const CommitDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  padding: 0.4rem 0;
`;

export const CommitDetails = styled.div`
  display: flex;
  justify-content: left;
  gap: 0.4rem;
`;

export const UpdateDetails = styled(CommitDetails)`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

export const AuthorDetail = styled.span(
  ({ theme: { colors: { Gray70 } = {} as any } = {} as any }) => css`
    color: ${Gray70};
    font-size: 11px;
  `,
);

export const CurrentVersionDetail = styled(AuthorDetail)`
  padding-top: 0.4rem;
`;

export const DotSeparator = styled.span(
  ({ theme: { colors: { Gray70 } = {} as any } = {} as any }) => css`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${Gray70};
    margin: 5px 0;
  `,
);
