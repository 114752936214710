import gql from 'graphql-tag';

const Graphql = {
  MonitorQuery: gql`
    query getMonitors {
      monitors {
        id
        lastEvent
        name
        version
        measure
        isDisabled
        threshold
        query {
          datasetId
          attributeNames
          measures {
            attributeName
            aggregation
            resultSetFunction
          }
          filters {
            attributeName
            operator
            operands
          }
          sorts {
            attributeName
            sortType
          }
        }
        trend {
          direction
          value
        }
        dataset {
          id
          name
        }
      }
    }
  `,
  MonitorEventQuery: gql`
    query monitorEvent($id: String!) {
      monitorEvent(id: $id) {
        id
        eventType
        payload
        startTimestamp
        monitor {
          id
          name
          config
        }
      }
    }
  `,
  MonitorSummary: gql`
    query getMonitorsSummary($datasetId: String) {
      monitors(datasetId: $datasetId) {
        id
        name
        dataset {
          id
          name
        }
      }
      visualizations(datasetId: $datasetId) {
        id
        name
        dataset {
          id
          name
        }
      }
    }
  `,
  MonitorDetail: gql`
    query monitorDetail($id: String!) {
      monitorDetail(id: $id) {
        id
        startTimestamp
        endTimestamp
        data {
          timestamp
          value
          baselineMin
          baselineMax
          anomalous
          score
        }
        anomalies {
          id
          startTimestamp
          endTimestamp
          peakScore
        }
      }
      monitor(id: $id) {
        threshold
        window {
          anchor
          duration
        }
      }
    }
  `,
  AddCustomMonitor: gql`
    mutation createAnomalyDetector($monitorReq: CustomMonitorRequest!) {
      addCustomMonitor(monitor: $monitorReq) {
        id
      }
    }
  `,
  AddMonitorMutation: gql`
    mutation add($monitorReq: MonitorRequest!) {
      addMonitor(monitor: $monitorReq) {
        id
      }
    }
  `,
  UpdateMonitor: gql`
    mutation update($monitorReq: MonitorRequest!) {
      updateMonitor(monitor: $monitorReq) {
        id
      }
    }
  `,

  DeleteMonitor: gql`
    mutation delete($monitor: String!) {
      deleteMonitor(id: $monitor) {
        id
      }
    }
  `,
};
export default Graphql;
