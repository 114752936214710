import { css } from '@emotion/react';
import { font, svgColorize } from '../mixins';

export const settingsMobileStyles = ({
  theme: {
    colors: {
      ChartLabelTextColor,
      RegularFontWeight,
      ActiveLinkText,
      LinkButtonTextColor,
      MediumFontWeight,
      ContentText,
      LightFontWeight,
      PanelIconColor,
      CorvanaMediumText,
      PanelIconActiveColor,
      TabHighlight,
      ErrorColor,
    },
  },
}) => css`
  .header-navigation__link-button {
    ${font({ size: '16px', weight: RegularFontWeight, color: ActiveLinkText })}
  }
  ${svgColorize({ color: ActiveLinkText })('.header-navigation__link-button')}
  .mobile-menu-button,
  .mobile-menu-button:hover {
    ${font({ size: '16px', weight: RegularFontWeight })}
  }
  .mobile-menu-button:active {
    ${font({ size: '16px', weight: RegularFontWeight })}
    background-color: ${LinkButtonTextColor};
  }
  .settings-account__fullName {
    ${font({ size: '16px', weight: MediumFontWeight })}
  }
  .settings-account__email {
    ${font({ size: '16px', weight: RegularFontWeight })}
  }
  .profile-image g[stroke],
  .profile-image line[stroke],
  .profile-image polyline[stroke],
  .profile-image path[stroke],
  .profile-image rect[stroke],
  .profile-image use[stroke],
  .profile-image circle[stroke] {
    stroke: ${ChartLabelTextColor};
  }
  .settings-profile__change-photo-link {
    ${font({ size: '12px', weight: RegularFontWeight, color: ActiveLinkText })}
  }
  .form--default {
    padding: 0 16px;
  }
  .form--default .control-label {
    ${font({ size: '16px', weight: MediumFontWeight })}
  }
  .password-validation li.password-validation-indicator {
    ${font({ size: '14px', weight: RegularFontWeight, color: ContentText })}
  }
  .password-validation .password-content-text {
    ${font({ size: '16px', weight: MediumFontWeight, color: ContentText })}
  }
  .password-validation--inverse ul {
    margin: 0 0 24px 0;
    padding: 0 0 0 4px;
  }
  .password-validation--inverse li.password-validation-indicator {
    ${font({ size: '14px', weight: LightFontWeight })}
  }
  .password-validation--inverse .password-content-text {
    ${font({ size: '16px', weight: MediumFontWeight })}
  }
  .settings__text--headline {
    ${font({ size: '16px', weight: MediumFontWeight })}
    margin: 40px 0 20px 0;
  }
  .settings__text--default {
    ${font({ size: '14px', weight: RegularFontWeight })}
    margin: 20px 0 30px 0;
  }
  .settings__text--small {
    ${font({ size: '12px', weight: RegularFontWeight, color: PanelIconColor })}
  }
  .settings__tabs ul li a {
    ${font({
      size: '14px',
      weight: RegularFontWeight,
      color: CorvanaMediumText,
    })}
  }
  .settings__tabs ul li.active a {
    ${font({
      size: '14px',
      weight: MediumFontWeight,
      color: PanelIconActiveColor,
    })}
  }
  .settings__tabs ul li.active {
    border-bottom: 2px solid ${TabHighlight};
  }
  .header-navigation__title {
    ${font({ size: '20px', weight: MediumFontWeight, color: ContentText })}
    white-space: nowrap;
  }
  .mobile-menu-button:last-of-type {
    border-bottom: 1px solid transparent;
    color: ${ActiveLinkText};
  }
  .error.help-block {
    border-color: ${ErrorColor};
    ${font({ size: '12px', weight: RegularFontWeight, color: ErrorColor })}
  }
`;
