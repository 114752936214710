export default {
  Dataset: {
    SET_DATASETS: 'DATASETS',
    SET_DATASET_PAGE: 'SET_PAGE',
    SET_DATASET_ADDED: 'SET_DATASET_ADDED',
    SET_SHOW_PROPS_CHANGE_ERROR: 'SET_SHOW_PROPS_CHANGE_ERROR',
    GET_ASSOCIATED_CONTENT_FULFILLED: 'GET_ASSOCIATED_CONTENT_FULFILLED',
    EDIT_DATASET: 'EDIT_DATASET',
    UPDATE_DATASET: 'UPDATE_DATASET',
    IS_EDITING_DATASET_SETTINGS: 'IS_EDITING_DATASET_SETTINGS',
  },
  Login: {
    SHOW_LOGIN_DIALOG: 'SHOW_LOGIN_DIALOG',
    LOGIN: 'LOGIN',
    INVALID_CREATE_PASSWORD_ATTEMPT: 'INVALID_CREATE_PASSWORD_ATTEMPT',
    RESET_LOGIN_STATE: 'RESET_LOGIN_STATE',
    LOGOUT: 'LOGOUT',
  },
  Account: {
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    SET_CHANGING_PASSWORD: 'SET_CHANGING_PASSWORD',
    SET_PASSWORD_CHANGED: 'SET_PASSWORD_CHANGED',
    SET_PASSWORD_CHANGE_ERROR: 'SET_PASSWORD_CHANGE_ERROR',
    SET_CURRENT_USER_PREFERENCES: 'SET_CURRENT_USER_PREFERENCES',
    SHOULD_LOGOUT_OTHER_SESSIONS: 'SHOULD_LOGOUT_OTHER_SESSIONS',
  },
  Main: {
    SET_USER_APPEARANCE_OVERRIDE: 'SET_USER_APPEARANCE_OVERRIDE',
    SET_SYSTEM_DARK_MODE: 'SET_SYSTEM_DARK_MODE',
    SET_MOBILE_MAX_WIDTH: 'SET_MOBILE_MAX_WIDTH',
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    SHOULD_FORCE_MOBILE: 'SHOULD_FORCE_MOBILE',
    UPDATE_FROM_ROUTE: 'UPDATE_FROM_ROUTE',
    MENU_NAMES: {
      activityFeed: 'Activity',
      discover: 'Discoveries',
      datasets: 'Datasets',
    },
    SUB_MENU_NAMES: {
      activityFeed: new Map([
        ['feed', 'FEED'],
        // ["manageNotifications", "MANAGE NOTIFICATIONS"]
      ]),
      discover: new Map([
        ['browse', 'BROWSE'],
        ['open', 'OPEN'],
      ]),
      datasets: new Map(),
    },
    SET_ACTIVITY_FEED_STATUS: 'SET_ACTIVITY_FEED_STATUS',
    TOGGLE_ADVANCED: 'TOGGLE_ADVANCED',
    ACTIVE_TAB: 'ACTIVE_TAB',
    ACTIVE_SUBMENU_TAB: 'ACTIVE_SUBMENU_TAB',
    SHOW_GLASS_PANE: 'SHOW_GLASS_PANE',
    SET_CONTROL_DOWN: 'SET_CONTROL_DOWN',
    SET_META_DOWN: 'SET_META_DOWN',
    SHOW_ERROR_DETAIL: 'SHOW_ERROR_DETAIL',
    SHOW_APP_OUT_OF_DATE: 'SHOW_APP_OUT_OF_DATE',
    SHOW_RE_AUTHENTICATE: 'SHOW_RE_AUTHENTICATE',
    CLEAR_RE_AUTH: 'CLEAR_RE_AUTH',
    SET_IS_MOBILE: 'SET_IS_MOBILE',
    SET_URL_LOCATION: 'SET_URL_LOCATION',
    SHOW_TOAST: 'SHOW_TOAST',
    CLOSE_TOAST: 'CLOSE_TOAST',
    SET_AGGREGATE_INFO: 'SET_AGGREGATE_INFO ',
    SET_SORTING: 'SET_SORTING',
    SET_SEARCH: 'SET_SEARCH',
    SET_SEARCH_TAGS: 'SET_SEARCH_TAGS',
    SET_SEARCH_CHART_TYPES: 'SET_SEARCH_CHART_TYPES',
    SET_SEARCH_DATASETS: 'SET_SEARCH_DATASETS',
    SET_ACCEPT_COOKIES: 'SET_ACCEPT_COOKIES',
    SET_AUTH_STATE: 'SET_AUTH_STATE',
  },
  Activity: {
    GET_FEED_FULFILLED: 'GET_FEED_FULFILLED',
    GET_FEED: 'GET_FEED',
    POST_FEEDBACK_FULFILLED: 'POST_FEEDBACK_FULFILLED',
    POST_FEEDBACK_REJECTED: 'POST_FEEDBACK_REJECTED',
    SET_APPROVAL_STATUS: 'SET_APPROVAL_STATUS',
    SET_APPROVAL_PENDING_STATUS: 'SET_APPROVAL_PENDING_STATUS',
  },
  Discover: {
    SET_SHOW_ADD_MONITOR_DIALOG: 'SET_SHOW_ADD_MONITOR_DIALOG',
    SET_SHOW_ADD_COMP_MONITOR: 'SET_SHOW_ADD_COMP_MONITOR',
    MOBILE_INSIGHTS_CHECKED: 'MOBILE_INSIGHTS_CHECKED',
    SET_PANEL_DETAIL: 'SET_PANEL_DETAIL',
    POST_MONITORS_FULFILLED: 'POST_MONITORS_FULFILLED',
    CLOSE_OPEN_DISCOVERY: 'CLOSE_OPEN_DISCOVERY',
    CLOSE_OPEN_DELETED_DISCOVERY: 'CLOSE_OPEN_DELETED_DISCOVERY',
    SET_DISPLAY_DISCOVERY: 'SET_DISPLAY_DISCOVERY',
    SET_DISCOVERY_DIRTY: 'SET_DISCOVERY_DIRTY',
    CHANGE_DISCOVERY_NAME: 'CHANGE_DISCOVERY_NAME',
    SET_DISCOVERY_TAGS: 'SET_DISCOVERY_TAGS',
    POST_QUERY: 'POST_QUERY',
    POST_QUERY_FULFILLED: 'POST_QUERY_FULFILLED',
    CHANGE_RANGE_SELECTION: 'CHANGE_RANGE_SELECTION',
    CHANGE_THRESHOLD: 'CHANGE_THRESHOLD',
    SET_CONFIG_PANEL_DETAIL: 'SET_CONFIG_PANEL_DETAIL',
    NEW_VISUALIZATION: 'NEW_VISUALIZATION',
    ADD_FIELD_TO_VIZ: 'ADD_VIZ_FIELD',
    REMOVE_FIELD_FROM_VIZ: 'REMOVE_FIELD_FROM_VIZ',
    RENAME_FIELD_IN_SHELF: 'RENAME_FIELD_IN_SHELF',
    CHANGE_VIZ_TYPE: 'CHANGE_VIZ_TYPE',
    SET_SHOW_NEW_VIZ_DIALOG: 'SET_SHOW_NEW_VIZ_DIALOG',
    SET_SHOW_SAVE_DIALOG: 'SET_SHOW_SAVE_DIALOG',
    SET_SHOW_SAVE_AS_DIALOG: 'SET_SHOW_SAVE_AS_DIALOG',
    SET_SHOW_VERSION_DIALOG: 'SET_SHOW_VERSION_DIALOG',
    SET_SAVE_ACTIVE: 'SET_SAVE_ACTIVE',
    SET_SAVE_ERROR: 'SET_SAVE_ERROR',
    FOCUS_ANOMALY: 'FOCUS_ANOMALY',
    UPDATE_DISCOVERY_WITH_VIZ: 'UPDATE_DISCOVERY_WITH_VIZ',
    UPDATE_DISCOVERY_WITH_ERROR: 'UPDATE_DISCOVERY_WITH_ERROR',
    UPDATE_DISCOVERY_WITH_VERSION: 'UPDATE_DISCOVERY_WITH_VERSION',
    REORDER_VIZ_SHELF: 'REORDER_VIZ_SHELF',
    SORT_VIZ_DATA: 'SORT_VIZ_DATA',
    SET_VIZ_TRASH_DROP_TARGET: 'SET_VIZ_TRASH_DROP_TARGET',
    OPEN_DISCOVERY: 'OPEN_DISCOVERY',
    ADD_OPEN_DISCOVERY: 'ADD_OPEN_DISCOVERY',
    OPEN_PINNED_DISCOVERIES: 'OPEN_PINNED_DISCOVERIES',
    SET_PINNED_DISCOVERIES_LOADING: 'SET_PINNED_DISCOVERIES_LOADING',
    SET_OPEN_DISCOVERY_STATE: 'SET_OPEN_DISCOVERY_STATE',
    SET_VIZ_ID: 'SET_VIZ_ID',
    SET_VIZ_LEGEND_DATA: 'SET_VIZ_LEGEND_DATA',
    UPDATE_AGGREGATION: 'UPDATE_AGGREGATION',
    CHANGE_DISPLAY_RANGE: 'CHANGE_DISPLAY_RANGE',
    SET_SHOW_VIZ_FILTER_DIALOG: 'SET_SHOW_VIZ_FILTER_DIALOG',
    SET_VIZ_ACCESS: 'SET_VIZ_ACCESS',
    APPLY_VIZ_FIELD_FILTER: 'APPLY_VIZ_FIELD_FILTER',
    AUTO_HYDRATE_VIZ_FIELD_FILTER: 'AUTO_HYDRATE_VIZ_FIELD_FILTER',
    REMOVE_VIZ_FIELD_FILTER: 'REMOVE_VIZ_FIELD_FILTER',
    APPLY_VIZ_STATE: 'APPLY_VIZ_STATE',
    APPLY_MONITOR_EVENT_ID: 'APPLY_MONITOR_EVENT_ID',
    SET_ACTIVE_VIZ_FIELD_FILTER: 'SET_ACTIVE_VIZ_FIELD_FILTER',
    SET_SHOW_VIZ_CALC_DIALOG: 'SET_SHOW_VIZ_CALC_DIALOG',
    SAVE_CALC_FIELD: 'SAVE_CALC_FIELD',
    DELETE_CALC_FIELDS: 'DELETE_CALC_FIELDS',
    SET_TOOLTIP_DATA: 'SET_TOOLTIP_DATA',
    SET_SCROLL_PCT: 'SET_SCROLL_PCT',
    SET_HOVER_OVER_VIZ_DATA: 'SET_HOVER_OVER_VIZ_DATA',
    SET_FOCUSED_VIZ_DATA: 'SET_FOCUSED_VIZ_DATA',
    TOGGLE_FOCUSED_VIZ_DATA_POINTS: 'TOGGLE_FOCUSED_VIZ_DATA_POINTS',
    SET_FOCUSED_VIZ_DATA_POINTS: 'SET_FOCUSED_VIZ_DATA_POINTS',
    UPDATE_FIELD_IN_VIZ: 'UPDATE_FIELD_IN_VIZ',
    SHOW_TIME_HIERARCHY: 'SHOW_TIME_HIERARCHY',
    VIZ_QUERY: 'VIZ_QUERY',
    VIZ_QUERY_LOADING: 'VIZ_QUERY_LOADING',
    VIZ_QUERY_FINISHED: 'VIZ_QUERY_FINISHED',
    VIZ_QUERY_ERROR: 'VIZ_QUERY_ERROR',
    VIZ_QUERY_WARNING: 'VIZ_QUERY_WARNING',
    MOVE_FIELD_TO_ANOTHER_SHELF: 'MOVE_FIELD_TO_ANOTHER_SHELF',
    SHOW_CONFIRM_REMOVE_FIELD: 'SHOW_CONFIRM_REMOVE_FIELD',
    IGNORE_VIZ_QUERY_ERROR: 'IGNORE_VIZ_QUERY_ERROR',
    SHOW_CONFIRM_ADD_FIELD: 'SHOW_CONFIRM_ADD_FIELD',
    UPDATE_VIZ_NAME: 'UPDATE_VIZ_NAME',
    UPDATE_VIZ_SETTING: 'UPDATE_VIZ_SETTING',
    SET_LIVE_QUERY: 'SET_LIVE_QUERY',
    COPY_VIZ_STATE: 'COPY_VIZ_STATE',
    COPY_UNDO_STACK: 'COPY_UNDO_STACK',
    UPDATE_DATASET_FOR_DISCOVERY: 'UPDATE_DATASET_FOR_DISCOVERY',
    REMOVE_MISSING_FIELDS: 'REMOVE_MISSING_FIELDS',
    CLEAR_VIZ_SORTING: 'CLEAR_VIZ_SORTING',
    SET_PIVOT_SORTING: 'SET_PIVOT_SORTING',
    SET_SAVE_CHECKPOINT: 'SET_SAVE_CHECKPOINT',
    INSIGHTS_RETURNED: 'INSIGHTS_RETURNED',
    INSIGHT_RETURNED: 'INSIGHT_RETURNED',
    INSIGHTS_FINISHED: 'INSIGHTS_FINISHED',
    INSIGHT_ERROR: 'INSIGHT_ERROR',
    INSIGHTS_LOADING: 'INSIGHTS_LOADING',
    SET_SETTING_FOR_VIZ: 'SET_SETTING_FOR_VIZ',
    SET_SHOW_CUSTOM_FORMAT_OPTION: 'SET_SHOW_CUSTOM_FORMAT_OPTION',
    SET_FUNNEL_STAGE_VISIBILITY_OPTIONS: 'SET_FUNNEL_STAGE_VISIBILITY_OPTIONS',
    SET_VIZ_IS_EMPTY: 'SET_VIZ_IS_EMPTY',
    SET_REPORT_DETAIL_INFO: 'SET_REPORT_DETAIL_INFO',
    SET_NEW_VIZ: 'SET_NEW_VIZ',
    BEGIN_NEW_VIZ_OPENING: 'BEGIN_NEW_VIZ_OPENING',
    BEGIN_REPORT_DETAIL_UPDATE: 'BEGIN_REPORT_DETAIL_UPDATE',
    BULK_FILTER_UPDATE: 'BULK_FILTER_UPDATE',
    CLEAR_VIZ_QUERY: 'CLEAR_VIZ_QUERY',
    DESIGNATE_RUNTIME_FILTER: 'DESIGNATE_RUNTIME_FILTER',
    REMOVE_RUNTIME_FILTER: 'REMOVE_RUNTIME_FILTER',
  },
  Dashlet: {
    OPEN_DASHLET_VIZ: 'OPEN_DASHLET_VIZ',
    CLOSE_DASHLET_VIZ: 'CLOSE_DASHLET_VIZ',
    SET_AVAILABLE_DASHLET_DISCOVERIES: 'SET_AVAILABLE_DASHLET_DISCOVERIES',
    SAVE_DASHLET: 'SAVE_DASHLET',
    CLEAR_DASHLET_ERROR: 'CLEAR_DASHLET_ERROR',
    DASHLET_SAVE_COMPLETE: 'DASHLET_SAVE_COMPLETE',
    SET_MOCK_FINALIZE_FAIL: 'SET_MOCK_FINALIZE_FAIL',
  },
};
