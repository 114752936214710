import { useRelativeDateCondition } from '../../../../filter/relative-date-condition/relative-date-condition.hook';
import {
  useActiveVizFieldSelector,
  useDiscoveryIdSelector,
} from '../../../../../common/redux/selectors/viz-selector.hook';
import { createFilterForField } from '../../../../filter/Filter';
import { isEmpty } from 'lodash';
import { TimePeriods } from '../../../../filter/relative-date-condition';

export const useSlicerOptionDateRange = ({
  customRange,
  timeFrame,
  includeCurrentPartial,
  name,
}: {
  customRange: string;
  timeFrame: string;
  includeCurrentPartial: boolean;
  name: string;
}) => {
  const vizId = useDiscoveryIdSelector();
  const field = useActiveVizFieldSelector({
    discoveryId: vizId,
    name,
  });

  const cleanOperands = [
    isEmpty(customRange) ? '0' : customRange,
    isEmpty(timeFrame) ? TimePeriods.DAYS.key : timeFrame,
    `${includeCurrentPartial}`,
  ];

  const emptyFilter = createFilterForField(field);
  const operator = !includeCurrentPartial ? 'thisAndPast' : 'past';
  const filter = {
    ...emptyFilter,
    expression: {
      ...emptyFilter.expression,
      left: {
        ...emptyFilter.expression.left,
        operator,
        operands: cleanOperands,
      },
    },
  };

  const {
    dateTimeFormat,
    datasetId,
    useFiscalCalendar,
    relativeDatesState,
    onIncludeFractionalPeriod,
    onPeriodOperatorChange,
    onOffsetChange,
    onOperatorChange,
  } = useRelativeDateCondition({ vizId, filter, field }, true);

  return {
    dateTimeFormat,
    datasetId,
    useFiscalCalendar,
    relativeDatesState,
    onIncludeFractionalPeriod,
    onPeriodOperatorChange,
    onOffsetChange,
    onOperatorChange,
  };
};
