import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  AutoSizer,
  Column,
  Table,
  createTableMultiSort,
} from 'react-virtualized';
import clone from 'lodash/clone';
import * as components from '../../components/ui/table';
import styled from '@emotion/styled';
import { messages } from '../../i18n';
import { css } from '@emotion/react';

const HEADER_HEIGHT = 48;
const ROW_HEIGHT = 80;

const TableRoot = styled.div(
  ({ theme: { colors: { ContentBackground } = {} } = {} }) => css`
    background: ${ContentBackground};
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
  `,
);
const EmptyView = styled.div(
  ({
    headerHeight,
    rowHeight,
    theme: { colors: { ContentBackground, TableBorderColor } = {} } = {},
  }) => css`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 14px;
    background-color: ${ContentBackground};
    border-bottom: 1px solid ${TableBorderColor};
    ${`top: ${headerHeight}px;`}
    ${`height: ${rowHeight}px; line-height: ${rowHeight}px;`}
  `,
);

/*
  component:UITable
  Supports single and multi column sorting
   - Shift + click column header to append column to sort by list
   - Cntrl + click column to remove column from sort by list
*/

class component extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _getDatum(list, index) {
    return list[index];
  }

  sort({ sortBy, sortDirection }) {
    this.props.setSorting(sortBy, sortDirection);
  }

  render() {
    const {
      data,
      sortBy,
      sortDirection,
      headerHeight,
      rowHeight,
      disableSort,
      rootStyle,
      loading,
      ...passthrough
    } = this.props;
    const rowGetter = ({ index }) =>
      loading ? {} : this._getDatum(data, index);
    const sortState = createTableMultiSort(this.sort.bind(this), {
      defaultSortBy: clone(sortBy),
      defaultSortDirection: clone(sortDirection),
    });

    return (
      <TableRoot
        className='UITable'
        style={rootStyle}
        ref={node => {
          this.root = node;
        }}
      >
        <AutoSizer>
          {({ width, height }) => {
            return [
              <Table
                key='ui-table'
                width={width}
                height={height}
                headerHeight={headerHeight}
                rowGetter={rowGetter}
                rowCount={loading ? 9 : data.length}
                rowHeight={rowHeight}
                sort={!disableSort && sortState.sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                {...passthrough}
              >
                {this.props?.children({ Column, ...components })}
              </Table>,
              !loading && data.length === 0 && (
                <EmptyView headerHeight={headerHeight} rowHeight={rowHeight}>
                  {messages.table.noMatches}
                </EmptyView>
              ),
            ];
          }}
        </AutoSizer>
      </TableRoot>
    );
  }
}

component.propTypes = {
  data: PropTypes.array,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  disableSort: PropTypes.bool,
  search: PropTypes.string,
  searchOptions: PropTypes.object,
};

component.defaultProps = {
  data: [],
  headerHeight: HEADER_HEIGHT,
  rowHeight: ROW_HEIGHT,
  disableSort: false,
  search: '',
  searchOptions: {},
};

export default component;
