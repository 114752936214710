import StackBarSvgIcon from '../../../../images/sdd/viz/visualization-selection-stacked-bar.svg';
import ListSvgStackBar from '../../../../images/sdd/viz/stacked-bar.svg';
import BaseChartSpec, {
  ENABLE_REPORT_LINK,
  NON_NUMERIC_CALC,
  Validations,
  generateMeasuresFromMetrics,
} from '../../BaseChartSpec';
import { ShelfTypes } from '../../../discovery/interfaces';
import { LegendShapes } from '../legend-shape';
import { ChartSpecIds, Types } from '../../../common/Constants';
import _ from 'lodash';
import { getDateTypesFromShelfAsOrdinals } from '../ChartUtils';

class StackBarSpec extends BaseChartSpec {
  constructor() {
    super({
      id: 'stack_bar',
      name: 'stackBar.chartName',
      placeholderImage: '/assets/images/sdd/chart/canvas-icon-stacked-bar.svg',
      canScroll: false,
      icon: <StackBarSvgIcon />,
      listIcon: <ListSvgStackBar />,
      legendShape: LegendShapes.SQUARE,
      shelves: {
        [ChartSpecIds.STACK]: {
          id: ChartSpecIds.STACK,
          name: 'stackBar.stackShelf',
          accepts: NON_NUMERIC_CALC,
          aggregateMeasures: false,
          shelfType: ShelfTypes.SELECTION,
        },
        [ChartSpecIds.XAXIS]: {
          id: ChartSpecIds.XAXIS,
          name: 'stackBar.xAxisShelf',
          accepts: NON_NUMERIC_CALC,
          shelfType: ShelfTypes.SELECTION,
          groupNames: true,
        },
        [ChartSpecIds.VALUES]: {
          id: ChartSpecIds.VALUES,
          name: 'stackBar.valuesShelf',
          accepts: [Types.ANY],
          isRequired: true,
          shelfType: ShelfTypes.MEASURE,
          isColumn: true,
        },
        [ChartSpecIds.SLICER]: {
          id: ChartSpecIds.SLICER,
          name: 'chartSpecs.area.slicer',
          accepts: [Types.STRING, Types.TIMESTAMP],
          isRequired: false,
          shelfType: ShelfTypes.SLICER,
        },
      },
      supportsLegendSelection: true,
      customFormatToggles: [
        {
          name: 'asPercentage',
          displayLabel: 'stackBar.asPercentageToggle',
          type: 'property',
          isAvailable: _.stubTrue,
        },
        ENABLE_REPORT_LINK,
      ],
    });
    this.validationRules = [
      Validations.HasLayout,
      Validations.OneOf([this.shelves.VALUES]),
      Validations.TypeSafe,
    ];
  }
  mapShelvesToQueryVariables(viz) {
    if (!this.validate(viz)) {
      return {
        attributeNames: [],
        measures: [],
      };
    }
    // we want all fields in the STACK shelf plus any ordinalAttributes for those fields
    const stackAttributes = viz.layout.STACK.map(g => g.name);
    const ordinalAttributes = _.concat(viz.layout.STACK, viz.layout.XAXIS)
      .filter(g => !_.isEmpty(g.ordinalAttribute))
      .map(g => g.ordinalAttribute);
    const attributeNames = viz.layout.XAXIS.map(c => c.name)
      .concat(stackAttributes)
      .concat(ordinalAttributes);

    // Check to see if any are date_part calculations. If so add ordinal selections for these to facilitate sorting
    const ordinalTimes = getDateTypesFromShelfAsOrdinals(viz.layout.XAXIS);

    return {
      attributeNames: _.uniq(attributeNames.concat(ordinalTimes)),
      measures: generateMeasuresFromMetrics(viz),
    };
  }
}

export default StackBarSpec;
