import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useActiveVizFieldSelector } from '../../../../common/redux/selectors/viz-selector.hook';
import { Viz } from '../../../VizUtil';
import { VIZ_SELECTORS } from '../../../../common/redux/selectors/viz-selectors';
import { Types } from '../../../../common';

export const useTimestampSlicer = ({ vizId, name }) => {
  const field = useActiveVizFieldSelector({
    discoveryId: vizId,
    name,
  });

  const { viz } = useSelector(state => ({
    viz: (VIZ_SELECTORS.getActiveViz as any)(state, {
      discoveryId: vizId,
    } as any),
  }));

  const isTimestampSlicer = [Types.TIMESTAMP].includes(field?.attributeType);

  const isTimestampSlicerNotInSlicerSelection = _.isUndefined(
    Viz.getSlicerSelections(viz).find(
      slicerSelection => slicerSelection.name === name,
    ),
  );

  return {
    isTimestampSlicer,
    isTimestampSlicerNotInSlicerSelection,
  };
};
