import { Children, cloneElement } from 'react';
import { compose, withProps } from 'react-recompose';
import {
  baseProps,
  CommonComponentHoc,
} from '../../tenants/corvana/Components';
import {
  ActivityFeedItem,
  ActivityFeedItemBody,
  ActivityFeedItemHeader,
  ActivityWidget,
} from '../../tenants/corvana/activity';
import _ from 'lodash';

import TimeUtils from '../../common/TimeUtils';
import AreaLineChart from '../../discovery/charts/AreaLineChart';
import QueryResultUtils from '../../discovery/charts/QueryResultUtils';
import { ChartSpecs } from '../../discovery/ChartSpecs';
import * as shortId from 'shortid';
import { AutoSizer } from 'react-virtualized';
import {
  DATA_FORMATTER,
  NULL_DISPLAY,
  NULL_TOKEN,
} from '../../common/Constants';
import { graphql } from '@apollo/client/react/hoc';
import Dataset from '../../common/graphql/DatasetQueries';
import { connect } from 'react-redux';
import Discover from '../../common/redux/actions/DiscoverActions';
import {
  gatherMonitorSettings,
  getRelativeDateFilterForMonitorEvent,
  getDatasetRelatedInfo,
} from '../../monitors';
import { messages } from '../../i18n';
import { DiscoverThemeInjector } from '../../common/emotion';

function guardAgainstNull(key) {
  return _.includes([NULL_TOKEN, 'Null', '_NULL_'], key) ? NULL_DISPLAY : key;
}

const BaseComponent = ({
  feedItem,
  monitorEvent,
  referenceId,
  payload,
  dataset,
  last,
  visualizations,
  name,
  date,
  barData,
  viz,
  condensed,
  className,
  history,
  onHeightChanged,
  children,
  isMobile,
  thumbnail,
  dataFormatters,
  applyFilter,
  i18nPrefs = {},
}) => {
  // Enhanced Payload waiting for real replacement
  payload = {
    ...payload,
  };

  const {
    comparisonWindowUnit,
    comparisonWindow,
    measureName,
    labels: { cardName, targetName, targetReport },
  } = gatherMonitorSettings(monitorEvent);

  const { valueFormatter, inverted, customFormatter } = getDatasetRelatedInfo(
    dataset,
    measureName,
    dataFormatters,
  );

  const newData = payload.chartData.map(line => line.value);
  newData.unshift(['Axis', 'm0', 'm1', 'm2', 'm3']);
  payload.columnInfo = [
    { attributeName: 'Axis', columnType: 'ATTRIBUTE' },
    { attributeName: 'm0', columnType: 'MEASURE' },
    { attributeName: 'm1', columnType: 'MEASURE' },
    { attributeName: 'm2', columnType: 'MEASURE' },
    { attributeName: 'm3', columnType: 'MEASURE' },
  ];
  payload.chartData = newData;

  // Difference between actual and expected
  const pct = Math.abs(
    1 - Number.parseFloat(payload.actual / payload.expected),
  );
  const up = payload.actual > payload.expected;
  const currentVal = payload.chartData[payload.chartData.length - 1][1];
  const previousVal = payload.chartData[1][1];
  const periodIncrease = currentVal > previousVal;

  // Pct change from the beginning of the comparison window (now compared to last week, etc), rounded to 2 decimals
  const pctChangeInWindow =
    Math.round(((currentVal - previousVal) / previousVal) * 10000) / 10000;

  const pctChangeInWindowAbs = Math.abs(pctChangeInWindow); // needs formatter?

  const linkedContent = _.find(visualizations, { id: targetReport }) ?? null;
  function openConnectedContent() {
    history.push(`/event/${monitorEvent.id}`);
  }

  let segmentShort = '';
  if (payload.group) {
    let segmentParts = '';
    for (const [key, value] of Object.entries(payload.group)) {
      segmentParts += ` ${guardAgainstNull(key)} (${guardAgainstNull(value)}),`;
    }
    if (Object.entries(payload.group).length > 0) {
      segmentShort = messages.formatString(
        messages.anomalyActivity.segmentShort,
        segmentParts,
      );
    }
  }
  let cardTitle = cardName;
  if (!thumbnail && condensed) {
    cardTitle += messages.formatString(messages.anomalyActivity.cardTitle, {
      segmentShort,
      pct: DATA_FORMATTER.PERCENT.format(pct, i18nPrefs),
      higherOrLower: up ? messages.higher : messages.lower,
    });
  }

  let periodVal = '';
  switch (comparisonWindowUnit) {
    case 'DAY':
      periodVal = messages.days;
      break;
    case 'WEEK':
      periodVal = messages.weeks;
      break;
    case 'MONTH':
      periodVal = messages.months;
      break;
  }

  const windowLabel = messages.formatString(
    messages.anomalyActivity.windowLabel,
    {
      comparisonWindow,
      periodVal,
    },
  );

  let segment = '';

  if (payload.group) {
    let segmentParts = '';
    for (const [key, value] of Object.entries(payload.group)) {
      segmentParts += messages.formatString(
        messages.anomalyActivity.segmentPart,
        guardAgainstNull(key),
        guardAgainstNull(value),
      );
      segment = messages.formatString(
        messages.anomalyActivity.segment,
        segmentParts,
      );
    }
  }

  // Get the specific children that we know we need
  const kids = Children.toArray(children).reduce((acc, child) => {
    acc[child.type.role] = cloneElement(child, {
      onHeightChanged,
    });
    return acc;
  }, {});

  const onClick = () => {
    if (feedItem) {
      // Activity Feed view No action.
      return;
    }
    const filter = getRelativeDateFilterForMonitorEvent(monitorEvent, viz);
    applyFilter(filter, viz);
  };

  // Support overriding of the default header by tenanted modules
  const renderHeader = () => {
    if (!_.isNil(kids) && !_.isNil(kids.ActivityFeedItemHeader)) {
      return kids.ActivityFeedItemHeader;
    } else {
      return (
        <ActivityFeedItemHeader
          {...{ indicatorIcon: up ? 'up' : 'down', feedItem }}
        >
          {messages.formatString(messages.anomalyActivity.header, {
            name: linkedContent ? (
              <a onClick={() => openConnectedContent()}>{name}</a>
            ) : (
              name
            ),
            segment,
            pct: DATA_FORMATTER.PERCENT.format(pct, i18nPrefs),
            higherOrLower: up ? messages.higher : messages.lower,
          })}
        </ActivityFeedItemHeader>
      );
    }
  };

  // Support overriding of the default body by tenanted modules
  const renderBody = ({
    valueFormatter: bodyValueFormatter,
    customFormatter: bodyCustomFormatter,
  }) => {
    if (!_.isNil(kids) && !_.isNil(kids.ActivityFeedItemBody)) {
      return kids.ActivityFeedItemBody;
    } else {
      return (
        <ActivityFeedItemBody monitorEvent={monitorEvent} feedItem={feedItem}>
          {messages.formatString(messages.anomalyActivity.body, {
            name: <strong>{monitorEvent.monitor.name}</strong>,
            segment,
            pct: DATA_FORMATTER.PERCENT.format(pct, i18nPrefs),
            higherOrLower: up ? messages.higher : messages.lower,
            maxOrMin: up ? messages.lcMaximum : messages.lcMinimum,
            upOrDown: periodIncrease ? messages.up : messages.down,
            pctChangeInWindow: DATA_FORMATTER.PERCENT.format(
              pctChangeInWindowAbs,
              i18nPrefs,
            ),
            windowLabel,
          })}
          {!isMobile && (
            <div className='weighted-forecast-body-table'>
              <div className='labels'>
                <span>
                  {messages.formatString(
                    messages.anomalyActivity.currentLabel,
                    measureName,
                  )}
                </span>
                <span>
                  {messages.formatString(
                    messages.anomalyActivity.previousLabel,
                    windowLabel,
                  )}
                </span>
                <span>{messages.anomalyActivity.changeLabel}</span>
              </div>
              <div className='values'>
                <span>
                  {bodyValueFormatter.format(
                    currentVal,
                    i18nPrefs,
                    bodyCustomFormatter,
                  )}
                </span>
                <span>
                  {bodyValueFormatter.format(
                    previousVal,
                    i18nPrefs,
                    bodyCustomFormatter,
                  )}
                </span>
                <span className={periodIncrease ? 'pct-up' : 'pct-down'}>
                  {periodIncrease ? '+' : ''}
                  {DATA_FORMATTER.PERCENT.format(pctChangeInWindow, i18nPrefs)}
                </span>
              </div>
            </div>
          )}
        </ActivityFeedItemBody>
      );
    }
  };

  // Support overriding of the default widget by tenanted modules
  const renderWidget = () => {
    if (!_.isNil(kids) && !_.isNil(kids.ActivityWidget)) {
      return kids.ActivityWidget;
    } else {
      const percent = condensed || isMobile ? pctChangeInWindow : pct;
      return (
        <ActivityWidget>
          <GamPercentChangeCard
            {...{
              up: condensed || isMobile ? periodIncrease : up,
              comparisonWindowUnit,
              comparisonWindow,
              pct: percent,
              date,
              payload,
              barData,
              condensed,
              inverted,
              targetName,
              valueFormatter,
              isMobile,
              thumbnail,
              onClick,
              i18nPrefs,
              customFormatter,
            }}
            title={cardTitle}
          />
        </ActivityWidget>
      );
    }
  };

  return (
    <div className={'anomaly-activity'} key={monitorEvent.id}>
      <ActivityFeedItem
        {...{
          referenceId,
          monitorEvent,
          feedItem,
          onHeightChanged,
          last,
          condensed,
          className,
          inverted,
        }}
      >
        {renderHeader({ valueFormatter })}
        {renderBody({ valueFormatter, customFormatter })}
        {renderWidget({ valueFormatter })}
      </ActivityFeedItem>
    </div>
  );
};

const GamComponent = compose(
  connect(state => {
    const { i18nPrefs = {} } = state?.account?.currentUser;
    return {
      isMobile: state.main.isMobile,
      i18nPrefs,
    };
  }),
  withProps(props => {
    return {
      monitorEvent: props.feedItem.monitorEvent,
      referenceId: props.feedItem.referenceId,
    };
  }),
  graphql(Dataset.DatasetDetail, {
    options: props => ({
      variables: { id: props.monitorEvent.monitor.dataset.id },
    }),
    props: ({ data: { dataset } }) => {
      return { dataset };
    },
  }),
  CommonComponentHoc,
)(BaseComponent);

GamComponent.supports = feedItem => {
  return (
    feedItem.referenceType === 'MonitorEvent' &&
    ['anomalydetector'].includes(feedItem.monitorEvent.eventType)
  );
};

const GamInsightComponent = compose(
  connect(
    state => {
      const { i18nPrefs = {} } = state?.account?.currentUser;
      return {
        isMobile: state.main.isMobile,
        i18nPrefs,
      };
    },
    dispatch => ({
      applyFilter: (filter, viz) =>
        dispatch(Discover.applyFieldFilter(viz.id, Object.values(filter)[0])),
    }),
  ),
  withProps(() => {
    return { className: 'insight-item' };
  }),
  baseProps,
)(BaseComponent);
GamInsightComponent.supports = feedItem => {
  return (
    feedItem.referenceType === 'MonitorEvent' &&
    ['anomalydetector'].includes(feedItem.monitorEvent.eventType)
  );
};

const GamPercentChangeCard = ({
  up,
  pct,
  payload,
  condensed,
  title,
  inverted,
  date,
  valueFormatter,
  comparisonWindowUnit,
  comparisonWindow,
  isMobile,
  thumbnail,
  onClick,
  i18nPrefs = {},
  customFormatter,
}) => {
  let periodVal = '';
  switch (comparisonWindowUnit) {
    case 'DAY':
      periodVal = messages.days;
      break;
    case 'WEEK':
      periodVal = messages.weeks;
      break;
    case 'MONTH':
      periodVal = messages.months;
      break;
  }

  const periodShort = messages.formatString(
    messages.anomalyActivity.periodShort,
    {
      comparisonWindow,
      periodVal,
    },
  );

  //  Remove expected column
  payload.chartData = payload.chartData.map(row =>
    row.slice(0, 2).concat(row.slice(3)),
  );

  const data = QueryResultUtils.createResultsFromArray(
    payload.chartData,
    payload.columnInfo,
  );

  const viz = {
    id: shortId.generate(),
    layout: {
      LINES: [
        {
          name: 'M0',
          attributeType: 'NUMBER',
          defaultAggregation: 'Sum',
          hidden: false,
        },
      ],
      VALUES: [
        {
          name: 'M2',
          attributeType: 'NUMBER',
          defaultAggregation: 'Sum',
          hidden: false,
        },
        {
          name: 'M3',
          attributeType: 'NUMBER',
          defaultAggregation: 'Sum',
          hidden: false,
        },
      ],
      XAXIS: [
        {
          name: 'Axis',
          attributeType: 'NUMBER',
          defaultAggregation: 'Sum',
          hidden: false,
        },
      ],
    },
    options: {
      alignYAxesAtZero: 'true',
    },
  };
  const expectedLabel =
    payload.actual < payload.expected
      ? messages.anomalyActivity.predictedMin
      : messages.anomalyActivity.predictedMax;
  const windowLabel = messages.formatString(
    messages.anomalyActivity.windowLabel,
    {
      comparisonWindow,
      periodVal,
    },
  );
  const previousVal = payload.chartData[1][1];

  return (
    <div
      className={`percent-changed-card${inverted ? ' inverted' : ''}`}
      onClick={onClick}
    >
      {!isMobile && (
        <span className={'title'}>
          <span title={title}>{title}</span>
        </span>
      )}
      {!isMobile && !thumbnail && condensed && (
        <div className='card-date'>{TimeUtils.formatDate(date)}</div>
      )}
      <div className='card-body'>
        <div className='weighted-forecast-body-table'>
          <div className='labels'>
            <span>{messages.anomalyActivity.current}</span>
            <span>
              {!thumbnail && (condensed || isMobile)
                ? windowLabel
                : expectedLabel}
            </span>
            {!thumbnail && (condensed || isMobile) && (
              <span key={'change-key'}>
                {messages.anomalyActivity.changeLabel}
              </span>
            )}
          </div>
          <div className='values'>
            <span>
              {valueFormatter.format(
                payload.actual,
                i18nPrefs,
                customFormatter,
              )}
            </span>
            <span>
              {valueFormatter.format(
                condensed || isMobile ? previousVal : payload.expected,
                i18nPrefs,
                customFormatter,
              )}
            </span>
            {!thumbnail && (condensed || isMobile) && (
              <span className={up ? 'pct-up' : 'pct-down'}>
                {pct > 0 ? '+' : ''}
                {DATA_FORMATTER.PERCENT.format(pct, i18nPrefs)}
              </span>
            )}
          </div>
        </div>
        <div className='area-chart'>
          <div className='vbox flex-fill preserve-ordering'>
            <div className='flex-fill'>
              <AutoSizer>
                {({ width, height }) => {
                  return (
                    <DiscoverThemeInjector>
                      {({
                        colors: { AnomolyPrimaryPlotLine, ValAreaFill } = {},
                      } = {}) => (
                        <AreaLineChart
                          spec={ChartSpecs.area}
                          queryResults={data}
                          height={height}
                          width={width}
                          hideAxis
                          hideGrid
                          primaryPlotProps={{
                            hidePoints: true,
                            disableLineSmoothing: true,
                            className: 'mixed-area-line',
                            colorPalette: [AnomolyPrimaryPlotLine],
                            xAxisPadding: 0,
                            showDataLabels: false,
                            showLastPoint: true,
                          }}
                          secondaryPlotProps={{
                            className: 'mixed-area',
                            colorPalette: [ValAreaFill],
                            xAxisPadding: 0,
                            paletteOffset: 0,
                          }}
                          secondaryUsesPrimaryScale
                          collapsedAxisRange // don't start axis at zero
                          fitAllData // ensure chart shows all data
                          alignYAxesAtZero
                          shouldRotateAxisLabels={false}
                          viz={viz}
                          vizId={viz.id}
                          mainChartPadding={0}
                          xAxisPadding={0}
                          yAxisPadding={0}
                          disableScrolling
                          disableLegend
                          disableTooltips
                        />
                      )}
                    </DiscoverThemeInjector>
                  );
                }}
              </AutoSizer>
            </div>
            <div className='axis'>
              <span>
                {messages.formatString(
                  messages.anomalyActivity.lastPeriod,
                  periodShort,
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

GamPercentChangeCard.defaultProps = {
  chartHeight: 90,
  chartWidth: 104,
  chartPadding: 4,
  includeArrowInTitle: false,
  i18nPrefs: {},
};

export { GamComponent, GamInsightComponent };
