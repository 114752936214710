import { memo } from 'react';
import { colorsMatch } from '../common';
import { ColorBox } from '../common/color-picker-common.styles';
import { ColorRowContainer } from './color-row.styles';

export const ColorRow = memo<{
  colors: string[];
  selectedColor: string;
  setColor: (newColor: string) => void;
}>(({ colors, selectedColor, setColor }) => {
  return (
    <ColorRowContainer>
      {colors.map(color => (
        <ColorBox
          isSelected={colorsMatch(color, selectedColor)}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setColor(color);
          }}
          key={`colorbox-${color}`}
          color={color}
        />
      ))}
    </ColorRowContainer>
  );
});
