import _, { toNumber } from 'lodash';
import AreaChart from '../charts/AreaChart';
import AreaLineChart from '../charts/AreaLineChart';
import BarChart from '../charts/BarChart';
import BarLine from '../charts/ColumnLineCombo/BarLine';
import FunnelChart from '../charts/Funnel';
import LineChart from '../charts/Line/LineChart';
import { PivotTable } from '../charts/pivot';
import StackBarChart from '../charts/StackBar';
import StackChart from '../charts/StackChart';
import StackLineChart from '../charts/StackLineCombo';
import { PipelineChangesChart } from '../charts/pipeline-changes/pipeline-changes.component';
import BaseChartSpec from '../BaseChartSpec';
import { IViz } from '../interfaces';
import { IVizQueryExecuteQueryResults } from '../../common/graphql/viz-query.interfaces';
import { ChartSpecs } from '../ChartSpecs';
import { WaterfallChart } from '../charts/waterfall/waterfall.component';
import { ChartContext } from './chart.hook';

export interface IChartProps {
  key: string;
  spec: BaseChartSpec;
  viz: IViz;
  vizId: string;
  queryResults: string | any;
  secondaryQueryResults: IVizQueryExecuteQueryResults;
  height: string | number;
  width: string | number;
}

export const Chart = (props: IChartProps) => {
  const { width, height, spec, vizId } = props;
  if (!_.isNumber(height) || height <= 0 || !_.isNumber(width) || width <= 0) {
    console.warn(
      `Invalid dimensions for chart. height: ${height} width: ${width}`,
    );
    return null;
  }

  let chart;
  switch (spec.id) {
    case ChartSpecs.line.id: {
      chart = <LineChart vizId={vizId} {...props} />;
      break;
    }
    case ChartSpecs.area_line.id: {
      chart = (
        <AreaLineChart
          vizId={vizId}
          {...props}
          primaryPlotProps={{
            hidePoints: true,
            disableLineSmoothing: true,
            className: 'mixed-area-line',
            showLastPoint: false,
          }}
          secondaryPlotProps={{ className: 'mixed-area' }}
        />
      );
      break;
    }
    case ChartSpecs.area.id: {
      chart = (
        <AreaChart
          vizId={vizId}
          {...props}
          secondaryUsesPrimaryScale
          alignYAxesAtZero='true'
        />
      );
      break;
    }
    case ChartSpecs.column_line.id: {
      chart = <BarLine vizId={vizId} {...props} />;
      break;
    }
    case ChartSpecs.stack.id: {
      chart = <StackChart vizId={vizId} {...props} />;
      break;
    }
    case ChartSpecs.stack_bar.id: {
      chart = <StackBarChart vizId={vizId} {...props} />;
      break;
    }
    case ChartSpecs.pivot.id: {
      chart = <PivotTable vizId={vizId} {...props} />;
      break;
    }
    case ChartSpecs.funnel.id: {
      chart = <FunnelChart vizId={vizId} {...props} />;
      break;
    }
    case ChartSpecs.stack_line.id: {
      chart = <StackLineChart vizId={vizId} {...props} />;
      break;
    }
    case ChartSpecs.pipeline_changes.id: {
      chart = <PipelineChangesChart vizId={vizId} {...props} />;
      break;
    }
    case ChartSpecs.waterfall.id: {
      chart = <WaterfallChart vizId={vizId} {...props} />;
      break;
    }
    default:
      chart = <BarChart vizId={vizId} {...props} />;
  }
  return (
    <ChartContext.Provider
      value={{
        width: toNumber(props?.width ?? 0),
        height: toNumber(props?.height ?? 0),
        queryResults: props?.queryResults,
      }}
    >
      {chart}
    </ChartContext.Provider>
  );
};
