import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Util from '../../../common/Util';
import { DefaultFontName } from '../../../components/ui/fonts';
import { StyledLabel, AxisLabelText } from './base-cartesian-chart.styles';

export const AxesLabel = ({
  label = '',
  rotate = 0,
  x = 0,
  y = 0,
  onMouseEnter,
  onMouseLeave,
  isMobile,
  width,
}) => {
  const enterTimeoutRef = useRef(null);
  const leaveTimeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current);
    }

    enterTimeoutRef.current = setTimeout(() => {
      const labelSize = Util.calcTextWidth(
        label,
        `${isMobile ? '14px' : '18px'} ${DefaultFontName}`,
      );

      if (labelSize > width) {
        onMouseEnter();
      }
    }, 300);
  };

  const handleMouseLeave = () => {
    if (enterTimeoutRef.current) {
      clearTimeout(enterTimeoutRef.current);
    }

    leaveTimeoutRef.current = setTimeout(() => {
      onMouseLeave();
    }, 200);
  };

  useEffect(() => {
    return () => {
      if (enterTimeoutRef.current) {
        clearTimeout(enterTimeoutRef.current);
      }
      if (leaveTimeoutRef.current) {
        clearTimeout(leaveTimeoutRef.current);
      }
    };
  }, []);

  return (
    <g transform={`rotate(${rotate}) translate(${x}, ${y})`}>
      <foreignObject x='0' y='0' width={width} height='44'>
        <StyledLabel isMobile={isMobile}>
          <div
            style={{ display: 'inline-block', width: '100%' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <AxisLabelText isMobile={isMobile} className='axes-label-text'>
              {label}
            </AxisLabelText>
          </div>
        </StyledLabel>
      </foreignObject>
    </g>
  );
};

AxesLabel.propTypes = {
  label: PropTypes.string,
  rotate: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  isMobile: PropTypes.bool,
  width: PropTypes.number,
};
