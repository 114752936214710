import { IExpression, IExpressionSegment } from '../../datasets/interfaces';
import _ from 'lodash';
import { Expression, LogicalOperatorType } from './Filter';

// recursively decode IExpression tree
export const decodeExpressionTree = (
  treeNode: IExpression,
): IExpressionSegment[] => {
  let _conditions = [];

  if (_.has(treeNode, 'left') && !_.isNil(treeNode.left as IExpression)) {
    _conditions = decodeExpressionTree(treeNode.left as IExpression);

    if (_.has(treeNode, 'right') && !_.isNil(treeNode.right)) {
      // right is always a condition
      _conditions = _.concat(_conditions, [treeNode.right]);
    }
  } else if (_.has(treeNode, 'operands')) {
    // if leaf node
    _conditions = [treeNode];
  }

  return _conditions;
};

// builds a simple left-oriented tree from an array of conditions
export const insertIntoFilterExpression = (
  currentExpression: IExpression,
  condition: IExpressionSegment,
  logicalOperator: LogicalOperatorType,
): IExpression => {
  if (_.isNil(currentExpression)) {
    return new Expression(condition);
  } else if (
    !_.has(currentExpression, 'right') ||
    _.isNil(currentExpression.right)
  ) {
    const _expression = _.cloneDeep(currentExpression);
    _expression.right = condition;
    _expression.operator = logicalOperator;
    return _expression;
  } else {
    return new Expression(currentExpression, logicalOperator, condition);
  }
};
