import { useDiscoverTheme } from '../../emotion';
import ChartSkeletonDarkMode from '../../../../images/sdd/chart_skeleton_dark_mode.svg';
import ChartSkeletonWhiteMode from '../../../../images/sdd/chart_skeleton_white_mode.svg';
import { ChartSkeletonWrapper } from './skeleton-viz-loader.styles';

export const SkeletonVizLoader = () => {
  const { darkMode } = useDiscoverTheme();
  return (
    <ChartSkeletonWrapper>
      {darkMode ? (
        <div className={'viz-skeleton-dark'}>
          <ChartSkeletonDarkMode />
        </div>
      ) : (
        <div className={'viz-skeleton-light'}>
          <ChartSkeletonWhiteMode />
        </div>
      )}
    </ChartSkeletonWrapper>
  );
};
