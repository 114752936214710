import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ChartToolTip } from '../chart-tooltip';

export const StyledLabel = styled('div')(
  ({
    isMobile,
    theme: { colors: { TableHeaderTextColor } = {} as any } = {} as any,
  }: any) => css`
    font-weight: 500;
    font-style: inherit;
    font-size: ${isMobile ? '14px' : '18px'};
    color: inherit;
    fill: ${TableHeaderTextColor};
  `,
);

export const LabelToolTip = styled(ChartToolTip)(
  ({ theme: { colors: { ContentBackground } = {} as any } = {} }: any) => css`
    white-space: nowrap;
    color: ${ContentBackground};
    min-width: 100px;
    pointer-events: none;
  `,
);

export const AxisLabelText = styled.span(
  ({
    isMobile,
    theme: { colors: { ContentText, ContentBackground } = {} as any } = {},
  }: any) => css`
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    background-color: ${ContentBackground};
    font-size: ${isMobile ? '14px' : '18px'};
    color: ${ContentText};
  `,
);
