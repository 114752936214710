import _ from 'lodash';
import { IVizBase } from '../../discovery';

export const getLinkFromAttribute = attribute => {
  if (!attribute.annotations) {
    return null;
  }
  const urlAnnotation = attribute.annotations.find(a => a.key === 'LINK_URL');
  if (!urlAnnotation) {
    return null;
  }

  let link = null;
  try {
    link = JSON.parse(urlAnnotation.value);
    link.attribute = attribute;
  } catch (e) {
    console.error('Unable to parse link definition for Attribute', attribute);
  }
  return link;
};

export const getAllLinksInPlay = (
  viz: IVizBase,
  fieldsInPlay,
): { [key: string]: any } => {
  return fieldsInPlay.reduce((accum, attribute) => {
    const link = getLinkFromAttribute(attribute);
    if (link) {
      accum[attribute.name] = link;
    }
    return accum;
  }, {});
};

export const getAllLinksEnabledInReport = (viz: IVizBase, fieldsInPlay) => {
  const links = getAllLinksInPlay(viz, fieldsInPlay);
  const activeLinks = JSON.parse(_.get(viz, 'options.drillLinking', '[]'));

  return _.pickBy(links, link =>
    _.find(activeLinks, { attribute: link.attribute.name }),
  );
};
