import { useMemo, useCallback, Children, cloneElement } from 'react';
import shortid from 'shortid';
import Util from '../../common/Util';
import { compose, withProps } from 'react-recompose';
import moment from 'moment';
import { CommonComponentHoc } from './Components';
import Table from '../../common/widgets/Table';
import TableColumn from '../../common/widgets/TableColumn';
import {
  ActivityFeedItem,
  ActivityFeedItemHeader,
  ActivityWidget,
} from './activity';
import _ from 'lodash';
import { messages } from '../../i18n';
import { Viz } from '../../discovery/VizUtil';
import { useDatetimeFormatter } from '../../common/formatting/datetime/datetime.hook';

const UnconnectedIdleopsComponent = ({
  feedItem,
  monitorEvent,
  referenceId,
  payload,
  last,
  visualizations,
  name,
  condensed,
  className,
  history,
  onHeightChanged,
  children,
  i18nPrefs = {},
}) => {
  const { dateFormat } = useDatetimeFormatter();
  const linkedContent = visualizations
    ? visualizations.find(
        viz =>
          viz.name === name && viz.creator === 'discover-admin@sugarcrm.com',
      )
    : null;

  if (!_.isNil(linkedContent)) {
    Viz.configureCurrencySymbol(linkedContent);
  }

  const openConnectedContent = useCallback(
    opportunity => {
      if (!_.isNil(opportunity)) {
        // Pass single opportunity within aggregated idle ops to monitor event redirect
        const options = JSON.stringify({
          operand: opportunity,
        });
        history.push(`/event/${monitorEvent.id}/${Util.encode(options)}`);
      } else {
        history.push(`/event/${monitorEvent.id}`);
      }
    },
    [history, monitorEvent.id],
  );

  const formatters = useMemo(
    () => ({
      date: cell => {
        if (cell && cell > 0) {
          const m = moment(parseInt(cell)).utcOffset(0);
          return m.format(dateFormat);
        } else {
          return '---';
        }
      },
      link: function LinkCell(cell) {
        return (
          <a onClick={() => openConnectedContent(cell)} title={cell}>
            {cell}
          </a>
        );
      },
      currency: cell => Util.formatCurrency(cell, i18nPrefs),
    }),
    [dateFormat, i18nPrefs, openConnectedContent],
  );

  // Get the specific children that we know we need
  const kids = useMemo(
    () =>
      Children.toArray(children).reduce((acc, child) => {
        acc[child.type.role] = cloneElement(child, {
          onHeightChanged,
        });
        return acc;
      }, {}),
    [children, onHeightChanged],
  );

  // Support overriding of the default header by tenanted modules
  const header = useMemo(() => {
    if (!_.isNil(kids) && !_.isNil(kids.ActivityFeedItemHeader)) {
      return kids.ActivityFeedItemHeader;
    } else {
      return (
        <ActivityFeedItemHeader {...{ indicatorIcon: 'alert', feedItem }}>
          {payload.events.length === 1 && (
            <span>
              {messages.formatString(messages.idleOps.headerSingleIdleOp, {
                opportunity: linkedContent ? (
                  <a
                    onClick={() =>
                      openConnectedContent(payload.events[0].opportunity)
                    }
                  >
                    {payload.events[0].opportunity}
                  </a>
                ) : (
                  payload.events[0].opportunity
                ),
                stage: payload.events[0].stage,
                days: payload.events[0].daysInactive,
              })}
            </span>
          )}
          {payload.events.length > 1 && (
            <span>
              {messages.formatString(
                messages.idleOps.headerMultipleIdleOps,
                linkedContent ? (
                  <a onClick={() => openConnectedContent()}>
                    {messages.formatString(
                      messages.idleOps.numberOfOpportunities,
                      payload.events.length,
                    )}
                  </a>
                ) : (
                  payload.events.length
                ),
              )}
            </span>
          )}
        </ActivityFeedItemHeader>
      );
    }
  }, [feedItem, kids, linkedContent, openConnectedContent, payload.events]);

  // Support overriding of the default body by tenanted modules
  const body = useMemo(() => {
    if (!_.isNil(kids) && !_.isNil(kids.ActivityFeedItemBody)) {
      return kids.ActivityFeedItemBody;
    } else {
      return [];
    }
  }, [kids]);

  // Support overriding of the default widget by tenanted modules
  const widget = useMemo(() => {
    if (!_.isNil(kids) && !_.isNil(kids.ActivityWidget)) {
      return kids.ActivityWidget;
    } else {
      const monitorPayloads = [...payload.events].sort(
        (a, b) => a.daysInactive - b.daysInactive,
      );
      return (
        <ActivityWidget>
          <Table
            id={shortid.generate()}
            className='dataTable'
            keyField='opportunity'
            data={monitorPayloads}
            striped={false}
            hover
          >
            <TableColumn
              dataField='opportunity'
              width='180'
              dataFormat={formatters.link}
            >
              {messages.idleOps.opportunityName}
            </TableColumn>
            <TableColumn dataField='opportunityOwner' width='170'>
              {messages.idleOps.opportunityOwner}
            </TableColumn>
            <TableColumn
              dataField='amount'
              width='90'
              dataFormat={formatters.currency}
            >
              {messages.idleOps.amount}
            </TableColumn>
            <TableColumn
              dataField='closeDate'
              width='95'
              dataFormat={formatters.date}
            >
              {messages.idleOps.closeDate}
            </TableColumn>
            <TableColumn dataField='stage' width='120'>
              {messages.idleOps.stage}
            </TableColumn>
            <TableColumn dataField='daysInactive'>
              {messages.idleOps.daysInStage}
            </TableColumn>
          </Table>
        </ActivityWidget>
      );
    }
  }, [
    formatters.currency,
    formatters.date,
    formatters.link,
    kids,
    payload.events,
  ]);
  return (
    <div key={monitorEvent.id} className={'idleops'}>
      <ActivityFeedItem
        {...{
          referenceId,
          monitorEvent,
          feedItem,
          onHeightChanged,
          last,
          condensed,
          className,
        }}
        inverted
      >
        {header}
        {body}
        {widget}
      </ActivityFeedItem>
    </div>
  );
};

const IdleopsComponent = compose(
  withProps(props => {
    return {
      monitorEvent: props.feedItem.monitorEvent,
      referenceId: props.feedItem.referenceId,
    };
  }),
  CommonComponentHoc,
)(UnconnectedIdleopsComponent);

IdleopsComponent.supports = feedItem =>
  feedItem.referenceType === 'MonitorEvent' &&
  ['idleops'].includes(feedItem.monitorEvent.eventType);

export { IdleopsComponent, UnconnectedIdleopsComponent };
