import { forwardRef, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const SVGComponent = forwardRef<SVGSVGElement>(
  ({ children, ...props }: { children: ReactNode }, ref) => {
    return (
      <svg {...props} ref={ref}>
        {children}
      </svg>
    );
  },
);

export const PipelineChangesSvg = styled(SVGComponent)(
  ({
    theme: {
      colors: { Gray40, Gray70 },
    },
  }: any) => css`
    .gridlines line {
      stroke: ${Gray40};
    }
    line.connector {
      stroke: ${Gray70};
    }
  `,
);
