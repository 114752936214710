import Const from '../actions/ActionConst';
import { composeResetReducer } from 'redux-reset-store';

const initialState = {
  feed: [],
  approvedItems: [],
  approvalPendingStatus: false,
  discussionEvent: {},
};

export default composeResetReducer((state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Const.Activity.GET_FEED_FULFILLED:
      return { ...state, feed: action.payload.data.data };
    case Const.Activity.GET_FEED_REJECTED:
      return state;

    case Const.Activity.POST_FEEDBACK_FULFILLED: {
      const feed = newState.feed.map(f => {
        if (f.id === action.payload.data.itemId) {
          return { ...f, feedback: action.payload.data.feedback };
        }
        return f;
      });
      // Have to update feed to trigger render, React is not good with nested connected components
      newState.feed = feed;
      return newState;
    }
    case Const.Activity.SET_APPROVAL_STATUS: {
      const approved = [...state.approvedItems];
      if (action.payload.checked) {
        approved.push(action.payload.itemId);
      } else {
        // remove it from the approved list if it is in there
        const foundIdx = approved.findIndex(a => a === action.payload.itemId);
        if (foundIdx !== -1) {
          approved.splice(foundIdx, 1);
        }
      }
      return {
        ...state,
        approvedItems: approved,
      };
    }
    case Const.Activity.SET_APPROVAL_PENDING_STATUS: {
      return {
        ...state,
        approvalPendingStatus: action.payload.approvalPendingStatus,
      };
    }
    default:
      return state;
  }
}, initialState);
