import { useSlicerOptionDateRange } from './slicer-timerange-info.hook';
import { useEffect, memo, MouseEvent as ReactMouseEvent } from 'react';
import {
  StyledLabelGroup,
  StyledSlicerGroup,
  StyledSlicerGroupFlex,
} from './slicer-timerange-info.styles';
import Checkbox from '@mui/material/Checkbox';
import { kebabCase } from 'lodash';
import { messages } from '../../../../../i18n';
import { FormatDateRange } from '../../../../filter/relative-date-condition/format-date-range';

interface ITimeRangeInfoBoxProps {
  customRange: string;
  timeFrame: string;
  includeCurrentPartial: boolean;
  name: string;
  includeCurrentPartialClickHandler: (checked: boolean) => void;
}
export const SlicerTimeRangeInfo = memo<ITimeRangeInfoBoxProps>(
  ({
    customRange,
    timeFrame,
    includeCurrentPartial,
    name,
    includeCurrentPartialClickHandler,
  }) => {
    const {
      dateTimeFormat,
      datasetId,
      useFiscalCalendar,
      relativeDatesState,
      onIncludeFractionalPeriod,
      onPeriodOperatorChange,
      onOffsetChange,
      onOperatorChange,
    } = useSlicerOptionDateRange({
      customRange,
      timeFrame,
      includeCurrentPartial,
      name,
    });

    useEffect(() => {
      onIncludeFractionalPeriod(includeCurrentPartial);
      onPeriodOperatorChange(timeFrame);
      onOffsetChange(customRange);
      onOperatorChange(includeCurrentPartial ? 'past' : 'thisAndPast');
    }, [
      customRange,
      includeCurrentPartial,
      onIncludeFractionalPeriod,
      onOffsetChange,
      onOperatorChange,
      onPeriodOperatorChange,
      timeFrame,
    ]);

    return (
      <TimeRangeInfo
        name={name}
        onPartialPeriodClick={() =>
          includeCurrentPartialClickHandler(!includeCurrentPartial)
        }
        isPartialPeriodChecked={includeCurrentPartial}
        showPartialPeriod={relativeDatesState.displayFractionalPeriod}
      >
        <FormatDateRange
          useFiscalCalendar={useFiscalCalendar}
          datasetId={datasetId}
          relativeDatesState={relativeDatesState}
          dateTimeFormat={dateTimeFormat}
        />
      </TimeRangeInfo>
    );
  },
);

const TimeRangeInfo = ({
  children,
  name,
  showPartialPeriod,
  isPartialPeriodChecked,
  onPartialPeriodClick,
}: {
  children: JSX.Element | JSX.Element[];
  name: string;
  showPartialPeriod: any;
  isPartialPeriodChecked: boolean;
  onPartialPeriodClick: (event: ReactMouseEvent<Element, MouseEvent>) => void;
}) => {
  if (showPartialPeriod) {
    return (
      <StyledSlicerGroupFlex>
        <StyledSlicerGroup>
          <Checkbox
            id={`include-fractional-period-${kebabCase(name)}`}
            checked={isPartialPeriodChecked}
            sx={{ padding: 0 }}
            size={'small'}
            onClick={onPartialPeriodClick}
          />
          <StyledLabelGroup>
            <label
              className={'control-label'}
              htmlFor={`include-fractional-period-${kebabCase(name)}`}
            >
              {messages.filters.includeCurrentPartialPeriod}
            </label>
            {children}
          </StyledLabelGroup>
        </StyledSlicerGroup>
      </StyledSlicerGroupFlex>
    );
  }

  return <StyledSlicerGroupFlex>{children}</StyledSlicerGroupFlex>;
};
