import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useResettableState } from '../../../../common';

export const useSlicerError = ({
  loading,
  isPaged,
  isOpen,
  isTimestampSlicer,
}) => {
  const showAgainRef = useRef(true);
  const [show, setShow] = useResettableState(isPaged);
  useEffect(() => {
    return () => {
      setShow(true);
    };
  }, [setShow]);
  const shouldShowSlicerError =
    show && isOpen && !loading && !isTimestampSlicer;
  const onHideSlicerError = useCallback(() => {
    showAgainRef.current = false;
    window.requestAnimationFrame(() => setShow(false));
  }, [setShow]);
  const result = useMemo(
    () => ({
      shouldShowSlicerError,
      onHideSlicerError,
    }),
    [onHideSlicerError, shouldShowSlicerError],
  );
  return result;
};
