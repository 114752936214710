import { useContext, useEffect, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { use18nFormatter } from '../../../../common/formatting';
import { InfoPanel } from '../../../../components/ui';
import { messages } from '../../../../i18n';
import { MAX_SLICER_OPTIONS } from '../../graphql/slicer-options.gql';
import {
  MobileErrorWrapper,
  SlicerErrorDialogTitle,
} from './slicer-error-dialog.styles';
import { ToastContext } from '../../../../components/toast/toast-launcher';

export const SlicerTooManyItemsDialog = memo<{
  name: string;
  modalId: string;
  shouldShow: boolean;
  onHide: () => void;
}>(({ name, onHide, shouldShow }) => {
  const isMobile = useSelector((state: any) => state.main.isMobile);
  const { formatI18n } = use18nFormatter();

  const { showToast } = useContext(ToastContext);

  useEffect(() => {
    if (shouldShow) {
      showToast({
        label: name,
        onClose: onHide,
        children: (
          <span>
            {formatI18n(messages.slicer.tooManyRowsError, MAX_SLICER_OPTIONS)}
          </span>
        ),
      });
    }
  }, [formatI18n, name, onHide, shouldShow, showToast]);

  const infoPanel = useMemo(
    () => (
      <InfoPanel onClickClear={onHide}>
        <SlicerErrorDialogTitle>{`${name} `}</SlicerErrorDialogTitle>
        <span>
          {formatI18n(messages.slicer.tooManyRowsError, MAX_SLICER_OPTIONS)}
        </span>
      </InfoPanel>
    ),
    [formatI18n, name, onHide],
  );
  return isMobile && shouldShow ? (
    <MobileErrorWrapper>{infoPanel}</MobileErrorWrapper>
  ) : null;
});
