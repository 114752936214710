import { branch, compose, renderComponent } from 'react-recompose';
import Activity from '../common/graphql/Activity';
import { graphql } from '@apollo/client/react/hoc';
import { CorbotoLoading } from '../common/loaders';
import ServiceLocator from '../common/ServiceLocator';
import { ComponentTypes, DATA_FORMATTER } from '../common/Constants';
import _ from 'lodash';
import NoThumbnailAvailable from './NoThumbnailAvailable';

const ActivityThumbnail = props => {
  if (!props.data || _.isEmpty(props.data.activity)) {
    console.log(`Can't find activity with id ${props.match.params.activityId}`);
    return <NoThumbnailAvailable />;
  }
  const feedItem = props.data.activity[0];
  const parsedPayload = JSON.parse(
    _.get(feedItem, 'monitorEvent.payload', '{}'),
  );
  const measureName = _.get(parsedPayload, 'measureName', null);
  const dataForamtters = {};
  const { dataset } = feedItem.monitorEvent.monitor;

  if (!_.isNil(measureName)) {
    const measureAttribute = dataset?.attributes.find(
      a => a.name === measureName,
    );

    dataForamtters[measureName] =
      measureAttribute && !_.isEmpty(measureAttribute.formatType)
        ? DATA_FORMATTER[measureAttribute.formatType.toUpperCase()]
        : DATA_FORMATTER.NUMBER;
  }

  const insightComponents = ServiceLocator.getAll('COMPONENT', {
    type: ComponentTypes.INSIGHTS,
  }).filter(c => _.isFunction(c.supports));
  const InsightComponent = insightComponents.find(comp =>
    comp.supports(feedItem),
  );
  if (_.isNil(InsightComponent)) {
    console.log(
      'Unsupported activity component for thumbnail generation',
      feedItem,
    );
    return <NoThumbnailAvailable />;
  } else {
    const event = feedItem.monitorEvent;
    return (
      <div className='thumbnail-content'>
        <InsightComponent
          monitorEvent={event}
          dataFormatters={dataForamtters}
          dataset={dataset}
          referenceId={event.id}
          condensed
          thumbnail
        />
      </div>
    );
  }
};

const GetActivityQuery = graphql(Activity.GetActivityQuery, {
  options: ownProps => ({
    variables: {
      activityId: ownProps.match.params.activityId,
    },
  }),
  props: ({ data }) => {
    if (data?.error) {
      console.log(data.error);
      return { error: data.error, loading: false };
    } else if (data.loading) {
      return { loading: true };
    } else if (data && !data.loading) {
      return { loading: false, data: { ...data } };
    }
  },
});

export default compose(
  GetActivityQuery,
  branch(
    ({ loading }) => loading,
    renderComponent(() => <CorbotoLoading />),
  ),
)(ActivityThumbnail);
