import { SugarIcon } from '../../sugar-icon';
import {
  ISugarIconProps,
  SugarIconColorType,
  SugarIconType,
} from '../../common';

export type IndicatorIconProps = Omit<ISugarIconProps, 'icon'> & {
  indicator: 'valid' | 'invalid' | 'incomplete';
};

export const IndicatorIcon = ({ indicator, ...props }: IndicatorIconProps) => {
  const icon: SugarIconType =
    indicator === 'invalid' ? 'invalid-circle-lg' : 'check-circle-lg';
  let colorType: SugarIconColorType = 'medium';
  if (indicator === 'valid') {
    colorType = 'success';
  } else if (indicator === 'invalid') {
    colorType = 'error';
  }
  return <SugarIcon icon={icon} iconType={colorType} {...props} />;
};

export const SuccessIndicator = (
  props: Omit<IndicatorIconProps, 'indicator'>,
) => <IndicatorIcon indicator='valid' {...props} />;

export const ErrorIndicator = (
  props: Omit<IndicatorIconProps, 'indicator'>,
) => <IndicatorIcon indicator='invalid' {...props} />;

export const IncompleteIndicator = (
  props: Omit<IndicatorIconProps, 'indicator'>,
) => <IndicatorIcon indicator='incomplete' {...props} />;
