import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { VIZ_SELECTORS } from '../../../common/redux/selectors/viz-selectors';
import { Viz } from '../../VizUtil';
import _ from 'lodash';
import { VizQueries } from '../../../common/graphql';
import { IQueryVariables } from '../../interfaces';

import { useMemo } from 'react';
import { NULL_TOKEN, NULL_DISPLAY } from '../../../common';
import { useDiscoverOptionSelector } from '../../discovery-context/discovery.context';
import { defaultTimestampSlicerOptions } from '../util';
import { useTimestampSlicer } from '../slicer-widget/common/timestamp-slicer.hook';

export const MAX_SLICER_OPTIONS = 10000;
interface ISlicerOptionsQueryParams {
  name: string;
  vizId: string;
}

export interface ISlicerOptionsQueryResult {
  loading: boolean;
  options: { option: string; isSelected: boolean }[];
  isPaged?: boolean;
  variables?: IQueryVariables;
}

export const useSlicerOptionsQuery: ({
  name,
  vizId,
}: ISlicerOptionsQueryParams) => ISlicerOptionsQueryResult = ({
  name,
  vizId,
}: ISlicerOptionsQueryParams) => {
  const { value: slicerSelections } = useDiscoverOptionSelector({
    vizId,
    option: 'slicerSelections',
  });
  const { datasetId, viz, useFiscalCalendar } = useSelector(state => {
    return {
      datasetId: (VIZ_SELECTORS.getActiveDataset as any)(state, {} as any).id,
      viz: (VIZ_SELECTORS.getActiveViz as any)(state, {
        discoveryId: vizId,
      } as any),
      useFiscalCalendar:
        VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting(state, {}) &&
        VIZ_SELECTORS.getActiveVizFiscalSetting(state, {}) === 'true',
    };
  });
  const variables = useMemo(() => {
    const filters = Viz.mapFiltersToQuery(viz, name)?.filters ?? [];

    const calcs = _.get(Viz.mapCalcsToQuery(viz), 'calcs', []);
    return {
      useFiscalCalendar,
      id: datasetId,
      attributeNames: [name],
      measures: [],
      filters,
      calcs,
      // Fetch one more than the max we will return, so we can tell if this would
      // be paged (catch the unlikely case where there are EXACTLY the max number
      // of items available).
      limit: MAX_SLICER_OPTIONS + 1,
      offset: 0,
      calcDynamicValues: [],
      bypassCache: false,
    };
  }, [viz, name, useFiscalCalendar, datasetId]);

  const {
    loading,
    data: { executeQuery: { results = [] } = {} } = {},
  } = useQuery(VizQueries.VizQuery, { variables });
  const stringSlicerOptions = useMemo(
    () =>
      _(results)
        .flatten()
        .union(
          _(slicerSelections)
            .filter({ name })
            .map('option')
            .value(),
        )
        .uniq()
        .map(option => (option === NULL_TOKEN ? NULL_DISPLAY : option))
        .map(option => ({
          option,
          isSelected: _.some(slicerSelections, {
            name,
            option: option === NULL_DISPLAY ? NULL_TOKEN : option,
          }),
        }))
        .take(MAX_SLICER_OPTIONS)
        .value(),
    [results, slicerSelections, name],
  );

  const {
    isTimestampSlicer,
    isTimestampSlicerNotInSlicerSelection,
  } = useTimestampSlicer({
    name,
    vizId,
  });

  const timestampSlicerOptions = useMemo(() => {
    if (isTimestampSlicerNotInSlicerSelection) {
      return [];
    }

    return Viz.getSlicerSelections(viz)
      .filter(e => e.name === name)
      .map(({ option }) => ({
        option,
        isSelected: true,
      }));
  }, [isTimestampSlicerNotInSlicerSelection, name, viz]);

  const options = isTimestampSlicer
    ? isTimestampSlicerNotInSlicerSelection
      ? defaultTimestampSlicerOptions
      : timestampSlicerOptions
    : stringSlicerOptions;

  return {
    variables,
    isPaged: results.length > MAX_SLICER_OPTIONS,
    loading,
    options,
    isTimestampSlicerNotInSlicerSelection,
  };
};
