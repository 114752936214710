import { flow, toLower, replace } from 'lodash/fp';
export const validateColor = color => {
  return /^[\dA-Fa-f]{6}$/.test(color);
};

export const colorsMatch = (color1, color2) => {
  const cleanForCompare = flow(replace(/[^\dA-Fa-f]/g, ''), toLower);
  const [clean1, clean2] = [color1, color2].map(cleanForCompare);
  return clean1 === clean2;
};
