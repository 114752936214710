import { css } from '@emotion/react';
import { font } from '../mixins';

export const loginMobileStyles = ({
  theme: {
    colors: {
      LightFontWeight,
      RegularFontWeight,
      ContentText,
      ContentBackground,
      PanelBackground,
      ErrorColor,
      LoginButtonColor,
      LoginDisabledColor,
    },
  },
}) => css`
  .dashlet.LoginDialog.login-dialog {
    background: ${ContentBackground};
  }
  .dashlet.LoginDialog.login-dialog .modal-content .login-header {
    text-align: center;
  }
  .dashlet.LoginDialog.login-dialog
    .modal-content
    .LoginDialog__body.modal-body {
    padding: 60px 40px 24px;
  }
  .dashlet.LoginDialog.login-dialog
    .modal-content
    .form-group
    input.form-control {
    border-radius: 5px;
  }
  .dashlet.LoginDialog.login-dialog .modal-content .LoginDialog__helperActions {
    visibility: hidden;
  }
  .modal-content {
    position: relative;
    background-color: transparent;
    box-shadow: transparent;
  }
  .modal-body {
    padding: 24px;
  }
  .modal-body .notice-container {
    width: 650px;
    max-width: 90vw;
    margin: auto auto 45px;
  }
  .modal-body .notice-container + .login-body {
    margin-top: 0;
  }
  .LoginDialog {
    height: 100%;
  }
  .LoginDialog__body {
    color: ${ContentText};
    font-style: normal;
    font-size: 14px;
    height: 100%;
    overflow: scroll;
  }
  .login-info {
    ${font({ size: '14px', weight: LightFontWeight })}
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .LoginDialog.modal-dialog {
    background: ${PanelBackground};
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .LoginDialog.modal-content {
    border: none;
    border-radius: 0;
    height: 100%;
    overflow: hidden;
  }
  .LoginDialog .loading-button.is-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .LoginDialog .loading-button.is-loading div {
    margin: 0 10px 0 0;
  }
  .login-logo {
    height: 40px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    text-align: center;
  }
  .login-logo img {
    max-width: 15em;
  }
  .login-header {
    color: ${ContentText};
    margin: 40px 0 32px 0;
    ${font({ size: '24px', weight: RegularFontWeight })}
    display: block;
  }
  .form-group-email .input-group,
  .form-group-password .input-group {
    width: 100%;
  }
  .form-group-email.has-error input[placeholder],
  .form-group-password.has-error input[placeholder] {
    border-color: ${ErrorColor} !important;
  }
  .form-group-email .help-block,
  .form-group-password .help-block {
    text-align: left;
    color: ${ErrorColor};
    margin: 8px 0 0 0;
    display: inline-block;
  }
  .form-group-email {
    margin-bottom: 24px;
  }
  .form-group-password .input-group-addon {
    padding: 5px 0px;
  }
  .form-group-password .input-group-addon input[type='checkbox'] {
    display: none;
  }
  .form-group-password .input-group-addon .loginCheckbox {
    margin: 0;
  }
  .form-group-password .input-group-addon .loginCheckbox label {
    padding: 0px 10px;
  }
  .LoginDialog__helperActions {
    font-weight: lighter;
    margin-bottom: 24px;
  }
  .LoginDialog__helperActions a,
  .LoginDialog__helperActions a:link,
  .LoginDialog__helperActions a:hover,
  .LoginDialog__helperActions a:visited,
  .LoginDialog__helperActions a:active,
  .LoginDialog__helperActions span {
    color: ${ContentText};
    font-weight: lighter;
    cursor: pointer;
  }
  .LoginDialog__helperActions .checkbox {
    margin: 0;
  }
  .LoginDialog__helperActions input[type='checkbox'] {
    bottom: 4px;
  }
  .form-group-flex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .returnLink {
    text-align: center;
    margin-top: 40px;
    cursor: pointer;
  }
  .returnLink a,
  .returnLink a:link,
  .returnLink a:visited,
  .returnLink a:hover,
  .returnLink a:active {
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
  }
  /* Initial Login Dialog specific stuff*/
  .initial-login .help-block {
    ${font({ size: '20px' })}
    margin: 40px 0px;
  }
  .initial-login .button-group {
    display: flex;
    justify-content: flex-start;
  }
  .initial-login .button-group button {
    margin-left: 16px;
  }
  .initial-login .button-group button:first-of-type {
    margin-left: 0px;
  }
  .initial-login button.btn.btn-primary {
    ${font({ size: '16px' })}
    background-color: ${LoginButtonColor};
    color: ${ContentBackground};
    border: 1px solid ${LoginButtonColor};
  }
  .initial-login button.btn.btn-primary:disabled {
    background-color: ${LoginDisabledColor};
    border-color: ${LoginDisabledColor};
    opacity: unset;
  }
  .initial-login .terms {
    ${font({ size: '12px' })}
    margin: 24px 0px 0px 0px;
  }
  .initial-login .terms a {
    text-decoration: underline;
  }
  .initial-login .agree-to-terms {
    ${font({ size: '16px', weight: LightFontWeight, color: ContentText })}
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .initial-login .agree-to-terms .tos_checkbox {
    margin-right: 8px;
    position: relative;
    top: -2px;
  }
  /* Terms of service*/
  .tosText {
    text-align: center;
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
    margin-top: 40px;
  }
  .tosText a {
    text-align: center;
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
    text-decoration: underline;
  }
  .tosText a:hover {
    text-decoration: underline;
  }
  .tosText .dot:before {
    content: '\B7';
    margin: 0 5px;
  }
  .tosText .vertical-bar:before {
    content: '|';
    margin: 0 12px;
  }
  .copyright {
    color: ${ContentText};
    margin-top: 16px;
    display: inline-block;
  }
`;
