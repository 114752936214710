import { createContext } from 'react';
import { useQuery } from '@apollo/client';
import { DiscoverQueries } from '../../common/graphql';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getChartSpecs } from '../VizUtil.common';
import { messages } from '../../i18n';
import { VIZ_SELECTORS } from '../../common/redux/selectors/viz-selectors';

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

interface ISearchOptions {
  visualizations: {
    tags: string[];
    chartType: string;
    datasetId: string;
  }[];
  datasets: {
    id: string;
    name: string;
  }[];
}

export const FilterWidgetContext = createContext<{
  loading: boolean;
  data: {
    visualizations: {
      tags: string[];
      chartType?: string;
      datasets?: string[];
    }[];
  };
  selectedTypes: {
    tags?: string[];
    chartTypes?: string[];
    datasets?: string[];
  };
  labelDisplayMap: { [key: string]: { [key: string]: string } };
}>({
  loading: false,
  data: {
    visualizations: [],
  },
  selectedTypes: {
    tags: [],
    chartTypes: [],
    datasets: [],
  },
  labelDisplayMap: {},
});

export const FilterWidgetContextProvider = ({ children }: Props) => {
  const labelDisplayMap = {
    chartTypes: _.mapValues(getChartSpecs(), val =>
      _.get(messages, val.name, val.name),
    ),
  };
  const { loading, data } = useQuery<ISearchOptions>(
    DiscoverQueries.SearchOptions,
  );
  const chartTypes = useSelector(({ tables }: any) => {
    return tables.library.chartTypes || [];
  });
  const datasets = useSelector(
    ({ tables }: any) => tables.library.searchDatasets || [],
  );
  const availableTags = useSelector(
    ({ tables }: any) => tables.library.tags || [],
  );
  const discoveryId = useSelector(state =>
    VIZ_SELECTORS.getDiscoveryId(state, {}),
  );
  const openDiscovery = useSelector(state =>
    VIZ_SELECTORS.getActive(state, { discoveryId }),
  );

  const isOnReportPage = (): boolean =>
    typeof window !== 'undefined' &&
    window.location.pathname.includes('/open/');
  const tagsOnReport = (isOnReportPage() && openDiscovery?.tags) || [];
  const tags = _.isEmpty(tagsOnReport) ? availableTags : tagsOnReport;
  const modifiedData = {
    visualizations: data?.visualizations?.map(el => ({
      ...el,
      chartTypes: [el.chartType],
      datasets: data?.datasets?.reduce((acc, { name, id }) => {
        if (id === el.datasetId) {
          return [...acc, name];
        }
        return acc;
      }, []),
    })),
  };

  return (
    <FilterWidgetContext.Provider
      value={{
        loading,
        data: modifiedData,
        selectedTypes: {
          tags,
          chartTypes,
          datasets,
        },
        labelDisplayMap,
      }}
    >
      {children}
    </FilterWidgetContext.Provider>
  );
};
