import React, { useCallback } from 'react';
import { createContext } from 'react';
import { noop } from 'lodash';
import { useDatasetSettingsContext } from '../../edit-dataset-settings.context';
import {
  IDatasetJson,
  IDatasetSettings,
} from '../../edit-dataset-settings.interfaces';

export const DatasetIngestionContext = createContext<{
  doChange: (_datasetJson: IDatasetJson) => void;
}>({
  doChange: noop,
});

export const DatasetIngestionContextProvider = ({
  children,
  onChange,
}: {
  children: any;
  onChange(saveObj: IDatasetSettings);
}) => {
  const {
    dataset: { id: datasetId } = {},
    datasetSettings: { datasetAnnotations } = {},
  } = useDatasetSettingsContext();

  const doChange = useCallback(
    _datasetJson => {
      onChange({
        datasetId,
        datasetAnnotations: {
          ...datasetAnnotations,
          datasetJson: _datasetJson,
        },
      });
    },
    [datasetAnnotations, datasetId, onChange],
  );

  return (
    <DatasetIngestionContext.Provider
      value={{
        doChange,
      }}
    >
      {children}
    </DatasetIngestionContext.Provider>
  );
};
