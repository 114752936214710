import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import InListFilter from '../discovery/filter/exports/InListFilter';
import { VizRedirect } from '../discovery/viz-redirect';
import { Viz as VizUtils } from '../discovery/VizUtil';

const DaysInStageRedirect = ({ stageAttrName, adminName }) => {
  const func = ({ monitorEvent, visualizations, openVizualization }) => {
    let payload;
    try {
      payload = JSON.parse(monitorEvent.payload);
    } catch (e) {
      return <Redirect to='/' />;
    }
    const linkedContent = visualizations
      ? visualizations.find(
          viz =>
            viz.name === monitorEvent.monitor.name && viz.creator === adminName,
        )
      : null;
    const segmentField = linkedContent
      ? _.find(
          linkedContent.dataset.attributes,
          a =>
            _.lowerCase(a.name) === _.lowerCase(payload.attributeName) ||
            _.lowerCase(a.name) === _.lowerCase(payload.attribute),
        )
      : null;
    const stageField = linkedContent
      ? _.find(
          linkedContent.dataset.attributes,
          a => _.lowerCase(a.name) === _.lowerCase(stageAttrName),
        )
      : null;
    if (linkedContent) {
      const filters = {};
      if (!_.isNil(segmentField)) {
        const segmentFilter = InListFilter(
          segmentField,
          payload.attributeValue,
        );
        filters[segmentField.name] = segmentFilter;
      }
      if (!_.isNil(stageField)) {
        const stageFilter = InListFilter(stageField, payload.stage);
        filters[stageField.name] = stageFilter;
      }
      openVizualization(linkedContent);
      return (
        <VizRedirect
          vizId={linkedContent.id}
          state={{
            // add slicers, slicerSelections here
            filters,
            monitorEventId: monitorEvent.id,

            slicerSelections: VizUtils.getSlicerSelections(linkedContent),
            slicers: VizUtils.getSlicers(linkedContent),
          }}
        />
      );
    }
    return <Redirect to='/' />;
  };

  func.supports = monitorEvent =>
    ['daysinstage'].includes(monitorEvent.eventType);
  return func;
};

export default DaysInStageRedirect;
