import { branch, renderComponent, compose, lifecycle } from 'react-recompose';
import { connect } from 'react-redux';
import Actions from '../../common/redux/actions/DiscoverActions';
import { messages } from '../../i18n';

export default condition => {
  return branch(
    condition,
    compose(
      connect(
        (state, props) => props,
        dispatch => {
          return {
            setVizIsEmpty: visIsEmpty => {
              dispatch(Actions.setVizIsEmpty(visIsEmpty));
            },
          };
        },
      ),
      lifecycle({
        componentDidMount() {
          this.props.setVizIsEmpty(true);
        },
        componentWillUnmount() {
          this.props.setVizIsEmpty(false);
        },
      }),
      renderComponent(() => (
        <div className='centered-panel'>
          <h2>{messages.noDataWasFound}</h2>
        </div>
      )),
    ),
  );
};
