import { InputProps } from '@mui/material';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import {
  DiscoverTextInputThemeProvider,
  ITextInputTheme,
  StyledAdornment,
  StyledInput,
} from './text-input.styles';

export interface IDiscoverTextInputProps extends InputProps {
  theme?: ITextInputTheme;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const DiscoverTextInput = ({
  error,
  startAdornment,
  endAdornment,
  onFocus,
  onBlur,
  theme,
  ...props
}: IDiscoverTextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const _onFocus = useCallback(() => {
    setIsFocused(true);
    if (_.isFunction(onFocus)) {
      onFocus();
    }
  }, [onFocus]);
  const _onBlur = useCallback(() => {
    setIsFocused(false);
    if (_.isFunction(onBlur)) {
      onBlur();
    }
  }, [onBlur]);
  const hasStartAdornment = !!startAdornment;
  const hasEndAdornment = !!endAdornment;
  return (
    <DiscoverTextInputThemeProvider
      hasStartAdornment={hasStartAdornment}
      hasEndAdornment={hasEndAdornment}
      theme={theme}
      focused={isFocused}
      error={error}
    >
      <StyledInput
        {...props}
        onFocus={_onFocus}
        onBlur={_onBlur}
        disableUnderline
        error={error}
        startAdornment={
          startAdornment && (
            <StyledAdornment focused={isFocused} error={error} position='start'>
              {startAdornment}
            </StyledAdornment>
          )
        }
        endAdornment={
          endAdornment && (
            <StyledAdornment focused={isFocused} error={error} position='end'>
              {endAdornment}
            </StyledAdornment>
          )
        }
      />
    </DiscoverTextInputThemeProvider>
  );
};
