import { InsightsIcon } from '../../icons';
import { Labels } from '../../common/Constants';
import { messages } from '../../i18n';
import _ from 'lodash';
import { connect } from 'react-redux';

interface IInsightsButtonStateToProps {
  isMobile?: boolean;
  isDashletMode?: boolean;
}

interface IInsightsButtonProps extends IInsightsButtonStateToProps {
  onClick?: () => void;
  bubble?: boolean;
  anim?: boolean;
  hasInsights?: boolean;
  hideLabel?: boolean;
  size?: number;
}

const UnconnectedInsightsButton = ({
  onClick = _.noop,
  bubble = false,
  anim = false,
  hasInsights = false,
  hideLabel = false,
  isMobile = false,
  isDashletMode = false,
  size = 12,
}: IInsightsButtonProps) => {
  const text = !hideLabel ? Labels.DISCOVERY_INSIGHTS : '';
  return (
    <div
      className={`mobile-icon-button insights-button${
        hasInsights ? '' : ' empty'
      }${!isDashletMode ? ' mobile' : ''}`}
      onClick={() => onClick()}
    >
      <InsightsIcon
        isMobile={isMobile || isDashletMode}
        animate={anim}
        hasNewInsights={bubble}
        disabled={!hasInsights}
        size={size ?? 12}
      />
      <div>{_.get(messages, text, text)}</div>
    </div>
  );
};

const mapDispatchToProps = () => {
  return {};
};

const mapStateToProps = state => {
  return {
    isMobile: _.get(state, 'main.isMobile', false),
    isDashletMode: _.get(state, 'dashlet.isDashletMode', false),
  };
};

export const InsightsButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnconnectedInsightsButton);
