import { Component } from 'react';
import { connect } from 'react-redux';
import Main from '../../redux/actions/MainActions';
import { Modal } from '@sugar-discover/react-bootstrap-wrapper';
import { branch, compose, renderNothing } from 'react-recompose';
import { messages } from '../../../i18n';

class ErrorDetailDialog extends Component {
  render() {
    return (
      <Modal
        animation={false}
        enforceFocus={false}
        show={this.props.show}
        onHide={this.props.hideError}
        dialogClassName={'error-dialog'}
        bsSize='large'
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{messages.stackTrace}</h5>
          <pre>{this.props.errorDetail}</pre>
        </Modal.Body>
      </Modal>
    );
  }
}

export default compose(
  connect(
    state => {
      return {
        show: state.main.showErrorDetailDialog,
        errorDetail: state.main.errorDetail,
        title: state.main.errorTitle,
      };
    },
    dispatch => {
      return {
        hideError: () => {
          dispatch(Main.showErrorDetail(false, undefined, undefined));
        },
      };
    },
  ),
  branch(props => {
    return !props.show;
  }, renderNothing),
)(ErrorDetailDialog);
