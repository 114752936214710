import { keyframes, css } from '@emotion/react';
import { FC } from 'react';
import { LoaderSizeProps } from './interfaces';
import { useMemo } from 'react';

const clip = keyframes`
  0% {transform: rotate(0deg) scale(1)}
  50% {transform: rotate(180deg) scale(1)}
  100% {transform: rotate(360deg) scale(1)}
`;

const Loader: FC<Required<LoaderSizeProps>> = ({
  loading,
  size = 35,
  color,
  additionalStyles = css``,
}) => {
  const serializedStyles = useMemo(() => {
    return css`
      background: transparent !important;
      width: ${size}px;
      height: ${size}px;
      border-radius: 100%;
      border: 2px solid;
      border-color: ${color};
      border-bottom-color: transparent;
      display: inline-block;
      animation: ${clip} 1s 0s infinite linear;
      animation-fill-mode: both;
    `;
  }, [color, size]);

  return loading ? <span css={[serializedStyles, additionalStyles]} /> : null;
};

export default Loader;
