import _ from 'lodash';
import {
  IFilter,
  FilterType,
  FilterSubType,
  IOperator,
  IExpression,
  IExpressionSegment,
  IAggregationContext,
} from '../../datasets/interfaces/filter.interface';
import { IAnyAttribute } from '../../datasets/interfaces/attribute.interface';

export const FilterTypes = {
  STRING: 'STRING' as FilterType,
  DATE: 'DATE' as FilterType,
  NUMERIC: 'NUMERIC' as FilterType,
  BOOLEAN: 'BOOLEAN' as FilterType,
};

export const StringFilterSubTypes = {
  SELECT_ITEMS: 'SELECT_ITEMS' as FilterSubType,
  SET_CONDITION: 'SET_CONDITION' as FilterSubType,
  TOP_BOTTOM: 'TOP_BOTTOM' as FilterSubType,
};

export const AggregateFilterSubTypes = {
  SET_CONDITION: 'SET_CONDITION' as FilterSubType,
  TOP_BOTTOM: 'TOP_BOTTOM' as FilterSubType,
};

export const TimestampFilterSubTypes = {
  SET_CONDITION: 'SET_CONDITION' as FilterSubType,
  RELATIVE_DATES: 'RELATIVE_DATES' as FilterSubType,
  PERIOD_TO_DATE: 'PERIOD_TO_DATE' as FilterSubType,
};

export const TimestampFilterPeriods = {
  YEAR: 'YEAR',
  QUARTER: 'QUARTER',
  MONTH: 'MONTH',
  DAY: 'DAY',
  HOUR: 'HOUR',
  MINUTE: 'MINUTE',
};

export const LogicalOperators = {
  AND: 'AND' as LogicalOperatorType,
  OR: 'OR' as LogicalOperatorType,
};

export type LogicalOperatorType = 'AND' | 'OR';

export const NOW = '=now()';

/**
 * Used in Top/Bottom filter. This represents how you determine the top or bottom ranking of the filtered field
 */
class AggregationContext implements IAggregationContext {
  field: string;
  aggregation: string;

  constructor(field: string, aggregation: string) {
    this.field = field;
    this.aggregation = aggregation;
  }
}

class Filter implements IFilter {
  type: FilterType;
  field: string;
  expression: Expression;
  subType: FilterSubType;
  aggregationContext: AggregationContext;

  constructor(
    type: FilterType,
    field: any,
    expression: Expression,
    subType?: FilterSubType,
    aggregationContext?: AggregationContext,
  ) {
    if (_.isNil(FilterTypes[type])) {
      throw new Error(`Unsupported FilterType $type`);
    }
    this.type = type;
    this.field = field;
    this.expression = expression;
    this.subType = subType;
    this.aggregationContext = aggregationContext;
  }
}

class Condition implements IExpressionSegment {
  operator: string;
  operands: any[];
  constructor(operator: string = null, operands = []) {
    this.operator = operator;
    this.operands = operands;
  }
}

class Expression implements IExpression {
  left: any;
  operator: any;
  right: any;
  constructor(leftCondition, logicalOperator = null, rightCondition = null) {
    if (_.isNil(leftCondition)) {
      throw new Error('A Condition is required');
    }
    if (!_.isNil(logicalOperator)) {
      if (_.isNil(LogicalOperators[logicalOperator])) {
        throw new Error(`Unsupported LogicalOperator $logicalOperator`);
      }
      if (_.isNil(rightCondition)) {
        throw new Error('Expected a second condition');
      }
    }
    this.left = leftCondition;
    this.operator = logicalOperator;
    this.right = rightCondition;
  }
}

class Operator implements IOperator {
  key: string;
  displayText: string;
  shortDisplayText: string;
  fiscalDisplayText: string;
  shortFiscalDisplayText: string;
  queryOperator: string;
  requiresOperand: boolean;
  hideOperand: boolean;
  disableTime: boolean;
  requiresFieldPicker: boolean;
  constructor({
    key,
    displayText,
    shortDisplayText,
    fiscalDisplayText,
    shortFiscalDisplayText,
    queryOperator,
    requiresOperand,
    hideOperand = false,
    disableTime = false,
    requiresFieldPicker = false,
  }: IOperator) {
    this.key = key;
    this.displayText = displayText;
    this.shortDisplayText = shortDisplayText;
    this.fiscalDisplayText = fiscalDisplayText;
    this.shortFiscalDisplayText = shortFiscalDisplayText;
    this.queryOperator = queryOperator;
    this.requiresOperand = requiresOperand;
    this.hideOperand = hideOperand;
    this.disableTime = disableTime;
    this.requiresFieldPicker = requiresFieldPicker;
  }
}

export const createFilterForField = (field?: IAnyAttribute) => {
  let filterType = FilterTypes.STRING;
  switch (field?.attributeType) {
    case 'BOOLEAN':
      filterType = FilterTypes.BOOLEAN;
      break;
    case 'DATE':
    case 'TIMESTAMP':
    case 'TIME_CALC':
      filterType = FilterTypes.DATE;
      break;
    case 'NUMBER':
    case 'NUMERIC':
    case 'CALC':
    case 'PRIOR_PERIOD_CALC':
      filterType = FilterTypes.NUMERIC;
      break;
    default:
      filterType = FilterTypes.STRING;
  }

  const defaultCondition = new Condition();
  const emptyExpression = new Expression(defaultCondition);
  return new Filter(filterType, field?.name, emptyExpression);
};

export { Filter, Condition, Expression, Operator, AggregationContext };
