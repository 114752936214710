import { css } from '@emotion/react';
import {
  font,
  vbox,
  svgColorizePartial,
  flexBox,
  backgroundImage,
} from './mixins';

export const datasetWizardStyles = ({
  appUrl,
  theme: {
    colors: {
      ContentBackground,
      LightFontWeight,
      baseFontColor,
      PanelBackground,
      Strokes,
      TabHighlight,
      MediumBorder,
      CorvanaLightText,
      PrimaryColor,
      negativeColor,
      errorBackground,
      RegularFontWeight,
      ChartLabelTextColor,
      ToolIconHoverColor,
      TableBorderColor,
      TableHeaderModalBackground,
      MediumFontWeight,
      ActiveLinkText,
      TabDisabledColor,
      DropdownButtonBorderColor,
      DisabledText,
      ContentText,
    },
  },
}) => css`
  .tabsHeader {
    ${font({ size: '24px', weight: LightFontWeight })}
    margin-bottom: 9px;
  }
  .tabs {
    ${font({ size: '20px', weight: LightFontWeight })}
    padding: 0px 20px;
  }
  .tabs > .nav-tabs {
    margin-bottom: 10px;
    /* Customize nav tab style*/
    /* Customize active nav tab style*/
  }
  .tabs > .nav-tabs > li > a {
    padding: 20px 15px 0px 15px;
    color: ${baseFontColor};
    background-color: ${PanelBackground};
  }
  .tabs > .nav-tabs > li > a:hover {
    border: transparent;
    border-bottom: 4px solid ${Strokes};
  }
  .tabs > .nav-tabs > li > a:focus {
    /* Disable blue focus outline on click*/
    outline: none;
  }
  .tabs > .nav-tabs > li.active > a {
    border: transparent;
    border-bottom: 4px solid ${TabHighlight};
    background-color: ${PanelBackground};
  }
  .tabs > .nav-tabs > li.active > a:hover {
    /* Maintain tab highlight on hover*/
    border-bottom: 4px solid ${TabHighlight};
  }
  .dataset-item {
    ${font({ weight: LightFontWeight })}
    color: ${baseFontColor};
    width: 200px;
    height: 185px;
    margin: 0px 10px 10px 0px;
    padding: 0px;
  }
  .dataset-item .thumbnail {
    border-radius: 4px;
    box-shadow: 0px 2px 1px ${MediumBorder};
  }
  .dataset-item:hover .thumbnail {
    box-shadow: 0px 8px 4px ${MediumBorder};
    transform: translateY(-5px);
  }
  .dataset-item > .thumbnail {
    margin: 0px;
    padding: 0px;
    height: 100%;
  }
  .dataset-item > .thumbnail .title {
    ${font({ size: '18px' })}
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 8px;
  }
  .dataset-item > .thumbnail .description {
    ${font({ size: '12px' })}
    margin-top: 3px;
    color: ${CorvanaLightText};
  }
  .dataset-item > .thumbnail .warning,
  .dataset-item > .thumbnail .spinner {
    margin-top: 3px;
  }
  .dataset-item.disabled:hover {
    cursor: default;
    margin-top: 0px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 1px ${TabDisabledColor};
  }
  .dataset-item.disabled > .thumbnail img {
    opacity: 0.5;
  }
  .dataset-item.disabled > .thumbnail .title {
    color: ${CorvanaLightText};
  }
  .fileDropzoneContainer {
    ${vbox()}
    ${font({ size: '24px', weight: LightFontWeight })}
    flex: 1;
  }
  .fileDropzone {
    ${font({ size: '24px', weight: LightFontWeight })}
    ${vbox()}
    height: 335px;
    justify-content: center;
    flex: 1;
    border: 1px dashed ${DropdownButtonBorderColor};
    border-radius: 2px;
    padding: 30px;
    text-align: center;
  }
  .fileDropzone .content {
    height: 335px;
  }
  .fileDropzone img {
    margin-bottom: 10px;
  }
  .fileDropzone .supportedFiles {
    ${font({ size: '16px', weight: LightFontWeight })}
    color: ${CorvanaLightText};
  }
  .fileDropzone .chooseFileButton {
    ${font({ size: '20px' })}
    width: 150px;
    margin-bottom: 30px;
  }
  .fileDropzone.supportedFile {
    border-color: ${PrimaryColor};
    color: ${PrimaryColor};
  }
  .fileDropzone.unsupportedFile {
    border: 1px solid ${negativeColor};
    background-color: ${errorBackground};
    color: ${negativeColor};
  }
  .fileDropzone.unsupportedFile .supportedFiles {
    color: ${negativeColor};
  }
  .propsHeader {
    ${font({ size: '16px' })}
    margin-bottom: 8px;
  }
  .propsDetails {
    ${font({ size: '14px', weight: LightFontWeight })}
    margin: 16px 0px;
  }
  .propsDetails .dropdown-menu {
    width: 100%;
  }
  .propsBold {
    ${font({ weight: RegularFontWeight })}
  }
  .propsTextbox {
    ${font({ size: '14px' })}
    display: inline-block;
    margin-left: 10px;
    height: 32px;
  }
  .propsInput {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 8px;
    vertical-align: middle;
  }
  .propsInput input[type='checkbox'] {
    margin-top: -5px;
    margin-left: 5px;
  }
  .propsInput .dropdown {
    margin-left: 10px;
  }
  .dataTable thead > tr > th .edit-text-popover {
    margin-left: 0px;
    max-width: 156px;
  }
  .dataTable thead > tr > th > .column-name {
    width: 100%;
    overflow: hidden;
    height: 40px;
    padding: 0px 6px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .dataTable .column-name .delete-icon,
  .dataTable .column-name .edit-icon {
    display: flex;
    opacity: 0;
  }
  .dataTable .column-name:hover .delete-icon,
  .dataTable .column-name:hover .edit-icon {
    opacity: 1;
    cursor: pointer;
  }

  ${svgColorizePartial({
    fromColor: ChartLabelTextColor,
    toColor: ToolIconHoverColor,
  })(`
    .dataTable .delete-icon:hover svg,
    .dataTable .edit-icon:hover svg
  `)}
  .dataTable .calc-column {
    display: flex;
    align-items: center;
  }
  .dataTable .calc-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 136px;
  }
  .dataTable thead > tr > th > .column-dropdown {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 40px;
    border-top: 1px solid ${TableBorderColor};
    padding: 0px 6px;
  }
  .dataTable > .react-bs-table > div > table > thead > tr > th {
    ${font({ size: '14px', weight: RegularFontWeight })}
    border-left: 1px solid ${TableBorderColor};
    padding: 0px;
    overflow: visible;
  }
  .dataTable
    > .react-bs-table
    > .react-bs-container-body
    > .table
    > tbody
    > tr
    > td {
    ${font({ size: '14px', weight: LightFontWeight })}
    border-left: 1px solid ${TableBorderColor};
    line-height: normal;
    padding: 8px 6px 7px 6px;
  }
  .dataTable > .react-bs-table {
    border-radius: 0px;
  }
  .dataTable thead > tr > th > .empty-header {
    height: 40px;
  }
  .dataTable .react-bs-table-no-data {
    border-left: 1px solid ${TableBorderColor} !important;
  }
  .dataTable .react-bs-container-header {
    overflow: visible;
  }
  .dataTable .react-bs-container-body {
    overflow: visible;
  }
  .dataTable {
    overflow: auto;
    height: 100%;
  }
  .dataTable table {
    border-right: 1px solid ${TableBorderColor};
  }
  .dataTable table > thead > tr > th > .btn-group,
  .dataTable table .btn-group {
    display: block;
    width: 100%;
  }
  .dataTable th .tableHeaderDropdown.btn:active,
  .dataTable th .tableHeaderDropdown.btn:focus,
  .dataTable th .tableHeaderDropdown.btn {
    ${font({ size: '14px', weight: LightFontWeight })}
  }
  .dataTable .react-bs-table > div > table > thead > tr > th {
    background: ${TableHeaderModalBackground};
  }
  .dataTable .dropdown-menu {
    width: 100%;
  }
  .fileMetadataDataTableContainer {
    ${vbox()}
  }
  .fileMetadataTableSummary .dataTable {
    overflow: visible;
  }
  .fileMetadataTableSummary .dropdown-menu {
    width: 100%;
  }
  #fileMetadataName {
    width: 200px;
  }
  .fileMetadataMarginLeft {
    margin-left: 16px;
  }
  .sourceFilePropsCol1 {
    width: 180px;
  }
  .sourceFilePropsCol2 {
    text-align: right;
    width: 300px;
  }
  .sourceFilePropsCol3 {
    text-align: right;
    width: 415px;
  }
  .sourceFileRow2 {
    margin-top: 15px;
  }
  .fileMetadataStats {
    ${font({ size: '16px', weight: LightFontWeight })}
    display: inline-block;
  }
  .fileMetadataPreviewCount {
    display: inline-block;
    text-align: right;
  }
  #fileMetadataDescription {
    width: 500px;
  }
  #fileMetadataFieldSeparator {
    width: 72px;
  }
  #fileMetadataPreviewCountDropdown {
    width: 100px;
    margin-right: 8px;
  }
  #fileMetadataCharacterSet {
    width: 225px;
  }
  #fileMetadataTextQualifier {
    width: 72px;
  }
  #fileMetadataLocale {
    width: 250px;
  }
  #fileMetadataPreviewCountFormControl {
    width: 80px;
    margin-right: 6px;
  }
  .button-row {
    margin-top: 16px;
  }
  .nullValue {
    ${font({ style: 'style' })}
    width: 100%;
    text-align: center;
  }
  .placeholder {
    color: ${CorvanaLightText};
    width: 100%;
    text-align: center;
  }
  .numberValue {
    text-align: right;
  }
  #sugarcrm-panel {
    ${flexBox()}
    ${vbox()}
    height: 100%;
    width: 100%;
  }
  #sugarcrm-panel .wizard-toolbar {
    margin: auto 0 0 0;
    padding: 0 9px 9px 0;
  }
  #sugarcrm-import-panel {
    border: none;
    box-shadow: none;
    padding: 9px;
    background-color: ${ContentBackground};
  }
  #sugarcrm-import-panel label:not(.control-label) {
    ${font({ size: '16px', weight: RegularFontWeight })}
    margin-bottom: 7px;
  }
  #sugarcrm-import-panel .form-group {
    margin-bottom: 15px;
  }
  #sugarcrm-import-panel label.control-label {
    ${font({ size: '14px', weight: LightFontWeight })}
    text-align: right;
    margin-right: 10px;
    white-space: nowrap;
    padding-left: 8px;
  }
  #sugarcrm-import-panel input.form-control {
    ${font({ size: '14px', weight: LightFontWeight })}
  }
  #sugarcrm-import-panel input.form-control.name-input {
    width: 207px;
    margin-right: 20px;
  }
  #sugarcrm-import-panel input.form-control.description-input {
    width: 544px;
  }
  .wizardHeader {
    text-align: center;
  }
  .wizardHeader h1 {
    margin-top: 0px;
    margin-bottom: 5px;
    ${font({ weight: MediumFontWeight, size: '24px' })}
  }
  .wizardHeader ol {
    -webkit-padding-start: 0px;
    list-style-position: inside;
    counter-reset: item;
  }
  .wizardHeader ol li:not(:last-of-type) {
    padding-right: 72px;
  }
  .wizardHeader ol li:not(:last-of-type):after {
    content: '';
    width: 60px;
    height: 1px;
    background-color: ${TableBorderColor};
    position: absolute;
    top: 17px;
    margin-left: 4px;
  }
  .wizardHeader ol li:before {
    content: counter(item);
    counter-increment: item;
    ${backgroundImage({
      appUrl,
      path: 'wizard_background_circle_unselected.svg',
    })}
    display: inline-block;
    background-repeat: no-repeat;
    padding-left: 8px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 2px;
    margin-right: 6px;
    line-height: 28px;
    ${font({
      weight: MediumFontWeight,
      size: '18px',
      color: ContentBackground,
    })}
  }
  .wizardHeader ol li.selectable-step:hover:before {
    color: ${ActiveLinkText};
    ${backgroundImage({
      appUrl,
      path: 'wizard_background_circle_hover.svg',
    })}
  }
  .wizardHeader ol li.selected-step:before {
    ${backgroundImage({
      appUrl,
      path: 'wizard_background_circle.svg',
    })}
  }
  .wizardHeader ol li {
    display: inline-block;
    padding-left: 0px;
    padding-top: 2px;
    position: relative;
    ${font({ size: '16px', weight: LightFontWeight, color: DisabledText })}
  }
  .wizardHeader ol li.selected-step {
    ${font({ size: '16px', weight: RegularFontWeight, color: ContentText })}
  }
  .wizardHeader ol li.selectable-step {
    cursor: pointer;
  }
  .wizardHeader ol li.selectable-step:hover {
    color: ${ActiveLinkText};
  }
  .delete-calc-detail {
    text-align: center;
  }
`;
