import React, { useEffect, useState, useRef, useCallback } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { messages } from '../../i18n';
import { FormInput } from '../../components/ui/form-input';
import {
  FormLabel,
  FormGroup,
  StyledUserInputDialog,
} from './edit-discovery-dialog.styles';

const detailTypes = {
  NAME: 'name',
  DESCRIPTION: 'description',
};

interface IProps {
  show?: string;
  detail?: string;
  value?: string;
  onOk?: any;
  onCancel?: any;
}

export const EditDiscoveryDialog = ({
  value,
  detail,
  onOk,
  onCancel,
}: IProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [autoSelectInput, setAutoSelectInput] = useState(true);
  const inputRef = useRef(null);
  const initialValue = value;

  useEffect(() => {
    if (inputRef.current && autoSelectInput) {
      inputRef.current.focus();
      setAutoSelectInput(false);
    }
  }, [autoSelectInput]);

  const onChange = e => {
    const { value } = e.target;
    setCurrentValue(value);
  };

  const onSave = useCallback(() => {
    onOk(currentValue);
  }, [onOk, currentValue]);

  const isSaveDisabled = useCallback(() => {
    const detailTypesHandlers = {
      [detailTypes.NAME]:
        isEmpty(currentValue) || isEqual(initialValue, currentValue),
      [detailTypes.DESCRIPTION]: isEqual(initialValue, currentValue),
    };

    if (detailTypesHandlers[detail]) {
      return detailTypesHandlers[detail];
    }

    return false;
  }, [detail, currentValue, initialValue]);

  useEffect(() => {
    const onEnter = e => {
      if (e.keyCode === 13) {
        e.preventDefault();
        if (!isSaveDisabled()) {
          onSave();
        }
      }
    };
    window.addEventListener('keypress', onEnter);
    return () => {
      window.removeEventListener('keypress', onEnter);
    };
  }, [isSaveDisabled, onSave]);

  return (
    <StyledUserInputDialog
      dialogClassName={'edit-discovery-dialog'}
      title={
        detail === detailTypes.NAME
          ? messages.library.rename
          : messages.library.editDescription
      }
      onCancel={onCancel}
      onOk={onSave}
      okButtonLabel={
        detail === detailTypes.NAME
          ? messages.library.rename
          : messages.library.update
      }
      okDisabled={isSaveDisabled()}
    >
      {detail === detailTypes.NAME && (
        <FormGroup>
          <FormLabel>{messages.name}</FormLabel>
          <FormInput
            value={currentValue}
            onChange={onChange}
            inputRef={inputRef}
          />
        </FormGroup>
      )}
      {detail === detailTypes.DESCRIPTION && (
        <FormGroup>
          <FormLabel>{messages.description}</FormLabel>
          <FormInput
            multiline
            value={currentValue}
            onChange={onChange}
            inputRef={inputRef}
          />
        </FormGroup>
      )}
    </StyledUserInputDialog>
  );
};
