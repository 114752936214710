import { useState, useEffect, useMemo, useCallback } from 'react';
import { messages } from '../../i18n';
import { sugarDateFormats, sugarTimeFormats } from '../../common';
import _, { kebabCase, map } from 'lodash';
import { I18nSettingsBox, I18nSettingsBoxItem } from './settings-i18n.styles';
import { SelectDropdown, SelectItem } from '../../ui/dropdowns/select-dropdown';
import { UserProfileDetail } from '../settings-user-profile/settings-user-profile.style';
import { FormInput } from '../../components/ui/form-input';
import { ISettingsI18nComponentProps } from './settings-i18n.interface';
import { MAX_SLICER_WIDTH, SCROLL_ROW_HEIGHT } from '../../discovery/slicer';

export function I18nSettingDropdown({
  options,
  value,
  setValue,
  disabled,
  title,
  'aria-labelledby': ariaLabelledby,
}) {
  const label = _(options)
    .chain()
    .fromPairs()
    .get(value, messages.account.useDefault)
    .value();
  const id = `i18n-setting-${_.kebabCase(title)}`;

  if (options) {
    return (
      <SelectDropdown
        title={label ?? messages.account.useDefault}
        aria-labelledby={ariaLabelledby}
        disabled={disabled}
        headerSx={{
          height: `${SCROLL_ROW_HEIGHT}px`,
          width: '100%',
          maxWidth: MAX_SLICER_WIDTH,
        }}
      >
        <SelectItem key={'default'} onClick={() => setValue('')}>
          {messages.account.useDefault}
        </SelectItem>
        {map(options, ([optionKey, optionLabel]) => (
          <SelectItem key={optionKey} onClick={() => setValue(optionKey)}>
            {optionLabel}
          </SelectItem>
        ))}
      </SelectDropdown>
    );
  }

  return (
    <FormInput
      id={id}
      disabled={disabled}
      className={'i18n-setting-input'}
      value={value || ''}
      placeholder={messages.account.useDefault}
      onChange={event => setValue(event.target.value)}
      aria-labelledby={ariaLabelledby}
      fullWidth
    />
  );
}

export function I18nSetting({ title, ...rest }: any) {
  const labelId = `i18n-setting-label-${kebabCase(title)}`;
  return (
    <I18nSettingsBoxItem>
      <UserProfileDetail>
        <div id={labelId} className={'user-label'}>
          {title}
        </div>
        <I18nSettingDropdown
          title={title}
          {...rest}
          aria-labelledby={labelId}
        />
      </UserProfileDetail>
    </I18nSettingsBoxItem>
  );
}

export const SettingsI18n = ({
  i18nPrefs: savedI18nPrefs,
  changeI18n,
  isSaving,
}: ISettingsI18nComponentProps) => {
  const [prefs, setPrefs] = useState(savedI18nPrefs);

  useEffect(() => {
    setPrefs(savedI18nPrefs);
  }, [savedI18nPrefs]);

  const handleChange = useCallback(
    (key: string, value: string) => {
      const updatedPrefs = { ...prefs, [key]: value };
      setPrefs(updatedPrefs);
      changeI18n(updatedPrefs);
    },
    [changeI18n, prefs],
  );

  const settings = useMemo(
    () => [
      {
        title: messages.account.dateFormatTitle,
        options: sugarDateFormats,
        value: prefs?.dateFormat ?? '',
        setValue: value => handleChange('dateFormat', value),
      },
      {
        title: messages.account.timeFormatTitle,
        options: sugarTimeFormats,
        value: prefs?.timeFormat ?? '',
        setValue: value => handleChange('timeFormat', value),
      },
      {
        title: messages.account.numGroupSeparatorTitle,
        value: prefs?.numGroupSeparator ?? '',
        setValue: value => handleChange('numGroupSeparator', value),
      },
      {
        title: messages.account.decimalTitle,
        value: prefs?.decimal ?? '',
        setValue: value => handleChange('decimal', value),
      },
    ],
    [handleChange, prefs],
  );

  return (
    <I18nSettingsBox>
      {map(settings, ({ title, ...rest }) => (
        <I18nSetting key={title} title={title} disabled={isSaving} {...rest} />
      ))}
    </I18nSettingsBox>
  );
};
