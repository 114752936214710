import { css } from '@emotion/react';
import _ from 'lodash';
import {
  ISugarIconProps,
  SugarIconColorType,
  SugarIconType,
} from '../../common';
import { SugarIcon } from '../../sugar-icon';
import classnames from 'classnames';
import { SortDirection as SortDirectionEnum } from '../../../common/Constants';
import { useDiscoverTheme } from '../../../common/emotion/theme';

type SortDirectionLower = SortDirectionEnum;
type SortDirectionUpper = Uppercase<SortDirectionLower>;
type SortDirection = SortDirectionLower | SortDirectionUpper;

export const SortIndicator = ({
  sortDirection,
  className,
  ...props
}: Omit<ISugarIconProps, 'icon'> & {
  sortDirection?: SortDirection;
}) => {
  const { darkMode } = useDiscoverTheme();
  let sortIcon: SugarIconType = 'arrow-down';
  let color: SugarIconColorType = 'light';
  if (!_.isUndefined(sortDirection)) {
    sortDirection = _.toLower(sortDirection) as SortDirectionLower;
  }
  if (sortDirection == 'asc') {
    sortIcon = 'arrow-up';
  }
  if (sortDirection) {
    color = 'dark';
  }
  return (
    <SugarIcon
      css={css`
        display: 'inline-block';
        margin-top: 7px;
      `}
      className={classnames({
        'unsorted-sort-indicator': !sortDirection,
        [className]: className,
      })}
      icon={sortIcon}
      iconType={darkMode ? null : color}
      hover={!!sortDirection}
      {...props}
    />
  );
};
