import intersection from 'lodash/intersection';
import { isEmpty, get } from 'lodash';
import { createSelector } from 'reselect';
import {
  IDM_ROLES,
  convertSugarDateFormat,
  convertSugarTimeFormat,
} from '../../Constants';

export const currentUserSelector = account => {
  let user = account.currentUser || {};
  if (!isEmpty(user)) {
    user = {
      ...user,
      profileImageURL: `/api/image/${user.email}/profile.jpg`,
    };
  }
  return user;
};

export const getUserRoles = account =>
  get(account, 'currentUser.roles', []).map(e => e.toUpperCase());

export const userHasRole = (...roles) => {
  return account => {
    const userRoles = getUserRoles(account);
    return intersection(userRoles, roles).length > 0;
  };
};

export const isAdmin = userHasRole('TENANT_ADMIN', 'APP_ADMIN', 'ADMIN');
export const isAppAdmin = userHasRole('APP_ADMIN');
export const isReadOnly = userHasRole('READONLY');
export const isDashletUser = userHasRole('DASHLETUSER');
export const isNoAccess = userHasRole('NOACCESS');
export const isIdmAdministrator = account => {
  return account?.currentUser?.idm?.role === IDM_ROLES.ADMINISTRATOR;
};
export const idmAdminUrl = account => {
  return account?.currentUser?.idm?.administrationUrl;
};
export const isNonDashletUser = userHasRole(
  'ADMIN',
  'USER',
  'READONLY',
  'APP_ADMIN',
  'ADMIN',
);
export const userHasNoRoles = account => isEmpty(getUserRoles(account));

export const isPrivate = state => {
  const discovery = getOpenDiscovery(state);
  return discovery.viz.isPrivate;
};

export const isUserOwnerOfDiscoveryViz = state => {
  const discovery = getOpenDiscovery(state);
  const user = getCurrentUser(state);
  return discovery.creator === user.id;
};

const getOpenDiscovery = state => {
  const openID = state.discover.displayDiscovery;
  return state.discover.openDiscoveries[openID].present;
};

export const getIsReadOnlyUser = state => {
  return isReadOnly(state.account);
};

// reselect selectors
const getCurrentUser = state => {
  return currentUserSelector(state.account);
};

const getI18nPrefs = createSelector(
  [getCurrentUser],
  user => user?.i18nPrefs ?? {},
);

const getDatetimeFormats = createSelector(
  [getI18nPrefs],
  ({ dateFormat: profileDateFormat, timeFormat: profileTimeFormat }) => {
    const dateFormat = convertSugarDateFormat(profileDateFormat);
    const timeFormat = convertSugarTimeFormat(profileTimeFormat);
    const dateTimeFormat = `${dateFormat} ${timeFormat}`;
    return {
      dateFormat,
      timeFormat,
      dateTimeFormat,
    };
  },
);

const getUserPreferences = createSelector([getCurrentUser], user => {
  return isEmpty(user.preferences) ? {} : user.preferences;
});

const getPinnedDiscoveries = createSelector(
  [getUserPreferences],
  userPreferences => {
    return isEmpty(userPreferences.pinnedDiscoveries)
      ? []
      : userPreferences.pinnedDiscoveries;
  },
);

export const ACCOUNT_SELECTORS = {
  getDatetimeFormats,
  getCurrentUser,
  getUserPreferences,
  getPinnedDiscoveries,
  getI18nPrefs,
};
