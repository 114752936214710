import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { DatasetSection } from '../../edit-dataset-settings.styles';

export const FunnelConfigurationSection = styled(DatasetSection)`
  width: 100%;
  max-width: 250px;
`;

export const FunnelConfigurationGrid = styled.div`
  display: grid;
`;

export const FunnelConfigurationSplitLabel = styled.div(
  ({ theme: { colors: { Gray70 } = {} as any } = {} as any }: any) => css`
    font-size: 12px;
    color: ${Gray70};
  `,
);

export const FunnelConfigurationSplitInput = styled.div`
  margin-bottom: 25px;

  .MuiInputBase-input {
    height: 0.9em;
  }
`;
