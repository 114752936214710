import styled from '@emotion/styled';
import { ModalPortal } from '../../common/widgets/dialogs/modal-portal';

export const StyledModalPortal = styled(ModalPortal)`
  .no-access-dialog {
    width: 400px;
  }
  .no-access-dialog-body {
    padding: 0.4rem 1rem 1.5rem;
    font-size: 0.75rem;
  }
`;
