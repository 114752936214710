import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MUITab from '@mui/material/Tab';
import { useDiscoverTheme } from '../../../common/emotion/theme';

export const StyledTab = styled(MUITab)(() => {
  const {
    colors: { MediumBlue, CorvanaMediumText, LightFontWeight },
  } = useDiscoverTheme();

  return css`
    padding: 0;
    text-transform: initial;
    font-weight: ${LightFontWeight};
    font-size: 16px;
    color: ${CorvanaMediumText};
    .Mui-selected,
    &:hover {
      color: ${MediumBlue};
    }
  `;
});
