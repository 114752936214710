import ServiceLocator from './common/ServiceLocator';
import { ComponentTypes } from './common/Constants';
import Top3Component from './components/Insights/Top3';
import ErrorInsightComponent from './components/Insights/Error';
import BiggestMoverComponent from './components/Insights/BiggestMovers';

import {
  DaysInStageRedirect,
  IdleopsRedirect,
  WeightedForecastRedirect,
} from './redirects';
import reducer from './tenants/corvana/CorvanaReducer';
import { InsightHoc, InsightsList } from './tenants/corvana/CorvanaInsights';
import {
  DaysInStageComponent,
  DaysInStageInsightComponent,
} from './tenants/corvana/DaysInStageComponent';
import {
  WeightedForecastComponent,
  WeightedForecastInsightComponent,
} from './tenants/corvana/WeightedForecastComponent';
import { IdleopsComponent } from './tenants/corvana/IdleopsComponent';
import GamRedirect from './redirects/GamRedirect';
import AnomalyActivity from './components/AnomalyActivity/Activity';
import AnomalyInsights from './components/AnomalyActivity/Insight';

import {
  ComparisonMonitorActivityFeedItem,
  ComparisonMonitorInsightComponent,
  ComparisonMonitorRedirect,
} from './monitors/comparison-monitor';

const ADMIN = 'discover-admin@sugarcrm.com';

class MainModule {
  constructor() {
    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.INSIGHTS },
      Top3Component,
    );
    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.INSIGHTS },
      ErrorInsightComponent,
    );
    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.INSIGHTS },
      BiggestMoverComponent,
    );

    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.ACTIVITY },
      WeightedForecastComponent,
    );
    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.ACTIVITY },
      DaysInStageComponent,
    );
    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.ACTIVITY },
      IdleopsComponent,
    );
    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.ACTIVITY },
      AnomalyActivity,
    );
    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.ACTIVITY },
      ComparisonMonitorActivityFeedItem,
    );

    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.INSIGHTS_LIST },
      InsightHoc(7)(InsightsList),
    );
    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.INSIGHTS },
      WeightedForecastInsightComponent,
    );
    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.INSIGHTS },
      DaysInStageInsightComponent,
    );
    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.INSIGHTS },
      AnomalyInsights,
    );
    ServiceLocator.add(
      'COMPONENT',
      { type: ComponentTypes.INSIGHTS },
      ComparisonMonitorInsightComponent,
    );

    ServiceLocator.add('HOC', { type: ComponentTypes.INSIGHTS }, InsightHoc(7));

    ServiceLocator.add(
      'REDIRECT',
      { type: ComponentTypes.REDIRECT },
      ComparisonMonitorRedirect({ adminName: ADMIN }),
    );
    ServiceLocator.add(
      'REDIRECT',
      { type: ComponentTypes.REDIRECT },
      WeightedForecastRedirect({
        attributeProp: 'attributeName',
        adminName: ADMIN,
      }),
    );
    ServiceLocator.add(
      'REDIRECT',
      { type: ComponentTypes.REDIRECT },
      DaysInStageRedirect({ stageAttrName: 'stage', adminName: ADMIN }),
    );
    ServiceLocator.add(
      'REDIRECT',
      { type: ComponentTypes.REDIRECT },
      IdleopsRedirect({ attributeProp: 'opportunity name', adminName: ADMIN }),
    );
    ServiceLocator.add(
      'REDIRECT',
      { type: ComponentTypes.REDIRECT },
      GamRedirect({ supportedTypes: ['anomalydetector'], adminName: ADMIN }),
    );
    ServiceLocator.add('REDUCER', { type: 'custom-reducer' }, reducer);
  }
}
export default new MainModule();
