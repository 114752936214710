import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useDiscoverThemeColors } from '../../common/emotion/theme';
import { ModalPortal } from '../../common/widgets/dialogs/modal-portal';

export const StyledModal = styled(ModalPortal)`
  .viz-save-dialog {
    width: 410px;
  }
`;

export const ModalLabel = styled.label(() => {
  const { Gray70, RegularFontWeight } = useDiscoverThemeColors();

  return css`
    color: ${Gray70};
    font-weight: ${RegularFontWeight};
    font-size: 12px;
    margin: 0;
  `;
});

export const FormRoot = styled.div(() => {
  const { ErrorColor, StrongFontWeight } = useDiscoverThemeColors();

  return css`
    padding: 0 15px 10px;

    textarea {
      resize: vertical;
    }
    .name-input.error {
      input {
        border-color: ${ErrorColor};
      }
    }
    .save-error-msg {
      color: ${ErrorColor};
      text-align: left;
      font-size: 12px;
      font-weight: ${StrongFontWeight};
    }
  `;
});

export const HelpBlock = styled.div(() => {
  const { RegularFontWeight } = useDiscoverThemeColors();

  return css`
    font-weight: ${RegularFontWeight};
    font-size: 12px;
  `;
});
