import { ApolloClient } from '@apollo/client';

let _client: ApolloClient<any>;
export const versionAndDispatchHolder: {
  dispatch?;
  version?;
} = {};
export const setClient = client => {
  _client = client;
};

export const getClient = () => _client;
