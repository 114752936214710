import React from 'react';
import { createPortal } from 'react-dom';
import {
  ModalPanel,
  ModalContent,
  ModalTitle,
  ModalBody,
} from './modal-portal.styles';
import { usePortalRef } from '../../../utilities/portal';
import { IModalPortalProps } from './modal-portal.interfaces';
import { ErrorBoundaryComponentProps } from '../../../../components/error-boundary';
import { ErrorBoundary } from 'react-error-boundary';

export const ModalPortal = ({
  className,
  contentClassName = '',
  title,
  buttonGroup,
  children,
  zIndex = 1050,
}: IModalPortalProps) => {
  const { getPortalContainer } = usePortalRef();
  const portalRef = getPortalContainer();

  return portalRef
    ? createPortal(
        <ModalPanel zIndex={zIndex} className={className}>
          <div className='modal-backdrop dialog-backdrop in'></div>
          <ModalContent zIndex={zIndex} className={contentClassName}>
            <ModalTitle>{title}</ModalTitle>
            <ModalBody>
              <ErrorBoundary {...ErrorBoundaryComponentProps}>
                {children}
              </ErrorBoundary>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {buttonGroup}
              </div>
            </ModalBody>
          </ModalContent>
        </ModalPanel>,
        portalRef,
      )
    : null;
};
