import { Component } from 'react';
import { compose, shouldUpdate, withProps, withState } from 'react-recompose';
import { connect } from 'react-redux';
import VizQueries from '../../common/graphql/VizGql';
import _ from 'lodash';
import CorvanaCheckbox from '../../common/widgets/CorvanaCheckbox';
import {
  Condition,
  createFilterForField,
  FilterTypes,
  StringFilterSubTypes,
} from './Filter';
import Discover from '../../common/redux/actions/DiscoverActions';
import { FilterOperators } from './FilterOperators';
import { AutoSizer, List } from 'react-virtualized';
import mapValues from 'lodash/mapValues';
import { NULL_DISPLAY, NULL_TOKEN } from '../../common/Constants';
import { Viz } from '../VizUtil';
import { client } from '../../common/ApolloClient';
import { messages } from '../../i18n';
import { FetchPolicy } from '@apollo/client';
import { IFilter } from '../../datasets/interfaces/filter.interface';
import { VIZ_SELECTORS } from '../../common/redux/selectors/viz-selectors';
import { SkeletonListLoader } from '../../common/loaders/skeleton-list-loader';
import { LoaderWrapper } from './active-filter-panel';

const { IN_LIST } = FilterOperators.forFilterType(
  FilterTypes.STRING,
  StringFilterSubTypes.SELECT_ITEMS,
);

const createSelectItemsFilter = field => {
  const filter = createFilterForField(field);
  filter.expression.left = new Condition(IN_LIST.key);
  filter.subType = StringFilterSubTypes.SELECT_ITEMS;
  return filter;
};

const PAGE_SIZE = 10000;

interface IPropTypes {
  queryResults: any;
  resultCount: any;
  selectedItems: any;
  allSelected: any;
  search: any;
  isLimitedResult: any;
  filter: IFilter;
  changeFilter: any;
  error: any;
  loading: any;
  field: any;
  vizCalcs: any;
  datasetId: any;
  applyCurrentVizFilters: any;
  viz: any;
  setQueryResults: any;
  useFiscalCalendar: boolean;
  setLoading: any;
}

class SelectStringItems extends Component<
  IPropTypes,
  { selectAllChecked: any; items: any; rows: any; searchAgain?: any }
> {
  static defaultProps = {
    search: '',
    applyCurrentVizFilters: true,
  };

  constructor(props) {
    super(props);
    const items = { ...props.selectedItems };
    this.state = {
      selectAllChecked: props.allSelected,
      items,
      rows: props.queryResults,
    };
  }
  componentDidMount() {
    if (!this.props.queryResults) {
      this.getMoreResults();
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      _.get(nextProps, 'filter.subType', StringFilterSubTypes.SELECT_ITEMS) ===
      StringFilterSubTypes.SELECT_ITEMS
    );
  }
  // TODO: [DSC-3544] Remove setState calls from inside of componentDidUpdate
  // (if possible)
  componentDidUpdate(prevProps, prevState) {
    // New items. Merge them in
    const prevCount =
      prevProps.resultCount || _.get(prevProps, 'filter.info.total', 0);
    const resultsChanged = !_.isEqual(
      prevCount || 0,
      this.props.resultCount || 0,
    );
    if (
      resultsChanged ||
      !_.isEqual(prevProps.selectedItems || [], this.props.selectedItems || [])
    ) {
      const newItems = { ...this.state.items };
      // merge in the new items
      Object.entries(this.props.selectedItems).forEach(([key, val]) => {
        if (_.isNil(newItems[key])) {
          newItems[key] = val;
        }
      });
      this.setState({
        items: newItems,
        selectAllChecked:
          this.state.selectAllChecked ||
          this.props.allSelected ||
          this.props.selectedItems.length === this.props.resultCount,
        rows: this.props.queryResults,
        searchAgain: !_.isEmpty(this.props.search),
      });
      this.updateFilterInfo(newItems);
    } else if (!_.isEqual(prevState.items, this.state.items)) {
      this.updateFilterInfo(this.state.items);
      this._changeFilter(this.state.items);
    }

    if (this.state.searchAgain || prevProps.search !== this.props.search) {
      this.performSearch(this.props.search);
    }
  }
  onSelectAll(event) {
    const isChecked = event.currentTarget.checked;
    const items = this.state.rows.reduce((acc, [rowName]) => {
      acc[rowName] = isChecked;
      return acc;
    }, {});

    this.setState({
      selectAllChecked: isChecked,
      items: { ...this.state.items, ...items },
    });
  }
  onItemChanged(event) {
    const isChecked = event.currentTarget.checked;
    const { value } = event.currentTarget;
    const selected = { ...this.state.items, [value]: isChecked };

    // sync up the 'Select All' check box with reality
    let shouldSelectAllBeChecked = null;
    const totalItemCount = Object.keys(this.state.items).length;
    const selectedCount = this._getSelectedItemList(selected).length;
    if (selectedCount === 0) {
      shouldSelectAllBeChecked = false;
    } else if (selectedCount === totalItemCount) {
      shouldSelectAllBeChecked = true;
    }

    this.setState({
      selectAllChecked: shouldSelectAllBeChecked,
      items: { ...selected },
    });
    this._changeFilter(selected);
  }
  _getSelectedItemList(items) {
    const currentlySelected = _.get(
      this.props,
      'filter.expression.left.operands',
      [],
    );
    const selected = new Set();
    if (this.props.isLimitedResult) {
      // may contain items not visible in current limited page
      const keys = Object.keys(items);
      currentlySelected.forEach(currSelection => {
        if (!_.includes(keys, currSelection)) {
          // not currently visible, preserve selection
          selected.add(currSelection);
        }
      });
    }

    return Array.from(
      Object.entries(items).reduce((selectedItems, curr) => {
        if (curr[1]) {
          selectedItems.add(curr[0]);
        }
        return selectedItems;
      }, selected),
    );
  }
  _changeFilter(items) {
    const f = { ...this.props.filter };
    const operands = this._getSelectedItemList(items);
    f.expression.left = new Condition(IN_LIST.key, operands);
    f.info = {
      total: Object.keys(items).length,
      selected: operands.length,
      allSelected: this.props.allSelected,
    };
    this.props.changeFilter(f);
  }

  updateFilterInfo(items) {
    const f = { ...this.props.filter };
    const operands = this._getSelectedItemList(items);
    f.info = {
      total: Object.keys(items).length,
      selected: operands.length,
      allSelected: this.props.allSelected,
    };
    this.props.changeFilter(f);
  }

  getItemsWithSelectionSet(isChecked) {
    return mapValues(this.state.items, () => {
      return !!isChecked;
    });
  }

  performSearch(search) {
    const { items } = this.state;
    const list = this.props.queryResults;
    if (search.length <= 0) {
      return this.setState(() => {
        return { rows: list, items };
      });
    }

    const searchLowered = _.toLower(search);
    const rows = _.filter(
      list,
      _.flow(_.head, _.toLower, str => _.includes(str, searchLowered)),
    );

    this.setState(() => {
      return { rows, items, searchAgain: false };
    });
  }

  renderCheckbox({ index, key, style, search }) {
    const getSelectAllRow = () => {
      if (search && search.length > 0) {
        return messages.filters.selectAllSearchResults;
      } else {
        return messages.filters.selectAll;
      }
    };

    const getMoreResults = () => {
      const rows = this.state.rows ? this.state.rows : [];
      if (
        search &&
        search.length > 0 &&
        rows.length === 0 &&
        this.props.isLimitedResult
      ) {
        return (
          <a href='#' onClick={() => this.getMoreResults()}>
            {messages.filters.getMoreResults}
          </a>
        );
      } else {
        return (
          <a href='#' onClick={() => this.getMoreResults()}>
            {messages.formatString(
              messages.filters.getMoreResultsPaginated,
              rows.length,
              this.props.resultCount,
              PAGE_SIZE,
            )}
          </a>
        );
      }
    };

    if (
      search &&
      search.length > 0 &&
      this.state.rows.length === 0 &&
      this.props.isLimitedResult
    ) {
      return (
        <a href='#' onClick={() => this.getMoreResults()}>
          {messages.formatString(
            messages.filters.noResultsPaginated,
            this.props.resultCount,
            PAGE_SIZE,
          )}
        </a>
      );
    } else if (index === 0 && this.state.rows.length > 0) {
      return (
        <div key={key} style={style}>
          <CorvanaCheckbox
            checked={this.state.selectAllChecked}
            value='selectAll'
            onChange={e => {
              this.onSelectAll(e);
            }}
          >
            {getSelectAllRow()}
          </CorvanaCheckbox>
        </div>
      );
    } else if (index > this.state.rows.length) {
      return (
        <div key={key} style={style}>
          {getMoreResults()}
        </div>
      );
    }

    try {
      const [itemName] = this.state.rows[index - 1];
      const isChecked = this.state.items[itemName];
      return (
        <div key={key} style={style}>
          <CorvanaCheckbox
            key={`check-${key}`}
            checked={isChecked}
            value={itemName}
            onChange={e => {
              this.onItemChanged(e);
            }}
          >
            {itemName === NULL_TOKEN ? NULL_DISPLAY : itemName}
          </CorvanaCheckbox>
        </div>
      );
    } catch (e) {
      return null;
    }
  }
  render() {
    const rows = this.state.rows ? this.state.rows : [];
    let rowCount = rows.length ? rows.length + 1 : 0;
    if (this.props.isLimitedResult) {
      // assume there are more results
      rowCount++;
    }
    const { search } = this.props;
    if (this.props.error) {
      return <div className='error'>{this.props.error}</div>;
    } else if (
      this.props.loading &&
      this.props.filter.subType === StringFilterSubTypes.SELECT_ITEMS
    ) {
      return (
        <LoaderWrapper>
          <SkeletonListLoader items={14} />
        </LoaderWrapper>
      );
    }
    if (rowCount <= 0) {
      return <div className='no-matches'>{messages.filters.noMatches}</div>;
    }
    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            width={width}
            rowHeight={20}
            rowCount={rowCount}
            rowRenderer={({ index, key, style }) => {
              return this.renderCheckbox({ index, key, style, search });
            }}
          />
        )}
      </AutoSizer>
    );
  }

  getMoreResults() {
    const viz = { ...this.props.viz };
    const prevResults = this.props.queryResults || [];
    let calcs = [];

    if (this.props.field.attributeType === 'STRING_CALC') {
      calcs = this.props.vizCalcs.map(c => {
        if (c.attributeType === 'PRIOR_PERIOD_CALC') {
          return {
            attributeName: c.name,
            expression: Viz.generatePriorPeriodCalcFormula(c, this.props.viz),
          };
        }
        return {
          attributeName: c.name,
          expression: c.formula,
        };
      });
    }
    let variables = {
      useFiscalCalendar: this.props.useFiscalCalendar,
      id: this.props.datasetId,
      attributeNames: [this.props.field.name],
      measures: [],
      filters: [],
      calcs,
      limit: PAGE_SIZE,
      offset: this.props.resultCount,
    };
    if (this.props.applyCurrentVizFilters) {
      const ignoreSlicerFilterNames = _.map(
        Viz.getFiltersFromSlicers(viz),
        'name',
      );
      let filters = _.get(
        Viz.mapFiltersToQuery(viz, ignoreSlicerFilterNames),
        'filters',
        [],
      );
      // ignore this filter
      filters = _.reject(filters, {
        attributeName: this.props.field.name,
      });
      // apply current calcs
      const currentCalcs = _.get(Viz.mapCalcsToQuery(viz), 'calcs', []);
      variables = {
        ...variables,
        filters,
        calcs: _.uniqBy([...calcs, ...currentCalcs], 'attributeName'),
      };
    }

    const queryOptions = {
      fetchPolicy: 'network-only' as FetchPolicy,
      query: VizQueries.VizQuery,
      variables,
    };
    return client.query(queryOptions).then(({ data }: any) => {
      if (data?.error) {
        console.error(data.error);
        this.props.setQueryResults([]);
        this.props.setLoading(false);
      } else if (data?.executeQuery) {
        this.props.setQueryResults([
          ...prevResults,
          ...data.executeQuery.results,
        ]);
        this.props.setLoading(data.loading);
      } else {
        this.props.setLoading(true);
      }
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const discovery = state.discover.openDiscoveries[ownProps.vizId].present;
  let { filter } = ownProps;
  const emptyFilter = createSelectItemsFilter(ownProps.field);
  if (_.isEmpty(filter)) {
    filter = emptyFilter;
  }
  const useFiscalCalendar =
    VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting(state, {}) &&
    VIZ_SELECTORS.getActiveVizFiscalSetting(state, {}) === 'true';
  return {
    useFiscalCalendar,
    datasetId: discovery.dataset.id,
    filter: { ...filter },
    viz: discovery.viz,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeFilter(filter) {
      if (!_.isEqual(ownProps.filter, filter)) {
        dispatch(Discover.setActiveFieldFilter(filter));
      }
    },
  };
};

export default compose<IPropTypes, any>(
  connect(mapStateToProps, mapDispatchToProps),
  shouldUpdate(
    (props: IPropTypes, nextProps: IPropTypes) =>
      !_.isEqual(props.filter, nextProps.filter) ||
      !_.isEqual(props.search, nextProps.search),
  ),
  withState('queryResults', 'setQueryResults', null),
  withState('loading', 'setLoading', true),
  withProps((props: IPropTypes) => {
    const selectedDiff =
      _.get(props, 'filter.info.total', 0) -
      _.get(props, 'filter.info.selected', 0);

    const allSelected =
      selectedDiff === 0 && _.get(props, 'filter.info.allSelected');

    const operands = _.get(props, 'filter.expression.left.operands', []);
    const filterSelectedItems = _.reduce(
      operands,
      (accum, curr) => {
        accum[curr] = true;
        return accum;
      },
      {},
    );
    let selectedCount = 0;
    let totalCount = 0;

    const isSelectedInFilter = item => {
      if (_.isEmpty(operands) && allSelected) {
        return true;
      }
      const isSelected = operands[item];

      if (isSelected || !_.isNil(filterSelectedItems[item])) {
        // if there aren't any operands, select them all
        return true;
      }
    };
    if (!props.queryResults) {
      return {
        selectedItems: [],
      };
    } else if (props.queryResults.error) {
      return {
        selectedItems: [],
        error: props.queryResults.error.message,
      };
    }
    // @NOTE: queryResults is an array of arrays
    const selectedItems = props.queryResults.reduce((items, [item]) => {
      items[item] = isSelectedInFilter(item);
      if (items[item]) {
        selectedCount++;
      }
      totalCount++;
      return items;
    }, {});
    return {
      selectedItems,
      allSelected:
        selectedCount === totalCount
          ? true
          : selectedCount === 0
          ? false
          : null,
      resultCount: props.queryResults.length,
      isLimitedResult:
        props.queryResults.length % PAGE_SIZE ===
        0 /* not precise, but good enough */,
    };
  }),
)(SelectStringItems);
