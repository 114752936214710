import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  IDiscoverEmotionTheme,
  useDiscoverTheme,
} from '../../common/emotion/theme/discover-emotion-theme-provider.component';

export const KabobIconContainerStyled = styled.div(() => {
  const discoverTheme = useDiscoverTheme();
  const { darkMode, colors: { PanelNavBackground, BaseWhite } = {} } =
    discoverTheme || ({} as IDiscoverEmotionTheme);
  return css`
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;

    &:hover {
      background-color: ${PanelNavBackground};
      & > .sicon {
        color: ${darkMode && BaseWhite};
      }
    }
  `;
});

export const iconDropdownStyles = ({
  theme: {
    darkMode,
    colors: { Gray30, Gray40, Gray60, Gray90, LightBlue },
  },
}) => css`
  .kabob-dropdown-menu {
    .MuiPaper-root {
      border-radius: 8px;
    }
    .MuiMenuItem-root {
      border-bottom: 0;
      font-size: 12px;
      font-weight: 300;
      height: 34px;
      line-height: 34px;
      color: ${Gray90};

      &:hover {
        background-color: ${LightBlue};
      }

      & > .sicon {
        padding-right: 6px;
      }
    }
    .MuiMenuItem-root.MuiMenuItem-divider {
      border-bottom: 1px;
    }
    .MuiDivider-root {
      border-color: ${Gray30};
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
    .MuiMenuItem-root.Mui-disabled {
      cursor: not-allowed;
      opacity: 1;
      pointer-events: all;
      color: ${darkMode ? Gray60 : Gray40};
      &:hover {
        background-color: transparent;
      }
    }
  }
`;
