import { Redirect } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { IVizRedirect } from './viz-redirect.interfaces';
import { IReportDirectBase } from '../../discovery/viz-redirect';

export const VizRedirect = (props: IVizRedirect) => {
  const toProp: LocationDescriptor<IReportDirectBase> = {
    pathname: `/open/${props.vizId}`,
  };

  if (props?.state) {
    toProp.state = props.state;
  }
  return <Redirect to={toProp} />;
};
