import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@sugar-discover/react-bootstrap-wrapper';
import { isEmpty, isEqual, noop } from 'lodash';

export class GhostInput extends Component {
  get hasChanged() {
    if (isEmpty(this.state.value) || isEmpty(this.state.previousValue)) {
      return isEmpty(this.state.value) !== isEmpty(this.state.previousValue);
    }
    return this.state.value !== this.state.previousValue;
  }
  constructor(props) {
    super(props);
    this.state = {
      previousValue: props.value,
      value: props.value,
      defaultValue: !isEmpty(props.defaultValue) ? props.defaultValue : '', // not be changed dynamically after construction
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.disabled, this.props.disabled) ||
      !isEqual(prevProps.value, this.props.value) // assigned value has changed
    ) {
      this.setState({
        value: this.props.value,
        previousValue: this.props.value,
      });
    }
  }
  onChange(value) {
    this.setState({
      value,
    });
  }
  onBlur() {
    if (isEmpty(this.state.value) && !isEmpty(this.state.defaultValue)) {
      this.onChange(this.state.defaultValue); // set to default value if left empty
      this.props.onChange(this.state.defaultValue);
    } else if (this.hasChanged) {
      // Until we have more advanced validation here, it probably just makes sense
      // to let the user set whatever value they would like, and deal with the
      // consequences later. YOLO!
      this.props.onChange(this.state.value);
    }
  }
  render() {
    return (
      <FormControl
        value={this.state.value}
        title={this.props.title}
        disabled={this.props.disabled}
        onFocus={e => e.target.select()}
        onChange={e => this.onChange(e.target.value)}
        onKeyUp={e => {
          // change on enter
          if (isEqual(e.keyCode, 13)) {
            this.onChange(e.target.value);
            e.target.blur();
          }
        }}
        onBlur={() => this.onBlur()}
      />
    );
  }
}

GhostInput.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

GhostInput.defaultProps = {
  value: '',
  defaultValue: '',
  onChange: noop,
  disabled: false,
};
