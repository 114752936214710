import { css } from '@emotion/react';
import { font, truncateLines, hbox, backgroundImage } from './mixins';

export const insightsStyles = ({
  appUrl,
  theme: {
    isDashletUser,
    colors: {
      ContentBackground,
      MediumBorder,
      PanelBorder,
      RegularFontWeight,
      ContentText,
      negativeColor,
      StrongFontWeight,
      CorvanaMediumBorder,
      ActiveLinkText,
    },
  },
}) => css`
  .insight-item {
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 280px;
    display: block;
    background: ${ContentBackground};
    -webkit-box-shadow: 0px 2px 6px 0px ${MediumBorder};
    -moz-box-shadow: 0px 2px 6px 0px ${MediumBorder};
    box-shadow: 0px 2px 6px 0px ${MediumBorder};
    border: 1px solid ${PanelBorder};
    padding: 8px 12px;
  }
  .insight-item.excluded {
    background: pink;
  }
  .insight-item .feed-row-left {
    display: flex;
    flex-direction: column-reverse;
  }
  .insight-item .percent-changed-card,
  .insight-item .weighted-forecast,
  .insight-item .top-3-insight {
    width: 100%;
    border: none;
    padding: 0px;
    align-items: center;
  }
  .insight-item .percent-changed-card .title,
  .insight-item .weighted-forecast .title,
  .insight-item .top-3-insight .title {
    ${font({
      size: '14px',
      weight: RegularFontWeight,
      color: ContentText,
    })}
    text-align: left;
    width: 100%;
    ${truncateLines({ lines: '2' })}
  }
  .insight-item .percent-changed-card .title > svg,
  .insight-item .weighted-forecast .title > svg,
  .insight-item .top-3-insight .title > svg {
    margin-bottom: -2px;
    margin-right: 6px;
    display: inline;
  }
  .insight-item .percent-changed-card > div.hbox,
  .insight-item .weighted-forecast > div.hbox,
  .insight-item .top-3-insight > div.hbox {
    justify-content: center;
    width: 205px;
    margin-bottom: 6px;
  }
  .insight-item .percent-changed-card > div.card-body {
    ${hbox()}
    ${font({ color: ContentText })}
    flex-direction: row-reverse;
    width: 100%;
  }
  .insight-item .percent-changed-card > div.card-body .area-chart {
    flex: 1;
    margin-right: 8px;
  }
  .insight-item .score {
    min-width: 88px;
  }
  .insight-item .score .peek-score {
    border-radius: 10px;
    border: 1px solid ${negativeColor};
    color: ${negativeColor};
    text-align: center;
    vertical-align: middle;
    padding: 2px 7px 0px 7px;
    margin-right: auto;
    min-height: 64px;
    min-width: 74px;
  }
  .insight-item .score .peek-score .score-title {
    ${font({ size: '12px', weight: StrongFontWeight })}
  }
  .insight-item .score .peek-score .score-value {
    ${font({ size: '42px' })}
    margin-top: -8px;
  }
  .insight-item .duration {
    margin-left: 6px;
    border: 1px solid ${ContentBackground};
    min-height: 64px;
    min-width: 92px;
  }
  .insight-item .duration .duration-title {
    ${font({ weight: StrongFontWeight })}
  }
  .insight-item .duration .duration-value {
    margin-top: -8px;
  }
  .insight-item .duration .duration-value .val {
    ${font({ size: '42px' })}
  }
  .insight-item .duration .duration-value .time-unit {
    ${font({ size: '9px' })}
  }
  .insight-item .start-end::before {
    content: '';
    ${backgroundImage({ appUrl, path: 'start_to_stop.svg' })}
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    width: 12px;
    height: 50px;
    margin-left: -12px;
    margin-top: 4px;
  }
  .insight-item .start-end {
    ${font({ size: '12px' })}
    margin-left: 12px;
    display: inline-block;
    position: relative;
  }
  .insight-item .start-end .time-icon {
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
  }
  .insight-item .start-end .time-info {
    display: inline-block;
    vertical-align: top;
    margin-left: 4px;
  }
  .insight-item .start-end .time-info .time-title {
    ${font({ size: '9px', weight: StrongFontWeight })}
  }
  .insight-item .start-end .time-info .time-value {
    ${font({ size: '9px' })}
    margin-left: 4px;
  }
  .insight-item .start-end .time-info .date {
    ${font({ size: '9px' })}
  }
  .insight-item .start-end .time-end {
    margin-top: 9px;
  }
  .insight-item .start-end > span {
    margin: 0px 6px;
  }
  .insight-item .actions {
    border-top: 1px solid ${CorvanaMediumBorder};
    margin-top: 8px;
    padding-top: 8px;
  }
  .insight-item .feedback-container {
    ${hbox()}
    justify-content: flex-end;
    align-items: center;
  }
  .insight-item .feedback-container .info-icon {
    height: 16px;
  }
  ${isDashletUser
    ? `.insight-item {
    pointer-events: none;
    cursor: default;
  }`
    : `.insight-item:hover {
    transform: scale(1.01);
    cursor: pointer;
    border: 1px solid ${ActiveLinkText};
  }`}
  .insight-enter {
    opacity: 0;
    transform: scaleY(0);
    height: 0px;
  }
  .insight-enter.insight-enter-active {
    opacity: 1;
    height: 183px;
    transition: all 0.6s ease-in;
    transform: scaleY(1);
  }
  .insight-leave {
    opacity: 1;
    transform: scaleY(1);
  }
  .insight-leave.example-leave-active {
    opacity: 0;
    transition: all 600ms ease-in;
    transform: scaleY(0);
  }
  .insight-appear {
    opacity: 0.01;
  }
  .insight-appear.example-appear-active {
    opacity: 1;
    transition: opacity 300ms ease-out;
  }
`;
