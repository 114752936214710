import _ from 'lodash';
import moment from '../../../common/Moment';
import { Hierarchy, Viz } from '../../VizUtil';
import { isTimeType, join as joinChartData } from '../ChartUtils';
import ChartUtils from '../ChartUtils';
import { NULL_DISPLAY, NULL_TOKEN, Types } from '../../../common/Constants';
import { SortFunctionManager } from '../../../common/redux/selectors/viz-selectors';
import { ShelfTypes } from '../../../discovery/interfaces';
import { ChartSpecs } from '../../ChartSpecs';
import { sortLegendData } from '../../LegendUtils';
import { IPreFilterOperand } from '../../../datasets/interfaces';

export const LineChartUtils = {
  /**
   * Given the query result produces the following structure:
   *
   * {
   *   AXIS : []                Array of XAxis labels
   *   LINES: [
   *      [
   *        pathInfo: {
   *          lines: [],
   *          valueName: []
   *        }
   *        {x, y, axises}...   Line Array instances
   *      ]
   *
   *   ]
   * }
   *
   * Given a query like the following
   *
   * {
   *    LINES: [TERRITORY]    North, South
   *    XAXIS: [MONTHS]
   *    VALUES: [SALES, MRR]
   * }
   *
   * Produces this result:
   *
   * {
   *    AXIS : [1,2,3,4,5,6,7,8,9,10,11,12]
   *    LINES: [
   *      [
   *        pathInfo: { lines: ["North"], valueName: "Sales"}
   *        {x: "January", y: 21324, axises: ["January"]},
   *        {x: "February", y: 45435, axises: ["February"]},
   *        ...
   *      ],
   *    ]
   * }
   *
   * @param queryResults
   * @param layout
   * @returns {{AXIS: Array, LINES: {}}}
   */
  transformResult: (
    queryResults,
    viz,
    valueName = 'VALUES',
    xAxisName = 'XAXIS',
    linesName = 'LINES',
    i18nPrefs = {},
  ) => {
    const chartType = _.get(viz, 'chartType');
    if (_.isNil(chartType)) {
      viz.chartType = 'line';
    }

    const { layout } = viz;
    const vizFilters = Viz.getFiltersFromViz(viz);
    const vizCalcs = Viz.getCalcsFromViz(viz);

    const data = { AXIS: [], LINES: [], layout };

    let axisList = [];

    const lines = {};

    const reportDetailInfo = {
      filters: vizFilters,
      calcs: vizCalcs,
      metrics: [...layout[valueName]],
      linkToReport: JSON.parse(_.get(viz, 'options.linkToReport', '{}')),
    };

    const { columnInfo, results } = queryResults.executeQuery;

    data.reportDetailInfo = reportDetailInfo;

    const dataFormatters = Viz.getDataFormatters(viz);
    const customDataFormatters = Viz.getDataCustomFormatters(viz);

    const axisLength = layout[xAxisName] ? layout[xAxisName].length : 0;
    const linesLength = layout[linesName] ? layout[linesName].length : 0;

    const chartSpec = ChartSpecs[viz.chartType];
    const metricShelves = _.values(chartSpec.shelves).filter(
      s => s.shelfType === ShelfTypes.MEASURE,
    );
    const metricValuesLength = _.toPairs(layout)
      .filter(([shelfId]) => {
        return _.some(metricShelves, { id: shelfId });
      })
      .reduce((total, [, shelfLayout]) => {
        return total + shelfLayout.length;
      }, 0);

    const ordinalMappings = Viz.getOrdinalMappings(viz, queryResults);

    // viz-selectors can sometimes add an aggregation column.
    const totalOriginalColumns = columnInfo.filter(
      ci => !ci.isClientSideAggregation,
    ).length;

    layout[valueName].forEach((valueField, idx) => {
      axisList = [];

      const yIndex = columnInfo.findIndex(
        info =>
          info.attributeName?.toUpperCase() === valueField.name?.toUpperCase(),
      );
      const xAxisPositions = _.range(0, axisLength);
      const formatter = dataFormatters[valueField.name];

      results.forEach(row => {
        const linesArray = row.slice(axisLength, linesLength + axisLength);
        const linesData = linesArray.map((linePart, lineIdx) => {
          const colIndex = lineIdx + axisLength;
          const sortValue =
            colIndex > ordinalMappings.length - 1
              ? linePart
              : row[ordinalMappings[colIndex].ordinalIndex];
          const sortInt = _.parseInt(sortValue);
          const { attributeName } = columnInfo[colIndex];
          const attributesInPlay = Viz.getAllFieldsInPlay(layout);
          const isDateCalc =
            _.find(attributesInPlay, { name: attributeName })?.timeAttribute
              ?.calcFunction === 'date';
          return {
            attributeName,
            displayValue: linePart,
            sortValue: !_.isNaN(sortInt) && !isDateCalc ? sortInt : sortValue,
          };
        });

        const axisArray = row.slice(0, axisLength);

        // we might have an extra metric added by the global sort, handle that scenario
        const hasAxisMetricAggregation =
          totalOriginalColumns === row.length - 1;
        let axisMetricAggregation = hasAxisMetricAggregation
          ? row[row.length - 1]
          : 0;
        const axisMetrics = row.slice(
          row.length - metricValuesLength - (hasAxisMetricAggregation ? 1 : 0),
          hasAxisMetricAggregation ? row.length - 1 : row.length,
        );

        if (_.isNaN(axisMetricAggregation)) {
          axisMetricAggregation = 0;
        }
        const axisData = axisArray.map((axisPart, lineIdx) => {
          const { attributeName } = columnInfo[lineIdx];
          const sortValue = row[ordinalMappings[lineIdx].ordinalIndex];

          // parseInt will convert a date string into a inconsistent number e.g. '2022/09/12' -> 2022
          const sortInt = _.parseInt(sortValue);
          const attributesInPlay = Viz.getAllFieldsInPlay(layout);
          const isDateCalc =
            _.find(attributesInPlay, { name: attributeName })?.timeAttribute
              ?.calcFunction === 'date';

          return {
            attributeName,
            displayValue: axisPart,
            sortValue: !_.isNaN(sortInt) && !isDateCalc ? sortInt : sortValue,
            axisMetricAggregation,
            axisMetrics,
          };
        });

        const groupName = joinChartData(linesArray);

        let line = lines[`${groupName}_${idx}`];
        if (!line) {
          line = lines[`${groupName}_${idx}`] = [];
          line.pathInfo = {
            lines: linesArray,
            linesData,
            valueName: valueField.name,
          };
        }

        let axises = ['']; // Default value of axis in the case where we have no XAxis (just dots)
        if (xAxisPositions.length > 0) {
          // We have an axis, get value
          axises = xAxisPositions.map(x => {
            const field = layout[xAxisName][x];
            if (!_.isNil(field)) {
              const axisDataField =
                axisData.find(ad => ad.attributeName === field.name) || {};
              return {
                value: ChartUtils.formatValue(
                  dataFormatters,
                  customDataFormatters,
                  i18nPrefs,
                  field.name,
                  row[x],
                  _.isString(row[x]),
                ),
                type: field.attributeType,
                ...axisDataField,
              };
            } else {
              return { value: row[x], type: null };
            }
          });
          // record XAxis value in array
          axisList[joinChartData(axises)] = axises;
        }
        // Push data into line array
        const yVal = row[yIndex];
        const xVal = joinChartData(axises);
        line.push({
          x: xVal,
          y: yVal,
          axises,
          formatter,
          axisData,
          axisMetricAggregation,
        });

        lines[`${groupName}_${idx}`] = line; // Javascript magic is afoot. Adding this line for readability
      });
    });

    // we will need to find any missing entries along the x axis and fill in null data
    // get the unique x-axis ticks
    const uniqueXaxisItems = _.values(lines).reduce((uniq, lineData) => {
      lineData.forEach(d => {
        uniq[d.x] = { ...d, y: NULL_DISPLAY, targetValue: null };
      });
      return uniq;
    }, {});

    const neededXaxisVals = _.keys(uniqueXaxisItems);

    _.toPairs(lines)
      .filter(([, l]) => l.length < _.keys(uniqueXaxisItems).length)
      .forEach(([key, l]) => {
        const knownXaxisVals = l.map(d => d.x);
        const missingXaxis = _.difference(neededXaxisVals, knownXaxisVals);
        const before = { ...lines[key] };
        lines[key] = [
          ...lines[key],
          ...missingXaxis.map(m => uniqueXaxisItems[m]),
        ];
        lines[key].pathInfo = before.pathInfo;
      });

    data.LINES = Object.values(lines);
    data.AXIS =
      Object.values(axisList).length > 0
        ? Object.values(axisList)
        : [[{ value: '', attributeType: 'String' }]];
    return data;
  },

  transformResultToArea: (
    queryResults,
    layout,
    valueName = 'VALUES',
    xAxisName = 'XAXIS',
    linesName = 'LINES',
  ) => {
    const data = { AXIS: [], LINES: [], layout };

    let axisList = [];

    const lines = {};

    for (let idx = 0; idx < layout[valueName].length - 1; idx += 2) {
      const valueField = layout[valueName][idx];

      axisList = [];
      const axisLength = layout[xAxisName] ? layout[xAxisName].length : 0;
      const linesLength = layout[linesName] ? layout[linesName].length : 0;
      const xAxisPositions = _.range(0, axisLength);

      queryResults.executeQuery.results.forEach(row => {
        const linesArray = row.slice(axisLength, linesLength + axisLength);
        const groupName = joinChartData(linesArray);

        let line = lines[`${groupName}_${idx}`];
        if (!line) {
          line = lines[`${groupName}_${idx}`] = [];
          line.pathInfo = {
            lines: linesArray,
            valueName: valueField.name,
          };
        }

        let axises = ['']; // Default value of axis in the case where we have no XAxis (just dots)
        if (xAxisPositions.length > 0) {
          // We have an axis, get value
          axises = xAxisPositions.map(x => {
            const field = layout[xAxisName][x];
            if (!_.isNil(field)) {
              // Format field data based on attribute type
              if (field.attributeType === 'TIMESTAMP') {
                return {
                  value: moment(row[x]).format(),
                  type: field.attributeType,
                };
              } else {
                return { value: row[x], type: field.attributeType };
              }
            } else {
              return { value: row[x], type: null };
            }
          });
          // record XAxis value in array
          axisList[joinChartData(axises)] = axises;
        }
        // Push data into line array
        const yVal = row[axisLength + linesLength + idx];
        const yVal2 = row[axisLength + linesLength + idx + 1];
        const xVal = joinChartData(axises);
        line.push({
          x: xVal,
          y: yVal === NULL_DISPLAY ? 0 : yVal,
          y1: yVal2 === NULL_DISPLAY ? 0 : yVal2,
          axises,
        });
      });
    }
    data.LINES = Object.values(lines);
    data.AXIS =
      Object.values(axisList).length > 0
        ? Object.values(axisList)
        : [[{ value: '', attributeType: 'String' }]];
    return data;
  },

  getDefaultXaxisSort: (viz, priority, xAxisName = 'XAXIS') => {
    // if the first field is a time calc, gather all time calcs in the same shelf and create sorts for them in the right order
    const firstField = viz.layout[xAxisName][0];
    let defaultSortFields = [firstField];
    if (firstField.attributeType === Types.TIME_CALC) {
      const timeIndexes = _.values(Hierarchy.TIME_ATTRIBUTES).reduce(
        (accum, th) => {
          accum[th.key] = th.order;
          return accum;
        },
        {},
      );
      defaultSortFields = viz.layout[xAxisName].filter(
        field =>
          field.attributeType === Types.TIME_CALC &&
          field.parentField.name === firstField.parentField.name,
      );

      defaultSortFields = _.sortBy(defaultSortFields, f => {
        return timeIndexes[f.timeAttribute.key];
      });
    }
    const xaxisSorts = defaultSortFields.map((field, idx) => {
      return {
        shelfName: 'X-Axis',
        fieldName: field.name,
        priority: priority + idx,
        direction: 'asc',
      };
    });
    return xaxisSorts;
  },

  sortData: (
    lineData,
    viz,
    querySort,
    valueName = 'VALUES',
    xAxisName = 'XAXIS',
    linesName = 'LINES',
  ) => {
    // if there are any sorts applied to the fields in the Lines shelf, order the LINES accordingly
    if (_.isNil(querySort)) {
      // grab it from the viz if we weren't given one
      querySort = Viz.getVizOption(viz, 'querySort', {});
    }

    // sort the line point values themselves

    let xaxisSorts = _.values(querySort).filter(
      s => s.shelfName.toUpperCase() !== linesName,
    );
    const metricSorts = _.values(querySort).filter(s =>
      _.includes(s.shelfName.toUpperCase(), 'METRICS'),
    );
    const lineSorts = _.values(querySort).filter(
      s => s.shelfName.toUpperCase() === linesName,
    );

    const chartSpec = ChartSpecs[viz.chartType];

    // no guarantee of order, so sort it since we depend on order for offset below
    const metricShelves = _.sortBy(
      _.values(chartSpec.shelves).filter(
        s => s.shelfType === ShelfTypes.MEASURE,
      ),
      'id',
    );

    //
    // If we don't have an xaxis sort specified, sort by the first item in the x-axis shelf.
    // If the first item is a time field, sort by all time fields in time order (year, month, day, ...).
    //
    // OR, if there are no xaxis sorts and we have a metrics sort, add the default sort as a secondary sort
    // to ensure the axis and the data line up.
    //
    if (
      (_.isEmpty(xaxisSorts) || xaxisSorts.length === metricSorts.length) &&
      !_.isEmpty(viz.layout[xAxisName])
    ) {
      const priority = _.isEmpty(xaxisSorts)
        ? 0
        : Math.max(xaxisSorts.map(s => s.priority)) + 1;
      const xSorts = LineChartUtils.getDefaultXaxisSort(
        viz,
        priority,
        xAxisName,
      );
      xaxisSorts = [...xaxisSorts, ...xSorts];
    }

    const getMetricSortVal = (axisData, sort) => {
      // Sort by the single metric value selected if there are no fields in LINES,
      // Otherwise, use the aggregation of all points along this same xaxis val if there are fields in LINES
      if (_.isEmpty(viz.layout[linesName]) || linesName === 'NONE') {
        let sortMetricFieldIndex = viz.layout[valueName].findIndex(
          f => f.name === sort.fieldName,
        );
        const otherValueShevles = metricShelves.filter(s => s.id !== valueName);

        // if it wasn't in the line metric shelf, it could be a combo chart. In that case, a sort on that metric is still required
        if (sortMetricFieldIndex < 0 && metricShelves.length > 1) {
          if (!_.isEmpty(otherValueShevles)) {
            // if this is a stacked line chart and the sort is for a field in the non-line metric shelf, use the aggregate
            if (chartSpec.id === 'stack_line') {
              return axisData.axisMetricAggregation;
            }

            // if the metric shelf is not the first metric shelf, use the offset of the length of the first metric shelf
            const useOffset =
              _.findIndex(metricShelves, { id: otherValueShevles[0].id }) > 0;
            const indexOffset = useOffset ? viz.layout[valueName].length : 0;
            sortMetricFieldIndex =
              viz.layout[otherValueShevles[0].id].findIndex(
                f => f.name === sort.fieldName,
              ) + indexOffset;
          }
        } else if (!_.isEmpty(otherValueShevles)) {
          const useOffset = _.findIndex(metricShelves, { id: valueName }) > 0;
          const indexOffset = useOffset
            ? viz.layout[otherValueShevles[0].id].length
            : 0;
          sortMetricFieldIndex =
            viz.layout[valueName].findIndex(f => f.name === sort.fieldName) +
            indexOffset;
        }

        const val = axisData.axisMetrics[sortMetricFieldIndex];
        return val === NULL_DISPLAY ? 0 : val;
      } else {
        return axisData.axisMetricAggregation;
      }
    };

    if (!_.isEmpty(xaxisSorts)) {
      const sortManager = new SortFunctionManager();
      xaxisSorts.forEach(sort => {
        const sortFunction = linePoint => {
          const sortField = linePoint.axisData.find(
            d => d.attributeName === sort.fieldName,
          );
          if (_.includes(sort.shelfName.toUpperCase(), 'METRICS')) {
            return getMetricSortVal(linePoint.axisData[0], sort);
          } else {
            const sortVal = _.get(sortField, 'sortValue', '');
            // treat nulls the same as the sort in viz-selectors treats them (as 0)
            return sortVal === NULL_DISPLAY ? 0 : sortVal;
          }
        };
        sortManager.addFunc(sortFunction, sort.direction, sort.priority);
      });

      // sort each line's points along the axis
      lineData.LINES.forEach((ld, idx) => {
        const pathInfo = { ...ld.pathInfo };
        const sortedLine = sortManager.order(ld);
        lineData.LINES[idx] = sortedLine;
        lineData.LINES[idx].pathInfo = pathInfo;
      });

      // Sort the axis the same as the line values to keep them in sync
      const axisSortManager = new SortFunctionManager();
      xaxisSorts.forEach(sort => {
        const sortFunction = axis => {
          const sortField = axis.find(d => d.attributeName === sort.fieldName);
          if (_.includes(sort.shelfName.toUpperCase(), 'METRICS')) {
            return getMetricSortVal(axis[0], sort);
          } else {
            const sortVal = _.get(sortField, 'sortValue', '');
            // treat nulls the same as the sort in viz-selectors treats them (as 0)
            return sortVal === NULL_DISPLAY ? 0 : sortVal;
          }
        };
        axisSortManager.addFunc(sortFunction, sort.direction, sort.priority);
      });
      const sortedAxis = axisSortManager.order(lineData.AXIS);
      lineData.AXIS = sortedAxis;
    }

    // sort the lines themselves. effectively just sorting the legend and color palette
    // TODO: [DSC-3532] This block of code does not seem to do anything related
    // to lines legends -- we should figure out what is going on here and either
    // update or remove it
    if (!_.isEmpty(lineSorts)) {
      const sortManager = new SortFunctionManager();
      lineSorts.forEach(sort => {
        const sortFunction = line => {
          const lineField = line.pathInfo.linesData.find(
            ld => ld.attributeName === sort.fieldName,
          );
          return _.get(lineField, 'sortValue', '');
        };
        // invert the sort priority. they are operated on in ascending order but the priority is assigned incrementally as the user toggles sorting
        sortManager.addFunc(sortFunction, sort.direction, -sort.priority);
      });

      const sortedData = sortManager.order(lineData.LINES);
      lineData.LINES = sortedData;
    }

    return lineData;
  },

  // CollectLegend also used to format the "global" tooltip by way of withXValAt
  // TODO: [DSC-3532] This should be refactored to centralize legend sorting
  collectLegend: (
    data,
    xScale,
    withXValAt,
    layout,
    layoutValueName = 'VALUES',
    querySort = {},
  ) => {
    const legend = [];
    data.LINES.forEach(line => {
      const origName = joinChartData(line.pathInfo.lines);
      let name = origName;
      if (data.layout[layoutValueName].length > 0) {
        if (name.length === 0) {
          name = line.pathInfo.valueName;
        } else if (
          layoutValueName === 'VALUES' &&
          data.layout[layoutValueName].length > 1
        ) {
          // Append value name if there is more than one value in play
          name = joinChartData([name, line.pathInfo.valueName]);
        }
      }
      const summary = {
        info: line.pathInfo,
        name,
        sort: origName,
        shape: 'LINE',
      };
      if (!_.isNil(withXValAt)) {
        const dataCell = line.find(d => xScale(d.x) === withXValAt);
        summary.value = dataCell ? dataCell.y : null;
      }

      legend.push(summary);
    });
    return sortLegendData(querySort, legend);
  },

  // Currently used to format a tooltip over an individual point on a line
  collectPathTo: (
    data,
    line,
    value,
    axisName = 'XAXIS',
    dataFormatters,
    i18nPrefs = {},
    customFormatProps,
  ) => {
    const pathInfo = {};
    data.LINES.forEach(lne => {
      lne.pathInfo.lines.forEach((l, y) => {
        const lineFormatter = dataFormatters[data.layout.LINES[y].name];
        const customFormat = _.get(
          customFormatProps,
          data.layout.LINES[y].name,
        );
        pathInfo[data.layout.LINES[y].name] = lineFormatter
          ? lineFormatter.format(l, i18nPrefs, customFormat)
          : l;
      });

      const valueFormatter = dataFormatters[lne.pathInfo.valueName];
      const customFormat = _.get(customFormatProps, lne.pathInfo.valueName);
      pathInfo[lne.pathInfo.valueName] = valueFormatter
        ? valueFormatter.format(value.y, i18nPrefs, customFormat)
        : value.y;

      if (data.layout[axisName].length > 0) {
        value.axises.forEach((axisVal, i) => {
          const axisFormatter = dataFormatters[data.layout[axisName][i].name];
          const axisCustomFormat = _.get(
            customFormatProps,
            data.layout[axisName][i].name,
          );
          pathInfo[data.layout[axisName][i].name] = axisFormatter
            ? axisFormatter.format(axisVal.value, i18nPrefs, axisCustomFormat)
            : axisVal.value;
        });
      }
    });
    return pathInfo;
  },

  // Adds 'attributes' to an IReportDetailInfo object
  // The 'attributes' field will be converted to filters in ReportLinkRedirect
  collectDetailInfo: (
    data,
    lineData,
    value,
    axisName = 'XAXIS',
    focusedDataPoints = [],
  ) => {
    const reportDetailInfo = { ...data.reportDetailInfo, attributes: [] };

    let preFilterOperands: IPreFilterOperand[] = reportDetailInfo.attributes;

    if (_.isArray(focusedDataPoints) && !_.isEmpty(focusedDataPoints)) {
      const focusedDataPointLineGroups = _.groupBy(
        focusedDataPoints,
        'pathInfo.linesData[0].attributeName',
      );

      _.forEach(
        _.toPairs(focusedDataPointLineGroups),
        ([attributeName, _linesByAttribute]) => {
          const attribute = _.find(
            data.layout.LINES,
            _attribute => _attribute?.name === attributeName,
          );
          let lineDatumSelector = 'pathInfo.linesData[0].displayValue';

          if (isTimeType(attribute.attributeType)) {
            lineDatumSelector = 'pathInfo.linesData[0].sortValue';
          }

          const filterOperands = _.uniq(
            _.map(_linesByAttribute ?? [], _lineDatum =>
              _.get(_lineDatum, lineDatumSelector),
            ),
          );

          // multi-select drill linking supports field types: STRING, and STRING_CALC
          if (
            _.includes(
              [Types.STRING, Types.STRING_CALC, Types.TIME_CALC],
              attribute.attributeType,
            ) &&
            _linesByAttribute
          ) {
            const _supportedAttributes: IPreFilterOperand[] = _.map(
              filterOperands ?? [],
              _operand => ({
                attribute,
                value: _.isArray(_operand) ? _operand : [_operand],
              }),
            );
            preFilterOperands = _.concat(
              preFilterOperands,
              _supportedAttributes,
            );
          }
        },
      );

      const additionalFilters: IPreFilterOperand[] = LineChartUtils.includeXAxisDetailInfo(
        preFilterOperands,
        data,
        value,
        axisName,
      );

      preFilterOperands = _.concat(preFilterOperands, additionalFilters);
    } else {
      data.LINES.filter(_lne => _.isEqual(_lne, lineData)).forEach(lne => {
        lne?.pathInfo?.lines?.forEach((l, y) => {
          preFilterOperands.push({
            attribute: data.layout.LINES[y],
            value: l,
          });
        });
        preFilterOperands = _.concat(
          preFilterOperands,
          LineChartUtils.includeXAxisDetailInfo(
            preFilterOperands,
            data,
            value,
            axisName,
          ),
        );
      });
    }

    reportDetailInfo.attributes = preFilterOperands;
    return reportDetailInfo;
  },
  includeXAxisDetailInfo(reportDetailAttributes, data, value, axisName) {
    const additionalReportDetailAttributes = [];
    if (data.layout[axisName].length > 0) {
      value.axises.forEach((axisVal, i) => {
        additionalReportDetailAttributes.push({
          attribute: data.layout[axisName][i],
          value: isTimeType(axisVal.type)
            ? [`${axisVal.sortValue}`]
            : [axisVal.value],
        });
      });
    }
    return additionalReportDetailAttributes;
  },

  getX: d => d?.x,
  getY: d => d?.y,
  getX0: ChartUtils.getX0,
  findShelfByFieldName: ChartUtils.findShelfByFieldName,
  getValueField: ChartUtils.getValueField,

  // transverse down to the lines, collect return the aggregated computation passed in (min/max, etc)
  reduceValuesByFunc(data, func, prop) {
    return func(Object.values(data.LINES), l =>
      func(l, ll => {
        const val = ll[prop];
        if (val === NULL_DISPLAY || val === NULL_TOKEN) {
          return 0;
        } else {
          return val;
        }
      }),
    );
  },

  getColorKey: lineDataPathInfo => {
    const colorKey = [
      ..._.get(lineDataPathInfo, 'lines', []),
      _.get(lineDataPathInfo, 'valueName'),
    ];
    return colorKey.join(' | ');
  },

  /**
   * Determines if the y value is null'ish. is actually null, is '__NULL__', or is '-'
   * @param lineData data for the line object
   * @param getYFunc function that gets the y value of the lineData
   * @returns {value is null | undefined | boolean}
   */
  isNull: (lineData, getYFunc = LineChartUtils.getY) => {
    try {
      const yVal = getYFunc(lineData);
      return (
        _.isNil(lineData) ||
        _.isNil(yVal) ||
        _.isNaN(yVal) ||
        yVal === NULL_DISPLAY ||
        yVal === NULL_TOKEN
      );
    } catch (error) {
      return true;
    }
  },

  syncXaxisData: (
    lineQueryResults,
    viz,
    axisInfoToSyncWith,
    otherQueryResults,
  ) => {
    const lineResults = lineQueryResults.executeQuery.results;
    // if the stack has data for axis values that the line data does not, we need to fill it in with nulls

    // get the attribute names that the axisInfoToSycWith tells us it is using
    const xaxisColumnNames = viz.layout.XAXIS.map(x => x.name);
    const axisColumnIndexes = lineQueryResults.executeQuery.columnInfo
      .map(ci => {
        return xaxisColumnNames.findIndex(name => name === ci.attributeName);
      })
      .filter(i => i !== -1);

    const missingAxises = axisInfoToSyncWith.reduce((missing, staxis, pos) => {
      // find the same axis values in the results
      const lineRowForAxis = lineResults.findIndex(lineRow => {
        if (_.isEmpty(staxis.axisValues)) {
          // there are no xaxis values from the original chart
          return true;
        }
        const matches = staxis.axisValues.map((av, idx) => {
          const cellVal = lineRow[axisColumnIndexes[idx]];
          if (
            _.toLower(_.toString(av.value)) === _.toLower(_.toString(cellVal))
          ) {
            return true;
          } else {
            return false;
          }
        });
        const uniqueMatches = _.uniq(matches);
        const allTrue = uniqueMatches.length === 1 && matches[0] === true;
        return allTrue;
      });
      if (lineRowForAxis === -1) {
        missing.push({ position: pos, axisData: staxis });
      }
      return missing;
    }, []);

    const allFields = Viz.getAllAvailableFields(viz);
    // build the missing row data
    missingAxises.forEach(missing => {
      const emptyDataRow = lineQueryResults.executeQuery.columnInfo.map(
        (ci, idx) => {
          if (_.includes(axisColumnIndexes, idx)) {
            // this is an axis column, set the proper value
            return missing.axisData.axisValues[idx]?.value;
          } else {
            // is it a measure, make it null
            if (ci.columnType === 'MEASURE') {
              if (
                ci.columnName === 'sum' &&
                !_.isNil(missing.axisData.axisTotal)
              ) {
                return missing.axisData.axisTotal;
              } else {
                return NULL_DISPLAY;
              }
            } else {
              // could be an ordinal, try to use the real values from the other query data
              const displayField = Viz.getDisplayFieldForOrdinal(
                ci.attributeName,
                allFields,
              );

              if (!_.isNil(displayField)) {
                const displayFieldName = displayField.name;
                // get the ordinal value for this from the original results
                if (!_.isEmpty(otherQueryResults)) {
                  const ordinalFieldIndex = otherQueryResults.executeQuery.columnInfo.findIndex(
                    oci => ci.attributeName === oci.attributeName,
                  );
                  const displayFieldIndex = otherQueryResults.executeQuery.columnInfo.findIndex(
                    oci => displayFieldName === oci.attributeName,
                  );
                  if (ordinalFieldIndex >= 0 && displayFieldIndex >= 0) {
                    const firstMatchingRow = otherQueryResults.executeQuery.results.find(
                      row => {
                        const missingVal = missing.axisData.axisValues.find(
                          av => av.attributeName === displayFieldName,
                        );
                        return row[displayFieldIndex] === missingVal?.value;
                      },
                    );
                    if (!_.isNil(firstMatchingRow)) {
                      return firstMatchingRow[ordinalFieldIndex];
                    }
                  }
                }
                // guess the ordinal value based on position since we couldn't find it in the original data
                return (missing.position + 1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                });
              }
            }
          }
        },
      );
      lineResults.splice(missing.position, 0, emptyDataRow);
    });

    return lineQueryResults;
  },
};
