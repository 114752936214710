import { compose, onlyUpdateForKeys, withPropsOnChange } from 'react-recompose';
import {
  BaseCartesianChart,
  ShouldEnableReportLinkHOC,
} from './base-cartesian-chart';
import { connect } from 'react-redux';
import BarPlot from './plots/BarPlot';
import BarChartUtils from './BarChartUtils';
import NoDataIfHOC from './NoDataIfHOC';

class BarChart extends BaseCartesianChart {
  constructor(props) {
    super(props, [
      {
        shelf: 'VALUES',
        Plot: BarPlot,
        dataId: 'barData',
        TargetPlot: BarPlot,
        targetDataId: 'targetData',
      },
    ]);
  }
}

export default compose(
  ShouldEnableReportLinkHOC,
  connect(
    BaseCartesianChart.mapStateToProps,
    BaseCartesianChart.mapDispatchToProps,
  ),
  withPropsOnChange(['queryResults', 'i18nPrefs'], props => {
    const barData = BarChartUtils.transformResult(
      props.queryResults,
      props.viz,
      'VALUES',
      props.i18nPrefs,
    );

    const targetData = [...barData];

    return { barData, targetData };
  }),
  onlyUpdateForKeys([
    'barData',
    'targetData',
    'height',
    'width',
    'id',
    'enableReportLink',
  ]),
  NoDataIfHOC(props => props.barData.length === 0),
)(BarChart);
