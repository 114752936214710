import { useCallback, useState, createContext } from 'react';
import { noop } from 'lodash';

export const NestedDropdownContext = createContext<{
  openSubMenu?: string;
  setOpenSubMenu?: (menuId: string) => void;
}>({
  openSubMenu: null,
  setOpenSubMenu: noop,
});

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

export const NestedDropdownContextProvider = ({ children }: Props) => {
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const setOpenMenuCallback = useCallback(
    (menuId = null) => {
      setOpenSubMenu(menuId);
    },
    [setOpenSubMenu],
  );
  return (
    <NestedDropdownContext.Provider
      value={{
        openSubMenu,
        setOpenSubMenu: setOpenMenuCallback,
      }}
    >
      {children}
    </NestedDropdownContext.Provider>
  );
};
