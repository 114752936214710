import { useDispatch, useSelector } from 'react-redux';
import { getActiveSlicers } from '../../common/redux/selectors/viz-selectors';
import {
  useDiscoverBooleanOptionSelector,
  useDiscoverOptionSelector,
} from '../../discovery/discovery-context/discovery.context';
import { useCallback, useMemo, MouseEvent, useContext } from 'react';
import { IReportDetailInfo } from '../../discovery/viz-redirect';
import _, { get } from 'lodash';
import {
  AllowedDashletDrillLinkDynamicFields,
  getDynamicValues,
  SugarFilterContext,
} from '../../common';
import DiscoverActions from '../../common/redux/actions/DiscoverActions';
import {
  useCustomFormatToggleSelector,
  useOpenDiscoveryPresentStateSelector,
  useOpenVizChartSpec,
  useVizOptionSelector,
  useVizRuntimeFilterSelector,
} from '../../common/redux/selectors/viz-selector.hook';
import { ChartSpecs } from '../../discovery/ChartSpecs';
import { IToggle, IViz } from '../../discovery';
import { Viz } from '../../discovery/VizUtil';
import { EventKeyType } from './mobile-viz-footer.interfaces';
import { isDashletUser as isDashletUserSelector } from '../../common/redux/selectors/AccountSelectors';
import Discover from '../../common/redux/actions/DiscoverActions';
import { IDashletFilter } from '../../common/utilities/sugar-filter-converter/sugar-filter-converter.interfaces';
import { convertSugarFilter } from '../../common/utilities/sugar-filter-converter/sugar-filter-converter';

export const useCanCollapse = discoveryId => {
  const chartSpec = useOpenVizChartSpec({ discoveryId });
  const isForecastChanges = chartSpec.id === ChartSpecs.pipeline_changes.id;

  const canCollapseToggle: IToggle = useCustomFormatToggleSelector({
    discoveryId,
    toggleName: 'canCollapse',
    defaultValue: false,
  });
  const canCollapse = !!canCollapseToggle?.on;

  return {
    isForecastChanges,
    canCollapseEnabled: canCollapse,
  };
};

export const useDashletExternalLinkAction = (vizId: string) => {
  const dispatch = useDispatch();
  const { tenantId, tenantIdmId } = useSelector(
    (state: { main: any }) => state?.main,
  );
  const slicers = useSelector(state =>
    getActiveSlicers(state, { discoveryId: vizId }),
  );

  const { value: slicerSelections } = useDiscoverOptionSelector({
    vizId,
    option: 'slicerSelections',
  });

  const availableRuntimeFilters = useVizOptionSelector({
    discoveryId: vizId,
    option: 'runtimeFilters',
  });

  const sugarRuntimeFilters: IDashletFilter = useContext(
    SugarFilterContext,
  ) as IDashletFilter;

  const runtimeFilters = convertSugarFilter(
    sugarRuntimeFilters[vizId],
    availableRuntimeFilters,
  );

  return useCallback(
    (event: MouseEvent<Element, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      const queryParams = {
        tenant_id: tenantId,
        tenant_idm_id: tenantIdmId,
      };

      // just populate drill-linked report with slicer info
      const reportDetailInfo: IReportDetailInfo = {
        linkToReport: {
          id: vizId,
        },
        runtimeFilters,
        slicers,
        slicerSelections,
      };

      const messageBody = {
        reportDetailInfo,
        linkStrategy: 'update',
      };

      const dynamicValues = _.pick(
        getDynamicValues(),
        AllowedDashletDrillLinkDynamicFields,
      );

      if (_.isObject(messageBody?.reportDetailInfo)) {
        messageBody.reportDetailInfo.dynamicValues = dynamicValues;
      }

      dispatch(DiscoverActions.postWindowOpenReport(queryParams, messageBody));
    },
    [
      dispatch,
      runtimeFilters,
      slicerSelections,
      slicers,
      tenantId,
      tenantIdmId,
      vizId,
    ],
  );
};

export const useMobileVizFooterState = ({ vizId }) => {
  const {
    value: showLegendPanel,
    updateValue: setShowLegendPanel,
  } = useDiscoverBooleanOptionSelector({
    option: 'showLegendPanel',
    defaultValue: true,
  });
  const {
    value: showFiltersPanel,
    updateValue: setShowFiltersPanel,
  } = useDiscoverBooleanOptionSelector({
    option: 'showFiltersPanel',
    defaultValue: true,
  });
  const { isMobile, isDashletMode, isDashletUser } = useSelector(
    (state: any) => {
      return {
        isDashletUser: isDashletUserSelector(state.account),
        isMobile: get(state, 'main.isMobile', false),
        isDashletMode: get(state, 'dashlet.isDashletMode', false),
      };
    },
  );

  const discovery = useOpenDiscoveryPresentStateSelector({
    discoveryId: vizId,
  });

  const convertedFilters = useVizRuntimeFilterSelector({
    discoveryId: vizId,
  });

  const vizWithFilters: IViz = useMemo(
    () => ({
      ...discovery.viz,
      options: {
        ...discovery.viz.options,
        filters: JSON.stringify(convertedFilters),
      },
    }),
    [convertedFilters, discovery.viz],
  );

  const dispatch = useDispatch();
  const refresh = useCallback(() => {
    const variables = Viz.buildQueryVariables(
      vizWithFilters,
      ChartSpecs[discovery?.viz?.chartType],
    );
    dispatch(
      Discover.vizQuery({
        id: vizId,
        sourceName: discovery?.viz?.name,
        monitorEventId: discovery?.monitorEventId,
        variables: {
          ...variables,
          bypassCache: true,
        },
        queryId: discovery?.viz?.queryId,
      }),
    );
  }, [
    discovery?.monitorEventId,
    discovery.viz,
    dispatch,
    vizId,
    vizWithFilters,
  ]);

  const { isForecastChanges, canCollapseEnabled } = useCanCollapse(vizId);

  const toggleCanCollapse = useCallback(() => {
    if (isForecastChanges) {
      dispatch(
        Discover.setCustomFormatToggle(
          vizId,
          !canCollapseEnabled,
          'canCollapse',
        ),
      );
    }
  }, [canCollapseEnabled, dispatch, isForecastChanges, vizId]);

  const queryResults = _.get(discovery, 'vizQueryResults.data');
  const chartSpec = ChartSpecs[discovery.viz.chartType];
  const chartIsValid = chartSpec.validate(discovery.viz).valid;
  const isRefreshDisabled = _.isEmpty(queryResults) || !chartIsValid;

  const onMenuSelection = useCallback(
    (eventKey: EventKeyType) => {
      switch (eventKey) {
        case 'HIDE_LEGEND': {
          setShowLegendPanel(!showLegendPanel);
          // force a resize event when closing the panel to give anything on the screen a chance to account for it
          sendResizeEvent();
          break;
        }
        case 'HIDE_FILTERS': {
          setShowFiltersPanel(!showFiltersPanel);
          // force a resize event when closing the panel to give anything on the screen a chance to account for it
          sendResizeEvent();
          break;
        }
        case 'REFRESH': {
          refresh();
          // force a resize event when closing the panel to give anything on the screen a chance to account for it
          sendResizeEvent();
          break;
        }
        case 'COLLAPSE_WHITESPACE': {
          toggleCanCollapse();
          // force a resize event when closing the panel to give anything on the screen a chance to account for it
          sendResizeEvent();
          break;
        }
        default: {
          console.warn(`${eventKey} is not yet supported`);
        }
      }
    },
    [
      refresh,
      setShowFiltersPanel,
      setShowLegendPanel,
      showFiltersPanel,
      showLegendPanel,
      toggleCanCollapse,
    ],
  );

  const retVal = useMemo(
    () => ({
      onMenuSelection,
      showLegendPanel,
      showFiltersPanel,
      isMobile,
      isDashletMode,
      isDashletUser,
      isRefreshDisabled,
    }),
    [
      isDashletMode,
      isMobile,
      onMenuSelection,
      showFiltersPanel,
      isDashletUser,
      showLegendPanel,
      isRefreshDisabled,
    ],
  );
  return retVal;
};

const sendResizeEvent = () => {
  setTimeout(() => window.dispatchEvent(new window.Event('resize')), 100);
};
