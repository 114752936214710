import { css } from '@emotion/react';
import {
  font,
  backgroundImage,
  DesktopMainHeaderHeight,
  mainHeaderHeightProperty,
} from './mixins';
export const mainNavStyles = ({
  mobileMaxWidth,
  appUrl,
  isMobile,
  isDashletMode,
  theme: {
    darkMode,
    colors: {
      baseFontColor,
      CorvanaBackgroundMedium,
      CorvanaMediumBorder,
      MainNavHover,
      NavDropdownBackground,
      LightFontWeight,
      BaseWhite,
      TabHighlightHover,
      ContentText,
      RegularFontWeight,
      ActiveLinkText,
      MediumBlue,
      LightBlue,
    },
  },
}) => css`
  /**/
  /*  Main Header CSS*/
  /**/

  @keyframes left-slide {
    0% {
      margin-left: -350px;
    }
    50% {
      margin-left: -350px;
    }
    100% {
      margin-left: 0px;
    }
  }
  @keyframes right-slide {
    0% {
      transform: translateX(200px);
    }
    50% {
      transform: translateX(200px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  #mainBackground {
    background-color: ${CorvanaBackgroundMedium};
  }
  #mainHeader {
    ${font({ weight: LightFontWeight })}
    background-color: ${darkMode ? MediumBlue : BaseWhite};
    ${mainHeaderHeightProperty({ isMobile, isDashletMode })}
    margin-bottom: 0px;
    border-radius: 0px;
    border-bottom: 1px solid ${darkMode ? LightBlue : CorvanaMediumBorder};
    border-top: none;
    border-left: none;
    border-right: none;

    &.navbar {
      min-height: 40px;
    }
    .navbar-brand {
      height: 41px;
    }
  }
  @media (max-width: ${mobileMaxWidth}) {
    #mainHeader {
      height: ${DesktopMainHeaderHeight};
    }
  }
  #mainHeader .container {
    width: 100%;
    padding-right: 15px;
  }
  #mainHeader .navbar-brand {
    /* Turn off the slide in animations for now*/
    /*animation: left-slide 2s forwards;*/
    width: 170px;
    padding: 5px;
    padding-top: 0px;
  }
  #mainHeader .navbar-brand .logo {
    height: 100%;
  }
  #mainHeader .navbar-nav.navbar-right {
    /* Turn off the slide in animations for now*/
    /*animation: right-slide 2s forwards;*/
  }
  #mainHeader .navbar-nav.navbar-right li {
    border: 0px;
  }
  #mainHeader .navbar-nav.menu-items li:not(.disabled):hover {
    border: none;
    background-color: ${MainNavHover};
  }

  #mainHeader .navbar-nav.menu-items {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  #mainHeader .navbar-nav li {
    border: none;
    margin: 0px 0px;
    height: 40px;
  }
  #mainHeader .navbar-nav li > a {
    color: ${ContentText};
    height: 40px;
    padding: 12px 23px;
  }
  #mainHeader .navbar-nav li.help-link > a,
  #mainHeader .navbar-nav li.dropdown > a {
    padding: 4px 0px 0px 8px;
  }
  #mainHeader .navbar-nav li > a:active,
  #mainHeader .navbar-nav li a:focus {
    outline: none;
  }
  #mainHeader .navbar-nav li.disabled > a {
    color: ${TabHighlightHover};
    ${darkMode ? '' : 'opacity: 0.5;'}
  }
  #mainHeader .navbar-nav.menu-items li.active:not(.disabled):hover {
    border: none;
    background-color: ${NavDropdownBackground};
  }
  #mainHeader .navbar-nav li.active:not(.disabled) {
    border: none;
    background-color: ${NavDropdownBackground};
    color: ${ContentText};
    ${font({ weight: RegularFontWeight })}
  }
  #mainHeader .navbar-nav li.active:not(.disabled) a {
    background-color: ${NavDropdownBackground};
  }
  #mainHeader .navbar-nav .menu-item {
    text-align: center;
    text-transform: capitalize;
  }
  #mainHeader .accountNavItem .dropdown {
    margin: 0px;
    padding: 0;
  }
  #mainHeader .accountNavItem li:hover {
    background-color: ${MainNavHover};
  }
  #mainHeader .accountNavItem .help-link {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
  #mainHeader .accountNavItem .help-link > a,
  #mainHeader .accountNavItem .dropdown-toggle {
    padding-top: 10px;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #mainHeader .accountNavItem .dropdown-menu {
    text-transform: none;
    z-index: 1001;
  }
  #mainHeader .accountNavItem .dropdown-header {
    display: flex;
    align-items: center;
    padding: 0 31px 0 23px;
    margin: 0;
  }
  #mainHeader .accountNavItem .dropdown-header img {
    margin-right: 8px;
  }
  #mainHeader .accountNavItem .dropdown-header .name {
    ${font({ size: '16px', color: ContentText })}
  }
  #mainHeader .accountNavItem li {
    margin: 0;
  }
  #mainHeader .accountNavItem li.menu-item > a {
    margin: 0;
  }
  #mainHeader .accountNavItem .divider {
    margin: 16px 0px 8px 0;
  }
  #mainHeader .accountNavItem .menu-item a {
    color: ${ContentText};
    display: flex;
    align-items: center;
    padding: 8px 23px;
  }
  #mainHeader .accountNavItem .menu-item a svg {
    vertical-align: middle;
    margin-right: 8px;
  }
  #mainHeader .accountNavItem .menu-item a:hover {
    color: ${ActiveLinkText};
    background: none;
  }

  #subHeader {
    border: none;
    border-radius: 0px;
    background-color: ${CorvanaBackgroundMedium};
    min-height: 44px;
    animation: fade-in 2s forwards;
  }
  #subHeader .container {
    margin: 0px;
    padding: 0px;
  }
  .addBtn {
    cursor: Pointer;
    border: none;
    padding: 0px;
    width: 24px;
    height: 24px;
    ${backgroundImage({ appUrl, path: 'add_button.svg' })}
  }
  .addBtn:hover {
    ${backgroundImage({ appUrl, path: 'add_button_hover.svg' })}
  }
  #subHeader .navbar-brand {
    position: absolute;
    width: calc(100% - 200px);
    height: 44px;
    padding: 10px;
    left: 0;
    top: 0;
    text-align: center;
    margin-left: 100px;
    margin-right: 100px;
    color: ${baseFontColor};
    ${font({ size: '24px', weight: RegularFontWeight })}
  }
  #subHeader .navbar-form {
    padding-left: 20px;
    margin: 0px;
    margin-top: 10px;
  }
  @media (min-width: ${mobileMaxWidth}) {
    #mainHeader .container,
    #mainHeader .navbar-right,
    .navbar-collapse.collapse {
      height: ${DesktopMainHeaderHeight} !important;
    }
  }
  .menu-item.mobile {
    display: none;
  }
  @media (min-width: ${mobileMaxWidth}) {
    #mainHeader .container .mainHeader__main-menu {
      display: flex !important;
      justify-content: space-between;
      padding-right: 0px;
    }
    .mainHeader__main-menu__list {
      margin: auto auto;
    }
    .accountNavItem {
      margin-left: 90px;
    }
    .MobileHeader {
      display: none;
    }
    .tenant-name {
      margin-right: 8px;
      text-transform: none;
    }
  }
`;
