import { Fragment } from 'react';
import { Redirect, Route, withRouter, Switch } from 'react-router-dom';
import SecureRoute from './common/SecureRoute';
import URLs from './common/Urls';
import DiscoverActions from './common/redux/actions/DiscoverActions';
import MonitorEventRedirect from './common/hoc/MonitorEventRedirect';
import ReportLinkRedirect from './common/hoc/ReportLinkRedirect';
import Thumbnail from './thumbnail/Thumbnail';
import SelectVizDatasource from './discovery/SelectVizDatasource';
import { MobileInsights } from './insights/mobile-insights';
import Administration from './views/Administration';
import Discover from './discovery/Discover';
import { Header } from './discovery/header';
import { MainNavigation } from './discovery/main-navigation';
import Datasets from './datasets/Datasets';
import { MobileAccountSettings, AccountSettings } from './account';
import CustomDragLayer from './CustomDragLayer';
import FullScreenDialog from './common/widgets/dialogs/FullScreenDialog';
import AuthenticatedReducerSet from './common/redux/reducers/AuthenticatedReducerSet';
import store from './common/redux';
import './MainServiceModule';
import { Navbar, Modal } from '@sugar-discover/react-bootstrap-wrapper';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { CenteredSpinner } from './common/widgets/Status';
import { LibraryDashlet, ReportDashlet } from './sugar-dashlet';
import _ from 'lodash';

import { messages } from './i18n';
import {
  isNoAccess,
  isDashletUser,
} from './common/redux/selectors/AccountSelectors';
import Util from './common/Util';
import NewRelic from './common/NewRelic';
import { NoAccessDialog } from './components/no-access-dialog';
import { FeatureFlag } from './common/utilities/feature-flag/feature-flag';
import {
  AppBackground,
  MainContentWrapper,
} from './ui/app-background/app-background.styles';
import { SidebarNavigation } from './discovery/sidebar-navigation';

store.replaceReducer(AuthenticatedReducerSet);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./common/redux/reducers/AuthenticatedReducerSet', () => {
    const updatedAuthenticatedReducer = require('./common/redux/reducers/AuthenticatedReducerSet');
    store.replaceReducer(updatedAuthenticatedReducer);
  });
}

const DiscoverView = ({ domain, ...other }) => {
  NewRelic.addPageAction('main-page-load', { domain });
  return (
    <Route
      {...other}
      render={props => <Discover {...props} domain={domain} />}
    />
  );
};

const MainNav = ({ isMobile, appUrl, children }) => {
  const renderNavAndHeader = loadPinnedDiscoveries => (
    <Fragment>
      {!isMobile && (
        <>
          <FeatureFlag withFeatures={['uiAwesomeFeature']}>
            <SidebarNavigation key='mainNav' />
          </FeatureFlag>
          <FeatureFlag withoutFeatures={['uiAwesomeFeature']}>
            <MainNavigation
              key='mainNav'
              loadPinnedDiscoveries={loadPinnedDiscoveries}
              appUrl={appUrl}
            />
          </FeatureFlag>
        </>
      )}
      <Header
        key='header'
        loadPinnedDiscoveries={loadPinnedDiscoveries}
        appUrl={appUrl}
      />
    </Fragment>
  );

  return [
    <Switch key='main-switch'>
      <Route exact path={'/open/:openDiscoveryId'}>
        {renderNavAndHeader(false)}
      </Route>
      <Route path={'*'}>{renderNavAndHeader(true)}</Route>
    </Switch>,
    <>
      <FeatureFlag withFeatures={['uiAwesomeFeature']}>
        <MainContentWrapper>{children}</MainContentWrapper>
      </FeatureFlag>
      <FeatureFlag withoutFeatures={['uiAwesomeFeature']}>
        <div id='contentDiv' key='contentDiv'>
          {children}
        </div>
      </FeatureFlag>
    </>,
  ];
};

const Shell = ({ appUrl, children = [] } = {}) => {
  const logoImgSrc = Util.assetUrl({ appUrl, path: 'discover_logo.svg' });
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Navbar id='mainHeader'>
        <Navbar.Header>
          <Navbar.Brand>
            <div>
              <img src={logoImgSrc} className='invertable logo' />
            </div>
          </Navbar.Brand>
        </Navbar.Header>
      </Navbar>
      <div
        className={'mainBackground'}
        style={{ width: '100%', height: '100%' }}
      >
        {_.isEmpty(children) ? <CenteredSpinner /> : children}
      </div>
    </div>
  );
};

const MainRouting = () => (
  <Switch>
    <SecureRoute
      path='/datasets'
      disallowedRoles={['READONLY']}
      redirect='/'
      render={() => <Datasets />}
    />
    <DiscoverView domain='discover' path='/open/:openDiscoveryId?' />
    <DiscoverView domain='library' path='/library' />
    <SecureRoute
      allowedRoles={['Admin']}
      redirect='/'
      path='/administration'
      render={() => {
        return (
          <FullScreenDialog
            show
            className='admin-dialog'
            titlePanel={messages.nav.administration}
            doCancel={() => {
              URLs.goToLastOrHome();
            }}
            padding={0}
          >
            <Administration />
          </FullScreenDialog>
        );
      }}
    />
    <Redirect to={'/library'} />
  </Switch>
);

const NewVizOpener = connect(_.constant({}), dispatch => ({
  openNewViz: newVizId => dispatch(DiscoverActions.openNewViz(newVizId)),
}))(({ match, openNewViz }) => {
  const newVizId = match?.params?.newVizId;
  openNewViz(newVizId);
  return <></>;
});

const UnconnectedMainComponent = props => {
  const { isMobile, appUrl = '' } = props;

  return [
    <Switch key='dashlet-switch'>
      <Route
        exact={true}
        path='/dashlet/library'
        render={routeProps => {
          return <LibraryDashlet {...routeProps} domain={'library'} />;
        }}
      />
      <Route
        exact={true}
        path='/dashlet/report/:openDiscoveryId?'
        render={routeProps => {
          const { openDiscoveryId } = routeProps?.match?.params ?? {};
          return (
            <ReportDashlet
              {...routeProps}
              isLoggedIn={routeProps.isLoggedIn}
              key={`vizChart-${openDiscoveryId}`}
              vizId={openDiscoveryId}
            />
          );
        }}
      />
      <Route
        exact={true}
        path='/dashlet/report/:vizId/insights/'
        render={routeProps => {
          return <MobileInsights {...routeProps} domain={'insights'} />;
        }}
      />
      <Route path='/event/:monitorEventId/:options?'>
        <MonitorEventRedirect />
      </Route>
      <Route
        path='/reportLink'
        render={routeProps => {
          const { reportDetailInfoId } = URLs.getQueryParams();
          return (
            <ReportLinkRedirect {...{ ...routeProps, reportDetailInfoId }} />
          );
        }}
      ></Route>
      <SecureRoute
        path='/newviztab/:newVizId'
        disallowedRoles={['READONLY']}
        redirect='/'
        render={routeProps => {
          return <NewVizOpener {...routeProps} />;
        }}
      />
      <Route
        path={'/insights/:vizId/:backText?'}
        render={routeProps => {
          return <MobileInsights {...routeProps} domain={'insights'} />;
        }}
      />
      <Route path='/thumbnail'>
        <Thumbnail {...props} />
      </Route>
      <SecureRoute
        path='/newviz'
        disallowedRoles={['READONLY']}
        redirect='/'
        component={SelectVizDatasource}
      />
      <Route
        path='/account'
        render={() => {
          return isMobile ? (
            <MainNav appUrl={appUrl} isMobile={isMobile}>
              <MobileAccountSettings />
            </MainNav>
          ) : (
            <AccountSettings />
          );
        }}
      />
      <Route path='*'>
        <FeatureFlag withoutFeatures={['uiAwesomeFeature']}>
          <MainNav {...props} appUrl={appUrl}>
            <MainRouting />
          </MainNav>
        </FeatureFlag>
        <FeatureFlag withFeatures={['uiAwesomeFeature']}>
          <AppBackground>
            <MainNav {...props} appUrl={appUrl}>
              <MainRouting />
            </MainNav>
          </AppBackground>
        </FeatureFlag>
      </Route>
    </Switch>,
    <CustomDragLayer key='drag-layer' snapToGrid={false} />,
  ];
};

const MainComponent = compose(
  withRouter,
  connect(state => {
    const {
      account,
      dashlet: {
        isDashletMode,
        isDashletLibraryMode,
        isDashletReportMode,
        hasSaveError,
      },
      main: { hasInternalServerError, appUrl },
    } = state;
    return {
      hasSaveError,
      isDashletLibraryMode,
      isNoAccessUser: isNoAccess(account),
      isDashletUser: isDashletUser(account),
      isDashletReportMode,
      hasInternalServerError,
      isDashletMode,
      appUrl,
    };
  }),
)(props => {
  const {
    isLoggedIn,
    isDashletMode,
    isDashletLibraryMode,
    isDashletReportMode,
    isNoAccessUser,
    isDashletUser: _isDashletUser,
    hasInternalServerError,
    hasSaveError,
    appUrl,
  } = props;
  const noAccess = isNoAccessUser || (_isDashletUser && !isDashletMode);
  const noDashletEditAccess =
    isDashletLibraryMode && (isNoAccessUser || _isDashletUser);
  const noDashletViewAccess = isDashletReportMode && isNoAccessUser;
  if (noAccess || noDashletEditAccess || noDashletViewAccess) {
    return (
      <NoAccessDialog
        noAccess={noAccess}
        noDashletEditAccess={noDashletEditAccess}
      />
    );
  } else if (hasInternalServerError && isDashletMode) {
    return (
      <Modal
        className={'regular-modal full-screen fixed-error-modal'}
        onHide={_.noop}
        show={true}
      >
        <Modal.Header>
          <Modal.Title>
            {messages.formatString(
              messages.dashlet.dashletOutOfDate,
              messages.nonTranslated.discover,
            )}
          </Modal.Title>
        </Modal.Header>
      </Modal>
    );
  } else if (hasSaveError && isLoggedIn && !isDashletLibraryMode) {
    return (
      <Modal
        className={'regular-modal full-screen fixed-error-modal'}
        onHide={_.noop}
        show={true}
      >
        <Modal.Header>
          <Modal.Title>
            {messages.formatString(
              messages.dashlet.errorSavingDashlet,
              messages.nonTranslated.dashletLowerCase,
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {messages.formatString(
            messages.dashlet.errorSavingDashletMessage,
            messages.nonTranslated.dashletLowerCase,
            messages.nonTranslated.discover,
          )}
        </Modal.Body>
      </Modal>
    );
  }
  return isLoggedIn || isDashletMode ? (
    <UnconnectedMainComponent {...props} appUrl={appUrl} />
  ) : (
    <Shell appUrl={appUrl} />
  );
});

export { MainComponent, AuthenticatedReducerSet };
