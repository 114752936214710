import { PureComponent } from 'react';
import _ from 'lodash';
import {
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
  InvisibleButton,
} from '../../ui';
import { messages } from '../../i18n';
import { HelpBlock } from '../../views/VizSaveDialog/ui';
import {
  ModalBody,
  StyledModalPortal,
  UnsavedList,
} from './confim-logout-prompt.styles';

class ConfirmLogoutPromptComponent extends PureComponent {
  isSaving;
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      saveError: '',
    };
    this.doCancel = this.doCancel.bind(this);
  }
  doLogout() {
    this.props.logout();
    this.props.onHide();
  }
  doSave() {
    // set the spinner button to spin
    this.setState({ isSaving: true });
    this.props.saveUnsaved([...this.props.unsaved], result => {
      // tell the spinner button to stop spinning
      this.setState({ isSaving: false });
      if (!_.isError(result)) {
        // now log them out
        this.doLogout();
        this.props.onHide();
      } else {
        this.setState({ saveError: result.message });
      }
    });
  }

  doCancel() {
    this.setState({ saveError: '' });
    this.props.cancel();
    this.props.onHide();
  }

  renderUnsavedList() {
    const { unsaved } = this.props;
    if (!_.isEmpty(unsaved) && unsaved.length > 1) {
      const list = unsaved.map(d => {
        return <li key={d.id}>{d.name}</li>;
      });
      return (
        <UnsavedList className={'unsaved-discoveries-list'}>{list}</UnsavedList>
      );
    }
  }
  renderButtons() {
    const { unsaved, isReadOnlyUser } = this.props;

    if (_.isEmpty(unsaved) || isReadOnlyUser) {
      // Logout without any modified visualizations. Need 'Yes' and 'No' buttons
      return [
        <SecondaryButton key='no' onClick={this.doCancel} className='promptNo'>
          {messages.cancel}
        </SecondaryButton>,
        <PrimaryButton
          key='yes'
          onClick={() => this.doLogout()}
          className='promptYes'
        >
          {messages.confirmLogout.yes}
        </PrimaryButton>,
      ];
    } else {
      // Logout with at least one dirty viz. Need 'Discard Changes', 'Cancel', and 'Save' buttons (Save should spin)
      return [
        <InvisibleButton
          key='cancel'
          onClick={this.doCancel}
          className='promptNo'
        >
          {messages.cancel}
        </InvisibleButton>,
        <SecondaryButton
          key='discard'
          onClick={() => this.doLogout()}
          className='promptYes'
        >
          {messages.confirmLogout.discardChanges}
        </SecondaryButton>,
        <PrimaryButton
          key='logout-loading-button'
          onClick={() => {
            this.doSave();
          }}
          disabled={this.state.isSaving}
        >
          {this.state.isSaving
            ? messages.confirmLogout.saving
            : messages.confirmLogout.save}
        </PrimaryButton>,
      ];
    }
  }

  doYes() {
    this.doLogout();
  }

  doNo() {
    this.doCancel();
  }

  render() {
    const { saveError } = this.state;
    const { unsaved } = this.props;

    let msg = messages.confirmLogout.areYouSure;
    if (unsaved.length === 1) {
      msg = (
        <div>
          <span>
            {messages.formatString(
              messages.confirmLogout.unsavedChanges,
              unsaved[0].name,
            )}
          </span>
          <br />
          <span>{messages.confirmLogout.unsavedChangesMore}</span>
        </div>
      );
    } else if (unsaved.length > 1) {
      msg = (
        <div>
          <span>{messages.confirmLogout.unsavedChangesMultiple}</span>
          <br />
          <span>{messages.confirmLogout.unsavedChangesMore}</span>
        </div>
      );
    }

    return (
      <StyledModalPortal
        contentClassName='confirm-logout-dialog'
        title={messages.confirmLogout.title}
        buttonGroup={
          <ButtonContainer
            style={{
              marginBottom: '0.8rem',
              alignSelf: 'flex-end',
              marginRight: '1rem',
            }}
          >
            {this.renderButtons()}
          </ButtonContainer>
        }
      >
        <ModalBody>
          <div>{msg}</div>
          {this.renderUnsavedList()}
          {!_.isEmpty(saveError) && (
            <HelpBlock className={'save-error-msg'} title={saveError}>
              {saveError}
            </HelpBlock>
          )}
        </ModalBody>
      </StyledModalPortal>
    );
  }
}

ConfirmLogoutPromptComponent.defaultProps = {
  unsaved: [],
  logout: _.noop,
  saveUnsaved: (unsaved, callBack) => {
    callBack(new Error('No "saveUnsaved" function defined.'));
  },
  show: false,
  cancel: _.noop,
  onHide: _.noop,
  isSaving: false,
};

export default ConfirmLogoutPromptComponent;
