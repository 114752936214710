import { FunctionComponent } from 'react';
import { DATA_FORMATTER, PIVOT_TABLE } from '../../../common/Constants';
import classnames from 'classnames';
import { getLegendItemStyle } from '../viz-legend/utils';
import { Tooltip } from '../../../components/ui/tooltip';
import { IInternationalizationPreferences } from '../../../account/interfaces';

interface IProps {
  label: string;
  colorScale?: string;
  min?: number;
  max?: number;
  focused?: boolean;
  formatter?: any;
  isMobile: boolean;
  isForcedMobile: boolean;
  i18nPrefs: IInternationalizationPreferences;
}

export const PivotLegendItem: FunctionComponent<IProps> = ({
  label,
  colorScale = 'GWR',
  min,
  max,
  focused,
  formatter,
  isMobile,
  isForcedMobile,
  i18nPrefs = {},
}) => {
  const fmt = formatter || DATA_FORMATTER.NUMBER;
  const scale = PIVOT_TABLE.COLOR_SCALES[colorScale];

  const legendItemStyle = getLegendItemStyle({ isForcedMobile });
  const minmaxStyle = { ...legendItemStyle };
  const colorScaleStyle = { ...legendItemStyle };

  if (isForcedMobile) {
    Object.assign(minmaxStyle, {
      display: 'flex',
      height: 17,
      flexDirection: 'row',
      overflow: 'auto hidden',
      alignItems: 'center',
    });
    Object.assign(colorScaleStyle, {
      width: 64,
      height: 17,
    });
  }
  const legendColorScale = (
    <div style={colorScaleStyle} className={'legend-color-scale'}>
      <div className={'swatch'} style={{ background: scale.swatch }} />
    </div>
  );

  return (
    <Tooltip
      id='legend-tooltip'
      key={`legend-item-${label}`}
      placement='top'
      title={label}
      enterDelay={1000}
      arrow
    >
      <div
        style={{ ...legendItemStyle, overflow: 'hidden' }}
        className={classnames(
          'pivot-legend-item',
          'legend-item',
          isMobile ? 'hbox' : 'vbox',
          { dim: !focused },
        )}
      >
        <div
          style={{ ...legendItemStyle, paddingLeft: 0, height: 17 }}
          className='legend-label'
        >
          {label}
        </div>
        {!isMobile && legendColorScale}
        <div style={minmaxStyle} className={'legend-min-max'}>
          <div
            style={{ ...legendItemStyle, height: 17 }}
            className={'pull-left'}
          >
            {fmt.formatSmall(min, i18nPrefs)}
          </div>
          {isMobile && legendColorScale}
          <div
            style={{ ...legendItemStyle, height: 17 }}
            className={'pull-right'}
          >
            {fmt.formatSmall(max, i18nPrefs)}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
