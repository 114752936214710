import { compose, shouldUpdate, withProps } from 'react-recompose';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';
import ServiceLocator from '../../common/ServiceLocator';
import { ComponentTypes } from '../../common/Constants';
import _ from 'lodash';
import { Viz } from '../../discovery/VizUtil';
import { connect } from 'react-redux';
import Main from '../../common/redux/actions/MainActions';
import { messages } from '../../i18n';

const BaseInsightsList = ({
  advancedMode,
  selectedDiscovery,
  events,
  dataFormatters,
  showScoreDetail,
  i18nPrefs = {},
  customFormatProps,
}) => {
  // This cache holds the dimensions of the activity items that it has rendered
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 200,
  });
  const insightComponents = ServiceLocator.getAll('COMPONENT', {
    type: ComponentTypes.INSIGHTS,
  }).filter(c => _.isFunction(c.supports));

  const rowRenderer = ({ index, key, parent, style }) => {
    const event = events[index];
    let InsightComp = insightComponents.find(comp =>
      comp.supports({
        referenceType: 'MonitorEvent',
        monitorEvent: event,
      }),
    );
    if (_.isNil(InsightComp)) {
      InsightComp = () => {
        return [];
      };
    }
    return (
      <CellMeasurer
        cache={cache}
        MonitorServiceTest
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div
          style={{ ...style }}
          onClick={e =>
            advancedMode && e.metaKey && showScoreDetail(event.scoreDetail)
          }
        >
          <InsightComp
            monitorEvent={event}
            referenceId={event.id}
            condensed
            dataFormatters={dataFormatters}
            dataset={selectedDiscovery.dataset}
            viz={selectedDiscovery.viz}
            i18nPrefs={i18nPrefs}
            customFormatProps={customFormatProps}
          />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          height={height}
          overscanRowCount={5}
          rowCount={events.length}
          rowRenderer={rowRenderer}
          rowHeight={cache.rowHeight}
          width={width - 20}
          style={{ padding: '0px 8px' }}
          deferredMeasurementCache={cache}
        />
      )}
    </AutoSizer>
  );
};

// Recursive
const formatStackTrace = ({ message: exceptionMessage, stackTrace, cause }) => {
  let returnVal = [];
  if (exceptionMessage) {
    returnVal.push(exceptionMessage);
  }
  if (stackTrace) {
    returnVal = returnVal.concat(
      stackTrace.map(({ methodName, className, lineNumber }) => {
        return `\t\tat ${className}.${methodName}(${lineNumber})`;
      }),
    );
  }
  if (cause) {
    returnVal.push(formatStackTrace(cause));
  }
  return returnVal.join('\n');
};

const filterEventsForCurrentViz = (props, events) => {
  return events.filter(insight => {
    if (!insight.monitor) {
      return true;
    }
    try {
      const payload = JSON.parse(insight.payload);
      const { attributeName, attributeValue } = payload;

      // if there's a filter in-play for the attribute check that attributeValue is included
      const filters = Viz.getFiltersFromViz(props.selectedDiscovery.viz);
      if (filters[attributeName]) {
        // there's a filter on the attribute. Check that value is included
        const filter = filters[attributeName];
        // only workes with the IN_LIST created from the Activity Link. Others can be added later
        if (_.get(filter, 'expression.left.operator.key') === 'IN_LIST') {
          return _.includes(filter.expression.left.operands, attributeValue);
        } else {
          return true;
        }
      }
      return true;
    } catch (e) {
      console.error('Error parsing monitor event payload', e);
      return true;
    }
  });
};

export const __private__ = { filterEventsForCurrentViz };

const InsightHoc = () =>
  compose(
    connect(
      state => {
        const { i18nPrefs = {} } = state?.account?.currentUser;
        return {
          advancedMode: state.main.advanced,
          i18nPrefs,
        };
      },
      dispatch => ({
        showScoreDetail(detail) {
          dispatch(
            Main.showErrorDetail(
              true,
              messages.insightFeed.scoreBreakdown,
              detail,
            ),
          );
        },
      }),
    ),
    withProps(props => {
      // Blend insights in with monitor events
      let events = [];
      if (_.isArray(props.selectedDiscovery.dynamicInsights)) {
        events = [...props.selectedDiscovery.dynamicInsights];
      }
      events = _.sortBy(events, 'score').reverse();

      // Error events need to have their stacktrace formatted as their "scoreDetail"
      events.forEach(event => {
        try {
          event.eventData = JSON.parse(event.payload);
          const { lastDate, lastLevel } = event;
          Object.assign(event.eventData, { lastDate, lastLevel });
          if (event.insightType === 'ERROR') {
            const { query, exception } = event.eventData;

            let formattedStackTrace = formatStackTrace(exception);

            if (query) {
              formattedStackTrace += `\n\n\nDatabase Query:\n\n${JSON.stringify(
                query,
                null,
                '\t',
              )}`;
            }
            event.scoreDetail = formattedStackTrace;
          }
        } catch (err) {
          console.warn('Could not parse event payload', event);
        }
      });

      // remove any events who's payload wasn't parsed successfully
      events = events.filter(evt => !_.isUndefined(evt.eventData));
      if (!props.advancedMode) {
        events = events.filter(evt => !evt.excluded);
      }

      // Filter out MonitorEvent Insights based on current filter
      events = filterEventsForCurrentViz(props, events);

      // get any dataFormatters for fields currently active so the insights can take advantage of them
      let dataFormatters = {};
      let customFormatProps = {};
      if (props.selectedDiscovery.viz && props.selectedDiscovery.viz.layout) {
        dataFormatters = Viz.getDataFormatters(props.selectedDiscovery.viz);
        customFormatProps = Viz.getDataCustomFormatters(
          props.selectedDiscovery.viz,
        );
      }
      return { ...props, events, dataFormatters, customFormatProps };
    }),
    shouldUpdate((prev, next) => {
      return !_.isEqual(prev.events, next.events);
    }),
  );

const InsightComponents = compose(InsightHoc(7))(BaseInsightsList);
const InsightsList = BaseInsightsList;
export { InsightHoc, InsightComponents, InsightsList };
