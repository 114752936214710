import { isDashletMode } from './dashlet.util';
import { omitBy, isUndefined } from 'lodash';
import { getConfig, updateConfig } from '../common/global-discover-config';
import _ from 'lodash';
import { IDM_TOKEN_HEADER_NAME } from '../common/Constants';

let refreshPromise: Promise<string>;

const updateAuthToken: () => Promise<string> = () => {
  if (!refreshPromise) {
    refreshPromise = new Promise((resolve, reject) => {
      (window as any).App?.api?.ping('', {
        success: () => resolve((window as any)?.App?.api?.getOAuthToken()),
        error: err => reject(err),
      });
    }).finally(() => {
      refreshPromise = null;
    }) as Promise<string>;
  }
  return refreshPromise;
};

export const getAuthToken: (opts?: {
  shouldRefresh?: boolean;
}) => Promise<string> = async ({ shouldRefresh = false } = {}) => {
  if (!isDashletMode()) {
    // get token from config
    const { IDM_TOKEN: token } = getConfig();
    return _.isEmpty(token) ? undefined : token;
  }
  if (_.isFunction((window as any)?.App?.api?.getOAuthToken)) {
    if (shouldRefresh) {
      await updateAuthToken();
    }
    return (window as any)?.App?.api?.getOAuthToken();
  }
  return updateAuthToken(); // not sure if this is needed. Do need to handle refreshing of token
};

export const handleNewAccessToken = (token: string) => {
  const { IDM_TOKEN } = getConfig();

  if (!_.isEmpty(token)) {
    if (!_.isEqual(IDM_TOKEN, token)) {
      updateConfig({
        IDM_TOKEN: token,
      });
    }
  }
};

// frontend will be provided the auth value in three different modalities:
// 1) provided by a global var (Web app) or
// 2) provided by a response from the Discover backend (token has/will be refreshed) or
// 3) Mango api (Dashlets)
export const getAuthHeaders: (headers: any) => Promise<any> = async headers => {
  const authToken = await getAuthToken();
  return omitBy(
    {
      ...headers,
      [IDM_TOKEN_HEADER_NAME]: authToken,
    },
    isUndefined,
  );
};
