import { Component } from 'react';
import { connect } from 'react-redux';
import GlassPane from './common/widgets/GlassPane';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import ErrorDetailDialog from './common/widgets/dialogs/ErrorDetailDialog';
import {
  BrowserRouter,
  HashRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import CorvanaUrlWrapper from './common/hoc/CorvanaUrlWrapper';
import AppOutOfDateDialog from './common/widgets/dialogs/AppOutOfDateDialog';
import { compose } from 'react-recompose';
import Main from './common/redux/actions/MainActions';
import { LastLocationProvider } from 'react-router-last-location';
import RedirectComponent from './RedirectComponent';
import { isStandalone } from './common/Util';
import Urls from './common/Urls';
import { MainComponent } from './MainApp';
import { LogoutDialog } from './common/widgets/dialogs/logout-dialog.component';
import { CenteredSpinner } from './common/widgets/Status';
import { MaterialThemeProvider } from './common/emotion/theme/material-theme-provider.component';
import classnames from 'classnames';
import { ToastProvider } from './components/toast/toast-launcher';

function configureRouter() {
  return isStandalone() ? HashRouter : BrowserRouter;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookieTimerStarted: false,
    };
  }

  onResize = (event = {}) => {
    const newWidth = event.currentTarget
      ? event.currentTarget.innerWidth
      : window.innerWidth;
    this.props.setIsMobile(newWidth);
  };

  onSystemDarkModeChange = e => {
    return this.props.setSystemDarkMode(e.matches);
  };

  componentDidMount() {
    this.match = window.matchMedia('(prefers-color-scheme: dark)');
    window.addEventListener('resize', this.onResize);
    this.match.addEventListener('change', this.onSystemDarkModeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    this.match.removeEventListener('change', this.onSystemDarkModeChange);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isMobile) {
      this.onResize();
    }
    if (isStandalone() && !prevProps.pathname && this.props.pathname) {
      Urls.goTo(this.props.pathname);
    }
  }

  render() {
    const Router = configureRouter();
    return (
      <DndProvider backend={HTML5Backend}>
        <MaterialThemeProvider>
          <ToastProvider>
            <Router>
              <LastLocationProvider>
                <CorvanaUrlWrapper>
                  <div id='mainDiv'>
                    <Switch>
                      <Route
                        exact
                        path='/loading'
                        component={CenteredSpinner}
                      />
                      <Route path={'/redirect'} component={RedirectComponent} />
                      <Route path='/login' render={() => <Redirect to='/' />} />
                      <Route path='/logout' render={() => <LogoutDialog />} />
                      <Route path='/'>
                        <div
                          className={classnames(
                            'vbox',
                            'flex-fill',
                            'content-container',
                            {
                              'pl-60':
                                !this.props.isMobile && this.props.isLoggedIn,
                            },
                          )}
                        >
                          <MainComponent {...this.props} />
                          <GlassPane
                            show={this.props.showGlassPane}
                            content={
                              this.props.glassPaneContent
                                ? this.props.glassPaneContent
                                : null
                            }
                          />

                          <ErrorDetailDialog />
                          <AppOutOfDateDialog />
                        </div>
                      </Route>
                    </Switch>
                  </div>
                </CorvanaUrlWrapper>
              </LastLocationProvider>
            </Router>
          </ToastProvider>
        </MaterialThemeProvider>
      </DndProvider>
    );
  }
}

const mapStateToProps = state => ({
  activeTab: state.main.activeTab,
  showGlassPane: state.main.showGlassPane,
  glassPaneContent: state.main.glassPaneContent,
  isLoggedIn: state.login.isLoggedIn,
  isMobile: state.main.isMobile,
  isAdvancedMode: state.main.advanced,
  showReAuthenticate: state.main.showReAuthenticate,
  pathname: state.storage.pathname,
  isDashletMode: state.dashlet?.isDashletMode,
});

const mapDispatchToProps = dispatch => ({
  setIsMobile: viewportWidth => {
    dispatch(Main.setIsMobile(viewportWidth));
  },
  setSystemDarkMode: isSystemDarkModeEnabled => {
    dispatch(Main.setSystemDarkMode(isSystemDarkModeEnabled));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
