import { memo } from 'react';
import { DefaultColorRows } from '../common/color-palette.const';
import { ColorRow } from '../color-row/color-row.component';
import { useColorPickerState } from './color-picker.hook';
import { PickerContainer } from './color-picker.styles';
import { DiscoverTextInput } from '../../form/text-input/text-input.component';

export const ColorPicker = memo<{
  color: string;
  setColor: (newColor: string) => void;
  colorRows?: string[][];
}>(({ color, setColor, colorRows = DefaultColorRows }) => {
  const { isValid, inputColor, onChange } = useColorPickerState({
    color,
    setColor,
  });
  return (
    <PickerContainer>
      {colorRows.map((colors, index) => (
        <ColorRow
          selectedColor={color}
          key={`color-row-${index}`}
          colors={colors}
          setColor={setColor}
        />
      ))}
      <div>
        <DiscoverTextInput
          onChange={onChange}
          error={!isValid}
          value={inputColor}
          startAdornment={'#'}
        />
      </div>
    </PickerContainer>
  );
});
