import _ from 'lodash';
import { useDiscoverOptionSelector } from '../../discovery-context/discovery.context';
import { useCallback } from 'react';
import { NULL_DISPLAY, NULL_TOKEN } from '../../../common';

export const useDispatchSetSelectionForSlicer = vizId => {
  const {
    value: currentSelected,
    updateValue: updateSlicerSelections,
  } = useDiscoverOptionSelector({ vizId, option: 'slicerSelections' });
  const dispatchSetSelectionForSlicer = useCallback(
    ({ options, name }) => {
      const slicerSelections = _(currentSelected)
        .reject({ name })
        .concat(
          _(options)
            .filter('isSelected')
            .map(({ option }) => ({
              option: option === NULL_DISPLAY ? NULL_TOKEN : option,
              name,
            }))
            .value(),
        )
        .value();
      updateSlicerSelections(slicerSelections);
    },
    [currentSelected, updateSlicerSelections],
  );
  return dispatchSetSelectionForSlicer;
};
