import Bars, { BarUtils } from '../../../common/d3/Bars';
import BarChartUtils from '../BarChartUtils';
import Util from '../../../common/Util';
import _ from 'lodash';
import * as ReactDOM from 'react-dom';
import { ChartTooltipData, ChartTooltipReportLinkNote } from '../chart-tooltip';
import * as d3 from 'd3';
import { Viz } from '../../VizUtil';
import { join as joinChartData } from '../ChartUtils';
import { METRIC_ORIENTATION } from '../../../common/d3/Axis';
import ColorManager from '../../../common/d3/ColorManager';
import { EMPTY_STRING_TOKEN } from '../../../common/Constants';
import { messages } from '../../../i18n';

class BarPlot {
  constructor(parameters) {
    const {
      vizId,
      valuesName,
      xAxisName,
      data,
      querySort,
      layout,
      width,
      height,
      showDataLabels,
      paletteOffset,
      chartPadding,
      presetPadding,
      defaultXAxisHeight,
      hoverFunction,
      labelRotation,
      isMobile,
      defaultYAxisWidth,
      customFormatToggles,
      disableTooltips,
      enableReportLink,
      linkToReport,
      nullHandling,
      focusedData,
      customFormatProps,
      isSecondaryPlot = false,
    } = parameters;
    this.vizId = vizId;
    this.valuesName = valuesName;
    this.xAxisName = xAxisName;
    this.data = data;
    this.querySort = querySort;
    this.layout = layout;
    this.width = width;
    this.height = height;
    this.showDataLabels = showDataLabels;
    this.paletteOffset = paletteOffset;
    this.chartPadding = chartPadding;
    this.defaultXAxisHeight = defaultXAxisHeight;
    this.defaultYAxisWidth = defaultYAxisWidth;
    this.presetPadding = presetPadding;
    this.hoverFunction = hoverFunction;
    this.labelRotation = labelRotation || 'vertical';
    this.isMobile = isMobile;
    this.scales = this.createScales(this.width);
    this.xScale = this.scales.groupScaleDefault;
    this.disableTooltips = disableTooltips;
    this.isSecondaryPlot = isSecondaryPlot;

    this.xScale =
      presetPadding && !_.isNil(presetPadding.groupPadding)
        ? this.xScale
            .paddingOuter(presetPadding.groupPaddingOuter)
            .paddingInner(presetPadding.groupPadding)
        : this.xScale;

    this.yScale = this.scales.yScaleDefault;
    this.shouldRotateAxisLabels = this.shouldRotateAxisLabels();

    const labelInfo = Util.calcLabelInfo(
      BarUtils.getDistinctGroups(this.data, d => BarChartUtils.getX0(d)),
    );

    this.maxChars = this.shouldRotateAxisLabels ? labelInfo.maxChars : 10;
    this.customFormatToggles = customFormatToggles;
    this.enableReportLink = enableReportLink && focusedData.length <= 1;
    this.linkToReport = linkToReport;
    this.nullHandling = nullHandling;
    this.focusedData = focusedData;
    this.customFormatProps = customFormatProps;
  }
  // eslint-disable-next-line lodash/prefer-constant
  getType() {
    return 'bars';
  }
  isActive() {
    return this.layout[this.valuesName].length > 0;
  }

  getYAxisLabel() {
    return Viz.getAxisLabel(this.layout[this.valuesName]);
  }
  getXAxisLabel() {
    if (!_.isEmpty(this.layout.XAXIS)) {
      return Viz.getAxisLabel(this.layout.XAXIS);
    } else if (!_.isEmpty(this.layout.COLUMNS)) {
      return Viz.getAxisLabel(this.layout.COLUMNS);
    }
  }

  createScales(width) {
    const scales = BarUtils.getDefaultScales(
      this.data,
      width,
      this.height,
      d => BarChartUtils.getY(d),
      d => BarChartUtils.getX(d),
      d => BarChartUtils.getX0(d),
      this.presetPadding,
      this.querySort,
    );

    return scales;
  }

  shouldRotateAxisLabels() {
    if (this.getDistinctGroups() > 2) {
      // calculate the distance between two bars
      const distance = this.xScale.step();
      if (distance < 64) {
        // Go Vertical
        return true;
      }
    }
    return false;
  }

  getDistinctGroups() {
    return BarUtils.getDistinctGroups(this.data, d => BarChartUtils.getX0(d))
      .length;
  }

  getXScale() {
    return this.xScale;
  }

  getYScale() {
    return this.yScale;
  }

  getYDomain() {
    return [
      d3.min(this.data, d => BarChartUtils.getY(d)),
      d3.max(this.data, d => BarChartUtils.getY(d)),
    ];
  }

  setYScale(scale) {
    this.yScale = scale;
  }

  setWidth(width) {
    this.width = width;
    this.scales = this.createScales(this.width);
    this.xScale = this.scales.groupScaleDefault;

    this.xScale =
      this.presetPadding && !_.isNil(this.presetPadding.groupPadding)
        ? this.xScale
            .paddingOuter(this.presetPadding.groupPaddingOuter)
            .paddingInner(this.presetPadding.groupPadding)
        : this.xScale;

    this.yScale = this.scales.yScaleDefault;
  }
  setHeight(height) {
    this.height = height;
    this.scales = this.createScales(this.width);
    this.xScale = this.scales.groupScaleDefault;
    this.yScale = this.scales.yScaleDefault;
  }
  getPreferredWidth() {
    let adjustedChartWidth = this.width;
    let adjustedXScale = this.scales.xScaleDefault;
    while (
      this.layout[this.valuesName].length > 0 &&
      adjustedXScale.bandwidth() < 18
    ) {
      const currentWidth = adjustedXScale.bandwidth();
      adjustedChartWidth = (18 / currentWidth) * adjustedChartWidth;
      adjustedXScale = this.createScales(adjustedChartWidth).xScaleDefault;
    }

    return adjustedChartWidth;
  }

  getStepSize() {
    return this.scales.groupScaleDefault.step();
  }

  getXAxisHeight() {
    if (this.shouldRotateAxisLabels) {
      const labelInfo = Util.calcLabelInfo(
        BarUtils.getDistinctGroups(this.data, d => BarChartUtils.getX0(d)),
      );
      if (this.labelRotation === 'vertical') {
        return labelInfo.maxWidth + 24;
      } else {
        const radians = (35 * Math.PI) / 180;
        const height = Math.sin(radians) * labelInfo.maxWidth;
        return height + (this.isMobile ? 32 : 36);
      }
    }
    return this.defaultXAxisHeight;
  }

  getYAxisWidth() {
    return this.defaultYAxisWidth;
  }

  getPreferredHeight() {
    if (!this.shouldRotateAxisLabels) {
      return this.height;
    }
    return this.height - (this.getXAxisHeight() - this.defaultXAxisHeight);
  }

  getComponent(props) {
    return (
      <Bars
        ref={bars => {
          this.bars = bars;
          if (props.plotRef) {
            props.plotRef(bars);
          }
        }}
        {...props}
        isSecondaryPlot={this.isSecondaryPlot}
        yScale={this.yScale}
        height={this.height}
        width={this.width}
        data={this.data}
        querySort={this.querySort}
        layout={this.layout}
        valuesName={this.valuesName}
        chartPadding={this.presetPadding}
        showLabelsInsideBar={false}
        globalMouseTooltipEnabled={false}
        vizId={this.vizId}
        disableTooltips={this.disableTooltips}
        onHover={(data, x, y) => this.hoverFunction(data, 'bars', x, y)}
        enableReportLink={this.enableReportLink}
        nullHandling={this.nullHandling}
        focusedData={this.focusedData}
        offsetX={this.getOffsetX()}
        customFormatProps={this.customFormatProps}
      />
    );
  }

  getComponentRef() {
    return ReactDOM.findDOMNode(this.bars);
  }

  getMeasureOffsetInData() {
    return this.layout.LINES ? this.layout.LINES.length : 0;
  }

  getOffsetX() {
    return this.xScale.bandwidth ? this.xScale.bandwidth() / 2 : 0;
  }

  getTooltip(dataItem, formatters, globalTooltip, useFiscalCalendar) {
    // only show tooltip info for focused bars
    if (
      !_.isEmpty(this.focusedData) &&
      !_.some(this.focusedData, dataItem.COLUMNS)
    ) {
      return [];
    }
    const components = [
      <ChartTooltipData
        key={`chart-tooltip-data`}
        data={dataItem.tooltipInfo}
        useFiscalCalendar={useFiscalCalendar}
      />,
    ];
    if (this.enableReportLink) {
      components.push(
        <ChartTooltipReportLinkNote name={this.linkToReport.name} />,
      );
    }
    return components;
  }

  getLegendTitle(isComboComponent = false) {
    if (isComboComponent) {
      if (!_.isEmpty(this.layout.COLUMNS)) {
        return messages.stackBar.columnsShelf;
      } else {
        return '';
      }
    }
    if (!_.isEmpty(this.layout.COLUMNS)) {
      return joinChartData(this.layout.COLUMNS.map(f => f.name));
    } else if (!_.isEmpty(this.layout.VALUES)) {
      return messages.stackBar.valuesShelf;
    } else {
      return '';
    }
  }

  getLegendData() {
    const { vizId } = this;
    const sortedData = BarUtils.getSortedList(this.data, this.querySort);

    const legendData = sortedData.reduce((accum, d) => {
      const offset = this.layout.LINES ? this.layout.LINES.length : 0;
      let name = BarChartUtils.getX(
        d,
        this.layout,
        this.valuesName,
        'COLUMNS',
        offset,
      );
      if (name === '') {
        name = EMPTY_STRING_TOKEN;
      }

      if (!_.isEmpty(name)) {
        // Use primitive data type for set value equality
        const o = JSON.stringify({
          label: name,
          shape: 'SQUARE',
          info: d.COLUMNS,
        });
        accum.add(o);
      }
      return accum;
    }, new Set());

    let list = Array.from(legendData).map(o => {
      const parsed = JSON.parse(o);
      return parsed;
    });
    list = list.map(parsed => {
      const color = ColorManager.getColor(vizId, parsed.measureLabel);
      return { ...parsed, color, sort: parsed.measureLabel };
    });
    return list;
  }

  globalMouseMove() {
    // Bar has no global tooltip
  }

  getMetricOrientation() {
    return METRIC_ORIENTATION.VERTICAL;
  }
}

export default BarPlot;
