import { css } from '@emotion/react';
import { font } from './mixins';

export const d3Styles = ({
  theme: {
    colors: {
      Black,
      BrushGrabberFill,
      ChartTooltipBackground,
      ChartTooltipHoverBackground,
      ContentBackground,
      CorvanaBackgroundDark,
      LineChartDotStroke,
      ScoreRectFill,
      Strokes,
      CorvanaLightText,
      LightFontWeight,
      StrongFontWeight,
      MediumFontWeight,
      negativeColor,
      ContentText,
      CorvanaMediumBorder,
      DropdownTextPrimary,
      ChartLabelTextColor,
      positiveColor,
      anomalyColor,
      CorvanaBrightBlue,
      baseFontColor,
      MediumBorder,
      PanelBackground,
      CorvanaButtonIconColor,
      DarkBorderColor,
      TooltipBackgroundColor,
      FunnelBackgroundColor,
      ValLineStroke,
      ValAreaFill,
      RequiredTextColor,
    },
  },
}) => css`
  .canvas-container {
    width: 100%;
    height: 100%;
  }
  .valLine {
    fill: none;
    stroke-width: 2px;
    stroke: ${ValLineStroke};
  }
  .anomalyLine {
    fill: none;
    stroke-width: 2px;
    stroke: ${negativeColor};
  }
  .valArea {
    fill: ${ValAreaFill};
  }
  .valCircle {
    fill: ${RequiredTextColor};
  }
  .brushArea {
    fill: ${Strokes};
  }
  .brushLine {
    fill: none;
    stroke-width: 1px;
    stroke: ${CorvanaLightText};
  }
  .timeAxis .tick text {
    text-anchor: middle;
    ${font({ size: '12px', weight: LightFontWeight })};
    color: ${ContentText};
  }
  /** Anomaly Chart **/
  .anomalyBar {
    fill: ${negativeColor};
    opacity: 0.15;
  }
  .yAxisLabel {
    ${font({ size: '11px' })};
  }
  .yAxisGrid .tick line {
    stroke: ${CorvanaMediumBorder};
    stroke-width: 1px;
    opacity: 0.48;
  }
  .yAxisGrid .tick.zeroLine line {
    stroke: ${ContentText};
    stroke-width: 1px;
  }
  .yAxisGrid .domain {
    stroke: ${CorvanaMediumBorder};
    stroke-width: 1px;
  }
  .yAxisTickFont {
    ${font({ size: '12px', weight: LightFontWeight })};
  }
  .yAxisTickFont .domain {
    stroke: ${ContentText};
    stroke-width: 1px;
  }
  .yAxisTickFont .tick text {
    fill: ${ContentText};
  }
  .xAxisLabel {
    ${font({ size: '11px', weight: LightFontWeight })};
  }
  .xAxisTickFont {
    ${font({ size: '12px', weight: LightFontWeight })};
    fill: ${ContentText};
  }
  .xAxisTickFont .domain {
    stroke: ${CorvanaMediumBorder};
    stroke-width: 1px;
  }
  .xAxisTickFont .tick text {
    fill: ${ContentText};
  }
  .xAxisTickFont .tick line {
    stroke: ${CorvanaMediumBorder};
    stroke-width: 1px;
    opacity: 0.48;
  }
  .zoom {
    cursor: move;
    fill: none;
    pointer-events: all;
  }
  .range-brush > .brush-outline {
    stroke: ${CorvanaLightText};
    stroke-width: 1px;
    fill: transparent;
  }
  .range-brush .brush-grabber-left,
  .range-brush .brush-grabber-right {
    cursor: ew-resize;
  }
  .range-brush .brush-grabber {
    stroke: ${DarkBorderColor};
    stroke-width: 2;
    y: 17;
    width: 4px;
    height: 14px;
    rx: 2;
    fill: ${BrushGrabberFill};
  }
  .range-brush .brush-grabber-line {
    fill: ${DarkBorderColor};
    width: 2px;
    x: 1;
    height: 48px;
  }
  .brush > .overlay-left,
  .brush .overlay-right {
    fill: ${ContentBackground};
    fill-opacity: 0.52;
  }
  .brush > .overlay-border {
    fill: ${DarkBorderColor};
    height: 2px;
    stroke: none;
  }
  .brush > .selection {
    fill: none;
    fill-opacity: 0;
    stroke: none;
  }
  /**/
  /* Monitor Tooltip*/
  /**/
  .dateText {
    fill: ${ContentBackground};
  }
  .valueText {
    ${font({ size: '18px', weight: StrongFontWeight })}
    fill: ${ContentBackground};
  }
  .tooltipRect {
    fill: ${TooltipBackgroundColor};
    fill-opacity: 0.9;
  }
  .chart-tooltip-view-details-container {
  }
  .chart-tooltip-view-details {
    display: inline-block;
    padding: 5px 10px;
    color: ${ContentBackground};
    text-align: center;
    white-space: nowrap;
    background-color: ${ChartTooltipBackground};
    cursor: pointer;

    &:hover {
      background-color: ${ChartTooltipHoverBackground};
    }
  }
  .upArrow,
  .downArrow,
  .rightArrow,
  .leftArrow {
    fill: ${TooltipBackgroundColor};
    fill-opacity: 0.9;
  }
  .scoreValue {
    ${font({ size: '12px', weight: StrongFontWeight })}
    fill: ${ContentBackground};
  }
  .scoreLabel {
    ${font({ size: '12px' })}
    fill: ${ContentBackground};
  }
  .tooltipLine {
    stroke-dasharray: 4, 4;
    stroke: ${CorvanaBackgroundDark};
    stroke-width: 1;
  }
  .scoreRectAnomalous {
    fill: ${negativeColor};
  }
  .scoreRectRegular {
    fill: ${ScoreRectFill};
  }
  .viz-chart .bar-label {
    ${font({ size: '10px', weight: LightFontWeight })}
  }
  .stack-total-outside {
    ${font({ size: '14px', weight: MediumFontWeight })}
    fill: ${DropdownTextPrimary};
    color: ${DropdownTextPrimary};
  }
  .stack-total-outside.dim {
    opacity: 0.16;
  }
  .bar.dim,
  .stack.dim {
    fill-opacity: 0.16;
  }
  .bar-label {
    ${font({ size: '12px', weight: LightFontWeight })}
    text-anchor: middle;
    fill: ${ChartLabelTextColor};
  }
  .bar-label.dim {
    opacity: 0.16;
  }
  .legend-item.dim {
    opacity: 0.16;
  }
  .legend-text {
    ${font({ size: '14px', weight: LightFontWeight })}
  }
  .score-line {
    fill: none;
    stroke-width: 2;
    stroke: ${positiveColor};
  }
  .anomaly-line {
    fill: none;
    stroke-width: 2;
    stroke: ${anomalyColor};
  }
  .anomaly-waterline {
    fill: none;
    stroke-width: 1;
    stroke-dasharray: 5 5;
    stroke: ${CorvanaBrightBlue};
  }
  .anomaly-sensitivity-chart.with-x-axis-labels .xAxisTickFont .tick text {
    display: inherit;
  }
  .anomaly-sensitivity-chart .xAxisTickFont {
    fill: none;
  }
  .anomaly-sensitivity-chart .xAxisTickFont .tick text {
    display: none;
  }
  .anomaly-sensitivity-chart .yAxisTickFont {
    ${font({ size: '12px', weight: LightFontWeight })}
  }
  .anomaly-sensitivity-chart .yAxisTickFont .domain {
    display: inherit;
    stroke: ${CorvanaMediumBorder};
    stroke-width: 1px;
  }
  .anomaly-sensitivity-chart .yAxisTickFont g.tick:last-of-type text {
    alignment-baseline: hanging;
  }
  .anomaly-sensitivity-chart .yAxisTickFont g.tick text {
    alignment-baseline: text-after-edge;
  }
  .anomaly-sensitivity-chart .y-axis-label {
    ${font({ size: '12px', weight: LightFontWeight })}
    text-anchor: middle;
    color: ${baseFontColor};
  }
  .anomaly-sensitivity-chart .timeAxis .tick text {
    ${font({ size: '12px', weight: LightFontWeight })}
  }
  .legend-dashed-line {
    stroke-width: 1px;
    stroke-dasharray: 2px 2px;
  }
  .legend-line {
    stroke-width: 1px;
  }
  .scrollBarFrame {
    stroke: ${MediumBorder};
    fill: ${PanelBackground};
  }
  .scrollHandle > rect {
    stroke: ${PanelBackground};
    fill: ${CorvanaButtonIconColor};
  }
  .line-chart-line {
    stroke-width: 2px;
    fill: none;
    pointer-events: stroke;
  }
  .line-chart-line.dim {
    stroke-opacity: 0.16;
  }
  .line-chart-line.dashed {
    stroke-dasharray: 4;
    stroke-dashoffset: 1;
  }
  .line-chart-dot {
    stroke: ${ContentBackground};
    stroke-width: 2px;
    pointer-events: all;

    &.bold {
      stroke: ${LineChartDotStroke};
    }
  }
  .line-chart-dot.dim {
    fill-opacity: 0.16;
    stroke-opacity: 0.16;

    &.hovered {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
  .line-chart-dot-inner-border {
    stroke: ${ContentBackground};
    stroke-width: 1px;
    fill: none;

    &.focused {
      stroke: ${Black};
    }

    &.bold {
      stroke: ${LineChartDotStroke};
    }

    &.dim {
      stroke: transparent;

      .hovered {
        stroke: ${ContentBackground};
      }
    }
  }
  .line-chart-dot-last {
    stroke: ${anomalyColor};
    stroke-width: 2px;
    pointer-events: all;
    fill: ${anomalyColor};
  }
  .line-chart-dot-last.dim {
    fill-opacity: 0.16;
  }
  .line-chart-dot-last-inner-border {
    stroke: ${anomalyColor};
    stroke-width: 1px;
    fill: ${anomalyColor};
  }
  .line-chart-dot-last-inner-border.focused {
    stroke: ${anomalyColor};
  }
  .line-chart-tooltip-indicator {
    stroke: ${ChartLabelTextColor};
    stroke-dasharray: 2px 2px;

    &.bold {
      stroke: ${LineChartDotStroke};
      stroke-width: 0.1em;
      stroke-dasharray: none;
    }
  }
  .captureMouseEvents {
    pointer-events: all;
  }
  .viz-chart .primaryTargetsPlot .bar-group .bar {
    fill-opacity: 0.35;
  }
  .viz-chart .primaryTargetsPlot .bar-group .bar.dim {
    fill-opacity: 0.16;
  }
  .funnel-delta {
    ${font({ size: '14px', weight: MediumFontWeight })}
    color: ${DropdownTextPrimary};
    fill: ${DropdownTextPrimary};
  }
  .funnel-delta tspan.light {
    font-weight: ${LightFontWeight};
  }
  .funnel-arrow.up {
    fill: ${DropdownTextPrimary};
  }
  .funnel-arrow.down {
    fill: ${DropdownTextPrimary};
  }
  .stack-bg {
    display: none;
  }
  .funnel .stack-bg {
    display: block;
    fill: ${FunnelBackgroundColor};
  }
`;
