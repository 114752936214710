import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { messages } from '../../../i18n';
import { useNumberFormatter } from '../number';

export const use18nFormatter = () => {
  const { formatNumber } = useNumberFormatter();

  const formatI18n = useCallback(
    (message: string, ...inputs) => {
      const formattedInputs = _.map(inputs, input => {
        if (_.isNumber(input)) {
          return formatNumber(input);
        }
        return input;
      });
      return messages.formatString(message, ...formattedInputs);
    },
    [formatNumber],
  );
  return {
    formatI18n,
  };
};

export const use18nFormattedString = (message: string, ...inputs) => {
  const { formatI18n } = use18nFormatter();
  const formatted = useMemo(() => {
    return formatI18n(message, ...inputs);
  }, [formatI18n, message, inputs]);
  return formatted;
};
