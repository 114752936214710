import { useCallback, useMemo, memo } from 'react';
import { useDispatch } from 'react-redux';
import ColorManager from '../../../common/d3/ColorManager';
import { messages } from '../../../i18n';
import { ColorPickerRow, ColorSeriesLink } from '../../../ui/colors';
import { LineChartUtils } from '../../charts/LineChartUtils/LineChartUtils';
import { StyledColorSettings } from './color-settings.styles';
import Discover from '../../../common/redux/actions/DiscoverActions';
import _ from 'lodash';
import { DisableableDiv, useDiscoverTheme } from '../../../common/emotion';

export const ColorSetting = memo<{
  item;
  vizId;
  customColors;
}>(({ item, vizId, customColors }) => {
  const { label, shape, info } = item;
  const theme = useDiscoverTheme();
  const itemLabel = useMemo(() => {
    if (shape === 'LINE') {
      return LineChartUtils.getColorKey(info);
    }
    return label;
  }, [label, shape, info]);

  const dispatch = useDispatch();
  const onColorChange = useCallback(
    newColor => {
      const newColors = _.assign({}, customColors, { [itemLabel]: newColor });
      dispatch(Discover.updateCustomColors(vizId, newColors));
    },
    [customColors, itemLabel, dispatch, vizId],
  );

  const color = ColorManager.getColor(vizId, itemLabel, theme);
  const selColor = _.get(customColors, [itemLabel], color);

  return (
    <ColorPickerRow
      key={item.label}
      label={item.label}
      selectedColor={selColor}
      onSelect={onColorChange}
    />
  );
});

export const ColorSettings = memo<{
  chartType;
  legendData;
  vizId;
  customColors;
}>(({ chartType, legendData, vizId, customColors }) => {
  const hasCustomColors = !_.isEmpty(customColors);
  const dispatch = useDispatch();
  const resetColors = useCallback(() => {
    dispatch(Discover.updateCustomColors(vizId, null));
  }, [dispatch, vizId]);
  if (chartType === 'pivot') {
    return null;
  }

  return (
    <StyledColorSettings className='target-settings'>
      <div className='viz-formatting-header'>
        <DisableableDiv className='viz-formatting-header-left'>
          {messages.formatting.colors}
        </DisableableDiv>
      </div>
      <div className='viz-formatting-form-group'>
        {legendData.map(item => (
          <ColorSetting
            key={`color-setting-${_.kebabCase(item.label)}`}
            customColors={customColors}
            item={item}
            vizId={vizId}
          />
        ))}
        <div className='viz-formatting-links'>
          <ColorSeriesLink disabled={!hasCustomColors} onClick={resetColors}>
            {messages.formatting.resetToDefaultColors}
          </ColorSeriesLink>
        </div>
      </div>
    </StyledColorSettings>
  );
});
