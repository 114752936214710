import { Component, ComponentClass } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Discover from '../../../common/redux/actions/DiscoverActions';
import { FullScreenPortal } from '../../../common/widgets/dialogs/full-screen-portal';
import { branch, compose, pure, renderComponent } from 'react-recompose';
import StringFilter from '../StringFilter';
import DateFilter from '../DateFilter';
import NumericFilter from '../NumericFilter';
import { BooleanFilter } from '../BooleanFilter';
import { validateFilter } from '../FilterValidation';
import { Viz } from '../../VizUtil';
import { messages } from '../../../i18n';
import {
  FilterDialogTypes,
  IFilter,
} from '../../../datasets/interfaces/filter.interface';
import { ButtonContainer, PrimaryButton, SecondaryButton } from '../../../ui';
import { AggregateFilter } from '../aggregate-filter';
import { Types } from '../../../common';

interface IProps {
  show: boolean;
  isFilterAggregateDialog: boolean;
  field: any;
  vizId: string;
  filter: IFilter;
  vizCalcs: any;
  doCancel: any;
  title: string;
  applyFilter: any;
}

interface IState {
  isValid: boolean;
}

class FilterDialogUnconnected extends Component<IProps, IState> {
  static defaultProps = {
    title: 'filters.dialogTitle',
  };

  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
    };
  }

  componentDidUpdate() {
    if (!_.isNil(this.props.filter)) {
      const isValid = validateFilter(this.props.filter, this.props.field);
      if (isValid != this.state?.isValid) {
        this.setState({ isValid });
      }
    }
  }

  renderSpecificFilter() {
    const {
      show,
      field,
      vizId,
      filter,
      vizCalcs,
      isFilterAggregateDialog,
    } = this.props;
    if (!show) {
      return;
    }

    switch (field.attributeType) {
      case Types.STRING:
      case Types.STRING_CALC:
        return isFilterAggregateDialog ? (
          <AggregateFilter vizId={vizId} />
        ) : (
          <StringFilter
            vizId={vizId}
            field={field}
            filter={filter}
            vizCalcs={vizCalcs}
          />
        );
      case Types.TIMESTAMP:
        return isFilterAggregateDialog ? (
          <AggregateFilter vizId={vizId} />
        ) : (
          <DateFilter vizId={vizId} field={field} filter={filter} />
        );
      case Types.BOOLEAN:
        return <BooleanFilter field={field} filter={filter} />;
      default:
        return field.calcType === Types.BOOLEAN ? (
          <BooleanFilter field={field} filter={filter} />
        ) : (
          <NumericFilter vizId={vizId} field={field} filter={filter} />
        );
    }
  }

  render() {
    const { isFilterAggregateDialog, title, filter, field } = this.props;
    const dialogTitle = messages.formatString(
      _.get(messages, title, title),
      isFilterAggregateDialog
        ? `${field.name} (${messages.filters.aggregationTitle})`
        : field.name,
    );

    return (
      <FullScreenPortal
        titlePanel={dialogTitle}
        className='filter-dialog'
        buttonGroup={
          <ButtonContainer>
            <SecondaryButton
              onClick={() => {
                this.props.doCancel();
              }}
            >
              {messages.cancel}
            </SecondaryButton>
            <PrimaryButton
              disabled={!this.state.isValid}
              onClick={() => {
                this.props.applyFilter(filter, isFilterAggregateDialog);
              }}
            >
              {messages.filters.applyButtonText}
            </PrimaryButton>
          </ButtonContainer>
        }
      >
        <div className='filter-dialog-content'>
          {this.renderSpecificFilter()}
        </div>
      </FullScreenPortal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // if we have a saved filter on the open discovery, start from that
  const discovery = state.discover.openDiscoveries[ownProps.vizId].present;
  const vizOptions = discovery.viz.options;
  const isFilterAggregateDialog =
    state?.discover?.showFieldFilterDialog === FilterDialogTypes.AGGREGATE;
  const activeFilter = state?.discover?.activeFilter;
  const field = state.discover.activeFilterField;
  let filter;
  let savedFilter;

  if (!_.isNil(vizOptions.filters) && !_.isNil(field)) {
    let filters = {};
    // filters are saved as a JSON string, if they haven't been parsed yet we need to do that here
    if (_.isString(vizOptions.filters)) {
      try {
        filters = JSON.parse(vizOptions.filters);
      } catch (error) {
        console.warn('Failed to parse filters', vizOptions.filters);
      }
    } else {
      filters = { ...vizOptions.filters };
    }
    if (!_.isNil(filters[field.name])) {
      savedFilter = filters[field.name];
    }
  }
  const vizCalcs = Viz.getCalcsFromViz(discovery.viz);

  if (!_.isEmpty(activeFilter)) {
    filter = { ...activeFilter };
  } else if (!_.isNil(savedFilter)) {
    const isSavedFilterAggregate =
      savedFilter.dialogType === FilterDialogTypes.AGGREGATE;
    if (!(!isFilterAggregateDialog && isSavedFilterAggregate)) {
      filter = { ...savedFilter };
    }
  }

  return {
    show: !!state?.discover?.showFieldFilterDialog,
    isFilterAggregateDialog,
    field,
    filter,
    vizCalcs,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    applyFilter: filter => {
      dispatch(Discover.applyFieldFilter(ownProps.vizId, { ...filter }));
      dispatch(Discover.hideFieldFilterDialog());
      dispatch(Discover.setActiveFieldFilter(null));
    },
    doCancel: () => {
      dispatch(Discover.hideFieldFilterDialog());
      dispatch(Discover.setActiveFieldFilter(null));
    },
  };
};

export const FilterDialog = compose(
  (connect as any)(mapStateToProps, mapDispatchToProps),
  (branch as any)(
    ({ field }) => _.isNil(field),
    renderComponent(() => <div />),
  ),
  pure,
)(FilterDialogUnconnected as ComponentClass<IProps>) as any;
