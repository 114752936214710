import { css } from '@emotion/react';
import { font } from '../mixins';

export const modalMobileStyles = ({
  theme: {
    colors: {
      MediumFontWeight,
      ContentBackground,
      RegularFontWeight,
      LoginButtonColor,
    },
  },
  isDashletMode,
}) => css`
  .prompt-dialog {
    ${isDashletMode
      ? `.in.prompt-dialog.modal,
    .prompt-dialog-backdrop {
      z-index: 80;
      top: 34px;
      height: calc(100% - 34px);
      width: 100%;
      &>.modal-lg {
        width: var(--viewport-width)
      }
    }`
      : ''}
  }
  .saveButtonGroup .btn {
    margin: 10px 10px 0 0;
  }
  .prompt-dialog--mobile .modal-header {
    opacity: 1;
    margin: 0;
    padding: 0;
    ${font({ size: '24px', weight: MediumFontWeight })}
    text-align: center;
  }
  .prompt-dialog--mobile .modal-title {
    color: ${ContentBackground};
  }
  .prompt-dialog--mobile .modal-body {
    color: ${ContentBackground};
    padding: 0;
    margin: 30px 0;
  }
  .prompt-dialog--mobile .modal-content {
    padding: 15px 30px;
  }
  .prompt-dialog--mobile .modal-body .promptMessage {
    margin: 0 0 30px 0;
    text-align: left;
    padding: 0;
  }
  .prompt-dialog--mobile .modal-body .saveButtonGroup {
    display: flex;
  }
  .prompt-dialog--mobile .regular-modal .modal-body .btn {
    height: 40px;
    ${font({
      size: '14px',
      weight: RegularFontWeight,
      color: ContentBackground,
    })}
    margin: 0 0 30px 0;
    width: 100%;
  }
  .prompt-dialog--mobile .regular-modal .modal-body .btn-primary {
    background-color: ${LoginButtonColor};
    border-color: ${LoginButtonColor};
  }
  .prompt-dialog--mobile .regular-modal .modal-body .btn-default {
    background-color: transparent;
    border-color: transparent;
  }
`;
