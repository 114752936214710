import { VizSaveDialog } from './component';
import { compose, pure } from 'react-recompose';
import { connect } from 'react-redux';
import { endsWith } from 'lodash';
import { isReadOnly } from '../../common/redux/selectors/AccountSelectors';

export default compose(
  connect(state => {
    const { openDiscoveries, closeDialogAfterSave = false } = state.discover;
    return {
      isReadOnlyUser: isReadOnly(state.account),
      closeDialogAfterSave,
      openDiscoveryNames: Object.values(openDiscoveries)
        .map(d => d.present.name)
        .filter(n => {
          return endsWith(n, 'copy');
        }),
    };
  }),
  pure,
)(VizSaveDialog);
