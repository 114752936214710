import {
  compose,
  onlyUpdateForKeys,
  pure,
  withPropsOnChange,
} from 'react-recompose';
import {
  BaseCartesianChart,
  ShouldEnableReportLinkHOC,
} from './base-cartesian-chart';
import { connect } from 'react-redux';
import AreaPlot from './plots/AreaPlot';
import { LineChartUtils } from './LineChartUtils';
import NoDataIfHOC from './NoDataIfHOC';

class AreaChart extends BaseCartesianChart {
  constructor(props) {
    super(props, [{ shelf: 'VALUES', Plot: AreaPlot, dataId: 'areaData' }]);
  }
}

export default compose(
  pure,
  ShouldEnableReportLinkHOC,
  connect(
    BaseCartesianChart.mapStateToProps,
    BaseCartesianChart.mapDispatchToProps,
  ),
  withPropsOnChange(['queryResults'], props => {
    const areaData = LineChartUtils.transformResultToArea(
      props.queryResults,
      props.viz.layout,
    );
    return { areaData, secondaryUsesPrimaryScale: true };
  }),
  onlyUpdateForKeys([
    'areaData',
    'lineData',
    'height',
    'width',
    'id',
    'enableReportLink',
  ]),
  NoDataIfHOC(props => props.areaData.LINES.length === 0),
)(AreaChart);
