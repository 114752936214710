import { css } from '@emotion/react';
import { backgroundImage } from '../../common/emotion/mixins';

export const sugarToastStyles = ({
  appUrl,
  theme: {
    darkMode,
    colors: { SugarToastBackground, PanelShadowColor, ContentText },
  },
}) => {
  const backgroundPath = darkMode ? 'flat_stack_dark.svg' : 'flat_stack.svg';
  return css`
    #sugar-toast {
      color: ${ContentText};
      max-width: 600px;
      min-height: 40px;
      border-radius: 8px;
      background-color: ${SugarToastBackground};
      box-shadow: 0px 0px 8px 0px ${PanelShadowColor};
      display: inline-flex;
      align-items: flex-start;
      column-gap: 20px;
      padding: 15px;
      font-size: 14px;
      ${backgroundImage({ appUrl, path: backgroundPath })};
      background-size: 173px;
      background-position-x: right;
      background-position-y: top;
      background-repeat: no-repeat;

      span.toast-notice {
        font-weight: 700;
      }
    }
  `;
};
