import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { getActiveSlicers } from '../../../common/redux/selectors/viz-selectors';
import uuid from 'uuid';

import HeaderNavigation from '../../../components/HeaderNavigation';
import SlideInOut from '../../../components/SlideInOut';
import { messages } from '../../../i18n';

import { useSelector } from 'react-redux';
import { SlicerWidget } from '../slicer-widget';
import {
  MobileSlicerContainer,
  MobileSlicerModal,
  MobileSlicerRowContainer,
} from './mobile-slicers.styles';

import Close from '../../../../images/sdd/icon_close.svg';

export function MobileSlicers({ show, setShow, vizId }) {
  const slicers = useSelector(state =>
    _.map(getActiveSlicers(state, { discoveryId: vizId } as any), 'name'),
  );

  const [currentOpen, setCurrentOpen] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [shouldRender, setShouldRender] = useState(true);
  useEffect(() => {
    if (show) {
      setShouldRender(true);
    }
  }, [show]);
  useEffect(() => {
    return () => {
      if (_.isNumber(timeoutId)) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);
  const onBack = useCallback(
    (force = false) => {
      if (!currentOpen || force) {
        setShouldRender(false);
        const _timeoutId = setTimeout(() => {
          setShow(false);
        }, 150);
        setTimeoutId(_timeoutId);
      }

      setCurrentOpen(null);
    },
    [currentOpen, setShow, setTimeoutId],
  );
  const slicerNames = useMemo(() => {
    return slicers.filter(name => (currentOpen ? currentOpen === name : true));
  }, [slicers, currentOpen]);
  const toggles = useMemo(() => {
    return _(slicerNames)
      .map(name => {
        return [
          name,
          isOpen => {
            if (isOpen) {
              setCurrentOpen(name);
            } else {
              setCurrentOpen(null);
            }
          },
        ];
      })
      .fromPairs()
      .value();
  }, [slicerNames]);
  const id = useMemo(() => `slicer_${uuid()}`, []);
  return (
    <MobileSlicerModal show={show} onHide={onBack} enforceFocus={false}>
      <SlideInOut show={show}>
        <div id={id} />
        <div>
          <HeaderNavigation
            title={currentOpen || messages.slicer.label}
            left={({ LinkButton, Chevron }) => {
              return (
                <LinkButton
                  aria-label={messages.slicer.back}
                  onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    onBack();
                  }}
                >
                  {currentOpen ? (
                    <Close aria-label={messages.close} />
                  ) : (
                    <Chevron />
                  )}{' '}
                  {currentOpen ? '' : messages.slicer.back}
                </LinkButton>
              );
            }}
          />
          {shouldRender && (
            <MobileSlicerContainer>
              {slicerNames.map(name => (
                <MobileSlicerRowContainer key={`slicer-widget-${name}`}>
                  <SlicerWidget
                    name={name}
                    onToggle={toggles[name]}
                    enabled={currentOpen === name}
                  />
                </MobileSlicerRowContainer>
              ))}
            </MobileSlicerContainer>
          )}
        </div>
      </SlideInOut>
    </MobileSlicerModal>
  );
}
