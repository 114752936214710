import { Component, Fragment } from 'react';
import _ from 'lodash';
import { ChartSpecs } from '../../discovery/ChartSpecs';
import { AutoSizer, Column, SortDirection, Table } from 'react-virtualized';
import { TextSearchField, MobileListHeader } from '../../components/ui/form';
import SearchCollection from '../../common/SearchCollection';
import { IfTrue } from '../../components/ui/conditional';
import styled from '@emotion/styled';
import { HeaderCellRendererWithSortIndicator } from '../DatasetPreviewTable/ui';
import { messages } from '../../i18n';
import { css } from '@emotion/react';
import { SugarIcon } from '../../icons';
import { FilterWidget } from '../../discovery/filter-widget/filter-widget.component';
import { FilterWidgetContextProvider } from '../../discovery/filter-widget/filter-widget.context';

const TableHeaders = {
  chartType: 'mobile.libraryType',
  name: 'mobile.libraryName',
  actions: 'mobile.libraryActions',
};

const EmptyView = styled.div(
  ({
    theme: { colors: { ContentBackground, TableBorderColor } = {} } = {},
  }) => css`
    width: 100%;
    height: 50px;
    line-height: 43px;
    font-size: 14px;
    background-color: ${ContentBackground};
    border-bottom: 1px solid ${TableBorderColor};
    height: 50px;
    text-align: center;
  `,
);

const Root = styled.div(
  ({ theme: { colors: { TableHeaderColor } = {} } = {} }) => css`
    .ReactVirtualized__Table__headerRow {
      background-color: ${TableHeaderColor};
    }
  `,
);
const HeaderCell = styled.div`
  div {
    padding: 0;
  }
`;

class MobileLibrary extends Component {
  constructor(props) {
    super();
    this.state = {
      sortedList: props.sortedList,
      sortBy: props.sortBy,
      sortDirection: props.sortDirection,
    };
    this.onSearch = this.onSearch.bind(this);
  }

  getDatum(list, index) {
    return list[index % list.length];
  }

  onSearch(search) {
    this.props.setSearch(search);
  }

  sort({ sortBy, sortDirection }) {
    const sortedList = this.sortList({ sortBy, sortDirection });
    this.setState({ sortBy, sortDirection, sortedList });
  }

  sortList({ sortBy, sortDirection }) {
    const list = this.state.sortedList;
    const sortedList = _.sortBy(list, [sortBy]);
    return sortDirection === SortDirection.DESC
      ? sortedList.reverse()
      : sortedList;
  }

  open(content) {
    const { discoveryType } = content;
    switch (discoveryType) {
      case 'MONITOR':
        this.props.openDiscovery(content);
        break;
      case 'VISUALIZATION':
        this.props.openVizualization(content);
        break;
    }
  }

  onSetTags(tags) {
    this.props.setSearchTags(tags);
  }

  render() {
    /* Search */
    const { search, tags } = this.props;
    let searchedData = [...this.state.sortedList];
    if (!_.isEmpty(search)) {
      const searcher = new SearchCollection(searchedData, {
        keys: ['name'],
      });
      searchedData = searcher.search(search);
    }

    const filteredContent = _.isEmpty(tags)
      ? searchedData
      : _.filter(
          searchedData,
          row => !_.isEmpty(_.intersection(row.tags, tags)),
        );

    const specs = {
      headerRowHeight: 40,
      rowHeight: 56,
      rowCount: filteredContent.length,
    };

    const rowGetter = ({ index }) => this.getDatum(filteredContent, index);

    const headerCellRenderer = ({ dataKey }) => {
      return (
        <HeaderCell className='header-cell'>
          {_.get(messages, TableHeaders[dataKey], TableHeaders[dataKey])}
        </HeaderCell>
      );
    };

    const headerCellRendererWithSortIndicator = ({
      dataKey,
      sortDirection,
    }) => {
      return (
        <HeaderCell className='header-cell'>
          <HeaderCellRendererWithSortIndicator
            allowSortableIcon={false}
            label={_.get(
              messages,
              TableHeaders[dataKey],
              TableHeaders[dataKey],
            )}
            dataKey={dataKey}
            sortBy={this.state.sortBy}
            sortDirection={sortDirection}
          />
        </HeaderCell>
      );
    };

    const cellRenderer = ({ dataKey, cellData, rowData }) => {
      let content = <span />;
      switch (dataKey) {
        case 'chartType':
          if (rowData.discoveryType === 'VISUALIZATION') {
            const spec = ChartSpecs[cellData];
            content = _.isNil(spec) ? <span /> : spec.listIcon;
          }
          break;
        case 'name':
          content = cellData;
          break;
        case 'actions':
          content = (
            <div
              onClick={() => this.open(rowData)}
              style={{
                display: 'flex',
                width: '2rem',
                height: '2rem',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
              }}
            >
              <SugarIcon hover icon={'launch-lg'} />
            </div>
          );
          break;
      }
      return <div className='body-cell'>{content}</div>;
    };

    return (
      <Root className='library'>
        <MobileListHeader>
          <TextSearchField
            isMobile
            style={{ width: '100%', marginBottom: 8 }}
            placeholder={messages.mobile.searchByName}
            value={this.props.search}
            onChange={this.onSearch}
          />
          <FilterWidgetContextProvider>
            <FilterWidget
              placeholder={messages.tags.searchPlaceholder}
              disableAdd={true}
              disableSelectAll={false}
              onSave={t => this.onSetTags(t)}
            />
          </FilterWidgetContextProvider>
        </MobileListHeader>
        <IfTrue value={specs.rowCount <= 0}>
          <EmptyView>
            <span>No matches</span>
          </EmptyView>
        </IfTrue>
        <IfTrue value={specs.rowCount > 0}>
          <AutoSizer>
            {({ width, height }) => (
              <Fragment>
                <Table
                  height={height}
                  headerHeight={specs.headerRowHeight}
                  rowCount={specs.rowCount}
                  rowGetter={rowGetter}
                  rowHeight={specs.rowHeight}
                  width={width}
                  sort={this.sort.bind(this)}
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection}
                  onRowClick={({ rowData }) => {
                    this.open(rowData);
                  }}
                >
                  <Column
                    dataKey='chartType'
                    headerRenderer={headerCellRendererWithSortIndicator}
                    cellRenderer={cellRenderer}
                    width={width * 0.2}
                  />
                  <Column
                    dataKey='name'
                    headerRenderer={headerCellRendererWithSortIndicator}
                    cellRenderer={cellRenderer}
                    width={width * 0.6}
                  />
                  <Column
                    dataKey='actions'
                    headerRenderer={headerCellRenderer}
                    cellRenderer={cellRenderer}
                    width={width * 0.2}
                    disableSort
                  />
                </Table>
              </Fragment>
            )}
          </AutoSizer>
        </IfTrue>
      </Root>
    );
  }
}

export default MobileLibrary;
