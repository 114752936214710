import { css } from '@emotion/react';
import { IDiscoverEmotionTheme } from '..';
import { font, hbox } from '../mixins';

export const reactVirtualizedMobileStyles = ({
  theme: {
    darkMode,
    colors: {
      ContentText,
      TableHeaderBackground,
      TableBorderColor,
      TableBodyBackground,
      RegularFontWeight,
      LightFontWeight,
      TableHeaderTextColor,
    },
  },
}: {
  theme: IDiscoverEmotionTheme;
}) => css`
  /* Corvana Table Styling */
  .ReactVirtualized__Table__headerRow {
    background: ${TableHeaderBackground};
    border-top: 1px solid ${darkMode ? TableHeaderBackground : TableBorderColor};
    border-bottom: 1px solid
      ${darkMode ? TableHeaderBackground : TableBorderColor};
  }
  .ReactVirtualized__Table__row {
    background: ${TableBodyBackground};
    border-bottom: 1px solid ${TableBorderColor};
    display: flex;
  }
  .ReactVirtualized__Table__headerColumn:first-of-type,
  .ReactVirtualized__Table__rowColumn:first-of-type,
  .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    margin: 0px;
  }
  .ReactVirtualized__Table__headerRow .header-cell {
    ${font({
      size: '14px',
      weight: RegularFontWeight,
      color: darkMode ? TableHeaderTextColor : ContentText,
    })}
    text-transform: capitalize;
    min-width: 80px;
    padding: 8px 16px;
  }
  .ReactVirtualized__Table__row .body-cell {
    ${hbox()}
    ${font({
      size: '14px',
      weight: LightFontWeight,
      color: ContentText,
    })}
    align-items: center;
    background: ${TableBodyBackground};
    padding: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
