import _ from 'lodash';

const modules = {
  // type
};

export default class {
  static add(type, meta, value) {
    if (!modules[type]) {
      modules[type] = [];
    }

    if (meta.id) {
      // metas with IDs are treated specially. A registration with ID must be unique for the given component type
      const existing = this.get(type, { id: meta.id });
      if (!_.isNil(existing)) {
        existing.value = value;
        existing.meta = meta;
        return;
      }
    }
    // check to see if it already exists by the given metadata

    modules[type].push({
      metadata: meta,
      value,
    });
  }

  static get(type, meta) {
    if (!modules[type]) {
      return null;
    }

    // find by "metadata"
    const found = modules[type].find(entry =>
      _.isEqual(meta, _.pick(entry.metadata, Object.keys(meta))),
    );
    return found ? found.value : null;
  }

  static getAll(type, meta = {}) {
    if (!modules[type]) {
      return [];
    }
    return modules[type].reduce((accum, entry) => {
      if (_.isEqual(meta, _.pick(entry.metadata, Object.keys(meta)))) {
        accum.push(entry.value);
      }
      return accum;
    }, []);
  }
}
