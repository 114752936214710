import { graphql } from '@apollo/client/react/hoc';
import MonitorGql from './MonitorsGql';
import DiscoverQueries from './DiscoverQueries';

const Queries = {
  DeleteMonitor: propMappings =>
    graphql(MonitorGql.DeleteMonitor, {
      options: {
        // Update Monitors query, we delete opportuniticly but this makes sure the local state is consistent
        refetchQueries: [
          {
            query: MonitorGql.MonitorQuery,
          },
        ],
        // Manually remove item from cache
        update: (store, { data: { deleteMonitor } }) => {
          store.modify({
            fields: {
              monitors(preList, { readField }) {
                return preList.filter(
                  m => readField('id', m) !== deleteMonitor.id,
                );
              },
            },
          });
        },
      },
      props: propMappings,
    }),
  AddMonitor: propMappings =>
    graphql(MonitorGql.AddMonitorMutation, {
      options: {
        // Replace with opportunistic update
        refetchQueries: [
          { query: MonitorGql.MonitorQuery },
          { query: DiscoverQueries.DiscoveriesQuery },
        ],
      },
      props: ({ mutate }) => ({
        mutate,
        ...propMappings,
      }),
    }),
};
export default Queries;
