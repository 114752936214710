import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IDiscoverSubMenuProps } from './discover-sub-menu.interfaces';
import Discover from '../../../common/redux/actions/DiscoverActions';
import { IconByChartType } from '../util';
import { SubMenuComponent } from '../sub-menu';
import { messages } from '../../../i18n';
import { useOpenDiscoveriesSelector } from '../../../common/redux/selectors/viz-selector.hook';

export const DiscoverSubMenu = ({
  setOpenedSubMenu,
  unsetOpenedSubMenu,
  hideSidebar,
}: IDiscoverSubMenuProps) => {
  const openDiscoveries = useOpenDiscoveriesSelector();

  const dispatch = useDispatch();

  const setDisplayDiscovery = id => {
    dispatch(Discover.setDisplayDiscovery(id));
  };

  const handleDiscoverSubMenuClick = id => {
    setDisplayDiscovery(id);
    hideSidebar();
  };

  const setDiscoverOpenedSubMenu = useCallback(() => {
    setOpenedSubMenu('discover');
  }, [setOpenedSubMenu]);

  return (
    <SubMenuComponent
      setOpenedSubMenu={setDiscoverOpenedSubMenu}
      unsetOpenedSubMenu={unsetOpenedSubMenu}
      collapsedTitle={messages.nav.reports}
      menuItems={Object.entries(openDiscoveries).map(([key, { present }]) => ({
        key,
        icon: present?.viz?.chartType && IconByChartType[present.viz.chartType],
        label: present.name,
        handleOnItemClick: () => handleDiscoverSubMenuClick(present.id),
      }))}
    />
  );
};
