import React from 'react';
import { useFeatureFlagProvider } from './feature-flag.provider';
import { isEmpty } from 'lodash';

interface IFeatureFlagProps {
  withFeatures?: string[];
  withoutFeatures?: string[];
  children: React.ReactNode | React.ReactNode[];
}

export const FeatureFlag = ({
  withFeatures = [],
  withoutFeatures = [],
  children,
}: IFeatureFlagProps) => {
  const { featureFlags } = useFeatureFlagProvider();

  const isIncluded =
    isEmpty(withFeatures) || withFeatures.some(flag => featureFlags[flag]);
  const isExcluded = withoutFeatures.some(flag => featureFlags[flag]);
  if (isExcluded || !isIncluded) {
    return null;
  }

  return <>{children}</>;
};
