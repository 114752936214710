import { css } from '@emotion/react';
import { font, backgroundImage } from './mixins';

export const sliderStyles = ({
  appUrl,
  theme: {
    colors: {
      DropdownButtonBorderColor,
      Black,
      ContentBackground,
      CorvanaBrightBlue,
    },
  },
}) => {
  const SliderBackground = DropdownButtonBorderColor;
  const sliderSideWidth = '10px';
  return css`
    .anomalySlider {
    }
    .anomalySlider .slider {
      width: 100%;
      height: 50px;
    }
    .anomalySlider .handle {
      ${font({ size: '14px' })}
      text-align: center;
      ${backgroundImage({ appUrl, path: 'slider_background.svg' })}
      color: ${ContentBackground};
      cursor: pointer;
      top: 1px;
      width: 27px;
      height: 52px;
      line-height: 28px;
      z-index: 2;
    }
    .anomalySlider .bar-0 {
      margin-right: 10px;
      margin-left: 10px;
      margin-top: 43px;
      padding-top: 2px;
      background-color: ${CorvanaBrightBlue};
      z-index: 0;
    }
    .anomalySlider .bar-1 {
      margin-right: 10px;
      margin-left: 10px;
      margin-top: 43px;
      padding-top: 2px;
      background-color: ${SliderBackground};
      z-index: 0;
    }
    .anomalySlider .sliderSide {
      width: ${sliderSideWidth};
      height: 10px;
      display: inline-block;
      position: relative;
    }
    .anomalySlider .slide-left {
      position: absolute;
      top: 3px;
    }
    .anomalySlider .slide-right {
      position: absolute;
      top: -1px;
    }
    .anomalySlider .sliderCenter {
      display: inline-block;
      position: relative;
      width: calc(100% - (10px * 2));
    }
    .anomalySlider .previousHandle {
      ${font({ size: '14px' })}
      text-align: center;
      ${backgroundImage({ appUrl, path: 'previous_slider.svg' })}
      color: ${Black};
      top: 35px;
      width: 27px;
      height: 52px;
      line-height: 76px;
      position: absolute;
      z-index: 0;
      cursor: default;
    }
    .anomalySliderWithPrev {
      padding-bottom: 32px;
    }
  `;
};
