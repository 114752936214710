import _ from 'lodash';
import { useRef, useEffect, useCallback } from 'react';

export const useAutofocus = ({
  shouldFocus,
  passthroughRef,
}: {
  shouldFocus: boolean;
  passthroughRef?: (r: Element) => void;
}) => {
  const focusRef = useRef(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (shouldFocus && inputRef?.current) {
      focusRef.current = true;
      window.requestAnimationFrame(() => {
        inputRef?.current?.focus();
      });
    } else {
      focusRef.current = false;
    }
  }, [shouldFocus]);
  const refCallback = useCallback(
    tsf => {
      if (shouldFocus && tsf && !focusRef.current) {
        focusRef.current = true;
        tsf.focus();
      }
      if (_.isFunction(passthroughRef)) {
        passthroughRef(tsf);
      }
      inputRef.current = tsf;
    },
    [passthroughRef, shouldFocus],
  );
  return refCallback;
};
