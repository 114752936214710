import {
  childrenAreLeafs,
  createHeaders,
  getAllPossibleCombinations,
  getQueryResultObjects,
  isLeaf,
  splitTotalsFromData,
  TOTALS_FLAG,
} from './QueryPivotUtils';
import _, { map } from 'lodash';
import { nest } from 'd3';
import { NULL_DISPLAY, NULL_TOKEN } from '../../../common/Constants';
import PivotTableUtils from './PivotTableUtils';

export default class QueryPivot {
  constructor(
    queryResults,
    columnNames,
    rowsToPivot,
    colsToPivot,
    measures,
    ordinalFieldNameMap,
    sorts,
    withColumnGrandTotals = false,
  ) {
    // we need a single row to pivot on at a minimum (create one if needed)
    if (rowsToPivot.withOrdinals.length === 0) {
      queryResults = queryResults.map(r => {
        // add a fake row value at the end
        return [...r, 1];
      });

      if (!withColumnGrandTotals) {
        // also need to remove any totals/subtotals since they won't be used
        queryResults = splitTotalsFromData(queryResults).pivot;
      }
      rowsToPivot.withOrdinals.push('fakeColumn');
    }

    this.queryResults = queryResults;
    this.columnNames = columnNames;
    this.rowsToPivot = rowsToPivot;
    this.colsToPivot = colsToPivot;
    this.measures = measures;
    this.queryResultObjects = getQueryResultObjects(queryResults, columnNames);
    this.sorts = sorts;
    this.ordinalFieldNameMap = ordinalFieldNameMap;
    this.withColumnGrandTotals = withColumnGrandTotals;

    if (!_.isEmpty(queryResults)) {
      this.pivoted = this.d3Pivot();

      if (_.isEmpty(colsToPivot.withOrdinals)) {
        this.colHeaderData = [];
      } else {
        this.colHeaderData = createHeaders(
          this.pivoted.columns,
          this.measures,
          this.withColumnGrandTotals,
        );
      }
      this.allData = this.createTableData(true);
      this.bodyDataWithRowSubtotals = this.allData.filter(
        row => row.type === 'data' || row.type === 'rowSubtotal',
      );
      this.bodyData = this.bodyDataWithRowSubtotals.filter(
        row => row.type === 'data',
      );

      this.rowSubtotalData = this.bodyDataWithRowSubtotals.filter(
        row => row.type === 'rowSubtotal',
      );
      this.rowGrandTotals = this.allData.filter(row => row.type === 'rowTotal');
    } else {
      this.measurePositionToColumnIndexes = [];
      this.bodyDataWithRowSubtotals = [];
      this.bodyData = [];
      this.rowSubtotalData = [];
      this.rowGrandTotals = [];
      this.allData = [];
      this.colHeaderData = [];
    }
  }

  d3Pivot() {
    // hierarchy doesn't seem to be used
    const hierarchy = nest();

    const doPivots = (rowsToPivot, colsToPivot) => {
      rowsToPivot.forEach(name => {
        // add a grouping key to the hierarchy (d3 nest)
        hierarchy.key(d => d[name]);
      });

      colsToPivot.forEach(name => {
        hierarchy.key(d => d[name]);
      });

      const rowsOnly = nest();
      rowsToPivot.forEach(name => {
        rowsOnly
          .key(d => {
            // if we don't find the field name in the data, then we need to add a fakeColumn to pivot on
            const keyName = _.get(d, name, 'fakeColumn');
            return keyName;
          })
          .sortKeys((a, b) => {
            // sort the __ALL__ keys to the end
            if (a === TOTALS_FLAG) {
              return 1;
            } else if (b === TOTALS_FLAG) {
              return -1;
            } else {
              // don't bother sorting other keys. assume the server sent them to us in the correct order
              return 0;
            }
          });
      });
      const rows = rowsOnly.entries(this.queryResultObjects.all);

      const colsOnly = nest();
      colsToPivot.forEach(name => {
        colsOnly.key(d => d[name]);
      });
      const cols = colsOnly.entries(this.queryResultObjects.all);

      return {
        rows,
        columns: cols,
      };
    };
    const withOrdinals = doPivots(
      this.rowsToPivot.withOrdinals,
      this.colsToPivot.withOrdinals,
    );
    let withoutOrdinals;
    if (
      this.rowsToPivot.withOrdinals !== this.rowsToPivot.withoutOrdinals ||
      this.colsToPivot.withOrdinals !== this.colsToPivot.withoutOrdinals
    ) {
      withoutOrdinals = doPivots(
        this.rowsToPivot.withoutOrdinals,
        this.colsToPivot.withoutOrdinals,
      );
    } else {
      withoutOrdinals = withOrdinals;
    }

    return {
      rows: withOrdinals.rows,
      rowsWithoutOrdinals: withoutOrdinals.rows,
      columns: withOrdinals.columns,
      columnsWithoutOrdinals: withoutOrdinals.columns,
    };
  }

  createTableData(collapseEmptyColumns = false) {
    const { pivoted: pivotHierarchy, colsToPivot, measures } = this;
    const rowTree = pivotHierarchy.rows;

    // be sure to provide a value for each column in the table
    const columnHeaderCombos = getAllPossibleCombinations(
      pivotHierarchy.columnsWithoutOrdinals,
      colsToPivot.withoutOrdinals,
      this.ordinalFieldNameMap,
      this.sorts,
    );

    // If we're displaying column totals add an entry
    if (colsToPivot.withoutOrdinals.length > 0 && this.withColumnGrandTotals) {
      columnHeaderCombos.push({
        [colsToPivot.withoutOrdinals[0]]: TOTALS_FLAG,
      });
    }

    /* bodyData is type:
      {
        type: 'data' | 'rowSubtotal' | 'rowTotal';
        rowMeta: {
          [fieldName]: string;
        }[];
        columnData: {
          value: number | string;
          columnMeta?: {
            [fieldName]: string;
          }
        }[]
      }[]
    */
    let bodyData = [];

    const measureToColIndexes = measures.map(() => {
      return new Set();
    }, []);

    // match comparison for props that tries === first, if false and the object value is a number, try ==
    // This is due to d3 nest converting numbers to strings when pivoting (because the are keys in objects)
    const doubleEqualNumbers = (objVal, srcVal) => {
      const isTripleEqual = objVal === srcVal;
      if (isTripleEqual) {
        return true;
      } else if (_.isNumber(objVal)) {
        // eslint-disable-next-line eqeqeq
        return objVal == srcVal; // Yep, ==
      }
    };
    const getMatchingColumnData = rowData => {
      // This is funny logic - what if the row data unique values have the same length as the
      if (
        rowData.values.length === columnHeaderCombos.length ||
        columnHeaderCombos.length === 0
      ) {
        return rowData.values;
      }
      // find the cell data for the max number of columns we will have, empty if no data found
      const rowValues = columnHeaderCombos.map(colHeaderData => {
        for (let i = 0; i < rowData.values.length; i++) {
          if (
            _.isMatchWith(rowData.values[i], colHeaderData, doubleEqualNumbers)
          ) {
            return rowData.values[i];
          }
        }
        return null;
      });
      return rowValues;
    };

    const getRowData = rowNode => {
      let row = [];
      const cellData = getMatchingColumnData(rowNode);

      // if there are no measures, but there are columns, we need to fill in with empty/null
      if (
        _.isEmpty(measures) &&
        columnHeaderCombos.length === cellData.length
      ) {
        row = cellData.map((_val, idx) => ({
          columnMeta: columnHeaderCombos[idx],
          value: NULL_DISPLAY,
        }));
      } else {
        // columnHeaderCombos is the same length as cellData and columnHeaderCombos has drill link meta
        cellData.forEach((d, dIdx) => {
          // get the measure values for the node
          if (!_.isNil(d)) {
            const values = measures.map((m, mIdx) => {
              measureToColIndexes[mIdx].add(row.length + mIdx);
              const value = d[m] === NULL_TOKEN ? NULL_DISPLAY : d[m];
              return {
                columnMeta: columnHeaderCombos[dIdx],
                value,
              };
            });
            row = [...row, ...values];
          } else {
            const emptyMeasures = _.map(
              _.fill(Array(measures.length), NULL_DISPLAY),
              value => ({
                columnMeta: columnHeaderCombos[dIdx],
                value,
              }),
            );
            row = [...row, ...emptyMeasures];
          }
        });
      }
      return row;
    };

    // totals at the first level mean all other row values would be aggregated as well (grand total)
    const isGrandTotalRow = parentLevelKeys => {
      return _.isEqual(_.uniq(parentLevelKeys.filter(key => !_.isNil(key))), [
        TOTALS_FLAG,
      ]);
    };

    const isSubtotalRow = parentLevelKeys => {
      if (parentLevelKeys.length > 1) {
        // ignore the first, that would indicate it is a grand total
        const anyParentTotals = parentLevelKeys
          .slice(1)
          .filter(k => k === TOTALS_FLAG);
        return anyParentTotals.length > 0;
      }
      return false;
    };

    const rowHeaderColCount = this.rowsToPivot.withOrdinals.length;

    const walkRows = (rowHeaderNode, rowHeaderCells) => {
      const cells = [...rowHeaderCells]; // cells will be of type {value: any, columnMeta?: {[fieldName]: value}[]}
      cells.push({ value: rowHeaderNode.key });

      if (childrenAreLeafs(rowHeaderNode)) {
        // add the data
        const colData = getRowData(rowHeaderNode);
        const rowTotal = { value: '' };

        const cellValues = [...cells, ...colData];
        // only add the aggregated col value if there were measures in the original query
        if (!_.isEmpty(measures) || columnHeaderCombos.length > 0) {
          cellValues.push(rowTotal);
        }
        // is this a total row?
        let rowData;

        if (isGrandTotalRow(map(cells, 'value'), rowHeaderNode)) {
          rowData = {
            type: 'rowTotal',
            columnData: cellValues,
            colMetaOffset: rowHeaderColCount,
          };
        } else if (isSubtotalRow(map(cells, 'value'), rowHeaderNode)) {
          rowData = {
            type: 'rowSubtotal',
            columnData: cellValues,
            colMetaOffset: rowHeaderColCount,
          };
        } else {
          const rowMeta = {}; // of type {[fieldName]: string}[]

          _.forEach(
            this.rowsToPivot.withoutOrdinals,
            (rowField, idx) => (rowMeta[rowField] = cells[idx]?.value),
          );

          rowData = {
            type: 'data',
            rowMeta,
            columnData: cellValues,
            colMetaOffset: rowHeaderColCount,
          };
        }
        bodyData.push(rowData);
      } else {
        rowHeaderNode.values.forEach(childRowHeaderNode => {
          walkRows(childRowHeaderNode, cells);
        });
      }
    };

    rowTree
      .filter(node => !isLeaf(node))
      .forEach(rowHeaderNode => {
        walkRows(rowHeaderNode, []);
      });

    // If the column data is not sparse (we have values for all expected columns, we shouldn't collapse empty/null columns)
    let dataIsSparse = true;
    if (!_.isEmpty(this.colHeaderData) && !_.isEmpty(bodyData)) {
      // all data is there?
      const colsNeeded = this.colHeaderData[0].reduce((count, col) => {
        count += col[1];
        return count;
      }, 0);
      dataIsSparse =
        bodyData[0].columnData.length !== colsNeeded + rowHeaderColCount + 1;
    }
    if (collapseEmptyColumns && measures.length > 0 && dataIsSparse) {
      const rotated = PivotTableUtils.rotateTable(bodyData, -90);
      const nonEmptyRotated = rotated.reduce((nonEmpty, row, idx) => {
        const firstNonEmpty = row?.columnData?.findIndex(
          ({ value }) =>
            _.isNumber(value) || (!_.isEmpty(value) && value !== NULL_DISPLAY),
        );
        // don't remove any known measure column indexes either
        const isKnownMeasureIndex =
          measureToColIndexes.filter(mtc => {
            const isMeasureIndex = mtc.has(idx - rowHeaderColCount);
            return isMeasureIndex;
          }).length > 0;

        const isRowHeaderIndex = idx < rowHeaderColCount;

        // don't take the last column out since that is for aggregations
        if (
          firstNonEmpty !== -1 ||
          idx === rotated.length - 1 ||
          isKnownMeasureIndex ||
          isRowHeaderIndex
        ) {
          nonEmpty.push({ type: 'data', columnData: row?.columnData });
        }
        return nonEmpty;
      }, []);
      // rotate it back
      const noEmptyColsBodyData = PivotTableUtils.rotateTable(
        nonEmptyRotated,
        90,
      );
      bodyData = noEmptyColsBodyData.map((row, rowIdx) => {
        // retain info from before we rotated
        const { type, rowMeta } = bodyData[rowIdx];
        return {
          type,
          columnData: row?.columnData,
          rowMeta,
          colMetaOffset: rowHeaderColCount,
        };
      });
    }
    // check the body data if there are no measures to make sure we don't have too many column values that came from us filling in all possibilities
    if (_.isEmpty(measures) && !_.isEmpty(this.colHeaderData)) {
      const totalColumnsNeeded = this.colHeaderData[
        this.colHeaderData.length - 1
      ].length;
      const currentRowHeaderColCount = this.rowsToPivot.withOrdinals.length;
      bodyData = bodyData.map(row => {
        const newValues = row.columnData.slice(
          0,
          currentRowHeaderColCount + totalColumnsNeeded,
        );
        // add back in the row aggregation
        newValues.push({ value: '' });
        return { type: row.type, columnData: newValues };
      });
    }

    this.measurePositionToColumnIndexes = measureToColIndexes.map(indexes => {
      return Array.from(indexes);
    });
    this._fillInRecordCounts(bodyData, rowHeaderColCount);
    // walk the data now that it is done, counting up records by group
    return bodyData;
  }

  _fillInRecordCounts(bodyData, rowHeaderCount) {
    const dataRows = bodyData.filter(row => row.type === 'data');

    const totalRecordCount = dataRows.length;
    const grandTotalRow = bodyData.find(row => row.type === 'rowTotal');
    if (!_.isNil(grandTotalRow)) {
      grandTotalRow.count = totalRecordCount;
    }

    const subtotalRowIndexes = _.filter(
      _.map(bodyData, (row, idx) => row?.type === 'rowSubtotal' && idx),
      _.isNumber,
    );

    for (let i = 0; i < subtotalRowIndexes.length; i++) {
      const sliceEndIdx = subtotalRowIndexes[i];
      const subtotalRow = bodyData[subtotalRowIndexes[i]];
      const dataRowSelector = _.filter(
        _.map(
          subtotalRow.columnData,
          ({ value }, idx) => value !== TOTALS_FLAG && { idx, val: value },
        ),
        _val => _.isNumber(_val?.value),
      );
      const firstRowIdxOfDataType = _.findIndex(bodyData, ({ columnData }) =>
        _.every(
          dataRowSelector,
          ({ idx, val }) => columnData[idx]?.value === val,
        ),
      );
      const slicedRows = _.slice(
        bodyData,
        firstRowIdxOfDataType,
        sliceEndIdx + 1,
      );
      const countingDataRows = _.filter(slicedRows, {
        type: 'data',
      });
      const matchingRows = _.filter(countingDataRows, dataRow =>
        _.every(
          subtotalRow.columnData.slice(0, rowHeaderCount),
          ({ value: subtotalRowVal }, idx) =>
            subtotalRowVal === TOTALS_FLAG ||
            subtotalRowVal === dataRow?.columnData[idx]?.value,
        ),
      );
      bodyData[sliceEndIdx].count = matchingRows?.length;
    }
  }
}
