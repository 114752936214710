import { css } from '@emotion/react';
import { vbox, font } from './mixins';

export const loginStyles = ({
  theme: {
    colors: {
      ActiveLinkText,
      ContentBackground,
      PanelBackground,
      RegularFontWeight,
      ContentText,
      LightFontWeight,
      CorvanaLightText,
      MediumFontWeight,
      PanelBorder,
      InvalidBullet,
      negativeBackgroundColor,
      negativeColor,
      ErrorColor,
      LoginButtonColor,
      CorbotoLoadingColor,
      LoginHoverColor,
      LoginDisabledColor,
      positiveColor,
      ErrorTextColor,
    },
  },
}) => css`
  /** Login and Account Activation Modals **/
  .login-dialog.modal-backdrop {
    background: ${PanelBackground};
    opacity: 1;
    z-index: 1140;
  }
  .login-dialog.modal {
    z-index: 1150;
  }
  .login-dialog.modal-dialog {
    width: 100%;
    height: 100%;
  }
  .login-dialog.modal-content {
    height: 100%;
  }
  .login-dialog .fade {
    opacity: 0;
    transition: opacity 0.5s;
  }
  .login-dialog .fade.in {
    opacity: 1;
  }
  .login-dialog .modal-body {
    height: 100%;
    ${vbox()}
    padding-bottom: 25px;
  }
  .login-dialog .modal-body .notice-container {
    width: 650px;
    max-width: 90vw;
    margin: auto auto 30px;
  }
  .login-dialog .modal-body .notice-container + .login-body {
    margin-top: 0;
  }
  .login-dialog .modal-body .login-body {
    margin: auto;
    border-radius: 15px;
    border: 1px solid ${PanelBorder};
    width: 650px;
    max-width: 95vw;
    background-color: ${ContentBackground};
    padding: 40px;
    /* chrome makes it very difficult to alter the background color of autofilled*/
    /* textboxes and passwords.  this hack prevents the background color from taking hold.*/
    /* see: https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/*/
  }
  .login-dialog .modal-body .login-body .login-logo {
    text-align: left;
  }
  .login-dialog .modal-body .login-body .login-logo img {
    height: 3em;
    transition: all 10ms ease;
  }
  .login-dialog .modal-body .login-body .login-header {
    ${font({ size: '24px', weight: RegularFontWeight, color: ContentText })}
    margin: 40px 0 32px 0;
  }
  .login-dialog .modal-body .login-body .login-info {
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .login-dialog .modal-body .login-body input:-webkit-autofill,
  .login-dialog .modal-body .login-body input:-webkit-autofill:hover,
  .login-dialog .modal-body .login-body input:-webkit-autofill:focus,
  .login-dialog .modal-body .login-body input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px ${ContentBackground} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  .login-dialog .modal-body .login-body .form-group {
    margin-bottom: 8px;
  }
  .login-dialog .modal-body .login-body .form-group label {
    ${font({ size: '16px', weight: RegularFontWeight, color: ContentText })}
  }
  .login-dialog .modal-body .login-body .form-group.buttonGroup {
    margin-bottom: 0px;
  }
  .login-dialog .modal-body .login-body .form-group-flex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .login-dialog .modal-body .login-body .has-error .form-control {
    border-color: ${negativeColor};
    background-color: ${negativeBackgroundColor};
    -webkit-box-shadow: 0px 0px 12px 0px ${ErrorTextColor};
    -moz-box-shadow: 0px 0px 12px 0px ${ErrorTextColor};
    box-shadow: 0px 0px 12px 0px ${ErrorTextColor};
  }
  .login-dialog
    .modal-body
    .login-body
    .has-error
    .form-control
    input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
    border-color: ${negativeColor};
    background-color: ${negativeBackgroundColor};
    -webkit-box-shadow: 0px 0px 12px 0px ${ErrorTextColor};
    -moz-box-shadow: 0px 0px 12px 0px ${ErrorTextColor};
    box-shadow: 0px 0px 12px 0px ${ErrorTextColor};
  }
  .login-dialog .modal-body .login-body .emailInputGroup,
  .login-dialog .modal-body .login-body .passInputGroup,
  .login-dialog .modal-body .login-body .passInputGroupFocused {
    margin-top: 17px;
    width: 100%;
    margin-bottom: 24px;
  }
  .login-dialog .modal-body .login-body input.inputFocused {
    box-shadow: 0px 0px 12px 0px ${CorbotoLoadingColor};
  }
  .login-dialog .modal-body .login-body .loginCheckbox input {
    display: none;
  }
  .login-dialog .modal-body .login-body .loginCheckbox label {
    padding-left: 0px;
    user-select: none;
  }
  .login-dialog .modal-body .login-body .loginCheckbox img {
    padding: 8px 4px;
    height: 34px;
  }
  .login-dialog
    .modal-body
    .login-body
    .form-group-password
    .input-group-addon {
    padding: 0px;
  }
  .login-dialog .modal-body .login-body .input-group-addon.showpass {
    background-color: ${ActiveLinkText};
    border-color: ${ActiveLinkText};
  }
  .login-dialog .modal-body .stayLoggedInCheckbox {
    /* these don't seem to take effect because they are in a single parent dom, but I wanted to override*/
    /* their bootstrap defaults so chrome doesn't display strange results in the debug panel.*/
    margin-top: 0px;
    margin-bottom: 24px;
  }
  .login-dialog .modal-body .stayLoggedInCheckbox label span {
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
    vertical-align: top;
    margin-left: 5px;
    user-select: none;
  }
  .login-dialog .modal-body .stayLoggedInCheckbox label input {
    margin-top: 2px !important;
  }
  .login-dialog .modal-body .link {
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
    text-decoration: none;
    vertical-align: top;
    cursor: pointer;
  }
  .login-dialog .modal-body .help-block {
    color: ${ErrorColor};
    margin-top: 8px;
    margin: 8px 0 0 0;
    display: inline-block;
  }
  .buttonGroup {
    text-align: right;
    margin-bottom: 0px;
  }
  .tosTop {
    margin-top: 20px;
  }
  .returnLink {
    margin-top: 40px;
    text-align: center;
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
  }
  /* Terms of service*/
  .tosText {
    text-align: center;
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
  }
  .tosText a {
    text-align: center;
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
    text-decoration: underline;
  }
  .tosText a:hover {
    text-decoration: underline;
  }
  .tosText .dot:before {
    content: '\B7';
    margin: 0 5px;
  }
  .tosText .vertical-bar:before {
    content: '|';
    margin: 0 12px;
  }
  .copyright {
    color: ${ContentText};
    margin-top: 16px;
    display: inline-block;
  }
  .loginCheckbox {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .activateAcctLoginTable {
    ${font({
      size: '18px',
      weight: RegularFontWeight,
      color: CorvanaLightText,
    })}
  }
  .activateAcctLoginTable tr:nth-of-type(2) td {
    padding-top: 5px;
  }
  .activateAcctLoginTable td:nth-of-type(2) {
    padding-left: 50px;
  }
  .validBullet {
    color: ${positiveColor};
  }
  .invalidBullet {
    color: ${InvalidBullet};
  }
  .activateAccountDescription {
    ${font({ size: '20px', weight: LightFontWeight })}
    margin-bottom: 20px;
  }
  /* Initial Login Dialog specific stuff*/
  .modal-body .initial-login .agree-to-terms {
    ${font({ size: '16px', weight: LightFontWeight, color: ContentText })}
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .modal-body .initial-login .agree-to-terms .tos_checkbox {
    margin-right: 8px;
    position: relative;
    top: -2px;
  }
  .modal-body .initial-login ul {
    padding: 0 8px;
  }
  .modal-body .initial-login .help-block {
    ${font({ size: '20px', color: ContentText })}
    margin: 40px 0px;
  }
  .modal-body .initial-login .password-content-text {
    ${font({ size: '16px', weight: MediumFontWeight, color: ContentText })}
    margin-bottom: 8px;
  }
  .modal-body .initial-login .button-group {
    display: flex;
    justify-content: flex-end;
  }
  .modal-body .initial-login .button-group button {
    margin-left: 16px;
  }
  .modal-body .initial-login button.btn.btn-primary {
    ${font({ size: '16px' })}
    background-color: ${LoginButtonColor};
    color: ${ContentBackground};
    border: 1px solid ${LoginButtonColor};
  }
  .modal-body .initial-login button.btn.btn-primary:disabled,
  .modal-body .initial-login button.btn.btn-primary[disabled]:hover {
    background-color: ${LoginDisabledColor};
    border-color: ${LoginDisabledColor};
    opacity: unset;
  }
  .modal-body .initial-login button.btn.btn-primary:hover,
  .modal-body .initial-login button.btn.btn-primary:active,
  .modal-body .initial-login button.btn.btn-primary:focus {
    background-color: ${LoginHoverColor};
    border-color: ${LoginHoverColor};
  }
  .modal-body .initial-login button.btn.btn-primary.full-width {
    width: 100%;
    height: 40px;
    margin-left: 0px;
  }
  .modal-body .initial-login .terms {
    ${font({ size: '12px', color: ContentText })}
    margin: 24px 0px 0px 0px;
  }
  .modal-body .initial-login .terms a {
    color: ${ContentText};
    text-decoration: underline;
  }
  .login-dialog .LoginDialog__helperActions > a.link {
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
  }
  .login-dialog .LoginDialog__helperActions > a.link:hover {
    text-decoration: underline;
  }
`;
