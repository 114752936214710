import gql from 'graphql-tag';
import {
  allOptionsFragment,
  allOptionsFragmentName,
  visualizationFragment,
  visualizationFragmentName,
  datasetFragment,
  datasetFragmentName,
  searchOptionsDatasetFragment,
  searchOptionsDatasetFragmentName,
  searchOptionsVizFragment,
  searchOptionsVizFragmentName,
  ADD_VISUALIZATION_QUERY_NAME,
} from './Fragments';
const Graphql = {
  AllTags: gql`
    query listDiscoveries {
      visualizations {
        tags
      }
    }
  `,
  SearchOptions: gql`
    query listDiscoveries {
      visualizations {
        ...${searchOptionsVizFragmentName}
      }
      datasets {
        ...${searchOptionsDatasetFragmentName}
      }
    }
    ${searchOptionsVizFragment}
    ${searchOptionsDatasetFragment}
  `,
  AllVizOptionsQuery: gql`
    query listDiscoveries {
      visualizations {
        ...${allOptionsFragmentName}
      }
    }
    ${allOptionsFragment}
  `,
  DiscoveriesQuery: gql`
    query listDiscoveries {
      monitors {
        id
        version
        name
        creatorName
        creator
        createdOn
        updatedByName
        updatedOn
        canDelete
        canUpdate
        trend {
          direction
          value
        }
        lastEvent
        dataset {
          id
          name
          editMode
          isDeletable
        }
        query {
          datasetId
          attributeNames
          measures {
            attributeName
            aggregation
            resultSetFunction
          }
          filters {
            attributeName
            operator
            operands
          }
          sorts {
            attributeName
            sortType
          }
        }
        measure
        threshold
        window {
          anchor
          duration
        }
      }
      visualizations {
        ...${visualizationFragmentName}
      }
      datasets {
        ...${datasetFragmentName}
      }
    }
    ${visualizationFragment}
    ${datasetFragment}
  `,
  ExecuteQuery: gql`
    query executeQuery($query: DiscoverQuery!) {
      executeQuery(query: $query) {
        columnNames
        columnInfo {
          columnName
          attributeName
          columnType
          parentName
          aggregationName
        }
        results
      }
    }
  `,
  GetQuerySql: gql`
    query(
      $id: String!
      $measures: [QueryMeasureInput!]!
      $attributeNames: [String!]!
      $filters: [QueryFilterInput!]!
      $calcs: [QueryCalculationInput!]
      $subtotals: [QuerySubtotalInput!]
      $useFiscalCalendar: Boolean = false
    ) {
      getSql(
        query: {
          datasetId: $id
          attributeNames: $attributeNames
          measures: $measures
          filters: $filters
          calcs: $calcs
          sorts: []
          subtotals: $subtotals
          useFiscalCalendar: $useFiscalCalendar
        }
      )
    }
  `,
  CreateVisualizationMutation: gql`
    mutation ${ADD_VISUALIZATION_QUERY_NAME}($viz: VisualizationRequest!) {
      ${ADD_VISUALIZATION_QUERY_NAME}(visualization: $viz) {
        ...${visualizationFragmentName}
      }
    }
    ${visualizationFragment}
  `,
  DeleteVisualizationMutation: gql`
    mutation deleteVisualization($id: String!) {
      deleteVisualization(id: $id) {
        id
      }
    }
  `,
  UpdateVisualizationMutation: gql`
    mutation updateVisualization($viz: VisualizationRequest!) {
      updateVisualization(visualization: $viz) {
        ...${visualizationFragmentName}
      }
    }
    ${visualizationFragment}
  `,
  ComputeDateRangeQuery: gql`
    query($request: DateRangeRequestInput!) {
      dateRange(dateRangeRequest: $request) {
        start
        end
      }
    }
  `,
};

export default Graphql;
