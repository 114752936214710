import UnconnectedAdministrationComponent from './component.js';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { graphql } from '@apollo/client/react/hoc';
import AdminQueries from '../../common/graphql/AdminQueries';
import get from 'lodash/get';
import {
  isIdmAdministrator,
  idmAdminUrl,
  isAdmin,
} from '../../common/redux/selectors/AccountSelectors';
import { AccountMutation } from '../../account/graphql';

const mapStateToProps = state => {
  return {
    userEmail: get(state, 'account.currentUser.email'),
    toasts: state.main.toasts,
    isIdmAdministrator: isIdmAdministrator(state?.account),
    idmAdminUrl: idmAdminUrl(state?.account),
    isAdmin: isAdmin(state.account),
  };
};

const SyncUsersMutation = graphql(AdminQueries.SyncUsersQuery, {
  props: ({ mutate }) => ({
    syncUsers() {
      return mutate();
    },
  }),
});

export default compose(
  connect(mapStateToProps),
  SyncUsersMutation,
  AccountMutation,
)(UnconnectedAdministrationComponent);
