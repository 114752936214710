import { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { messages } from '../../i18n';

export function Copyright() {
  const year = new Date().getFullYear();
  return (
    <div className='copyright'>
      &copy; {year} {messages.sugarCrm}
    </div>
  );
}

export class LongPress extends Component {
  start(e) {
    this.startTime = Date.now();
    e.preventDefault();
  }
  stop() {
    this.endTime = Date.now();
    const diff = this.endTime - this.startTime;
    if (diff > this.props.wait) {
      this.props.onAction();
    }
  }

  render() {
    return (
      <div
        className='longPress'
        onTouchStart={this.start.bind(this)}
        onTouchEnd={this.stop.bind(this)}
      >
        {this.props.children}
      </div>
    );
  }
}

LongPress.propTypes = {
  onAction: PropTypes.func,
  wait: PropTypes.number,
};
LongPress.defaultProps = {
  onAction: noop,
  wait: 500,
};
