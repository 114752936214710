import { keyframes, css, SerializedStyles } from '@emotion/react';
import { PureComponent } from 'react';

import { LoaderSizeProps } from './interfaces';

const right = keyframes`
  0% {transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg)}
  100% {transform: rotateX(180deg) rotateY(360deg) rotateZ(360deg)}
`;

const left = keyframes`
  0% {transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg)}
  100% {transform: rotateX(360deg) rotateY(180deg) rotateZ(360deg)}
`;

class Loader extends PureComponent<LoaderSizeProps> {
  public static defaultProps = {
    loading: true,
    size: 60,
  };

  public getSize = (): number => {
    return this.props.size as number;
  };

  public style = (i: number): SerializedStyles => {
    const { color, size } = this.props;
    return css`
      position: absolute;
      top: 0;
      left: 2px;
      width: ${size}px;
      height: ${size}px;
      border: 3px solid ${color};
      opacity: 0.4;
      border-radius: 100%;
      animation-fill-mode: forwards;
      perspective: 800px;
      animation: ${i === 1 ? right : left} 2s 0s infinite linear;
    `;
  };

  public wrapper = (): SerializedStyles => {
    return css`
      width: ${this.getSize()}px;
      height: ${this.getSize()}px;
      position: relative;
    `;
  };

  public render(): JSX.Element | null {
    const { loading, css } = this.props;

    return loading ? (
      <div css={[this.wrapper(), css]}>
        <span css={this.style(1)} />
        <span css={this.style(2)} />
      </div>
    ) : null;
  }
}

export default Loader;
