import { createFilterForField, TimestampFilterSubTypes } from '../Filter';
import { FilterOperators } from '../FilterOperators';
import _ from 'lodash';
import {
  IFilter,
  IOperator,
} from '../../../datasets/interfaces/filter.interface';

export default (field, operands, op?: string | IOperator): IFilter => {
  const filter = createFilterForField(field);
  let operator: string = _.isString(op) ? op : op?.key;
  if (_.isNil(operator)) {
    const defaultOperatorForType = FilterOperators.forFilterType(
      filter.type,
      TimestampFilterSubTypes.RELATIVE_DATES,
    ).this;
    operator = defaultOperatorForType?.key;
  }
  filter.expression.left.operator = operator;
  filter.expression.left.operands = operands;
  filter.subType = TimestampFilterSubTypes.RELATIVE_DATES;
  return filter;
};
