import { lazy, Suspense, useMemo, memo } from 'react';
import { NonMemoizedCenteredSpinner } from '../../common/widgets/Status';

export const LazyWrapper = ({
  importFn,
  fallback = NonMemoizedCenteredSpinner,
}) => {
  const WrappedLazy = memo<any>(({ children, ...props }) => {
    const LazyComp = useMemo(() => lazy(importFn), []);
    return (
      <Suspense fallback={fallback}>
        <LazyComp {...props}>{children}</LazyComp>
      </Suspense>
    );
  });
  return WrappedLazy;
};
