import component from './component';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import moment from '../../common/Moment';
import { get, isNil } from 'lodash';
import Discover from '../../common/redux/actions/DiscoverActions';
import { ACCOUNT_SELECTORS } from '../../common/redux/selectors/AccountSelectors';

const mapStateToProps = state => {
  const rawDiscovery =
    state.discover.openDiscoveries[state.discover.displayDiscovery];
  const open = rawDiscovery.present;
  const { viz } = open;
  let { updatedOn } = open;
  // Overwrite if latest update info is available
  if (!isNil(rawDiscovery.updatedOn)) {
    updatedOn = rawDiscovery.updatedOn;
  }
  const { dateTimeFormat } = ACCOUNT_SELECTORS.getDatetimeFormats(state);
  return {
    discoveryId: open.id,
    discoveryType: open.discoveryType,
    newDiscovery: open.id.includes('newViz'),
    canUpdate: open.canUpdate,
    name: open.name,
    creatorName: open.creatorName,
    updatedOn: moment(updatedOn).format(dateTimeFormat),
    description: get(viz, 'options.description', ''),
    tags: open.tags,
    revisionSelected: get(viz, 'revisionSelected', {}),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeDiscoveryName: (discoveryId, value) => {
      dispatch(Discover.changeDiscoveryName(discoveryId, value));
    },
    setSettingForViz: (discoveryId, key, value) => {
      dispatch(
        Discover.setSettingForViz({ id: discoveryId, setting: key, value }),
      );
    },
    changeDiscoveryTags: (discoveryId, tags) => {
      dispatch(Discover.setTagsForViz(discoveryId, tags));
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(component);
