import { messages } from '../../i18n';
import { isEmpty, map, mapValues, nth } from 'lodash';
import {
  UserDataContainer,
  UserProfileDetail,
} from './settings-user-profile.style';
import {
  ISettingsUserProfileComponentProps,
  IUserProfileDataProps,
} from './settings-user-profile.interface';

const UserProfileData = ({ label, value }: IUserProfileDataProps) => {
  return (
    <UserProfileDetail key={label}>
      <div className={'user-label'}>{label}</div>
      <div className={'user-info'}>{value}</div>
    </UserProfileDetail>
  );
};

const SettingsUserProfileComponent = (
  props: ISettingsUserProfileComponentProps,
) => {
  const {
    userInfo: { fullName = '', email = '', title = '', phoneNumber = '' },
  } = props;

  const displayData = mapValues(
    {
      [messages.account.firstName]: nth(fullName.split(' '), 0),
      [messages.account.lastName]: nth(fullName.split(' '), 1),
      [messages.account.email]: email,
      [messages.account.jobTitle]: title,
      [messages.account.phoneNumber]: phoneNumber,
    },
    (value: string | null) => (isEmpty(value) ? '-' : value),
  );

  return (
    <UserDataContainer>
      {map(displayData, (value, label) => (
        <UserProfileData key={label} label={label} value={value} />
      ))}
    </UserDataContainer>
  );
};

export default SettingsUserProfileComponent;
