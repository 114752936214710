import get from 'lodash/get';
import { createSelector } from 'reselect';

const getStateTables = state => state.tables;
const getTableDefault = state => state.tables.default;
const getDomain = (state, props) => props.domain;

const getTable = createSelector(
  [getStateTables, getTableDefault, getDomain],
  (stateTables, defaultTable, domain) => {
    return get(stateTables, domain, defaultTable);
  },
);

const getSorts = createSelector([getTable], table => {
  return get(table, 'sorts');
});

const getSortBy = createSelector([getSorts], sorts => {
  return get(sorts, 'sortBy');
});

const getSortDirection = createSelector([getSorts], sorts => {
  return get(sorts, 'sortDirection');
});

const getSearch = createSelector([getTable], table => {
  return get(table, 'search');
});

const getSearchTags = createSelector([getTable], table => {
  return get(table, 'tags');
});

const getSearchChartTypes = createSelector([getTable], table => {
  return get(table, 'chartTypes');
});

const getSearchDatasets = createSelector([getTable], table => {
  return get(table, 'searchDatasets');
});

export const TABLE_SELECTORS = {
  getSortBy,
  getSortDirection,
  getSearch,
  getSearchTags,
  getSearchChartTypes,
  getSearchDatasets,
};
