import { useDiscoverThemeColors } from '../../../common/emotion/theme/discover-emotion-theme-provider.component';
import {
  ISugarIconProps,
  SugarIconColorType,
  SugarIconType,
} from '../../common';
import { SugarIcon } from '../../sugar-icon';

export type AddRemoveIconProps = Omit<ISugarIconProps, 'icon'> & {
  indicator: 'add' | 'remove';
};

export const AddRemoveIcon = ({ indicator, ...props }: AddRemoveIconProps) => {
  const icon: SugarIconType = indicator === 'remove' ? 'close-lg' : 'plus-lg';
  const colorType: SugarIconColorType = 'medium';
  const { MediumBlue } = useDiscoverThemeColors();
  return (
    <SugarIcon
      icon={icon}
      hover
      hoverColor={MediumBlue}
      iconType={colorType}
      {...props}
    />
  );
};

export const AddConditionIcon = (
  props: Omit<AddRemoveIconProps, 'indicator'>,
) => <AddRemoveIcon indicator={'add'} {...props} />;

export const RemoveConditionIcon = (
  props: Omit<AddRemoveIconProps, 'indicator'>,
) => <AddRemoveIcon indicator={'remove'} {...props} />;
