import { Children, cloneElement } from 'react';
import { compose, withProps } from 'react-recompose';
import { baseProps, CommonComponentHoc } from './Components';
import {
  ActivityFeedItem,
  ActivityFeedItemBody,
  ActivityFeedItemHeader,
  ActivityWidget,
  PercentChangeCard,
} from './activity';
import _ from 'lodash';
import { messages } from '../../i18n';
import { Viz } from '../../discovery/VizUtil';
import Util from '../../common/Util';

const UnconnectedWeightedForecastComponent = ({
  feedItem,
  monitorEvent,
  referenceId,
  payload,
  last,
  visualizations,
  up,
  period,
  pct,
  name,
  date,
  attrValue,
  attrName,
  barData,
  condensed,
  className,
  inverted,
  history,
  onHeightChanged,
  cardName,
  children,
}) => {
  const linkedContent = visualizations
    ? visualizations.find(
        viz =>
          viz.name === name && viz.creator === 'discover-admin@sugarcrm.com',
      )
    : null;

  if (!_.isNil(linkedContent)) {
    Viz.configureCurrencySymbol(linkedContent);
  }

  function openConnectedContent() {
    history.push(`/event/${monitorEvent.id}`);
  }

  let cardTitle;
  switch (monitorEvent.eventType) {
    case 'daysinstage':
      cardTitle =
        cardName ||
        messages.formatString(
          messages.wfComponent.avgDaysInStageCardTitle,
          payload.stage,
        );
      if (condensed) {
        if (attrName) {
          cardTitle += ` (${attrName}=${attrValue})`;
        }
      }
      break;
    case 'weightedforecast':
      cardTitle = cardName || messages.wfComponent.weightedForecastCardTitle;
      if (condensed) {
        if (attrName) {
          cardTitle += ` (${attrName}=${attrValue})`;
        }
      }
      break;
  }

  let segment = '';

  if (payload.attribute !== 'ALL') {
    segment = messages.formatString(
      messages.wfComponent.segment,
      payload.attributeName || payload.attribute,
      payload.attributeValue,
    );
  }

  let periodVal = '';
  switch (payload.previousPeriod) {
    case 'WEEK':
      periodVal = messages.wfComponent.sevenDays;
      break;
    case 'MONTH':
      periodVal = messages.wfComponent.month;
      break;
  }

  // Get the specific children that we know we need
  const kids = Children.toArray(children).reduce((acc, child) => {
    acc[child.type.role] = cloneElement(child, {
      onHeightChanged,
    });
    return acc;
  }, {});

  // Support overriding of the default header by tenanted modules
  const renderHeader = () => {
    if (!_.isNil(kids) && !_.isNil(kids.ActivityFeedItemHeader)) {
      return kids.ActivityFeedItemHeader;
    } else {
      return (
        <ActivityFeedItemHeader
          {...{ indicatorIcon: up ? 'up' : 'down', feedItem }}
        >
          {messages.formatString(messages.wfComponent.header, {
            name: linkedContent ? (
              <a onClick={() => openConnectedContent()}>{name}</a>
            ) : (
              name
            ),
            segment,
            pct,
            period,
            upOrDown: up ? messages.up : messages.down,
          })}
        </ActivityFeedItemHeader>
      );
    }
  };

  // Support overriding of the default body by tenanted modules
  const renderBody = () => {
    if (!_.isNil(kids) && !_.isNil(kids.ActivityFeedItemBody)) {
      return kids.ActivityFeedItemBody;
    } else {
      return (
        <ActivityFeedItemBody monitorEvent={monitorEvent} feedItem={feedItem}>
          {messages.formatString(messages.wfComponent.body, {
            name: <strong>{monitorEvent.monitor.name}</strong>,
            segment,
            upOrDown: up ? messages.up : messages.down,
            pct,
            currentValue: Util.formatCurrency(payload.currentValue),
            periodVal,
            previousValue: Util.formatCurrency(payload.previousValue),
          })}
        </ActivityFeedItemBody>
      );
    }
  };

  // Support overriding of the default widget by tenanted modules
  const renderWidget = () => {
    if (!_.isNil(kids) && !_.isNil(kids.ActivityWidget)) {
      return kids.ActivityWidget;
    } else {
      return (
        <ActivityWidget>
          <PercentChangeCard
            {...{
              up,
              period,
              pct,
              date,
              payload,
              barData,
              condensed,
              inverted,
            }}
            title={cardTitle}
          />
        </ActivityWidget>
      );
    }
  };

  return (
    <div key={monitorEvent.id}>
      <ActivityFeedItem
        {...{
          referenceId,
          monitorEvent,
          feedItem,
          onHeightChanged,
          last,
          condensed,
          className,
        }}
      >
        {renderHeader()}
        {renderBody()}
        {renderWidget()}
      </ActivityFeedItem>
    </div>
  );
};

const weightedForcastSupportFunc = feedItem => {
  return (
    feedItem.referenceType === 'MonitorEvent' &&
    ['weightedforecast'].includes(feedItem.monitorEvent.eventType)
  );
};

// Two derived components are registered, one for activity feed and the other for insight card view
const WeightedForecastComponent = compose(
  withProps(props => {
    return {
      monitorEvent: props.feedItem.monitorEvent,
      referenceId: props.feedItem.referenceId,
    };
  }),
  CommonComponentHoc,
)(UnconnectedWeightedForecastComponent);

WeightedForecastComponent.supports = weightedForcastSupportFunc;

const WeightedForecastInsightComponent = compose(
  withProps(() => ({ className: 'insight-item' })),
  baseProps,
)(UnconnectedWeightedForecastComponent);

WeightedForecastInsightComponent.supports = weightedForcastSupportFunc;

// export default WeightedForecastComponent
export {
  WeightedForecastComponent,
  WeightedForecastInsightComponent,
  UnconnectedWeightedForecastComponent,
  weightedForcastSupportFunc,
};
