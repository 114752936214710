import { MouseEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { InsightsButton } from '../../components/InsightsButton';
import { messages } from '../../i18n';
import { SlicerButton } from '../../discovery/slicer/slicer-button';
import { useSelector } from 'react-redux';
import {
  EventKeyType,
  IMobileVizFooterProps,
} from './mobile-viz-footer.interfaces';
import { LinkButton } from '../buttons/link-button';
import _ from 'lodash';
import { getActiveSlicers } from '../../common/redux/selectors/viz-selectors';
import {
  useCanCollapse,
  useDashletExternalLinkAction,
  useMobileVizFooterState,
} from './mobile-viz-footer.hook';
import { ClickthroughDiv, MoreKabob } from './mobile-viz-footer.styles';
import { IconDropdown } from '../icon-dropdown';

const ExternalLinkButton = ({ vizId }) => {
  const externalLinkCallback = useDashletExternalLinkAction(vizId);
  return (
    <LinkButton href={''} target='_blank' onClick={externalLinkCallback} />
  );
};

export const MobileVizFooter = ({
  hideLabels,
  hasInsights = false,
  insightsLoading = false,
  vizId,
  overrideStyle = {},
  showInsightsBubble = false,
  onOpenInsights,
}: IMobileVizFooterProps) => {
  const {
    onMenuSelection,
    showLegendPanel = true,
    showFiltersPanel = true,
    isMobile,
    isDashletMode,
    isDashletUser,
    isRefreshDisabled,
  } = useMobileVizFooterState({ vizId });

  const { isForecastChanges, canCollapseEnabled } = useCanCollapse(vizId);

  const showLinkButton = !(isDashletUser && isDashletMode);

  onOpenInsights = onOpenInsights || _.noop;

  const slicers = useSelector(state =>
    getActiveSlicers(state, { discoveryId: vizId }),
  );

  const handleMenuItemClick = (
    e: MouseEvent<HTMLElement>,
    eventKey: EventKeyType,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    onMenuSelection(eventKey);
  };

  return (
    <div
      className={'mobile-viz-footer'}
      style={{ width: '100%', ...overrideStyle }}
    >
      {isDashletMode && <ClickthroughDiv />}
      <MoreKabob className={'more-kabob'}>
        <IconDropdown
          id={'more-viz-options-btn'}
          disablePortal={true}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {isDashletMode && (
            <MenuItem
              disabled={isRefreshDisabled}
              onClick={e => {
                handleMenuItemClick(e, 'REFRESH');
              }}
            >
              {messages.mobile.refresh}
            </MenuItem>
          )}
          <MenuItem
            onClick={e => {
              handleMenuItemClick(e, 'HIDE_LEGEND');
            }}
          >
            {showLegendPanel
              ? messages.mobile.hideLegend
              : messages.mobile.showLegend}
          </MenuItem>
          <MenuItem
            onClick={e => {
              handleMenuItemClick(e, 'HIDE_FILTERS');
            }}
          >
            {showFiltersPanel
              ? messages.mobile.hideFilters
              : messages.mobile.showFilters}
          </MenuItem>
          {isForecastChanges && (
            <MenuItem
              onClick={e => {
                handleMenuItemClick(e, 'COLLAPSE_WHITESPACE');
              }}
            >
              {canCollapseEnabled
                ? messages.mobile.disableDynamicCollapse
                : messages.mobile.enableDynamicCollapse}
            </MenuItem>
          )}
        </IconDropdown>
      </MoreKabob>
      {!_.isEmpty(slicers) && (
        <SlicerButton
          isDashletMode={isDashletMode}
          isMobile={isMobile}
          hideLabel={hideLabels}
          vizId={vizId}
        />
      )}
      {((isMobile && !isDashletMode) || (isDashletMode && hasInsights)) && (
        <InsightsButton
          hideLabel={hideLabels}
          hasInsights={hasInsights}
          anim={insightsLoading}
          bubble={showInsightsBubble}
          onClick={() => {
            onOpenInsights();
          }}
          size={20}
        />
      )}
      {showLinkButton && <ExternalLinkButton vizId={vizId} />}
      {isDashletMode && <ClickthroughDiv />}
    </div>
  );
};
