export type LegendShapesType = 'SQUARE' | 'CIRCLE' | 'LINE' | 'DASHED_LINE';

export enum LegendShapes {
  SQUARE = 'SQUARE',
  CIRCLE = 'CIRCLE',
  LINE = 'LINE',
  DASHED_LINE = 'DASHED_LINE',
}

export const LegendShape = ({ shape, color, style }) => {
  switch (shape) {
    case LegendShapes.CIRCLE:
      return (
        <svg width='8px' height='8px'>
          <circle cx={4} cy={4} r={4} fill={color} style={style} />
        </svg>
      );
    case LegendShapes.LINE:
      return (
        <svg width='8px' height='8px'>
          <line
            x1={0}
            y1={4}
            x2={8}
            y2={4}
            stroke={color}
            strokeWidth={2}
            style={style}
          />
        </svg>
      );
    case LegendShapes.DASHED_LINE:
      // construct two dashes to fit appropriately within 8 x 8
      return (
        <svg width='8px' height='8px'>
          <line
            x1={0}
            y1={4}
            x2={3}
            y2={4}
            stroke={color}
            strokeWidth={2}
            style={style}
          />
          <line
            x1={5}
            y1={4}
            x2={8}
            y2={4}
            stroke={color}
            strokeWidth={2}
            style={style}
          />
        </svg>
      );
    default:
      return (
        <svg width='8px' height='8px'>
          <rect width={8} height={8} fill={color} style={style} />
        </svg>
      );
  }
};
