import {
  BaseCartesianChart,
  ShouldEnableReportLinkHOC,
} from './base-cartesian-chart';
import { connect } from 'react-redux';
import {
  compose,
  onlyUpdateForKeys,
  pure,
  withPropsOnChange,
} from 'react-recompose';
import StackChartUtils from './StackChartUtils';
import StackPlot from './plots/StackPlot';
import NoDataIfHOC from './NoDataIfHOC';
import { Viz } from '../VizUtil';

class StackChart extends BaseCartesianChart {
  constructor(props) {
    super(props, [{ shelf: 'VALUES', Plot: StackPlot, dataId: 'stackData' }]);
  }
}

export default compose(
  pure,
  ShouldEnableReportLinkHOC,
  connect(
    BaseCartesianChart.mapStateToProps,
    BaseCartesianChart.mapDispatchToProps,
  ),
  withPropsOnChange(['queryResults', 'i18nPrefs'], props => {
    const customFormatToggles = Viz.getCustomFormatTogglesFromViz(props.viz);
    const stackData = StackChartUtils.transformResult(
      props.queryResults,
      props.viz,
      customFormatToggles,
      undefined,
      undefined,
      undefined,
      props.i18nPrefs,
    );
    const legendData = StackChartUtils.getUniqueStackNames({
      queryResults: props.queryResults,
      layout: props.viz.layout,
    });

    return { stackData, legendData };
  }),
  onlyUpdateForKeys(['stackData', 'height', 'width', 'id', 'enableReportLink']),
  NoDataIfHOC(props => props.stackData.length === 0),
)(StackChart);
