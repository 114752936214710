import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { font, IDiscoverEmotionTheme } from '../../../common/emotion';
import { ChartTooltip } from './chart-tooltip.component';

export const ChartTooltipStyled = styled(ChartTooltip)(
  ({
    theme: {
      colors: { TooltipBackgroundColor, TooltipTextColor, LightFontWeight },
    },
  }: {
    theme?: IDiscoverEmotionTheme;
  }) => css`
    svg {
      position: absolute;
      left: 0;
      top: 0;
    }

    .chart-tooltip-container {
      display: inline-block;
      position: absolute;
      opacity: 1;

      .invisible {
        opacity: 0;
      }
    }

    .chart-tooltip-value {
      display: inline-block;
      font-weight: 400;
      font-style: inherit;
      font-size: 12px;
      color: ${TooltipTextColor};
      fill: ${TooltipTextColor};
    }

    .chart-tooltip-label {
      display: inline-block;
      font-weight: 300;
      font-style: inherit;
      font-size: 12px;
      color: ${TooltipTextColor};
      fill: ${TooltipTextColor};
      margin-right: 20px;
      text-overflow: ellipsis;
      max-width: 175px;
      overflow: hidden;
    }

    .upArrow,
    .downArrow,
    .rightArrow,
    .leftArrow {
      fill: ${TooltipBackgroundColor};
    }

    .tooltipRect {
      fill: ${TooltipBackgroundColor};
    }

    .tooltip-content {
      display: inline-block;
      margin: 8px 8px 8px 10px;
      line-height: 18px;
      color: ${TooltipTextColor};
    }

    .tooltip-entry {
      width: 100%;
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
      max-width: 30em;
      word-wrap: break-word;
    }

    .funnel .tooltip-entry {
      max-width: 21em;
      white-space: break-spaces;
    }

    .chart-tooltip-extra {
      display: inline-block;
      ${font({
        size: '10px',
        weight: LightFontWeight,
        color: TooltipTextColor,
      })}
      fill: ${TooltipTextColor};
      font-style: italic;
      white-space: nowrap;
    }
  `,
);
