import styled from '@emotion/styled';

export const DropdownGroup = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  max-width: unset;
  white-space: nowrap;

  > div:nth-child(2) {
    min-width: 8rem;
  }
`;
