import TimeUtils from '../../../common/TimeUtils';
import ArrowDown from '../../../../images/sdd/arrow_down.svg';
import ArrowUp from '../../../../images/sdd/arrow_up.svg';
import { WarningIcon } from '../../../icons';

const ActivityFeedItemHeader = ({ children, indicatorIcon, feedItem }) => {
  const renderIcon = () => {
    switch (indicatorIcon) {
      case 'up':
        return <ArrowUp className='arrow-up' height='24' width='27' />;
      case 'down':
        return <ArrowDown className='arrow-down' height='24' width='27' />;
      case 'alert':
        return <WarningIcon size={28} />;
      default:
        return [];
    }
  };
  return (
    <div className='header'>
      <span className='title'>
        {renderIcon()}
        <span className={'title-text'}>{children}</span>
      </span>
      {feedItem && (
        <span className='date text-light text-small'>
          {TimeUtils.formatDate(parseInt(feedItem.date))}
        </span>
      )}
    </div>
  );
};
ActivityFeedItemHeader.role = 'ActivityFeedItemHeader';

ActivityFeedItemHeader.defaultProps = {
  indicatorIcon: 'up',
};
export default ActivityFeedItemHeader;
