import { css } from '@emotion/react';
import { useState, memo } from 'react';
import { isFunction, isString } from 'lodash';
import {
  SlicerCheckboxLabel,
  SlicerCheckbox,
} from '../slicer-widget/slicer-widget.styles';
import {
  SlicerOptionRow,
  SlicerOptionSelectOnlyButton,
} from './slicer-option.styles';
import { messages } from '../../../i18n';
import { useEllipsisDetector } from '../../../common/utilities/ellipsis-detector.hook';
import _ from 'lodash';
import { useDiscoverTheme } from '../../../common/emotion';
import { Tooltip } from '@mui/material';

interface ISlicerOptionProps {
  name: string;
  option: string;
  selected: boolean;
  onSelect: () => any;
  onSelectOnly: () => any;
  className?: string;
  width: number;
  style?: any;
  isMobile?: boolean;
  disableOnly?: boolean;
  labelOverride?: string;
}

export function SlicerLabel({ option, width, labelRef }) {
  const { darkMode, colors: { ContentText } = {} as any } = useDiscoverTheme();
  return (
    <div
      ref={labelRef}
      css={css({
        width: isString(width) ? width : width - 20,
        maxWidth: 'calc(100% - 10px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        marginLeft: 10,
        marginRight: 0,
        paddingTop: '3px',
        color: darkMode ? 'inherit' : ContentText,
      })}
    >
      {option}
    </div>
  );
}

export function SlicerSelectionColumn({
  option,
  selected,
  labelRef,
  onSelect,
  width,
  name,
  indeterminate = false,
}) {
  const { colors: { DropdownOptionHoverBackground } = {} } = useDiscoverTheme();
  return (
    <div
      css={css({
        flex: 1,
        minWidth: 0,
        '&:hover,&:focus': { backgroundColor: DropdownOptionHoverBackground },
      })}
    >
      <SlicerCheckboxLabel
        label={
          <SlicerLabel labelRef={labelRef} width={width} option={option} />
        }
        control={
          <div>
            <SlicerCheckbox
              id={`slicer-option-checkbox-${_.kebabCase(name)}__${_.kebabCase(
                option,
              )}`}
              indeterminate={indeterminate}
              checked={selected}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                onSelect();
              }}
            />
          </div>
        }
      />
    </div>
  );
}

export const SlicerButtonColumn = memo<{ onSelectOnly; width }>(
  ({ onSelectOnly, width }) => {
    return (
      <SlicerOptionSelectOnlyButton
        css={css({ width, minWidth: width })}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          if (isFunction(onSelectOnly)) {
            onSelectOnly();
          }
        }}
      >
        {messages.slicer.only}
      </SlicerOptionSelectOnlyButton>
    );
  },
);

export const SlicerOption = memo(
  ({
    option,
    selected,
    onSelect,
    onSelectOnly,
    className,
    style,
    isMobile,
    name,
    width,
    disableOnly,
  }: ISlicerOptionProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const buttonWidth = isHovered || isMobile ? 40 : 0;
    const { isEllipsized, ref } = useEllipsisDetector();
    return (
      <Tooltip
        placement='right'
        enterDelay={500}
        title={isEllipsized ? option : ''}
        arrow
      >
        <SlicerOptionRow
          id={`slicer-option-row-${_.kebabCase(name)}__${_.kebabCase(option)}`}
          style={style}
          onMouseOver={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => setIsHovered(false)}
          className={`combo-menu-item ${selected && 'selected'} ${className}`}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            onSelect();
          }}
        >
          <SlicerSelectionColumn
            name={name}
            labelRef={ref}
            option={option}
            onSelect={onSelect}
            selected={selected}
            width={width - (buttonWidth + 17)}
          />
          {(isHovered || isMobile) && !disableOnly && (
            <SlicerButtonColumn
              width={buttonWidth}
              onSelectOnly={onSelectOnly}
            />
          )}
        </SlicerOptionRow>
      </Tooltip>
    );
  },
);
