import originalMoment from 'moment-timezone';

let activeTimezone = 'America/New_York';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace DiscoverMomentExtension {
  export let originalFormat: string;
  export let ISO8601: string;
}

const moment: typeof DiscoverMomentExtension &
  typeof originalMoment = originalMoment as any;

moment.originalFormat = moment.defaultFormat;
export const globalDateWithoutTimeFormat = 'M/D/YYYY';
export const globalMomentFormat = `${globalDateWithoutTimeFormat} h:mm A`;
moment.defaultFormat = globalMomentFormat;
moment.ISO8601 = 'YYYY-MM-DDTHH:mm:ssZ';

const setTimeZone = timezone => {
  activeTimezone = timezone;
};

export const setGlobalFormat = format => {
  moment.defaultFormat = format;
};

// returns the name of the timezone for the browser, not the tenant
const getBrowserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export { setTimeZone, getBrowserTimezone };

// Create a Proxy to ensure timezone is set. Note many module references go out before activeTimezone so this
// method is necessary to ensure that the current active timezone is used.
const handler = {
  apply(target, thisArg, argumentsList) {
    return target.tz.setDefault(activeTimezone).apply(thisArg, argumentsList);
  },
};

export default new Proxy(moment, handler);
