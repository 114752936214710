import {
  useOpenVizDatasetSelector,
  useOpenVizLayoutSelector,
  useOpenVizSelector,
  useVizOptionSelector,
} from '../../../common/redux/selectors/viz-selector.hook';
import {
  find,
  flatMap,
  get,
  groupBy,
  head,
  keyBy,
  map,
  omit,
  reject,
  some,
} from 'lodash';
import { ChartSpecIds, DATA_FORMATTER, Types } from '../../../common';
import {
  FieldMetadata,
  IAnyAttribute,
  IAppliedFilters,
} from '../../../datasets';
import { useCallback } from 'react';
import { useDiscoverTheme } from '../../../common/emotion';
import ColorManager from '../../../common/d3/ColorManager';
import { useFormattedDateRange } from '../../filter/active-filter-panel/active-filter-panel.hook';
import {
  getCaseQueryCalcs,
  getDefaultSnapshotFieldName,
  getFirstMetric,
  getFormattedShelfName,
  getMetricCalcByFieldName,
  waterfallExtraCalcs,
  waterfallQueryMeasures,
} from './waterfall.util';
import { Viz } from '../../VizUtil';
import { IQueryCalc } from '../../interfaces';
import { MetricAggregateType, OnHoverArg } from '../pipeline-changes';
import { ChartSpecs } from '../../ChartSpecs';
import { TooltipData } from '../chart-tooltip/chart-tooltip.interface';
import { FieldsToShelves } from '../../viz-redirect';

export const useWaterfallCalcs = vizId => {
  const viz = useOpenVizSelector({ discoveryId: vizId });

  const caseQueryCalcs: IQueryCalc[] = getCaseQueryCalcs(viz);
  const firstMetricCalcs = waterfallExtraCalcs(viz.layout, viz.dataset);
  const firstMetric = getFirstMetric(viz);
  const additionalMetrics = map(
    firstMetricCalcs,
    ({ attributeName, expression }) =>
      ({
        name: attributeName,
        formula: expression,
        attributeType: Types.CALC,
        formatType: firstMetric?.formatType,
        defaultAggregation: firstMetric?.defaultAggregation,
      } as IAnyAttribute),
  );
  const caseCalcs = map(
    caseQueryCalcs,
    ({ attributeName, expression }) =>
      ({
        name: attributeName,
        formula: expression,
        attributeType: Types.STRING,
        formatType: DATA_FORMATTER.STRING.formatType,
        defaultAggregation: 'Sum',
      } as IAnyAttribute),
  );

  return {
    caseCalcs,
    caseQueryCalcs,
    additionalMetrics,
    additionalCalcs: caseCalcs.concat(additionalMetrics),
  };
};

export const useStartEndDate = (vizId: string) => {
  const dataset = useOpenVizDatasetSelector({ discoveryId: vizId });
  const viz = useOpenVizSelector({ discoveryId: vizId });

  const fieldName = getDefaultSnapshotFieldName(dataset);

  const filters: IAppliedFilters = useVizOptionSelector({
    discoveryId: vizId,
    option: 'filters',
  });
  const slicerFilters = keyBy(Viz.getFiltersFromSlicers(viz), 'field');

  const filter = get(slicerFilters, fieldName) ?? get(filters, fieldName);

  return useFormattedDateRange({ vizId, filter });
};

export const useFindCalc = vizId => {
  const layout = useOpenVizLayoutSelector({
    discoveryId: vizId,
  });

  const { caseCalcs: calcs } = useWaterfallCalcs(vizId);

  const { chartType } = useOpenVizSelector({ discoveryId: vizId });

  const getFieldByStageName = useCallback(
    (stageName: string): IAnyAttribute | undefined => {
      const shelfId = Viz.findShelfContainingField(
        omit(layout, [ChartSpecIds.SLICER, ChartSpecIds.VALUES]),
        stageName,
      );

      // @TODO note that fields can be renamed, and finding the field by name is not reliable
      return find(calcs, {
        name: getFormattedShelfName(chartType, shelfId),
      });
    },
    [layout, calcs, chartType],
  );

  return getFieldByStageName;
};

export const useBarColor = ({ vizId }) => {
  const theme = useDiscoverTheme();

  const getBarColor = useCallback(
    stageName => ColorManager.getColor(vizId, stageName, theme),
    [vizId, theme],
  );

  return {
    getBarColor,
  };
};

export const useOnHover = ({
  vizId,
  setTooltipData,
  fieldsToShelvesRef,
}: {
  vizId: string;
  setTooltipData: (data?: TooltipData) => void;
  fieldsToShelvesRef: React.MutableRefObject<FieldsToShelves>;
}) => {
  const viz = useOpenVizSelector({ discoveryId: vizId });
  const firstMetric = getFirstMetric(viz);

  const { caseQueryCalcs, additionalMetrics } = useWaterfallCalcs(vizId);

  return useCallback(
    ({ d3Data, anchor }: OnHoverArg = {} as OnHoverArg) => {
      if (!d3Data) {
        setTooltipData();
        fieldsToShelvesRef.current = {};
        return;
      }

      const field = getMetricCalcByFieldName(viz, d3Data.stageName);

      const tooltipData = reject(d3Data?.tooltipData, ({ name }) => {
        const isAdditionalMetric = some(additionalMetrics, { name });
        const isInShelfBucket = field?.attributeName === name;
        const isFirstMetric = firstMetric?.name === name;
        return (isAdditionalMetric && !isInShelfBucket) || isFirstMetric;
      });

      setTooltipData({
        value: tooltipData,
        anchor,
      });

      const fieldNames = map(caseQueryCalcs, 'attributeName');

      fieldsToShelvesRef.current = {
        [ChartSpecs.pivot.shelves.ROWS.id]: fieldNames,
      };
    },
    [
      viz,
      setTooltipData,
      caseQueryCalcs,
      fieldsToShelvesRef,
      additionalMetrics,
      firstMetric?.name,
    ],
  );
};

export const useYAxisMetrics = ({
  vizId,
  metricsWithStageAggregation,
}: {
  vizId: string;
  metricsWithStageAggregation: MetricAggregateType[][];
}) => {
  const viz = useOpenVizSelector({ discoveryId: vizId });

  const { stageOrder } = useStagedFields(vizId);

  const yAxisMetrics: MetricAggregateType[] = map(stageOrder, stageName => {
    const { attributeName: metricName } = getMetricCalcByFieldName(
      viz,
      stageName,
    );
    const stageMetricsByBucket = find(
      metricsWithStageAggregation,
      stageMetrics => head(stageMetrics)?.metricName === metricName,
    );
    return find(stageMetricsByBucket, { stage: stageName });
  });

  return yAxisMetrics;
};

export const useStagedFields = vizId => {
  const viz = useOpenVizSelector({ discoveryId: vizId });
  const layout = useOpenVizLayoutSelector({
    discoveryId: vizId,
  });

  const { additionalMetrics } = useWaterfallCalcs(vizId);

  const stageOrder = flatMap(
    [
      layout[ChartSpecIds.ENTRANCE] ?? [],
      layout[ChartSpecIds.CHANGE] ?? [],
      layout[ChartSpecIds.EXIT] ?? [],
    ],
    shelfFields => map(shelfFields, 'name'),
  );

  const fieldMetadata = useVizOptionSelector({
    discoveryId: vizId,
    option: 'fieldMetadata',
    defaultValue: {},
  });

  const groupedChangeFields = groupBy(
    layout[ChartSpecIds.CHANGE],
    ({ name }) => {
      const isDecrease = get(
        fieldMetadata,
        `${name}.${FieldMetadata.IS_DECREASE}`,
        false,
      );
      return isDecrease ? 'downside' : 'upside';
    },
  );

  const upsideStages = map(
    layout[ChartSpecIds.ENTRANCE].concat(groupedChangeFields.upside),
    'name',
  );
  const downsideStages = map(
    layout[ChartSpecIds.EXIT].concat(groupedChangeFields.downside),
    'name',
  );

  const queryMeasures = waterfallQueryMeasures(viz);
  const allFieldsInPlay = (Viz.getAllFieldsInPlay(viz.layout) ?? []).concat(
    additionalMetrics,
  );
  const metricFields = map(queryMeasures, ({ attributeName }) =>
    find(allFieldsInPlay, { name: attributeName }),
  );

  return {
    stageOrder,
    upsideStages,
    downsideStages,
    metricFields,
  };
};
