import { useCallback, useState } from 'react';
import { SettingsI18n } from '../settings-i18n';
import URLs from '../../common/Urls';
import { messages } from '../../i18n';
import { FullScreenPortal } from '../../common/widgets/dialogs/full-screen-portal';
import {
  ButtonContainer,
  InvisibleButton,
  PrimaryButton,
  SecondaryButton,
} from '../../ui';
import { SettingsUserProfile } from '../settings-user-profile';
import { useAccountMutation, useAccountQuery } from '../graphql';
import { IInternationalizationPreferences, IUserInfo } from '../interfaces';
import { isEqual } from 'lodash';
import { setDatetimeFormat } from '../../common';

const AccountSettings = () => {
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [updatedUserInfo, setUpdatedUserInfo] = useState<IUserInfo>(undefined);
  const { userInfo } = useAccountQuery();
  const { i18nPrefs } = userInfo ?? {};
  const { updateUser, saving } = useAccountMutation();

  const changeI18n = useCallback(
    (updatedPrefs: IInternationalizationPreferences) => {
      if (!isEqual(updatedPrefs, i18nPrefs)) {
        setSaveEnabled(true);
        const newUserInfo = {
          ...userInfo,
          i18nPrefs: updatedPrefs,
        };
        setUpdatedUserInfo(newUserInfo);
      }
    },
    [i18nPrefs, userInfo],
  );

  const close = () => {
    URLs.goToLastOrHome('/account');
  };

  const save = () => {
    updateUser(updatedUserInfo).then(() => {
      setSaveEnabled(false);
      const { i18nPrefs: updatedPrefs } = updatedUserInfo;
      setDatetimeFormat({
        dateFormat: updatedPrefs.dateFormat,
        timeFormat: updatedPrefs.timeFormat,
      });
    });
  };

  const saveAndClose = () => {
    save();
    close();
  };

  return (
    <FullScreenPortal
      className='account-settings'
      titlePanel={messages.account.profile}
      buttonGroup={
        <ButtonContainer>
          <InvisibleButton className='cancel-button' onClick={close}>
            {messages.cancel}
          </InvisibleButton>
          <SecondaryButton onClick={save} disabled={!saveEnabled}>
            {saving
              ? messages.editDatasetPanel.applying
              : messages.editDatasetPanel.applyChanges}
          </SecondaryButton>
          <PrimaryButton
            onClick={saveAndClose}
            disabled={!saveEnabled && !saving}
          >
            {messages.editDatasetPanel.saveChangesAndExit}
          </PrimaryButton>
        </ButtonContainer>
      }
    >
      <div className='account-settings__content'>
        <div className='account-profile-wrapper'>
          <div className='account-profile-section'>
            <h3 className='account-profile-header'>{messages.account.user}</h3>
            <SettingsUserProfile userInfo={userInfo} />
          </div>
          <div className={'account-profile-section'}>
            <h3 className={'account-profile-header'}>
              {messages.account.locale}
            </h3>
            <SettingsI18n
              changeI18n={changeI18n}
              i18nPrefs={i18nPrefs}
              isSaving={saving}
            />
          </div>
        </div>
      </div>
    </FullScreenPortal>
  );
};

export default AccountSettings;
