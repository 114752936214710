import { css } from '@emotion/react';
import { font, vbox, multiLineEllipsis, hbox } from '../mixins';

export const activityFeedMobileStyles = ({
  theme: {
    colors: {
      LightFontWeight,
      MediumFontWeight,
      CorvanaMediumBorder,
      RegularFontWeight,
      ContentBackground,
    },
  },
}) => css`
  #activity-feed-panel.feed-table {
    flex-direction: column;
  }
  .feed-table {
    overflow: hidden;
    padding-top: 0;
    ${font({ size: '16px', weight: LightFontWeight })}
  }
  .feed-table .feed-grouping {
    font-size: 20px;
    text-align: left;
    padding: 8px;
    margin: 2px;
  }
  .feed-table .feed-grouping:before {
    display: none;
  }
  .feed-table .feed-row {
    ${vbox()}
    width: 100%;
    padding: 8px 16px;
  }
  .feed-table .feed-row-left {
    flex-direction: column;
  }
  .feed-table .feed-row-body .header {
    display: block;
  }
  .feed-table .feed-row-body .header .arrow-down,
  .feed-table .feed-row-body .header .arrow-up {
    top: 4px;
    position: relative;
  }
  .feed-table .feed-row-body .header .title {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    padding: 0 8px;
  }
  .feed-table .feed-row-body .header .title .title-text {
    font-weight: ${MediumFontWeight};
  }
  .feed-table .feed-row-body .header .date {
    margin-left: 32px;
    line-height: 24px;
  }
  .feed-table .feed-row-body .message {
    font-size: 16px;
    padding: 0;
    max-width: calc(100% - 16px);
    line-height: 1.2em;
    text-align: justify;
  }
  .feed-table .feed-row-body .message.collapsed {
    ${multiLineEllipsis({
      lineHeight: '1.2em',
      lineCount: 2,
      backgroundColor: ContentBackground,
    })}
  }
  .feed-table .feed-row-body .message.collapsed:before {
    /* points in the end */
    content: '...';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
  }
  .feed-table .feed-row-body .message.collapsed:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: ${ContentBackground};
  }
  .feed-table .footer {
    height: 30px;
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    border-top: 1px solid ${CorvanaMediumBorder};
  }
  .feed-table .footer .feedback-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding-top: 2px;
  }
  .feed-table .footer .posFb,
  .feed-table .footer .negFb {
    margin-right: 16px;
  }
  .feed-table .footer .negFb {
    margin-left: 8px;
  }
  .feed-table .percent-changed-card {
    width: calc(100% - 16px);
    border: none;
    display: flex;
    margin-top: 16px;
    ${hbox()}
    ${font({ size: '16px', weight: RegularFontWeight })}
  }
`;
