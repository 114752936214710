import React from 'react';
import { createPortal } from 'react-dom';
import {
  FSPHeader,
  FSPTitle,
  FullScreenPanel,
  FSPBody,
} from './full-screen-portal.styles';
import { usePortalRef } from '../../../utilities/portal';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryComponentProps } from '../../../../components/error-boundary';

type IProps = {
  className?: string;
  titlePanel: string | JSX.Element | JSX.Element[];
  buttonGroup: string | JSX.Element | JSX.Element[];
  children: string | JSX.Element | JSX.Element[];
};

export const FullScreenPortal = ({
  className = '',
  titlePanel,
  buttonGroup,
  children,
}: IProps) => {
  const { getPortalContainer } = usePortalRef();
  const portalRef = getPortalContainer();

  return portalRef
    ? createPortal(
        <FullScreenPanel className={className}>
          <FSPHeader>
            <FSPTitle>{titlePanel}</FSPTitle>
            {buttonGroup}
          </FSPHeader>
          <ErrorBoundary {...ErrorBoundaryComponentProps}>
            <FSPBody className='modal-body'>{children}</FSPBody>
          </ErrorBoundary>
        </FullScreenPanel>,
        portalRef,
      )
    : null;
};
