import React, { useContext } from 'react';
import { messages } from '../../../../i18n';
import _ from 'lodash';
import {
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
} from '../../../../ui';
import { useSlicerTheme } from '../common';
import { SelectDropdownContext } from '../../../../ui/dropdowns/select-dropdown';

interface ISlicerButtonFooterProps {
  onSave: () => any;
  onCancel: () => any;
  saveDisabled: boolean;
  name: string;
}

export function SlicerButtonFooter({
  onSave,
  onCancel,
  name,
  saveDisabled,
}: ISlicerButtonFooterProps) {
  const { width } = useSlicerTheme();

  const { closeDropdown } = useContext(SelectDropdownContext);
  return (
    <ButtonContainer style={{ width }}>
      <SecondaryButton
        id={`slicer-cancel-button-${_.kebabCase(name)}`}
        onClick={e => {
          closeDropdown(e);
          onCancel();
        }}
        sx={{
          marginRight: '0.5rem',
        }}
      >
        {messages.slicer.cancel}
      </SecondaryButton>
      <PrimaryButton
        id={`slicer-apply-button-${_.kebabCase(name)}`}
        onClick={e => {
          closeDropdown(e);
          onSave();
        }}
        disabled={saveDisabled}
      >
        {messages.slicer.apply}
      </PrimaryButton>
    </ButtonContainer>
  );
}
