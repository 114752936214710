import { combineReducers } from 'redux';
import dataset from './DatasetReducers';
import activityFeed from './ActivityReducers';
import login from './LoginReducers';
import main from './MainReducers';
import discover from './DiscoverReducers';
import tenant from './TenantReducer';
import account from './AccountReducer';
import tables from './Tables';
import storage from './Storage';
import dashlet from './DashletReducers';

export default combineReducers({
  dataset,
  login,
  main,
  activityFeed,
  discover,
  tenant,
  tables,
  account,
  storage,
  dashlet,
});
