import {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
  SyntheticEvent,
} from 'react';
import { isEmpty, last, noop } from 'lodash';
import { SugarToast } from '../sugar-toast/sugar-toast.component';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../../i18n';
import Discover from '../../common/redux/actions/DiscoverActions';
import MainActions from '../../common/redux/actions/MainActions';
import { IToastContext } from './toast.interfaces';

export const ToastContext = createContext<IToastContext>({ showToast: noop });

export const ToastProvider = ({ children }) => {
  const toast = useRef<JSX.Element>();
  const [isShowing, setShowToast] = useState(false);

  const showToast = useCallback(
    _toastProps => {
      setShowToast(true);

      const { onClose = noop, type = 'info', label, children: toastChildren } =
        _toastProps ?? {};

      const doClose = () => {
        setShowToast(false);
        onClose();
      };

      toast.current = (
        <SugarToast open={true} onClose={doClose} type={type} label={label}>
          {toastChildren}
        </SugarToast>
      );
    },
    [toast],
  );

  useReduxToasts({ showToast });

  return (
    <ToastContext.Provider value={{ showToast }}>
      {isShowing && toast?.current}
      {children}
    </ToastContext.Provider>
  );
};

// legacy toasts are dispatched in redux
const useReduxToasts = ({ showToast }) => {
  const toasts = useSelector<{ main: any }>(
    (state: any) => state?.main?.toasts ?? [],
  ) as any[];
  const dispatch = useDispatch();

  const showFieldFilterDialog = useCallback(
    ({ field, key }) => {
      dispatch(Discover.showFieldFilterDialog(field));
      dispatch(MainActions.closeToast({ key }));
    },
    [dispatch],
  );

  const closeToast = useCallback(
    key => dispatch(MainActions.closeToast({ key })),
    [dispatch],
  );

  useEffect(() => {
    if (!isEmpty(toasts)) {
      const toastItem: any = last(toasts);
      const { text, key, toastType } = toastItem;

      // from legacy toast in viz.component
      if (!text && toastType === 'filtering-removal-message') {
        showToast({
          children: (
            <span>
              <h3>
                {messages.formatString(
                  messages.funnel.filteringOn,
                  <strong>{toastItem.closedStageSelected}</strong>,
                )}
              </h3>
              <p>
                {messages.viz.funnelMaxSupport}{' '}
                <a
                  onClick={(e: SyntheticEvent) => {
                    e.preventDefault();
                    showFieldFilterDialog({
                      field: toastItem.closedStageSelected,
                      key,
                    });
                  }}
                  href='#'
                >
                  {messages.viz.editStageFilter}
                </a>
              </p>
            </span>
          ),
          onClose: () => closeToast(key),
        });
      } else {
        showToast({
          children: <span>{text}</span>,
          onClose: () => closeToast(key),
        });
      }
    }
  }, [toasts, showToast, showFieldFilterDialog, closeToast]);
};

export const ToastHOC = Component => (props: any) => (
  <ToastContext.Consumer>
    {toastContext => {
      const { showToast = noop } = toastContext;
      return (
        <Component showToast={showToast} {...props}>
          {props.children}
        </Component>
      );
    }}
  </ToastContext.Consumer>
);
