import { useEffect, useState } from 'react';
import { InsightLoader } from '../../../common/widgets/loaders';
import { ISugarIconProps } from '../../';
import { SugarIcon } from '../../sugar-icon/sugar-icon.component';
import styled from '@emotion/styled';
import _ from 'lodash';
import { IndicatorBubble } from '../../sugar-icon/sugar-icon.util';
import { useSelector } from 'react-redux';

const WAIT = 1000;

const InsightLoaderStyled = styled(InsightLoader)`
  height: 24px;
  overflow: visible;
`;

const InsightsIconStyled = styled(SugarIcon)`
  position: relative;
`;

const InsightsIconContainer = styled.div<{ size?: number }>`
  height: ${props => props.size ?? 24}px;
  display: inline-block;
  position: relative;
`;

const PositionedIndicatorBubble = styled(IndicatorBubble)`
  position: absolute;
  top: 0px;
  right: 1px;
`;

export const InsightsIcon = (
  props: Omit<ISugarIconProps, 'icon'> & {
    animate?: boolean;
    hasNewInsights?: boolean;
    isMobile?: boolean;
  },
) => {
  const { animate: requestingAnimation, isMobile, hasNewInsights } = props;
  const isDashletMode = useSelector(
    (state: any) => state.dashlet.isDashletMode,
  );

  const size = isDashletMode ? 16 : props.size ?? 24;

  const [isAnimating, setAnimate] = useState(requestingAnimation);
  const [animationTimeoutId, setAnimationTimeoutId] = useState(null);
  const [bubble, setBubble] = useState(hasNewInsights);

  useEffect(() => {
    if (_.isBoolean(hasNewInsights) && hasNewInsights !== bubble) {
      setBubble(hasNewInsights);
    }
  }, [hasNewInsights, bubble, setBubble]);

  useEffect(() => {
    if (requestingAnimation) {
      setAnimate(true);
    } else if (isAnimating && _.isNil(animationTimeoutId)) {
      // do not instantaneously stop animating
      setAnimationTimeoutId(setTimeout(() => setAnimate(false), WAIT));
    }

    return () => {
      if (!_.isNil(animationTimeoutId)) {
        clearTimeout(animationTimeoutId);
        setAnimationTimeoutId(null);
      }
    };
  }, [
    requestingAnimation,
    animationTimeoutId,
    setAnimationTimeoutId,
    isAnimating,
  ]);

  return (
    <InsightsIconContainer size={size}>
      {isAnimating ? (
        <InsightLoaderStyled size={size} />
      ) : (
        <InsightsIconStyled
          hover
          icon={isMobile && !isDashletMode ? 'insights-xl' : 'insights-lg'}
          {..._.omit(props, ['animate', 'isMobile'])}
          size={size}
        />
      )}
      {bubble && !isAnimating && <PositionedIndicatorBubble size={6} />}
    </InsightsIconContainer>
  );
};
