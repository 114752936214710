import { useContext } from 'react';
import { useDiscoverTheme } from '../../common/emotion';
import { useDashletExternalLinkAction } from '../../components/MobileVizFooter/mobile-viz-footer.hook';
import _ from 'lodash';
import { useExportToExcel } from '../../common/utilities/export-to-excel';
import { WorkerRefContext } from '../../common/utilities/web-worker';
import { messages } from '../../i18n';
import { ChartPlaceholderWithMessage } from './chart-placeholder.component';
import { ExportExcelMessage } from './viz-chart.styles';
import { PrimaryButton } from '../../ui';

export const ExportToExcelPlaceholder = ({ vizId, queryResults }) => {
  const { isDashletMode } = useDiscoverTheme();
  const openInDiscoverCallback = useDashletExternalLinkAction(vizId);

  const { exportToExcel = _.noop, workerId } = useExportToExcel({
    vizId,
  });
  const { getWorkerMeta } = useContext(WorkerRefContext);
  const { isExporting: excelReportExporting = false } = getWorkerMeta(workerId);

  const buttonCallback = isDashletMode ? openInDiscoverCallback : exportToExcel;
  const buttonLabel = isDashletMode
    ? messages.formatString(
        messages.dashlet.openInApp,
        messages.nonTranslated.discover,
      )
    : messages.formatString(
        messages.discoveryToolbar.exportToExcelMenuItem,
        messages.nonTranslated.excel,
      );

  return (
    <ChartPlaceholderWithMessage vizId={vizId}>
      <ExportExcelMessage>
        <p className={'bold'}>
          {messages.formatString(
            messages.baseChart.largeDatasetAdjust,
            queryResults?.executeQuery?.results?.length,
          )}
        </p>
        <p>
          {messages.formatString(
            messages.baseChart.exportToExcelCallout,
            messages.nonTranslated.excel,
          )}
        </p>
        <PrimaryButton
          key='yes'
          onClick={buttonCallback}
          disabled={excelReportExporting}
        >
          {excelReportExporting
            ? messages.discoveryToolbar.exporting
            : buttonLabel}
        </PrimaryButton>
      </ExportExcelMessage>
    </ChartPlaceholderWithMessage>
  );
};
