import React, { useCallback, useState } from 'react';
import { ISugarToast } from './sugar-toast.interfaces';
import { CloseIcon, ToastIcon } from '../../icons';
import { Snackbar } from '@mui/material';

export const SugarToast = ({
  open = false,
  onClose,
  type = 'info',
  label,
  children,
}: ISugarToast) => {
  const [isOpen, setOpen] = useState(!!open);

  const doClose = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);
  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={7000}
      onClose={doClose}
    >
      <div id={'sugar-toast'}>
        <div
          style={{
            display: 'inline-flex',
            columnGap: '25px',
            padding: '15px 0 5px 15px',
          }}
        >
          <ToastIcon iconType={type} iconSize={'xl'} />
          <p>
            {label && <span className={'toast-notice'}>{`${label}: `}</span>}
            {children}
          </p>
        </div>
        <CloseIcon size={12} onClick={doClose} />
      </div>
    </Snackbar>
  );
};
