import _ from 'lodash';

export const unescapeCalcFieldReference = fieldReference => {
  let fieldRef = fieldReference.replace(/^\[/, '').replace(/]$/, '');

  // we want to unescape any internal square brackets now
  fieldRef = unescapeFieldName(fieldRef);
  return fieldRef;
};
export const unescapeFieldName = fieldName => {
  return fieldName.replace(/\\\[/g, '[').replace(/\\]/g, ']');
};
export const escapeFieldName = fieldReference => {
  return _.trim(fieldReference.replace(/\[/g, '\\[').replace(/]/g, '\\]'));
};
