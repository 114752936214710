const QueryResultUtils = {
  createResultsFromArray(arry, columnInfo) {
    return {
      executeQuery: {
        columnNames: arry[0],
        columnInfo,
        results: [...arry.slice(1)],
      },
    };
  },
};

export default QueryResultUtils;
