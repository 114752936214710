import { TransitionGroup, CSSTransition } from 'react-transition-group';

const component = ({ show, enterTimeout, leaveTimeout, children }) => {
  return (
    <div className={'transition-wrapper'}>
      <TransitionGroup
        transitionAppear
        transitionAppearTimeout={enterTimeout}
        transitionEnterTimeout={enterTimeout}
        classNames={'transition-slide-in'}
      >
        <CSSTransition timeout={leaveTimeout}>
          <div>{show && children}</div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};
component.defaultProps = {
  show: true,
  enterTimeout: 400,
  leaveTimeout: 200,
};

export default component;
