import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IDiscoverEmotionTheme } from '../../../common/emotion';
import { font, vbox, svgColorizePartial } from '../../../common/emotion/mixins';

const LeftPanelWidth = '200px';

export const CalcDialogFieldSearchContainer = styled.div(
  ({
    theme: {
      darkMode,
      colors: {
        PanelBorder,
        LightFontWeight,
        Strokes,
        ContentText,
      } = {} as any,
    } = {} as any,
  }: {
    theme?: IDiscoverEmotionTheme;
  }) => css`
    position: relative;
    background: #fff;
    border: 1px solid ${PanelBorder};
    border-bottom: none;
    input {
      ${font({ size: '12px', weight: LightFontWeight })}
      margin-bottom: 0px;
      border-radius: 0px;
      box-shadow: none;
      padding-right: 24px;
      padding-left: 30px;
      border: none;
      width: 100%;
      padding-bottom: 3px;
    }
    input:focus {
      border-color: ${Strokes};
      box-shadow: none;
      color: ${ContentText};
    }
    hr {
      position: absolute;
      bottom: 5px;
      right: 0px;
      left: 0px;
      margin: 0px 8px;
      border-color: ${darkMode && PanelBorder};
    }
    .sicon-search {
      padding-bottom: 3px;
    }
  `,
);

export const calcDialogStyles = ({
  theme: {
    darkMode,
    colors: {
      RegularFontWeight,
      LightFontWeight,
      negativeColor,
      PrimaryColor,
      PanelBorder,
      ContentText,
      ToolIconColor,
      PanelIconColor,
      ToolIconHoverColor,
      DropdownBackgroundHover,
      DropdownTextHover,
      DropdownTextPrimary,
      ErrorStatusTextColor,
      ValidStatusTextColor,
      CorvanaBrandOrangeHighlight,
      ActiveLinkText,
      CorvanaLightText,
      InputTextColor,
      ContentBackground,
      Black,
      FieldPillBackgroundHover,
      DropdownButtonBorderColor,
      PanelBackground,
      BorderColor,
    },
  },
}) => css`
  .calc-dialog .buttonGroup {
    margin-top: 8px;
  }
  .calc-dialog .viz-field-text {
    max-width: 150px !important;
  }
  .calc-dialog .sicon-chevron-right,
  .calc-dialog .sicon-chevron-down {
    margin-right: -4px;
    margin-top: 4px;
  }
  .calc-dialog-content {
    ${font({ size: '14px', weight: LightFontWeight })}
    ${vbox()}
    flex: 1;
    padding: 30px;
    background-color: ${PanelBackground};
  }
  .calc-dialog-content .fieldName {
    display: inline-block;
    flex-direction: column;
    margin: 0 16px 0 0;
  }
  .calc-dialog-content .fieldName input[type='text'] {
    width: 200px;
  }
  .calc-dialog-content .fieldName .controls {
    display: flex;
    flex-direction: column;
  }
  .calc-dialog-content .fieldName .controls .form-group {
    margin-bottom: 0;
  }
  .calc-dialog-content .fieldName .error-message {
    ${font({ size: '14px', weight: RegularFontWeight, color: negativeColor })}
    height: 20px;
    margin: 4px 0 8px 0;
  }
  .calc-dialog-content .fieldName.description {
    margin-right: 0;
  }
  .calc-dialog-content .fieldName.description .form-group {
    width: 100%;
  }
  .calc-dialog-content .fieldName.description input[type='text'] {
    width: 100%;
  }
  .calc-dialog-content .title {
    ${font({ weight: RegularFontWeight })}
    display: inline-block;
    margin-right: 15px;
  }
  .calc-dialog-content .title.left {
    width: ${LeftPanelWidth};
  }
  .calc-dialog-content .view-function-ref {
    color: ${PrimaryColor};
    float: right;
  }
  .calc-dialog-content .content {
    display: flex;
    flex: 1;
  }
  .calc-dialog-content .content .left {
    background: ${ContentBackground};
    width: ${LeftPanelWidth};
    margin-right: 15px;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
  }
  .calc-dialog-content .content .left .fields {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    border: 1px solid ${PanelBorder};
    border-top: none;
    position: absolute;
    top: 36px;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .calc-dialog-content .content .left .fields ul {
    list-style: none;
    padding: 0px;
  }
  .calc-dialog-content .content .left .fields .no-search-results {
    ${font({ size: '12px', color: ContentText })}
    text-align: center;
    padding: 8px 0px;
  }
  .calc-dialog-content .content .right {
    background: ${ContentBackground};
    flex: 1;
    display: flex;
    flex-direction: column;
    border: ${darkMode && `1px solid ${BorderColor}`}
  }
  .calc-dialog-content .content .right .calc {
    padding: 5px;
    display: flex;
    flex-direction: column;
    flex: 1;
    color: ${ToolIconColor};
  }
  .calc-dialog-content .content .right .calc .toolbar svg {
    margin-right: 10px;
  }
  ${svgColorizePartial({
    fromColor: PanelIconColor,
    toColor: ToolIconHoverColor,
  })('.calc-dialog-content .content .right .calc .toolbar svg:hover')}

  .calc-dialog-content .content .right .calc .area {
    flex: 1;
    resize: none;
  }
  .calc-dialog-content .content .right .footer {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    position: relative;
    height: 35px;
  }
  .calc-dialog-content .content .right .footer .count {
    white-space: nowrap;
    line-height: 18px;
  }
  .calc-dialog-content .dropdown-menu {
    width: ${LeftPanelWidth};
    background-color: ${ContentBackground};
  }
  .calc-dialog-content .dropdown-menu li:hover,
  .calc-dialog-content .dropdown-menu li.selected {
    background-color: ${DropdownBackgroundHover};
  }
  .calc-dialog-content .dropdown-menu li:hover a,
  .calc-dialog-content .dropdown-menu li.selected a {
    color: ${DropdownTextHover};
  }
  .calc-dialog-content .dropdown-menu li > a {
    ${font({
      size: '14px',
      weight: LightFontWeight,
      color: DropdownTextPrimary,
    })}
    display: flex;
    flex-direction: row;
    padding: 8px 16px;
  }
  .status-error {
    ${font({
      size: '14px',
      weight: LightFontWeight,
      color: ErrorStatusTextColor,
    })}
  }
  .status-valid {
    ${font({
      size: '14px',
      weight: LightFontWeight,
      color: ValidStatusTextColor,
    })}
  }
  .calc-field.dragging .viz-field-text .viz-field-label,
  .calc-field.dragging .viz-field-text .viz-field-label:hover {
    background-color: transparent;
    border: 1px solid transparent;
  }
  .calc-field .viz-field-text .viz-field-label {
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .calc-field .viz-field-text .viz-field-label:hover {
    border: 1px solid ${FieldPillBackgroundHover};
    border-radius: 4px;
    background-color: ${FieldPillBackgroundHover};
  }
  .drag-calc-field.drag-preview {
    ${font({ size: '14px', weight: LightFontWeight })}
    top: 20px;
    left: 20px;
  }
  .calc-field-syntax {
    ${font({
      size: '14px',
      weight: RegularFontWeight,
      color: CorvanaBrandOrangeHighlight,
    })}
  }
  .field-can-drop .ace-editor {
    color: ${ActiveLinkText} !important;
    opacity: 1 !important;
  }
  .field-can-drop .ace-editor::before {
    content: '\A';
    position: absolute;
    top: 0px;
    left: -5.5px;
    border-style: solid;
    border-width: 5px 3px 0px 5px;
    border-color: ${ActiveLinkText} transparent transparent transparent;
    height: 0px;
    width: 0px;
  }
  .field-can-drop .ace-editor text {
    ${font({ size: '12px', weight: LightFontWeight })}
    fill: ${Black};
    text-shadow: 0px 0px 2px ${ContentBackground},
      0px 0px 2px ${ContentBackground}, 0px 0px 2px ${ContentBackground},
      0px 0px 2px ${ContentBackground}, 0px 0px 2px ${ContentBackground},
      0px 0px 2px ${ContentBackground}, 0px 0px 2px ${ContentBackground};
    opacity: 0.4;
  }
  .field-can-drop .ace-editor.focused text {
    opacity: 1;
  }
  #calculated-measure-editor {
    margin-top: 8px;
  }
  #calculated-measure-editor .ace_invisible.ace_emptyMessage {
    ${font({ size: '14px', weight: LightFontWeight, color: CorvanaLightText })}
    padding: 1px 8px;
  }
  #calc-status {
    margin-left: 8px;
    line-height: 18px;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .calc-attribute-settings {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
  }
  .calc-attribute-settings .left {
    width: ${LeftPanelWidth};
    margin-right: 15px;
  }
  .calc-attribute-settings .setting {
    width: ${LeftPanelWidth};
    margin-right: 16px;
  }
  .calc-attribute-settings .setting label {
    ${font({ size: '14px', weight: RegularFontWeight })}
  }
  .calc-attribute-settings .data-type-dropdown__label.single,
  .calc-attribute-settings .dropdown-toggle.btn {
    ${font({ size: '14px', weight: LightFontWeight, color: InputTextColor })}
    text-align: left;
    width: ${LeftPanelWidth};
    border: 1px solid ${DropdownButtonBorderColor};
    border-radius: 4px;
    background-color: ${ContentBackground};
    padding: 9px 10px;
  }
`;
