import { Route, Switch } from 'react-router-dom';
import ActivityThumbnail from './ActivityThumbnail';
import NoThumbnailAvailable from './NoThumbnailAvailable';

const Thumbnail = () => {
  return (
    <div className='thumb'>
      <Switch>
        <Route
          path='/thumbnail/activity/:activityId'
          component={ActivityThumbnail}
        />
        <Route path='/thumbnail/viz/:vizId'>
          <h2>TODO: Render a thumbnail for a report</h2>
        </Route>
        <Route path='*' component={NoThumbnailAvailable} />
      </Switch>
    </div>
  );
};

export default Thumbnail;
