import { Component } from 'react';
import { throttle } from 'throttle-debounce';

export default WrappedComponent =>
  class Wrapped extends Component {
    constructor(props) {
      super(props);
      this.onResize = throttle(200, e => this._resize(e));
    }
    componentDidMount() {
      window.addEventListener('resize', this.onResize);

      // Fire one event on load to set initial size
      const event = document.createEvent('Event');
      event.initEvent('resize', true, true);
      this._resize(event);
    }
    shouldComponentUpdate(nextProps) {
      if (WrappedComponent.shouldComponentUpdate) {
        return WrappedComponent.shouldComponentUpdate(nextProps);
      }
      return true;
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize);
    }

    _resize(event) {
      if (this.wrappedComponent && this.wrappedComponent.onResize) {
        this.wrappedComponent.onResize(event);
      } else if (this.props.onResize) {
        // Supports Functional Components
        this.props.onResize(event);
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          ref={c => {
            this.wrappedComponent = c;
          }}
        />
      );
    }
  };
