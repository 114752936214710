import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { ellipsis, hex2rgba } from './../../../common/emotion/mixins';
import { useDiscoverTheme } from '../../../common/emotion/theme';
import { setLightness } from 'polished';

export const SubMenu = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
`;

export const SubMenuTitle = styled.li(_ => {
  const {
    darkMode,
    colors: {
      SugarGrayPalette: { Gray60, Gray70, Gray90 },
      LightBlue,
      DarkBlue,
      PivotWhite,
      PanelBackground,
      ContentText,
    },
  } = useDiscoverTheme();

  const textColor = darkMode ? ContentText : Gray90;

  return css`
    font-size: 0.875rem;
    color: ${textColor};
    padding: 0.5rem 0.75rem 0.125rem;
    font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif;
    position: sticky;
    top: 0;
    width: 100%;
    align-self: flex-start;
    background-color: ${darkMode ? PanelBackground : PivotWhite};
    z-index: 5;

    span {
      border-bottom: 1px solid ${textColor};
      display: block;
    }

    .sicon {
      position: absolute;
      top: 8px;
      right: 4px;
      color: ${darkMode ? Gray60 : Gray70};

      &:hover {
        color: ${darkMode ? setLightness(0.92, LightBlue) : DarkBlue};
      }
    }
  `;
});

export const KabobIconStyled = styled.button`
  background-color: transparent;
  border: none;
  width: 1rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;

  .sicon {
    border: none;
    color: ${({ theme }) => theme.colors.PivotWhite};
  }

  &:hover,
  &.active {
    background-color: ${({ theme }) => theme.colors.LightBlue};

    .sicon {
      color: ${({ theme }) => theme.colors.DarkBlue};
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 0.75rem;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
`;

export const MenuListStyled = styled(MenuList)<{ open: boolean }>`
  padding: 0px;
  position: absolute;
  left: 100%;
  box-shadow: 0px 4px 4px ${({ theme }) => hex2rgba(theme.colors.Black, 0.25)};
  border-radius: 8px;
  border: none;
  width: 200px;
  overflow: hidden;
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  overflow-y: auto;
  max-height: 22rem;
`;

export const MenuItemStyled = styled(MenuItem)(() => {
  const {
    darkMode,
    colors: {
      SugarGrayPalette: { Gray60, Gray70, Gray90 },
      PivotWhite,
      LightBlue,
      DarkBlue,
      PanelBackground,
      ContentText,
      PrimaryColor,
    },
  } = useDiscoverTheme();

  const textColor = darkMode ? ContentText : Gray90;
  const textHoverColor = darkMode ? setLightness(0.92, LightBlue) : DarkBlue;
  const iconColor = darkMode ? Gray60 : Gray70;

  return css`
    height: auto;
    border: 0;
    background-color: ${darkMode ? PanelBackground : PivotWhite};
    color: ${textColor};
    padding: 0;

    &:last-child {
      position: sticky;
      bottom: 0;
      width: 100%;
      align-self: flex-end;
      z-index: 5;
    }

    > div {
      display: flex;
      padding: 0.5rem 0.75rem;
      min-width: 0;
    }

    span {
      font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1rem;
      ${ellipsis()}
    }

    a {
      padding: 0.5rem 0.75rem;
      color: ${textColor};
      display: flex;
      min-width: 0;
      width: 100%;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    .sicon {
      color: ${iconColor};
    }

    svg {
      height: 0.75rem;

      path {
        fill: ${iconColor};
      }
    }

    &:hover {
      background-color: ${darkMode ? PrimaryColor : LightBlue};
      color: ${textHoverColor};

      a,
      .sicon {
        color: ${textHoverColor};
      }

      svg {
        path {
          fill: ${textHoverColor};
        }
      }
    }
  `;
});
