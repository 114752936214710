import {
  useCallback,
  useState,
  useEffect,
  useMemo,
  memo,
  MouseEvent,
  useRef,
} from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { List } from 'react-virtualized';
import Calendar from 'rc-calendar';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import enUS from 'rc-calendar/lib/locale/en_US';
import { Moment } from 'moment';
import { SlicerOptionRow } from '../../slicer-option/slicer-option.styles';
import { RightChevron } from '../../../../icons';
import { SCROLL_ROW_HEIGHT, useSlicerTheme } from '../../slicer-widget/common';
import {
  DatePickerWrapper,
  SlicerLabel,
  SlicerOptionSubMenuWrapper,
  StyledList,
  PopperStyled,
} from './timestamp-slicer-options.styles';
import { SlicerOptionSubMenu } from './slicer-option-sub-menu';
import { useSlicerDimensions } from '../../slicer-widget/common/slicer-dimensions.hook';
import { messages } from '../../../../i18n';
import { ClickOutsideListener } from '../../../../components/ClickOutsideListener';
import moment, { globalDateWithoutTimeFormat } from '../../../../common/Moment';
import { TimestampSlicerTypes } from '../../interfaces';
import { useAccount } from '../../../../common/utilities/account';
import {
  ListButtonStyled,
  PaperStyled,
} from '../../../../components/nested-dropdown/nested-dropdown.styles';
import {
  IOptionRowProps,
  ITimestampSlicerOptionsProps,
} from './timestamp-slicer-options.interfaces';
import { useResizeObserver } from '../../../../common/utilities/resize-observer';

const timestampMenuItems = [
  messages.slicer.setTimeFrame,
  messages.slicer.since,
  messages.slicer.between,
];

export const OptionRow = memo<IOptionRowProps>(
  ({
    id,
    name,
    timestampMenuItem,
    style,
    selectTimeFrame,
    options,
    breadcrumbs,
    pushBreadcrumb,
    clear,
    listHeight,
  }) => {
    const [_anchor, setAnchorRefEl] = useState<HTMLElement>(null);
    const anchorEl = useRef<HTMLElement>();
    const setAnchorEl = useCallback(
      (el: HTMLElement | null) => {
        // the use of the setAnchorRefEl and ref prevent flickering of the popper portal
        anchorEl.current = el;
        setAnchorRefEl(el);
      },
      [anchorEl],
    );
    const resizeElement = useMemo(() => anchorEl?.current ?? _anchor, [
      anchorEl,
      _anchor,
    ]);
    const { right: anchorRightPos = 0 } =
      useResizeObserver(resizeElement) ?? {};
    const isOpen = useMemo(() => Boolean(resizeElement), [resizeElement]);
    const [hoverValue, setHoverValue] = useState([]);
    const isMobile = useSelector((state: any) => state.main.isMobile);
    const isBetweenType = useMemo(
      () => _.last(options).option === TimestampSlicerTypes.BETWEEN,
      [options],
    );
    const isSinceType = useMemo(
      () => _.last(options).option === TimestampSlicerTypes.SINCE,
      [options],
    );

    const { currentUser: { i18nPrefs = {} } = {} } = useAccount();

    const { isDashletMode, width } = useSlicerTheme();

    const disablePortal = isMobile || isDashletMode;

    const closeSubMenu = useCallback(() => {
      clear();
      setAnchorEl(null);
    }, [setAnchorEl, clear]);

    const handleOnClick = (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      pushBreadcrumb(timestampMenuItem);

      isOpen && closeSubMenu();
      setAnchorEl(isOpen ? null : e.currentTarget);
    };

    useEffect(() => {
      if (isMobile && breadcrumbs.length === 0) {
        closeSubMenu();
      }
    }, [breadcrumbs, closeSubMenu, isMobile]);

    useEffect(() => {
      moment.updateLocale(moment.locale(), {
        invalidDate: '',
      });
      return () =>
        moment.updateLocale(moment.locale(), {
          invalidDate: messages.slicer.invalidDate,
        });
    }, []);

    const setRangeTimeFrame = useCallback(
      (vals: string[], includeCurrentPeriod: boolean) => {
        selectTimeFrame([
          ...vals,
          `${includeCurrentPeriod}`,
          TimestampSlicerTypes.RANGE,
        ]);
      },
      [selectTimeFrame],
    );

    const setSinceTimeFrame = useCallback(
      (val: Moment) => {
        if (_.isNil(val)) {
          clear();
        } else {
          selectTimeFrame([
            val.startOf('day').format(moment.ISO8601),
            TimestampSlicerTypes.SINCE,
          ]);
        }
      },
      [clear, selectTimeFrame],
    );

    const setBetweenTimeFrame = useCallback(
      (val: Moment[]) => {
        if (_.isNil(val) || val.length < 2) {
          clear();
        } else {
          selectTimeFrame([
            val[0].startOf('day').format(moment.ISO8601),
            val[1].endOf('day').format(moment.ISO8601),
            TimestampSlicerTypes.BETWEEN,
          ]);
        }
      },
      [clear, selectTimeFrame],
    );

    const sinceTypeSelectedValue = () => {
      if (isSinceType) {
        const head = _.head(options);
        if (head.isSelected) {
          return _.head(options).option;
        }
      }
      return null;
    };

    const betweenTypeSelectedValue = () => {
      if (isBetweenType) {
        return [
          moment(_.head(options).option),
          moment(_.last(_.dropRight(options)).option),
        ];
      }
      return [null, null];
    };

    const onHoverChange = hoverValue => {
      if (hoverValue.length == 1) {
        setHoverValue([...hoverValue, ...hoverValue]);
      } else {
        setHoverValue(hoverValue);
      }
    };

    useEffect(() => {
      if (isBetweenType) {
        setHoverValue([
          moment(_.head(options).option),
          moment(_.last(_.dropRight(options)).option),
        ]);
      } else {
        setHoverValue([]);
      }
    }, [isBetweenType, options]);

    const getFormat = () =>
      _.isEmpty(i18nPrefs?.dateFormat)
        ? globalDateWithoutTimeFormat
        : i18nPrefs?.dateFormat;

    const renderSubMenu = timestampMenuItem => {
      const subMenu = {
        [timestampMenuItems[0]]: (
          <SlicerOptionSubMenu
            options={options}
            selectRange={setRangeTimeFrame}
            name={name}
          />
        ),
        [timestampMenuItems[1]]: (
          <DatePickerWrapper className='corvana-calendar-picker since-calendar'>
            <Calendar
              format={getFormat()}
              selectedValue={moment(sinceTypeSelectedValue())}
              locale={enUS}
              showOk={false}
              onChange={setSinceTimeFrame}
            />
          </DatePickerWrapper>
        ),
        [timestampMenuItems[2]]: (
          <DatePickerWrapper className='corvana-calendar-picker between-calendar'>
            <RangeCalendar
              selectedValue={betweenTypeSelectedValue()}
              hoverValue={hoverValue}
              onHoverChange={onHoverChange}
              format={getFormat()}
              locale={enUS}
              showOk={false}
              onSelect={setBetweenTimeFrame}
            />
          </DatePickerWrapper>
        ),
      };

      if (_.isNil(subMenu[timestampMenuItem])) {
        return null;
      }

      return subMenu[timestampMenuItem];
    };

    return (
      <SlicerOptionRow
        id={id}
        style={
          disablePortal
            ? { ...style, height: 'initial', minWidth: '100%' }
            : style
        }
        width={width}
        className='combo-menu-item'
      >
        <ListButtonStyled
          key={'popper-control'}
          aria-owns={open ? id : undefined}
          aria-haspopup={true}
          onClick={handleOnClick}
          disableRipple
        >
          <SlicerLabel
            height={style.height}
            isOpen={isOpen}
            className='main-timestamp-option'
          >
            <span>{timestampMenuItem}</span>
            <RightChevron size={16} />
          </SlicerLabel>
          <PopperStyled
            key={'popper-content'}
            id={id}
            anchorEl={resizeElement}
            open={isOpen}
            placement={'right-start'}
            onClick={e => e.stopPropagation()}
            disablePortal={disablePortal}
            popperOptions={
              disablePortal
                ? {
                    modifiers: [
                      {
                        name: 'popperOffsets',
                        phase: 'read',
                        enabled: true,
                        fn: ({ state }) => ({
                          ...state,
                          popperOffsets: {
                            x: anchorRightPos ?? 0,
                            y: 0,
                          },
                        }),
                      },
                    ],
                  }
                : {}
            }
          >
            <PaperStyled square>
              <ClickOutsideListener
                isChildrenVisible={isOpen}
                onClickAway={e => {
                  if (
                    !(
                      e?.target?.tagName === 'BUTTON' ||
                      isMobile ||
                      resizeElement.contains(e.target)
                    )
                  ) {
                    closeSubMenu();
                  }
                }}
              >
                <SlicerOptionSubMenuWrapper
                  isSubMenuOpen={isOpen}
                  listHeight={listHeight}
                >
                  {renderSubMenu(timestampMenuItem)}
                </SlicerOptionSubMenuWrapper>
              </ClickOutsideListener>
            </PaperStyled>
          </PopperStyled>
        </ListButtonStyled>
      </SlicerOptionRow>
    );
  },
);

export const TimestampSlicerOptions = memo<ITimestampSlicerOptionsProps>(
  ({
    options,
    name,
    slicerErrorVisible,
    selectTimeFrame,
    breadcrumbs,
    pushBreadcrumb,
    clear,
  }) => {
    const { isDashletMode, isMobile } = useSlicerTheme();
    const { height, width } = useSlicerDimensions({
      optionsLength: timestampMenuItems.length,
      slicerErrorVisible,
    });

    const RowRenderer = useCallback(
      ({ key, index, style }) => {
        const timestampMenuItem = timestampMenuItems[index];

        return (
          <OptionRow
            id={`slicer-option-row-${_.kebabCase(name)}__${_.kebabCase(
              timestampMenuItem,
            )}`}
            name={name}
            key={key}
            style={{
              ...style,
              maxHeight:
                isDashletMode || isMobile ? SCROLL_ROW_HEIGHT : 'initial',
            }}
            listHeight={height}
            timestampMenuItem={timestampMenuItem}
            options={options}
            selectTimeFrame={selectTimeFrame}
            breadcrumbs={breadcrumbs}
            pushBreadcrumb={pushBreadcrumb}
            clear={clear}
          />
        );
      },
      [
        name,
        isDashletMode,
        isMobile,
        height,
        options,
        selectTimeFrame,
        breadcrumbs,
        pushBreadcrumb,
        clear,
      ],
    );

    return (
      <StyledList>
        <List
          width={width}
          height={height}
          style={{ overflow: 'inherit', maxHeight: height }}
          rowCount={timestampMenuItems.length}
          rowHeight={SCROLL_ROW_HEIGHT}
          rowRenderer={RowRenderer}
        />
      </StyledList>
    );
  },
);
