import { css } from '@emotion/react';
import { font, vbox, hbox, svgColorize } from './mixins';

export const activityFeedStyles = ({
  theme: {
    colors: {
      PanelBackground,
      Strokes,
      PanelBorder,
      ContentBackground,
      MediumBorder,
      CorvanaMediumBorder,
      ValidStatusTextColor,
      ErrorStatusTextColor,
      DimText,
      ContentText,
      LightFontWeight,
      RegularFontWeight,
      ActiveLinkText,
      CorvanaLightText,
      ActivityFeedChartBackground,
      ActivityFeedChartBorder,
      Black,
    },
  },
}) => css`
  .feed-grouping {
    ${font({ size: '16px', weight: 'normal' })}
    background: ${PanelBackground};
    padding: 2px;
    text-align: center;
    margin-bottom: 16px;
    padding-top: 16px;
    z-index: 1000;
    position: sticky;
    position: -webkit-sticky;
    -webkit-box-shadow: 0px 2px 1px 0px ${PanelBackground};
    -moz-box-shadow: 0px 2px 1px 0px ${PanelBackground};
    box-shadow: 0px 2px 1px 0px ${PanelBackground};
  }
  .fade-me-in {
    animation: fade-in 0.75s;
  }
  .fade-me-out {
    animation: fade-out 0.5s;
  }
  .feed-grouping > span {
    background: ${PanelBackground};
    padding: 0px 6px;
    position: relative;
    z-index: 1;
  }
  .feed-grouping:before {
    background: ${Strokes};
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: 27px;
    width: 1240px;
    left: calc(50% - 620px);
  }
  .feed-grouping.frozen::after {
    content: '';
    background: ${PanelBackground};
    position: absolute;
    width: 100%;
    left: 0px;
    top: 40px;
    height: 16px;
    border-bottom: 1px solid ${Strokes};
  }
  .feed-grouping.frozen {
    z-index: 100;
    /* The -15px avoids overlap with the scrollbar */
    width: calc(100% - 15px);
    position: fixed;
  }
  .feed-table {
    padding-top: 8px;
    margin: 0 auto;
    height: 100%;
    overflow: auto;
    border-collapse: collapse;
    ${font({ size: '22px', weight: LightFontWeight })}
  }
  .feed-table .error {
    text-align: center;
  }
  .feed-table .feed-row {
    ${vbox()}
    width: 1240px;
    margin-left: auto;
    margin-right: auto;
    min-height: 172px;
    padding: 8px 8px;
    display: flex;
    border: 1px solid ${PanelBorder};
    background: ${ContentBackground};
    -webkit-box-shadow: 0px 2px 1px 0px ${MediumBorder};
    -moz-box-shadow: 0px 2px 1px 0px ${MediumBorder};
    box-shadow: 0px 2px 1px 0px ${MediumBorder};
    margin-bottom: 8px;
  }
  .feed-table .feed-row.last {
    border-bottom: none;
  }
  .feed-table .activity-widget,
  .feed-table .feed-row-body {
    ${vbox()}
    flex: 1;
  }
  .feed-table .activity-widget a,
  .feed-table .feed-row-body a {
    color: ${ActiveLinkText};
    cursor: pointer;
  }
  .feed-table .activity-widget .dataTable,
  .feed-table .feed-row-body .dataTable {
    max-height: 133px;
    overflow: auto;
  }
  .feed-table .activity-widget .dataTable th,
  .feed-table .feed-row-body .dataTable th {
    padding: 8px 6px 7px 6px !important;
    /* Overwrite dataTable styling */
  }
  .feed-table .activity-widget .dataTable td,
  .feed-table .feed-row-body .dataTable td {
    height: initial !important;
    /* Overwrite dataTable styling */
  }
  .feed-table .activity-widget {
    flex: unset;
  }
  .feed-table .header {
    ${hbox()}
    width: 100%;
  }
  .feed-table .header > * {
    display: inline-block;
  }
  .feed-table .header .date {
    margin-left: 8px;
    margin-right: 8px;
    white-space: nowrap;
    line-height: 38px;
  }
  .feed-table .header a {
    color: ${ActiveLinkText};
  }
  .feed-table .header > .feed-icon {
    width: 1px;
    height: 18px;
    margin-bottom: 8px;
    margin-right: 8px;
    margin-left: 0px;
  }
  .feed-table .header svg {
    margin-right: 8px;
    flex-shrink: 0;
  }
  .feed-table .header .title {
    ${font({ size: '22px', weight: LightFontWeight })}
  }
  .feed-table .category {
    ${font({ weight: 'normal' })}
    width: 125px;
    max-width: 125px;
    min-width: 125px;
  }
  .feed-table .feed-row-left {
    ${hbox()}
    padding-bottom: 8px;
  }
  .feed-table .visualization {
    display: inline-block;
    width: 243px;
    height: 138px;
    background: ${ActivityFeedChartBackground};
    border: 1px solid ${ActivityFeedChartBorder};
  }
  .feed-table .visualization img {
    margin-left: 9px;
    width: 225px;
    height: 155px;
  }
  .feed-table .visualization .bar-label {
    fill: ${Black};
  }
  .feed-table .feed-row-body {
    flex: 1;
    ${vbox()}
    ${font({ size: '14px' })}
    margin-top: 8px;
  }
  .feed-table .feed-row-body .message {
    flex: 1;
    padding: 4px;
  }
  .feed-table .footer {
    ${vbox()}
    ${font({ size: '14px' })}
    flex: 1;
  }
  .feed-table .footer .actions {
    ${hbox()}
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid ${CorvanaMediumBorder};
  }
  .feed-table .footer .actions .feedback-container {
    ${hbox()}
    align-items: center;
  }

  ${svgColorize({ color: ValidStatusTextColor })('.arrow-up')}
  ${svgColorize({ color: ValidStatusTextColor })(`
    .inverted .arrow-down,
    .weighted-forecast.inverted .arrow-down,
    .percent-changed-card.inverted .arrow-down,
    .inverted .weighted-forecast .arrow-down,
    .inverted .percent-changed-card .arrow-down
  `)}
  ${svgColorize({ color: ErrorStatusTextColor })(`
    .inverted .arrow-up,
    .weighted-forecast.inverted .arrow-up,
    .percent-changed-card.inverted .arrow-up,
    .inverted .weighted-forecast .arrow-up,
    .inverted .percent-changed-card .arrow-up
  `)}

  .inverted .pct.up,
  .weighted-forecast.inverted .pct.up,
  .percent-changed-card.inverted .pct.up,
  .inverted .weighted-forecast .pct.up,
  .inverted .percent-changed-card .pct.up {
    color: ${ErrorStatusTextColor};
  }
  .inverted .pct.down,
  .weighted-forecast.inverted .pct.down,
  .percent-changed-card.inverted .pct.down,
  .inverted .weighted-forecast .pct.down,
  .inverted .percent-changed-card .pct.down {
    color: ${ValidStatusTextColor};
  }
  .percent-changed-card,
  .weighted-forecast {
    ${vbox()}
    justify-content: flex-start;
    width: 242px;
    padding: 6px;
    border: 1px solid ${DimText};
  }
  .percent-changed-card .title,
  .weighted-forecast .title {
    text-align: center;
    ${font({ size: '14px', weight: RegularFontWeight, color: ContentText })}
  }
  .percent-changed-card .title svg,
  .weighted-forecast .title svg {
    display: none;
  }
  .percent-changed-card > div.card-date,
  .weighted-forecast > div.card-date {
    ${font({ size: '14px', weight: LightFontWeight, color: CorvanaLightText })}
    text-align: left;
    width: 100%;
  }
  .percent-changed-card span,
  .weighted-forecast span {
    line-height: 18px;
  }
  .percent-changed-card .pct-container,
  .weighted-forecast .pct-container {
    ${vbox()}
    justify-content: flex-end;
    padding: 0px 0px;
    width: 175px;
  }
  .percent-changed-card .pct,
  .weighted-forecast .pct {
    ${hbox()}
    ${font({ size: '48px', weight: LightFontWeight })}
    line-height: 80px;
    align-items: center;
    white-space: nowrap;
  }
  .percent-changed-card .pct.pct-digits-5,
  .weighted-forecast .pct.pct-digits-5 {
    font-size: 34px;
  }
  .percent-changed-card .pct.pct-digits-4,
  .weighted-forecast .pct.pct-digits-4 {
    font-size: 40px;
  }
  .percent-changed-card .pct.pct-digits-3,
  .weighted-forecast .pct.pct-digits-3 {
    font-size: 48px;
  }
  .percent-changed-card .pct.pct-digits-2,
  .weighted-forecast .pct.pct-digits-2 {
    font-size: 52px;
  }
  .percent-changed-card .pct.up,
  .weighted-forecast .pct.up {
    color: ${ValidStatusTextColor};
  }
  .percent-changed-card .pct.up .inverted,
  .weighted-forecast .pct.up .inverted {
    color: ${ErrorStatusTextColor};
  }
  .percent-changed-card .pct.down,
  .weighted-forecast .pct.down {
    color: ${ErrorStatusTextColor};
  }
  .percent-changed-card .pct.down .inverted,
  .weighted-forecast .pct.down .inverted {
    color: ${ValidStatusTextColor};
  }
  .percent-changed-card .pct svg,
  .weighted-forecast .pct svg {
    margin-bottom: 4px;
    margin-right: 3px;
  }
  .percent-changed-card .period,
  .weighted-forecast .period {
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
    text-align: center;
    width: 105px;
  }
  .percent-changed-card .bar-group .bar-label,
  .weighted-forecast .bar-group .bar-label {
    fill: ${ContentText};
    text-anchor: middle;
  }
  .percent-changed-card .axis,
  .weighted-forecast .axis {
    ${hbox()}
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
    margin: 0px 5px;
  }
  .percent-changed-card .axis span,
  .weighted-forecast .axis span {
    text-align: center;
  }
  .percent-changed-card .chart,
  .weighted-forecast .chart {
    ${vbox()}
    justify-content: flex-end;
  }
  .percent-changed-card .area-chart,
  .weighted-forecast .area-chart {
    ${vbox()}
    justify-content: flex-end;
    min-height: 75px;
  }
  .percent-changed-card .area-chart > div:first-of-type,
  .weighted-forecast .area-chart > div:first-of-type {
    flex: 1;
  }
  .supervised-activity-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .supervised-activity-item .loading-button {
    margin-right: 8px;
  }
  .supervised-activity-item .loading-button span {
    color: ${ContentBackground};
  }
  .approve-activity {
    background: ${PanelBackground};
    text-align: center;
    z-index: 1002;
    float: left;
  }
  .approve-activity .btn {
    min-width: 140px;
    position: absolute;
    left: 16px;
    top: 8px;
  }
  .feed-table .ReactVirtualized__Grid.ReactVirtualized__List {
    overflow-y: overlay !important;
  }
  .feed-table .idleops .feed-row-left {
    flex-direction: column;
  }
  .feed-table .idleops .feed-row-left .activity-widget {
    margin-top: 8px;
  }
  .percent-changed-card > div.card-body {
    ${vbox()}
  }
  .weighted-forecast-body-table {
    ${hbox()}
    margin: 8px 0px;
    ${font({ size: '14px' })}
  }
  .weighted-forecast-body-table .labels {
    ${vbox()}
    padding-right: 20px;
  }
  .weighted-forecast-body-table .labels span {
    font-weight: ${RegularFontWeight};
    text-align: left;
  }
  .weighted-forecast-body-table .values {
    ${vbox()}
  }
  .weighted-forecast-body-table .values span {
    font-weight: ${LightFontWeight};
    text-align: right;
  }
  .pct-up {
    color: green;
  }
  .pct-down {
    color: red;
  }
  .inverted .pct-up {
    color: red;
  }
  .inverted .pct-down {
    color: green;
  }
`;
