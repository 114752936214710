import { HistoryWrapper } from './report-history.styles';
import { isEqual, map, noop } from 'lodash';
import { HistoryItem } from '../history-item';
import { IReportHistoryProps } from './report-history.interfaces';
import { useReportHistory } from './report-history.hook';
import { useState } from 'react';

export const ReportHistory = ({
  vizId,
  revisionSelected,
}: IReportHistoryProps) => {
  const [selectedVersion, setSelectedVersion] = useState<number>(
    revisionSelected?.version || -1,
  );
  const { fetchVersion, loading, historyItems } = useReportHistory(vizId);

  const handleSelectVersion = (version: number) => {
    setSelectedVersion(version);
    fetchVersion(version);
  };

  return (
    <HistoryWrapper>
      {map(historyItems, (props, i) => {
        const isFetching =
          isEqual(selectedVersion, props.commitVersion) && loading;
        const isSelected =
          isEqual(selectedVersion, props.commitVersion) ||
          (props.isCurrentVersion && selectedVersion < 0);
        return (
          <HistoryItem
            key={`history-item-${i}`}
            commitMessage={props.commitMessage}
            commitDate={props.commitDate}
            commitAuthor={props.commitAuthor}
            commitVersion={props.commitVersion}
            fetchingViz={isFetching}
            isCurrentVersion={props.isCurrentVersion}
            isFirstVersion={props.isFirstVersion}
            selected={isSelected}
            onClick={isSelected ? noop : handleSelectVersion}
            disabled={props.disabled}
          />
        );
      })}
    </HistoryWrapper>
  );
};
