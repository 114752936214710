import styled from '@emotion/styled';

import CheckIconSVG from '../../../images/sdd/Check.svg';

export EditIcon from '../../../images/sdd/edit_dataset_icon.svg';
export AddIcon from '../../../images/sdd/icon_add.svg';
export RefreshIcon from '../../../images/sdd/icon_refresh.svg';
export DeleteIcon from '../../../images/sdd/delete_icon.svg';
export CancelIconBubble from '../../../images/sdd/icon_cancel_bubble.svg';
export CaretIcon from '../../../images/sdd/icon_caret.svg';
export UserIcon from '../../../images/sdd/icon_user.svg';
export SearchIcon from '../../../images/sdd/icon-search.svg';
export ClearAllIcon from '../../../images/sdd/icon-clear-all.svg';
export IndicatorBubbleIcon from '../../../images/sdd/icon_indicator.svg';
export UploadIcon from '../../../images/sdd/icon_upload.svg';

/* Data Types */
export TimestampIcon from '../../../images/sdd/dataTypes/icon_timestamp.svg';

import _ from 'lodash';

export const CheckIconSmall = styled(props => (
  <CheckIconSVG {..._.omit(props, 'show')} />
))`
  width: 15px;
  height: 15px;
  position: absolute;
  left: 5px;
  display: ${({ show }) => (show === true ? 'block' : 'none')} !important;
`;

export const CheckIcon = CheckIconSVG;
