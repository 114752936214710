import styled from '@emotion/styled';

export const SkeletonWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  svg {
    width: 100%;
    clipPath {
      rect {
        x: 0;
        width: 100%;
      }
    }
  }
`;
