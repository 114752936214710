const REDIRECT_PATH = 'redirectPath';
/**
 * Allows the app to have a universal /redirect path which leverages a SessionStorage for the actual redirect value
 *
 * Useful in OAUTH2 redirects where the list of redirect URLs is limited
 *
 */
const RedirectComponent = () => {
  const redirect = window.sessionStorage.getItem(REDIRECT_PATH);
  if (redirect) {
    window.sessionStorage.removeItem(REDIRECT_PATH);
    window.location.href = redirect;
  }
  return null;
};

export { REDIRECT_PATH };
export default RedirectComponent;
