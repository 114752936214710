import { useCallback, useState } from 'react';
import { messages } from '../../i18n';
import { ButtonContainer, InvisibleButton, PrimaryButton } from '../../ui';
import { FullScreenPortal } from '../../common/widgets/dialogs/full-screen-portal';
import { ModalLabel } from '../../views/VizSaveDialog/viz-save-dialog.styles';
import { FormInput } from '../../components/ui/form-input';
import _, { isEmpty } from 'lodash';
import { useCreateDataset } from './create-dataset-modal.hook';
import {
  StyledFormatRoot,
  StyledFormGroup,
} from './create-dataset-modal.styles';

interface ICreateDatasetModelProps {
  doCancel: () => void;
}

export const CreateDatasetModal = ({ doCancel }: ICreateDatasetModelProps) => {
  const [datasetName, setDatasetName] = useState('');
  const [datasetDescription, setDatasetDescription] = useState('');

  const { isSaving, createDataset } = useCreateDataset();

  const handleSave = useCallback(() => {
    createDataset({ name: datasetName, description: datasetDescription });
  }, [createDataset, datasetDescription, datasetName]);

  return (
    <FullScreenPortal
      titlePanel={messages.datasets.createDataset}
      buttonGroup={
        <ButtonContainer>
          <InvisibleButton
            className='cancel-button'
            onClick={doCancel}
            disabled={isSaving}
          >
            {messages.cancel}
          </InvisibleButton>
          <PrimaryButton
            onClick={handleSave}
            disabled={isEmpty(datasetName) || isSaving}
          >
            {messages.datasets.createDataset}
          </PrimaryButton>
        </ButtonContainer>
      }
    >
      <StyledFormatRoot id='sugarcrm-import-panel'>
        <StyledFormGroup>
          <ModalLabel>{messages.editDatasetPanel.datasetNameLabel}</ModalLabel>
          <FormInput
            className={`name-input`}
            placeholder={messages.editDatasetPanel.datasetNameLabel}
            value={datasetName}
            autoFocus
            onChange={e => {
              setDatasetName(e?.target?.value);
            }}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <ModalLabel>
            {messages.editDatasetPanel.datasetDescriptionLabel}
          </ModalLabel>
          <FormInput
            className={`description-input`}
            placeholder={messages.optional}
            value={datasetDescription}
            onChange={e => {
              setDatasetDescription(e?.target?.value);
            }}
          />
        </StyledFormGroup>
      </StyledFormatRoot>
    </FullScreenPortal>
  );
};
