import { IAnnotation } from './annotation.interface';
import { FieldTypes, PriorPeriodTypes } from '../../common/Constants';

type StringType = 'STRING';
type TimeCalcType = 'TIME_CALC';

export type AttributeType = StringType | TimeCalcType | string;

export interface ITimeAttribute {
  key: string;
  fiscalKey?: string;
  displayText: string;
  calcFunction: string;
  moment_val?: any;
  hidden?: boolean;
  abbreviation: string;
  order: number;
}

export interface IAttribute {
  aggregateMeasure?: boolean;
  annotations?: IAnnotation[];
  attributeType: FieldTypes;
  calculation?: string; // basically the same as `formula`. Should we consolidate?
  formula?: string; // basically the same ase `calculation`. Should we consolidate? Is formula for timestamps only?
  defaultAggregation?: string;
  description?: string;
  formatType?: string;
  hidden?: boolean;
  name: string;
  ordinalAttribute?: string;
  requiresOrdinal?: boolean;
  ordinalFormula?: string;
  parentField?: IAttribute;
  children?: IAttribute[];
  calcType?: string;
  requiredFields?: IAnyAttribute[];
  timeField?: IAnyAttribute;
  valueField?: IAnyAttribute;
  priorPeriodType?: typeof PriorPeriodTypes;
}

export const FieldMetadata = {
  IS_DECREASE: 'isDecrease',
  WATERFALL_DRILL_CALC: 'WATERFALL_DRILL_CALC',
};

export type FieldMetadataKeys = keyof typeof FieldMetadata;
export type FieldMetadataType = {
  [K in typeof FieldMetadata[FieldMetadataKeys]]: any;
};

export type IStringAttribute = IAttribute;

export interface ITimeCalcAttribute extends IAttribute {
  formula?: string;
  fieldListDisplayName?: string;
  timeAttribute?: ITimeAttribute;
  priorPeriodFormula?: string;
}

export type IAnyAttribute = IStringAttribute | ITimeCalcAttribute;
