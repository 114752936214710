import {
  enUs,
  arSA,
  bgBG,
  caES,
  csCZ,
  daDK,
  deDE,
  elGR,
  enGB,
  esCO,
  esES,
  etEE,
  fiFI,
  frFR,
  heIL,
  hrHR,
  huHU,
  itIT,
  jaJP,
  koKR,
  ltLT,
  lvLV,
  nlNL,
  noNO,
  plPL,
  ptBR,
  ptPT,
  roRO,
  ruRU,
  skSK,
  sqAL,
  srCS,
  svSE,
  thTH,
  trTR,
  ukUA,
  zhCN,
  zhTW,
} from './locales';
import * as LocalizedStrings from 'react-localization';
import _ from 'lodash';
import { Types } from '../common/Constants';
import * as nonTranslated from './nonTranslated.json';

export const languageDictionaryMap = {
  en: enUs,
  'ar-SA': arSA,
  bg: bgBG,
  'ca-ES': caES,
  'cs-CZ': csCZ,
  'da-DK': daDK,
  de: deDE,
  'el-GR': elGR,
  'en-GB': enGB,
  'es-CO': esCO,
  es: esES,
  'et-EE': etEE,
  fi: fiFI,
  fr: frFR,
  'he-IL': heIL,
  hr: hrHR,
  hu: huHU,
  it: itIT,
  'ja-JP': jaJP,
  'ko-KR': koKR,
  lt: ltLT,
  lv: lvLV,
  nl: nlNL,
  no: noNO,
  pl: plPL,
  'pt-BR': ptBR,
  pt: ptPT,
  ro: roRO,
  ru: ruRU,
  sk: skSK,
  'sq-AL': sqAL,
  'sr-CS': srCS,
  'sv-SE': svSE,
  th: thTH,
  tr: trTR,
  'uk-UA': ukUA,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
};

// enUS is always the base object as any new labels
// are added there first during the release cycle
export const messagesUnproxied = new LocalizedStrings.default(
  _.mapValues(languageDictionaryMap, _langDictionary =>
    _.merge({}, enUs, _langDictionary, nonTranslated),
  ),
);

// LocalizedStrings library fails hard when parameters are undefined
const messagesHandler = {
  get(target, prop, receiver) {
    if (prop === 'formatString') {
      return function(...args) {
        const correctedStrings = _.map(args, _arg => {
          // some parameters can be wrapped in JSX
          return _.isNil(_arg) ? '' : _arg;
        });
        return target[prop].apply(this, correctedStrings);
      };
    }
    return Reflect.get(target, prop, receiver);
  },
};
export const messages: typeof enUs &
  typeof LocalizedStrings.default & { [key: string]: any } = new Proxy(
  messagesUnproxied,
  messagesHandler,
);

// avoiding using Hierarchy.TIME_ATTRIBUTES from ../discovery/VizUtil here because of circular dependencies
const FiscalTimeHierarchyNames = ['Year', 'Quarter', 'Week', 'week-in-quarter'];
const fiscalKeyMap = {
  Year: 'fiscalYear',
  Quarter: 'fiscalQuarter',
  Week: 'fiscalWeek',
  'week-in-quarter': 'fiscalWeekInQuarter',
};

export const i18nUtils = {
  //
  // this function is used in rendering calendar field labels in their
  // appropriate locale.  Due to the current implementation of our field
  // selection approach in calcs and queries, we need to leave calendar field
  // names as is, for instance "Year (Close Date)", which gets stored in the
  // saved report.  But upon rendering in various places, that string needs to
  // be translated to the appropriate locale, including drop zones and
  // tooltips.  We use the simple heuristic of an open paren to see if
  // the name may be a calendar field, and attempt to translate the value
  // from the timeAttributes sub-section of i18n strings.
  //
  translateFieldName: (msgs, fieldOrName, useFiscalCalendar = false) => {
    let name = fieldOrName?.name ?? fieldOrName;
    let prefix = name;
    let suffix = '';

    // split parenthesis for later recombination
    const suffixIdx = name.indexOf('(');
    if (suffixIdx > 0) {
      suffix = name.substring(suffixIdx);
      prefix = name.substring(0, suffixIdx).trim();
    }

    let messagesKey = prefix;

    // change to fiscal-specific key if there is one
    if (
      useFiscalCalendar &&
      ((fieldOrName?.attributeType === Types.TIME_CALC &&
        _.includes(
          FiscalTimeHierarchyNames,
          fieldOrName.fieldListDisplayName,
        )) ||
        _.includes(FiscalTimeHierarchyNames, prefix))
    ) {
      messagesKey = _.get(fiscalKeyMap, prefix, prefix);
    }

    // translate the label key, or default to the label key
    prefix = _.get(msgs, `timeAttributes.${messagesKey}`, prefix);

    // recombine if needed
    name = suffixIdx > 0 ? `${prefix} ${suffix}` : prefix;

    return name;
  },
  getAggregationDisplayText: (aggregationName: string): string => {
    switch (aggregationName) {
      case 'Sum':
        return messages.aggregation.sum;
      case 'Count':
        return messages.aggregation.count;
      case 'Count (Distinct)':
        return messages.aggregation.countDistinct;
      case 'Maximum':
        return messages.aggregation.maximum;
      case 'Minimum':
        return messages.aggregation.minimum;
      case 'Average':
        return messages.aggregation.average;
      case 'None':
      case 'NONE':
        return messages.aggregation.none;
      default:
        return 'Unknown';
    }
  },
};
