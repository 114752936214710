// This file exists only to resolve the circular dependencies between VizUtil
// and ChartSpecs. The setters are intended to only be called once by the
// VizUtils and ChartSpecs files once they initialize their global objects. The
// getters do not need to be used anywhere else outside of these two files.

let _vizUtil;
let _chartSpecs;

export const setVizUtil = vizUtil => {
  _vizUtil = vizUtil;
};

export const getVizUtil = () => _vizUtil;

export const setChartSpecs = chartSpecs => {
  _chartSpecs = chartSpecs;
};

export const getChartSpecs = () => _chartSpecs;
