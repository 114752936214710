import {
  useEffect,
  useRef,
  forwardRef,
  KeyboardEvent,
  createContext,
  useCallback,
} from 'react';
import { Box } from '@mui/system';
import { useDiscoverTheme } from '../../../common/emotion/theme/discover-emotion-theme-provider.component';
import { DownChevron } from '../../../icons/icons';
import Paper from '@mui/material/Paper';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import { messages } from '../../../i18n';
import { BoxProps, BoxTypeMap } from '@mui/system/Box/Box';
import {
  StyledDiv,
  useDropdownStyles,
  useHeaderSx,
  useSelectItemSx,
} from './select-dropdown.styles';
import { usePopperProps, useDashletClickAway } from './select-dropdown.hook';
import { ISelectDropdownProps } from './select-dropdown.interface';
import { isFunction, noop } from 'lodash';
import { useHasValueChanged } from '../../../common/utilities/state-helpers.hook';

export const SelectDropdownContext = createContext({
  closeDropdown: noop,
});

export const SelectDropdown = forwardRef<
  HTMLDivElement,
  ISelectDropdownProps & { children?: any }
>(
  (
    {
      title,
      children,
      id,
      className,
      'aria-labelledby': ariaLabelledby,
      'aria-label': ariaLabel,
      headerSx: providedHeaderSx = {},
      popperSx: providedPopperSx = {},
      listSx: providedListSx = {},
      disablePortal = true,
      closeAfterSelect = true,
      onToggle = noop,
      onDismiss = noop,
      ...remainingProps
    },
    forwardingRef,
  ) => {
    const headerRef = useRef(null);
    const listRef = useRef(null);
    const dropdownRef = useRef(null);

    const { isDashletMode } = useDiscoverTheme();

    const {
      PopperComponent,
      anchorEl,
      handleToggle: handlePopperToggle,
      handleClickAway: handlePopperClickAway,
      isOpen: isPopperOpen,
      renderedPlacement,
    } = usePopperProps({ onToggle });

    const dismiss = useCallback(() => {
      isFunction(onDismiss) && onDismiss();
      handlePopperClickAway();
    }, [handlePopperClickAway, onDismiss]);

    useDashletClickAway({ anchorEl, handleClickAway: dismiss });

    const handleAfterClick = useCallback(
      e => {
        if (
          (e?.target as HTMLElement)?.tagName !== 'INPUT' &&
          closeAfterSelect &&
          listRef?.current &&
          listRef.current.contains(e?.target)
        ) {
          setTimeout(() => handlePopperToggle(e), 50);
        }
      },
      [listRef, handlePopperToggle, closeAfterSelect],
    );

    const hasListRefChanged = useHasValueChanged({ value: listRef?.current });

    // asynchronously close menu on child click. allows the onClick for the ListItem to run
    useEffect(() => {
      if (hasListRefChanged && listRef?.current) {
        listRef.current.addEventListener('click', handleAfterClick);
      }
    }, [listRef, hasListRefChanged, handleAfterClick]);

    const {
      headerSx,
      popperSx,
      listSx,
      paperSx,
      wrapperStyle,
    } = useDropdownStyles({
      renderedPlacement,
      isOpen: isPopperOpen,
      headerSx: providedHeaderSx,
      popperSx: providedPopperSx,
      listSx: providedListSx,
      headerRef,
      listRef,
      dropdownRef,
      disablePortal,
    });

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e?.code === 'Space') {
        handlePopperToggle(e);

        if (isPopperOpen) {
          dismiss();
        }
      }
    };

    const handleKeyUpCapture = (e: KeyboardEvent) => {
      if (e?.code === 'Escape' && isPopperOpen) {
        handlePopperToggle(e);
        dismiss();
      }
    };

    return (
      <SelectDropdownContext.Provider
        value={{ closeDropdown: handlePopperClickAway }}
      >
        <div ref={dropdownRef} style={wrapperStyle}>
          <StyledDiv
            ref={forwardingRef}
            id={id}
            className={className}
            onKeyUpCapture={handleKeyUpCapture}
            role={'listbox'}
            isOpen={isPopperOpen}
            {...remainingProps}
          >
            <DropdownHeader
              ref={headerRef}
              sx={headerSx}
              onClick={handlePopperToggle}
              onKeyUp={handleKeyUp}
              tabIndex={0}
              aria-labelledby={ariaLabelledby}
              aria-label={ariaLabel}
              role={'list'}
            >
              {title ?? `${messages.search}...`}
            </DropdownHeader>
            <PopperComponent
              open={isPopperOpen}
              anchorEl={anchorEl}
              placement={'bottom-start'}
              sx={popperSx}
              disablePortal={disablePortal}
            >
              <ClickAwayListener
                onClickAway={(e: MouseEvent) => {
                  // click away binds to the document, not the shadowRoot
                  if (
                    isDashletMode ||
                    (e?.target as HTMLElement)?.tagName === 'INPUT'
                  ) {
                    return;
                  }
                  dismiss();
                }}
              >
                <Paper sx={paperSx} square elevation={0}>
                  <List ref={listRef} sx={listSx} disablePadding>
                    {children}
                  </List>
                </Paper>
              </ClickAwayListener>
            </PopperComponent>
          </StyledDiv>
        </div>
      </SelectDropdownContext.Provider>
    );
  },
);

export const SelectItem = forwardRef<
  HTMLLIElement,
  ListItemProps & { children?: any }
>(({ sx, children, ...props }: ListItemProps, forwardingRef) => {
  const itemSx = useSelectItemSx({ providedSx: sx });

  return (
    <ListItem
      ref={forwardingRef}
      role={'listitem'}
      tabIndex={0}
      sx={itemSx}
      disablePadding
      {...props}
    >
      {children}
    </ListItem>
  );
});

export const DropdownHeader = forwardRef<
  HTMLDivElement,
  BoxProps<BoxTypeMap['defaultComponent']>
>(
  (
    {
      sx = {},
      children,
      'aria-labelledby': ariaLabelledby,
      'aria-label': ariaLabel,
      ...props
    }: BoxProps<BoxTypeMap['defaultComponent']>,
    forwardingRef,
  ) => {
    const { colors: { Gray70 } = {} } = useDiscoverTheme();

    const headerSx = useHeaderSx({
      providedSx: sx,
    });

    return (
      <Box ref={forwardingRef} sx={headerSx} {...props}>
        <span
          className={'title'}
          aria-labelledby={ariaLabelledby}
          aria-label={ariaLabel}
        >
          {children}
        </span>
        <DownChevron className={'caret'} size={12} color={Gray70} />
      </Box>
    );
  },
);
