import {
  ButtonContainer,
  InvisibleButton,
  PrimaryButton,
} from '../../ui/button';
import _ from 'lodash';
import classnames from 'classnames';
import { messages } from '../../i18n';
import { ModalPortal } from '../../common/widgets/dialogs/modal-portal';
import { IUserInputDialogProps } from './user-input-dialog.interfaces';

export const UserInputDialog = ({
  className,
  dialogClassName,
  title,
  children,
  onCancel,
  onOk,
  cancelDisabled,
  cancelButtonLabel,
  okDisabled,
  pendingAction,
  loadingText,
  okButtonLabel,
}: IUserInputDialogProps) => {
  return (
    <ModalPortal
      className={className}
      title={title}
      contentClassName={classnames('user-input-dialog', dialogClassName)}
      buttonGroup={
        <ButtonContainer
          style={{
            marginBottom: '0.8rem',
            alignSelf: 'flex-end',
            marginRight: '1rem',
          }}
        >
          <InvisibleButton onClick={() => onCancel()} disabled={cancelDisabled}>
            {_.get(messages, cancelButtonLabel, cancelButtonLabel) ||
              messages.cancel}
          </InvisibleButton>
          <PrimaryButton
            onClick={() => onOk()}
            disabled={okDisabled || pendingAction}
          >
            {pendingAction
              ? loadingText
              : _.get(messages, okButtonLabel, okButtonLabel)}
          </PrimaryButton>
        </ButtonContainer>
      }
      zIndex={1051}
    >
      <div className={'scrollable'}>{children}</div>
    </ModalPortal>
  );
};
