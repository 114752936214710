import { Component } from 'react';
import { DragLayer } from 'react-dnd';
import { DnDTypes } from './common/Constants';
import { FieldPillDragPreview } from './discovery/FieldPill';
import { CalcFieldDragPreview } from './discovery/calc/CalcField';
import { compose } from 'react-recompose';

function getItemStyles(props) {
  const { initialOffset, currentOffset } = props;
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x = 0, y = 0 } = currentOffset;
  if (props.itemType === DnDTypes.CALC_FIELD) {
    x += 25;
    y += 20;
  }
  // position the preview element
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

class CustomDragLayer extends Component {
  renderItem(type, item, isOverTarget) {
    switch (type) {
      case DnDTypes.VIZ_FIELD:
        return <FieldPillDragPreview {...item} isOverTarget={isOverTarget} />;
      case DnDTypes.CALC_FIELD:
        return <CalcFieldDragPreview {...item} isOverTarget={isOverTarget} />;
      default:
        return null;
    }
  }

  render() {
    const { item, itemType, isDragging, isOverTarget } = this.props;

    if (!isDragging) {
      return null;
    }

    return (
      <div id={'custom-dnd-layer'}>
        <div style={getItemStyles(this.props)}>
          {this.renderItem(itemType, item, isOverTarget)}
        </div>
      </div>
    );
  }
}

const collect = monitor => {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    isOverTarget: targetId => monitor.isOverTarget(targetId, { shallow: true }),
  };
};

export default compose(DragLayer(collect))(CustomDragLayer);
