import { useCallback, useEffect } from 'react';
import { find, isEmpty, isNil, isUndefined } from 'lodash';
import { AggregationContext } from '../Filter';
import { getFieldsFromViz } from '../FieldsDropDown';
import { useOpenDiscoveryPresentStateSelector } from '../../../common/redux/selectors/viz-selector.hook';
import { Viz } from '../../VizUtil';
import { useFilterDialogContext } from '../filter-dialog/filter-dialog.context';

export const useAggregateCondition = ({ vizId }) => {
  const { field, filter, changeAggregateFilter } = useFilterDialogContext();

  const discovery = useOpenDiscoveryPresentStateSelector({
    discoveryId: vizId,
  });
  const _aggContext = filter.aggregationContext;
  const allFields = Viz.getAllAvailableFields(discovery.viz);
  let selectedField = isNil(_aggContext)
    ? null
    : find(allFields, { name: _aggContext.field });
  let selectedAggregation = isNil(_aggContext) ? null : _aggContext.aggregation;
  if (isNil(selectedField)) {
    const fields = getFieldsFromViz(
      discovery.viz,
      { ...field, name: '' },
      false,
    );
    selectedField = isEmpty(fields.fieldsInPlay)
      ? fields.fieldsNotInPlay[0]
      : fields.fieldsInPlay[0];
  }
  if (isNil(selectedAggregation)) {
    selectedAggregation = selectedField?.defaultAggregation;
  }

  const _changeFilter = useCallback(
    (condition, aggContext) => {
      const f = { ...filter };
      f.expression.left = condition;
      if (!isUndefined(aggContext)) {
        f.aggregationContext = aggContext;
      }
      changeAggregateFilter(f);
    },
    [changeAggregateFilter, filter],
  );

  const onAggFieldChange = useCallback(
    field => {
      const aggContext = new AggregationContext(
        field.name,
        field.defaultAggregation,
      );
      _changeFilter(filter.expression.left, aggContext);
    },
    [_changeFilter, filter.expression.left],
  );

  const onAggregationChange = useCallback(
    aggregation => {
      const aggContext = new AggregationContext(
        selectedField?.name,
        aggregation.name,
      );
      _changeFilter(filter.expression.left, aggContext);
    },
    [_changeFilter, filter.expression.left, selectedField?.name],
  );

  useEffect(() => {
    if (isNil(_aggContext)) {
      const aggregationContext = new AggregationContext(
        selectedField?.name,
        selectedField?.defaultAggregation,
      );
      changeAggregateFilter({ ...filter, aggregationContext });
    }
  }, [
    _aggContext,
    changeAggregateFilter,
    filter,
    selectedField?.defaultAggregation,
    selectedField?.name,
  ]);

  return {
    viz: discovery.viz,
    onAggFieldChange,
    selectedAggregation,
    selectedField,
    onAggregationChange,
    field,
  };
};
