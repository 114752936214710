import { css } from '@emotion/react';
import { svgColorize } from '../../common/emotion/mixins';

export const cellMenuStyles = ({
  theme: {
    colors: { ActiveLinkText, TabHighlightDisabled },
  },
}) => css`
  .cell-menu-root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px 0 0;
  }
  .cell-menu-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 25px;
  }
  .cell-menu-root:hover .cell-menu-edit {
    display: block;
  }
  ${svgColorize({ color: ActiveLinkText })(
    '.cell-menu-root:hover .cell-menu-edit:hover',
  )}

  .cell-menu-icon {
    cursor: pointer;
  }
  .cell-menu-icon.disabled {
    cursor: default;
  }
  ${svgColorize({ color: TabHighlightDisabled })('.cell-menu-icon.disabled')}
  .cell-menu-edit {
    display: inline-block;
    position: relative;
    display: none;
  }
  .cell-menu-edit svg {
    position: absolute;
  }
`;
