import _, { head, noop } from 'lodash';
import {
  HorizontalCell,
  HorizontalCellInteractive,
  VerticalCellInteractiveName,
  SubCell,
} from '../../components/ui/table';
import { ClickToCopy } from '../../components/ui/form';
import moment from '../../common/Moment';
import MuiMenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { IconDropdown } from '../../components/icon-dropdown';
import { ChartSpecs } from '../../discovery/ChartSpecs';
import { messages } from '../../i18n';
import { SearchableDropdown } from '../../ui/dropdowns/searchable-dropdown';

export const DIMENSIONS = {
  HEADER_HEIGHT: 48,
  ROW_HEIGHT: 80,
};

export const TableSpec = {
  searchOptions: {
    keys: ['name', 'creatorName', 'description'],
  },
  columns: [
    {
      key: 'chartType',
      label: 'table.chartTypeLabel',
      width: 72,
      maxWidth: 72,
      sortable: true,
      flex: 0,
      hidden: false,
      advanced: false,
      cellRenderer: ({ rowData, onClick = noop }) => {
        const { chartType } = rowData;
        const spec = _.get(ChartSpecs, chartType);
        // @NOTE we are not using onRowClick on UITable because isPrivate dropdown needs event propagation
        return (
          <HorizontalCellInteractive onClick={() => onClick({ rowData })}>
            {_.isNil(spec) ? '' : spec.listIcon}
          </HorizontalCellInteractive>
        );
      },
    },
    {
      key: 'name',
      label: 'table.nameLabel',
      width: 440,
      maxWidth: 500,
      sortable: true,
      flex: 1,
      hidden: false,
      advanced: false,
      cellRenderer: ({ rowData, onClick }) => {
        const { name, description } = rowData;
        return (
          <VerticalCellInteractiveName
            onClick={() => onClick({ rowData })}
            title={name}
          >
            {name}
            <SubCell title={description}>{description}</SubCell>
          </VerticalCellInteractiveName>
        );
      },
    },
    {
      key: 'creatorName',
      label: 'table.creatorNameLabel',
      width: 212,
      maxWidth: 272,
      sortable: true,
      flex: 1,
      hidden: false,
      advanced: false,
      cellRenderer: ({ rowData, onClick }) => {
        const { creatorName } = rowData;
        return (
          <HorizontalCellInteractive
            onClick={() => onClick({ rowData })}
            title={creatorName}
          >
            {creatorName}
          </HorizontalCellInteractive>
        );
      },
    },
    {
      key: 'isPrivate',
      label: 'table.isPrivateLabel',
      width: 172,
      maxWidth: 172,
      sortable: true,
      flex: 0,
      hidden: false,
      advanced: false,
      cellRenderer: ({
        rowData,
        currentUser,
        isAdmin,
        doAction,
        isReadOnly,
        onClick,
      }) => {
        const { id, creator, isPrivate } = rowData;
        const isUserOwner = _.isEqual(currentUser.id, creator);
        const isAppAdminReport = creator === 'discover-admin@sugarcrm.com';
        const canEditVisibility =
          !isReadOnly && (isUserOwner || (isAdmin && !isAppAdminReport));
        if (canEditVisibility) {
          return (
            <HorizontalCellInteractive style={{ cursor: 'default' }}>
              <SearchableDropdown
                id={id}
                defaultValue={{
                  value: isPrivate ? 'private' : 'public',
                  label: isPrivate
                    ? messages.table.private
                    : messages.table.public,
                }}
                headerSx={{
                  width: '100%',
                  '.clear-action': {
                    display: 'none',
                  },
                }}
                options={[
                  {
                    value: 'private',
                    label: messages.table.private,
                  },
                  {
                    value: 'public',
                    label: messages.table.public,
                  },
                ]}
                onSelect={options => {
                  const eventKey = head(options)?.value;
                  doAction(eventKey, rowData);
                }}
                disablePortal={false}
              />
            </HorizontalCellInteractive>
          );
        }
        return (
          <HorizontalCellInteractive
            onClick={() => onClick({ rowData })}
            style={{ paddingLeft: '13px' }}
          >
            {isPrivate ? messages.table.private : messages.table.public}
          </HorizontalCellInteractive>
        );
      },
      customSort: list => {
        return _.sortBy(list, [
          o => (o.isPrivate ? messages.table.private : messages.table.public),
        ]);
      },
    },
    {
      key: 'tags',
      label: 'tags.tags',
      width: 200,
      maxWidth: 300,
      sortable: true,
      flex: 0,
      hidden: false,
      advanced: false,
      cellRenderer: ({ rowData, onClick }) => {
        const { tags } = rowData;
        const tagLabel = _.isEmpty(tags) ? '-' : tags.join(', ');
        return (
          <HorizontalCellInteractive
            onClick={() => onClick({ rowData })}
            title={tagLabel}
          >
            {tagLabel}
          </HorizontalCellInteractive>
        );
      },
    },
    {
      key: 'updatedOn',
      label: 'table.updatedOnLabel',
      width: 180,
      maxWidth: 180,
      sortable: true,
      flex: 0,
      hidden: false,
      advanced: false,
      cellRenderer: ({ rowData, onClick }) => {
        const { updatedOn } = rowData;
        let content = '';
        if (updatedOn && updatedOn > 0) {
          const m = moment(updatedOn);
          const d = m.format().toUpperCase();
          content = d;
        } else {
          content = '---';
        }
        return (
          <HorizontalCellInteractive
            onClick={() => onClick({ rowData })}
            title={content}
          >
            {content}
          </HorizontalCellInteractive>
        );
      },
    },
    {
      key: 'datasetName',
      label: 'table.datasetNameLabel',
      width: 172,
      maxWidth: 172,
      sortable: true,
      flex: 0,
      hidden: false,
      advanced: false,
      cellRenderer: ({ rowData, onClick }) => {
        const {
          dataset: { name },
        } = rowData;
        return (
          <HorizontalCellInteractive
            onClick={() => onClick({ rowData })}
            title={name}
          >
            {name}
          </HorizontalCellInteractive>
        );
      },
    },
    {
      key: 'actions',
      label: '',
      width: 78,
      sortable: false,
      flex: 1,
      hidden: false,
      advanced: false,
      cellRenderer: ({ rowData, doAction, setActionsOpen, isReadOnly }) => {
        const { id, canUpdate, canDelete, discoveryType } = rowData;
        if (!canUpdate) {
          return <div />;
        }
        return (
          <HorizontalCellInteractive>
            {!isReadOnly && (
              <IconDropdown
                id={`actions-dropdown-${_.kebabCase(id)}`}
                disabled={!canUpdate && !canDelete}
                onOpen={() => {
                  setActionsOpen(true);
                }}
                onClose={() => {
                  setActionsOpen(false);
                }}
              >
                <MuiMenuItem
                  eventKey='name'
                  disabled={!canUpdate}
                  onClick={event => {
                    doAction('name', rowData, event);
                  }}
                >
                  {messages.table.renameMenuItem}
                </MuiMenuItem>
                <MuiMenuItem
                  eventKey='description'
                  disabled={!canUpdate || discoveryType === 'MONITOR'}
                  onClick={event => {
                    doAction('description', rowData, event);
                  }}
                >
                  {messages.table.editDescriptionMenuItem}
                </MuiMenuItem>
                <Divider />
                <MuiMenuItem
                  eventKey='delete'
                  disabled={!canDelete}
                  onClick={event => {
                    doAction('delete', rowData, event);
                  }}
                >
                  {messages.table.deleteReportMenuItem}
                </MuiMenuItem>
              </IconDropdown>
            )}
          </HorizontalCellInteractive>
        );
      },
    },
    {
      key: 'id',
      label: '',
      width: 304,
      maxWidth: 304,
      sortable: false,
      flex: 0,
      hidden: false,
      advanced: true,
      cellRenderer: ({ rowData }) => {
        const { id } = rowData;
        return (
          <HorizontalCell>
            <ClickToCopy text={id} />
          </HorizontalCell>
        );
      },
    },
  ],
};
