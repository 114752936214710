import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledToggle = styled.div(
  ({
    theme: { colors: { ContentBackground, ContentTextColor } = {} } = {},
  }) => css`
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: ${ContentBackground};
    color: ${ContentTextColor};
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  `,
);
