import { css } from '@emotion/react';

export const headerNavigationStyles = css`
  .header-navigation {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    position: absolute;
    width: 100%;
  }
  .header-navigation__title {
    font-size: 20px;
    text-align: center;
    text-transform: capitalize;
  }
  .header-navigation__link-button {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .header-navigation__left,
  .header-navigation__right {
    width: 33vw;
  }
  .header-navigation__left svg {
    margin-right: 5px;
  }
`;
