import { LineChartUtils } from './LineChartUtils';
import BarChartUtils from './BarChartUtils';
import _ from 'lodash';
import * as d3 from 'd3';
import Util from '../../common/Util';
import { Viz } from '../VizUtil';
import ChartUtils, { join as joinChartData } from './ChartUtils';

const BarLineChartUtils = {
  transformResultToLine: (
    queryResults,
    viz,
    defaultChartType = 'column_line',
    i18nPrefs = {},
  ) => {
    const vizChartType = _.get(viz, 'chartType');
    if (_.isNil(vizChartType)) {
      viz.chartType = defaultChartType;
    }
    return LineChartUtils.transformResult(
      queryResults,
      viz,
      'LINES',
      'XAXIS',
      'NONE',
      i18nPrefs,
    );
  },

  transformResultToArea: (queryResults, viz, i18nPrefs = {}) => {
    return BarLineChartUtils.transformAreaResult(
      queryResults,
      viz,
      'VALUES',
      'XAXIS',
      'NONE',
      viz.layout.LINES.length,
      i18nPrefs,
    );
  },

  transformResultToBar: (queryResults, viz, i18nPrefs = {}) => {
    return BarChartUtils.transformResult(
      queryResults,
      viz,
      'COLUMNS',
      i18nPrefs,
    );
  },

  transformAreaResult: (
    queryResults,
    viz,
    valueName = 'VALUES',
    xAxisName = 'XAXIS',
    linesName,
    dataOffset = 0,
    i18nPrefs = {},
  ) => {
    const data = { AXIS: [], LINES: [], layout: viz.layout };

    let axisList = [];

    const lines = [];
    const { layout } = viz;
    const dataFormatters = Viz.getDataFormatters(viz);
    const customDataFormatters = Viz.getDataCustomFormatters(viz);

    for (let idx = 0; idx < layout[valueName].length - 1; idx += 2) {
      const valueField = layout[valueName][idx];
      axisList = [];
      const axisLength = layout[xAxisName] ? layout[xAxisName].length : 0;

      let line = [];
      line.pathInfo = {
        lines: [],
        valueName: valueField.name,
      };
      lines.push(line);
      const xAxisPositions = _.range(0, axisLength);

      const formatter = dataFormatters[valueField.name];

      queryResults.executeQuery.results.forEach(row => {
        const linesArray = row.slice(axisLength, dataOffset + axisLength);
        const groupName = joinChartData(linesArray);

        if (!line) {
          line = lines[`${groupName}_${idx}`] = [];
          line.pathInfo = {
            lines: linesArray,
            valueName: valueField.name,
          };
        }

        let axises = ['']; // Default value of axis in the case where we have no XAxis (just dots)
        if (xAxisPositions.length > 0) {
          // We have an axis, get value
          axises = xAxisPositions.map(x => {
            const field = layout[xAxisName][x];
            if (!_.isNil(field)) {
              return {
                value: ChartUtils.formatValue(
                  dataFormatters,
                  customDataFormatters,
                  i18nPrefs,
                  field.name,
                  row[x],
                ),
                type: field.attributeType,
              };
            } else {
              return { value: row[x], type: null };
            }
          });
          // record XAxis value in array
          axisList[joinChartData(axises)] = axises;
        }
        // Push data into line array
        const yVal = row[axisLength + dataOffset + idx];
        const yVal2 = row[axisLength + dataOffset + idx + 1];
        const xVal = joinChartData(axises);
        line.push({
          x: xVal,
          y: yVal,
          y1: yVal2,
          axises,
          formatter,
        });
      });
    }
    data.LINES = lines;
    data.AXIS =
      Object.values(axisList).length > 0
        ? Object.values(axisList)
        : [[{ value: '', attributeType: 'String' }]];
    return data;
  },

  /**
   * This function takes two scales, manually constructing tickValues for the second to match
   * the first. In our usage here it makes a tick array for Lines to match the one constructed for Columns
   *
   * Background:
   * D3 ticks are generated with a special algorithm which takes the .ticks(n) into account but may produce a
   * different number.
   *
   * @param barYScale
   * @param lineYScale
   */
  generateLineTicks(barYScale, lineYScale, numTicks) {
    // Get actual ticks for the bar scale
    const barTicks = barYScale.ticks(numTicks);

    if (barTicks.length === 0) {
      return [];
    }

    // Create tickValues
    return _.range(0, barTicks.length).map(tickNo => {
      // Get matching line tick value
      const tickValue = lineYScale.invert(barYScale(barTicks[tickNo]));
      // need to support at least as many decimal places as we need to show percent with 2 digits to the right (so 4)
      const niceValue = _.round(tickValue, 4);
      return _.isInteger(tickValue) ? tickValue : niceValue;
    });
  },

  /**
   * Function takes two y domains and manually constructs y scales
   * to align the y axes at 0.
   *
   * Background:
   * D3 has no way of connecting two or more scales with different domains
   * and visually align them at a certain value.
   *
   * @param collapsedAxisRange if true collapse scale around domain of data to maximize visiblility of data
   * @param fitAllData if true combines the domains to ensure all data will be displayed
   */
  getZeroAlignedScales(
    barYDomain,
    lineYDomain,
    chartHeight,
    numTicks,
    collapsedAxisRange = false,
    fitAllData = false,
  ) {
    let bMin = d3.min(barYDomain);
    let bMax = d3.max(barYDomain);
    let lMin = d3.min(lineYDomain);
    let lMax = d3.max(lineYDomain);

    // Check for a special case where there is no variance in the bar chart range. Zero ignored
    if (bMax - bMin === 0) {
      [bMin, bMax] = handleNoVariance(bMin, bMax, lMin, lMax);
    }

    // Check for a special case where there is no variance in the line chart range. Zero ignored
    if (lMax - lMin === 0) {
      [lMin, lMax] = handleNoVariance(lMin, lMax, bMin, bMax);
    }

    if (fitAllData) {
      // Match ranges around largest and smallest values
      bMin = lMin = Math.min(bMin, lMin);
      bMax = lMax = Math.max(bMax, lMax);
    }

    const barRatio = this.getRatio(bMin, bMax);
    const lineRatio = this.getRatio(lMin, lMax);

    if (
      !collapsedAxisRange &&
      bMin >= 0 &&
      bMax >= 0 &&
      lMin >= 0 &&
      lMax >= 0
    ) {
      // Both domains are positive, set floor to 0
      bMin = 0;
      lMin = 0;
    } else if (
      !collapsedAxisRange &&
      bMin <= 0 &&
      bMax <= 0 &&
      lMin <= 0 &&
      lMax <= 0
    ) {
      // Both domains are negative, set ceiling to 0
      bMax = 0;
      lMax = 0;
    } else if (barRatio < lineRatio) {
      if (bMax <= 0) {
        // Bar chart is completely negative, adjust bar max
        if (lMin === 0 || lMax === 0) {
          bMax = -1 * bMin;
        } else {
          bMax = bMin * (lMin / lMax);
          bMax = bMax < 0 ? bMax * -1 : bMax;
        }
      }
      // Adjust the line min
      lMin = (bMin / bMax) * lMax;
    } else {
      if (lMax <= 0) {
        // Line chart is completely negative, adjust line max
        if (bMin === 0 || bMax === 0) {
          lMax = -1 * lMin;
        } else {
          lMax = lMin * (bMin / bMax);
          lMax = lMax < 0 ? lMax * -1 : lMax;
        }
      }
      // Adjust the bar min
      bMin = (lMin / lMax) * bMax;
    }

    return {
      barYScale: d3
        .scaleLinear()
        .range([chartHeight, 0])
        .domain(Util.expandRange([bMin, bMax], 0.05)),
      lineYScale: d3
        .scaleLinear()
        .range([chartHeight, 0])
        .domain(Util.expandRange([lMin, lMax], 0.05)),
    };
  },

  expandRange(min) {
    if (min !== 0) {
      // Single axis value not zero, pad such that 3 becomes [0,3,6], -3 becomes [-6,-3,0].
      // Results in values compressed in the middle.
      return min < 0 ? [min * 2, 0] : [0, min * 2];
    } else {
      // Special case when the only axis value is zero, again pushes the values into the middle.
      return [-1, 1];
    }
  },

  getRatio(min, max) {
    const ratio = max / (max - min);
    return _.isNaN(ratio) ? 0 : ratio;
  },
};

/**
 * If there's no variance but the value is non-zero, expand it out. If the value is zero expand to 1 in the direction
 * of the other scale's data. If the other scale is all positive it will expand to [0,1], negative [-1,0]. If the
 * other scale is both positive and negative [-1,1]
 *
 * @param priMin invariant scale min
 * @param priMax invariant scale max
 * @param secondMin other scale min
 * @param secondMax other scale max
 * @returns {*[]} array of [min,max]
 */
const handleNoVariance = function(priMin, priMax, secondMin, secondMax) {
  if (priMax !== 0) {
    const newRange = this.expandRange(priMin, priMax);
    priMin = d3.min(newRange);
    priMax = d3.max(newRange);
  } else {
    // both zero. Expand in the direction of the bar data, staying positive or negative, or expanding both directions
    if (secondMin >= 0 && secondMax >= 0) {
      priMax = 1;
    } else if (secondMin < 0 && secondMax < 0) {
      priMin = -1;
    } else {
      priMax = 1;
      priMin = -1;
    }
  }
  return [priMin, priMax];
}.bind(BarLineChartUtils);

export const __private__ = { handleNoVariance };

export default BarLineChartUtils;
