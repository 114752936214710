import { css } from '@emotion/react';

export const anomalyActivityStyles = css`
  .thumbnail-content
    .anomaly-activity
    .insight-item
    .percent-changed-card
    > div.card-body {
    flex-direction: column;
  }

  .percent-changed-card .area-chart .viz-chart-wrapper {
    display: block;
    height: auto;
  }

  .thumbnail-content
    .anomaly-activity
    .insight-item
    .percent-changed-card
    .area-chart {
    min-height: 95px;
  }
  .thumbnail-content .anomaly-activity .weighted-forecast-body-table {
    margin: 16px 0;
  }
`;
