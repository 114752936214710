import { Fragment } from 'react';
import _ from 'lodash';
import { TemplateMap } from './templating.interface';
import shortid from 'shortid';

export function interpolateArray<Tarr = any, Titem = Tarr | any>(
  arr: Tarr[],
  item: Titem,
): (Tarr | Titem)[] {
  return _(arr)
    .chain()
    .map((val, i) => i)
    .join('__item__')
    .split('__')
    .map(idx => (idx === 'item' ? item : arr[Number(idx)]))
    .value();
}

export function templateString<T = any>(
  str: string,
  template: string | RegExp,
  replacement: T,
): (T | string)[] {
  return interpolateArray(_.split(str, template), replacement);
}

export function handleTemplates(
  str: string | number,
  templateMap: TemplateMap,
): JSX.Element[] {
  if (_.isEmpty(str)) {
    if (_.isNumber(str)) {
      str = `${str}`;
    } else {
      return null;
    }
  }
  return _(templateMap)
    .chain()
    .reduce(
      (elems, replacement, template) => {
        if (!_.isObject(replacement)) {
          replacement = (
            <Fragment key={shortid.generate()}>{replacement}</Fragment>
          ) as any;
        }
        return _(elems)
          .flatMap(e =>
            _.isString(e) ? templateString(e, template, replacement) : e,
          )
          .reject(_.isEmpty)
          .value();
      },
      [str],
    )
    .map(e => (_.isNumber(e) ? _.toString(e) : e))
    .map(item => <Fragment key={shortid.generate()}>{item}</Fragment>)
    .value();
}
