import { withTheme } from '@emotion/react';

import { Component } from 'react';
import ClipboardButtonJS from 'react-clipboard.js';

class ClipboardButton extends Component {
  getInitialState() {
    return { img: this.props.img };
  }
  enterButton() {
    this.setState({ img: this.props.hoverImg });
  }
  leaveButton() {
    this.setState({ img: this.props.img });
  }
  getStyle() {
    const style = {
      backgroundColor: this.props.theme?.colors?.Clear,
      padding: '0',
      lineHeight: '0px',
      verticalAlign: 'top',
    };
    if (this.props.hide) {
      style.display = 'none';
    }
    return style;
  }
  render() {
    return (
      <ClipboardButtonJS
        style={this.getStyle()}
        bsClass='toolbar-btn btn'
        button-onMouseEnter={this.enterButton}
        button-onMouseLeave={this.leaveButton}
        data-clipboard-text={this.props.text}
      >
        <img src={this.state.img} />
      </ClipboardButtonJS>
    );
  }
}

export default withTheme(ClipboardButton);
