import React from 'react';
import PromptDialog from '../../common/widgets/dialogs/PromptDialog';
import { Viz as VizUtils } from '../../discovery/VizUtil';
import {
  branch,
  compose,
  renderComponent,
  renderNothing,
  withProps,
} from 'react-recompose';
import { HelpBlock } from '../../views/VizSaveDialog/ui';
import { messages } from '../../i18n';
import _ from 'lodash';
import { ICalc } from '../interfaces';

export const ConfirmDeleteCalcPrompt = props => {
  const boldName = (
    <strong>
      '{props.calcFieldToDelete ? props.calcFieldToDelete.name : ''}'
    </strong>
  );
  const deleteFieldDetail = (
    <HelpBlock className='text-center'>
      <p>
        {messages.formatString(
          messages.layoutPanel.confirmDeleteCalcField,
          boldName,
        )}
      </p>
      <br />
      <p>{messages.layoutPanel.warningOpCannotBeUndone}</p>
      <br />
    </HelpBlock>
  );
  return (
    <PromptDialog
      show={props.show}
      detail={deleteFieldDetail}
      doYes={() => {
        props.onConfirm();
      }}
      doNo={() => {
        props.onCancel();
      }}
    />
  );
};

export const WarnDeleteDependentCalcPrompt = props => {
  const deps = props.dependentFields;
  const depsList = deps.map(d => `'${d.name}'`).join(', ');
  const boldName = (
    <strong>
      '{props.calcFieldToDelete ? props.calcFieldToDelete.name : ''}'
    </strong>
  );
  const deleteFieldDetail = (
    <HelpBlock className='text-center'>
      <p>
        {messages.formatString(
          messages.layoutPanel.warnFieldHasDependentCalcs,
          {
            name: boldName,
            dependentList: <strong>{depsList}</strong>,
          },
        )}
      </p>
      <br />
      <p>
        <em>{messages.layoutPanel.cannotDeleteFieldReferencedByOthers}</em>
      </p>
      <br />
    </HelpBlock>
  );
  return (
    <PromptDialog
      show={props.show}
      detail={deleteFieldDetail}
      asInfo
      doOk={() => {
        props.onCancel();
      }}
    />
  );
};

interface IDeleteCalcPromptProps {
  show?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  calcFieldToDelete?: ICalc;
  calcFields?: ICalc[];
  dependentFields?: ICalc[];
}

export const DeleteCalcPrompt = compose<any, IDeleteCalcPromptProps>(
  branch((props: IDeleteCalcPromptProps) => {
    return !props.show;
  }, renderNothing),
  withProps((props: IDeleteCalcPromptProps) => {
    const deps = VizUtils.findDependentCalcs(
      props.calcFieldToDelete,
      props.calcFields,
    );
    return {
      dependentFields: deps,
    };
  }),
  branch(
    (props: IDeleteCalcPromptProps) => {
      return _.isEmpty(props.dependentFields);
    },
    renderComponent((props: IDeleteCalcPromptProps) => (
      <ConfirmDeleteCalcPrompt {...props} />
    )),
  ),
)(WarnDeleteDependentCalcPrompt);
