import styled from '@emotion/styled';

export const FilterToolBarIconWrapper = styled.div`
  > div {
    display: flex;
    align-items: center;
    margin: 0;
    max-width: 32px;
    width: 32px;
    height: 32px;
  }
  .sicon {
    width: 32px;
    height: 32px;
    margin-left: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.sicon-calendar {
      margin-left: 0px;
    }
  }
`;
