import EqualsFilter from '../../../discovery/filter/exports/EqualsFilter';
import _ from 'lodash';

const AnnotationColumnNameRegex = /\[[^/[\]]+]/g;

export function findAttributeByName(dataset, name) {
  return _.find(dataset.attributes, _.matches({ name }));
}

export function getAnnotationValue(attr, key) {
  return _(attr)
    .chain()
    .get('annotations')
    .find(_.matches({ key }))
    .get('value')
    .value();
}

export function getColumnNamesFromAnnotationString(anno) {
  if (!_.isString(anno)) {
    anno = '';
  }
  return _.map(anno.match(AnnotationColumnNameRegex), s => _.trim(s, '[]'));
}

export function findAttributeAnnotation(dataset, attrName, annoName) {
  return getAnnotationValue(findAttributeByName(dataset, attrName), annoName);
}

export function getInsightContext(eventData, dataset) {
  const { measure } = eventData;
  return getColumnNamesFromAnnotationString(
    findAttributeAnnotation(dataset, measure, 'INSIGHT_CONTEXT'),
  );
}

export function getInsightContextFilter(eventData, dataset) {
  const ctx = getInsightContext(eventData, dataset);
  return _.reduce(
    ctx,
    (acc, ctxFieldName) => {
      const ctxField = findAttributeByName(dataset, ctxFieldName);
      if (ctxField) {
        try {
          const filter = EqualsFilter(ctxField, '1');
          return { ...acc, [ctx]: filter };
        } catch (err) {
          return acc;
        }
      }
      return acc;
    },
    {},
  );
}
