import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import _ from 'lodash';
import BaseInsightComponent from '../Common';
import { DATA_TYPE_FORMAT, NULL_DISPLAY } from '../../../common/Constants';
import { getFormatterFromDataset } from '../BiggestMovers/component';
import Discover from '../../../common/redux/actions/DiscoverActions';
import ReportParamFunc from './report/func';
import { getIsDarkModeEnabled } from '../../../common/redux/selectors/main-selector-hooks';

const palette = {
  green: ['#21B91E', '#4DC74B', '#7AD578', '#A6E3A5', '#D3F1D2', '#E8F8E8'],
  red: ['#CA302C', '#D36151', '#DE897D', '#E9B0A8', '#F4D8D4', '#F7E2DF'],
};

const Top3Component = ({
  monitorEvent,
  dataset,
  viz,
  newVisualization,
  i18nPrefs,
}) => {
  // Format: "{"measure":"New_MRR","segment":"Customer_Type_2","top":{"Enterprise": 1234.5,"Global": 1234}}"
  const { eventData, excluded } = monitorEvent;

  // find the highest value, bars are sized based on this
  const max = _(eventData.top)
    .map(Math.abs)
    .max();

  const paletteGroup =
    eventData.increasesAreGood || _.isNil(eventData.increasesAreGood)
      ? 'green'
      : 'red';

  const getTop = () =>
    Object.entries(eventData.top)
      .sort((a, b) => a[1] < b[1])
      .map(([segment, segmentValue], idx) => {
        let { formatter, customFormatter } = getFormatterFromDataset(
          dataset,
          eventData.measure,
        );

        if (!formatter) {
          formatter = DATA_TYPE_FORMAT.getDefaultFormatterForType('Number');
        }
        // use the format from the measure field for the tooltips
        const formattedSegmentValue = formatter
          ? formatter.format(segmentValue, i18nPrefs, customFormatter)
          : segmentValue;

        const segmentFormatted = segment === 'null' ? NULL_DISPLAY : segment;
        const color = palette[paletteGroup][idx];
        return (
          <div
            key={`top3-insight-${_.kebabCase(segmentFormatted)}`}
            className='dynamic-insight-row'
          >
            <div className='segment' title={segmentFormatted}>
              {segmentFormatted}
            </div>
            <div className='segment-bar-container'>
              <div
                className='bar'
                title={formattedSegmentValue}
                style={{
                  backgroundColor: color,
                  width: `${Math.abs(segmentValue / max) * 100}%`,
                }}
              >
                &nbsp;
              </div>
            </div>
            <div className='segment-value' title={formattedSegmentValue}>
              {formatter.formatSmall(segmentValue, i18nPrefs, customFormatter)}
            </div>
          </div>
        );
      });

  // past month or past N months
  const periodName = _.flow(
    _.toLower,
    _.words,
    _.partialRight(_.join, ' '),
  )(eventData.periodType);
  let period = '';
  if (eventData.periodMonths > -1) {
    period = ` past ${
      eventData.periodMonths > 1
        ? `${eventData.periodMonths} ${periodName}`
        : _.trimEnd(periodName, 's')
    }`;
  }

  const partition = eventData.partitionName;
  const { partitionValue } = eventData;

  const segmentFormatted =
    eventData.segment === 'null' ? NULL_DISPLAY : eventData.segment;
  let title = `Top ${segmentFormatted} for ${eventData.measure}${period}`;
  if (partition) {
    title += ` (${partition}=${partitionValue})`;
  }

  const onClick = () => {
    const params = ReportParamFunc({ monitorEvent, dataset, viz });
    newVisualization(params);
  };

  return (
    <BaseInsightComponent title={title} excluded={excluded} onClick={onClick}>
      {getTop()}
    </BaseInsightComponent>
  );
};

Top3Component.supports = function(payload) {
  return (
    payload.monitorEvent.insightType &&
    payload.monitorEvent.insightType === 'TOP3'
  );
};

const mapStateToProps = state => {
  const {
    account: {
      currentUser: { i18nPrefs = {} },
    },
  } = state;
  const isDarkModeEnabled = getIsDarkModeEnabled(state);
  return { i18nPrefs, isDarkModeEnabled };
};

const mapDispatchToProps = dispatch => {
  return {
    newVisualization: spec => {
      dispatch(Discover.newVisualization(spec));
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Top3Component,
);
