import _ from 'lodash';
import { composeResetReducer } from 'redux-reset-store';
import Const from '../actions/ActionConst';
import { URLs } from '../../Urls';
import { getDashletMode } from '../../../auth';
import { getConfig } from '../../global-discover-config';
const { TENANT_IDM_ID } = getConfig();

function getStateFromRoute() {
  let {
    strictDiagnostics,
    tenantIdmId,
    hasSaveError,
    ...queryParams
  } = URLs.getQueryParams();
  tenantIdmId = tenantIdmId || TENANT_IDM_ID;
  hasSaveError = hasSaveError === 'true';
  const dashletMode = getDashletMode();
  const isDashletLibraryMode = dashletMode === 'Library';
  const isDashletReportMode = dashletMode === 'Report' && !isDashletLibraryMode;
  strictDiagnostics = strictDiagnostics === 'true' && isDashletReportMode;
  return {
    isDashletLibraryMode,
    isDashletReportMode,
    isDashletMode: !!dashletMode,
    hasSaveError,
    strictDiagnostics,
    ...queryParams,
  };
}

const initialState = {
  activeVisualizationId: null, //@NOTE rename dashletDiscoveryId because of DSC-3794
  discoveries: [],
  ...getStateFromRoute(),
};

// Splitting the Reducer to handle non-global state reduction
// https://redux.js.org/basics/reducers/#splitting-reducers
function libraryReducer(libraryState, action) {
  switch (action.type) {
    case Const.Dashlet.SET_AVAILABLE_DASHLET_DISCOVERIES:
      if (_.isNil(action.discoveries)) {
        return [];
      }
      return [...action.discoveries];
  }
  return libraryState;
}

export default composeResetReducer((dashletState, action) => {
  try {
    switch (action.type) {
      case '@@INIT':
        return {
          ...dashletState,
          ...getStateFromRoute(),
        };
      case Const.Main.UPDATE_FROM_ROUTE:
        return { ...dashletState, ...getStateFromRoute() };
      case Const.Dashlet.SET_MOCK_FINALIZE_FAIL:
        return {
          ...dashletState,
          mockFinalizeFail: action.mockFinalizeFail,
        };
      case Const.Dashlet.SAVE_DASHLET:
        return {
          ...dashletState,
          isDashletSaving: true,
          dashletError: null,
        };
      case Const.Dashlet.DASHLET_SAVE_COMPLETE:
        return {
          ...dashletState,
          isDashletSaving: false,
          dashletError: action.dashletError,
        };
      case Const.Dashlet.CLEAR_DASHLET_ERROR:
        return {
          ...dashletState,
          dashletError: null,
        };
      case Const.Dashlet.OPEN_DASHLET_VIZ:
        return {
          ...dashletState,
          datasetId: action.dashletDatasetId,
          activeVisualizationId: action.dashletDiscoveryId, //@NOTE rename dashletDiscoveryId because of DSC-3794
        };
      case Const.Main.SET_URL_LOCATION:
        return {
          ...dashletState,
          ...getStateFromRoute(),
        };
      case Const.Dashlet.CLOSE_DASHLET_VIZ:
        return {
          ...dashletState,
          activeVisualizationId: null,
        };
      case Const.Dashlet.SET_AVAILABLE_DASHLET_DISCOVERIES:
        return {
          ...dashletState,
          discoveries: libraryReducer(dashletState?.discoveries, action),
        };
    }
  } catch (e) {
    console.warn(
      `Failed to execute ${action.type}. Returning current state.`,
      e,
    );
  }
  return dashletState || {};
}, initialState);
