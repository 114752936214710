import { css } from '@emotion/react';

export const mobileMenuButtonStyles = ({
  theme: {
    colors: { PanelNavBackground },
  },
}) => css`
  .mobile-menu-button {
    cursor: pointer;
    height: 56px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    border-top: 1px solid ${PanelNavBackground};
    user-select: none;
  }
  .mobile-menu-button__content {
    width: 90%;
  }
  .mobile-menu-button__chevron {
    transform: rotateZ(180deg);
    margin-right: 20px;
  }
`;
