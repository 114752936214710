import styled from '@emotion/styled';
import { ErrorIcon as ErrorIconBase } from '../../../icons';

export const ErrorFallbackBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
  flex: 1;
  height: 100%;
  font-size: 1.125rem;
`;

export const ErrorIcon = styled(ErrorIconBase)`
  margin-bottom: 0.7rem;
`;

export const Info = styled.div`
  font-size: 0.875em;
`;
