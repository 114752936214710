import _ from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';

export interface IScrollContext {
  leftPct: number;
  topPct: number;
  offscreenWidth: number;
  offscreenHeight: number;
}

export const ScrollContext = createContext<IScrollContext>({
  leftPct: 0,
  topPct: 0,
  offscreenWidth: 0,
  offscreenHeight: 0,
});

export const ScrollableContainer = ({ children }) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const {
    leftPct: pctLeft = 0,
    topPct: pctTop = 0,
    offscreenWidth = 0,
    offscreenHeight = 0,
  } = useContext(ScrollContext);
  useEffect(() => {
    let left = Math.min(
      0,
      Math.max(-(offscreenWidth * pctLeft), -offscreenWidth),
    );
    let top = Math.min(
      0,
      Math.max(-(offscreenHeight * pctTop), -offscreenHeight),
    );
    left = _.isFinite(left) ? left : 0;
    top = _.isFinite(top) ? top : 0;
    const scrollLeft = _.isFinite(left) ? left : 0;
    const scrollTop = _.isFinite(top) ? top : 0;
    setScrollLeft(scrollLeft);
    setScrollTop(scrollTop);
  }, [offscreenHeight, offscreenWidth, pctLeft, pctTop]);

  let translate;
  if (scrollLeft != 0 || scrollTop != 0) {
    translate = `translate(${scrollLeft}, ${scrollTop})`;
  }
  return (
    <g className='scrollWrapper' transform={translate}>
      {children}
    </g>
  );
};
