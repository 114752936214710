import { useEffect, useState } from 'react';
import { ChartType, ILayout } from './interfaces';
import _ from 'lodash';

export const useMultiSelectDrillLinkDisable = (
  chartType: ChartType,
  layout: ILayout,
) => {
  const [
    multiSelectDrillLinkDisabled,
    setMultiSelectDrillLinkDisabled,
  ] = useState(false);

  useEffect(() => {
    if (isLineChartWithMultipleFields(chartType, layout)) {
      setMultiSelectDrillLinkDisabled(true);
    }
  }, [
    multiSelectDrillLinkDisabled,
    setMultiSelectDrillLinkDisabled,
    chartType,
    layout,
  ]);

  return {
    multiSelectDrillLinkDisabled,
  };
};

export const isLineChartWithMultipleFields = (chartType, layout) => {
  return chartType === 'line' && _.get(layout, 'LINES', [])?.length > 1;
};
