import React from 'react';
import styled from '@emotion/styled';
import { centeredPanel } from '../../common/emotion';
import { ErrorBoundary } from 'react-error-boundary';

export const CenteredPanel = styled.div`
  ${centeredPanel()};
  flex-direction: column;
  font-size: 13px;
  font-weight: 500;
`;

export const CenteredPanelTitle = styled.p`
  font-weight: 700;
  margin: 0;
`;

export const ChartErrorBoundary = styled(ErrorBoundary)`
  font-size: 0.875rem;

  > div:last-child {
    font-size: 0.7rem;
  }
`;
