import { useCallback } from 'react';
import { SecondaryButton } from '../../../ui';
import { messages } from '../../../i18n';
import { useDispatch } from 'react-redux';
import Discover from '../../../common/redux/actions/DiscoverActions';

export const AddMonitorButton = ({ advancedMode }) => {
  const dispatch = useDispatch();
  const showAddMonitorDialog = useCallback(() => {
    dispatch(Discover.setShowAddMonitorDialog(true));
  }, [dispatch]);
  const showAddCompMonitor = useCallback(() => {
    dispatch(Discover.setShowCompMonitorDialog(true));
  }, [dispatch]);
  if (advancedMode) {
    return (
      <>
        <SecondaryButton className='add-button' onClick={showAddMonitorDialog}>
          <div className='pull-right' style={{ marginLeft: '8px' }}>
            {messages.formatting.newAnomalyDetectionMonitor}
          </div>
        </SecondaryButton>
        <SecondaryButton className='add-button' onClick={showAddCompMonitor}>
          <div className='pull-right' style={{ marginLeft: '8px' }}>
            {messages.formatting.newComparisonPeriodMonitor}
          </div>
        </SecondaryButton>
      </>
    );
  } else {
    return null;
  }
};
