import styled from '@emotion/styled';

export const ExportExcelMessage = styled.div`
  text-align: center;
  margin-top: 4rem;

  p {
    margin: 0.25rem 0 !important;
    color: unset !important;

    &.bold {
      font-weight: 700 !important;
    }
  }
  button {
    margin-top: 1.5rem;
  }
`;
