import { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { compose, branch, renderNothing } from 'react-recompose';
import { createSelector } from 'reselect';
import moment from '../../../common/Moment';
import _ from 'lodash';
import Discover from '../../../common/redux/actions/DiscoverActions';
import { VIZ_SELECTORS } from '../../../common/redux/selectors/viz-selectors';
import { Query } from '@apollo/client/react/components';
import { client } from '../../../common/ApolloClient';
import DiscoverQueries from '../../../common/graphql/DiscoverQueries';
import { Tooltip } from '../../../components/ui/tooltip';
import { messages } from '../../../i18n';
import {
  FilterDialogTypes,
  IExpressionSegment,
  IFilter,
} from '../../../datasets';
import { IViz } from '../../interfaces';

import {
  StackedFilterArea,
  StackedFilterAreaTitle,
  StackedFilterContainer,
  StackedFilter,
  StackedFilterIcon,
  StackedFilterText,
  StackedFilterDelete,
  LoaderWrapper,
} from './active-filter-panel.styles';
import {
  ACCOUNT_SELECTORS,
  getIsReadOnlyUser,
} from '../../../common/redux/selectors/AccountSelectors';
import { withDiscoverOption } from '../../discovery-context/discovery.context';
import { formatString, getOperatorObj } from '../exports/FilterFormatter';
import { InfoIcon } from '../../../icons';
import { SkeletonListLoader } from '../../../common/loaders/skeleton-list-loader';

interface IFilterToolTipPropTypes {
  placement: 'top' | 'bottom' | 'left' | 'right';
  defaultTitle?: string;
  filter: IFilter;
  id: string;
  disableEdit: boolean;
  dateTimeFormats: any;
  useFiscalCalendar?: boolean;
  datasetId?: string;
  children?: any;
  isFilterMissing?: boolean;
}

const FilterToolTip = ({
  placement,
  filter,
  disableEdit,
  dateTimeFormats,
  useFiscalCalendar,
  children,
  datasetId,
  defaultTitle = '',
  isFilterMissing = false,
}: IFilterToolTipPropTypes) => {
  const editLinkContent = disableEdit ? (
    ''
  ) : (
    <div key='edit-link-content'>
      <hr />
      <div className={'edit-link'}>{messages.clickToEdit}</div>
    </div>
  );
  const formatTooltip = data => {
    const { dateFormat } = dateTimeFormats ?? {};
    return [
      <div className={'date-range'} key='date-range'>
        {data?.dateRange
          ? `${moment(data.dateRange.start).format(dateFormat)} - ${moment(
              data.dateRange.end,
            )
              .subtract(1, 'seconds')
              .format(dateFormat)}`
          : messages.activeFilterPanel.calculating}
      </div>,
      editLinkContent,
    ];
  };
  if (filter.subType === 'SET_CONDITION') {
    return (
      <Tooltip
        placement={placement}
        id='verbose-filter-tooltip'
        title={
          <Fragment>
            <div className={'verbose-filter-tooltip-text'}>
              {formatString(dateTimeFormats)(filter, useFiscalCalendar, true)}
            </div>
            {editLinkContent}
          </Fragment>
        }
        arrow
      >
        {children}
      </Tooltip>
    );
  } else if (filter.type !== 'DATE') {
    return (
      <Tooltip
        placement={placement}
        title={
          isFilterMissing ? (
            <div className={'field-tooltip-missing-field'}>
              <InfoIcon />
              {messages.filters.filterFieldMissingTooltip}
            </div>
          ) : disableEdit ? (
            defaultTitle
          ) : (
            messages.clickToEdit
          )
        }
        arrow
      >
        {children}
      </Tooltip>
    );
  }

  const request = {
    filter: {
      attributeName: filter.field,
      operator: getOperatorObj(filter, filter.expression.left.operator)
        .queryOperator,
      operands: (filter.expression.left as IExpressionSegment)?.operands,
    },
    useFiscalCalendar,
    datasetId,
  };
  const queryVariables = { request };

  // useDateRange is available
  return (
    <Query
      query={DiscoverQueries.ComputeDateRangeQuery}
      variables={queryVariables}
      client={client}
    >
      {({ data }) => {
        return (
          <Tooltip
            arrow
            placement={placement}
            title={
              <Fragment>
                <div className={'date-filter-tooltip'}>
                  {formatTooltip(data)}
                </div>
              </Fragment>
            }
          >
            {children}
          </Tooltip>
        );
      }}
    </Query>
  );
};

interface IPropTypes {
  loading: any;
  filters: any;
  isMobile: boolean;
  isForcedMobile: boolean;
  panelStyle: any;
  isDashletMode: boolean;
  isReadOnly: boolean;
  showFieldFilterDialog: any;
  removeFieldFilter: any;
  useFiscalCalendar: boolean;
  viz: IViz;
  datasetId: string;
  dateTimeFormats: any;
}

interface IState {
  expanded: boolean;
}

export class UnconnectedActiveFilterPanel extends Component<
  IPropTypes,
  IState
> {
  state: IState;

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  formatFilter(f) {
    const { useFiscalCalendar } = this.props;
    const startOfLabel =
      f.dialogType === FilterDialogTypes.AGGREGATE
        ? `${f.field} ${f.aggregationContext.field} ${f.aggregationContext.aggregation}`
        : f.field;
    return `${startOfLabel}: ${formatString(this.props.dateTimeFormats)(
      f,
      useFiscalCalendar,
    )}`;
  }

  renderFiltersStacked() {
    const {
      datasetId,
      filters,
      isDashletMode,
      isReadOnly,
      useFiscalCalendar,
      viz,
    } = this.props;

    const disableEditLink = isDashletMode || isReadOnly;

    return (
      <StackedFilterArea className={`display-control`}>
        <StackedFilterAreaTitle className='title'>
          {filters.length > 0
            ? messages.activeFilterPanel.filteredBy
            : messages.activeFilterPanel.noFilters}
        </StackedFilterAreaTitle>
        <StackedFilterContainer>
          {filters.map(filter => {
            const isAggregate =
              filter.dialogType === FilterDialogTypes.AGGREGATE;
            const text = isAggregate
              ? `[${messages.filters.aggregation}] ${this.formatFilter(filter)}`
              : this.formatFilter(filter);
            const isFilterMissing = _.some(viz.missingFilters, obj =>
              _.isEqual(filter.field, obj.field),
            );
            return (
              <StackedFilter
                key={`stacked-filter-${text}`}
                className={`filter-item`}
              >
                <StackedFilterIcon />
                <StackedFilterText>
                  <FilterToolTip
                    placement='top'
                    dateTimeFormats={this.props.dateTimeFormats}
                    filter={filter}
                    id={'active-filter-panel-click-to-edit'}
                    disableEdit={disableEditLink}
                    useFiscalCalendar={useFiscalCalendar}
                    datasetId={datasetId}
                    defaultTitle={text}
                    isFilterMissing={isFilterMissing}
                  >
                    <span
                      className={isFilterMissing ? 'missing-error' : ''}
                      onClick={() => {
                        if (!disableEditLink) {
                          this.props.showFieldFilterDialog(
                            filter.field,
                            isAggregate,
                          );
                        }
                      }}
                    >
                      {text}
                    </span>
                  </FilterToolTip>
                </StackedFilterText>
                {!disableEditLink && (
                  <Tooltip
                    placement='top'
                    title={messages.activeFilterPanel.remove}
                    arrow
                  >
                    <StackedFilterDelete
                      onClick={() => this.props.removeFieldFilter(filter)}
                    />
                  </Tooltip>
                )}
              </StackedFilter>
            );
          })}
        </StackedFilterContainer>
      </StackedFilterArea>
    );
  }

  renderMobileFilters() {
    let { filters, isForcedMobile, panelStyle = {} } = this.props;
    panelStyle = { ...panelStyle };
    const titleStyle = {};
    if (isForcedMobile) {
      Object.assign(titleStyle, { display: 'flex', alignItems: 'center' });
      panelStyle.padding = 10;
    }
    return (
      <div className='active-filters' style={panelStyle}>
        <div className='title' style={titleStyle}>
          <span style={{ whiteSpace: 'nowrap' }}>
            {filters.length > 0
              ? messages.activeFilterPanel.filteredBy
              : messages.activeFilterPanel.noFilters}
          </span>
        </div>
        {filters.map((filter, index) => {
          return (
            <div className='filter-item' key={`filter-item-${index}`}>
              <span>{this.formatFilter(filter)}</span>
              {index < filters.length - 1 && ', '}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { loading, isMobile, isDashletMode } = this.props;

    if (loading) {
      if (isMobile) {
        return null;
      } else if (!isMobile) {
        // could be dashlet mode
        return (
          <LoaderWrapper>
            <SkeletonListLoader />
          </LoaderWrapper>
        );
      }
    }

    if (isMobile || isDashletMode) {
      return this.renderMobileFilters();
    }

    return <div>{this.renderFiltersStacked()}</div>;
  }
}

const getActiveFilters: (state, props) => any = createSelector(
  [VIZ_SELECTORS.getActiveVizFilters],
  filters => _.values(filters),
);

const mapStateToProps = (state, ownProps) => {
  const discoveryId = VIZ_SELECTORS.getDiscoveryId(state, ownProps);
  const discovery = state.discover.openDiscoveries[discoveryId].present;
  const { isDashletMode } = state.dashlet;
  const useFiscalCalendar = VIZ_SELECTORS.isFiscalCalendarActive(
    state,
    ownProps,
  );
  return {
    isMobile: state.main.isMobile,
    dateTimeFormats: ACCOUNT_SELECTORS.getDatetimeFormats(state),
    isForcedMobile: state.main.isForcedMobile,
    show: ownProps.showFiltersPanel,
    filters: getActiveFilters(state, ownProps),
    viz: discovery?.viz,
    vizId: discovery?.viz?.id,
    loading: discovery.vizLoading,
    isDashletMode: !!isDashletMode,
    isReadOnly: !!getIsReadOnlyUser(state),
    useFiscalCalendar,
    datasetId: discovery?.dataset?.id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    removeFieldFilter: filter => {
      dispatch(Discover.removeFieldFilter(ownProps.discoveryId, filter));
    },
    showFieldFilterDialog: (field, isAggregate) => {
      dispatch(
        isAggregate
          ? Discover.showFieldFilterAggregateDialog(field)
          : Discover.showFieldFilterDialog(field),
      );
    },
  };
};

export const ActiveFilterPanel = compose(
  withDiscoverOption({ option: 'showFiltersPanel' }),
  connect(mapStateToProps, mapDispatchToProps),
  branch(props => !(props as any).show, renderNothing),
)(UnconnectedActiveFilterPanel as any) as any;
