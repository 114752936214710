import {
  FormRoot,
  ModalLabel,
  StyledModal,
} from '../VizSaveDialog/viz-save-dialog.styles';
import {
  PrimaryButton,
  SecondaryButton,
  SmallModalButtonContainer,
} from '../../ui';
import { messages } from '../../i18n';
import { ISaveVersionDialogProps } from './save-version-dialog.interfaces';
import { FormInput } from '../../components/ui/form-input';
import { useCallback, useState } from 'react';
import { isEmpty, trim } from 'lodash';
import { IViz } from '../../discovery';
import { useOpenDiscoveryPresentStateSelector } from '../../common/redux/selectors/viz-selector.hook';

export const SaveVersionDialog = ({
  reportName,
  vizId,
  onSave,
  onCancel,
}: ISaveVersionDialogProps) => {
  const [commitMessage, setCommitMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const present = useOpenDiscoveryPresentStateSelector({ discoveryId: vizId });
  const { viz } = present;

  const handleSave = useCallback(() => {
    setIsSaving(true);
    const newViz: IViz = {
      ...viz,
      name: present.name ?? viz.name,
      tags: present.tags ?? viz.tags,
      commitMessage: trim(commitMessage),
      newRevision: true,
    };
    onSave({ optionalViz: newViz });
  }, [commitMessage, onSave, present, viz]);

  return (
    <StyledModal
      contentClassName={'viz-save-dialog'}
      title={messages.formatString(
        messages.vizSaveDialog.saveVersionTitle,
        reportName,
      )}
      buttonGroup={
        <SmallModalButtonContainer>
          <SecondaryButton onClick={onCancel}>
            {messages.cancel}
          </SecondaryButton>
          <PrimaryButton
            onClick={handleSave}
            disabled={isEmpty(trim(commitMessage))}
          >
            {isSaving
              ? messages.confirmLogout.saving
              : messages.confirmLogout.save}
          </PrimaryButton>
        </SmallModalButtonContainer>
      }
    >
      <FormRoot>
        <ModalLabel>{messages.vizSaveDialog.saveVersionLabel}</ModalLabel>
        <FormInput
          multiline
          className='version-input'
          name='version'
          placeholder={messages.vizSaveDialog.saveVersionPlaceholder}
          value={commitMessage}
          inputProps={{ maxLength: 256 }}
          inputRef={input => input?.focus()}
          onChange={e => setCommitMessage(e.target.value)}
        />
      </FormRoot>
    </StyledModal>
  );
};
