import { withTheme } from '@emotion/react';

const TableUtils = theme => ({
  /** TABLE RELATED UTILITY FUNCTIONS **/

  getTableInitialState(props) {
    this.onRowSelect = TableUtils.onRowSelect.bind(this);
    this.selectRowProp = TableUtils.selectRowProp.bind(this);
    this.getSelectedKeys = TableUtils.getSelectedKeys.bind(this);
    this.handleSelection = TableUtils.handleSelection.bind(this);
    this.selectAll = TableUtils.selectAll.bind(this);
    this.checkboxFormatter = TableUtils.checkboxFormatter.bind(this);
    this.emptyFormatter = TableUtils.emptyFormatter.bind(this);
    this.unselectableCellFormatter = TableUtils.unselectableCellFormatter.bind(
      this,
    );
    this.selectRowOnRowSelect = true;
    return {
      list: props ? props.list : this.props.list,
    };
  },
  onRowSelect(row) {
    if (!row.unselectable) {
      if (this.selectRowOnRowSelect) {
        row.selected = true;
      }
      this.setState({});
      if (this.props.header) {
        this.props.header.setState({
          selectCount: this.getSelectedKeys().length,
        });
        this.props.header.refs.detail.showModal(row);
      }
      if (this.handleRowSelect) {
        this.handleRowSelect(row);
      }
    }
  },
  selectRowProp() {
    const rowSelect = this.onRowSelect;
    return {
      mode: 'radio',
      clickToSelect: true,
      bgColor: theme?.colors?.SelectRowPropColor,
      onSelect: rowSelect,
      hideSelectColumn: true,
      selected: this.getSelectedKeys(),
    };
  },
  getSelectedKeys() {
    const selected = [];
    for (let i = 0; i < this.state.list.length; i++) {
      if (this.state.list[i].selected) {
        selected.push(this.state.list[i].id);
      }
    }
    return selected;
  },
  handleSelection(event) {
    if (event.target.name === 'selectAll') {
      for (let i = 0; i < this.state.list.length; i++) {
        if (!this.state.list[i].unselectable) {
          this.state.list[i].selected = event.target.checked;
        }
      }
    } else {
      for (let i = 0; i < this.state.list.length; i++) {
        if (
          this.state.list[i].id === event.target.value &&
          !this.state.list[i].unselectable
        ) {
          this.state.list[i].selected = event.target.checked;
        }
      }
    }
    this.setState({});
    if (this.props.header) {
      this.props.header.setState({
        selectCount: this.getSelectedKeys().length,
      });
    }
  },
  selectAll() {
    for (let i = 0; i < this.state.list.length; i++) {
      if (!this.state.list[i].selected && !this.state.list[i].unselectable) {
        return false;
      }
    }
    return true;
  },
  checkboxFormatter: function CheckboxFormatter(cell, row) {
    /** Without the div, React or React Table gets very confused **/
    return (
      <div id={row.id}>
        <input
          type='checkbox'
          name='selected'
          disabled={row.unselectable}
          value={row.id}
          checked={cell}
          onChange={this.handleSelection}
        />
      </div>
    );
  },
  unselectableCellFormatter: function UnselectableCellFormatter(cell, row) {
    return (
      <div className={!row.unselectable ? '' : ' disabledCell'}>{cell}</div>
    );
  },
  emptyFormatter: function EmptyFormatter() {
    return <span />;
  },
  /** HEADER RELATED UTILITIES **/

  getHeaderInitialState() {
    this.getDisplay = TableUtils.getDisplay.bind(this);
    this.getSelectedKeys = TableUtils.getSelectedKeys.bind(this);
    this.showAddModal = TableUtils.showAddModal.bind(this);
    this.removeText = TableUtils.removeText.bind(this);
    return {
      selectCount: 0,
      list: this.props.list,
    };
  },
  getDisplay(bool) {
    if (bool) {
      return {};
    } else {
      return { display: 'none' };
    }
  },
  showAddModal() {
    this.refs.add.showModal();
  },
  removeText() {
    if (this.getSelectedKeys().length > 1) {
      return `Delete Selected ${this.type}s`;
    } else {
      return `Delete Selected ${this.type}`;
    }
  },
});

export default WrappedComponent =>
  withTheme(props => {
    return (
      <WrappedComponent props={{ ...props, ...TableUtils(props.theme) }} />
    );
  });
