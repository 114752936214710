import { css, ThemeProvider } from '@emotion/react';
import { useCallback, memo } from 'react';
import styled from '@emotion/styled';
import { Checkbox, Radio } from '@sugar-discover/react-bootstrap-wrapper';
import { IDiscoverEmotionTheme, useDiscoverTheme } from './theme';
import _ from 'lodash';

export const NoFlex = styled.div({ flex: 'none' });
export interface IDisableableTheme extends IDiscoverEmotionTheme {
  disabled?: boolean;
}

export const Disableable = comp => {
  const result = styled(comp)(
    ({
      disabled: _propsDisabled,
      theme: { disabled: _themeDisabled },
    }: {
      disabled?: boolean;
      theme: IDisableableTheme;
    }) => {
      const disabled = _themeDisabled || _propsDisabled;
      return css({
        opacity: disabled ? 0.48 : 1,
        cursor: disabled ? 'not-allowed' : undefined,
      });
    },
  );
  return result;
};

export const useDisableableTheme = () => {
  const theme = useDiscoverTheme() as IDisableableTheme;
  return theme;
};

export const DisableableThemeProvider = memo<{
  disabled?: boolean;
  children?;
}>(({ disabled, children }) => {
  const theme = useCallback(
    (ancestorTheme = {} as any) => {
      return {
        ...ancestorTheme,
        disabled,
      };
    },
    [disabled],
  );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
});

export const DisableableDiv = Disableable('div');

export const DisableableSpan = DisableableDiv.withComponent('span');
export const DisableableLabel = DisableableDiv.withComponent('label');

export const DisableableControl = comp => {
  const StyledComp = Disableable(comp);
  const NewComp = ({ children = [], ...props }: any) => {
    const { disabled } = useDisableableTheme();
    return (
      <StyledComp
        {..._.omit(props, 'disabled')}
        disabled={props.disabled || disabled}
      >
        {children}
      </StyledComp>
    );
  };
  return NewComp;
};

export const DisableableRadio = DisableableControl(Radio);
export const DisableableCheckbox = DisableableControl(Checkbox);
