import { useOpenDiscoverySelector } from '../../common/redux/selectors/viz-selector.hook';
import { IHistoryItem } from '../history-item/history-item.interface';
import { find, head, isEmpty, isEqual, map, omit } from 'lodash';
import moment from '../../common/Moment';
import { ACCOUNT_SELECTORS } from '../../common/redux/selectors/AccountSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { IReportHistory } from './report-history.interfaces';
import { useLazyQuery } from '@apollo/client';
import { VizQueries } from '../../common/graphql';
import { openDiscoveryAction } from '../../common/redux/actions/DiscoverActions';
import Const from '../../common/redux/actions/ActionConst';
import { useRef } from 'react';

export const useReportHistory = (discoveryId: string): IReportHistory => {
  const openDiscovery = useOpenDiscoverySelector({ discoveryId });
  const { present } = openDiscovery;
  const { creatorName, viz, name: reportName, updatedOn } = present;
  const { revisions } = viz;
  const selectedVersionRef = useRef<number>(-1);
  const dispatch = useDispatch();

  const [getPreviousVersion, { loading }] = useLazyQuery(
    VizQueries.GetVisualizationVersion,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        const oldVizWithExistingRevisions = {
          ...omit(data.visualization, 'revisions'),
          revisions,
          revisionSelected: find(revisions, {
            version: selectedVersionRef.current,
          }),
        };
        dispatch(
          openDiscoveryAction(
            {
              type: Const.Discover.UPDATE_DISCOVERY_WITH_VERSION,
              visualization: oldVizWithExistingRevisions,
              discoveryId,
            },
            discoveryId,
          ),
        );
      },
    },
  );

  const [getCurrentVersion, { loading: currentLoading }] = useLazyQuery(
    VizQueries.GetVisualization,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        dispatch(
          openDiscoveryAction(
            {
              type: Const.Discover.UPDATE_DISCOVERY_WITH_VIZ,
              visualization: data.visualization,
              discoveryId,
            },
            discoveryId,
          ),
        );
      },
    },
  );
  const fetchVersion = (version: number) => {
    selectedVersionRef.current = version;
    if (version > 0) {
      getPreviousVersion({ variables: { id: discoveryId, version } });
    } else {
      getCurrentVersion({ variables: { id: discoveryId } });
    }
  };

  const { datetimeFormat } = useSelector(state =>
    ACCOUNT_SELECTORS.getDatetimeFormats(state),
  );

  const lastRevision = head(revisions);

  const hasUnversionedChanges = !isEqual(lastRevision?.updatedOn, updatedOn);

  const historyItems: IHistoryItem[] = map(revisions, (revision, index) => ({
    commitMessage: isEmpty(revision.commitMessage)
      ? reportName
      : revision.commitMessage,
    commitDate: moment(revision.updatedOn).format(datetimeFormat),
    commitAuthor: revision.updatedByName,
    commitVersion: revision.version,
    isCurrentVersion: !hasUnversionedChanges && index === 0,
    isFirstVersion: index === revisions.length - 1,
    disabled: loading || currentLoading,
  }));

  if (!hasUnversionedChanges) {
    return { historyItems, loading, fetchVersion };
  }

  const currentHead: IHistoryItem = {
    commitMessage: '',
    commitDate: moment(updatedOn).format(datetimeFormat),
    commitAuthor: creatorName,
    isCurrentVersion: true,
    isFirstVersion: isEmpty(revisions),
    disabled: loading || currentLoading,
  };

  return {
    historyItems: [currentHead, ...historyItems],
    loading: loading || currentLoading,
    fetchVersion,
  };
};
