import { useSelector } from 'react-redux';
import { replaceNumberSeparators } from '../../Constants';
import { ACCOUNT_SELECTORS } from '../../redux/selectors/AccountSelectors';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { format as d3Format } from 'd3';

const defaultFormatter = d3Format(',.2~f');

export const stringifyNumber = (
  num: string | number,
  numberFormatter?: string | Function,
) => {
  if (!_.isString(num) && !_.isNumber(num)) {
    return '';
  }
  let format = _.isString(numberFormatter)
    ? d3Format(numberFormatter)
    : numberFormatter;
  format = _.isFunction(format) ? format : defaultFormatter;
  const asNumber = Number(num);
  return _.isFinite(asNumber) ? format(asNumber) : '';
};

export const useNumberFormatter = (
  numberFormatter: string | Function = defaultFormatter,
) => {
  const { decimal, numGroupSeparator } = useSelector(
    ACCOUNT_SELECTORS.getI18nPrefs,
  ) as any;

  const formatNumber = useCallback(
    (num: any) => {
      const stringifiedNum = stringifyNumber(num, numberFormatter);
      return replaceNumberSeparators(stringifiedNum, {
        decimal,
        numGroupSeparator,
      });
    },
    [decimal, numGroupSeparator, numberFormatter],
  );
  return {
    formatNumber,
  };
};

export const useFormattedNumber = ({ num }) => {
  const { formatNumber: format } = useNumberFormatter();
  const formatted = useMemo(() => format(num), [num, format]);
  return formatted;
};
