import { memo } from 'react';
import Axis, { METRIC_ORIENTATION } from '../../../common/d3/Axis';

export const AxisGrid = memo<{
  primaryPlotGetMetricOrientation;
  primaryPlotGetYScale;
  secondaryPlotGetYScale;
  primaryPlotGetXScale;
  numTicks;
  isMobile;
  primaryYAxisEnabled;
  secondaryYAxisEnabled;
  chartWidth;
}>(
  ({
    primaryPlotGetMetricOrientation,
    primaryPlotGetYScale,
    secondaryPlotGetYScale,
    primaryPlotGetXScale,
    numTicks,
    isMobile,
    primaryYAxisEnabled,
    secondaryYAxisEnabled,
    chartWidth,
  }) => {
    const isVertical =
      primaryPlotGetMetricOrientation() === METRIC_ORIENTATION.VERTICAL;
    if (!primaryYAxisEnabled() && !secondaryYAxisEnabled()) {
      return null;
    }
    const scale = isVertical
      ? primaryYAxisEnabled()
        ? primaryPlotGetYScale()
        : secondaryPlotGetYScale()
      : primaryPlotGetXScale();
    const yGridProps = {
      scale,
      ticks: numTicks,
      orient: isVertical ? 'grid' : 'grid-horizontal',
      tickFormat: '',
      tickSizeInner: -chartWidth,
      scrollable: 'true',
      isMobile,
    };
    return <Axis {...yGridProps} />;
  },
);
