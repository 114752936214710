import { css } from '@emotion/react';

export const mobileInsightsStyles = ({
  theme: { viewWidth, viewHeight },
}) => css`
  .no-insights {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  .viz-insights-panel {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .mobile-insights {
    height: calc(${viewHeight} - 10px);
    overflow: hidden;
    width: ${viewWidth};
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;
