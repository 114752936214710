import {
  useOpenVizChartSpec,
  useOpenVizSelector,
} from '../../common/redux/selectors/viz-selector.hook';
import { getFirstMetric } from '../../discovery/charts/waterfall';

export const useHasDisabledAggregations = ({ discoveryId, field }) => {
  const chartSpec = useOpenVizChartSpec({ discoveryId });
  const viz = useOpenVizSelector({ discoveryId });
  const firstMetric = getFirstMetric(viz);

  return firstMetric?.name === field?.name && chartSpec?.id === 'waterfall';
};
