import styled from '@emotion/styled';
import { ErrorFallbackBase } from '../default-error-fallback/default-error-fallback.styes';

export const FullScreenFallBackStyled = styled(ErrorFallbackBase)<{
  isDashletMode?: boolean;
}>`
  height: ${props => (props.isDashletMode ? '100%' : '100vh')};
  width: ${props => (props.isDashletMode ? '100%' : '100vw')};
  ${props => (props.isDashletMode ? 'padding: 0;' : '')}
  background-color: ${
    window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'black'
      : 'white'
  };
  color: ${
    window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'white'
      : 'black'
  };
  font-size: 1.125rem;
  line-height: ${props => (props.isDashletMode ? '2rem' : '3rem')};
`;
