import { Component } from 'react';
import { Button } from '../../ui';
import isString from 'lodash/isString';
import classnames from 'classnames';
import { Tooltip } from '../../components/ui/tooltip';
import { isObject, isFunction } from 'lodash';

class ToolbarButton extends Component {
  constructor(props) {
    super(props);
    const pathLoading = isString(this.props.img);
    this.state = { img: this.props.img, icon: this.props.img, pathLoading };
  }

  enterButton() {
    if (!this.state.pathLoading) {
      return;
    }
    if (!this.props.disabled && this.props.hoverImg) {
      this.setState({ img: this.props.hoverImg });
    }
  }

  leaveButton() {
    if (!this.state.pathLoading) {
      return;
    }
    this.setState({ img: this.props.img });
  }

  mouseDown() {
    if (!this.state.pathLoading) {
      return;
    }
    if (this.props.activeImg) {
      this.setState({ img: this.props.activeImg, prevImg: this.state.img });
    }
  }

  mouseUp() {
    if (!this.state.pathLoading) {
      return;
    }
    if (this.props.activeImg && this.state.prevImg) {
      this.setState({ img: this.state.prevImg, prevImg: null });
    }
  }

  render() {
    const css = classnames(
      'toolbar-button',
      { hidden: this.props.hide },
      this.props.className,
    );
    const Icon = this.state.icon;
    const title = this.props.useOverlay ? '' : this.props.tooltip;
    const button = (
      <Button
        title={title}
        className={css}
        onClick={this.props.onClick}
        bsClass='toolbar-btn btn'
        onMouseDown={() => this.mouseDown()}
        onMouseUp={() => this.mouseUp()}
        onMouseEnter={() => this.enterButton()}
        onMouseLeave={() => this.leaveButton()}
        disabled={this.props.disabled}
      >
        {this.state.pathLoading ? (
          <img src={this.state.img} />
        ) : isObject(this.state.icon) && !isFunction(this.state.icon) ? (
          this.state.icon
        ) : (
          <Icon />
        )}
      </Button>
    );
    return this.props.tooltip && this.props.useOverlay ? (
      <Tooltip
        id='tooltip2'
        title={this.props.tooltip}
        placement={this.props.placement}
      >
        {button}
      </Tooltip>
    ) : (
      button
    );
  }
}

ToolbarButton.defaultProps = {
  placement: 'bottom',
  useOverlay: false, // true:use an Overlay as the tooltip | false: use title [default]
};

export default ToolbarButton;
