import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const SpinnerLoaderStyles = css`
  margin: auto 0.25em;
`;

export const HelpBlock = styled.div(
  ({ theme: { colors: { ContentText } = {} } = {} }) => `
  color: ${ContentText};
  text-align: center;
  margin-bottom: 30px;
`,
);

export const ModalLabel = styled.div(
  ({ theme: { darkMode, colors: { ContentText, BaseWhite } = {} } = {} }) => `
  color: ${darkMode ? BaseWhite : ContentText};
  `,
);
