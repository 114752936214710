import styled from '@emotion/styled';

export const LoaderDiv = styled.div`
  .spinner {
    flex-direction: column;
    .spinner-label {
      padding: 16px 0 0 0;
      font-size: 14px;
    }
  }
`;
