import { useMemo } from 'react';
import { Global } from '@emotion/react';
import { connect } from 'react-redux';
import { compose, pure } from 'react-recompose';
import { useDiscoverTheme } from './theme';
import { mainStyles } from './main.styles';

export const GlobalStyles = compose(
  connect((state: any) => {
    let {
      main: { mobileMaxWidth, appUrl, isMobile },
      dashlet: { isDashletMode },
    } = state;
    mobileMaxWidth = isDashletMode ? 10000 : mobileMaxWidth;
    return { mobileMaxWidth, appUrl, isDashletMode, isMobile };
  }),
  pure,
)(({ mobileMaxWidth, appUrl, isDashletMode, isMobile }: any) => {
  const theme = useDiscoverTheme();
  const styles = useMemo(
    () =>
      mainStyles({
        mobileMaxWidth: `${mobileMaxWidth}px`,
        theme,
        appUrl,
        isDashletMode,
        isMobile,
      }),
    [appUrl, isDashletMode, isMobile, mobileMaxWidth, theme],
  );
  return <Global styles={styles} />;
});
