import { css } from '@emotion/react';
import { font, vbox, centeredPanel, backgroundImage } from './mixins';

export const dialogsStyles = ({
  appUrl,
  theme: {
    colors: {
      ContentBackground,
      MediumBorder,
      CorvanaBackgroundMedium,
      LightFontWeight,
      PanelBackground,
      ModalBackdropColor,
      MediumFontWeight,
      ContentText,
      RegularFontWeight,
      Black,
      FixedErrorModalShadowColor,
      ModalHeaderBackground,
      ModalFontColor,
    },
  },
}) => {
  return css`
    /** Regular Modal **/

    .regular-modal .modal-header {
      ${font({ size: '22px' })}
      background-color: ${ModalHeaderBackground};
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom: none;
    }
    .regular-modal {
      ${font({ color: ModalFontColor })}
    }
    .regular-modal .modal-title {
      ${font({ size: '22px' })}
    }
    .regular-modal .modal-footer {
      border-top: none;
    }
    .regular-modal .modal-content,
    .regular-modal .modal-header {
      box-shadow: none;
      border: none;
      background: none;
    }
    .regular-modal .control-label {
      ${font({ size: '16px', color: ModalFontColor })}
      margin-bottom: 8px;
    }
    .regular-modal .input-group-addon {
      background-color: ${CorvanaBackgroundMedium};
      color: inherit;
    }
    .regular-modal input[type='checkbox'] {
      margin-top: 3px !important;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
    .regular-modal .buttonGroup {
      text-align: center;
    }
    .regular-modal .help-block {
      ${font({ size: '14px' })}
      margin: 10px 0;
    }
    .regular-modal {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
    }
    .prompt-dialog.modal {
      z-index: 1150;
    }
    .prompt-dialog p.message {
      text-align: center;
      margin-bottom: 30px;
    }
    .prompt-dialog {
      color: ${ModalFontColor};
    }
    .prompt-dialog p.message ul,
    .prompt-dialog p.message li {
      text-align: left;
    }
    .prompt-dialog-backdrop {
      z-index: 1140;
    }
    .auto-width {
      width: auto;
    }
    .modal-backdrop.in {
      background-color: ${ModalBackdropColor};
      opacity: 0.96;
    }
    .promptMessage {
      margin-bottom: 24px;
      text-align: center;
      ${font({
        size: '16px',
        weight: LightFontWeight,
        color: ModalFontColor,
      })}
    }
    .regular-modal.full-screen {
      width: 95%;
      max-height: 95%;
      height: 95%;
      -webkit-box-shadow: 0 0 10px ${MediumBorder};
      box-shadow: 0 0 10px ${MediumBorder};
      overflow: hidden;
    }
    .regular-modal.full-screen .modal-content {
      height: 100%;
    }
    .regular-modal.full-screen .modal-body {
      padding: 20px;
      /* account for the header ~61px*/
      height: calc(100% - 61px);
      overflow: initial;
    }
    .regular-modal.full-screen .react-bs-table-container.corvana-bs-table.grid {
      overflow: initial;
    }
    .regular-modal.full-screen .react-bs-table {
      height: calc(100% - 20px);
    }
    .regular-modal.full-screen.fixed-error-modal {
      -webkit-box-shadow: 0 0 10px ${FixedErrorModalShadowColor};
      box-shadow: 0 0 10px ${FixedErrorModalShadowColor};
      overflow-y: scroll;
    }
    .preview-toolbar {
      ${font({ size: '16px', weight: LightFontWeight })}
      display: flex;
      align-items: center;
    }
    .preview-toolbar div:not(:last-of-type) {
      margin-right: 16px;
    }
    .preview-toolbar .form-control {
      height: 34px;
      margin-right: 10px;
    }
    .preview-toolbar .dropdown-menu {
      width: 100%;
    }
    .preview-scroll-container {
      display: flex;
      flex-flow: column nowrap;
      flex: 1;
    }
    .full-screen-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
    }
    .prompt-dialog.modal .saveButtonGroup button:first-of-type {
      margin-left: 0;
    }
    .error-dialog .modal-content,
    .full-screen-dialog .modal-content {
      box-shadow: none;
      border: none;
      border-radius: inherit;
      height: 100%;
    }
    .error-dialog .modal-body,
    .full-screen-dialog .modal-body {
      ${vbox()}
      background-color: ${PanelBackground};
      overflow-y: auto;
    }
    .error-dialog .close,
    .full-screen-dialog .close,
    .custom-format-dialog .close {
      background-repeat: no-repeat;
      ${backgroundImage({ appUrl, path: 'full_screen_cancel_icon.svg' })}
      width: 18px;
      height: 18px;
      margin-top: 14px;
      margin-right: 14px;
      opacity: 1;
    }
    .error-dialog .close span,
    .full-screen-dialog .close span,
    .custom-format-dialog .close span {
      display: none;
    }
    .error-dialog {
      height: 530px;
      width: 900px;
      position: absolute;
      top: calc(50% - 290px);
      left: calc(50% - 450px);
    }
    .error-dialog h5 {
      ${font({ size: '18px', weight: MediumFontWeight, style: ContentText })}
    }
    .error-dialog pre {
      border: none;
      height: 100%;
      overflow: auto;
      word-break: normal;
    }
    .error-dialog .modal-header {
      display: flex;
    }
    .error-dialog .modal-header h4 {
      flex: 1;
      align-self: center;
    }
    .error-dialog .close {
      position: absolute;
      right: 0px;
    }
    .dataset-edit-dialog .modal-body {
      overflow: hidden;
    }
    .dataset-edit-dialog .form-group {
      display: inline-block;
      margin: 32px 0px 32px 24px;
      max-width: 100%;
    }
    .dataset-edit-dialog label {
      ${font({ size: '16px', weight: RegularFontWeight })}
    }
    .dataset-edit-dialog .form-control {
      ${font({ size: '14px', weight: LightFontWeight })}
    }
    .dataset-edit-dialog .form-control[name='name'] {
      width: 416px;
    }
    .dataset-edit-dialog .form-control[name='description'] {
      width: 704px;
      max-width: 100%;
    }
    .dataset-edit-dialog .button-group {
      display: flex;
      justify-content: flex-end;
      margin: 16px;
    }
    .app-out-of-date-dialog {
      z-index: 2010;
    }
    .app-out-of-date-message {
      ${centeredPanel()}
      margin-bottom: 30px;
    }
    .prompt-dialog--desktop .buttonGroup button:first-of-type {
      margin-left: 0;
    }
    .select-datasource-dialog .modal-body {
      padding: 15px 0 1px 0 !important;
    }
    .select-datasource-dialog .modal-body .tabsHeader {
      padding-left: 15px;
    }
    .import-records-table {
      border: 1px solid ${ContentBackground};
    }
    .import-records-table td {
      padding: 8px;
    }
    .import-records-table td.dataset {
      width: 40%;
    }
    .import-records-dropdown > div {
      width: 100%;
    }
    .import-records-dropdown .dropdown-menu > li > a {
      color: ${Black};
      width: 100%;
    }
  `;
};
