import {
  withProps,
  InferableComponentEnhancerWithProps,
} from 'react-recompose';
import _ from 'lodash';
import { handleTemplates } from './templating.util';
import { TemplateMap } from './templating.interface';

export type TemplatePropsEnhancer<T> = InferableComponentEnhancerWithProps<
  _.Dictionary<JSX.Element[]> & T,
  T
>;
export type TemplateFunc<T = any> = (props: T) => TemplateMap;

export function TemplateProps<T = any>(templates: {
  [key: string]: TemplateFunc<T>;
}): TemplatePropsEnhancer<T>;
export function TemplateProps<T = any, Tout = Partial<T>>(
  templateFn: TemplateFunc<T>,
  propsFunc: (props: T) => Tout,
): TemplatePropsEnhancer<T>;
export function TemplateProps<T = any, Tout = Partial<T>>(
  templateFn: { [key: string]: TemplateFunc<T> } | TemplateFunc<T>,
  propSelector?: (props: T) => Tout,
) {
  return withProps((props: T) => {
    let propsOut: any;
    if (_.isFunction(templateFn)) {
      const templates = templateFn(props);
      if (!_.isFunction(propSelector)) {
        throw new Error(
          'TemplateProps HOC requires a propSelector when used with a single set of templates',
        );
      }
      propsOut = _.mapValues(propSelector(props) as any, value => ({
        templates,
        value,
      }));
    } else if (_.isObject(templateFn)) {
      if (propSelector) {
        console.warn(
          'When passing an object as the first argument of TemplateProps HOC, the propSelector function will not be ' +
            'used. This will not break anything, but it might not work as intended.',
          {
            propSelector,
            templateFn,
          },
        );
      }
      propsOut = _.mapValues(templateFn, (fn, propName) => ({
        templates: fn(props),
        value: props[propName],
      }));
    }
    return _.mapValues(propsOut, ({ templates, value }) => {
      return handleTemplates(value, templates);
    });
  });
}
