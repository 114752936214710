import { CSSProperties, useMemo } from 'react';
import { max, merge, min, pickBy, startsWith } from 'lodash';
import {
  bottom as POPPER_BOTTOM_SELECTOR,
  top as POPPER_TOP_SELECTOR,
} from '@popperjs/core';
import { useDiscoverTheme } from '../../../common/emotion/theme/discover-emotion-theme-provider.component';
import { useResizeObserver } from '../../../common/utilities/resize-observer';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  MAX_SLICER_WIDTH,
  SLICER_DROPDOWN_HEIGHT_MOBILE,
  BORDER_RADIUS,
  SCROLL_ROW_HEIGHT,
} from '../../../discovery/slicer/slicer-widget/common/slicer-widget-consts.style';

export const StyledDiv = styled.div(
  ({ height = 0 }: any) =>
    css`
      flex: 1 1 0%;
      max-width: 100%;
      height: ${height ?? 0}px;
      border-radius: ${BORDER_RADIUS};
    `,
);

export const useDropdownStyles = ({
  renderedPlacement,
  isOpen = false,
  headerSx: providedHeaderSx,
  popperSx: providedPopperSx,
  listSx: providedListSx,
  headerRef,
  listRef,
  dropdownRef,
  disablePortal,
}) => {
  const {
    isDashletMode,
    isMobile,
    darkMode,
    colors: {
      Gray30,
      ContentText,
      ContentBackground,
      DropdownButtonBorderColor,
      DropdownButtonBackgroundColor,
      DropdownBorderColor,
    } = {},
  } = useDiscoverTheme();

  const isResponsiveView = isMobile || isDashletMode;

  const isRenderedOnTop = startsWith(renderedPlacement, POPPER_TOP_SELECTOR);
  const isRenderedOnBottom = startsWith(
    renderedPlacement,
    POPPER_BOTTOM_SELECTOR,
  );

  const openListSxOverrides: any = {};
  const openHeaderSxOverrides: any = { width: 'auto' };

  if (isRenderedOnTop) {
    openListSxOverrides.borderBottomWidth = 0;
    openListSxOverrides.borderBottomLeftRadius = 0;
    openListSxOverrides.borderBottomRightRadius = 0;

    openHeaderSxOverrides.borderTopWidth = 0;
    openHeaderSxOverrides.borderTopLeftRadius = 0;
    openHeaderSxOverrides.borderTopRightRadius = 0;
  } else if (isRenderedOnBottom) {
    openListSxOverrides.borderTopWidth = 0;
    openListSxOverrides.borderTopLeftRadius = 0;
    openListSxOverrides.borderTopRightRadius = 0;

    openHeaderSxOverrides.borderBottomWidth = 0;
    openHeaderSxOverrides.borderBottomLeftRadius = 0;
    openHeaderSxOverrides.borderBottomRightRadius = 0;
  }

  const { width: headerWidth } = useResizeObserver(headerRef?.current);
  const { height: listHeight = 0, width: listWidth } = useResizeObserver(
    listRef?.current,
  );
  const {
    height: _dropdownHeight = 0,
    width: _dropdownWidth,
  } = useResizeObserver(dropdownRef?.current);

  const { dropdownWidth, dropdownHeight, containerHeight } = useMemo(() => {
    const headerHeight =
      _dropdownHeight === 0 ? SCROLL_ROW_HEIGHT : _dropdownHeight;
    const nextWidth = min([
      max([_dropdownWidth, listWidth, headerWidth + 13]),
      MAX_SLICER_WIDTH,
    ]);

    return {
      dropdownHeight: headerHeight,
      dropdownWidth:
        nextWidth - _dropdownWidth > 3 ? nextWidth : _dropdownWidth,
      containerHeight: disablePortal ? listHeight + headerHeight : headerHeight,
    };
  }, [
    _dropdownHeight,
    _dropdownWidth,
    listWidth,
    headerWidth,
    disablePortal,
    listHeight,
  ]);

  providedHeaderSx.minWidth = `min(100cqw, ${min([
    MAX_SLICER_WIDTH,
    dropdownWidth,
  ])}px)`;

  if (!disablePortal) {
    providedPopperSx.width = `min(100cqw, ${min([
      MAX_SLICER_WIDTH,
      dropdownWidth,
    ])}px)`;
  }

  const headerSizingRules = pickBy(providedHeaderSx, (_value, key) =>
    key.match(/margin|width|height/i),
  );

  const widthSizingRules = pickBy(headerSizingRules, (_value, key) =>
    key.match(/width/i),
  );

  let popperSx = merge(
    {
      width: isResponsiveView
        ? '100%'
        : `min(100cqw, ${min([MAX_SLICER_WIDTH, dropdownWidth])}px)`,
      maxWidth: '100%',
      zIndex: 11,
    },
    providedPopperSx,
  );

  if (disablePortal) {
    popperSx = {
      ...popperSx,
      ...widthSizingRules,
    };
  }

  const borderStyle = {
    width: '100%',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: isOpen ? DropdownBorderColor : DropdownButtonBorderColor,
  };

  const paperSx = merge(
    {
      borderRadius: BORDER_RADIUS,
      overflow: 'hidden',
      backgroundColor: 'transparent',
    },
    borderStyle,
    openListSxOverrides,
  );

  const listSx = merge(
    {
      width: '100%',
      maxHeight: '20rem',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden auto',
      backgroundColor: DropdownButtonBackgroundColor,
    },
    isOpen ? {} : { containerType: 'size' },
    openListSxOverrides,
    providedListSx,
  );

  const wrapperStyle: CSSProperties = {
    display: 'flex',
    position: 'relative',
    height: isResponsiveView ? SLICER_DROPDOWN_HEIGHT_MOBILE : dropdownHeight,
    minWidth: `min(100cqw, ${min([MAX_SLICER_WIDTH, dropdownWidth])}px)`,
    width: `min(100cqw, ${min([MAX_SLICER_WIDTH, dropdownWidth])}px)`,
    ...headerSizingRules,
  };

  const headerSx = merge(
    {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      backgroundColor: DropdownButtonBackgroundColor,
      color: ContentText,
      '&:hover': {
        backgroundColor: darkMode ? Gray30 : ContentBackground,
        color: ContentText,
      },
    },
    borderStyle,
    openHeaderSxOverrides,
    providedHeaderSx,
  );

  return {
    headerSx,
    popperSx,
    listSx,
    paperSx,
    wrapperStyle,
    containerHeight,
  };
};

export const useSelectItemSx = ({ providedSx = {} }) => {
  const {
    colors: { DropdownOptionHoverBackground, Gray90 } = {},
  } = useDiscoverTheme();

  return merge(
    {
      color: Gray90,
      padding: '0.5rem 0.75rem',
      fontSize: '12px',
      height: '34px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        backgroundColor: DropdownOptionHoverBackground,
      },
      '&:focus': {
        backgroundColor: DropdownOptionHoverBackground,
      },
    },
    providedSx,
  );
};

export const useHeaderSx = ({ providedSx }) => {
  const {
    darkMode,
    colors: {
      DefaultIconColor,
      Gray50,
      DropdownButtonBackgroundColor,
      ContentBackground,
      ContentText,
    } = {},
  } = useDiscoverTheme();

  const headerSx = merge(
    {
      height: '32px',
      width: 'auto',
      borderRadius: BORDER_RADIUS,
      backgroundColor: darkMode
        ? DropdownButtonBackgroundColor
        : ContentBackground,
      fontSize: '12px',
      color: darkMode ? ContentText : Gray50,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '5px',
      cursor: 'pointer',
      userSelect: 'none',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '& .caret': {
        marginLeft: '0.3rem',
        marginRight: '0.1rem',
        color: DefaultIconColor,
      },
      '& span.title': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: 'auto',
        maxWidth: 'calc(100% - 1.4rem)',
      },
    },
    providedSx,
  );

  return headerSx;
};
