import { Component } from 'react';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import toUpper from 'lodash/toUpper';
import map from 'lodash/map';

class component extends Component {
  render() {
    const {
      isLoggedIn,
      roles,
      allowedRoles,
      disallowedRoles,
      redirect,
      ...otherProps
    } = this.props;
    if (!isLoggedIn) {
      return null;
    }
    const hasCreds = disallowedRoles
      ? intersection(map(roles, toUpper), map(disallowedRoles, toUpper))
          .length === 0
      : intersection(map(roles, toUpper), map(allowedRoles, toUpper)).length >
        0;
    if (hasCreds) {
      return <Route {...otherProps} />;
    } else if (redirect) {
      return <Redirect to={redirect} />;
    } else {
      return null;
    }
  }
}

component.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  redirect: PropTypes.string,
};

component.defaultProps = {
  allowedRoles: undefined, // allow any by default
  redirect: undefined,
};

const mapDispatchToProps = () => {
  return {};
};

const mapStateToProps = state => {
  return {
    isLoggedIn: get(state, 'login.isLoggedIn', undefined),
    roles: get(state, 'account.currentUser.roles', []),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(component);
