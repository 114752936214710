/**
 *  This library contains the actions and state relating to the login experience.
 */
import _ from 'lodash';
import URLs from '../../Urls';
import { client } from '../../ApolloClient';
import Const from './ActionConst';
import { getAuthHeaders } from '../../../auth';
import { CommonActions } from './common.actions';

const Actions = {
  loginActions: [],
  login: CommonActions.login,
  isLoggedIn: CommonActions.isLoggedIn,
  async logout(dispatch) {
    const doLogout = _.once(result => {
      let idmLogoutUrl;
      try {
        ({ idmLogoutUrl } = _.isString(result) ? JSON.parse(result) : result);
      } catch (err) {
        idmLogoutUrl = null;
      }
      // Clear Redux
      dispatch({ type: 'RESET_STATE' });
      // Clear Apollo
      localStorage.clear();
      client.resetStore();
      if (idmLogoutUrl) {
        dispatch({
          type: Const.Login.LOGOUT,
          idmLogoutUrl,
          returnToUrl: window.location.href,
        });
        URLs.goTo('/logout');
      } else {
        /******************
         At this point the app should have re-routed to `/logout`.
         Reloading the window to said login screen will prevent any webpack-require caching of tenanted modules.
         Clearing out the ServiceLocator is not enough...
         Even though when we load the modules, we specify no cache and we can see the web request go to the server,
         webpack tries to outsmart us. The returned js is wrapped in a the webpack bootstrap loader (webpackJsonp).
         It sees that it has already seen this module and just assumes it has already loaded it and DOES NOT execute the module.
         This means that the ServiceLoacator.add calls in the constructor never get called and we have NO tenanted components loaded.
         This scenario would cause a failure on the 2nd login attempt to the same tenant without re-loading the window. An initial
         tenant switch would work.
        *******************/
        window.location.reload();
      }
    });

    const headers = await getAuthHeaders({});
    return fetch('/api/auth/logout', {
      credentials: 'include',
      headers,
    }).then(async res => {
      let json;
      try {
        json = await res.json();
      } catch (err) {
        json = {};
      }
      doLogout(json);
    });
  },
  onFailure(dispatch, callback, event) {
    if (event.status === 401 /* UNAUTHORIZED */) {
      URLs.goTo('/login');
    } else {
      callback.call(this, dispatch, event);
    }
  },
};

export default Actions;
