import _ from 'lodash';
/**
 * Simple div that takes declarative scroll positions
 */
import { Component } from 'react';
import PropTypes from 'prop-types';

class component extends Component {
  componentDidUpdate() {
    if (!_.isUndefined(this.props.scrollTop)) {
      this.div.scrollTop = this.props.scrollTop;
    }
    if (!_.isUndefined(this.props.scrollLeft)) {
      this.div.scrollLeft = this.props.scrollLeft;
    }
  }
  render() {
    return (
      <div
        {..._.omit(this.props, 'scrollLeft', 'scrollTop')}
        ref={d => {
          this.div = d;
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

component.propTypes = {
  scrollLeft: PropTypes.number,
  scrollTop: PropTypes.number,
};

component.defaultProps = {
  scrollLeft: undefined,
  scrollTop: undefined,
};

export default component;
