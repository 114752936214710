import { Component } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import { event as currentEvent } from 'd3-selection';
import { HANDLE_NULL_AS } from '../Constants';
import { LineChartUtils } from '../../discovery/charts/LineChartUtils';
import { isEqual, isFunction } from 'lodash';
import classnames from 'classnames';
import _ from 'lodash';

class Line extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
  }

  componentDidUpdate(prevProps) {
    if (isEqual(this.props.data, prevProps.data)) {
      return;
    } else {
      if (this.pathElement && this.props.transitionDuration > 0) {
        const p = this.pathElement;
        const node = d3.select(p);

        const data = [...this.props.data];
        const pathDef = this.line(data, this.props);
        node
          .transition('line-ease')
          .duration(this.props.transitionDuration)
          .ease(d3.easeLinear)
          .attr('d', () => pathDef)
          .on('end', () =>
            this.setState({ path: pathDef, transitioned: true }),
          );
      } else {
        this.setState({ path: this.line(this.props.data, this.props) });
      }
    }
  }

  componentDidMount() {
    const updatedPath = this.line(this.props.data);
    if (!_.isEqual(this.state.path, updatedPath)) {
      this.setState({ path: updatedPath });
    }

    if (this.props.onMouseMove) {
      d3.select(this.pathElement)
        .on('mousemove', evt => {
          if (!this.props.selectionMode) {
            currentEvent.stopPropagation();
            this.props.onMouseMove(this.props.data, evt);
          }
        })
        .on('click', () => {
          if (isFunction(this.props.onLineClick)) {
            this.props.onLineClick(this.props.data);
            currentEvent.stopPropagation();
          }
        });
    }
    this.labelContainer = d3.select(this.lineContainer).append('g');
  }

  line(data, props) {
    let lineProps = this.props;
    const lineData =
      lineProps.nullHandling === HANDLE_NULL_AS.MISSING
        ? _.filter(data, d => _.isNumber(d.y))
        : data.map(item => (_.isNumber(item.y) ? item : { ...item, y: 0 }));
    if (props) {
      lineProps = props;
    }
    const lineFunc = d3
      .line()
      .x(d => {
        const { offsetX = 0 } = this.props;
        const offset = _.isNaN(offsetX) ? 0 : offsetX;
        return lineProps.xScale(lineProps.getX(d)) + offset;
      })
      .y(d => {
        if (
          LineChartUtils.isNull(d, lineProps.getY) &&
          lineProps.nullHandling === HANDLE_NULL_AS.ZERO
        ) {
          return lineProps.yScale(0);
        }
        return lineProps.yScale(lineProps.getY(d));
      })
      .defined(d => {
        if (lineProps.nullHandling === HANDLE_NULL_AS.MISSING) {
          return !LineChartUtils.isNull(d, lineProps.getY);
        }
        return true;
      });
    if (lineProps.curve) {
      lineFunc.curve(lineProps.curve);
    }

    // There is some data that creates NaNs in the path here
    const xDomain = lineProps.xScale.domain();
    let sortedLineData = lineData;

    if (
      data.length === xDomain.length &&
      _.every(sortedLineData, ({ x }) => _.includes(xDomain, x))
    ) {
      sortedLineData = _.sortBy(data, ({ x }) =>
        _.findIndex(xDomain, d => x === d),
      );
    }
    return lineFunc(sortedLineData);
  }

  renderLinePath() {
    return (
      <g
        className='captureMouseEvents'
        ref={p => {
          this.pathElement = p;
        }}
      >
        <path
          className={classnames(this.props.className, {
            dim: !this.props.focus,
          })}
          d={this.state.path}
          stroke={this.props.color}
        />
        <path
          d={this.state.path}
          fill='none'
          stroke='transparent'
          strokeWidth={10}
          pointerEvents='stroke'
        />
      </g>
    );
  }

  render() {
    return (
      <g
        className={this.props.className}
        width={this.props.width}
        height={this.props.height}
        style={this.props.style}
      >
        {this.renderLinePath()}
      </g>
    );
  }
}

Line.defaultProps = {
  className: 'valLine',
  transitionDuration: 0,
  focus: true,
  offsetX: 0, // Bar-Line combo adjustment, move points to center of bars
};

export default Line;
