import { useSelector } from 'react-redux';
import {
  isAdmin as _isAdmin,
  isAppAdmin as _isAppAdmin,
  isReadOnly as _isReadOnly,
  isDashletUser as _isDashletUser,
  isNoAccess as _isNoAccess,
  ACCOUNT_SELECTORS,
} from '../../../common/redux/selectors/AccountSelectors';
import { IUserInfo } from '../../../account/interfaces';
import _ from 'lodash';

export type UseAccountHook = {
  isAdmin: boolean;
  isAppAdmin: boolean;
  isReadOnly: boolean;
  isDashletUser: boolean;
  isNoAccess: boolean;
  currentUser: IUserInfo;
};

export const useAccount = (): UseAccountHook => {
  const isAdmin = useSelector(({ account }: any) => _isAdmin(account));
  const isAppAdmin = useSelector(({ account }: any) => _isAppAdmin(account));
  const isReadOnly = useSelector(({ account }: any) => _isReadOnly(account));
  const isDashletUser = useSelector(({ account }: any) =>
    _isDashletUser(account),
  );
  const isNoAccess = useSelector(({ account }: any) => _isNoAccess(account));
  const currentUser =
    useSelector(ACCOUNT_SELECTORS.getCurrentUser, _.isEqual) ?? {};

  return {
    isAdmin,
    isAppAdmin,
    isReadOnly,
    isDashletUser,
    isNoAccess,
    currentUser,
  };
};
