import { css } from '@emotion/react';
import { vbox, font } from '../../../common/emotion/mixins';

export const commonInsightStyles = ({
  theme: {
    colors: { LightFontWeight, ContentText },
  },
}) => css`
  .dynamic-insight-table-wapper {
    ${vbox()}
    justify-content: center;
    align-items: stretch;
    flex: 1;
    width: 100%;
    margin: 14px 0px;
  }
  .dynamic-insight-table-wapper .dynamic-insight-table {
    display: table;
    width: 100%;
  }
  .dynamic-insight-table-wapper .dynamic-insight-table .dynamic-insight-row {
    display: table-row;
  }
  .dynamic-insight-table-wapper
    .dynamic-insight-table
    .dynamic-insight-row
    > div {
    padding: 5px;
  }
  .dynamic-insight-table-wapper
    .dynamic-insight-table
    .dynamic-insight-row
    .segment {
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
    display: table-cell;
    max-width: 100px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dynamic-insight-table-wapper
    .dynamic-insight-table
    .dynamic-insight-row
    .segment-value {
    display: table-cell;
    max-width: 100px;
    ${font({ size: '14px', weight: LightFontWeight, color: ContentText })}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dynamic-insight-table-wapper
    .dynamic-insight-table
    .dynamic-insight-row
    .segment-value
    span.difference {
    color: green;
  }
  .dynamic-insight-table-wapper
    .dynamic-insight-table
    .dynamic-insight-row.bottom
    span.difference {
    color: red;
  }
  .dynamic-insight-table-wapper
    .dynamic-insight-table
    .dynamic-insight-row.bottom
    span.difference
    svg {
    vertical-align: middle;
  }
`;
