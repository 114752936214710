import styled from '@emotion/styled';
import { hbox } from '../../../common/emotion';
import { css } from '@emotion/react';

export const SlicerPanelContainer = styled.div<{
  width: number;
}>(
  ({ width }) => css`
  ${hbox()}
  // left and overflow-x property will be set by ref updates
  width: ${width}px;
  overflow-x: auto;
  margin-top: 10px;
  padding-bottom: 5px;
  position: relative;
  z-index: 5;
`,
);
