import Utils from './PivotTableUtils';
import _ from 'lodash';

export default props => ({
  /**
   * Updates pivot sorting. Every row and column is sorted ascending by default as returned by the server.
   *
   * Multiple sorts are applied in order though this only applies in the "tabular" view. Pivot sorting obeys hierarchy rules
   *
   * The last sorted field in each group is highlighted in the UI. We indicate this in the sort options via the highlight
   * property. This in no way affects the sorting.
   *
   * @param kind Rows or Columns
   * @param idx Index of the row or column
   * @param dir asc or desc
   * @param parents optional array of parent fields to be considered along with name as a unique sort position in the pivot table
   */
  sort: (kind, name, dir, parents = []) => {
    const newSorting = _.cloneDeep(props.sorting);
    let sortCategory = newSorting[kind];

    const shelf = Utils.findShelf(props, name);

    const path = [name].concat(parents);

    const fieldsToSort = [[name, dir, path]];
    if (parents.length === 0 && Utils.isPivotLayout(props)) {
      // not a value sort AND we're in Pivot view. Ensure that parents in the rows or columns hierarchy are also sorted
      const levelIdx = props.viz.layout[shelf].findIndex(x => x.name === name);

      // special case for last level in the hierarchy, clear any existing value sorts as this will take precedence
      if (levelIdx === props.viz.layout[shelf].length - 1) {
        // If there's a sort of the last level, clear it out so the value sort can have an effect
        newSorting[kind] = sortCategory = _.reject(
          sortCategory,
          x => Utils.findShelf(props, x.path[0]) === 'VALUES',
        );
      }
    }

    if (shelf === 'VALUES') {
      // value row sort
      // not a value sort, ensure that parents in the rows of columns heirarchy are also sorted

      // Sort all levels in the heirarchy except the last, which values is a peer of.
      props.viz.layout.ROWS.slice(
        0,
        Math.max(0, props.viz.layout.ROWS.length - 1),
      ).forEach(attr => {
        if (!sortCategory.find(x => _.isEqual(x.path, [attr.name]))) {
          fieldsToSort.unshift([attr.name, 'asc', [attr.name]]);
        }
      });

      if (props.viz.layout.ROWS.length > 0) {
        // If there's a sort of the last level, clear it out so the value sort can have an effect
        newSorting[kind] = sortCategory = _.reject(sortCategory, x =>
          _.isEqual(x.path, [_.last(props.viz.layout.ROWS).name]),
        );
      }

      // We can only ever sort by one value at a time, clear out any others
      newSorting[kind] = sortCategory = _.reject(
        sortCategory,
        x =>
          x.path.length > 1 /* only value sorts have a path greater than 1 */,
      );
    }

    fieldsToSort.forEach(([, _dir, _path]) => {
      const exiting = sortCategory.find(x => _.isEqual(x.path, _path));
      if (exiting) {
        // change existing sort direction
        exiting.direction = _dir;
        newSorting.highlight[kind] = _path;
      } else {
        sortCategory.push({ path: _path, direction: _dir });
        newSorting.highlight[kind] = _path;
      }
    });

    props.setSorting(newSorting);
  },
});
