import { Component } from 'react';
import ReactDOM from 'react-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import _ from 'lodash';
import { messages } from '../../i18n';
import { VIZ } from '../../common/Constants';
import { DisableableDiv, DisableableSpan } from '../emotion';
import { CloseIcon } from '../../icons';
import { Tooltip } from '../../components/ui/tooltip';
import styled from '@emotion/styled';

const FlexContainer = styled.div`
  display: flex;
`;
const DisableableSpanStyled = styled(DisableableSpan)`
  flex-grow: 1;
`;

interface IProps {
  expanded: boolean;
  slideTo: string;
  transitionName: string;
  panelWidth: number;
  transitionDuration: number;
  title: string;
  minWidth: number;
  maxWidth: number;
  onOpen: any;
  setPanelWidth: any;
  onClose: any;
  tooltip?: string;
  children: any;
}
interface IState {
  expanded: boolean;
  isResizing: boolean;
  panelWidth: number;
}

class ClosablePanel extends Component<IProps, IState> {
  state: IState;
  eventMouseUp;
  eventMouseMove;
  startingX;
  startingWidth;

  static defaultProps = {
    slideTo: 'left',
    expanded: true,
    title: 'Title',
    transitionDuration: 110,
    transitionName: 'closable-panel',
    panelWidth: VIZ.LEFT_PANEL_MIN_WIDTH,
    minWidth: VIZ.LEFT_PANEL_MIN_WIDTH,
    maxWidth: 1000,
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded,
      isResizing: false,
      panelWidth: props.panelWidth,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({ expanded: this.props.expanded });
    }

    // undo/redo can change props
    if (prevProps.panelWidth !== this.props.panelWidth) {
      this.setState({ panelWidth: this.props.panelWidth });
    }

    if (prevState.expanded && !this.state.expanded) {
      this._onClose();
    } else if (!prevState.expanded && this.state.expanded) {
      this._onOpen();
    }
  }

  componentDidMount() {
    // set up a global mouseup listener to handle mouseup outside the bounds of the table itself
    if (this.props.slideTo === 'left') {
      this.eventMouseUp = () => this.onMouseUp();
      this.eventMouseMove = event => this.onMouseMove(event);
      window.addEventListener('mouseup', this.eventMouseUp);
      window.addEventListener('mousemove', this.eventMouseMove);
    }
  }

  componentWillUnmount() {
    if (this.props.slideTo === 'left') {
      window.removeEventListener('mouseup', this.eventMouseUp);
      window.removeEventListener('mousemove', this.eventMouseMove);
    }
  }

  onMouseUp() {
    if (this.state.isResizing) {
      this.setResizing(false);
      if (this.state.panelWidth !== this.props.panelWidth) {
        this.props.setPanelWidth(this.state.panelWidth);
      }
    }
  }

  close() {
    this.setState({ expanded: false });
  }

  _onClose() {
    setTimeout(() => {
      if (_.isFunction(this.props.onClose)) {
        this.props.onClose();
      }
      // force a resize event when closing the panel to give anything on the screen a chance to account for it
      window.dispatchEvent(new Event('resize'));
    }, this.props.transitionDuration);
  }

  _onOpen() {
    setTimeout(() => {
      if (_.isFunction(this.props.onOpen)) {
        this.props.onOpen();
      }
      // force a resize event when opening the panel to give anything on the screen a chance to account for it
      window.dispatchEvent(new Event('resize'));
    }, this.props.transitionDuration);
  }

  renderHeader() {
    return (
      <FlexContainer className='panel-heading-content panel-title'>
        <DisableableSpanStyled>{this.props.title}</DisableableSpanStyled>
        <Tooltip
          id='tooltip2'
          key='tooltip2'
          arrow
          title={messages.close}
          placement='top'
        >
          <CloseIcon
            hover
            onClick={() => {
              this.close();
            }}
          />
        </Tooltip>
      </FlexContainer>
    );
  }

  handleMouseDown(event) {
    this.setResizing(true, event);
  }

  setResizing(isResizing, event?) {
    this.setState({ isResizing });
    if (isResizing && event) {
      // at the start of a resize, capture the current width of the resizable panel and starting x of the mouse
      const el: any = ReactDOM.findDOMNode(this);
      const panel = el.querySelector('.panel');
      this.startingX = event.clientX;
      this.startingWidth = panel.getBoundingClientRect().width;
    }
  }

  doResize(newWidth) {
    this.setState({ panelWidth: newWidth });
    try {
      window.dispatchEvent(new Event('resize'));
    } catch (e) {
      console.log(e);
    }
  }

  onMouseMove(event) {
    if (this.state.isResizing) {
      // find out where the mouse is now
      const resizeX = event.clientX;

      const howMuchTheMouseMoved = this.startingX - resizeX;
      const newWidth = Math.floor(this.startingWidth - howMuchTheMouseMoved);

      // don't allow to resize beyond the minWidth and maxWidth
      if (newWidth >= this.props.minWidth && newWidth <= this.props.maxWidth) {
        this.doResize(newWidth);
      }
    }
  }

  renderPanel() {
    let onMouseMove = null;
    let onMouseUp = null;
    if (this.props.slideTo === 'left') {
      onMouseMove = event => this.onMouseMove(event);
      onMouseUp = () => this.onMouseUp();
    }

    const panelWidth =
      this.props.slideTo === 'left'
        ? `${this.state.panelWidth}px`
        : `${this.props.minWidth}px`;

    // we no longer take advantage of bootstrap-react Panel due to the addition of the resize div, but we still use
    // the same bootstrap CSS
    return (
      <div
        className='panel panel-default'
        style={{ width: panelWidth }}
        key={`close-panel-key-${this.props.title}`}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
        <div className='panel-heading'>{this.renderHeader()}</div>
        <DisableableDiv className='panel-body'>
          <div className='closable-panel-body'>{this.props.children}</div>
        </DisableableDiv>
        {this.props.slideTo === 'left'
          ? [
              <div
                key='cp-left-resize-handle'
                className='closable-panel-resize'
                onMouseDown={event => this.handleMouseDown(event)}
              />,
            ]
          : []}
      </div>
    );
  }

  render() {
    // only animate when the panel is expanded or collapsed, by swapping in different keyed divs
    return (
      <TransitionGroup
        className={`closable-panel-${this.props.slideTo} ${
          this.state.expanded ? 'enter' : 'exit'
        }`}
      >
        <CSSTransition
          classNames={`${this.props.transitionName}-${this.props.slideTo}`}
          addEndListener={_.noop}
        >
          <div key='panel-show' className='panel-show'>
            {this.renderPanel()}
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default ClosablePanel;
