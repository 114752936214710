import styled from '@emotion/styled';
import { useDiscoverTheme } from '../../../../../common/emotion';
import { css } from '@emotion/react';

export const StyledSlicerGroupFlex = styled.div(() => {
  const {
    colors: { DefaultButtonFillColor, MediumBorder },
  } = useDiscoverTheme();

  return css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    border-radius: 2px;
    border: 1px solid ${MediumBorder};
    background-color: ${DefaultButtonFillColor};
    padding: 0.5rem;
  `;
});

export const StyledSlicerGroup = styled.div`
  display: inline-flex;
  gap: 8px;
  align-items: flex-start;
`;

export const StyledLabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
