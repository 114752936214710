import { BaseTemplates } from '../../common';
import _ from 'lodash';
import Moment from 'moment';
import { messages } from '../../i18n';

interface IComparisonMonitorTemplateInput {
  visualizations: { id: string }[];
  pct: string;
  metricName: string;
  monitorName: string;
  linkText: string;
  history: {
    push: (path: string) => void;
  };
  monitorEvent: {
    id: string;
    startTimestamp: number;
    endTimestamp: number;
  };
  up: boolean;
  monitorConfig: {
    labels: {
      targetReport;
      linkText;
    };
    comparisonPeriodType;
    comparisonPeriodNum;
  };
  payload: {
    group: string;
  };
  currentPeriodValue: number;
  previousPeriodValue: number;
  dateTimeFormats: any;
}

const getTemplateValues = (props?: IComparisonMonitorTemplateInput) => {
  if (!props) {
    return {};
  }
  let {
    visualizations = [],
    pct: percentChange,
    metricName,
    monitorName,
    monitorEvent: { id, startTimestamp, endTimestamp },
    history,
    up,
    monitorConfig: {
      labels: { targetReport, linkText },
      comparisonPeriodType,
      comparisonPeriodNum,
    },
    payload: { group },
    currentPeriodValue,
    previousPeriodValue: priorPeriodValue,
    dateTimeFormats: { dateFormat = 'L' } = {},
  } = props || {};
  const linkedContent = _.find(visualizations, _.matches({ id: targetReport }));
  comparisonPeriodNum = Math.floor(comparisonPeriodNum);
  if (comparisonPeriodType && comparisonPeriodNum !== 1) {
    comparisonPeriodType += 's';
  }
  const [segment, segmentValue] =
    _(group)
      .toPairs()
      .head() || [];
  function openConnectedContent() {
    history.push(`/event/${id}`);
  }

  comparisonPeriodType = _.toLower(comparisonPeriodType);
  const [previousPeriodStart, previousPeriodEnd] = [
    startTimestamp,
    endTimestamp,
  ].map(ts => Moment(ts).format(dateFormat));

  if (linkedContent && linkText) {
    linkText = <a onClick={() => openConnectedContent()}>{linkText}</a>;
  }

  return {
    monitorName: linkedContent ? (
      <a onClick={() => openConnectedContent()}>{monitorName}</a>
    ) : (
      <strong>{monitorName}</strong>
    ),
    linkText,
    segment,
    segmentValue,
    upDown: up ? messages.up : messages.down,
    percentChange,
    metricName,
    previousPeriodStart,
    previousPeriodEnd,
    currentPeriodValue,
    priorPeriodValue,
    comparisonPeriodNum,
    comparisonPeriodType,
  };
};

const getBaseComparisonMonitorTemplates = (
  props?: IComparisonMonitorTemplateInput,
) => {
  const {
    monitorName,
    linkText,
    segment,
    segmentValue,
    upDown,
    percentChange,
    metricName,
    currentPeriodValue,
    priorPeriodValue,
    comparisonPeriodNum,
    comparisonPeriodType,
    previousPeriodStart,
    previousPeriodEnd,
  } = getTemplateValues(props);

  return {
    ...BaseTemplates,
    ..._.mapKeys(
      {
        monitorName,
        linkText,
        segment,
        segmentValue,
        upDown,
        percentChange,
        metricName,
        priorPeriodValue,
        currentPeriodValue,
        comparisonPeriodNum,
        comparisonPeriodType,
        previousPeriodStart,
        previousPeriodEnd,
      },
      (v, k) => `{${_.snakeCase(k)}}`,
    ),
  };
};

const getSmsComparisonMonitorTemplates = (
  props?: IComparisonMonitorTemplateInput,
) => {
  const {
    monitorName,
    segment,
    segmentValue,
    metricName,
    upDown,
    percentChange,
    currentPeriodValue,
    priorPeriodValue,
  } = getTemplateValues(props);

  return {
    ...BaseTemplates,
    ..._.mapKeys(
      {
        monitorName,
        segment,
        segmentValue,
        metricName,
        upDown,
        percentChange,
        priorPeriodValue,
        currentPeriodValue,
      },
      (v, k) => `{${_.snakeCase(k)}}`,
    ),
  };
};

export const getSmsTemplates = getSmsComparisonMonitorTemplates;
export const getTitleTemplates = getBaseComparisonMonitorTemplates;
export const getBodyTemplates = _.flow(
  getBaseComparisonMonitorTemplates,
  templates =>
    _.assign(templates, {
      '{metric_name}': <strong>{templates['{metric_name}']}</strong>,
    }),
);
