import {
  ApolloClient,
  InMemoryCache,
  Observable,
  Operation,
} from '@apollo/client';
import { ApolloLink, from, split } from '@apollo/client/link/core';
import { MockLink } from '@apollo/client/testing';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { Reference } from '@apollo/client/utilities';
import Main from './redux/actions/MainActions';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import {
  AuthHeadersApolloLink,
  getAuthHeaders,
  getAuthToken,
  handleNewAccessToken,
  isDashletMode,
} from '../auth';
import _ from 'lodash';
import fragmentTypes from './fragmentTypes.json';
import { setClient, versionAndDispatchHolder } from './ApolloClient.common';
import { getConfig } from '../common/global-discover-config';
import { getFrontendUrl, IDM_TOKEN_HEADER_NAME } from './Constants';
import URLs from './Urls';
import { setContext } from '@apollo/client/link/context';

const { wsUri } = getConfig();

const httpLink = createHttpLink({
  uri: '/api/graphql',
  headers: {},
  fetch: (input, init) => {
    if (isDashletMode()) {
      input = URLs.joinUrls(getFrontendUrl(), input as string);
    }
    return fetch(input, init);
  },
});

const _global: any =
  typeof global !== 'undefined'
    ? global
    : typeof window !== 'undefined'
    ? window
    : {};
const NativeWebSocket =
  _global.WebSocket || _global.MozWebSocket || class NoOp {};

/**
 * Our server implementation does not accept the graphql subprotocol, we subclass the native implementation to
 * prevent the sending of the subprotocol to the server.
 */
class SubProtocolIgnorantWebSocket extends NativeWebSocket {
  constructor(url) {
    super(url);
  }
}

/**
 * Subclass of the default cache which ignores subscription result. Cache reconciliation is slow and the items
 * presently place within are never used by another query.
 */
class SubscriptionIgnoringCache extends InMemoryCache {
  write(bundle): Reference {
    // We never store the results of a subscription in the cache (it's just too slow and never used)
    if (
      bundle.query.definitions.length > 0 &&
      bundle.query.definitions[0].operation === 'subscription'
    ) {
      return;
    }
    super.write(bundle);
  }
}

const getWsLink = () => {
  // development environments can ignore websocket links
  if (wsUri === 'ws://no-op') {
    return new MockLink([]);
  }

  return wsLink;
};

let wsLink: any;

try {
  wsLink = new WebSocketLink({
    uri: wsUri,
    reconnect: true,
    connectionParams: {
      // Pass any arguments you want for initialization
    },
    webSocketImpl: SubProtocolIgnorantWebSocket,
  });
} catch (_e) {
  // console.log('wsLink error', _e);

  // Provide mock. WebSockets can be blocked based on Sugar's Content Security Policy, etc.
  wsLink = new MockLink([]);
}

const retryQueue: Operation[] = [];
const refresh = async (): Promise<Response | string> => {
  if (isDashletMode()) {
    return getAuthToken({ shouldRefresh: true });
  }
  return fetch('/api/auth/refresh', {
    credentials: 'include',
    headers: await getAuthHeaders({}),
  }).then(res => {
    if (res?.status === 200) {
      return res?.headers?.get(IDM_TOKEN_HEADER_NAME);
    } else {
      console.error('Failed to refresh token', res);
      throw new Error('Failed to refresh token');
    }
  });
};

const authLink = setContext(async () => {
  const { wsUri: uri } = await getConfig();
  if (_.has(wsLink, 'subscriptionClient')) {
    (wsLink as any).subscriptionClient.url = uri;
  }
});

const errorLink = onError(({ networkError, operation, forward }) => {
  const status = (networkError as any)?.statusCode;
  if (status === 401) {
    console.error(
      'Apollo Network Error: Forbidden (401). Redirecting to login',
    );
    retryQueue.push(operation);
    if (retryQueue.length == 1) {
      return new Observable(observer => {
        refresh()
          .then(res => {
            if (_.isString(res)) {
              return res;
            } else if (res?.status === 200) {
              return res?.headers?.get(IDM_TOKEN_HEADER_NAME);
            }
          })
          .then(newToken => {
            if (newToken) {
              handleNewAccessToken(newToken);
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              while (retryQueue.length !== 0) {
                const op = retryQueue.pop();
                op.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    'X-IDM-ACCESS-TOKEN': newToken,
                  },
                }));
                try {
                  forward(op).subscribe(subscriber);
                } catch (e) {
                  console.error('error retrying operation', e);
                }
              }
            } else {
              versionAndDispatchHolder.dispatch(Main.unAuthedLogout());
            }
          })
          .catch(error => {
            console.error(error);
            versionAndDispatchHolder.dispatch(Main.unAuthedLogout());
          });
      });
    }
  } else if (status === 500) {
    versionAndDispatchHolder.dispatch(Main.internalServerError());
  } else if (networkError) {
    console.error('Network Error:', networkError);
  }
  if (operation?.query) {
    console.debug('Failed GraphQL query:', operation.query.loc.source.body);
    console.debug(
      'Failed GraphQL query variables:',
      JSON.stringify(operation.variables, null, 2),
    );
  }
});

// use with apollo-client
const versionMiddleware = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const version = context.response.headers.get('corvana-version');
    const existingVersion = versionAndDispatchHolder.version;
    if (!existingVersion) {
      versionAndDispatchHolder.version = version;
    } else if (existingVersion !== version) {
      versionAndDispatchHolder.dispatch(Main.showAppOutOfDate());
    }
    const {
      response: { headers },
    } = context;

    if (headers) {
      // handles refreshed access tokens
      const oauthToken = headers.get(IDM_TOKEN_HEADER_NAME);

      if (!_.isEmpty(oauthToken)) {
        handleNewAccessToken(oauthToken);
      }
    }

    return response;
  });
});

const possibleTypes = {};
fragmentTypes.data.__schema.types.forEach(supertype => {
  if (supertype.possibleTypes) {
    possibleTypes[supertype.name] = supertype.possibleTypes.map(
      subtype => subtype.name,
    );
  }
});

const client = new (class extends ApolloClient<any> {
  setDispatch(dispatch) {
    versionAndDispatchHolder.dispatch = dispatch;
  }
})({
  link: from(
    [
      AuthHeadersApolloLink,
      errorLink,
      authLink,
      split(
        // split based on operation type
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query) as any;
          return kind === 'OperationDefinition' && operation === 'subscription';
        },
        getWsLink(),
        from([versionMiddleware, httpLink]),
      ),
    ].filter(Boolean),
  ),
  cache: new SubscriptionIgnoringCache({
    typePolicies: {
      Shelf: {
        keyFields: ['id', 'fields'],
      },
    },
    possibleTypes,
  }).restore((window as any).__APOLLO_STATE__),
} as any);
setClient(client);
export { client, versionAndDispatchHolder };
