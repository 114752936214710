import { css } from '@emotion/react';
import { font, svgColorizePartial } from '../mixins';

export const discoverMobileStyles = ({
  isDashletMode,
  theme: {
    colors: {
      MediumFontWeight,
      ContentText,
      CorvanaMediumBorder,
      LightFontWeight,
      ContentBackground,
      TabHighlight,
      BaseWhite,
      CorvanaButtonIconColor,
      DefaultButtonTextColor,
    },
  },
}) => css`
  #discovery-toolbar {
    display: none;
  }
  #discover-content-panel,
  .discover-open-panel,
  .discover-tab-panel,
  .viz-container,
  .viz-detail,
  .content,
  .viz-chart,
  .viz-chart-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: 100%;
    ${isDashletMode ? 'max - width: 100%;' : ''}
  }
  .discover-tab-panel {
    width: 100%;
  }
  .viz-detail .viz-chart-top-panel,
  .monitor-detail .viz-chart-top-panel,
  .viz-detail .right-panel,
  .monitor-detail .right-panel,
  .viz-detail .left-panel,
  .monitor-detail .left-panel {
    display: none;
  }
  .viz-detail > .content .viz-chart .x-axis-label,
  .monitor-detail > .content .viz-chart .x-axis-label,
  .viz-detail > .content .viz-chart .y-axis-label,
  .monitor-detail > .content .viz-chart .y-axis-label {
    ${font({ size: '14px', weight: MediumFontWeight })}
    text-anchor: middle;
    fill: ${ContentText};
  }
  .viz-detail > .content .viz-chart .xAxisTickFont .tick line,
  .monitor-detail > .content .viz-chart .xAxisTickFont .tick line,
  .viz-detail > .content .viz-chart .xAxisTickFont .domain,
  .monitor-detail > .content .viz-chart .xAxisTickFont .domain {
    stroke: ${ContentText};
    opacity: 0.48;
  }
  .viz-detail > .content .viz-chart .xAxisTickFont.mutedLine .domain,
  .monitor-detail > .content .viz-chart .xAxisTickFont.mutedLine .domain,
  .viz-detail > .content .viz-chart .yAxisTickFont.mutedLine .domain,
  .monitor-detail > .content .viz-chart .yAxisTickFont.mutedLine .domain {
    stroke: ${CorvanaMediumBorder};
  }
  .viz-detail > .content .viz-chart .xAxisTickFont .tick text,
  .monitor-detail > .content .viz-chart .xAxisTickFont .tick text,
  .viz-detail > .content .viz-chart .yAxisTickFont .tick text,
  .monitor-detail > .content .viz-chart .yAxisTickFont .tick text {
    ${font({ size: '11px', weight: LightFontWeight })}
    fill: ${ContentText};
  }
  .viz-container {
    max-width: 100%;
  }
  .content {
    background-color: ${ContentBackground};
  }
  .viz-chart-error-message {
    width: calc(100% - 32px);
  }
  .viz-legend {
    flex-direction: row;
    overflow: hidden;
    height: 40px;
    padding: 0;
  }
  .legend-items {
    overflow-y: hidden;
    overflow-x: overlay;
    display: flex;
    flex-direction: row;
  }
  .legend-items .legend-item {
    padding: 8px;
    align-items: center;
  }
  .legend-items .pivot-legend-item .legend-label {
    margin-right: 8px;
  }
  .legend-items .pivot-legend-item .legend-min-max {
    display: flex;
    align-items: center;
  }
  .legend-items .pivot-legend-item .legend-color-scale {
    margin: 0px 8px;
    width: 64px;
  }
  .viz-legend-panel {
    border: none;
    overflow-x: hidden;
    height: auto;
  }
  .discover-tab-panel .nav-tabs {
    display: flex;
    overflow: auto hidden;
  }
  .discover-tab-panel .nav-tabs > li > a {
    display: flex;
    border: 1px solid transparent;
  }
  .discover-tab-panel .nav-tabs > li.active > a,
  .discover-tab-panel .nav-tabs > li.active > a:focus,
  .discover-tab-panel .nav-tabs > li.active > a:hover {
    border: 1px solid transparent;
    border-color: transparent;
    border-bottom: 3px solid ${TabHighlight};
  }
  .discover-tab-panel .closeDiscovery {
    margin-left: 10px;
    opacity: 0;
  }
  .discover-tab-panel .active .closeDiscovery {
    opacity: 1;
  }
  .viz-detail .mobile-viz-footer {
    border-color: ${CorvanaMediumBorder};
    background-color: ${BaseWhite};
  }
  .viz-detail .mobile-viz-footer .more-kabob {
    cursor: pointer;
  }
  ${svgColorizePartial({
    fromColor: CorvanaButtonIconColor,
    toColor: DefaultButtonTextColor,
  })('.viz-detail .mobile-viz-footer .more-kabob')}
  .transition-wrapper .header-navigation {
    position: inherit;
  }
  .active-filters {
    flex: none;
    color: ${ContentText};
    align-items: center;
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    ${isDashletMode ? '' : 'margin-bottom: 1em;'}
    flex-flow: row;
    padding-left: 8px;
  }
  .active-filters .title {
    white-space: nowrap;
    margin-right: 0.25em;
  }
  .active-filters .filter-item {
    white-space: nowrap;
    pointer-events: none;
  }
  .active-filters .filter-item.dashlet-pointer {
    pointer-events: visiblePainted;
    cursor: default;
  }
  .funnel-delta {
    font-size: 12px;
  }
`;
