import { DropTarget } from 'react-dnd';
import { DnDTypes } from '../common/Constants';
import Discover from '../common/redux/actions/DiscoverActions';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import shortid from 'shortid';
import _ from 'lodash';
import { Viz } from './VizUtil';

const ShelfDropLocation = ({
  isOver,
  canDrop,
  connectDropTarget,
  children,
}) => {
  return connectDropTarget(
    <div>
      <div
        className={`shelf-drop-location ${
          isOver && canDrop ? 'drop-valid' : ''
        }`}
      />
      {children}
    </div>,
  );
};
const sdlTarget = {
  drop(props, monitor) {
    // if the field is already on a shelf, remove it
    const reduxTransactionId = `drop${shortid.generate()}`;
    let { field, shelf: fromShelf } = monitor.getItem();
    const { shelf: toShelf } = props;
    if (!_.isEqual(toShelf?.id, 'SLICER') && !_.isEmpty(field.children)) {
      // set the field we are dropping to the first one not in-play in of the children
      let firstOneLeft;
      field.children.some(child => {
        const isInPlay = Viz.isFieldInPlay(child.name, props.layout);
        if (_.isNil(isInPlay)) {
          firstOneLeft = child;
          return true;
        }
        return false;
      });
      field = _.isNil(firstOneLeft) ? field : firstOneLeft;
    }

    const from = Viz.findShelvesContainingField(props.layout, field.name);
    const fromWithoutSlicer = _.without(from, 'SLICER');
    const fieldOnlyOnSlicer = _.isEmpty(fromWithoutSlicer);
    const draggedFromSlicer = fromShelf?.id === 'SLICER';
    const draggedToSlicer = props.shelf.id === 'SLICER';
    const movingInSameShelf =
      !_.isNil(fromShelf) && fromShelf?.id === props.shelf?.id;

    let moveToShelf = _.head(fromWithoutSlicer);

    if (
      (fieldOnlyOnSlicer ||
        (draggedFromSlicer && fieldOnlyOnSlicer) ||
        draggedToSlicer) &&
      !movingInSameShelf
    ) {
      props.addField(
        field,
        props.shelf.id,
        props.index,
        reduxTransactionId,
        props.layout,
      );
    } else {
      if (movingInSameShelf) {
        moveToShelf = props.shelf.id;
      }
      props.moveFieldToAnotherShelf(
        field,
        moveToShelf,
        props.shelf.id,
        props.index,
        reduxTransactionId,
      );
    }
  },
  canDrop(props, monitor) {
    const { field, shelf: fromShelf } = monitor.getItem();
    const shelfId = props.shelf.id;
    const { layout } = props;

    const validFieldForShelf = props.chartSpec.validateFieldForShelf(
      field,
      shelfId,
      layout,
      fromShelf,
    );
    const currentIndexInShelf = props.layout[shelfId].findIndex(
      f => f.name === field.name,
    );

    if (currentIndexInShelf > -1) {
      const isValidLocationWithinTheShelf =
        currentIndexInShelf !== props.index &&
        currentIndexInShelf + 1 !== props.index;
      return validFieldForShelf && isValidLocationWithinTheShelf;
    }
    return validFieldForShelf;
  },
};
const sdlCollect = (connectComponent, monitor) => {
  return {
    connectDropTarget: connectComponent.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
};
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addField: (field, shelf, index, reduxTransactionId, layout) => {
      // if the field is a time attribute, make sure it is not a more granular attribute than the lowest in any prior period calc field already in play
      const priors = Viz.priorPeriodCalcsFieldInvalidates(field, layout);
      if (!_.isEmpty(priors)) {
        // You are adding a time attribute that will invalidate a prior period calculation, continue?
        dispatch(
          Discover.showConfirmAddField(
            ownProps.discoveryId,
            field,
            shelf,
            priors,
            index,
          ),
        );
      } else {
        dispatch(
          Discover.addFieldToVisualization(
            ownProps.discoveryId,
            field,
            shelf,
            index,
            reduxTransactionId,
          ),
        );
      }
    },
    removeField: (field, shelf, reduxTransactionId) => {
      dispatch(
        Discover.removeFieldFromVisualization(
          ownProps.discoveryId,
          field,
          shelf,
          reduxTransactionId,
        ),
      );
    },
    moveFieldToAnotherShelf: (
      field,
      fromShelf,
      toShelf,
      index,
      reduxTransactionId,
    ) => {
      dispatch(
        Discover.moveFieldToAnotherShelf(
          ownProps.discoveryId,
          field,
          fromShelf,
          toShelf,
          index,
          reduxTransactionId,
        ),
      );
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  DropTarget(DnDTypes.VIZ_FIELD, sdlTarget, sdlCollect),
)(ShelfDropLocation);
