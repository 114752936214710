import { IQueryFilter } from '../../datasets';
import { ICustomFormatToggle } from './custom-format-toggle.interface';
import { ChartType, IViz, IVizBase } from './viz.interface';
import { IQueryWithCalc } from './calc.interface';
import { ShelfName } from '../../common';

export type IActionListener = (...args: any[]) => any;

export type ValidationFunction = (
  viz: IVizBase,
  spec: IBaseChartSpec,
) => boolean | void;

export interface ILimit {
  min: number;
  max: number;
}

export interface IChartShelf {
  accepts?: string[];
  id: ShelfName;
  name: string;
  tooltip?: string;
  shelfType: string;
  aggregateMeasures?: boolean;
  isColumn?: boolean;
  groupNames?: boolean;
  limits?: ILimit;
  requiresOrdinal?: boolean;
  isRequired?: boolean;
}
export interface IQuerySubtotal {
  attributeNames: string[];
}

export interface IQueryWithSubtotals {
  subtotals?: IQuerySubtotal[];
}
export interface IQueryVariables {
  attributeNames?: string[];
  measures?: IMeasure[];
  useFiscalCalendar?: boolean;
  bypassCache?: boolean;
}

export interface IQueryWithFilters {
  filters?: IQueryFilter[];
}

export interface IQueryWithSorts {
  sorts?: any[];
}

export type IQueryCommon = IQueryWithCalc &
  IQueryWithFilters &
  IQueryWithSorts &
  IQueryWithSubtotals &
  IQueryVariables;

export type IQuery = IQueryCommon & {
  id: string;
};

export type IGqlQuery = IQueryCommon & {
  datasetId: string;
};

export enum ShelfTypes {
  MEASURE = 'MEASURE',
  SELECTION = 'SELECTION',
  SLICER = 'SLICER',
}

export interface IBaseChartSpec {
  id: ChartType;
  name: string;
  hidden?: boolean;
  placeholderImage: string;
  canScroll?: boolean;
  shelves: { [K in ShelfName]?: IChartShelf };
  validationRules?: ValidationFunction[];
  legendShape: string;
  icon: JSX.Element;
  requiredFields?: boolean;
  listIcon: JSX.Element;
  supportsSummary?: boolean;
  isToggleDisabled?: (string, IViz) => boolean;
  customFormatToggles?: ICustomFormatToggle[];
  supportsPriorPeriodMetrics?: boolean;
  supportsConditionalFormatting?: boolean;
  supportsLayoutPanelSort?: boolean;
  supportsDifferentiatedNullHandling?: boolean;
  actionListeners?: IActionListener[];
  supportsLegendSelection?: boolean;
  showTooltipIndicator?: boolean;
  xAxisOrient?: string;
  hideXAxisTickLabels?: boolean;
  summaryOrientation?: string;
  mapCalcsToQuery?: (viz: IVizBase) => IQueryWithCalc;
  mapSubtotalsToQuery?: (viz: IVizBase) => IQueryWithSubtotals;
  mapShelvesToQueryVariables?: (viz: IVizBase) => IQueryVariables;
  buildSecondaryQueryVariables?(viz: IVizBase, args): any;
  isAdvancedModeOnly?: boolean;
  rowRenderThreshold?: number;
  shouldDeferToExport(queryResults: any): boolean;
  copyLayoutToViz(viz: Partial<IViz>, nextChartType?: string): void;
}

export interface IMeasure {
  attributeName?: string;
  aggregation?: string;
  resultSetFunction?: string;
}
