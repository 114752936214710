import { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { createFilterForField, FilterTypes } from './Filter';
import Discover from '../../common/redux/actions/DiscoverActions';
import FilterExpression from './FilterExpression';
import { messages } from '../../i18n';
import { IFilter } from '../../datasets/interfaces/filter.interface';

class NumericFilter extends Component<
  { filter: IFilter; changeFilter: any },
  {}
> {
  onExpressionChanged(expression) {
    const filter = { ...this.props.filter, expression: { ...expression } };
    this.props.changeFilter(filter);
  }
  render() {
    return (
      <div className='filter-content numeric-filter'>
        <div className='section-label'>{messages.filters.setCondition}</div>
        <div className='filter-panel filter-condition'>
          <div className='numeric-condition'>
            <FilterExpression
              expression={this.props.filter.expression}
              onChange={exp => this.onExpressionChanged(exp)}
              filterType={FilterTypes.NUMERIC}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { filter } = ownProps;
  const emptyFilter = createFilterForField(ownProps.field);
  if (_.isNil(filter)) {
    filter = emptyFilter;
  }
  return {
    filter,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeFilter(filter) {
      dispatch(Discover.setActiveFieldFilter(filter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NumericFilter);
