import _ from 'lodash';
import { Hierarchy } from '../../discovery/VizUtil';

const { TIME_ATTRIBUTES } = Hierarchy;

export const DEFAULT_TIME_HIERARCHY_OPTIONS = {
  YQM: {
    text: 'timeHierarchyMenuItem.yqm',
    fiscalText: 'timeHierarchyMenuItem.fyqm',
    attributes: [
      TIME_ATTRIBUTES.YEAR,
      TIME_ATTRIBUTES.QTR,
      TIME_ATTRIBUTES.MONTH,
    ],
  },
  YQMD: {
    text: 'timeHierarchyMenuItem.yqmd',
    fiscalText: 'timeHierarchyMenuItem.fyqmd',
    attributes: [
      TIME_ATTRIBUTES.YEAR,
      TIME_ATTRIBUTES.QTR,
      TIME_ATTRIBUTES.MONTH,
      TIME_ATTRIBUTES.DAY,
    ],
  },
  YQMDH: {
    text: 'timeHierarchyMenuItem.yqmdh',
    fiscalText: 'timeHierarchyMenuItem.fyqmdh',
    attributes: [
      TIME_ATTRIBUTES.YEAR,
      TIME_ATTRIBUTES.QTR,
      TIME_ATTRIBUTES.MONTH,
      TIME_ATTRIBUTES.DAY,
      TIME_ATTRIBUTES.HOUR,
    ],
    hidden: true,
  },
  YW: {
    text: 'timeHierarchyMenuItem.yw',
    fiscalText: 'timeHierarchyMenuItem.fyw',
    attributes: [TIME_ATTRIBUTES.YEAR, TIME_ATTRIBUTES.WEEK],
  },
  YQW: {
    text: 'timeHierarchyMenuItem.yqw',
    fiscalText: 'timeHierarchyMenuItem.fyqw',
    attributes: [
      TIME_ATTRIBUTES.YEAR,
      TIME_ATTRIBUTES.QTR,
      TIME_ATTRIBUTES.WEEK_IN_QTR,
    ],
  },
};

export const FISCAL_HIERARCHY_LABEL_KEYS = [
  'YQM',
  'YQMD',
  'YQMDH',
  'YW',
  'YQW',
];

export const timeHierarchyOptions = Object.entries(
  DEFAULT_TIME_HIERARCHY_OPTIONS,
).filter(([, option]) => {
  return !_.get(option, 'hidden', false);
});

export const getHierarchyNonHiddenAttributes = () =>
  Object.values(Hierarchy.TIME_ATTRIBUTES).filter(a => !a.hidden);
