export interface IDashletRoute {
  route: string;
  entryPoint?: string;
}

export const BaseDashletRoute = '/dashlet';

export enum DASHLET_MODE {
  LIBRARY = 'Library',
  REPORT = 'Report',
  BASE = 'Base',
}

export const DashletRouteDefinitions = {
  [DASHLET_MODE.LIBRARY]: {
    route: `${BaseDashletRoute}/library`,
    entryPoint: 'discoverLibraryEntryPoint',
  },
  [DASHLET_MODE.REPORT]: {
    route: `${BaseDashletRoute}/report`,
    entryPoint: 'discoverReportEntryPoint',
  },
  [DASHLET_MODE.BASE]: {
    route: BaseDashletRoute,
  },
};

export type DashletMode = keyof typeof DashletRouteDefinitions;
