import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledMainSectionHeader = styled.div(
  ({
    theme: { colors: { ContentText, ContentBackground, Gray20 } } = {},
  }) => css`
    color: ${ContentText};
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 24px;
    border-bottom: 1px solid ${Gray20};
    background-color: ${ContentBackground};
  `,
);
