import { css } from '@emotion/react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classnames from 'classnames';
import { SugarIcon } from '../../icons';

const Chevron = props => (
  <SugarIcon
    css={css`
      margin-right: 5px;
    `}
    icon='chevron-left-xl'
    hover
    {...props}
  />
);

const LinkButton = ({ children, className, ...passthrough }) => {
  return (
    <div
      className={classnames(className, 'header-navigation__link-button')}
      {...passthrough}
    >
      {children}
    </div>
  );
};

class component extends Component {
  render() {
    const { title, left, right, ...passthrough } = this.props;
    return (
      <div className='header-navigation' {...passthrough}>
        <div className='header-navigation__left'>
          {left({ LinkButton, Chevron })}
        </div>
        <div className='header-navigation__title'>{title}</div>
        <div className='header-navigation__right'>{right({ LinkButton })}</div>
      </div>
    );
  }
}

component.propTypes = {
  title: PropTypes.string,
  left: PropTypes.func,
  right: PropTypes.func,
};

component.defaultProps = {
  title: '',
  left: noop,
  right: noop,
};

export default component;
