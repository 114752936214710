import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Tab from '@mui/material/Tab';
import {
  VizTabNavHeight,
  ellipsis,
  useDiscoverTheme,
} from '../../common/emotion';

export const CloseDiscovery = styled.div`
  opacity: 0;
  position: absolute;
  right: 8px;
`;

export const TabStyled = styled(Tab)(() => {
  const {
    darkMode,
    colors: {
      Gray90,
      LightBlue,
      SemiboldFontWeight,
      ActiveTabTextColor,
      ContentText,
    },
  } = useDiscoverTheme();

  return css`
    text-transform: none;
    font-size: 0.6875rem;
    line-height: 1.2;
    font-weight: ${SemiboldFontWeight};
    min-height: 32px;
    padding: 9px 24px;
    color: ${darkMode ? ContentText : Gray90};
    min-width: 0;
    max-width: 200px;
    &:hover {
      background: ${LightBlue};
      ${CloseDiscovery} {
        opacity: 1;
      }
    }
    &.Mui-selected {
      color: ${ActiveTabTextColor};
      @media (max-width: 808px) {
        ${CloseDiscovery} {
          opacity: 1;
        }
      }
    }
  `;
});

export const DiscoveryTabText = styled.div`
  ${ellipsis()};
  width: 100%;
`;

export const DiscoveryDirtyIndicator = styled.div`
  position: absolute;
  left: 8px;
`;

export const AddDiscovery = styled.button(() => {
  const {
    colors: { TabBackground },
  } = useDiscoverTheme();

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${TabBackground};
    height: ${VizTabNavHeight}px;
    width: 56px;
    padding: 0 20px;
    border: none;
  `;
});
