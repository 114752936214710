import { useMemo } from 'react';
import moment from './Moment';
import { useDatetimeFormatter } from './formatting/datetime/datetime.hook';

const FormattedDate = ({ dateMillis }) => {
  const { dateTimeFormat } = useDatetimeFormatter();
  const formatted = useMemo(() => {
    const date = new Date(dateMillis);
    const m = moment(date);
    return m.format(dateTimeFormat);
  }, [dateTimeFormat, dateMillis]);
  return formatted;
};

const TimeUtils = {
  today: new Date(),
  yesterday: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  /**
   * Based on new Year, Month, Date
   **/
  isToday(d) {
    return TimeUtils.isSameDay(TimeUtils.today, d);
  },
  wasYesterday(d) {
    return TimeUtils.isSameDay(TimeUtils.yesterday, d);
  },
  isSameDay(d1, d2) {
    return (
      d1.getYear() === d2.getYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  },
  wasPastWeek(d) {
    return (
      d.getTime() > new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
    );
  },
  wasPreviousYear(d) {
    return TimeUtils.today.getYear() !== d.getYear();
  },
  formatDate: dateMillis => <FormattedDate dateMillis={dateMillis} />,
};

export default TimeUtils;
