import BaseInsightComponent from '../Common';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';

const ErrorComponent = ({ monitorEvent, advanced }) => {
  if (!advanced) {
    return null;
  }

  const { eventData } = monitorEvent;
  const { message, segmentQuery } = eventData;

  return (
    <BaseInsightComponent title={'Insight Error'}>
      <h3>Insight Error</h3>
      <p>
        Message:
        <br />
        <pre>{message}</pre>
      </p>
      <p>
        Segment Configuration:
        <br />
        <pre>{JSON.stringify(segmentQuery, null, '\t')}</pre>
      </p>
    </BaseInsightComponent>
  );
};

ErrorComponent.supports = function(payload) {
  return (
    payload.monitorEvent.insightType &&
    payload.monitorEvent.insightType === 'ERROR'
  );
};

const mapStateToProps = state => {
  return {
    advanced: state.main.advanced,
  };
};

export default compose(connect(mapStateToProps))(ErrorComponent);
