import { Component } from 'react';
import { Modal } from '@sugar-discover/react-bootstrap-wrapper';
import {
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
} from '../../../ui/button';
import { messages } from '../../../i18n';
import styled from '@emotion/styled';
import {
  SelectDropdown,
  SelectItem,
} from '../../../ui/dropdowns/select-dropdown';
import _, { map } from 'lodash';
import { IVizReportEditing } from '../../../discovery/interfaces';
import { IDataset } from '../../../datasets';
import uuid from 'uuid';

const ImportReportsTable = styled.table`
  margin: 12px auto;
  min-width: 600px;
`;

interface IVizReportEditingTracked extends IVizReportEditing {
  trackingKey: string;
  hasUpdated?: boolean;
}

interface IVizReportEditingRequiresInput {
  newRpt: IVizReportEditingTracked;
  existingRpt: IVizReportEditingTracked;
}

interface IPropsTableRow {
  report: IVizReportEditingTracked;
  doUpdateReport: any;
  datasets: IDataset[];
  originalName: String;
}

interface IStateTableRow {
  actionText?: any;
  updatingReport: IVizReportEditingRequiresInput;
  deletingReport?: any;
}

class ImportReportsTableRow extends Component<IPropsTableRow, IStateTableRow> {
  state;
  props;

  constructor(props) {
    super(props);

    const _updatingReport = props.report;

    this.state = {
      updatingReport: _updatingReport,
      reportToDelete: {},
    };
  }

  onDatasetChange(datasetId, datasetName) {
    this.setState(
      {
        updatingReport: {
          ...this.state.updatingReport,
          datasetName,
          datasetId,
          needsDataset: false,
        },
      },
      () => {
        this.props.doUpdateReport(this.state.updatingReport);
      },
    );
  }

  render() {
    const { datasets, originalName } = this.props;
    const {
      updatingReport: {
        newRpt: { datasetName, id, needsDataset, newName, needsName },
      },
      actionText,
    } = this.state;

    const dropdownOptions = map(datasets, ({ id, name }) => (
      <SelectItem
        onClick={() => {
          this.onDatasetChange(id, name);
        }}
      >
        {name}
      </SelectItem>
    ));

    return (
      <tr key={id}>
        <td>{originalName}</td>
        <td>{newName}</td>
        <td className={'import-records-dropdown'}>
          {needsDataset ? (
            <SelectDropdown title={datasetName ?? messages.account.useDefault}>
              {dropdownOptions}
            </SelectDropdown>
          ) : (
            datasetName
          )}
        </td>
        <td>
          {needsName ? (
            <ButtonContainer>
              <SecondaryButton
                key='yes'
                onClick={() => {
                  const rpt = _.cloneDeep(this.state.updatingReport.newRpt);
                  const delRpt = _.cloneDeep(
                    this.state.updatingReport.existingRpt,
                  );
                  rpt.id = uuid();
                  rpt.needsName = false;
                  this.setState(
                    {
                      updatingReport: {
                        newRpt: rpt,
                        existingRpt: delRpt,
                      },
                      deletingReport: delRpt,
                      actionText: messages.library.replaceReportText,
                    },
                    () => {
                      this.props.doUpdateReport(this.state.updatingReport);
                      this.props.doUpdateDeleteReport(
                        this.state.deletingReport,
                      );
                    },
                  );
                }}
                className='promptReplace'
              >
                {messages.library.replaceButtonText}
              </SecondaryButton>
              <PrimaryButton
                key='keep'
                onClick={() => {
                  const rpt = _.cloneDeep(this.state.updatingReport.newRpt);
                  rpt.id = uuid();
                  rpt.name = rpt.newName;
                  rpt.needsName = false;
                  this.setState(
                    {
                      updatingReport: {
                        ...this.state.updatingReport,
                        newRpt: rpt,
                      },
                      actionText: messages.library.keepBothReportsText,
                    },
                    () => {
                      this.props.doUpdateReport(this.state.updatingReport);
                    },
                  );
                }}
                className='promptKeep'
              >
                {messages.library.keepBothButtonText}
              </PrimaryButton>
            </ButtonContainer>
          ) : (
            <span>{actionText}</span>
          )}
        </td>
      </tr>
    );
  }
}

interface IDialogProps {
  reportsRequiringInput: IVizReportEditingRequiresInput[];
  reportsNotRequiringInput: IVizReportEditing[];
  className: string;
  datasets: IDataset[];
  show: boolean;
  customClass: string;
  doSave: any;
  doCancel: any;
  cancelText: string;
}

interface IDialogState {
  saveDisabled: boolean;
  updatingReports: IVizReportEditingRequiresInput[];
  deletingReports: IVizReportEditingTracked[];
  needsDataset: boolean;
}

class ImportReportsDuplicateDialog extends Component<
  IDialogProps,
  IDialogState
> {
  static defaultProps = {
    okDisabled: false,
    saveDisabled: true,
    noDisabled: false,
  };

  constructor(props) {
    super(props);

    const _state = {
      saveDisabled: true,
      updatingReports: _.cloneDeep(
        props.reportsRequiringInput,
      ) as IVizReportEditingRequiresInput[],
      deletingReports: [],
      needsDataset: false,
    };

    //add tracking info
    _state.updatingReports = _.map(_state.updatingReports, rpt => {
      rpt.newRpt.trackingKey = uuid();
      return rpt;
    });

    // set all importing reports' dataset if there are no options
    if (_.size(props.datasets) === 1) {
      const onlyDataset: IDataset = _.head(props.datasets);
      _state.updatingReports = _.map(_state.updatingReports, rpt => {
        rpt.newRpt.datasetName = onlyDataset.name;
        rpt.newRpt.datasetId = onlyDataset.id;
        rpt.newRpt.needsDataset = false;
        return rpt;
      });
    } else if (_.size(props.datasets) === 0) {
      _state.needsDataset = true;
    }

    this.state = _state;

    this.doUpdateReport = this.doUpdateReport.bind(this);
    this.doUpdateDeleteReport = this.doUpdateDeleteReport.bind(this);
    this.doCancel = this.doCancel.bind(this);
    this.doSave = this.doSave.bind(this);
  }

  doSave() {
    const allReportsToImport = _.concat(
      _.map(this.state.updatingReports, 'newRpt'),
      this.props.reportsNotRequiringInput,
    );
    this.props.doSave(allReportsToImport, this.state.deletingReports);
  }

  doCancel() {
    if (_.isFunction(this.props.doCancel)) {
      this.props.doCancel();
    }
  }

  buildTableRows() {
    const { datasets } = this.props;
    const { updatingReports } = this.state;
    return updatingReports.map(rpt => {
      const originalName = rpt.existingRpt.name;
      return (
        <ImportReportsTableRow
          key={rpt.newRpt.trackingKey}
          report={rpt}
          datasets={datasets}
          originalName={originalName}
          doUpdateReport={this.doUpdateReport}
          doUpdateDeleteReport={this.doUpdateDeleteReport}
        />
      );
    });
  }

  doUpdateReport(updatedReport: IVizReportEditingRequiresInput) {
    const updatingReports = _.map(
      this.state.updatingReports,
      (rpt: IVizReportEditingRequiresInput) => {
        if (
          _.isEqual(rpt.newRpt.trackingKey, updatedReport.newRpt.trackingKey)
        ) {
          return updatedReport;
        }
        return rpt;
      },
    );

    this.setState({
      updatingReports,
      saveDisabled: this.checkCleanReports(updatingReports),
    });
  }

  doUpdateDeleteReport(deletedReport: IVizReportEditingTracked) {
    const currentReportsToDelete = this.state.deletingReports;
    currentReportsToDelete.push(deletedReport);

    this.setState({
      deletingReports: currentReportsToDelete,
    });
  }

  checkCleanReports(updatingReports) {
    // find the first dupeReport that needs a dataset or name
    return !!_.find(updatingReports, rpt => {
      return rpt.needsDataset || rpt.needsName;
    });
  }

  render() {
    const { className } = this.props;
    const { saveDisabled, needsDataset } = this.state;

    return (
      <Modal
        animation={false}
        show={this.props.show}
        onHide={this.doCancel}
        dialogClassName='regular-modal'
        backdropClassName='prompt-dialog-backdrop'
        bsClass='prompt-dialog modal'
        bsSize='large'
        className={className}
      >
        <Modal.Header>
          <Modal.Title>
            {!needsDataset
              ? messages.library.importReportDuplicateNamesTitle
              : messages.library.importReportsNeedsDataset}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={this.props.customClass}>
          <form style={{ textAlign: 'center' }}>
            {!needsDataset && (
              <div>
                {messages.library.importReportDuplicateNamesText}
                <ImportReportsTable className='import-records-table'>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>New Name</td>
                      <td className={'dataset'}>Dataset</td>
                      <td>Actions</td>
                    </tr>
                  </thead>
                  <tbody>{this.buildTableRows()}</tbody>
                </ImportReportsTable>
              </div>
            )}
            <ButtonContainer>
              {this.props.doCancel && (
                <SecondaryButton
                  onClick={this.props.doCancel}
                  className='promptCancel'
                >
                  {this.props.cancelText || messages.cancel}
                </SecondaryButton>
              )}
              {!needsDataset && (
                <PrimaryButton
                  key='yes'
                  onClick={this.doSave}
                  className='promptYes'
                  disabled={saveDisabled}
                >
                  {messages.library.save}
                </PrimaryButton>
              )}
            </ButtonContainer>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ImportReportsDuplicateDialog;
