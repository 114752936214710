import { Modal } from '@sugar-discover/react-bootstrap-wrapper';
import {
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
  InvisibleButton,
} from '../../../ui/button';
import { messages } from '../../../i18n';
import { ModalLabel } from '../../../views/VizSaveDialog/ui';

const PromptDialog = ({
  className = '',
  okDisabled = false,
  yesDisabled = false,
  noDisabled = false,
  cancelDisabled = false,
  hideAllButtons = false,
  autoFocus,
  enforceFocus,
  detail,
  children,
  customClass,
  title,
  show = false,
  asInfo = false,
  doCancel,
  doOk,
  doYes,
  doNo,
  okText,
  yesText,
  noText,
  cancelText,
}) => {
  const CancelButtonComponent = asInfo ? SecondaryButton : InvisibleButton;
  return (
    <Modal
      animation={false}
      show={show}
      onHide={doCancel}
      dialogClassName='regular-modal'
      backdropClassName='prompt-dialog-backdrop'
      bsClass='prompt-dialog modal'
      bsSize='large'
      enforceFocus={enforceFocus}
      className={className}
      autoFocus={autoFocus}
    >
      <Modal.Header>
        <Modal.Title>
          <ModalLabel>{title}</ModalLabel>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={customClass}>
        <form>
          {detail}
          {children}
          {!hideAllButtons && (
            <ButtonContainer>
              {doCancel && (
                <CancelButtonComponent
                  onClick={doCancel}
                  className='promptCancel'
                  disabled={cancelDisabled}
                >
                  {cancelText || messages.cancel}
                </CancelButtonComponent>
              )}
              {asInfo ? (
                <PrimaryButton onClick={doOk} disabled={okDisabled}>
                  {okText || messages.ok}
                </PrimaryButton>
              ) : (
                [
                  doNo ? (
                    <SecondaryButton
                      key='no'
                      onClick={doNo}
                      className='promptNo'
                      disabled={noDisabled}
                    >
                      {noText || messages.no}
                    </SecondaryButton>
                  ) : null,
                  <PrimaryButton
                    key='yes'
                    onClick={doYes}
                    className='promptYes'
                    disabled={yesDisabled}
                  >
                    {yesText || messages.yes}
                  </PrimaryButton>,
                ].filter(Boolean)
              )}
            </ButtonContainer>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PromptDialog;
