import { memo, useState } from 'react';
import _ from 'lodash';
import { messages } from '../../../../../i18n';
import {
  ITimePeriod,
  TimePeriods,
  getDisplayText,
} from '../../../../filter/relative-date-condition';
import { ClickOutsideListener } from '../../../../../components/ClickOutsideListener/click-outside-listener.component';
import {
  StyledList,
  StyledListItemButton,
  StyledToggleButton,
} from './time-frame-dropdown.styles';
import { DownChevron } from '../../../../../icons';
import { useSelector } from 'react-redux';
import { VIZ_SELECTORS } from '../../../../../common/redux/selectors/viz-selectors';

interface ITimeFrameDropdownProps {
  onMenuItemClick: (key: string) => void;
  selectedValue: string;
}

export const TimeFrameDropdown = memo<ITimeFrameDropdownProps>(
  ({ onMenuItemClick, selectedValue }) => {
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const { useFiscalCalendar } = useSelector(state => ({
      useFiscalCalendar:
        VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting(state, {}) &&
        VIZ_SELECTORS.getActiveVizFiscalSetting(state, {}) === 'true',
    }));

    const menuItems = [{ key: '', moment_val: '', display: '', format: '' }]
      .concat(Object.values(TimePeriods))
      .map((timePeriod: ITimePeriod) => {
        const displayMessage = getDisplayText(timePeriod, useFiscalCalendar);
        const { key } = timePeriod;
        return {
          key,
          title:
            key === ''
              ? `${messages.slicer.timeFrame}...`
              : _.get(messages, displayMessage, displayMessage),
        };
      });

    const toggleButtonTitle = menuItems.find(({ key }) => key === selectedValue)
      .title;

    return (
      <div className={`dropdown${open ? ' open' : ''}`}>
        <ClickOutsideListener
          isChildrenVisible={openSubMenu}
          onClickAway={() => setOpenSubMenu(false)}
        >
          <StyledToggleButton
            value='check'
            className='timeframe-toggle-button'
            selected={openSubMenu}
            onChange={() => {
              setOpenSubMenu(prev => !prev);
            }}
          >
            <span>{toggleButtonTitle}</span>
            <DownChevron />
          </StyledToggleButton>
          <StyledList open={openSubMenu} className='timeframe-dropdown-menu'>
            {menuItems.map(({ key, title }) => {
              return (
                <StyledListItemButton
                  key={key}
                  onClick={() => {
                    setOpenSubMenu(false);
                    onMenuItemClick(key);
                  }}
                  selected={selectedValue === key}
                  className={key === '' && 'hide'}
                >
                  {title}
                </StyledListItemButton>
              );
            })}
          </StyledList>
        </ClickOutsideListener>
      </div>
    );
  },
);
