import { compose, pure, withState } from 'react-recompose';
import { parseJSON } from '../common/Util';
import { connect } from 'react-redux';
import Discover from '../common/redux/actions/DiscoverActions';
import { withErrorBoundary } from 'react-error-boundary';
import { VIZ_SELECTORS } from '../common/redux/selectors/viz-selectors';
import { messages, i18nUtils } from '../i18n';
import _ from 'lodash';
import ListItem from '@mui/material/ListItem';
import { NestedDropdown } from '../components/nested-dropdown';

const Fallback = () => {
  return (
    <ListItem key={'disabled-aggregation-types-due-to-error'}>
      {messages.aggregation.menuItemLabel}
    </ListItem>
  );
};

const AggregationMenuItem = props => {
  const { aggregateInfo, onSelect = _.noop, selectedAggregation } = props;

  if (!(aggregateInfo.length >= 1)) {
    return null;
  }

  return (
    <NestedDropdown
      id={'aggregation-submenu'}
      popperSx={{ width: 150 }}
      options={_.map(aggregateInfo, agg => ({
        selected: agg.name === selectedAggregation,
        label: i18nUtils.getAggregationDisplayText(agg.name),
        onClick: () => onSelect(agg),
      }))}
      label={messages.aggregation.menuItemLabel}
    />
  );
};

const ConnectedAggregationMenuItem = compose(
  withState('open', 'setOpen', false),
  pure,
  connect(
    (state, ownProps) => {
      const open = VIZ_SELECTORS.getActive(state, ownProps);
      const customAggregations = parseJSON(open.viz.options.customAggregations);
      const aggName = `SHELF_${ownProps.field.name}`;
      const selectedAggregation =
        customAggregations[aggName] || ownProps.field.defaultAggregation;
      const aggregateInfo = getAggregateList(
        ownProps.field,
        state.main.aggregateInfo,
      );

      return {
        chartSpec: VIZ_SELECTORS.getActiveVizChartSpec(state, ownProps),
        selectedAggregation,
        layout: VIZ_SELECTORS.getActiveVizLayout(state, ownProps),
        discoveryId: open.id,
        aggregateInfo,
      };
    },
    (dispatch, ownProps) => {
      const { discoveryId, field, shelf } = ownProps;
      return {
        onSelect: newAggregation => {
          dispatch(
            Discover.updateAggregation({
              discoveryId,
              newAggregation,
              field,
              shelf,
            }),
          );
          dispatch(Discover.setDiscoveryDirty(discoveryId, true));
        },
      };
    },
  ),
)(AggregationMenuItem);

export default withErrorBoundary(ConnectedAggregationMenuItem, Fallback, e => {
  console.error(
    'Could not render AggregationMenuItem, falling back to a disabled menu item.',
  );
  console.debug(e);
});

export const getAggregateList = (field, aggregateInfo = []) => {
  if (field.aggregateMeasure === true) {
    return [];
  }
  const attributeType = field.calcType || field.attributeType || 'NONE';
  return aggregateInfo.filter(info =>
    _.includes(info.supportedTypes, attributeType.toUpperCase()),
  );
};
