import _ from 'lodash';
import { useMemo } from 'react';
import { use18nFormatter } from '../../../../common/formatting';
import { messages } from '../../../../i18n';
import { ISlicerOption } from '../../interfaces';
import { SlugSpan } from './item-count-slug.styles';

interface IItemCountSlugProps {
  options: ISlicerOption[];
  className?: string;
  timestampStatusString: string;
}

export function ItemCountSlug({
  options,
  className,
  timestampStatusString,
}: IItemCountSlugProps) {
  const { formatI18n } = use18nFormatter();
  const statusString = useMemo(() => {
    const numSelected = _.filter(options, 'isSelected').length;
    const total = options.length;
    if (timestampStatusString !== '') {
      return timestampStatusString;
    }
    if (total === numSelected) {
      return messages.slicer.all;
    }
    return formatI18n(messages.slicer.numSelectedLabel, numSelected, total);
  }, [options, timestampStatusString, formatI18n]);

  return <SlugSpan className={className}>{statusString}</SlugSpan>;
}
