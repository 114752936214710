import Const from './ActionConst';
import { find } from 'lodash';

const Actions = {
  clearDashletError() {
    return dispatch => {
      dispatch({ type: Const.Dashlet.CLEAR_DASHLET_ERROR });
    };
  },
  openDiscovery(discovery, dashletId) {
    const {
      id: dashletDiscoveryId,
      name,
      dataset: { id: dashletDatasetId, annotations: datasetAnnotations },
      options,
    } = discovery;

    const runtimeFilters =
      find(options, { key: 'runtimeFilters' })?.value || {};

    const datasetJson =
      find(datasetAnnotations, { key: 'datasetJson' })?.value || '';

    return (dispatch, getState) => {
      const {
        dashlet: { activeVisualizationId, isDashletSaving, mockFinalizeFail },
      } = getState();
      if (isDashletSaving) {
        return;
      }
      if (activeVisualizationId !== dashletDiscoveryId) {
        window.top.postMessage(
          {
            type: 'SELECT_ID',
            dashletDatasetId,
            dashletDiscoveryId,
            dashletId,
            mockFinalizeFail,
            name,
            runtimeFilters,
            datasetJson,
          },
          window.location.origin,
        );
        dispatch({
          type: Const.Dashlet.OPEN_DASHLET_VIZ,
          dashletDatasetId,
          dashletDiscoveryId, //@NOTE rename dashletDiscoveryId because of DSC-3794
        });
      }
    };
  },
  setMockFinalizeFail(mockFinalizeFail) {
    return {
      type: Const.Dashlet.SET_MOCK_FINALIZE_FAIL,
      mockFinalizeFail,
    };
  },
  saveDashlet(variables) {
    const { pendingId, dashletId, mockFinalizeFail } = variables ?? {};
    return dispatch => {
      let dashletError;
      dispatch({ type: Const.Dashlet.SAVE_DASHLET });
      window.top.postMessage(
        {
          type: 'DASHLET_ID_SAVED',
          pendingId,
          dashletId,
          dashletError,
          mockFinalizeFail,
        },
        window.location.origin,
      );
      dispatch({
        type: Const.Dashlet.DASHLET_SAVE_COMPLETE,
        dashletError,
      });
    };
  },
  closeDiscovery() {
    return dispatch => {
      dispatch({
        type: Const.Dashlet.CLOSE_DASHLET_VIZ,
      });
    };
  },
  setAvailableDiscoveries(discoveries) {
    return dispatch => {
      dispatch({
        type: Const.Dashlet.SET_AVAILABLE_DASHLET_DISCOVERIES,
        discoveries,
      });
    };
  },
};

export default Actions;
