import { Panel } from '@sugar-discover/react-bootstrap-wrapper';
import { clearLegendWrapper } from './legend-wrapper.hoc';
import { messages } from '../../i18n';

export const ChartValidationPanel = ({ validation }) => {
  if (!validation?.valid) {
    const messagesDiv = validation?.error?.messages?.length > 0 && (
      <div>
        <h3>Additional Messages:</h3>
        <ul>
          {validation?.error?.messages.map(message => (
            <li key={`validation-message-${message.field.className}`}>
              Field {message.field.name} : {message.message}
            </li>
          ))}
        </ul>
      </div>
    );

    const fieldsDiv = (validation?.error?.errorFields?.length ?? []) > 0 && (
      <div>
        <h3>Invalid Fields:</h3>
        <ul>
          {validation?.error?.errorFields.map(field => (
            <li key={`validation-error-${field.name}`}>{field.name}</li>
          ))}
        </ul>
      </div>
    );
    return (
      <Panel
        header={messages.baseChart.errorsValidatingLayout}
        bsStyle='danger'
      >
        <h2>{validation?.error?.message}</h2>
        {messagesDiv}
        {fieldsDiv}
      </Panel>
    );
  } else {
    return '';
  }
};

export const LegendAwareChartValidationPanel: any = clearLegendWrapper(
  ChartValidationPanel as any,
);
