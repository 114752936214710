import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { Monitors } from '../graphql/index';
import Discover from '../redux/actions/DiscoverActions';
import { constant } from 'lodash';
import { compose } from 'react-recompose';

class AnomalyLink extends Component {
  goToAnomaly() {
    // Open monitor
    this.props.openMonitorDetail(this.props.monitor);
    // Get monitor data frequency
    const { monitorDetail } = this.props.monitorData;
    const dataFrequency =
      (monitorDetail.endTimestamp - monitorDetail.startTimestamp) /
      monitorDetail.data.length;
    // Focus anomaly
    this.props.focusAnomaly(
      this.props.monitor.id,
      this.props.anomaly,
      dataFrequency,
      this.props.focusWindowPadding,
    );
  }
  render() {
    return (
      <span
        className='link'
        onClick={() => {
          this.goToAnomaly();
        }}
      >
        {this.props.linkContent}
      </span>
    );
  }
}

AnomalyLink.PropTypes = {
  anomaly: PropTypes.object.isRequired,
  monitor: PropTypes.object.isRequired,
  focusWindowPadding: PropTypes.string,
  linkContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

const MonitorDetailQuery = graphql(Monitors.MonitorDetail, {
  name: 'monitorData',
  options: ({ monitor }) => ({
    variables: {
      id: monitor.id,
    },
  }),
});

export default compose(
  MonitorDetailQuery,
  connect(constant({}), dispatch => {
    return {
      openMonitorDetail(monitor) {
        dispatch(Discover.openDiscovery(monitor));
      },
      focusAnomaly(id, anomaly, dataFrequency, focusWindowPadding) {
        dispatch(
          Discover.focusAnomaly(id, anomaly, dataFrequency, focusWindowPadding),
        );
      },
    };
  }),
)(AnomalyLink);
