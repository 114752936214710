import { css } from '@emotion/react';
import { font } from './mixins';

export const formsStyles = ({
  theme: {
    colors: { RegularFontWeight, baseFontColor },
  },
}) => css`
  .formInput--text input {
    transition: background-color 5000s ease-in-out 0s;
    ${font({ size: '16px', weight: RegularFontWeight, color: baseFontColor })}
    padding-top: 10px;
    padding-bottom: 10px;
    height: 40px;
    border-radius: 2px;
  }
  .formInput--text label {
    ${font({ size: '16px', weight: RegularFontWeight, color: baseFontColor })}
  }
  .dropdown-menu .checkbox {
    margin: 0;
  }
`;
