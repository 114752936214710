import { useCallback, useContext, useState, MouseEvent, Fragment } from 'react';
import _ from 'lodash';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import RightChevron from '../../../images/sdd/icon_right_chevron.svg';
import { NestedDropdownContext } from './nested-dropdown.context';
import {
  CheckIconStyled,
  ListButtonStyled,
  MenuItemSpacedForCheck,
  PaperStyled,
  PopperStyled,
} from './nested-dropdown.styles';
import {
  INestedListItem,
  INestedDropdownProps,
} from './nested-dropdown.interfaces';
import { useHasValueChanged } from '../../common/utilities/state-helpers.hook';

export const CheckedMenuItem = ({
  option: {
    label,
    selected = false,
    showDividerAfter = false,
    ...listItemProps
  },
}: {
  option: INestedListItem;
}) => (
  <Fragment key={label}>
    <MenuItemSpacedForCheck>
      <ListButtonStyled {..._.pick(listItemProps, ['onClick'])}>
        {selected && <CheckIconStyled aria-label={'selected'} />}
        <span>{label}</span>
      </ListButtonStyled>
    </MenuItemSpacedForCheck>
    {showDividerAfter && <Divider sx={{ margin: '8px 0' }} />}
  </Fragment>
);

export const NestedDropdown = (props: INestedDropdownProps) => {
  const {
    id,
    popperSx = {},
    options = [],
    label,
    OptionRenderer = CheckedMenuItem,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  const dropdownContext = useContext(NestedDropdownContext);
  const { openSubMenu, setOpenSubMenu } = dropdownContext;

  const hasOpenMenuChanged = useHasValueChanged({
    value: openSubMenu,
  });

  const open = Boolean(anchorEl);

  const handleMenuOpen = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setOpenSubMenu(id);
      setAnchorEl(event.currentTarget);
    },
    [setOpenSubMenu, setAnchorEl, id],
  );

  const handleMenuClose = useCallback(
    (event = null) => {
      const toElement = event?.toElement || event?.relatedTarget;
      const isOffDropdown = !(
        _.isFunction(toElement?.closest) && toElement?.closest(`#${id}`)
      );
      const isSibling =
        anchorEl !== toElement &&
        anchorEl?.parentElement === toElement?.parentElement;
      if ((!open || id === openSubMenu) && !(isOffDropdown || isSibling)) {
        return;
      }

      setAnchorEl(null);
    },
    [anchorEl, setAnchorEl, openSubMenu, id, open],
  );

  if (hasOpenMenuChanged && openSubMenu !== id) {
    handleMenuClose();
  }

  return (
    <ListItem disablePadding>
      <ListButtonStyled
        key={'popper-control'}
        aria-owns={open ? id : undefined}
        aria-haspopup={true}
        onMouseEnter={handleMenuOpen}
        onMouseLeave={handleMenuClose}
      >
        {label}
        <RightChevron className='nested-dropdown-chevron' height={16} />
        <PopperStyled
          key={'popper-content'}
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement={'right-start'}
          sx={{ width: 125, ...popperSx }}
        >
          <PaperStyled square onMouseLeave={handleMenuClose}>
            <List>
              {_.map(options, option => (
                <OptionRenderer option={option} />
              ))}
            </List>
          </PaperStyled>
        </PopperStyled>
      </ListButtonStyled>
    </ListItem>
  );
};
