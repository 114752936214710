import { ComponentType, ReactNode } from 'react';
import component from './component';
import { connect } from 'react-redux';

const mapDispatchToProps = () => {
  return {};
};

const mapStateToProps = () => {
  return {};
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(component) as ComponentType<{ onClick?; icon?; children: ReactNode }>;
