import { useState } from 'react';
import { isAdmin as isUserAdmin } from '../../common/redux/selectors/AccountSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import AccountQueries from '../../common/graphql/Account';
import { userFragment } from '../../common/graphql/Fragments';
import { filter } from 'graphql-anywhere';
import { isFunction, noop } from 'lodash';
import { IUserInfo, IUpdateProfileRequest } from '../interfaces';
import Account from '../../common/redux/actions/AccountActions';

interface IAccountMutationInputProps {
  isAdmin?: boolean;
  refreshUser?(user);
  setSaving?: (saving?: boolean) => any;
}

export interface IAccountMutationOutputProps {
  updateUser(user: IUpdateProfileRequest): Promise<any>;
}

interface IAccountMutationData {
  updateUser: IUserInfo;
}

const accountMutationFuncs = (
  { isAdmin, refreshUser = noop, setSaving = noop }: IAccountMutationInputProps,
  mutate,
) => ({
  updateUser: (modifiedUser: IUserInfo) => {
    const user = filter(userFragment, {
      ...modifiedUser,
      fullName: '',
    });
    if (isAdmin) {
      user.roles = modifiedUser.roles;
    }
    setSaving(true);
    return mutate({
      variables: {
        user,
      },
      refetchQueries: [{ query: AccountQueries.AccountQuery }],
    })
      .then(({ data }) => {
        // Update stored current user info
        if (isFunction(refreshUser)) {
          refreshUser(data.updateUser);
        }
      })
      .catch(error => {
        console.log('Error updating account.', error);
      })
      .finally(() => setSaving(false));
  },
});

export const AccountMutation = graphql<
  IAccountMutationInputProps,
  IAccountMutationData,
  {},
  IAccountMutationOutputProps
>(AccountQueries.UpdateAccountMutation, {
  props: ({ ownProps = {}, mutate }) => accountMutationFuncs(ownProps, mutate),
});

export const useAccountMutation = () => {
  const [mutate] = useMutation(AccountQueries.UpdateAccountMutation);
  const [saving, setSaving] = useState<boolean>();
  const dispatch = useDispatch();
  const refreshUser = user => {
    dispatch(Account.setCurrentUser(user));
  };
  const isAdmin = useSelector(({ account }: any) => isUserAdmin(account));
  return {
    saving,
    ...accountMutationFuncs({ isAdmin, refreshUser, setSaving }, mutate),
  };
};
