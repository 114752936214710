import { setVizUtil, getChartSpecs } from './VizUtil.common';
import _, {
  cloneDeep,
  head,
  isEqual,
  isNil,
  map,
  split,
  tail,
  trim,
} from 'lodash';
import {
  DATA_FORMATTER,
  DATA_TYPE_FORMAT,
  getCurrentUser,
  DynamicFields,
  PriorPeriodTypes,
  Types,
  isDashletOnlyDynamicFieldValue,
  getDynamicValues,
  getCustomFormatProps,
  FISCAL_CALENDAR_START_DATE,
  FISCAL_CALENDAR_YEAR_TYPE,
  FIELD_ORDINAL_SUFFIX,
  DynamicValue,
  ShelfName,
  IFormatProto,
  FIELD_SEPARATOR_SYMBOL,
} from '../common/Constants';
import Util, { parseJSON } from '../common/Util';
import * as d3 from 'd3';
import FileSaver from 'file-saver';
import * as svgUtil from 'save-svg-as-png';
import html2canvas from 'html2canvas';
import moment from '../common/Moment';
import { getAllLinksEnabledInReport } from '../components/PivotDrillLinks/utils';
import { escapeFieldName, unescapeFieldName } from './calc/CalcUtils';
import shortid from 'shortid';
import {
  IAnyAttribute,
  IExpression,
  IFilter,
  IQueryFilter,
  ITimeAttribute,
  ITimeCalcAttribute,
  IFiscalCalendarInfo,
  IExpressionSegment,
  IOperator,
  IAppliedFilters,
  FilterDialogTypes,
} from '../datasets';
import { join as joinChartData } from './charts/ChartUtils';
import {
  IBaseChartSpec,
  ICalc,
  IChartShelf,
  ICustomFormatToggle,
  IDehydratedLayoutItem,
  IDehydratedViz,
  ILayout,
  IQuery,
  IQueryCalc,
  IQueryWithCalc,
  IQueryWithSorts,
  IQueryWithSubtotals,
  IToggle,
  IViz,
  IVizBase,
  IVizOptions,
  ISlicerSelection,
  IDehydratedLayout,
  IDehydratedVizOptions,
  IQueryWithFilters,
  ShelfTypes,
} from './interfaces';
import { IRgbColor } from './interfaces/color.interface';
import {
  FilterOperators,
  IN_LIST,
  aggregateFilterOperators,
} from './filter/FilterOperators';
import { IInternationalizationPreferences } from '../account/interfaces';
import InListFilter from './filter/exports/InListFilter';
import DateFilter from './filter/exports/DateFilter';
import {
  AggregateFilterSubTypes,
  Condition,
  LogicalOperators,
  TimestampFilterSubTypes,
  createFilterForField,
} from './filter/Filter';
import { DefaultFontName } from '../components/ui/fonts';
import { isDashletMode } from '../auth';
import { ICustomFormatProps } from './custom-format-modal';
import { IDiscoverEmotionTheme } from '../common/emotion';
import { createOrdinalName } from './charts/ChartUtils';
import { TimestampSlicerTypes } from './slicer/interfaces';

export const MonthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export enum MonthLabels {
  'january' = 1,
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
}

/**
 * For a given time_calc return an expression to create a corresponding ordinal column
 *
 * @param name Field name involved in the time calculation (Source)
 * @param part Time Calc extraction part
 * @returns {string} Ordinal Column Field expression
 */
function getDateOrdinalFormat(name, part) {
  part = part.toUpperCase();

  // extraction types we can to_char
  const formats = {
    SECOND: 'SS',
    MINUTE: 'MI',
    HOUR: 'HH',
    DAY: 'DD',
    WEEK: 'IW',
    MONTH: 'MM',
    QTR: 'Q',
    YEAR: 'YYYY',
  };

  if (formats[part]) {
    return `Date([${name}], "${formats[part]}")`;
  }

  // Full dates have a european format which facilitates sorting
  if (part === 'DATE') {
    return `date([${name}], "YYYY/MM/DD")`;
  } else if (part === 'EXACT_DATE') {
    return `date([${name}], "YYYY/MM/DD HH24:MI:SS")`;
  } else if (part === 'WEEK_IN_QTR') {
    // by wrapping this in a case statement, the query engine skips date formatting (appending 'Week'), allowing for numeric sorting.
    return `case(TRUE, date_part("week_in_qtr", [${name}]), NULL)`;
  }

  // all else not matching simply return column. Should not occurr unless a new time calc type has been added
  console.log(
    `Unable to build an ordinal column for Time calc: ${part} on field: ${name}`,
  );
  return `[${name}]`;
}

// Backwards compatibility code. Only called for visualizations created prior to ordinal column introduction
function recreateFormula(field) {
  return getDateOrdinalFormat(
    field.parentField.name,
    field.timeAttribute.calcFunction,
  );
}

export const Hierarchy: {
  TIME_ATTRIBUTES: { [key: string]: ITimeAttribute };
  createTimeCalcFields: (
    field: IAnyAttribute,
    timeHierarchyAttributes?: ITimeAttribute[],
  ) => ITimeCalcAttribute[];
  determineMostGranular: (
    timeAttributes: ITimeAttribute[],
    minSupported: ITimeAttribute,
  ) => ITimeAttribute;
} = {
  TIME_ATTRIBUTES: {
    YEAR: {
      key: 'YEAR',
      fiscalKey: 'fiscalYear',
      displayText: 'Year',
      calcFunction: 'year',
      moment_val: 'year',
      abbreviation: 'Yr',
      order: 1,
    },
    QTR: {
      key: 'QTR',
      displayText: 'Quarter',
      fiscalKey: 'fiscalQuarter',
      calcFunction: 'qtr',
      moment_val: 'quarter',
      abbreviation: 'Qtr',
      order: 2,
    },
    MONTH: {
      key: 'MONTH',
      displayText: 'Month',
      calcFunction: 'month',
      moment_val: 'month',
      abbreviation: 'Mo',
      order: 3,
    },
    WEEK: {
      key: 'WEEK',
      fiscalKey: 'fiscalWeek',
      displayText: 'Week',
      calcFunction: 'week',
      moment_val: 'isoWeek',
      abbreviation: 'Wk',
      order: 4,
    },
    WEEK_IN_QTR: {
      key: 'WEEK_IN_QTR',
      fiscalKey: 'fiscalWeekInQuarter',
      displayText: 'week-in-quarter',
      calcFunction: 'Week_in_Qtr',
      moment_val: 'day',
      abbreviation: 'WiQ',
      order: 5,
    },
    DAY: {
      key: 'DAY',
      displayText: 'Day',
      calcFunction: 'day',
      moment_val: 'day',
      abbreviation: 'D',
      order: 6,
    },
    HOUR: {
      key: 'HOUR',
      displayText: 'Hour',
      calcFunction: 'hour',
      moment_val: 'hour',
      abbreviation: 'Hr',
      order: 7,
      hidden: true,
    },
    MINUTE: {
      key: 'MINUTE',
      displayText: 'Minute',
      calcFunction: 'minute',
      moment_val: 'minute',
      abbreviation: 'Mi',
      order: 8,
      hidden: true,
    },
    SECOND: {
      key: 'SECOND',
      displayText: 'Second',
      calcFunction: 'second',
      moment_val: 'second',
      abbreviation: 'S',
      order: 9,
      hidden: true,
    },
    EXACT_DATE: {
      key: 'EXACT_DATE',
      displayText: 'Exact Date',
      calcFunction: 'exact_date',
      abbreviation: 'ts',
      order: 10,
    },
    DATE: {
      key: 'DATE',
      displayText: 'Date',
      calcFunction: 'date',
      abbreviation: 'dt',
      order: 11,
    },
  },

  createTimeCalcFields: (
    field: IAnyAttribute,
    timeHierarchyAttributes: ITimeAttribute[],
  ): ITimeCalcAttribute[] => {
    let attributes = timeHierarchyAttributes;
    if (_.isNil(attributes)) {
      attributes = [
        Hierarchy.TIME_ATTRIBUTES.YEAR,
        Hierarchy.TIME_ATTRIBUTES.QTR,
        Hierarchy.TIME_ATTRIBUTES.MONTH,
        Hierarchy.TIME_ATTRIBUTES.DATE,
      ];
    } else if (!_.includes(attributes, Hierarchy.TIME_ATTRIBUTES.DATE)) {
      // Backwards compatibility with saved reports prior to introduction of "Date". Add it if missing
      attributes.push(Hierarchy.TIME_ATTRIBUTES.DATE);
    }
    const parent = { ...field };
    delete parent.children;

    return Object.values(Hierarchy.TIME_ATTRIBUTES)
      .filter(a => {
        return !_.get(a, 'hidden', false);
      })
      .map(a => {
        const escapedFieldName = escapeFieldName(field.name);
        return {
          name: `${a.displayText} (${field.name})`,
          fieldListDisplayName: a.displayText,
          attributeType: Types.TIME_CALC as any,
          defaultAggregation: field.defaultAggregation,
          formula: `date_part("${a.calcFunction}", [${escapedFieldName}])`,
          ordinalFormula: getDateOrdinalFormat(
            escapedFieldName,
            a.calcFunction,
          ),
          priorPeriodFormula: `date_trunc("${a.calcFunction}", [${escapedFieldName}])`,
          timeAttribute: a,
          parentField: parent,
          hidden: !_.some(attributes, _.pick(a, 'key')),
        };
      });
  },
  determineMostGranular: (
    timeAttributes: ITimeAttribute[],
    minSupported: ITimeAttribute = Hierarchy.TIME_ATTRIBUTES.WEEK_IN_QTR,
  ): ITimeAttribute => {
    const keysInOrder = Object.keys(Hierarchy.TIME_ATTRIBUTES);
    const minIdx = keysInOrder.findIndex(k => k === minSupported.key);

    if (!_.isEmpty(timeAttributes)) {
      let mostGranular = -1;
      timeAttributes.forEach(ta => {
        const index = keysInOrder.findIndex(k => k === ta.key);
        mostGranular = Math.max(mostGranular, index);
      });
      if (mostGranular > -1 && mostGranular <= minIdx) {
        return Hierarchy.TIME_ATTRIBUTES[keysInOrder[mostGranular]];
      }
    }
    return null;
  },
};

export const Viz = {
  // @NOTE: 'Viz' here is different than with ./viz/viz.component
  rehydrateViz: (viz: IDehydratedViz): IViz => {
    const partiallyHydratedViz: Omit<IDehydratedViz, 'layout' | 'options'> & {
      layout: IDehydratedLayout | ILayout;
      options: IDehydratedVizOptions | IVizOptions;
    } = _.cloneDeep(viz);

    const newOptions = {} as IVizOptions;
    if (_.isArray(viz.options)) {
      _.forEach(viz.options, o => (newOptions[o.key] = o.value));
      partiallyHydratedViz.options = newOptions;
    } else {
      // assume we got options already broken down into an object
    }
    const newLayout: ILayout = {};

    const calcs = Viz.getCalcsFromViz({
      ...partiallyHydratedViz,
      options: partiallyHydratedViz.options as IVizOptions,
      layout: newLayout,
    });

    const timeCalcs = Viz.createTimeCalcFieldsForViz({
      ...partiallyHydratedViz,
      options: partiallyHydratedViz.options as IVizOptions,
      layout: newLayout,
    });

    const { attributes: datasetAttributes = [] } = viz.dataset;

    // if any needed created, make sure we set the string back on the viz
    (partiallyHydratedViz.options as IVizOptions).timeHierarchies = JSON.stringify(
      timeCalcs,
    );

    (partiallyHydratedViz.layout as IDehydratedLayoutItem[]).forEach(shelf => {
      const fields = shelf.fields.map(f => {
        // look for matching fields in the dataset
        const dsField = datasetAttributes.find(a => a.name === f);

        // if we didn't find a field in the dataset, look for matching calc fields
        let calcField;
        if (_.isNil(dsField)) {
          if (!_.isEmpty(calcs)) {
            calcField = calcs.find(c => c.name === f);
            if (!_.isNil(calcField)) {
              return calcField;
            }
          }
          if (!_.isEmpty(timeCalcs)) {
            // split apart the time calc field name to get the parent field so we can find it in the timeCalcs
            const re = /^([^(]*)\s\((.*)\)$/;
            const groups = re.exec(f);
            if (!_.isEmpty(groups) && groups.length === 3) {
              const hierarchy = timeCalcs[groups[2]];
              if (!_.isEmpty(hierarchy)) {
                const tf = hierarchy.find(timeField => {
                  return f === timeField.name;
                });
                return tf;
              }
            }
          }
        }
        // return the field if found in the dataset or saved calcs. if not, return it with just the name and let the
        // missing field logic pick it up & warn the user about it
        return dsField || { name: f };
      });
      // Fields are currently stored as just name, replace with full field from schema
      newLayout[shelf.id] = fields.filter(f => !_.isNil(f));
    });

    if (viz?.chartType === 'waterfall' && isNil(newLayout?.EXTRA_VALUES)) {
      newLayout.EXTRA_VALUES = [];
    }

    // waterfall VALUES has been limited to one field
    if (viz?.chartType === 'waterfall' && newLayout?.VALUES?.length > 1) {
      const prevValues = cloneDeep(newLayout?.VALUES);
      const maybeFirstMetric = head(prevValues);
      if (maybeFirstMetric?.attributeType === Types.NUMBER) {
        newLayout.VALUES = [maybeFirstMetric];
      } else {
        newLayout.EXTRA_VALUES = [maybeFirstMetric];
      }

      newLayout.EXTRA_VALUES.push(...tail(prevValues));
    }

    const newViz: IViz = { ...partiallyHydratedViz } as IViz;
    newViz.layout = newLayout as ILayout;

    // Rehydrate filters
    const vizFilters = Viz.getFiltersFromViz(newViz);

    // Fix old filters.
    _.forIn(vizFilters, (value: any) => {
      value.expression = Viz.replaceLegacyFilterExpression(value.expression);

      // Relative dates used to have an optional 3rd parameter of the anchor date. That's been moved to the 4th position
      // with a boolean indicating that the fractional period should be included in calendar ranges.
      if (value.subType === 'RELATIVE_DATES') {
        const { operands } = value.expression.left;
        if (operands.length < 3) {
          // old non-anchored, just add missing flag
          operands.push('false');
        } else if (operands.length === 4) {
          // We're new. Old ones only had up to 3
        } else if (operands.length === 3) {
          // check to see if it's a "new" one with boolean at 3rd pos
          if (_.includes(['false', 'true'], operands[2])) {
            // 3 positions, but 3rd is a boolean. We're new
          } else {
            // Old with anchor at 3rd. Move anchor to end
            value.expression.left.operands = operands
              .slice(0, 2)
              .concat(['false', operands[2]]);
          }
        }
      }

      // transition to only referencing ops by name vs. full operator info.
      if (value.expression?.left?.operator?.key) {
        value.expression.left.operator = value.expression.left.operator.key;
      }
      if (value.expression?.operator?.key) {
        value.expression.operator = value.expression.operator.key;
      }
      if (value.expression?.right?.operator?.key) {
        value.expression.right.operator = value.expression.right.operator.key;
      }

      // transition to only referencing fields by name vs. full field info.
      if (value.field?.name) {
        value.field = value.field.name;
      }
      if (value.aggregationContext?.field?.name) {
        value.aggregationContext.field = value.aggregationContext.field.name;
      }
    });

    newViz.options.filters = JSON.stringify(vizFilters);

    // Initialize layout mapping store
    newViz.layoutMapping = {};

    newViz.queryId = shortid.generate();

    const missingFields = Viz.findMissingFields(partiallyHydratedViz);

    _.merge(newViz, missingFields);

    return newViz;
  },
  getValueShelves: (viz: IVizBase): IChartShelf[] => {
    let chartSpec = getChartSpecs()[viz.chartType];
    if (_.isNil(chartSpec)) {
      // try to guess based on the shelf ids
      const vizShelves = _.keys(_.get(viz, 'layout', {}));
      const possibleChartSpecs = _.values(getChartSpecs()).filter(
        possibleChartSpec => {
          const specShelves = _.keys(possibleChartSpec.shelves);
          const diff = _.difference(vizShelves, specShelves);
          return _.isEmpty(diff) && vizShelves.length === specShelves.length;
        },
      );
      if (!_.isEmpty(possibleChartSpecs)) {
        chartSpec = possibleChartSpecs[0];
      } else {
        chartSpec = getChartSpecs().pivot;
      }
    }
    const shelves = _.filter(chartSpec.shelves, {
      shelfType: ShelfTypes.MEASURE,
    });

    // there is no guarantee of iteration order over map keys (above _.forIn), so we force the results to be sorted to be predictable
    return _.sortBy(shelves, 'id');
  },
  getAllFieldsInPlay: (
    vizLayout: ILayout | IDehydratedLayoutItem[],
  ): IAnyAttribute[] => {
    let layout = vizLayout as ILayout;
    if (_.isArray(vizLayout)) {
      layout = _.reduce(
        vizLayout,
        (newLayout, shelf) => {
          newLayout[shelf.id] = shelf.fields.map(f => {
            return { name: f } as any;
          });
          return newLayout;
        },
        {} as ILayout,
      );
    }

    return _.flatMap(_.values(layout)).filter(f => f !== undefined);
  },

  getAllFieldsUsedInQuery: (viz: IVizBase): IAnyAttribute[] => {
    let inPlay = Viz.getAllFieldsInPlay(viz.layout);
    const allFields = Viz.getAllAvailableFields(viz as IViz);
    // Add fields referenced by Filters

    const filters = Viz.getFiltersFromViz(viz);
    inPlay = inPlay.concat(
      Object.values(filters).map(f =>
        allFields.find(field => field.name === f.field),
      ),
    );

    const calcs = Viz.getCalcsFromViz(viz);

    const findNestedFields = (list: IAnyAttribute[]): IAnyAttribute[] => {
      return _.flatMap(list, field => {
        if (!(field as ITimeCalcAttribute).formula) {
          return [field];
        }
        const nestedFields: IAnyAttribute[] = _.flatMap(
          Viz.getFieldsReferencedInCalc((field as ITimeCalcAttribute).formula),
          name => {
            let referencedField = calcs.find(
              c => c.name === name,
            ) as IAnyAttribute;
            if (!referencedField) {
              referencedField = viz.dataset.attributes.find(
                c => c.name === name,
              );
            }
            return referencedField ? [referencedField] : [];
          },
        );
        return [field].concat(findNestedFields(nestedFields));
      });
    };

    const allFieldsIncludingNested = findNestedFields(inPlay);

    // _.uniq doesn't catch all duplicates
    const map: { [key: string]: IAnyAttribute } = {};
    allFieldsIncludingNested.forEach(attr => {
      map[attr.name] = attr;
    });
    const unique = Object.values(map);

    return unique;
  },

  getLowestHeirarchyLevel: (hierarchy): ITimeAttribute => {
    if (!hierarchy || hierarchy.length === 0) {
      return null;
    }
    let lowest = -1;
    const hierarchyOfFunctions = Object.values(Hierarchy.TIME_ATTRIBUTES).map(
      h => h.calcFunction,
    );
    Object.keys(hierarchy).forEach(level => {
      lowest = Math.max(lowest, hierarchyOfFunctions.indexOf(level));
    });
    if (lowest === -1) {
      return null;
    }
    return Object.values(Hierarchy.TIME_ATTRIBUTES)[lowest];
  },
  /**
   * @deprecated
   * @param viz
   * For hook-based components, use 'useFiscalCalendarInfo' hook in common/utilities
   */
  getFiscalCalendarInfo(viz?: IViz): IFiscalCalendarInfo {
    const { value: fiscalCalendarYearType } =
      _.find(viz?.dataset?.annotations, { key: FISCAL_CALENDAR_YEAR_TYPE }) ??
      {};
    const { value: fiscalCalendarStartDate } =
      _.find(viz?.dataset?.annotations, { key: FISCAL_CALENDAR_START_DATE }) ??
      {};
    const useFiscalCalendar =
      (fiscalCalendarYearType &&
        fiscalCalendarStartDate &&
        _.some(viz?.options, {
          key: 'useFiscalCalendar',
          value: 'true',
        })) ||
      _.get(viz, 'options.useFiscalCalendar') === 'true';
    return {
      useFiscalCalendar,
      fiscalCalendarYearType,
      fiscalCalendarStartDate,
    } as IFiscalCalendarInfo;
  },
  computeLastDateFromHierarchy: (
    dateMap: {
      [key: string]: string | number;
    },
    viz?: IViz,
  ) => {
    const {
      useFiscalCalendar,
      fiscalCalendarYearType,
      fiscalCalendarStartDate,
    } = Viz.getFiscalCalendarInfo(viz);

    const monthOffset =
      useFiscalCalendar && fiscalCalendarStartDate
        ? moment(fiscalCalendarStartDate).month()
        : 0;
    if (Object.keys(dateMap).length === 0) {
      return null;
    }

    if (dateMap.exact_date) {
      return moment(dateMap.exact_date, 'MM-DD-YYYY hh:mm A');
    }

    const dateBuilder: any = {};

    const lowestHeirarchyLevel = Viz.getLowestHeirarchyLevel(dateMap);

    const deferred = [];
    for (const [key, val] of Object.entries(dateMap)) {
      switch (key) {
        case Hierarchy.TIME_ATTRIBUTES.YEAR.calcFunction:
          dateBuilder.years = val;
          break;
        case Hierarchy.TIME_ATTRIBUTES.QTR.calcFunction:
          dateBuilder.months =
            Math.max(0, ['Q1', 'Q2', 'Q3', 'Q4'].indexOf(val as string)) * 3;
          break;
        case Hierarchy.TIME_ATTRIBUTES.MONTH.calcFunction:
          dateBuilder.months = Math.max(0, MonthsShort.indexOf(val as string));
          break;
        case Hierarchy.TIME_ATTRIBUTES.WEEK.calcFunction:
          deferred.push(date => date.add(val, 'week'));
          break;
        case Hierarchy.TIME_ATTRIBUTES.DAY.calcFunction:
          dateBuilder.days = val;
          break;
        case Hierarchy.TIME_ATTRIBUTES.HOUR.calcFunction:
          deferred.push(date => date.add(val, 'hours'));
          break;
        case Hierarchy.TIME_ATTRIBUTES.MINUTE.calcFunction:
          deferred.push(date => date.add(val, 'minutes'));
          break;
        case Hierarchy.TIME_ATTRIBUTES.SECOND.calcFunction:
          deferred.push(date => date.add(val, 'seconds'));
          break;
      }
    }

    const useFiscalQuarters = useFiscalCalendar && dateMap.qtr;
    const useFiscalMonths = useFiscalCalendar && dateMap.month;

    if (useFiscalQuarters && fiscalCalendarYearType === 'end') {
      dateBuilder.years--;
    }
    if (useFiscalMonths) {
      if (
        fiscalCalendarYearType === 'start' &&
        dateBuilder.months < monthOffset
      ) {
        dateBuilder.years--;
      } else if (
        fiscalCalendarYearType === 'end' &&
        dateBuilder.months >= monthOffset
      ) {
        dateBuilder.years++;
      }
    }

    const date = moment(dateBuilder);
    deferred.forEach(def => def(date));

    // Go to the start of the next period
    date.add(1, lowestHeirarchyLevel.moment_val);
    date.startOf(lowestHeirarchyLevel.moment_val);
    if (useFiscalQuarters) {
      date.add(monthOffset, 'month');
    }
    return date;
  },
  getVizOption: (viz, optionName, defaultValue) => {
    const opt = _.get(viz, `options.${optionName}`, defaultValue);
    return parseJSON(opt, defaultValue);
  },
  getCalcsFromViz: (viz: IVizBase): ITimeCalcAttribute[] => {
    let calcs = [];
    const calcFields = _.get(viz, 'options.calcFields', '[]');
    if (_.isString(calcFields)) {
      try {
        calcs = JSON.parse(calcFields);
      } catch (e) {
        console.log(e);
      }
    } else if (_.isArray(calcFields)) {
      calcs = [...calcFields];
    }
    return calcs;
  },
  getTimeCalcsFromViz: (
    viz: IVizBase,
  ): { [key: string]: ITimeCalcAttribute[] } => {
    let timeCalcs = {};
    const timeHierarchies = _.get(viz, 'options.timeHierarchies', '{}');
    if (_.isString(timeHierarchies)) {
      try {
        timeCalcs = JSON.parse(timeHierarchies);
      } catch (e) {
        console.log(e);
      }
    } else if (_.isArray(timeHierarchies)) {
      timeCalcs = timeHierarchies;
    }
    return timeCalcs;
  },
  /**
   * Given a viz, construct the full object model for timeHierarchies based on the dataset fields.
   * @param viz
   */
  createTimeCalcFieldsForViz: (
    viz: IVizBase,
  ): { [key: string]: ITimeCalcAttribute[] } => {
    let visibleTimeHierarchyLevels = {};
    if (_.isString(viz.options.visibleTimeHierarchyLevels)) {
      try {
        visibleTimeHierarchyLevels = JSON.parse(
          viz.options.visibleTimeHierarchyLevels,
        );
      } catch (e) {
        console.log(e);
      }
    } else if (!_.isEmpty(viz.options.visibleTimeHierarchyLevels)) {
      visibleTimeHierarchyLevels = viz.options.visibleTimeHierarchyLevels;
    }
    const { attributes: datasetAttributes = [] } = viz.dataset;
    const calcsUnhydrated = Viz.getCalcsFromViz(viz);
    const rehydratingCalcs = [...datasetAttributes, ...calcsUnhydrated];

    const timeCalcs = rehydratingCalcs.reduce((fields, current) => {
      const field = { ...current };
      // create time hierarchy fields for every timestamp in the dataset. show/hide the fields based on what is specified in visibleTimeHierarchyLevels
      if (field.attributeType === Types.TIMESTAMP) {
        let hierarchyLevels;
        if (visibleTimeHierarchyLevels[field.name]) {
          const visibleLevels = visibleTimeHierarchyLevels[field.name];
          hierarchyLevels = visibleLevels
            .map(vl => Hierarchy.TIME_ATTRIBUTES[vl])
            .filter(vl => !_.isNil(vl) && !_.get(vl, 'hidden', false));
        }
        fields[field.name] = Hierarchy.createTimeCalcFields(
          field as IAnyAttribute,
          hierarchyLevels,
        );
      }
      return fields;
    }, {});
    return timeCalcs;
  },
  getFiltersFromViz: (viz: IVizBase): IAppliedFilters => {
    const options = { ...viz.options };
    let filters: {} | string = _.isNil(options.filters) ? {} : options.filters;
    if (_.isString(filters)) {
      try {
        filters = JSON.parse(filters as string);
      } catch (error) {
        console.warn('Could not parse filters', options.filters);
      }
    }
    return filters as IAppliedFilters;
  },
  getCustomFormatTogglesFromViz: (viz: IViz): IToggle[] => {
    const options: IVizOptions = { ...viz?.options };
    let customFormatToggles = _.isNil(options.customFormatToggles)
      ? []
      : options.customFormatToggles;
    if (_.isString(customFormatToggles)) {
      try {
        customFormatToggles = JSON.parse(customFormatToggles as string);
      } catch (error) {
        console.warn(
          'Could not parse customFormatToggles',
          options.customFormatToggles,
        );
      }
    }
    const chartSpec = getChartSpecs()[viz?.chartType];

    const availableToggles: ICustomFormatToggle[] = _.get(
      chartSpec,
      'customFormatToggles',
      [],
    ).filter((toggle: ICustomFormatToggle) => {
      return (
        !_.isFunction(toggle.isAvailable) ||
        (_.isFunction(toggle.isAvailable) && toggle.isAvailable(viz))
      );
    });

    // support previous version of customFormatToggles where it was just an array of strings
    if (_.isString((customFormatToggles as string[])[0])) {
      return availableToggles.map(possibleToggle => {
        let isOn = _.includes(
          customFormatToggles as string[],
          possibleToggle.name,
        );
        if (!isOn) {
          // see if we have a default value that we should use instead
          isOn = _.get(possibleToggle, 'default', false);
        }
        return { key: possibleToggle.name, on: isOn };
      });
    } else {
      return availableToggles.map(possibleToggle => {
        const found = ((customFormatToggles as unknown) as IToggle[]).find(
          t => possibleToggle.name === t.key,
        );
        if (!found) {
          // see if we have a default value that we should use instead
          const isOn = _.get(possibleToggle, 'default', false);
          const toggle: IToggle = { key: possibleToggle.name, on: isOn };
          // add default options
          if (_.isFunction(possibleToggle.getDefaultOptions)) {
            toggle.options = possibleToggle.getDefaultOptions(viz);
          }
          return toggle;
        } else {
          // support default options for previous versions
          if (
            _.isNil(found.options) &&
            _.isFunction(possibleToggle.getDefaultOptions)
          ) {
            found.options = possibleToggle.getDefaultOptions(viz);
          }
          return found;
        }
      });
    }
  },

  isCustomFormatToggleOn: (toggleName, viz) => {
    const toggles = Viz.getCustomFormatTogglesFromViz(viz);
    const toggleSetting = toggles.find(t => t.key === toggleName);
    if (!_.isNil(toggleSetting)) {
      return toggleSetting.on;
    }
    return false;
  },
  getCustomFormatToggleOptions: (toggleName, viz) => {
    const toggles = Viz.getCustomFormatTogglesFromViz(viz);
    const toggleSetting = toggles.find(t => t.key === toggleName);
    if (!_.isNil(toggleSetting)) {
      return !_.isNil(toggleSetting.options) ? toggleSetting.options : {};
    }
    return {};
  },
  // can use useVizOptionSelector with 'fieldMetadata'
  getAllFieldMeta: (viz: IViz) => {
    return Viz.getVizOption(viz, 'fieldMetadata', viz?.options?.fieldMetadata);
  },
  getAllAvailableFields: (viz: IViz): IAnyAttribute[] => {
    if (!_.isNil(viz)) {
      const calcs = Viz.getCalcsFromViz(viz);
      const timeCalcs = Object.values(Viz.getTimeCalcsFromViz(viz)).reduce(
        (accum, curr) => {
          return [...accum, ...curr];
        },
        [],
      );
      const dsAttributes = _.get(viz, 'dataset.attributes', []);
      const allFields = [
        ...(!_.isNil(dsAttributes) ? dsAttributes : []),
        ...calcs,
        ...timeCalcs,
      ];
      return allFields;
    }
    return [];
  },
  getSlicers: (viz): string[] => {
    return _.get(viz, 'layout.SLICER', []);
  },
  getSlicerSelections: (viz): ISlicerSelection[] => {
    try {
      return JSON.parse(_.get(viz, 'options.slicerSelections', '[]'));
    } catch (err) {
      return [];
    }
  },
  findDependentCalcs: (calcField, calcFields) => {
    if (_.isNil(calcField)) {
      return [];
    }
    const calcs = [...calcFields];
    const dependents = calcs.filter(c => {
      const found = c.formula.indexOf(`[${calcField.name}]`);
      return found >= 0;
    });
    return dependents;
  },
  findMissingFields: (/* Viz */ viz) => {
    if (_.isNil(viz)) {
      return {};
    }
    const inPlay = Viz.getAllFieldsInPlay(viz.layout);
    const filters = Viz.getFiltersFromViz(viz);

    let allFields = Viz.getAllAvailableFields(viz);
    // check the calcs to see if they reference any fields not available
    let allBadCalcs = [];
    let badCalcs;
    while (
      !_.isEmpty(
        (badCalcs = Viz.calcFieldsThatHaveMissingReferences(allFields)),
      )
    ) {
      // remove any bad calc fields
      allFields = allFields.filter(f => !_.some(badCalcs, _.pick(f, 'name')));
      allBadCalcs = [...allBadCalcs, ...badCalcs];
    }

    const combined = [...Viz.getAllAvailableFields(viz), ...inPlay];
    // are there any fields in play that are on the bad list?
    // are there any fields in play that reference a field that is no longer available?
    const report = combined.reduce(
      (accum, current) => {
        const isBad = _.some(
          _.map(allBadCalcs, 'name'),
          _.pick(current, 'name'),
        );
        let isMissing = false;
        if (
          current.attributeType !== Types.PRIOR_PERIOD_CALC &&
          current.attributeType !== Types.CALC &&
          current.attributeType !== Types.STRING_CALC &&
          current.attributeType !== Types.TIME_CALC
        ) {
          isMissing = !_.some(allFields, _.pick(current, 'name'));
        } else if (current.attributeType === Types.TIME_CALC) {
          isMissing = !_.some(allFields, { name: current.parentField?.name });
        }

        if (isBad) {
          accum.missingDependantFields.push(current);
        } else if (isMissing) {
          accum.missingFields.push(current);
        } else {
          accum.goodFields.push(current);
        }
        return accum;
      },
      { goodFields: [], missingFields: [], missingDependantFields: [] },
    );

    let impactedFilters = [];
    if (!_.isEmpty(allFields)) {
      impactedFilters = Object.values(filters).reduce((accum, f) => {
        // check the field name and any aggregation context field names
        let isImpacted = !_.some(allFields, {
          name: f.field,
        });
        if (!isImpacted && !_.isNil(f.aggregationContext)) {
          const isAggContextFieldImpacted = !_.some(allFields, {
            name: f.aggregationContext.field,
          });
          if (isAggContextFieldImpacted) {
            // this field should be considered missing too
            report.missingFields.push(f.aggregationContext.field);
          }
          isImpacted = isAggContextFieldImpacted;
        }
        if (isImpacted) {
          accum.push(f);
        }
        return accum;
      }, []);
    }

    return {
      missingFields: _.uniqBy(report.missingFields, 'name'),
      missingDependantFields: _.uniqBy(report.missingDependantFields, 'name'),
      goodFields: _.uniqBy(report.goodFields, 'name'),
      missingFilters: impactedFilters,
    };
  },
  /**
   * See {@link https://regex101.com/r/iQLlIY/1} for an example and breakdown of this regex
   * @param formula
   * @returns Array of field names (unescaped) referenced in the calculation
   */
  getFieldsReferencedInCalc: formula => {
    //
    // Safari does not support negative lookbehinds, so we can't use something like this:
    //   const m = /(?<![\\])\[(.+?)(?<![\\])\]/g
    // So, instead we will:
    //   1. swap escaped square brackets with obscure unicode chars that looks like them
    //   2. use a simple regex that assumes the only square brackets denote begining and end of fields
    //   3. swap the escaped brackets back in before we send back the results
    //
    const m = /\[(.+?)]/g;
    // replace escaped square brackets with an obscure unicode char that looks similar
    const swappedFormula = formula.replace(/\\\[/g, '【').replace(/\\]/g, '】');

    const referencedFieldNames = new Set();
    let match;
    while ((match = m.exec(swappedFormula)) !== null) {
      if (match.length === 2) {
        const fieldName = match[1];
        // add the first capture group to the list of fields referenced
        const putBackTheEscapedSquareBrackets = fieldName
          .replace(/【/g, '\\[')
          .replace(/】/g, '\\]');
        referencedFieldNames.add(
          unescapeFieldName(putBackTheEscapedSquareBrackets),
        );
      }
    }
    return Array.from(referencedFieldNames);
  },

  calcFieldsThatHaveMissingReferences: (/* Array */ fields) => {
    const calcsWithMissingRefs = [];
    // normal calc fields
    fields
      .filter(f => !_.isNil(f.formula))
      .forEach(calc => {
        // Safari doesn't support positive lookbehind regex, have to use capture group
        const referencedFieldNames = Viz.getFieldsReferencedInCalc(
          calc.formula,
        );
        const hasMissing = _.isNil(referencedFieldNames)
          ? []
          : referencedFieldNames.filter(f => {
              return !_.some(fields, { name: f });
            });
        if (!_.isEmpty(hasMissing)) {
          calcsWithMissingRefs.push(calc);
        }
      });
    // handle prior period calc fields too
    fields
      .filter(f => f.attributeType === Types.PRIOR_PERIOD_CALC)
      .forEach(calc => {
        const referencedFieldNames = [
          calc.timeField.name,
          calc.timeField.parentField.name,
          calc.valueField.name,
        ];
        const hasMissing = _.isNil(referencedFieldNames)
          ? []
          : referencedFieldNames.filter(f => {
              return !_.some(fields, { name: f });
            });
        if (!_.isEmpty(hasMissing)) {
          calcsWithMissingRefs.push(calc);
        }
      });

    return calcsWithMissingRefs;
  },
  calcFieldsThatHaveMissingReferencesInLayout: (vizLayout: ILayout) => {
    let allFields = [];
    Object.values(vizLayout).forEach(fields => {
      allFields = [...allFields, ...fields];
    });
    return Viz.calcFieldsThatHaveMissingReferences(allFields);
  },

  findCalcFieldsReferencingFieldName: (
    fieldName: string,
    vizLayout: ILayout,
  ) => {
    return _(vizLayout)
      .values()
      .flatten()
      .filter(f =>
        _.includes((f as ITimeCalcAttribute).formula, `[${fieldName}]`),
      )
      .value();
  },
  isFieldInPlay: (/* String */ fieldName, /* Viz layout */ vizLayout) => {
    const inPlay = Viz.getAllFieldsInPlay(vizLayout);
    return inPlay.find(f => f.name === fieldName);
  },
  findShelfContainingField: (vizLayout: ILayout, fieldName: string) => {
    let shelf = null;
    Object.entries(vizLayout).forEach(([shelfId, fields]) => {
      const isInShelf = fields?.find(f => f.name === fieldName);
      if (isInShelf) {
        shelf = shelfId;
      }
    });
    return shelf;
  },
  findShelvesContainingField: (
    vizLayout: ILayout,
    fieldName: string,
  ): ShelfName[] => {
    return _(vizLayout)
      .pickBy(fields => _.some(fields, { name: fieldName }))
      .keys()
      .value() as ShelfName[];
  },
  findShelfAndPositionContainingField: (
    vizLayout: ILayout,
    fieldName: string,
  ) => {
    let shelf = {};
    Object.entries(vizLayout).forEach(([shelfId, fields]) => {
      const isInShelf = fields.findIndex(f => f.name === fieldName);
      if (isInShelf > -1) {
        shelf = {
          shelfId,
          position: isInShelf,
        };
      }
    });
    return _.isEmpty(shelf) ? null : shelf;
  },
  fieldNameIsReferencedByPriorPeriodCalc: (
    vizLayout: ILayout,
    fieldName: string,
  ) => {
    return Viz.fieldIsReferencedByPriorPeriodCalc(vizLayout, {
      name: fieldName,
    });
  },
  fieldIsReferencedByPriorPeriodCalc: (
    vizLayout: ILayout,
    field: { name: string },
  ) => {
    return Viz.getAllFieldsInPlay(vizLayout).filter(fieldInPlay => {
      let isSecondaryilyRequired = false;
      if (!_.isNil(fieldInPlay.requiredFields)) {
        isSecondaryilyRequired = _.some(
          fieldInPlay.requiredFields,
          _.pick(field, 'name'),
        );
      }
      return (
        fieldInPlay.attributeType === Types.PRIOR_PERIOD_CALC &&
        (fieldInPlay.timeField.name === field.name ||
          fieldInPlay.valueField.name === field.name ||
          isSecondaryilyRequired)
      );
    });
  },
  replaceLegacyFilterExpression: (expression: IExpression): IExpression => {
    // map any legacy operator values
    if (!_.isNil(expression.operator)) {
      expression.operator = FilterOperators.replaceLegacyFilterOperators(
        expression.operator,
      );
    }
    if (!_.isNil(expression.left) && !_.isNil(expression.left.operator)) {
      expression.left.operator = FilterOperators.replaceLegacyFilterOperators(
        expression.left.operator,
      );
    }
    if (!_.isNil(expression.right) && !_.isNil(expression.right.operator)) {
      expression.right.operator = FilterOperators.replaceLegacyFilterOperators(
        expression.right.operator,
      );
    }

    return expression;
  },
  doQueryFilterDynamicReplacement: (filter: IQueryFilter) => {
    const user = getCurrentUser();
    let { operands, operator } = filter;
    if (
      operands.length === 1 &&
      isDashletOnlyDynamicFieldValue(_.head(operands))
    ) {
      if (!isDashletMode() || !getDynamicValues()[_.head(operands)]) {
        return null;
      }
    }

    let requiresOperatorInList = false;
    operands = _.map(operands, o => {
      switch (o) {
        case DynamicFields.Users.name:
          return user?.fullName;
        case DynamicFields.Users.userName:
          return user?.userName;
        case DynamicFields.Users.id:
          return _.last(_.split(user?.id, ':'));
        default:
          break;
      }
      const dynamicValue = (getDynamicValues() as DynamicValue)[o];
      if (dynamicValue) {
        if (isDashletMode()) {
          if (
            o === DynamicFields.Forecasts.reportees &&
            !_.isEmpty(dynamicValue)
          ) {
            requiresOperatorInList = true;
          }
          return dynamicValue;
        } else {
          return null;
        }
      }
      return o;
    }).filter(_.negate(_.isNil));

    if (requiresOperatorInList) {
      operator = IN_LIST.queryOperator;
      operands = (_.isArray(_.head(operands))
        ? _.head(operands)
        : operands) as string[];
    }

    return { ...filter, operands, operator };
  },
  isJoiningOperator: (operator: string) => {
    return _.includes([LogicalOperators.AND, LogicalOperators.OR], operator);
  },
  getFiltersFromSlicers: (viz: IViz, ignore?: string[]): IFilter[] => {
    try {
      const slicerSelections = JSON.parse(
        _.get(viz, 'options.slicerSelections', '[]'),
      );
      const newFilters = _(slicerSelections)
        .groupBy('name')
        .omit(ignore)
        .mapValues(values => _.map(values, 'option'))
        .map((values, name) => {
          const field = _.find(Viz.getAllAvailableFields(viz), { name });
          if ([Types.TIMESTAMP].includes(field.attributeType)) {
            const filterType = _.last(values);
            const valuesWithoutType = _.dropRight(values);
            const filters = {
              [TimestampSlicerTypes.RANGE]: () => {
                const includesPartialPeriod =
                  _.last(valuesWithoutType) === 'true';
                const filterOperator = includesPartialPeriod
                  ? 'past'
                  : 'thisAndPast';
                return DateFilter(field, valuesWithoutType, filterOperator);
              },
              [TimestampSlicerTypes.SINCE]: () => {
                const filter = createFilterForField(field);
                const defaultOperatorForType = FilterOperators.forFilterType(
                  filter.type,
                  TimestampFilterSubTypes.SET_CONDITION,
                );
                filter.expression.left.operator =
                  defaultOperatorForType.gte?.key;
                filter.expression.left.operands = valuesWithoutType;
                filter.expression.operator = 'AND';
                filter.expression.right = new Condition(
                  defaultOperatorForType.lte?.key,
                  ['=now()'],
                );
                filter.subType = TimestampFilterSubTypes.SET_CONDITION;
                return filter;
              },
              [TimestampSlicerTypes.BETWEEN]: () => {
                const filter = createFilterForField(field);
                const defaultOperatorForType = FilterOperators.forFilterType(
                  filter.type,
                  TimestampFilterSubTypes.SET_CONDITION,
                );
                filter.expression.left.operator =
                  defaultOperatorForType.gte?.key;
                filter.expression.left.operands = [_.head(valuesWithoutType)];
                filter.expression.operator = 'AND';
                filter.expression.right = new Condition(
                  defaultOperatorForType.lte?.key,
                  [_.last(valuesWithoutType)],
                );
                filter.subType = TimestampFilterSubTypes.SET_CONDITION;
                return filter;
              },
            };

            if (_.isNil(filters[filterType])) {
              return DateFilter(field, values, 'thisAndPast');
            }

            return filters[filterType]();
          }
          return InListFilter(field, values);
        })
        .value();
      return newFilters;
    } catch {
      return [];
    }
  },
  shouldApplyFilterToAggregation: (viz, field) => {
    if (!field) {
      return false;
    }
    const fieldShelf = Viz.findShelfContainingField(viz.layout, field.name);
    return (
      (field.attributeType === Types.NUMBER ||
        field.attributeType === Types.CALC) &&
      !_.isNil(fieldShelf) &&
      fieldShelf.shelfType === ShelfTypes.MEASURE
    );
  },
  filterQueryAccumulator: (
    viz: IViz,
    filterExpression: IExpression | IExpressionSegment,
    previousFilterMeta: {
      field: IAnyAttribute;
      filter: IFilter;
      conjunctionQuery?: IQueryFilter;
    },
  ): IQueryFilter[] => {
    /*
    // Assumes logic (binary) tree is unbalanced to the left.
    - for the provided filter expression
      - evaluate left hand side
      - if right exists, evaluate right
      - create IFilterQuery when evaluating an IExpressionSegment
     */

    const filterMeta = { ...previousFilterMeta };

    const { left, operator, right } = Viz.replaceLegacyFilterExpression(
      filterExpression as IExpression,
    );

    let expressionOperatorKey: string = _.isString(operator)
      ? (operator as string)
      : _.get(operator, 'key', null);
    if (expressionOperatorKey === 'currentUser') {
      expressionOperatorKey = 'dynamicField';
    }

    const filterOperatorsForFilterType = FilterOperators.forFilterType(
      filterMeta.filter.type,
      filterMeta.filter.subType,
    );

    const expressionOperator: IOperator = !_.isNil(expressionOperatorKey)
      ? filterOperatorsForFilterType[expressionOperatorKey]
      : null;

    // make conjunction operator if needed. Only one should exist per field
    if (
      _.isNil(filterMeta?.conjunctionQuery) &&
      _.isString(expressionOperatorKey) &&
      _.has(LogicalOperators, expressionOperatorKey) &&
      _.isNil(expressionOperator) &&
      !_.isNil(right) &&
      Viz.validateRightOperation(
        expressionOperatorKey,
        expressionOperator,
        filterOperatorsForFilterType,
        right,
      )
    ) {
      const conjunction: IQueryFilter = {
        attributeName: filterMeta.field.name,
        operator,
        operands: [],
      };

      // only apply filtering to aggregated data if its a number field and the shelf its on wants measures
      if (Viz.shouldApplyFilterToAggregation(viz, filterMeta.field)) {
        conjunction.aggregation = filterMeta.field.defaultAggregation;
      }

      filterMeta.conjunctionQuery = conjunction;
    }

    let queryFilters = [];

    // non-leaf nodes get evaluated as 1) left, 2) right
    if (_.has(filterExpression as IExpression, 'left')) {
      // evaluate left expression
      queryFilters = _.concat(
        queryFilters,
        Viz.filterQueryAccumulator(viz, left as IExpression, filterMeta),
      );

      // non-leaf nodes can evaluate a right expression. Rights can only be leaves
      if (
        Viz.validateRightOperation(
          expressionOperatorKey,
          expressionOperator,
          filterOperatorsForFilterType,
          right,
        )
      ) {
        // evaluate right expression
        queryFilters = _.concat(
          queryFilters,
          Viz.filterQueryAccumulator(
            viz,
            right as IExpressionSegment,
            filterMeta,
          ),
        );
      }

      // add the conjunction filter if needed
      if (
        !_.isNil(filterMeta?.conjunctionQuery) &&
        !_.find(queryFilters, filterMeta.conjunctionQuery)
      ) {
        queryFilters = _.concat(queryFilters, [filterMeta.conjunctionQuery]);
      }

      return queryFilters;
    }

    // filterExpression is IExpressionSegment. evaluate the leaf node
    if (
      !_.isNil(expressionOperatorKey) &&
      !_.isNil(expressionOperator) &&
      (!expressionOperator.requiresOperand ||
        !_.isEmpty((filterExpression as IExpressionSegment).operands))
    ) {
      const _filterExpression: IExpressionSegment = _.cloneDeep(
        filterExpression,
      ) as IExpressionSegment;

      if (!_.isNil(filterMeta.filter.aggregationContext)) {
        // add the agg context as operands
        _filterExpression.operands = [
          ...(filterExpression as IExpressionSegment).operands,
          filterMeta.filter.aggregationContext.field,
          filterMeta.filter.aggregationContext.aggregation,
        ];
      }
      const queryfilter: IQueryFilter =
        filterMeta.filter.dialogType === FilterDialogTypes.AGGREGATE &&
        filterMeta.filter.subType === AggregateFilterSubTypes.SET_CONDITION
          ? {
              attributeName: filterMeta.filter.field,
              operator: aggregateFilterOperators.measure.queryOperator,
              operands: [
                ..._filterExpression.operands,
                expressionOperator.queryOperator,
              ],
            }
          : {
              attributeName: filterMeta.filter.field,
              operator: expressionOperator.queryOperator,
              operands: _filterExpression.operands,
            };

      // only apply filtering to aggregated data if its a number field and the shelf its on wants measures
      if (Viz.shouldApplyFilterToAggregation(viz, filterMeta.field)) {
        queryfilter.aggregation = filterMeta.field.defaultAggregation;
      }

      return [queryfilter];
    }

    // something went wrong
    return [];
  },
  validateRightOperation: (
    expressionOperatorKey,
    expressionOperator,
    filterOperatorsForFilterType,
    right,
  ) => {
    const rightOperator = right?.operator
      ? filterOperatorsForFilterType[right.operator as string]
      : null;

    return (
      _.isString(expressionOperatorKey) &&
      (!_.isNil(expressionOperator) ||
        _.includes(
          [LogicalOperators.OR, LogicalOperators.AND],
          expressionOperatorKey,
        )) &&
      rightOperator?.requiresOperand ===
        !_.isEmpty((right as IExpressionSegment).operands)
    );
  },
  mapFiltersToQuery: (
    viz: IViz,
    ignoreSlicer?: string | string[],
  ): IQueryWithFilters => {
    let filters = [];

    const allFields = Viz.getAllAvailableFields(viz);

    let savedFilters: IFilter[] | string =
      !_.isNil(viz.options) && !_.isNil(viz.options.filters)
        ? (viz.options.filters as any)
        : [];
    if (_.isString(savedFilters)) {
      try {
        savedFilters = JSON.parse(savedFilters as string) as IFilter[];
      } catch (error) {
        console.warn(
          'Could not map filters to query. No filters active',
          savedFilters,
        );
        savedFilters = [];
      }
    }

    const ignoreSlicerFilterNames: string[] = _.isArray(ignoreSlicer)
      ? (ignoreSlicer as string[])
      : [ignoreSlicer as string];
    filters = _(savedFilters)
      .map()
      .concat(Viz.getFiltersFromSlicers(viz, ignoreSlicerFilterNames))
      .reduce((accum, f: IFilter) => {
        const filterField = allFields.find(field => field.name === f.field);
        if (!filterField) {
          return accum;
        }

        const queryFilters = Viz.filterQueryAccumulator(viz, f.expression, {
          filter: f,
          field: filterField,
        });
        return _.concat(
          accum,
          _.map(queryFilters, Viz.doQueryFilterDynamicReplacement).filter(
            Boolean,
          ),
        );
      }, []);

    return { filters };
  },
  mapSortsToQuery: (): IQueryWithSorts => {
    return { sorts: [] };
  },
  mapCalcsToQuery: (viz: IVizBase): IQueryWithCalc => {
    let calcs: IQueryCalc[] = [];
    const savedCalcs = Viz.getRequiredCalcs(viz);

    if (!_.isEmpty(savedCalcs)) {
      calcs = savedCalcs.reduce((accum, c) => {
        let { formula } = c;
        if (c.attributeType === Types.PRIOR_PERIOD_CALC) {
          formula = Viz.generatePriorPeriodCalcFormula(c, viz);
        }
        accum.push({
          attributeName: c.name,
          expression: formula,
        });
        return accum;
      }, []);
    }

    // Add in Links
    const links = getAllLinksEnabledInReport(
      viz,
      Viz.getAllFieldsInPlay(viz.layout),
    );
    const linkCalcs: IQueryCalc[] = _.flatMap(
      Viz.getAllFieldsInPlay(viz.layout),
      attr => {
        const link = links[attr.name];
        if (link) {
          const linkFormula = [link.linkText, link.link];
          linkFormula.push(link.linkTooltip || link.linkText);
          return [
            {
              expression: linkFormula.join('+ "||" +'),
              attributeName: `${attr.name} :: link`,
            },
          ];
        } else {
          return [];
        }
      },
    );

    let timeCalcs: IQueryCalc[] = [];
    if (!_.isNil(viz.options) && !_.isNil(viz.options.timeHierarchies)) {
      let savedTimeHierarchies:
        | { [key: string]: IAnyAttribute[] }
        | string = viz.options.timeHierarchies as any;
      if (_.isString(savedTimeHierarchies)) {
        try {
          savedTimeHierarchies = JSON.parse(savedTimeHierarchies as string);
        } catch (error) {
          console.warn(
            'Could not map date calcs to query. No date calcs active',
            savedTimeHierarchies,
          );
          savedTimeHierarchies = {};
        }
      }
      if (!_.isEmpty(savedTimeHierarchies)) {
        timeCalcs = Object.values(savedTimeHierarchies).reduce(
          (accum: IQueryCalc[], hierarchyFields) => {
            if (!_.isEmpty(hierarchyFields)) {
              hierarchyFields.forEach(f => {
                // we only care about it if this field is in play
                if (Viz.isFieldInPlay(f.name, viz.layout)) {
                  // if a field is the only time attributes used in a prior period calc then we need to use the priorPeriodFormula (essentially a date_trunc vs date_part)
                  let includePriorPeriodCalc = false;

                  if (!_.isEmpty(savedCalcs)) {
                    const priorPeriodCalcs = savedCalcs.filter(c => {
                      return (
                        c.attributeType === Types.PRIOR_PERIOD_CALC &&
                        f.parentField.name === c.timeField.parentField.name
                      );
                    });
                    includePriorPeriodCalc = priorPeriodCalcs.length > 0;
                  }

                  accum.push({
                    attributeName: f.name,
                    expression: (f as ITimeCalcAttribute).formula,
                  });
                  if (includePriorPeriodCalc) {
                    accum.push({
                      attributeName: f.name,
                      expression: (f as ITimeCalcAttribute).priorPeriodFormula,
                    });
                  }

                  const formula = f.ordinalFormula || recreateFormula(f);
                  accum.push({
                    attributeName: createOrdinalName(f.name),
                    expression: formula,
                  });
                }
              });
            }
            return accum;
          },
          [],
        );
      }
    }

    return { calcs: [...calcs, ...timeCalcs, ...linkCalcs] };
  },
  buildQueryVariables: (
    viz: IViz,
    chartSpec: IBaseChartSpec = null,
  ): IQuery => {
    let cs: any = chartSpec;
    if (_.isNil(cs)) {
      cs = getChartSpecs()[viz.chartType];
    }
    let calcs: IQueryWithCalc;
    if (_.isFunction(cs.mapCalcsToQuery)) {
      calcs = cs.mapCalcsToQuery(viz);
    } else {
      calcs = Viz.mapCalcsToQuery(viz);
    }
    let subtotals: IQueryWithSubtotals;
    if (_.isFunction(cs.mapSubtotalsToQuery)) {
      subtotals = cs.mapSubtotalsToQuery(viz);
    }

    const useFiscalCalendar: boolean =
      viz?.options?.useFiscalCalendar === 'true';

    const calcDynamicValues = _.map(
      _.toPairs(
        _.omitBy(
          _.pick(getDynamicValues(), [
            DynamicFields.Forecasts.timeperiodstart,
            DynamicFields.Forecasts.timeperiodend,
          ]),
          _.isNil,
        ),
      ),
      ([name, value]) => ({
        name,
        value,
      }),
    );

    const variables = {
      id: viz.dataset?.id,
      ...cs.mapShelvesToQueryVariables({
        ...viz,
        getValueShelves: Viz.getValueShelves,
      }),
      ...Viz.mapFiltersToQuery(viz, []),
      ...Viz.mapSortsToQuery(),
      ...calcs,
      ...subtotals,
      useFiscalCalendar,
    };

    if (!_.isEmpty(calcDynamicValues)) {
      variables.calcDynamicValues = calcDynamicValues;
    }

    return variables;
  },

  generatePriorPeriodCalcFormula: (priorPeriodCalcField, viz) => {
    const {
      valueField,
      priorPeriodConversion,
      priorPeriodType,
    } = priorPeriodCalcField;
    const conversionUnits = _.isNil(priorPeriodConversion)
      ? 1
      : priorPeriodConversion.units;

    let vfFormula = `${valueField.defaultAggregation}([${escapeFieldName(
      valueField.name,
    )}])`;
    if (valueField.defaultAggregation === 'NONE') {
      vfFormula = `[${escapeFieldName(valueField.name)}]`;
    }
    const inPlay = Viz.getAllFieldsInPlay(viz.layout);

    // example prior calc function: Prior(SUM([Sales]), 12, list([Year_TxDate],[Month_TxDate]), list([Territory]))
    // get all time fields in play that are in the same hierarchy
    const timeFields = inPlay.filter(fieldInPlay => {
      return (
        fieldInPlay.attributeType === Types.TIME_CALC &&
        fieldInPlay.parentField.name ===
          priorPeriodCalcField.timeField.parentField.name
      );
    });

    const fieldRefList = timeFields.map(tf => {
      return `[${escapeFieldName(tf.name)}]`;
    });

    // All of the fields in play that are not measures and are not timeFields used in the prior calc are fields to partition on
    const partitioningFields = inPlay
      .filter(fieldInPlay => {
        if (!_.isEmpty(timeFields)) {
          return !_.some(timeFields, _.pick(fieldInPlay, 'name'));
        }
        return true;
      })
      .filter(fieldInPlay => {
        const shelf = Viz.findShelfContainingField(
          viz.layout,
          fieldInPlay.name,
        );
        if (shelf) {
          const shelfDef = getChartSpecs()[viz.chartType].shelves[shelf];
          return ShelfTypes.SELECTION === shelfDef.shelfType;
        }
      });
    const partitionRefList = _.isEmpty(partitioningFields)
      ? []
      : partitioningFields.map(pf => {
          return `[${escapeFieldName(pf.name)}]`;
        });
    const partitionArg = _.isEmpty(partitionRefList)
      ? ''
      : `, list(${partitionRefList.join(', ')})`;
    let formula = `Prior(${vfFormula}, ${conversionUnits}, list(${fieldRefList.join(
      ', ',
    )})${partitionArg})`;

    if (_.isEqual(priorPeriodType, PriorPeriodTypes.CHANGE_FROM)) {
      formula = `${vfFormula} - ${formula}`;
    } else if (_.isEqual(priorPeriodType, PriorPeriodTypes.PCT_CHANGE_FROM)) {
      formula = `((${vfFormula} - ${formula}) / ${formula}) * 100`;
    }
    return formula;
  },
  priorPeriodCalcsFieldInvalidates: (field, layout) => {
    // if the field is a time attribute, make sure it is not a more granular attribute than the lowest in any prior period calc field already in play
    if (!_.isNil(field.timeAttribute)) {
      let priorPeriodsRefs = [];

      // get the parent field
      const timeAttributesInPlay = Viz.getAllFieldsInPlay(layout)
        .filter(inPlay => {
          // capture the unique prior period calcs that would be impacted
          priorPeriodsRefs = _.unionBy(
            priorPeriodsRefs,
            Viz.fieldIsReferencedByPriorPeriodCalc(layout, inPlay),
            'name',
          );

          // we just want the ones in the same hierarchy
          return (
            inPlay.attributeType === Types.TIME_CALC &&
            inPlay.parentField.name === field.parentField.name &&
            !_.isEmpty(priorPeriodsRefs)
          );
        })
        .map(tf => {
          return (tf as ITimeCalcAttribute).timeAttribute;
        });
      const mostGranular = Hierarchy.determineMostGranular(
        [...timeAttributesInPlay, field.timeAttribute],
        Hierarchy.TIME_ATTRIBUTES.EXACT_DATE,
      );
      if (
        !_.isEmpty(timeAttributesInPlay) &&
        _.isEqual(mostGranular, field.timeAttribute)
      ) {
        return priorPeriodsRefs;
      }
    }
    return [];
  },
  multiLineTSpans(textLabel, containerWidth, isBelowBar = false) {
    let renderedLabel = [
      {
        text: textLabel,
        dy: 0,
        x: 0,
      },
    ];
    const breakLineSeparator = '\n';
    let multilineLabel = false;
    if (_.includes(textLabel, breakLineSeparator)) {
      multilineLabel = true;
      renderedLabel = _.map(
        _.split(textLabel, breakLineSeparator),
        (_text, idx) => {
          let dyOffset = 1.2;
          if (idx == 0) {
            dyOffset = isBelowBar ? 0.3 : 0;
          }
          return {
            x: 0,
            dy: dyOffset,
            text: _text,
          };
        },
      );
    }
    return { label: renderedLabel, isMultiline: multilineLabel };
  },
  getContrastColor: (bgColor: IRgbColor & string, theme): string => {
    const { colors: { ContrastColorDark, ContrastColorLight } = {} as any } =
      theme || {};
    const contrastColor = (r, g, b) => {
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? ContrastColorDark : ContrastColorLight;
    };
    if (_.isString(bgColor)) {
      // Convert to RGB
      const hex = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
      const r = parseInt(hex.substr(0, 2), 16); // hex to R
      const g = parseInt(hex.substr(2, 2), 16); // hex to G
      const b = parseInt(hex.substr(4, 2), 16); // hex to B
      return contrastColor(r, g, b);
    } else if (_.isObject(bgColor)) {
      const { r, g, b } = bgColor;
      return contrastColor(r, g, b);
    } else {
      return ContrastColorDark;
    }
  },

  /**
   * Deprecated. Use Constants.js -> DATA_FORMATTERs
   */
  formatNumber: value => {
    // Ensure value is number
    value = _.toNumber(value);
    const absValue = Math.abs(value);
    const hasDecimal = value % 1 !== 0;
    if (value === 0) {
      return 0;
    } else if (value > -1 && value < 1) {
      // Show 2 decimals
      return value.toFixed(2);
    } else if (absValue >= 1 && absValue < 1000) {
      // Show 2 decimals if they exist
      return hasDecimal ? value.toFixed(2) : value;
    } else if (absValue >= 1000 && absValue < 1000000) {
      // Show 3 significant digits
      return d3
        .format('.3s')(value)
        .replace('G', 'B');
    } else {
      // Default to 2 significant digits
      return d3
        .format('.2s')(value)
        .replace('G', 'B');
    }
  },
  calcTextWidth: text => {
    return Util.calcTextWidth(text, `10px ${DefaultFontName}`);
  },
  calcTextHeight: text => {
    return Util.calcTextHeight(text, `10px ${DefaultFontName}`);
  },
  isTextTooBig: (text, width) => {
    return Viz.calcTextWidth(text) > width;
  },
  formatNumberToFit: (
    value,
    width,
    formatter,
    i18nPrefs: IInternationalizationPreferences = {},
    customFormatProps?: ICustomFormatProps,
  ) => {
    // Ensure value is number
    value = _.toNumber(value);
    const f = formatter || DATA_FORMATTER.NUMBER;
    const originalFormatVal = f.format(value, i18nPrefs, customFormatProps);
    let val = originalFormatVal;

    if (Viz.isTextTooBig(originalFormatVal, width)) {
      // try to shorten it with a format
      val = f.formatSmall(value, i18nPrefs, customFormatProps);
      const prefixValue = customFormatProps?.prefixValue;
      const suffixValue = customFormatProps?.suffixValue;
      const breakLineSeparator = '\n';
      if (_.isString(prefixValue) && !_.isEmpty(prefixValue)) {
        val = _.replace(
          val,
          prefixValue,
          `${prefixValue}${breakLineSeparator}`,
        );
      }
      if (_.isString(suffixValue) && !_.isEmpty(suffixValue)) {
        val = _.replace(
          val,
          suffixValue,
          `${breakLineSeparator}${suffixValue}`,
        );
      }

      let widestTextWidth = 0;
      _.forEach(_.split(val, breakLineSeparator), _text => {
        widestTextWidth = _.max([widestTextWidth, Viz.calcTextWidth(_text)]);
      });
      if (widestTextWidth > width) {
        // hide
        val = null;
      }
    }
    return val;
  },
  /**
   * returns a map of field name to formatter object (one of the Constants.js DATA_FORMATTERs)
   * @param viz
   */
  getDataFormatters: viz => {
    const fields = Viz.getAllFieldsInPlay(viz.layout);
    const metricFields = Viz.getAllMetricFieldsInPlay(viz);
    return fields.reduce((accum, current) => {
      // is this a metric field?
      const isMetricField = _.some(metricFields, _.pick(current, 'name'));
      // if it is a metric and its default aggregation is count or count distinct, force the format to whole number
      if (
        isMetricField &&
        (current.defaultAggregation === 'Count' ||
          current.defaultAggregation === 'Count (Distinct)')
      ) {
        accum[current.name] = DATA_FORMATTER.WHOLE_NUMBER;
        return accum;
      }

      const formatter = DATA_TYPE_FORMAT.getFormatterByName(current.formatType);
      if (formatter) {
        accum[current.name] = formatter;
      } else {
        accum[current.name] = DATA_TYPE_FORMAT.getDefaultFormatterForType(
          current.attributeType,
        );
      }
      return accum;
    }, {});
  },

  /**
   * returns map of fieldnames and customFormatProps
   * @param viz
   */
  getDataCustomFormatters: (
    viz: IViz,
  ): { [key: string]: ICustomFormatProps } => {
    return Viz.getAllMetricFieldsInPlay(viz).reduce((accum, current) => {
      accum[current.name] = !_.isNil(current.customFormatProps)
        ? current.customFormatProps
        : getCustomFormatProps(current.annotations);
      return accum;
    }, {});
  },

  configureCurrencySymbol: viz => {
    const datasetAnnotations = _.get(viz, 'dataset.annotations', []);
    const currencySymbol = _.find(datasetAnnotations, {
      key: 'DEFAULT_CURRENCY_SYMBOL',
    })?.value;
    if (!_.isUndefined(currencySymbol)) {
      d3.formatDefaultLocale({
        decimal: '.',
        thousands: ',',
        grouping: [3],
        currency: [currencySymbol, ''],
      });
    }
  },

  getCurrencySymbol: viz => {
    const datasetAnnotations = _.get(viz, 'dataset.annotations', []);
    const currencySymbol = _.find(datasetAnnotations, {
      key: 'DEFAULT_CURRENCY_SYMBOL',
    })?.value;
    if (!_.isUndefined(currencySymbol)) {
      return currencySymbol;
    } else {
      return '$';
    }
  },

  getAllMetricFieldsInPlay: viz => {
    const metricShelves = Viz.getValueShelves(viz);
    return metricShelves.reduce((metricFields, shelf) => {
      const fields = _.get(viz, `layout.${shelf.id}`, []);
      return [...metricFields, ...fields];
    }, []);
  },
  getAxisLabel: fields => {
    const copied = [...fields];
    const axisFields = copied.reduce((reduced, f) => {
      // if it is not a time attribute, just add it
      if (_.isNil(f.timeAttribute)) {
        reduced.push({ ...f });
      } else {
        // is this the first time attribute we find for the time hierarchy?
        const otherTimeHierarchyFields = reduced.filter(r => {
          return (
            !_.isNil(r.timeAttribute) &&
            !_.isNil(r.parentField) &&
            r.parentField.name === f.parentField.name
          );
        });
        // only add it if it is the first time we see a time attribute for this hierarchy (and use the parent field name)
        if (_.isEmpty(otherTimeHierarchyFields)) {
          reduced.push({ ...f, name: f.parentField.name });
        }
      }
      return reduced;
    }, []);
    const labelData = axisFields.map(r => r.name);
    return joinChartData(labelData);
  },
  getAxisLabelWithCurrency: (
    label: string,
    dataFormatters: { [key: string]: IFormatProto },
    currencySymbol: string,
  ) => {
    const labelsWithSymbols = map(
      split(label, FIELD_SEPARATOR_SYMBOL),
      label => {
        const cleanLabel = trim(label);
        return isEqual(
          dataFormatters[cleanLabel]?.formatType,
          DATA_FORMATTER.CURRENCY.formatType,
        )
          ? `${cleanLabel} (${currencySymbol})`
          : cleanLabel;
      },
    );

    return joinChartData(labelsWithSymbols);
  },
  getOrdinalFor: (fieldName, viz) => {
    const datasetAttributes = _.get(viz, 'dataset.attributes', []);
    const field = datasetAttributes.find(attr => attr.name === fieldName);
    if (_.isNil(field)) {
      return null;
    }
    if (!_.isEmpty(field.ordinalAttribute)) {
      const ordinal = datasetAttributes.find(
        attr => attr.name === field.ordinalAttribute,
      );
      return ordinal;
    } else {
      // maybe this field IS an ordinal
      if (field.attributeType === Types.NUMBER) {
        // see if some other field references this one as it's ordinal
        const usesOrdinal = datasetAttributes.find(
          attr => attr.ordinalAttribute === field.name,
        );
        if (usesOrdinal) {
          return field;
        }
      }
    }
    return null;
  },

  applyCssToSvgNode: (parentNode, originalNode) => {
    if (_.isNil(parentNode) || _.isNil(originalNode)) {
      return;
    }
    const SVG_CONTAINERS = ['svg', 'g'];
    const CSS_STYLES = ['fill', 'stroke', 'stroke-width', 'display']; // the css attributes we care to apply
    const RELEVANT = {
      rect: CSS_STYLES,
      path: CSS_STYLES,
      circle: CSS_STYLES,
      line: CSS_STYLES,
      text: [
        ...CSS_STYLES,
        'font-size',
        'font-family',
        'font-weight',
        'text-anchor',
      ],
      tspan: [
        ...CSS_STYLES,
        'font-size',
        'font-family',
        'font-weight',
        'text-anchor',
      ],
      polygon: CSS_STYLES,
    };
    const CSS_CLASSES_TO_HIDE = [
      'scrollContainer',
      'chart-tooltip-container',
      'line-chart-tooltip-indicator',
      'chartAreaLine',
    ];

    const children = parentNode.childNodes;
    const originalChildren = originalNode.childNodes;
    children.forEach((child, idx) => {
      for (let i = 0; i < CSS_CLASSES_TO_HIDE.length; i++) {
        if (
          !_.isEmpty(child.classList) &&
          child.classList.contains(CSS_CLASSES_TO_HIDE[i])
        ) {
          child.classList.add('hidden');
          break;
        }
      }
      if (
        _.includes(SVG_CONTAINERS, child.tagName) &&
        child.tagName === originalChildren[idx].tagName
      ) {
        Viz.applyCssToSvgNode(child, originalChildren[idx]);
      } else if (child.tagName in RELEVANT) {
        // we care about this node, get the computed style of the original node that corresponds to this one
        try {
          if (child.tagName !== originalChildren[idx].tagName) {
            // don't apply styles to children if they are not the same node type
            return;
          }
          const computedStyle = window.getComputedStyle(originalChildren[idx]);
          const style = RELEVANT[child.tagName]
            .map(cssAttribute => {
              const value = computedStyle.getPropertyValue(cssAttribute);
              return [cssAttribute, value].join(': ');
            })
            .join('; ');
          child.setAttribute('style', style);
        } catch (e) {
          // one of the original elements doesn't exist in the cloned node hierarchy... as expected
        }
      }
    });
  },
  generateChartScreenshot(
    fileNameWithoutExtension,
    theme: IDiscoverEmotionTheme,
  ) {
    const backgroundColor = theme?.colors?.ContentBackground;
    const container = document.querySelector('.viz-detail');

    // try to find the pivot table element first
    const pivot = container.querySelector('.pivot-table-wrapper');

    const clonedDetail: HTMLElement = container.cloneNode(true) as HTMLElement;

    const hideExtraneousPanels = (chartBounds, padding = 24) => {
      document.body.appendChild(clonedDetail);

      // set up all the proper sizing
      const leftPanel = clonedDetail.querySelector('.left-panel');
      const rightPanel = clonedDetail.querySelector('.right-panel');

      const legendPanel = clonedDetail.querySelector('.viz-legend');
      const legendBounds = _.isNil(legendPanel)
        ? { height: 0, width: 0 }
        : legendPanel.getBoundingClientRect();

      const filtersPanel = clonedDetail.querySelector('.viz-chart-top-panel');
      const filtersBounds = _.isNil(filtersPanel)
        ? { height: 0, width: 0 }
        : filtersPanel.getBoundingClientRect();

      const chartSummaryPanel = clonedDetail.querySelector('.chart-summary');
      const chartSummaryBounds = _.isNil(chartSummaryPanel)
        ? { height: 0, width: 0 }
        : chartSummaryPanel.getBoundingClientRect();

      const height =
        filtersBounds.height +
        chartBounds.height +
        chartSummaryBounds.height +
        padding;
      const width =
        Math.max(filtersBounds.width, chartBounds.width) +
        legendBounds.width +
        padding;

      // force the proper size with inline styles
      clonedDetail.setAttribute(
        'style',
        `height: ${height}px; width: ${width}px; padding: 8px;`,
      );

      const hrLine = clonedDetail.querySelector('hr');
      hrLine.setAttribute('style', 'display: none;');

      // turn off the side panels, we never want to include those
      leftPanel.classList.add('hidden');
      rightPanel.classList.add('hidden');
    };

    if (!_.isNil(pivot)) {
      const chart = container.querySelector('.viz-chart');
      hideExtraneousPanels(chart.getBoundingClientRect());
      const allPivotCells = clonedDetail.querySelectorAll(
        'div[aria-label=grid]',
      );
      allPivotCells.forEach(grid => {
        grid.setAttribute(
          'style',
          `${grid.getAttribute('style')} overflow: hidden;`,
        );
      });

      // generate an image of the pivot table
      html2canvas(clonedDetail)
        .then(canvas => {
          canvas.toBlob(blob => {
            document.body.removeChild(clonedDetail);
            FileSaver.saveAs(blob, `${fileNameWithoutExtension}.png`);
          });
        })
        .catch(e => {
          console.error('Error generating screenshot', e);
        });
    } else {
      // convert the inline svg to canvas
      const chartSelector = '.viz-chart svg';
      const chart = container.querySelector(chartSelector);
      const cloned: Element = chart.cloneNode(true) as Element;

      // force the svg background to white
      cloned.setAttribute('style', `background-color: ${backgroundColor};`);

      // not all applicable css is available when looking just at our original svg element. However, if we
      // inline-style the applicable styles, it will work just fine.
      Viz.applyCssToSvgNode(cloned, chart);

      // remove elements that we don't ever want to show. *This has to happen after we apply css, otherwise we can't match original to cloned nodes*
      const removeElementsSelectorsCompletely = [
        '.scrollContainer',
        '.chart-tooltip-container',
        '.line-chart-tooltip-indicator',
        '.chartAreaLine',
      ];
      removeElementsSelectorsCompletely.forEach(selector => {
        const elements = cloned.querySelectorAll(selector);
        if (!_.isEmpty(elements)) {
          elements.forEach(e => {
            e.parentNode.removeChild(e);
          });
        }
      });

      const saveOpts = {
        backgroundColor,

        // Explicitly include the fonts used in the charts. Otherwise it will try to auto-detect them and include ALL found in fonts.less.
        fonts: [
          {
            // Normal - weight 300 - latin
            text: DefaultFontName,
            url:
              'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfAZ9hjp-Ek-_EeA.woff',
            format: 'woff',
          },
          {
            // Normal - weight 600 - latin
            text: DefaultFontName,
            url:
              'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZ9hjp-Ek-_EeA.woff',
            format: 'woff',
          },
        ],
      };

      try {
        svgUtil.svgAsPngUri(cloned, saveOpts, uri => {
          // create the image element and set it's source to the png URI we generated
          const img = document.createElement('img');
          const chartBounds = chart.getBoundingClientRect();
          img.src = uri;
          img.height = chartBounds.height;
          img.width = chartBounds.width;

          // uri data for chart may be empty
          if (uri !== 'data:,') {
            // in our cloned container, replace the svg version of the chart with the png
            const replaceThis = clonedDetail.querySelector(chartSelector);
            replaceThis.parentNode.replaceChild(img, replaceThis);
          }
          hideExtraneousPanels(chartBounds);

          // DEBUGGING: to see the generated svg that will be converted to png, uncomment the next line
          //container.replaceWith(clonedDetail);

          // DEBUGGING: to see the generated svg that will be converted to png, comment out the html2canvas call
          // generate a png from the modified/cloned html/svg
          html2canvas(clonedDetail)
            .then(canvas => {
              canvas.toBlob(blob => {
                FileSaver.saveAs(blob, `${fileNameWithoutExtension}.png`);
                document.body.removeChild(clonedDetail);
              });
            })
            .catch(e => {
              console.error('Error generating screenshot', e);
            });
        });
      } catch (e) {
        console.error('Error generating screenshot', e);
      }
    }
  },
  updateVizForSave(
    viz: IViz,
    vizId: string = undefined,
    vizName: string = undefined,
    vizTags: string[] = undefined,
  ) {
    const layoutList = Object.entries(viz.layout).map(([key, value]) => {
      return { id: key, fields: value.map(f => f.name) };
    });
    const optionsList = Object.entries(viz.options)
      .map(([key, value]) => {
        if (_.isObject(value)) {
          return { key, value: JSON.stringify(value) };
        }
        return { key, value: _.toString(value) };
      })
      .filter(o => {
        // Full-blown timerHierarchies are too verbose to persist.
        // Instead, they are reconstructed when a viz is opened from
        // options.visibleTimeHierarchyLevels. So, we filter out timeHierarchies before we save
        return o.key !== 'timeHierarchies';
      });

    return Util.removeTypeNameRecursively(
      _.pick(
        {
          ...viz,
          id: _.isEmpty(vizId) ? viz.id : vizId,
          name: _.isEmpty(vizName) ? viz.name : vizName,
          layout: layoutList,
          datasetId: viz.dataset.id,
          private: viz.isPrivate,
          options: optionsList,
          tags: _.isNil(vizTags) ? viz.tags : vizTags,
          newRevision: viz.newRevision,
          commitMessage: viz.commitMessage,
        },
        [
          'id',
          'name',
          'layout',
          'options',
          'chartType',
          'datasetId',
          'private',
          'tags',
          'newRevision',
          'commitMessage',
        ],
      ),
    );
  },

  getDisplayFieldForOrdinal: (fieldName, allAvailableFields) => {
    if (_.endsWith(fieldName, FIELD_ORDINAL_SUFFIX)) {
      const displayField = allAvailableFields.find(
        f =>
          f.name ===
          fieldName
            .substring(0, fieldName.indexOf(FIELD_ORDINAL_SUFFIX))
            .trim(),
      );
      return displayField;
    } else {
      // otherwise, see if any field references this field as it's ordinal attribute
      const displayField = allAvailableFields.find(
        f => _.isNil(f.ordinalAttribute) && f.ordinalAttribute === fieldName,
      );
      return displayField;
    }
  },

  getOrdinalMappings: (viz, queryResults) => {
    const { layout } = viz;

    const chartSpec = getChartSpecs()[viz.chartType];
    const nonMetricShelves = _.values(chartSpec.shelves).filter(
      shelf => shelf.shelfType !== ShelfTypes.MEASURE,
    );
    const nonMetricFields = nonMetricShelves.reduce((fields, shelf) => {
      if (_.isNil(layout[shelf.id])) {
        return fields;
      }

      const shelfFields = layout[shelf.id].map(f => {
        // tag it with the shelf it's on for quick lookup later
        return { ...f, shelf: shelf.id };
      });

      fields = [...fields, ...shelfFields];
      return fields;
    }, []);

    const getShelf = fieldName => {
      const field = nonMetricFields.find(f => f.name === fieldName);
      return field ? field.shelf : undefined;
    };

    // get a mapping of fields, field index, and ordinal field index (don't care about the measures)
    const ordinalMappings = queryResults.executeQuery.columnInfo
      .filter(info => info.columnType !== 'MEASURE')
      .reduce((accum, info, idx) => {
        let displayDataIndex = idx;
        let ordinalIndex = idx;

        // is it a dynamic ordinal?
        const indexOfOrdinalSuffix = info.attributeName.indexOf(
          FIELD_ORDINAL_SUFFIX,
        );
        if (indexOfOrdinalSuffix !== -1) {
          // this is a dynamic ordinal, find where its corresponding data field is
          const correspondingFieldName = _.trim(
            info.attributeName.substring(0, indexOfOrdinalSuffix),
          );
          displayDataIndex = queryResults.executeQuery.columnInfo.findIndex(
            columnInfoItem =>
              columnInfoItem.attributeName === correspondingFieldName,
          );

          // update the index to use the dynamic ordinal index instead
          accum[displayDataIndex].ordinalIndex = idx;
        } else {
          // find the field in the layout
          const f = nonMetricFields.find(
            field => field.name === info.attributeName,
          );
          // see if this field has an ordinal field
          if (!_.isNil(f)) {
            if (!_.isEmpty(f.ordinalAttribute)) {
              ordinalIndex = queryResults.executeQuery.columnInfo.findIndex(
                columnInfoItem =>
                  columnInfoItem.attributeName === f.ordinalAttribute,
              );
            }
            accum.push({
              fieldName: f.name,
              index: idx,
              ordinalIndex,
              shelf: getShelf(f.name),
            });
          }
        }
        return accum;
      }, []);
    return ordinalMappings;
  },

  getRequiredCalcs: (viz: IVizBase): ICalc[] => {
    const allCalcs = Viz.getVizOption(viz, 'calcFields', []);
    const inPlay = Viz.getAllFieldsInPlay(viz.layout);
    // are any of the in play fields calcs?
    const inPlayCalcs = inPlay.filter(field => {
      if (field.attributeType === Types.TIME_CALC) {
        field = (field?.parentField as any) || field;
      }
      const { name } = field;
      return _.some(allCalcs, _.matches({ name }));
    });

    let required = {};
    if (!_.isEmpty(inPlayCalcs)) {
      required = inPlayCalcs.reduce((req, calc) => {
        req[calc.name] = calc;
        return req;
      }, {});

      // get all calcs referenced by this calc
      inPlayCalcs.forEach(calc => {
        Viz._addDependentCalcs(calc, allCalcs, required);
      });
    }

    const filters = Viz.getFiltersFromViz(viz);
    const calcsUsedInFiltersNotAlreadyKnown = Object.values(filters)
      .reduce((fields, filter) => {
        const aggContextField = _.get(filter, 'aggregationContext.field', null);

        const calcField = allCalcs.find(calc => {
          return filter.field === calc.name;
        });

        if (!_.isNil(calcField)) {
          fields.push(calcField);
        }

        // also check for calcs in the aggregationContext
        if (!_.isNil(aggContextField)) {
          const aggContextCalcField = allCalcs.find(calc => {
            return aggContextField === calc.name;
          });

          if (!_.isNil(aggContextCalcField)) {
            fields.push(aggContextCalcField);
          }
        }
        return fields;
      }, [])
      .filter(calcFieldInFilters => {
        // don't bother with fields that we've already added to the required set
        const isKnown = !_.isNil(required[calcFieldInFilters.name]);
        return !isKnown;
      });

    if (!_.isEmpty(calcsUsedInFiltersNotAlreadyKnown)) {
      calcsUsedInFiltersNotAlreadyKnown.forEach(calc => {
        const resolvedCalc = allCalcs.find(f => f.name === calc.name);
        if (!resolvedCalc) {
          throw new Error('Filter references an unknown calc');
        }
        required[calc.name] = resolvedCalc;
        Viz._addDependentCalcs(resolvedCalc, allCalcs, required);
      });
    }
    return _.values(required);
  },

  _addDependentCalcs: (calc, calcs, requiredCalcs) => {
    // find all field references in the calc formula
    const formula = !_.isNil(calc.formula)
      ? calc.formula
      : !_.isNil(calc.calculation)
      ? calc.calculation
      : null;
    if (_.isNil(formula)) {
      return;
    }
    const referencedFields = Viz.getFieldsReferencedInCalc(formula);
    referencedFields.forEach(fieldName => {
      const calcFound = calcs.find(c => c.name === fieldName);

      if (!_.isNil(calcFound) && _.isNil(requiredCalcs[calcFound.name])) {
        requiredCalcs[calcFound.name] = calcFound;
        Viz._addDependentCalcs(calcFound, calcs, requiredCalcs);
      }
    });
  },

  findAttributes: (viz, predicateFunc = x => x) => {
    const allFields = Viz.getAllFieldsUsedInQuery(viz);
    return allFields.filter(predicateFunc);
  },

  /**
   * Get all fields that a given field depends on
   * @param field to look for its dependencies
   * @param allFields Array of all available fields
   * @returns {*}
   */
  getFieldDependencies: (field, allFields) => {
    const formula = !_.isEmpty(field.formula)
      ? field.formula
      : !_.isEmpty(field.calculation)
      ? field.calculation
      : null;
    if (!_.isNil(formula)) {
      // this is a calculated field and might have dependencies
      const matchingFieldNames = new Set(
        Viz.getFieldsReferencedInCalc(formula),
      );

      const dependents = allFields
        .filter(f => matchingFieldNames.has(f.name))
        .reduce((deps, matchedField) => {
          deps[matchedField.name] = matchedField;
          return deps;
        }, {});

      // for each of the dependent fields, check their dependencies
      _.values(dependents).forEach(dependent => {
        const depsDeps = Viz.getFieldDependencies(dependent, allFields);
        // add each field dependency might have dependencies of their own.
        depsDeps.forEach(dd => {
          // only add them if we didn't already know about it
          if (_.isNil(dependents[dd.name])) {
            dependents[dd.name] = dd;
          }
        });
      });
      return _.values(dependents);
    }
    return [];
  },
  hydrateTimeHierarchyFields({
    datasetAttributes,
    calcFields,
    timeHierarchies,
  }: {
    datasetAttributes;
    calcFields;
    timeHierarchies;
  }) {
    const fields = _([...datasetAttributes, ...calcFields])
      .chain()
      .reduce((resultFields, current) => {
        const field = { ...current };
        if (field.attributeType === Types.TIMESTAMP) {
          let hierarchyFields = [];

          if (!_.isEmpty(timeHierarchies)) {
            // find out if this field has a hierarchy that it should render
            hierarchyFields = timeHierarchies[field.name];
          }

          if (_.isEmpty(hierarchyFields)) {
            // add in the default hierarchies
            hierarchyFields = Hierarchy.createTimeCalcFields(field);
          }

          if (!_.isEmpty(hierarchyFields)) {
            field.children = [...hierarchyFields.filter(thf => !thf.hidden)];
          }
        }
        resultFields.push(field);
        return resultFields;
      }, [])
      .thru(SortFieldsByType)
      .value();

    return {
      fields,
    };
  },
  shouldClearQuery(current, previous) {
    const prevQueryResults = _.get(previous, 'vizQueryResults');
    const currLiveQuery = _.get(current, 'viz.options.useLiveQuery');

    const pathsToCompare = [
      'viz.layout',
      'viz.options.filters',
      'viz.options.useFiscalCalendar',
    ];

    const hasChanges = pathsToCompare.some(
      path => !_.isEqual(_.get(current, path), _.get(previous, path)),
    );

    return !!prevQueryResults && currLiveQuery === 'false' && hasChanges;
  },
  isTwoFiltersExpressionEqual(filterOne: IFilter, filterTwo: IFilter) {
    return (
      _.isEqual(filterOne.expression, filterTwo.expression) &&
      filterOne.subType === filterTwo.subType
    );
  },
};

const TypeSortOrder = {
  STRING: [Types.STRING, Types.STRING_CALC],
  TIMESTAMP: [Types.TIMESTAMP, Types.TIME_CALC, Types.PRIOR_PERIOD_CALC],
  NUMBER: [Types.NUMBER, Types.CALC, Types.BOOLEAN],
  ANY: [Types.ANY],
};

export const SortFieldsByType = fields => {
  // Ascending, ordered by attribute type (appMessages.baseChart, Timestamps, Numbers, etc.)
  const asc = _.sortBy(fields, f => _.lowerCase(f.name));
  const sorted = [];
  _.forEach(TypeSortOrder, types => {
    const filtered = _.remove(asc, f => _.includes(types, f.attributeType));
    sorted.push(filtered);
  });
  return _.flatten(sorted);
};

setVizUtil(Viz);

export function notHidden(fields) {
  return _.reject(fields, 'hidden');
}
