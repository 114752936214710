import { FormRoot } from '../../views/VizSaveDialog/viz-save-dialog.styles';
import styled from '@emotion/styled';
import { FormGroup } from '@mui/material';

export const StyledFormatRoot = styled(FormRoot)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 2em;
  gap: 5%;
`;

export const StyledFormGroup = styled(FormGroup)`
  flex: 0 0 40%;
  &:first-child {
    flex: 0 0 20%;
  }
`;
