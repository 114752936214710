import { isEmpty } from 'lodash';

export const doesPointDataHaveMissingValue = ({ focusedData, pointData }) => {
  if (isEmpty(pointData)) {
    return false;
  }

  const focusedDataLines = focusedData.map(({ lines }) => lines[0]);

  return pointData
    .filter(
      ({ pathInfo: { lines } }) =>
        isEmpty(focusedData) || focusedDataLines.includes(lines[0]),
    )
    .every(({ y }) => y === '-' || y === 0);
};
