import { ErrorInfo } from 'react';
import { DefaultErrorFallback } from './default-error-fallback';
import NewRelic from '../../common/NewRelic';
import {
  ErrorBoundaryPropsWithComponent,
  ErrorBoundaryPropsWithRender,
} from 'react-error-boundary/dist/declarations/src/types';

export const LogErrorBoundary = (error: Error, info: ErrorInfo) => {
  NewRelic.addPageAction(
    'ui-error-fallback',
    `${error.message} ${info.componentStack}`,
  );
};

export const ErrorBoundaryHOCOptions: ErrorBoundaryPropsWithComponent = {
  FallbackComponent: DefaultErrorFallback,
  onError: LogErrorBoundary,
};

export const ErrorBoundaryComponentProps: ErrorBoundaryPropsWithRender = {
  fallbackRender: DefaultErrorFallback,
  onError: LogErrorBoundary,
};
