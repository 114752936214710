import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { font } from '../../../common/emotion/mixins';
import { FieldTypeIcon as FieldTypeIconUnstyled } from '../../../icons';
import { filterIconStyles, StackedFilterText } from '../active-filter-panel';

export const RuntimeFilterSection = styled.aside`
  padding: 8px;
  grid-column: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Title = styled.span(
  ({
    theme: { colors: { LightFontWeight } = {} as any } = {} as any,
  }: any) => css`
    ${font({ size: '12px', weight: LightFontWeight })}
  `,
);

export const RuntimeFilter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-bottom: 0.5rem;
`;

export const RuntimeFilterText = styled(StackedFilterText)(
  ({
    theme: {
      colors: { LightFontWeight, ContentTextColor } = {} as any,
    } = {} as any,
  }: any) => css`
    ${font({ size: '12px', weight: LightFontWeight })}
    color: ${ContentTextColor};
    &:hover {
      text-decoration: unset;
    }
  `,
);

export const FieldTypeIcon = styled(FieldTypeIconUnstyled)`
  ${filterIconStyles}
`;
