import ServiceLocator from '../../ServiceLocator';

export default function reducer(state = {}, action) {
  const tenantReducer = ServiceLocator.get('REDUCER', {
    type: 'custom-reducer',
  });
  if (tenantReducer) {
    return tenantReducer(state, action);
  } else {
    return state;
  }
}
