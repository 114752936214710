import { connect } from 'react-redux';
import { Modal } from '@sugar-discover/react-bootstrap-wrapper';
import { noop } from 'lodash';
import { Copyright } from '../UIUtilityWidgets';
import { PRIVACY_POLICY_LINK } from '../../../common/Constants';
import { messages } from '../../../i18n';
import { compose } from 'react-recompose';
import Util from '../../Util';
import { URLs } from '../../Urls';
import { ButtonContainer, PrimaryButton } from '../../../ui';

const UnconnectedLogoutDialog = ({
  idmLogoutUrl,
  isDashletMode,
  returnToUrl = URLs.getQueryParams()?.return_to_url ?? '/',
  appUrl,
}) => {
  const logoImgSrc = Util.assetUrl({ appUrl, path: 'discover_logo.svg' });
  try {
    const url = new URL(idmLogoutUrl);
    const redirectUriObj = new URL(appUrl);
    redirectUriObj.pathname = 'logout';
    redirectUriObj.searchParams.append('return_to_url', returnToUrl);
    const redirectUri = redirectUriObj.toString();
    url.searchParams.append('redirect_uri', redirectUri);
    const fixedUrl = url.toString();
    window.location.href = fixedUrl;
    return null;
  } catch (err) {
    console.log('Failed to parse idmLogoutUrl', err);
  }

  const validateAndRedirect = redirectToUrl => {
    const currentDomain = window.location.hostname;
    const regex = new RegExp(`^(.*\\.)?${currentDomain}$`, 'i');
    try {
      const cleanUrl = decodeURIComponent(redirectToUrl);
      if (regex.test(new URL(cleanUrl).hostname)) {
        window.location.assign(returnToUrl);
      } else {
        throw new Error('Invalid Redirect');
      }
    } catch (err) {
      console.error(err);
      return;
    }
  };

  return (
    <Modal
      show
      dialogClassName='regular-modal'
      bsClass={`re-autenticate ${
        isDashletMode ? 'dashlet ' : ''
      }LoginDialog login-dialog modal`}
      bsSize='large'
      onHide={noop}
      animation={false}
    >
      <Modal.Body className='LoginDialog__body'>
        <div className='login-body'>
          <div className='login-logo'>
            <img src={logoImgSrc} className='invertable center-block' />
          </div>
          <div className='login-header text-center'>
            {messages.logoutDialog.loggedOut}
          </div>
          <form onSubmit={() => validateAndRedirect(returnToUrl)}>
            <ButtonContainer>
              <PrimaryButton
                className={'logout-button'}
                onClick={() => validateAndRedirect(returnToUrl)}
              >
                {messages.loginDialog.returnToLogin}
              </PrimaryButton>
            </ButtonContainer>
          </form>
        </div>
        {!isDashletMode && (
          <div className='tosText'>
            <Copyright />
            <span className='vertical-bar' />
            <a href={PRIVACY_POLICY_LINK} target={'_blank'} rel='noreferrer'>
              {messages.privacyPolicy}
            </a>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export const LogoutDialog = compose(
  connect((state: any) => {
    const {
      dashlet: { isDashletMode },
      login: { idmLogoutUrl, returnToUrl },
      main: { appUrl },
    } = state;
    return {
      returnToUrl,
      isDashletMode,
      idmLogoutUrl,
      appUrl,
    };
  }),
)(UnconnectedLogoutDialog as any);
