import { Component, ComponentClass } from 'react';
import { FormGroup, Radio } from '@sugar-discover/react-bootstrap-wrapper';
import moment from '../../common/Moment';
import {
  Condition,
  createFilterForField,
  Expression,
  FilterTypes,
  TimestampFilterSubTypes,
} from './Filter';
import { FilterOperators } from './FilterOperators';
import _ from 'lodash';
import { connect } from 'react-redux';
import Discover from '../../common/redux/actions/DiscoverActions';
import { messages } from '../../i18n';
import { VIZ_SELECTORS } from '../../common/redux/selectors/viz-selectors';

import {
  FilterSubType,
  IFilter,
  IExpressionSegment,
} from '../../datasets/interfaces/';
import DiscoverGql from '../../common/graphql/DiscoverQueries';
import { client } from '../../common/ApolloClient';
import { ACCOUNT_SELECTORS } from '../../common/redux/selectors/AccountSelectors';

const QUARTER = [
  moment()
    .month(0)
    .date(1),
  moment()
    .month(3)
    .date(1),
  moment()
    .month(6)
    .date(1),
  moment()
    .month(9)
    .date(1),
];

interface IPeriod {
  key: string;
  displayText: string;
  displayDate: string;
  fiscalDisplay?: string;
}

const PERIODS = {
  YEAR: {
    key: 'YEAR',
    displayText: 'filters.yearToDate',
    fiscalDisplay: 'filters.fiscalYearToDate',
    displayDate: ({ dateFormat }) =>
      moment()
        .startOf('year')
        .format(dateFormat),
  },
  QUARTER: {
    key: 'QUARTER',
    displayText: 'filters.quarterToDate',
    fiscalDisplay: 'filters.fiscalQuarterToDate',
    displayDate: ({ dateFormat }) =>
      QUARTER[moment().quarter() - 1].format(dateFormat),
  },
  MONTH: {
    key: 'MONTH',
    displayText: 'filters.monthToDate',
    displayDate: ({ dateFormat }) =>
      moment()
        .startOf('month')
        .format(dateFormat),
  },
  DAY: {
    key: 'DAY',
    displayText: 'filters.dayToDate',
    displayDate: ({ dateTimeFormat }) =>
      moment()
        .startOf('day')
        .format(dateTimeFormat),
  },
  HOUR: {
    key: 'HOUR',
    displayText: 'filters.hourToDate',
    displayDate: ({ dateTimeFormat }) =>
      moment()
        .startOf('hour')
        .format(dateTimeFormat),
  },
  MINUTE: {
    key: 'MINUTE',
    displayText: 'filters.minuteToDate',
    displayDate: ({ dateTimeFormat }) =>
      moment()
        .startOf('minute')
        .format(dateTimeFormat),
  },
};

const FISCAL_CALENDAR_FIELDS = ['YEAR', 'QUARTER'];

interface IProps {
  defaultOperand: any;
  filter: IFilter;
  operator: any;
  changeFilter: (filter: IFilter) => never;
  useFiscalCalendar: boolean;
  datasetId: string;
  field: any;
  dateTimeFormats: any;
}

interface IState {
  periodOperand: any;
  displayDate?: any;
}

class TimestampPeriod extends Component<IProps, IState> {
  state: IState;

  constructor(props) {
    super(props);
    let operand = this.props.defaultOperand;
    if (
      (this.props.filter.expression.left as IExpressionSegment).operands
        .length > 0 &&
      !_.isNil(
        PERIODS[
          (this.props.filter.expression.left as IExpressionSegment).operands[0]
        ],
      )
    ) {
      operand = (this.props.filter.expression.left as IExpressionSegment)
        .operands[0];
    }

    this.state = {
      periodOperand: operand,
      displayDate: PERIODS[operand].displayDate,
    };

    const { useFiscalCalendar } = this.props;
    if (useFiscalCalendar && _.includes(FISCAL_CALENDAR_FIELDS, operand)) {
      this.setFiscalDisplayDate(operand);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isSubTypePeriodToDate =
      nextProps.filter.subType === TimestampFilterSubTypes.PERIOD_TO_DATE;
    const hasFiscalDisplayDateChanged =
      nextState.displayDate !== this.state.displayDate;
    return isSubTypePeriodToDate || hasFiscalDisplayDateChanged;
  }

  componentDidMount() {
    if (
      (this.props.filter.expression.left as IExpressionSegment).operands
        .length === 0
    ) {
      this._changeFilter(this.props.defaultOperand);
    }
  }
  componentDidUpdate() {
    if (
      (this.props.filter.expression.left as IExpressionSegment).operands
        .length === 0
    ) {
      this._changeFilter(this.props.defaultOperand);
    }
  }

  setFiscalDisplayDate(periodOperand?: string) {
    const operand = periodOperand ?? this.state.periodOperand;
    const {
      filter: {
        expression: { left: filter },
      },
      operator,
      field,
    } = this.props;
    const period = PERIODS[operand];

    const requestFilter = filter as IExpressionSegment & {
      attributeName: string;
    };

    requestFilter.attributeName = field.name ?? '';
    requestFilter.operator = operator ?? 'PERIOD';
    requestFilter.operands = [period.key];

    const request = {
      filter: requestFilter,
      useFiscalCalendar: this.props.useFiscalCalendar,
      datasetId: this.props.datasetId,
    };

    // see useDateRange for hook that fetches this data
    client
      .query({
        query: DiscoverGql.ComputeDateRangeQuery,
        variables: { request },
        fetchPolicy: 'network-only',
      })
      .then((response: { data: any }) => {
        const { start } = response.data.dateRange;

        this.setState({
          displayDate: ({ dateFormat }) => moment.utc(start).format(dateFormat),
        });
      });
  }

  onChange(key) {
    this._changeFilter(key);
  }

  _changeFilter(key) {
    const { useFiscalCalendar } = this.props;
    if (useFiscalCalendar && _.includes(FISCAL_CALENDAR_FIELDS, key)) {
      this.setFiscalDisplayDate(key);
    } else {
      this.setState({
        displayDate: PERIODS[key].displayDate,
      });
    }

    this.setState({
      periodOperand: key,
    });

    const condition = new Condition(this.props.operator, new Array(key));
    const expression = new Expression(condition, null, null);
    const filter = { ...this.props.filter, expression: { ...expression } };
    this.props.changeFilter(filter);
  }

  render() {
    const { useFiscalCalendar } = this.props;
    const debounced = _.debounce(key => this.onChange(key), 300);
    return (
      <div>
        <FormGroup>
          {_(PERIODS)
            .map((period: IPeriod) => {
              let periodDisplayText = _.get(
                messages,
                period.displayText,
                period.displayText,
              );

              if (
                useFiscalCalendar &&
                _.includes(FISCAL_CALENDAR_FIELDS, period.key)
              ) {
                periodDisplayText = _.get(
                  messages,
                  period.fiscalDisplay,
                  period.fiscalDisplay,
                );
              }
              return (
                <Radio
                  name='period'
                  key={period.key}
                  onChange={() => debounced(period.key)}
                  checked={this.state.periodOperand === period.key}
                >
                  {periodDisplayText}
                </Radio>
              );
            })
            .value()}
        </FormGroup>
        <div>
          {messages.formatString(
            messages.filters.effectiveDateRange,
            this.state.displayDate(this.props.dateTimeFormats),
            messages.filters.now,
          )}
        </div>
      </div>
    );
  }
}

const createTimestampFilter = field => {
  const filter = createFilterForField(field);
  filter.subType = TimestampFilterSubTypes.PERIOD_TO_DATE as FilterSubType;
  return filter;
};

const mapStateToProps = (state, ownProps) => {
  const discovery = state.discover.openDiscoveries[ownProps.vizId].present;
  const useFiscalCalendar =
    (VIZ_SELECTORS.getActiveVizFiscalSetting as any)(state, ownProps) ===
    'true';
  let { filter } = ownProps;
  if (_.isNil(filter)) {
    filter = createTimestampFilter(ownProps.field);
  }
  const operator = FilterOperators.forFilterType(
    FilterTypes.DATE,
    TimestampFilterSubTypes.PERIOD_TO_DATE,
  ).PERIOD.key;

  const dateTimeFormats = ACCOUNT_SELECTORS.getDatetimeFormats(state);

  return {
    dateTimeFormats,
    datasetId: discovery.dataset.id,
    filter: { ...filter },
    operator,
    defaultOperand: 'YEAR',
    useFiscalCalendar,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    changeFilter(filter) {
      dispatch(Discover.setActiveFieldFilter(filter));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimestampPeriod as ComponentClass);
