/* eslint-env browser */
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose, pure, shouldUpdate, withProps } from 'react-recompose';
import ClosablePanel from '../common/widgets/ClosablePanel';
import Discover from '../common/redux/actions/DiscoverActions';
import * as _ from 'lodash';
import { VisualizationInsights } from '../insights/visualization-insights';
import ServiceLocator from '../common/ServiceLocator';
import { ComponentTypes, Labels } from '../common/';
import { messages } from '../i18n';
import { VIZ_SELECTORS } from '../common/redux/selectors/viz-selectors';

class DetailPanel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showPanel: props.showPanel === true,
    };

    this.onClose = this.onClose.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  componentDidUpdate() {
    // Trigger window resize when the panel is updated. This manually resizes
    // the monitor chart once panel is in an open or closed state.
    try {
      window.dispatchEvent(new Event('resize'));
    } catch (e) {
      console.log(e);
    }
  }

  panelDetail() {
    if (this.props.vizId) {
      switch (this.props.panelDetail) {
        case Labels.DISCOVERY_INSIGHTS:
          return (
            <VisualizationInsights
              selectedDiscovery={this.props.selectedDiscovery}
            />
          );
        default:
          return <div />;
      }
    } else {
      return <div />;
    }
  }

  onClose() {
    this.setState(
      {
        showPanel: false,
      },
      () => {
        this.props.togglePanel(this.state.showPanel);
      },
    );

    this.onResize(0);
  }

  onResize(newWidth) {
    this.props.setLeftPanelWidth(newWidth);
  }

  render() {
    const collapsedPanel = this.props.panelDetail
      ? this.state.showPanel
      : false;
    const title = _.get(
      messages,
      this.props.panelDetail,
      this.props.panelDetail,
    );
    return (
      <div
        id='monitor-detail-panel'
        className={collapsedPanel ? '' : 'collapsed-x'}
      >
        <ClosablePanel
          slideTo='right'
          expanded={collapsedPanel}
          title={title}
          onClose={() => {
            this.onClose();
          }}
          setPanelWidth={newWidth => {
            this.onResize(newWidth);
          }}
          panelWidth={this.props.leftPanelWidth}
        >
          {this.panelDetail()}
        </ClosablePanel>
      </div>
    );
  }
}

export default compose(
  pure,
  connect(
    (state, ownProps) => {
      const discoveryWithHistory =
        state.discover.openDiscoveries[state.discover.displayDiscovery];
      const openDiscovery = discoveryWithHistory.present;
      const leftPanelWidth = VIZ_SELECTORS.getActiveVizPanelWidth(
        state,
        ownProps,
      );

      // insights may be visible depending on advanced mode
      let { hasInsights = false, hasAdvancedInsights = false } =
        openDiscovery || {};
      const isAdvancedMode = state.main.advanced;
      hasInsights = (isAdvancedMode && hasAdvancedInsights) || hasInsights;

      let { panelDetail } = openDiscovery.viz.options;

      // Sometimes the boolean value could be a string. If panelDetail has a value, then a toolbar icon is selected
      const showPanel =
        String(openDiscovery.viz.options.showPanel).toLowerCase() === 'true' &&
        !_.isNil(panelDetail);

      if (panelDetail === 'Insights' || panelDetail === 'Discovery Insights') {
        panelDetail = Labels.DISCOVERY_INSIGHTS;
      } else if (panelDetail === 'Comments') {
        panelDetail = Labels.COMMENTS;
      }

      return {
        showPanel,
        panelDetail,
        selectedDiscovery: { ...openDiscovery },
        hasInsights,
        leftPanelWidth,
      };
    },
    (dispatch, ownProps) => {
      return {
        togglePanel(show) {
          const id = ownProps.vizId || ownProps.monitorId;
          if (!show) {
            dispatch(Discover.setPanelDetail(id, null));
          }
        },
        setLeftPanelWidth: _.noop,
      };
    },
  ),
  // only update if the panel to show has changed
  shouldUpdate((props, nextProps) => {
    return (
      props.vizId !== nextProps.vizId ||
      props.panelDetail !== nextProps.panelDetail ||
      props.selectedDiscovery.hasInsights !==
        nextProps.selectedDiscovery.hasInsights ||
      props.selectedDiscovery.dynamicInsights !==
        nextProps.selectedDiscovery.dynamicInsights
    );
  }),
  Wrapped => {
    const wrap = props => <Wrapped {...props} />;
    return props => {
      const tenantedInsightsLookupHoc = ServiceLocator.get('HOC', {
        type: ComponentTypes.INSIGHTS,
      });
      const Comp = tenantedInsightsLookupHoc
        ? tenantedInsightsLookupHoc(wrap)
        : wrap;
      return <Comp {...props} />;
    };
  },
  withProps(ownProps => {
    const { hasInsights } = ownProps;
    return {
      panelDetail: !ownProps.panelDetail
        ? hasInsights
          ? Labels.DISCOVERY_INSIGHTS
          : null
        : ownProps.panelDetail,
    };
  }),
)(DetailPanel);
