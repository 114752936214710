import _ from 'lodash';
import { createOrdinalName } from '../ChartUtils';
const TOTALS_FLAG = '__ALL__';

/**
 * Splits totals and subtotals from regular data in a query result.
 * <br />
 * Totals and subtotals are indicated by __ALL__
 * @param queryResults
 * @returns object with an array for the data to pivot and one for the totals
 * <code>
 *   {pivot: Array, totals: Array}
 * </code>
 */
const splitTotalsFromData = queryResults => {
  return queryResults.reduce(
    (split, result) => {
      if (!_.includes(result, TOTALS_FLAG)) {
        split.pivot.push(result);
      } else {
        split.totals.push(result);
      }
      return split;
    },
    { pivot: [], totals: [] },
  );
};

const getQueryResultObjects = (queryResults, columnNames) => {
  const split = splitTotalsFromData(queryResults);
  const all = queryResultsToObjects(queryResults, columnNames);
  const totalsOnly = queryResultsToObjects(split.totals, columnNames);
  const bodyOnly = queryResultsToObjects(split.pivot, columnNames);
  return {
    all,
    totalsOnly,
    bodyOnly,
  };
};
const queryResultsToObjects = (queryResults, columnNames) => {
  return queryResults.map(row => {
    const obj = columnNames.reduce((o, name, idx) => {
      o[name] = row[idx];
      return o;
    }, {});
    return obj;
  });
};

const isLeaf = node => {
  return !(
    _.keys(node).length === 2 &&
    _.has(node, 'key') &&
    _.has(node, 'values')
  );
};

const childrenAreLeafs = node => {
  return isLeaf(node.values[0]);
};

const pivotOn = (fieldNamesWithOrdinals, fieldNamesWithoutOrdinals) => {
  return {
    withOrdinals: fieldNamesWithOrdinals,
    withoutOrdinals: fieldNamesWithoutOrdinals || fieldNamesWithOrdinals,
  };
};

function NodeTracker(key, parent) {
  this.key = key;
  this.parent = parent;
  this.depth = _.isNil(parent) ? 0 : parent.depth + 1;
  this.count = 0;
  this.increment = num => {
    this.count += _.isNil(num) ? 1 : num;
    if (this.parent) {
      this.parent.increment(num);
    }
  };
}

const createHeaders = (pivotedData, measures, includeColTotals = false) => {
  // walk the tree
  const headers = [];
  const depthTracker = new Set();

  const walk = (node, counter) => {
    if (isLeaf(node) || childrenAreLeafs(node)) {
      counter.increment(_.isEmpty(measures) ? 1 : measures.length);
      return;
    }

    // walk the children as well, counting their children as we go
    node.values
      .filter(nodeValue => nodeValue.key !== TOTALS_FLAG || includeColTotals)
      .forEach(n => {
        depthTracker.add(counter.depth + 1);
        const myCounter = new NodeTracker(n.key, counter);
        headers.push(myCounter);
        walk(n, myCounter);
      });
  };

  pivotedData
    .filter(
      node => (node.key !== TOTALS_FLAG || includeColTotals) && !isLeaf(node),
    )
    .forEach(node => {
      depthTracker.add(0);
      const counter = new NodeTracker(node.key);
      headers.push(counter);
      walk(node, counter);
    });

  if (_.isEmpty(headers)) {
    return [[{ value: null }, 1]];
  }

  const pivotedHeaders = [];
  depthTracker.forEach(depth => {
    const pivotedHeader = headers
      .filter(h => h.depth === depth)
      .map(h => {
        return [{ value: h.key }, h.count === 0 ? 1 : h.count];
      });
    pivotedHeaders.push(pivotedHeader);
  });
  return pivotedHeaders;
};

const getUniqueValuesAtEachLevel = (
  pivotedData,
  columnNames,
  ordinalFieldNameMap = {},
  sorts = {},
) => {
  // walk the tree
  const depth = 0;
  const uniqueValuesOrdinalMap = {};

  let uniqueValuesByName;
  if (!_.isEmpty(columnNames)) {
    uniqueValuesByName = columnNames.reduce((all, name) => {
      all[name] = new Set();
      return all;
    }, {});
  }

  let uniqueValuesByDepth = [];

  const walk = (node, currentDepth) => {
    if (isLeaf(node) || childrenAreLeafs(node)) {
      if (childrenAreLeafs(node)) {
        if (!_.isEmpty(ordinalFieldNameMap)) {
          _.forEach(
            ordinalFieldNameMap,
            (ordinalFieldName, displayFieldName) => {
              const sortValue = _.get(node.values[0], ordinalFieldName, null);
              const displayValue = `${_.get(
                node.values[0],
                displayFieldName,
                '',
              )}`;
              if (!_.isNil(sortValue)) {
                const fieldOrdinals = _.get(
                  uniqueValuesOrdinalMap,
                  displayFieldName,
                  {},
                );
                fieldOrdinals[displayValue.toString()] = sortValue;
                uniqueValuesOrdinalMap[displayFieldName] = fieldOrdinals;
              }
            },
          );
        }
      }
      return;
    }

    if (currentDepth >= uniqueValuesByDepth.length) {
      uniqueValuesByDepth.push(new Set());
    }

    const levelDepth = currentDepth + 1;
    // walk the children as well, counting their children as we go
    node.values
      .filter(nodeValue => nodeValue.key !== TOTALS_FLAG)
      .forEach(n => {
        if (uniqueValuesByName) {
          uniqueValuesByName[columnNames[currentDepth]].add(n.key);
        }
        uniqueValuesByDepth[currentDepth].add(n.key);
        walk(n, levelDepth);
      });
  };

  uniqueValuesByDepth.push(new Set());

  pivotedData
    .filter(node => node.key !== TOTALS_FLAG && !isLeaf(node))
    .forEach(node => {
      if (uniqueValuesByName) {
        uniqueValuesByName[columnNames[depth]].add(node.key);
      }
      uniqueValuesByDepth[depth].add(node.key);
      walk(node, depth + 1);
    });

  // turn the sets into arrays
  uniqueValuesByName = _.reduce(
    uniqueValuesByName,
    (accum, val, key) => {
      accum[key] = Array.from(val);
      // is there an ordinal field?
      const hasOrdinal = !_.isNil(uniqueValuesByName[createOrdinalName(key)]);
      if (hasOrdinal) {
        const order = uniqueValuesByName[createOrdinalName(key)];
        const needsSorted = accum[key].map((v, idx) => {
          return { idx, value: v };
        });
        const sorted = _.sortBy(needsSorted, data => {
          return Array.from(order)[data.idx];
        });
        accum[key] = sorted.map(d => d.value);
      }
      return accum;
    },
    {},
  );

  uniqueValuesByDepth = uniqueValuesByDepth.map(u => {
    return Array.from(u);
  });

  // sort by the ordinals if there are any
  if (!_.isEmpty(uniqueValuesOrdinalMap)) {
    _.forEach(uniqueValuesOrdinalMap, (ordinalMap, fieldName) => {
      if (_.includes(columnNames, fieldName)) {
        const columnSorts = _.get(sorts, 'COLUMNS', []);
        const fieldSort = columnSorts.find(s => {
          return _.includes(s.path, fieldName);
        });
        const direction = _.get(fieldSort, 'direction', 'asc');
        uniqueValuesByName[fieldName] = _.orderBy(
          uniqueValuesByName[fieldName],
          displayValue => {
            return ordinalMap[displayValue];
          },
          direction,
        );

        const columnDepth = columnNames.findIndex(cn => cn === fieldName);
        if (columnDepth !== -1) {
          uniqueValuesByDepth[columnDepth] = _.orderBy(
            uniqueValuesByDepth[columnDepth],
            displayValue => {
              return ordinalMap[displayValue];
            },
            direction,
          );
        }
      }
    });
  }
  return { byName: uniqueValuesByName, byDepth: uniqueValuesByDepth };
};

const getAllPossibleCombinations = (
  pivotedData,
  columnNames,
  ordinalFieldNameMap,
  sorts,
) => {
  const unique = getUniqueValuesAtEachLevel(
    pivotedData,
    columnNames,
    ordinalFieldNameMap,
    sorts,
  );

  const flat = _.reduce(
    unique.byName,
    (all, values, key) => {
      const l = values.map(v => {
        return { [key]: v };
      });
      all.push(l);
      return all;
    },
    [],
  );

  let combos = [];
  const walk = (level, depth, parent) => {
    const levelCombos = level.reduce((accum, val) => {
      const merged = { ...val, ...parent };

      if (depth < flat.length - 1) {
        walk(flat[depth + 1], depth + 1, merged);
      } else {
        accum.push(merged);
      }
      return accum;
    }, []);
    combos = [...combos, ...levelCombos];
  };
  if (!_.isEmpty(flat)) {
    walk(flat[0], 0);
  }
  return combos;
};

export {
  TOTALS_FLAG,
  splitTotalsFromData,
  queryResultsToObjects,
  createHeaders,
  getUniqueValuesAtEachLevel,
  getAllPossibleCombinations,
  pivotOn,
  isLeaf,
  childrenAreLeafs,
  getQueryResultObjects,
};
