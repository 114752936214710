import _, { isObject, omit } from 'lodash';
import {
  FiscalCalendarYearType,
  IDatasetSettings,
  IDatasetAnnotationsRequest,
} from './edit-dataset-settings.interfaces';
import {
  FISCAL_CALENDAR_START_DATE,
  FISCAL_CALENDAR_YEAR_TYPE,
} from '../../common';
import { IAnnotation, IDataset } from '../../datasets';

export const PANEL_FISCAL_CALENDAR = 'fiscalCalendar';
export const PANEL_DATASET_INGESTION = 'datasetIngestion';
export const PANEL_FUNNEL_CONFIGURATION = 'funnelConfiguration';

// ensures a valid request
export const prepareDatasetSettingsRequest = (
  dsRequest: IDatasetSettings,
): IDatasetAnnotationsRequest => {
  // will eventually send to standalone endpoint
  let updateDatasetAnnotationsRequest: IDatasetAnnotationsRequest = {
    datasetId: dsRequest.datasetId,
  };

  if (!_.isNil(dsRequest.datasetAnnotations)) {
    updateDatasetAnnotationsRequest = {
      ...updateDatasetAnnotationsRequest,
      ...omit(dsRequest.datasetAnnotations, 'datasetJson'),
    };
    // stringify datasetJson
    const providedDatasetJson = dsRequest.datasetAnnotations?.datasetJson;

    if (providedDatasetJson && isObject(providedDatasetJson)) {
      updateDatasetAnnotationsRequest.datasetJson = JSON.stringify(
        providedDatasetJson,
      );
    }
  }

  if (!_.isNil(dsRequest.annotations)) {
    const fiscalCalendarStartDate = _.find(dsRequest.annotations, {
      key: FISCAL_CALENDAR_START_DATE,
    });
    const fiscalCalendarYearType = _.find(dsRequest.annotations, {
      key: FISCAL_CALENDAR_YEAR_TYPE,
    });

    if (
      _.every([fiscalCalendarStartDate, fiscalCalendarYearType], _annotation =>
        _.get(_annotation, 'value'),
      )
    ) {
      updateDatasetAnnotationsRequest.fiscalCalendarStartDate =
        fiscalCalendarStartDate.value;
      updateDatasetAnnotationsRequest.fiscalCalendarYearType = fiscalCalendarYearType.value as FiscalCalendarYearType;
    }
  }

  // only send allowed fields
  const allowedFieldsList = [
    'datasetId',
    'datasetJson',
    'systemName',
    'dryRun',
    'objectIdAttrib',
    'closeDateAttrib',
    'createdDateAttrib',
    'fiscalCalendarStartDate',
    'fiscalCalendarYearType',
    'skipRefresh',
  ];

  updateDatasetAnnotationsRequest = {
    datasetId: updateDatasetAnnotationsRequest.datasetId,
    ..._.pick(updateDatasetAnnotationsRequest, allowedFieldsList),
  };

  return updateDatasetAnnotationsRequest;
};

// remove prohibited metadata (similar to Util.removeTypeNameRecursively)
export const sanitizedDatasetAnnotations = (dataset: IDataset): IAnnotation[] =>
  _.map(_.get(dataset, 'annotations', []), (_annotation: IAnnotation) => {
    return _.pick(_annotation, ['key', 'value']);
  });
