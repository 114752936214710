import { css, keyframes } from '@emotion/react';
import { memo } from 'react';
import { SugarIcon } from '../../icons/sugar-icon/sugar-icon.component';
import styled from '@emotion/styled';
import { useDiscoverThemeColors } from '../emotion/theme/discover-emotion-theme-provider.component';
import { LoaderSpinner } from './loaders';

export const spinAnim = keyframes`
0% {
  transform: rotate(0deg) scale(1);
}

50% {
  transform: rotate(180deg) scale(1);
}

100% {
  transform: rotate(360deg) scale(1);
}
`;

const spinnerCSS = css`
  animation: ${spinAnim} 0.75s linear 0s infinite normal both running !important;
`;

const Warning = props => {
  return (
    <div className='status'>
      <SugarIcon icon='warning-lg' iconType='warning' />
      <div className='status-label'>{props.children}</div>
    </div>
  );
};

const Error = props => {
  return (
    <div className='status'>
      <SugarIcon icon='warning-lg' iconType='error' />
      <div className='status-label'>{props.children}</div>
    </div>
  );
};

export const SpinnerLoader = props => {
  return <LoaderSpinner additionalStyles={spinnerCSS} {...props} />;
};

const SpinnerHolder = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const Spinner = ({ size: givenSize, color, children, styles = css`` }) => {
  const colors = useDiscoverThemeColors();
  const size = givenSize || 20;
  return (
    <div css={styles} className='spinner'>
      <SpinnerHolder size={size}>
        <SpinnerLoader
          loading
          size={size}
          color={color ? color : colors?.CorbotoLoadingColor}
        />
      </SpinnerHolder>
      <div className='spinner-label'>{children}</div>
    </div>
  );
};

const MemoizedSpinner = memo(Spinner);

const OuterContainer = styled.div(
  ({
    theme: {
      colors: { ContentBackground },
    },
  }) => css`
    background-color: ${ContentBackground};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `,
);

const NonMemoizedCenteredSpinner = ({ size = 64 }) => {
  const InnerContainer = styled.div`
    width: ${size + 1}px;
    height: ${size + 1}px;
  `;

  return (
    <OuterContainer>
      <InnerContainer>
        <Spinner size={size} />
      </InnerContainer>
    </OuterContainer>
  );
};

const CenteredSpinner = memo(NonMemoizedCenteredSpinner);

export {
  Warning,
  Spinner,
  Error,
  CenteredSpinner,
  MemoizedSpinner,
  NonMemoizedCenteredSpinner,
};
