import { css } from '@emotion/react';

export const slideInOutStyles = css`
  .transition-wrapper {
    flex: 1;
    display: flex;
  }
  .transition-wrapper span {
    flex: 1;
    display: flex;
  }
  .transition-slide-in-appear {
    transform: translateX(100%);
    opacity: 0;
  }
  .transition-slide-in-appear.transition-slide-in-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.4s linear;
  }
  .transition-slide-in-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .transition-slide-in-enter.transition-slide-in-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.2s linear 0.4s;
  }
  .transition-slide-in-leave {
    opacity: 1;
    transform: translateX(0);
  }
  .transition-slide-in-leave.transition-slide-in-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
    transition: all 0.2s linear;
  }
`;
