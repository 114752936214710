import { useEffect } from 'react';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import URLs from '../Urls';
import MainActions from '../redux/actions/MainActions';

// Captures History and last location
export default compose(
  withRouter,
  withLastLocation,
  connect(),
)(({ dispatch, history, location, children, lastLocation }) => {
  useEffect(() => {
    const lastPath = lastLocation ? lastLocation.pathname : '';
    // ignore path changes internal to the account dialog for history, unless you are going there from a direct url
    if (location.pathname !== '/login' && lastPath.includes('/account')) {
      URLs.setHistory(history);
    } else {
      URLs.setHistoryAndLastLocation(history, lastLocation);
    }

    if (location.pathname !== '/login' && location.pathname !== '/') {
      dispatch(MainActions.setUrlLocation(location.pathname));
    }
  }, [dispatch, history, lastLocation, location.pathname]);

  return children;
});
