import _ from 'lodash';

export const VizLegendUtils = {
  isFocusItem: (focusedData, pathInfo) => {
    return (
      _.isEmpty(focusedData) ||
      _.isNil(pathInfo) ||
      _.some(focusedData, _pathInfo => _.isEqual(_pathInfo, pathInfo))
    );
  },
};

export const getLegendItemStyle = ({ isForcedMobile }) => {
  const style = {} as any;
  if (isForcedMobile) {
    style.paddingLeft = 5;
  }
  return style;
};
