import { Error } from '../common/widgets/Status';
import Main from '../common/redux/actions/MainActions';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { messages } from '../i18n';

const GenericLoadingError = props => {
  const title = messages.datasets.issueLoadingData;
  const detail = props.datasetStatusDetailStackTrace;
  const showErrorDetail = event => {
    event.stopPropagation();
    props.showErrorDetail(title, detail);
  };
  if (props.shortDisplay) {
    return <Error>{messages.datasets.errorLoadingDatasetShort}</Error>;
  } else {
    return (
      <Error>
        {messages.formatString(messages.datasets.errorLoadingDataset, {
          retryLink: (
            <a onClick={e => props.onRefresh(e)}>{messages.datasets.retry}</a>
          ),
          deleteLink: (
            <a onClick={e => props.onDelete(e)}>{messages.datasets.delete}</a>
          ),
          showErrorLink: (
            <a onClick={e => showErrorDetail(e)}>
              {messages.datasets.showDetails}
            </a>
          ),
        })}
      </Error>
    );
  }
};

const DatasetError = props => {
  return <GenericLoadingError {...props} />;
};

export default compose(
  connect(
    () => {
      return {};
    },
    dispatch => {
      return {
        showErrorDetail: (title, detail) => {
          dispatch(Main.showErrorDetail(true, title, detail));
        },
      };
    },
  ),
)(DatasetError);
