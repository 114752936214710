import * as d3 from 'd3';
import palette from './ColorPalette';
import { isNil } from 'lodash';
import { EMPTY_STRING_TOKEN } from '../Constants';

class ColorManager {
  constructor() {
    this.db = {};
    this.custom = {};
  }

  addGroup(name, force = false) {
    if (!this.db[name] || force) {
      const colors = d3.scaleOrdinal().range(palette);
      this.db[name] = colors;
    }
    return this.db[name];
  }

  getGroup(name) {
    return this.db[name] ? this.db[name] : this.addGroup(name);
  }

  getCustomGroup(name) {
    return this.custom[name];
  }

  getColor(groupName, colorName, theme) {
    if (isNil(colorName)) {
      return theme?.colors?.ContentBackground;
    }
    if (colorName === '') {
      colorName = EMPTY_STRING_TOKEN;
    }
    const group = this.getGroup(groupName);
    let color = group(colorName);
    const custom = this.custom[groupName];
    if (custom?.[colorName]) {
      color = custom[colorName];
    }
    return color;
  }

  setCustomColors(name, colors) {
    this.custom[name] = colors;
  }
  clearCustomColors(name) {
    this.custom[name] = {};
  }

  reset(groupName) {
    return this.addGroup(groupName, true);
  }
}

const colorManager = new ColorManager();

export default colorManager;
