import { useEffect, useMemo, FC, useCallback, useContext } from 'react';
import {
  chain,
  slice,
  includes,
  head,
  last,
  isEmpty,
  findIndex,
  matches,
  nth,
  get,
  map,
  filter,
  forEach,
  max,
  min,
  reverse,
  range,
  concat,
  find,
  isEqual,
  isFunction,
  isNil,
  noop,
  reduce,
  constant,
  toNumber,
  isNumber,
  isString,
  uniqBy,
  identity,
} from 'lodash';
import {
  DATA_FORMATTER,
  DATA_TYPE_FORMAT,
  VIZ_QUERY_TOTALS_FLAG,
} from '../../../common';
import {
  MetricAggregateType,
  IPipelineChangesDataAnnotated,
  IPipelineChangesColumnData,
  D3StrategyHookType,
  DrillLinkingProps,
  INoDataHocProps,
  UseMouseEventsProps,
  UseRendererProps,
} from './pipeline-changes.interfaces';
import * as d3 from 'd3';
import { IAnyAttribute, IPreFilterOperand } from '../../../datasets';
import {
  useCustomFormatToggleSelector,
  useOpenDiscoveryPresentStateSelector,
  useOpenVizSelector,
  useVizOptionSelector,
} from '../../../common/redux/selectors/viz-selector.hook';
import { useAccount } from '../../../common/utilities/account';
import { ILegendDatum, VizLegendUtils } from '../viz-legend';
import { LegendShapes } from '../legend-shape';
import {
  centeredPanel,
  useDiscoverTheme,
  useDiscoverThemeColors,
} from '../../../common/emotion';
import ColorManager from '../../../common/d3/ColorManager';
import { useDispatch } from 'react-redux';
import Discover from '../../../common/redux/actions/DiscoverActions';
import { messages } from '../../../i18n';
import { Viz } from '../../VizUtil';
import { useHasValueChanged } from '../../../common/utilities/state-helpers.hook';
import {
  IHistoryState,
  ILinkToReport,
  IReportDetailInfo,
} from '../../viz-redirect';
import { useReportLinkEnabled } from '../base-cartesian-chart';
import { isDashletMode as getDashletMode } from '../../../auth';
import { useHistory } from 'react-router-dom';
import { IToggle } from '../../interfaces';
import { TOOLTIP_ID } from '../chart-tooltip/chart-tooltip.hook';
import { FormattedNameValue } from '../chart-tooltip/chart-tooltip.interface';
import { ChartContext } from '../../viz-chart/chart.hook';

// @NOTE: these definitions are hard-coded dependencies based on DSC-6171 viz. Should be dynamic sometime later
export const CLOSED_WON_FIELD_NAME = 'Closed Won';
export const pipelineChangesStageOrder = [
  'Start',
  'New',
  'Added',
  'Increases',
  'Moved In',
  'Moved Out',
  'Decreases',
  'Removed',
  'Closed Lost',
  CLOSED_WON_FIELD_NAME,
  'End',
];

export const allUpsideStages = slice(pipelineChangesStageOrder, 1, 4);
export const allDownsideStages = slice(pipelineChangesStageOrder, 5, 9);

// These labels should be eventually translated
export const LegendKeys = {
  StartEnd: 'Pipeline',
  Increases: 'Increases',
  Decreases: 'Decreases',
  ClosedWon: CLOSED_WON_FIELD_NAME,
};

export const filterByZeroValue = ({ value = 0 }) => {
  return Math.abs(value) === 0;
};

export const topRoundedRect = (x, y, width, height, unadjustedRadius) => {
  if (width === 0 || height === 0) {
    return '';
  }

  let radius = unadjustedRadius;

  if (radius > height) {
    radius = height;
  }

  // SVG path operations
  // https://www.w3.org/TR/SVG/paths.html#PathDataLinetoCommands
  // https://www.w3.org/TR/SVG/paths.html#PathDataEllipticalArcCommands
  return `M${x + radius},${y}h${width -
    radius * 2}a${radius},${radius} 0 0 1 ${radius},${radius}v${height -
    radius}h${-width}v${-height +
    radius}a${radius},${radius} 0 0 1 ${radius},${-radius}z`;
};

export const getFocusedLabel = (
  stageName,
  upsideStages = [],
  downsideStages = [],
  stageNames = [],
) => {
  if (includes([head(stageNames), last(stageNames)], stageName)) {
    return LegendKeys.StartEnd;
  } else if (stageName === CLOSED_WON_FIELD_NAME) {
    return LegendKeys.ClosedWon;
  } else if (includes(upsideStages, stageName)) {
    return LegendKeys.Increases;
  } else if (includes(downsideStages, stageName)) {
    return LegendKeys.Decreases;
  }
  return LegendKeys.StartEnd;
};

export const useForecastChangesColors = (vizId: string) => {
  const dispatch = useDispatch();
  const customColors = useVizOptionSelector({
    discoveryId: vizId,
    option: 'customColors',
  });
  const {
    PipelineChangesForecastColor: ForecastColor,
    PipelineChangesIncreasesColor: IncreasesColor,
    PipelineChangesDecreasesColor: DecreasesColor,
    PipelineChangesClosedWonColor: ClosedWonColor,
  } = useDiscoverThemeColors();

  const defaultColors = useMemo(
    () => ({
      increasesColor: IncreasesColor,
      decreasesColor: DecreasesColor,
      closedWonColor: ClosedWonColor,
      startEndColor: ForecastColor,
    }),
    [ForecastColor, IncreasesColor, DecreasesColor, ClosedWonColor],
  );

  const {
    increasesColor,
    decreasesColor,
    closedWonColor,
    startEndColor,
  } = defaultColors;

  // Forecast Changes has a default color set
  useEffect(() => {
    if (isEmpty(customColors)) {
      dispatch(
        Discover.updateCustomColors(vizId, {
          [LegendKeys.Increases]: IncreasesColor,
          [LegendKeys.Decreases]: DecreasesColor,
          [CLOSED_WON_FIELD_NAME]: ClosedWonColor,
          [LegendKeys.StartEnd]: ForecastColor,
        }),
      );
    }
  }, [
    dispatch,
    vizId,
    customColors,
    IncreasesColor,
    DecreasesColor,
    ClosedWonColor,
    ForecastColor,
  ]);

  const customGroup = ColorManager.getCustomGroup(vizId);
  return useMemo(() => {
    if (!isEmpty(customGroup)) {
      return {
        increasesColor: ColorManager.getColor(vizId, LegendKeys.Increases),
        decreasesColor: ColorManager.getColor(vizId, LegendKeys.Decreases),
        closedWonColor: ColorManager.getColor(vizId, LegendKeys.ClosedWon),
        startEndColor: ColorManager.getColor(vizId, LegendKeys.StartEnd),
      };
    }
    return {
      increasesColor,
      decreasesColor,
      closedWonColor,
      startEndColor,
    };
  }, [
    customGroup,
    increasesColor,
    decreasesColor,
    closedWonColor,
    startEndColor,
    vizId,
  ]);
};

export const useSegmentStages = (
  segmentFieldName: string,
  columnNames: string[],
  results: any[] = [],
  stageNameLabelChangeDict: { [key: string]: string } = {},
) => {
  return useMemo(() => {
    const stageNameIdx = findIndex(columnNames, matches(segmentFieldName));
    if (stageNameIdx < 0) {
      return [];
    }

    return chain(results)
      .map((_resultRow: any[]) => {
        const _stageName = nth(_resultRow, stageNameIdx) as string;
        return get(stageNameLabelChangeDict, _stageName, _stageName);
      })
      .reject(matches(VIZ_QUERY_TOTALS_FLAG))
      .reject(isEmpty)
      .uniq()
      .value();
  }, [segmentFieldName, columnNames, results, stageNameLabelChangeDict]);
};

export const useMetricValuesSortedByStage = (
  stageNames: string[],
  results: any[],
  metricField,
  metricFormatter,
  metricIndex = 0,
): MetricAggregateType[] => {
  return useMemo<MetricAggregateType[]>(() => {
    return map(
      stageNames,
      (stage: string): MetricAggregateType => {
        // VIZ_QUERY_TOTALS_FLAG indicates the total'd data for the metrics
        const aggregateRows = filter(
          results,
          resultRow =>
            includes(resultRow, stage) &&
            includes(resultRow, VIZ_QUERY_TOTALS_FLAG),
        );

        // stage may not exist in query results. Default metric value for that stage to zero
        const aggregateRow = head(aggregateRows);
        const metricValue = aggregateRow ? nth(aggregateRow, metricIndex) : 0;

        return {
          stage,
          stageLabel: stage,
          metricName: metricField?.name,
          metricValue,
          metricValueFormatter: metricFormatter,
        } as MetricAggregateType;
      },
    );
  }, [metricField, metricFormatter, metricIndex, stageNames, results]);
};

export const useMetricsWithStageAggregation = (
  stageNames: string[],
  metricFields: IAnyAttribute[],
  columnInfo: any[],
  results: any[],
): MetricAggregateType[][] => {
  const metricNamesWithIndexes = map(metricFields, field => {
    const _fieldIndex = findIndex(columnInfo, {
      attributeName: field?.name,
    });

    const fieldFormatter =
      DATA_TYPE_FORMAT.getFormatterByName(field?.formatType) ||
      DATA_TYPE_FORMAT.getDefaultFormatterForType(field?.attributeType);

    return {
      metricField: field,
      index: _fieldIndex,
      metricFormatter: fieldFormatter,
    };
  });

  return map(
    metricNamesWithIndexes,
    ({ metricField, index: _metricIndexInQueryResults, metricFormatter }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useMetricValuesSortedByStage(
        stageNames,
        results,
        metricField,
        metricFormatter,
        _metricIndexInQueryResults,
      );
    },
  );
};
export const useAggregatedMetricsWithTimeLabels = (
  metricsWithStageAggregation: MetricAggregateType[][],
  columnInfo: any[],
  results: any[],
  currentSnapshotField: IAnyAttribute,
  previousSnapshotField?: IAnyAttribute,
): MetricAggregateType[][] => {
  return useMemo<MetricAggregateType[][]>(() => {
    const currentSnapshotFieldOrdinal = findIndex(columnInfo, {
      attributeName: currentSnapshotField?.name,
    });
    if (
      isEmpty(columnInfo) ||
      isEmpty(previousSnapshotField) ||
      currentSnapshotFieldOrdinal < 0
    ) {
      return metricsWithStageAggregation;
    }
    const previousSnapshotFieldOrdinal = findIndex(columnInfo, {
      attributeName: previousSnapshotField.name,
    });
    const nonAggregateResultRow = find(results, row => {
      return (
        row[currentSnapshotFieldOrdinal] !== VIZ_QUERY_TOTALS_FLAG &&
        row[previousSnapshotFieldOrdinal] !== VIZ_QUERY_TOTALS_FLAG
      );
    });

    if (isEmpty(nonAggregateResultRow)) {
      return metricsWithStageAggregation;
    } else {
      const startLabel = nonAggregateResultRow[previousSnapshotFieldOrdinal];
      const endLabel = nonAggregateResultRow[currentSnapshotFieldOrdinal];

      return map(metricsWithStageAggregation, metricRow => {
        return map(metricRow, obj => {
          if (obj.stage === head(metricRow)?.stage) {
            return {
              ...obj,
              stageLabel: startLabel,
            };
          } else if (obj.stage === last(metricRow)?.stage) {
            return {
              ...obj,
              stageLabel: endLabel,
            };
          } else {
            return {
              ...obj,
            };
          }
        });
      });
    }
  }, [
    columnInfo,
    currentSnapshotField,
    previousSnapshotField,
    results,
    metricsWithStageAggregation,
  ]);
};

/**
 * build the data for d3 to consume
 * - calculates absolute and relative amounts for the eventual size/position of bars
 * - label
 * - closed won flag (special color flag)
 * - formatter (for eventual render)
 */
export const useAnnotatedData = (
  queryError: any,
  upsideStages: string[],
  orderedStageAggregations: MetricAggregateType[],
): IPipelineChangesDataAnnotated[] => {
  return useMemo<IPipelineChangesDataAnnotated[]>(() => {
    if (queryError) {
      return [];
    }

    let sumAfterCurrentValue = 0;
    const _pipelineChangesDataAnnotated: IPipelineChangesDataAnnotated[] = [];
    forEach(orderedStageAggregations, (_stageAggregation, _stageIdx) => {
      const {
        stage: stageName,
        stageLabel,
        metricValue: stageMetricAggregate,
        metricName: stageMetricName,
        metricValueFormatter,
      } = _stageAggregation;

      // Each stage is a relative change from the last stage. Upside changes are positive, downside stages are negative
      const effectivePipelineStageValue: number = includes(
        upsideStages,
        stageName,
      )
        ? Math.abs(stageMetricAggregate)
        : -1 * Math.abs(stageMetricAggregate);

      if (_stageIdx === 0) {
        sumAfterCurrentValue = effectivePipelineStageValue;
      } else if (_stageIdx === orderedStageAggregations.length - 1) {
        sumAfterCurrentValue = 0;
      } else {
        sumAfterCurrentValue =
          _pipelineChangesDataAnnotated[_stageIdx - 1].sumAfterCurrentValue +
          effectivePipelineStageValue;
      }

      _pipelineChangesDataAnnotated[_stageIdx] = {
        stage: stageName,
        label: stageLabel,
        value:
          0 === Math.abs(effectivePipelineStageValue)
            ? 0
            : effectivePipelineStageValue,
        primaryMetricName: stageMetricName,
        sumAfterCurrentValue,
        formatter: metricValueFormatter ?? DATA_FORMATTER.WHOLE_NUMBER,
      };
    });

    return _pipelineChangesDataAnnotated;
  }, [queryError, upsideStages, orderedStageAggregations]);
};

export const COLLAPSING_PERCENTAGE_THRESHOLD = 1 / 3;

/**
 * useScaleFeatures calculates and provides anything related to the scale of the chart
 * @param stageNames
 * @param chartXPadding
 * @param chartYPadding
 * @param pipelineChangesData
 * @param chartWidth
 * @param chartHeight
 */
export const useScaleFeatures = ({
  vizId,
  stageLabels = [],
  chartXPadding,
  chartYPadding,
  pipelineChangesData,
}: {
  vizId: string;
  stageLabels: string[];
  chartXPadding: number;
  chartYPadding: number;
  pipelineChangesData: IPipelineChangesDataAnnotated[];
}) => {
  const { height: chartHeight, width: chartWidth } = useContext(ChartContext);
  const canCollapseToggle: IToggle = useCustomFormatToggleSelector({
    discoveryId: vizId,
    toggleName: 'canCollapse',
    defaultValue: false,
  });

  const canCollapse = !!canCollapseToggle?.on;

  return useMemo(() => {
    // quantitative domain for d3
    const chartMinValue = d3.min(
      pipelineChangesData,
      _pipelineChangesDatum => _pipelineChangesDatum.sumAfterCurrentValue,
    );
    const chartMaxValue = d3.max(
      pipelineChangesData,
      _pipelineChangesDatum => _pipelineChangesDatum.sumAfterCurrentValue,
    );

    // scaling functions for d3
    const xScale = d3
      .scaleBand()
      .domain(stageLabels)
      .range([chartXPadding, chartWidth - chartXPadding]);

    // collapse whitespace if necessary
    const bodyValues = map(
      slice(pipelineChangesData, 1, pipelineChangesData.length - 1),
      'value',
    );
    const maxBodyValue = max(map(bodyValues, Math.abs));

    const newAndEndValues = map(
      [head(pipelineChangesData), last(pipelineChangesData)],
      'value',
    );

    // using the minimum makes sure that collapsed data stays above above the axis
    const newEndAbsoluteValues = map(newAndEndValues, Math.abs);
    const minNewEnd = min(newEndAbsoluteValues);
    const minPercentOfNewEnd = maxBodyValue <= 0 ? 0 : minNewEnd / maxBodyValue;
    const isCollapsing =
      canCollapse && minPercentOfNewEnd >= COLLAPSING_PERCENTAGE_THRESHOLD;
    const fullBodyValue = chartMaxValue - minNewEnd;

    // if collapsing the chart, make the base "zero-value" relative to the size of stages between new/end
    const chartFloor = isCollapsing
      ? minNewEnd - fullBodyValue * COLLAPSING_PERCENTAGE_THRESHOLD
      : min([0, chartMinValue]);

    // chart boundaries - based on min/max, but get a 'rounded' padding above the max
    // NOTE: current data structure doesn't lend well to d3.extent
    const adjustedChartMax = Math.abs(chartMaxValue - chartFloor);
    const log10 = adjustedChartMax > 0.001 ? Math.log10(chartMaxValue) : 0;
    const log10Floor = Math.floor(log10);
    const minSteps = max([5, log10Floor]);
    const numSteps = min([minSteps, 10]);
    const stepValue = (adjustedChartMax * 1.08) / numSteps;

    const yScale = d3
      .scaleLinear()
      .domain([chartFloor, chartMaxValue])
      .range([
        chartHeight - chartYPadding,
        chartYPadding + chartYPadding / 4, // adding 1/4 prevents some labels from being cut off
      ]);

    const [yDomStart, yDomEnd] = yScale.domain();

    let yTickValues = reverse(range(yDomStart, yDomEnd + stepValue, stepValue));

    // unlikely for negative values, but guards for it
    if (chartMinValue < 0) {
      const negativeRangedTicks = range(yDomStart, chartMinValue, -stepValue);
      yTickValues = concat(yTickValues, negativeRangedTicks);
    }

    // bandWidth the maximum width a bar can be without overlapping into the adjacent bar
    const bandWidth = d3
      .scaleBand()
      .domain(stageLabels)
      .range([chartXPadding, chartWidth - chartXPadding])
      .bandwidth();
    const barWidth = bandWidth / 1.1;

    // xScaleDomainSelector ensures that d3 has a unique way to map data to the x axis
    const xScaleDomainSelector = data => data?.label;

    return {
      xScale,
      xScaleDomainSelector,
      yScale,
      yTickValues,
      barWidth,
      bandWidth,
    };
  }, [
    pipelineChangesData,
    stageLabels,
    chartXPadding,
    chartWidth,
    canCollapse,
    chartHeight,
    chartYPadding,
  ]);
};

export const useBarColor = ({ vizId, pipelineChangesData }) => {
  const {
    increasesColor,
    decreasesColor,
    closedWonColor,
    startEndColor,
  } = useForecastChangesColors(vizId);

  const getBarColor = useCallback(
    stageName => {
      const datum = find(pipelineChangesData, { stage: stageName });
      const idx = findIndex(pipelineChangesData, { stage: stageName });

      const isEndColumn = idx === 0 || idx === pipelineChangesData.length - 1;
      const isOffsetNegative = datum.value < 0;
      const offsetColor = isOffsetNegative ? decreasesColor : increasesColor;
      const isClosedWon = stageName === CLOSED_WON_FIELD_NAME;

      return isEndColumn
        ? startEndColor
        : isClosedWon
        ? closedWonColor
        : offsetColor;
    },
    [
      closedWonColor,
      decreasesColor,
      increasesColor,
      pipelineChangesData,
      startEndColor,
    ],
  );

  return {
    getBarColor,
  };
};

/**
 * useD3DataBuilder calculates the finalized positions/numbers/strings for d3 elements
 * @param vizId
 * @param pipelineChangesData
 * @param yScale
 * @param metricAggregationsByStage
 * @param xScale
 * @param xScaleDomainSelector
 * @param barWidth
 * @param maximumBarWidth
 * @param upsideStages
 * @param downsideStages
 * @param stageNames
 */
export const useD3DataBuilder = ({
  vizId,
  pipelineChangesData,
  yScale,
  metricAggregationsByStage,
  xScale,
  xScaleDomainSelector,
  barWidth,
  maximumBarWidth,
  downsideStages,
  isDataFocused,
  getBarColor,
  showPositiveLabels = false,
}: D3StrategyHookType) => {
  const { currentUser: { i18nPrefs = {} } = {} } = useAccount();
  const viz = useOpenVizSelector({ discoveryId: vizId });

  return useMemo(() => {
    const pipelineChangesColumnData: IPipelineChangesColumnData[] = map(
      pipelineChangesData,
      (datum: IPipelineChangesDataAnnotated, idx) => {
        const {
          value,
          sumAfterCurrentValue,
          stage: stageName,
          primaryMetricName,
        } = datum;
        const customFormatProps = (Viz.getDataCustomFormatters(viz) ?? {})[
          primaryMetricName
        ];
        const previousSum =
          idx === 0 ? 0 : pipelineChangesData[idx - 1]?.sumAfterCurrentValue;

        // the chart can collapse whitespace, based on size of start/end of data
        const scaledBase =
          idx === 0 || idx === pipelineChangesData.length - 1
            ? head(yScale.range())
            : yScale(0);
        const barLength = scaledBase - yScale(Math.abs(value));

        const barPosLeft: number =
          xScale(xScaleDomainSelector(datum)) +
          (maximumBarWidth - barWidth) / 2;
        const barPosTop: number = yScale(
          max([previousSum, sumAfterCurrentValue]),
        );

        const metricAggregationsForCurrentStage: MetricAggregateType[] = get(
          metricAggregationsByStage,
          stageName,
          [],
        );

        const formattedMetricAggregations: FormattedNameValue[] = map(
          metricAggregationsForCurrentStage,
          ({
            stage,
            metricName: _metricName,
            metricValue,
            metricValueFormatter,
          }) => {
            const adjustedStageValue: number =
              isEqual(_metricName, primaryMetricName) &&
              includes(downsideStages, stage) &&
              !showPositiveLabels
                ? -1 * Math.abs(metricValue)
                : Math.abs(metricValue);
            const formattedValue: string =
              metricValueFormatter?.formatSmall(
                adjustedStageValue,
                i18nPrefs,
              ) ?? `${adjustedStageValue}`;
            return {
              name: _metricName,
              value: formattedValue,
            };
          },
        );

        const textLabel = Viz.formatNumberToFit(
          showPositiveLabels ? Math.abs(value) : value,
          barWidth - 20,
          datum.formatter,
          i18nPrefs,
          customFormatProps,
        );

        const d3Datum: IPipelineChangesColumnData = {
          x: barPosLeft,
          y: barPosTop,
          width: barWidth,
          height: barLength,
          labelPosX: barPosLeft + barWidth / 2,
          adjacentConnectorLeft: barPosLeft + barWidth,
          adjacentConnectorRight: barPosLeft + maximumBarWidth, // algebraically, this is the reduced formula
          fill: getBarColor(stageName),
          value,
          label: textLabel,
          stageName,
          ordinal: idx,
          tooltipData: formattedMetricAggregations,
        };

        d3Datum.dim = !isDataFocused(d3Datum);

        return d3Datum;
      },
    );

    return pipelineChangesColumnData;
  }, [
    pipelineChangesData,
    viz,
    yScale,
    xScale,
    xScaleDomainSelector,
    maximumBarWidth,
    barWidth,
    metricAggregationsByStage,
    i18nPrefs,
    getBarColor,
    isDataFocused,
    downsideStages,
    showPositiveLabels,
  ]);
};

export const useFocusedData = ({
  vizId,
  toLabel = stageName => identity(stageName),
}) => {
  const dispatch = useDispatch();
  const discovery = useOpenDiscoveryPresentStateSelector({
    discoveryId: vizId,
  });
  const { focusedData = [] } = discovery;

  const buildFocusObject = useCallback(
    (d3Datum: IPipelineChangesColumnData) => {
      const label: string = toLabel(d3Datum.stageName);
      return {
        shape: LegendShapes.CIRCLE,
        label,
        info: { label },
      };
    },
    [toLabel],
  );

  const setFocusedVizData = useCallback(
    (d3Datum: IPipelineChangesColumnData) => {
      dispatch(
        Discover.setFocusedVizData(vizId, buildFocusObject(d3Datum)?.info),
      );
    },
    [dispatch, vizId, buildFocusObject],
  );

  return {
    isDataFocused: (d3Datum: IPipelineChangesColumnData) =>
      VizLegendUtils.isFocusItem(focusedData, buildFocusObject(d3Datum)?.info),
    setFocusedVizData,
    buildFocusObject,
  };
};

export const NoDataHoc: FC<INoDataHocProps> = ({
  condition = false,
  children,
}) => {
  if (condition) {
    return (
      <div css={centeredPanel()}>
        <h2>{messages.noDataWasFound}</h2>
      </div>
    );
  }
  return <>{children}</>;
};

export const useDrillLinking = ({
  vizId,
  getFieldByStageName,
  additionalCalcs = [],
  additionalMetrics = [],
  fieldsToShelvesRef,
}: DrillLinkingProps) => {
  const dispatch = useDispatch();

  const linkToReport: ILinkToReport | {} = useVizOptionSelector({
    discoveryId: vizId,
    option: 'linkToReport',
    defaultValue: {},
  });

  const filters = useVizOptionSelector({
    discoveryId: vizId,
    option: 'filters',
  });

  const history = useHistory<IHistoryState>();

  const drillLinkIntoStage = useCallback(
    (d3Data: IPipelineChangesColumnData) => {
      const { stageName } = d3Data ?? {};

      if (!isFunction(getFieldByStageName) || !isString(stageName)) {
        return;
      }

      const fieldToFilter = getFieldByStageName(stageName);

      if (isNil(fieldToFilter)) {
        return;
      }

      const attributes: IPreFilterOperand[] = [
        {
          attribute: fieldToFilter,
          value: [stageName],
        },
      ];

      const reportDetailInfo: IReportDetailInfo = {
        linkToReport: linkToReport as ILinkToReport,
        attributes,
        filters,
        calcs: additionalCalcs,
        metrics: additionalMetrics,
        toShelves: fieldsToShelvesRef?.current,
      };
      dispatch(Discover.openReportLink(reportDetailInfo, history));
    },
    [
      getFieldByStageName,
      linkToReport,
      filters,
      dispatch,
      history,
      additionalCalcs,
      additionalMetrics,
      fieldsToShelvesRef,
    ],
  );

  return {
    drillLinkIntoStage,
  };
};

export const useLegend = ({ vizId, d3Data, buildFocusObject }) => {
  const dispatch = useDispatch();
  const legendData = useMemo(() => {
    const stageLegendItems: ILegendDatum[] = map(d3Data, buildFocusObject);
    return uniqBy(stageLegendItems, 'label');
  }, [d3Data, buildFocusObject]);

  const hasLegendValueChanged = useHasValueChanged({
    value: legendData,
  });

  useEffect(() => {
    if (hasLegendValueChanged) {
      dispatch(Discover.setVizLegendData(vizId, legendData));
    }
  }, [dispatch, vizId, legendData, hasLegendValueChanged]);
};

export const getD3MouseEvent = ref => {
  try {
    return (d3 as any)?.mouse(ref ?? null); // d3-selection is an old version and needs an upgrade
  } catch (e) {
    console.log('Mouse event failure', e);
  }

  return null;
};

export const useMouseEvents = ({
  vizId,
  svgRef,
  onHover = noop,
  drillLink = noop,
  setFocusedVizData = noop,
}: UseMouseEventsProps) => {
  const isDrillLinkingEnabled = useReportLinkEnabled(vizId);

  const onMouseMove = useCallback(
    (d3Data: IPipelineChangesColumnData) => {
      const [xRelToChart = 0, yRelToChart = 0] =
        getD3MouseEvent(svgRef?.current) ?? [];

      onHover({
        d3Data,
        anchor: {
          posX: xRelToChart,
          posY: yRelToChart,
        },
      });
    },
    [svgRef, onHover],
  );
  const onForecastStageMouseOut = useCallback(() => {
    onHover();
  }, [onHover]);

  const onBarClick = useCallback(
    (d3Data: IPipelineChangesColumnData) =>
      isDrillLinkingEnabled ? drillLink(d3Data) : setFocusedVizData(d3Data),
    [isDrillLinkingEnabled, drillLink, setFocusedVizData],
  );

  return {
    onBarMouseEnter: onMouseMove,
    onBarMouseMove: onMouseMove,
    onBarMouseLeave: onForecastStageMouseOut,
    onBarClick,
  };
};

export const DEFAULT_PADDING_Y = 50;

export const useRenderer = ({
  vizId,
  svgRef,
  d3Data,
  xScale,
  yScale,
  yTickValues,
  yTickFormatter,
  stageLabels,
  onBarMouseEnter = noop,
  onBarMouseMove = noop,
  onBarMouseLeave = noop,
  onBarClick = noop,
  chartYPadding,
  chartXPadding,
  setYPadding,
  upsideStages,
  xScaleDomainSelector,
  secondaryXAxisLabels,
}: UseRendererProps) => {
  const { height: chartHeight, width: chartWidth } = useContext(ChartContext);
  const {
    MediumBorder: MediumBorderColor,
    LightFontWeight,
    Gray70,
  } = useDiscoverThemeColors();
  const theme = useDiscoverTheme();

  const linkToReport: ILinkToReport | {} = useVizOptionSelector({
    discoveryId: vizId,
    option: 'linkToReport',
    defaultValue: {},
  });

  const availableChartWidth = chartWidth - chartXPadding * 2;

  const zeroValueBarData = filter(d3Data, filterByZeroValue);

  const showDataLabels = useVizOptionSelector({
    discoveryId: vizId,
    option: 'showDataLabels',
  });

  const canCollapseToggle: IToggle = useCustomFormatToggleSelector({
    discoveryId: vizId,
    toggleName: 'canCollapse',
    defaultValue: false,
  });

  const canCollapse = !!canCollapseToggle?.on;

  const isDrillLinkingEnabled = useReportLinkEnabled(vizId);
  const isDashletMode = getDashletMode();
  const { currentUser: { i18nPrefs = {} } = {} } = useAccount();

  const hasD3DataSignificantlyChanged = useHasValueChanged({
    value: d3Data?.length ?? 0,
  });
  const hasDrillLinkingToggleChanged = useHasValueChanged({
    value: isDrillLinkingEnabled,
  });
  const hasDrillLinkingValueChanged = useHasValueChanged({
    value: linkToReport,
  });
  const hasShowDataLabelsChanged = useHasValueChanged({
    value: showDataLabels,
  });
  const hasCanCollapseChanged = useHasValueChanged({
    value: canCollapse,
  });
  const shouldResetGraph =
    hasD3DataSignificantlyChanged ||
    hasDrillLinkingToggleChanged ||
    hasDrillLinkingValueChanged ||
    hasShowDataLabelsChanged ||
    hasCanCollapseChanged;

  // recreate elements and DOM listeners when data significantly changes
  useEffect(() => {
    if (shouldResetGraph) {
      const svg = d3.select(svgRef.current);

      if (d3Data?.length > 0) {
        // remove listeners
        svg
          .selectAll('.bar.stage-data')
          .on('mouseenter', null)
          .on('mousemove', null)
          .on('mouseleave', null)
          .on('click', null);
      }

      svg.selectAll(`svg > *:not(#${TOOLTIP_ID},.axis)`).remove();

      // create bar objects and bind data
      const barGroup = svg
        .insert('g', ':first-child')
        .attr('class', 'forecast-stage-data')
        .selectAll('.bar.stage-data')
        .data(d3Data)
        .enter();

      const barContainers = barGroup.insert('g');

      if (showDataLabels) {
        barContainers
          .insert('text', ':first-child')
          .attr('text-anchor', 'middle')
          .attr('class', 'bar-value-label')
          .on('mouseenter', onBarMouseEnter)
          .on('mousemove', onBarMouseMove)
          .on('mouseleave', onBarMouseLeave)
          .on('click', onBarClick);
      }

      // create the bars and their listeners (creating listeners separately prevents overloading)
      barContainers
        .insert('path', ':first-child')
        .attr('class', 'bar stage-data')
        .on('mouseenter', onBarMouseEnter)
        .on('mousemove', onBarMouseMove)
        .on('mouseleave', onBarMouseLeave)
        .on('click', onBarClick);

      // aria labels
      barContainers.insert('desc');

      barContainers
        .filter(filterByZeroValue)
        .insert('line', ':first-child')
        .attr('class', 'connector bar-zero-connector')
        .attr('stroke', Gray70)
        .attr('stroke-dasharray', '2,3');

      barContainers
        .filter(
          (d: IPipelineChangesColumnData) => d.ordinal < d3Data.length - 1,
        )
        .insert('line', ':first-child')
        .attr('class', 'connector bar-adjacent-connector')
        .attr('stroke', Gray70)
        .attr('stroke-dasharray', '2');
    }
  }, [
    shouldResetGraph,
    svgRef,
    d3Data,
    zeroValueBarData,
    onBarClick,
    onBarMouseEnter,
    onBarMouseMove,
    onBarMouseLeave,
    isDrillLinkingEnabled,
    showDataLabels,
    Gray70,
  ]);

  //  Modifies existing D3 elements (performant updates on window resize, etc)
  useEffect(() => {
    if (!isEmpty(d3Data) && !isNil(svgRef?.current)) {
      const svg = d3.select(svgRef.current);

      // delete any existing stuff we don't need
      const axes = svg.selectAll('.axis,.background-gridlines');
      if (axes?.nodes()?.length > 0) {
        axes.remove();
      }

      // create x axis
      svg
        .insert('g', ':first-child')
        .attr('class', 'axis xAxis')
        .attr('id', 'x-axis')
        .call(d3.axisBottom(xScale).tickValues(stageLabels))
        .attr('transform', `translate(0, ${chartHeight - chartYPadding + 1})`);

      if (!isEmpty(secondaryXAxisLabels)) {
        const secAxis = svg
          .insert('g', ':first-child')
          .attr('class', 'axis xAxis')
          .attr('id', 'x-axis-secondary')
          .attr(
            'transform',
            `translate(0, ${chartHeight - chartYPadding + 20 + 1})`,
          );
        const secAxisLabels = secAxis
          .selectAll('.label')
          .data([
            {
              key: head(stageLabels),
              value: head(secondaryXAxisLabels),
            },
            {
              key: last(stageLabels),
              value: last(secondaryXAxisLabels),
            },
          ])
          .enter();

        secAxisLabels
          .insert('text')
          .text(d => d.value)
          .attr('class', 'secondary-label')
          .attr('x', d => xScale(d.key) + xScale.bandwidth() / 2)
          .attr('y', 12)
          .attr('text-anchor', 'middle')
          .attr('fill', 'currentColor');
      }

      // rotate x-axis labels if they can't fit the horizontal space in the chart
      const xAxis = svgRef?.current?.querySelector('#x-axis');
      const secondaryXAxis = svgRef?.current?.querySelector(
        '#x-axis-secondary',
      );
      const xAxisLabels = xAxis?.querySelectorAll('text');
      const secXAxisLabels = secondaryXAxis?.querySelectorAll('text');
      const calculateOverlap = reduce(
        xAxisLabels,
        (acc, label: SVGTextElement) => {
          const { left, right } = label?.getBoundingClientRect();
          const {
            left: axisLeft,
            right: axisRight,
          } = xAxis?.getBoundingClientRect();
          return {
            lastRight: right,
            hasOverlap:
              acc.hasOverlap ||
              left < acc.lastRight ||
              left < axisLeft ||
              right > axisRight,
          };
        },
        {
          lastRight: 0,
          hasOverlap: false,
        },
      );
      if (calculateOverlap.hasOverlap) {
        if (chartYPadding === DEFAULT_PADDING_Y) {
          setYPadding(60);
        }
        forEach(xAxisLabels, (label: SVGTextElement, index) => {
          const thisLabel = d3.select(label);
          thisLabel
            .style('text-anchor', 'end')
            .attr('dx', '-0.8em')
            .attr('transform', 'rotate(325)');

          if (index === 0 || index === xAxisLabels.length - 1) {
            const label =
              index === 0 ? head(secXAxisLabels) : last(secXAxisLabels);
            if (!isNil(label)) {
              const secLabelText = d3.select(label);
              if (!isEmpty(secLabelText.text())) {
                thisLabel.text(secLabelText.text());
              }
            }
          }
        });
        forEach(secXAxisLabels, (label: SVGTextElement) => {
          d3.select(label).remove();
        });
      } else if (chartYPadding !== DEFAULT_PADDING_Y) {
        setYPadding(DEFAULT_PADDING_Y);
      }

      // create y axis
      svg
        .insert('g', ':first-child')
        .attr('class', 'axis yAxis gridlines')
        .attr('id', 'y-axis-gridlines')
        .call(
          d3
            .axisLeft(yScale)
            .tickValues(yTickValues)
            .tickFormat(
              (d, i) =>
                yTickValues.map(v => yTickFormatter.formatSmall(v, i18nPrefs))[
                  i
                ],
            ),
        )
        .attr('transform', `translate(${chartXPadding}, 0)`);

      const yGridLines = d3
        .axisLeft(yScale)
        .tickValues(yTickValues)
        .tickFormat(constant(''))
        .tickSizeInner(-chartWidth + chartXPadding + DEFAULT_PADDING_Y);

      svg
        .insert('g', ':first-child')
        .attr('class', 'background-gridlines')
        .attr('transform', `translate(${chartXPadding}, 0)`)
        .call(yGridLines);

      svg
        .selectAll('g.background-gridlines line')
        .attr('stroke', MediumBorderColor);

      svg
        .selectAll(
          'g.axis .tick text, g.axis text.secondary-label, text.bar-value-label',
        )
        .attr('font-size', 12)
        .attr('font-weight', LightFontWeight);

      // hide the vertical axis edge
      svg
        .selectAll('g.yAxis .domain,g.background-gridlines .domain')
        .attr('stroke-width', 0);
      svg.selectAll('g.yAxis line').attr('stroke-width', 0);

      // create stage elements
      const bars = svg.selectAll('.bar.stage-data');
      bars.data(d3Data);
      svg.selectAll('text.bar-value-label').data(d3Data);
      svg.selectAll('.forecast-stage-data desc').data(d3Data);
      svg.selectAll('line.bar-adjacent-connector').data(d3Data);
      svg.selectAll('line.bar-zero-connector').data(zeroValueBarData);

      svg
        .selectAll('.forecast-stage-data desc')
        .text((d: IPipelineChangesColumnData) => d.label);

      bars
        .attr('d', (d: IPipelineChangesColumnData) =>
          topRoundedRect(d.x, d.y, d.width, d.height, 4),
        )
        .attr('fill', (d: IPipelineChangesColumnData) => d.fill)
        .attr('stroke', (d: IPipelineChangesColumnData) => d.fill)
        .attr('opacity', (d: IPipelineChangesColumnData) =>
          d.dim ? '0.16' : '1',
        );

      const barLabels = svg
        .selectAll('text.bar-value-label')
        .attr('x', (d: IPipelineChangesColumnData) => d.labelPosX)
        .attr('y', (d: IPipelineChangesColumnData) => {
          if (d.height >= 14) {
            return d.y + 4 + d.height / 2;
          }
          return d.y - 5;
        })
        .attr('fill', (d: IPipelineChangesColumnData) => {
          if (d.height >= 14) {
            return Viz.getContrastColor(d3.color(d.fill) as any, theme);
          }
          return 'currentColor';
        })
        .attr('width', '200px');
      const textLabels = barLabels.text(null);

      const multiLineLabels =
        map(d3Data, ({ label, width }) => Viz.multiLineTSpans(label, width)) ??
        [];
      const numTspans = max(map(multiLineLabels, 'label.length'));

      if (numTspans > 1 && !(textLabels.select('tspan').size() > 0)) {
        for (let i = 0; i < numTspans; i++) {
          textLabels.append('tspan');
        }
        for (let i = 0; i < multiLineLabels?.length; i++) {
          for (let j = 0; j < numTspans; j++) {
            const labelArrLength = multiLineLabels[i]?.label?.length;
            svg
              .select(`text.bar-value-label:nth-of-type(${i + 1})`)
              .attr(
                'y',
                (d: IPipelineChangesColumnData) => d.y - labelArrLength * 12,
              );

            const x =
              toNumber(d3Data[i]?.labelPosX) +
              toNumber(multiLineLabels[i]?.label[j]?.x);
            const dy = multiLineLabels[i]?.label[j]?.dy;
            const text = multiLineLabels[i]?.label[j]?.text;
            if (isNumber(x) && isNumber(dy) && isString(text)) {
              svg
                .select(
                  `text.bar-value-label:nth-of-type(${i +
                    1}) tspan:nth-of-type(${j + 1})`,
                )
                .text(text)
                .attr('x', x)
                .attr('dy', `${dy}em`);
            }
          }
        }
      } else {
        barLabels.text((d: IPipelineChangesColumnData) => d.label);
      }

      const getAdjacentConnectorYPos = (d: IPipelineChangesColumnData) =>
        includes(upsideStages, d.stageName) ? d.y : d.y + d.height;
      svg
        .selectAll('line.bar-adjacent-connector')
        .attr('x1', (d: IPipelineChangesColumnData) => d.adjacentConnectorLeft)
        .attr('y1', getAdjacentConnectorYPos)
        .attr('x2', (d: IPipelineChangesColumnData) => d.adjacentConnectorRight)
        .attr('y2', getAdjacentConnectorYPos);

      svg
        .selectAll('line.bar-zero-connector')
        .attr('x1', (d: IPipelineChangesColumnData) => d.x)
        .attr('y1', (d: IPipelineChangesColumnData) => d.y)
        .attr('x2', (d: IPipelineChangesColumnData) => d.x + d.width)
        .attr('y2', (d: IPipelineChangesColumnData) => d.y);
    }
  }, [
    xScale,
    yScale,
    xScaleDomainSelector,
    i18nPrefs,
    d3Data,
    zeroValueBarData,
    svgRef,
    chartHeight,
    availableChartWidth,
    yTickValues,
    yTickFormatter,
    stageLabels,
    chartXPadding,
    chartYPadding,
    setYPadding,
    chartWidth,
    upsideStages,
    isDashletMode,
    MediumBorderColor,
    LightFontWeight,
    theme,
    secondaryXAxisLabels,
  ]);
};
