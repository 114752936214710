import { css } from '@emotion/react';

export const thumbnailStyles = ({
  theme: {
    colors: { LightFontWeight, PanelBorder, CorvanaBackgroundLight },
  },
}) => css`
  .thumb {
    width: fit-content;
    padding: 8px;
  }
  .thumbnail-content {
    font-weight: ${LightFontWeight};
    padding: 8px;
    /* maintain a 16:9 aspect ratio to make for a nice slack thumbnail on larger mobile devices (iphone 6+ 7+ 8+ ...) */
    width: 362px;
    height: 204px;
    color: ${PanelBorder};
  }
  .thumbnail-content.no-thumbnail {
    height: 144px;
    width: 215px;
    background-color: ${CorvanaBackgroundLight};
    border-radius: 10px;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .thumbnail-content .insight-item {
    border: none;
    box-shadow: none;
    margin: 0px;
    padding: 0px;
    width: inherit;
  }
  .thumbnail-content .insight-item:hover {
    transform: none;
    cursor: default;
  }
  .thumbnail-content .insight-item .footer {
    display: none;
  }
`;
