import gql from 'graphql-tag';

const AdminQueries = {
  GetUsersQuery: gql`
    query {
      getUsers {
        id
        fullName
        givenName
        surname
        title
        email
        profileImage
        roles
        status
        createDate
        phoneNumber
        lastModifiedDate
        lastActivityDate
        emailNotifications
        slackNotifications
        welcomeDeliveryStatus
        smsNotifications
      }
    }
  `,

  SyncUsersQuery: gql`
    mutation {
      syncUsers {
        id
        fullName
        givenName
        surname
        title
        email
        profileImage
        roles
        status
        createDate
        phoneNumber
        lastModifiedDate
        lastActivityDate
        emailNotifications
        slackNotifications
        welcomeDeliveryStatus
        smsNotifications
      }
    }
  `,
};
export default AdminQueries;
