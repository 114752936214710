import { createFilterForField, StringFilterSubTypes } from '../Filter';
import { FilterOperators } from '../FilterOperators';
import { isString } from 'lodash';
import { IAnyAttribute } from '../../../datasets';

export default (field: IAnyAttribute, operands: string | string[]) => {
  const filter = createFilterForField(field);
  const EQUALS = FilterOperators.forFilterType(filter.type).eq;
  filter.expression.left.operator = EQUALS?.key;
  filter.expression.left.operands = isString(operands) ? [operands] : operands;
  filter.subType = StringFilterSubTypes.SET_CONDITION;
  return filter;
};
