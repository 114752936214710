import styled from '@emotion/styled';
import { font } from '../../../common/emotion';
import { CancelIcon } from '../../../icons/icons/icons.component';
import { FilterIcon } from '../../../icons';
import { css } from '@emotion/react';

export const StackedFilterArea = styled.div(
  ({
    theme: { colors: { LightFontWeight, ContentText } = {} as any } = {},
  }: any) => css`
    ${font({ size: '12px', weight: LightFontWeight, color: ContentText })}
    display: flex;
    flex-direction: column;
    padding: 8px;
    user-select: none;
    height: 100%;
    min-width: 80px;
  `,
);

export const StackedFilterAreaTitle = styled.div(
  ({ theme: { colors: { MediumFontWeight } = {} as any } = {} }: any) => css`
    ${font({ size: '14px', weight: MediumFontWeight })}
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
);

export const StackedFilterContainer = styled.ul`
  display: grid;
  grid-template-columns: [panel-left] 1fr [panel-right];
  grid-template-rows: auto;
  margin: 0;
  padding: 0;
`;

export const StackedFilter = styled.li`
  justify-self: stretch;
  width: 100%;
  margin-bottom: 0.3em;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
`;

export const filterIconStyles = css`
  min-width: 15px;
  margin: 0.5em;
`;

export const StackedFilterIcon = styled(FilterIcon)`
  ${filterIconStyles}
`;

export const StackedFilterText = styled.div(
  ({ theme: { colors: { ActiveLinkText } = {} as any } = {} }: any) => css`
    margin-top: 0.25em;
    flex-grow: 1;
    color: ${ActiveLinkText};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `,
);

export const StackedFilterDelete = styled(CancelIcon)`
  min-width: 15px;
  margin: 0.5em;
  height: 1em;
  width: 1em;
`;

export const LoaderWrapper = styled.div`
  padding: 8px;
  width: 100%;
`;
