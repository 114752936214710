// can convert to ISearchableDropdownOption
export interface ISlicerOption {
  option: string;
  isSelected: boolean;
  labelOverride?: string;
}

export enum TimestampSlicerTypes {
  RANGE = 'RANGE',
  SINCE = 'SINCE',
  BETWEEN = 'BETWEEN',
}

export enum SelectStatus {
  ALL = 'all',
  NONE = 'none',
  SOME = 'some',
}

export interface ISlicerReducerType {
  hasChanged: boolean;
  selectStatus: SelectStatus;
  allSelected: boolean;
  noneSelected: boolean;
  options: ISlicerOption[];
  loading: boolean;
  searchInput: string;
  reset: () => any;
  isPaged: boolean;
  updateSearchInput: (searchInput: string) => any;
  toggleOption: (option: string) => any;
  selectOnly: (option: string) => any;
  clear: () => any;
  selectAll: () => any;
  save: () => any;
  selectTimeFrame: (options: string[]) => void;
}
