import { css } from '@emotion/react';
import { useMemo } from 'react';
import { ISugarIconProps } from '../common/sugar-icon.types';
import classnames from 'classnames';
import { useIconColor } from './icon-color.hook';
import { useDiscoverTheme } from '../../common/emotion/theme';
import { getIconSize } from '../common/icon-size.util';
import { noop, pickBy, startsWith } from 'lodash';

export const SugarIcon = ({
  icon,
  flipX,
  flipY,
  rotation,
  isSpinning,
  size,
  disabled,
  onClick,
  color,
  className,
  iconType = 'medium',
  iconSize,
  hoverColor,
  hover,
  sm,
  md,
  lg,
  ...remainingProps
}: ISugarIconProps) => {
  const { colors: { IconPrimaryHoverColor } = {} as any } = useDiscoverTheme();
  let cursor;
  if (disabled) {
    cursor = 'not-allowed';
  } else if (onClick) {
    cursor = 'pointer';
  }
  const allowHover = (onClick || hover) && !disabled;
  const hoverStyle = useMemo(
    () =>
      allowHover ? { color: hoverColor || IconPrimaryHoverColor } : undefined,
    [allowHover, hoverColor, IconPrimaryHoverColor],
  );
  const iconColor = useIconColor({ iconType, color, disabled });
  const fontSize = getIconSize({ icon, iconSize, size, sm, md, lg });
  const styles: any = useMemo(
    () =>
      css({
        cursor,
        fontSize,
        color: iconColor,
        ':hover': hoverStyle,
        '::before': { fontSize },
      }),
    [cursor, fontSize, iconColor, hoverStyle],
  );
  return (
    <div
      onMouseEnter={noop}
      css={styles}
      onClick={onClick}
      className={classnames({
        sicon: true,
        [`sicon-${icon}`]: true,
        [`sicon-rotate-${rotation}`]: !!rotation,
        'sicon-is-spinning': isSpinning,
        'sicon-flip-y': flipY,
        'sicon-flip-x': flipX,
        'sicon-disabled': disabled,
        [className]: className,
      })}
      {...pickBy(remainingProps, (val, key) => startsWith(key, 'aria-'))}
    />
  );
};
