import { useEffect, useCallback, useState, memo } from 'react';
import { isEmpty, last } from 'lodash';
import { SubMenu, CustomRangeItem } from './slicer-option-sub-menu.styles';
import { ISlicerOption, TimestampSlicerTypes } from '../../../interfaces';
import { TimeFrameDropdown } from '../time-frame-dropdown';
import { SlicerTimeRangeInfo } from '../slicer-timerange-info';

export interface ISlicerOptionSubMenuProps {
  options: ISlicerOption[];
  selectRange: (options: string[], includeCurrentPeriod: boolean) => void;
  name: string;
}

export const SlicerOptionSubMenu = memo<ISlicerOptionSubMenuProps>(
  ({ options, selectRange, name }) => {
    const isRangeType = last(options).option === TimestampSlicerTypes.RANGE;

    const [customRange, setCustomRange] = useState('');
    const [timeFrame, setTimeFrame] = useState('');
    const [includeCurrentPartial, setIncludeCurrentPartial] = useState(false);

    useEffect(() => {
      if (isRangeType) {
        const [customRange, timeFrame, includeCurrentPartial] = options.map(
          option => option?.option,
        );
        setCustomRange(customRange);
        setTimeFrame(timeFrame);
        setIncludeCurrentPartial(includeCurrentPartial === 'true');
      } else {
        setCustomRange('');
        setTimeFrame('');
        setIncludeCurrentPartial(false);
      }
    }, [isRangeType, options]);

    const handleChangeCustomRange = event => {
      const { value } = event.target;
      if (isEmpty(value) || /^\d+$/.test(value)) {
        setCustomRange(value);
        if (!isEmpty(timeFrame) && !isEmpty(value)) {
          selectRange([value, timeFrame], includeCurrentPartial);
        }
      }
    };

    const handleChangeTimeFrame = value => {
      setTimeFrame(value);
      if (!isEmpty(customRange) && !isEmpty(value)) {
        selectRange([customRange, value], includeCurrentPartial);
      }
    };

    const handleChangeIncludeCurrentPartialPeriod = useCallback(
      (toggledValue: boolean) => {
        setIncludeCurrentPartial(toggledValue);

        if (!isEmpty(customRange) && !isEmpty(timeFrame)) {
          selectRange([customRange, timeFrame], toggledValue);
        }
      },
      [customRange, selectRange, timeFrame],
    );

    return (
      <SubMenu>
        <CustomRangeItem>
          <input
            className='form-control'
            type='text'
            pattern='\d+'
            placeholder='Number'
            value={customRange}
            onChange={handleChangeCustomRange}
          />
        </CustomRangeItem>
        <TimeFrameDropdown
          onMenuItemClick={handleChangeTimeFrame}
          selectedValue={timeFrame}
        />
        {!isEmpty(customRange) && !isEmpty(timeFrame) && (
          <SlicerTimeRangeInfo
            customRange={customRange}
            timeFrame={timeFrame}
            includeCurrentPartial={includeCurrentPartial}
            name={name}
            includeCurrentPartialClickHandler={
              handleChangeIncludeCurrentPartialPeriod
            }
          />
        )}
      </SubMenu>
    );
  },
);
