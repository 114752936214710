import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { DatasetQueries } from '../../common/graphql';
import Dataset from '../../common/redux/actions/DatasetActions';
import { SUGARCRM_PRIMARY_DATASET_SYSTEM_NAME } from '../../common';

export const useCreateDataset = () => {
  const dispatch = useDispatch();

  const [createDatasetMutation, { loading }] = useMutation(
    DatasetQueries.CreateSugarCrmDatasetMutation,
    {
      refetchQueries: [{ query: DatasetQueries.DatasetQuery }],
    },
  );

  const createDataset = useCallback(
    dataset => {
      const datasetWithAnnotations = {
        ...dataset,
        customDatasetAnnotations: {
          systemName: SUGARCRM_PRIMARY_DATASET_SYSTEM_NAME,
        },
      };
      return createDatasetMutation({
        variables: { request: { ...datasetWithAnnotations } },
      }).then(({ data }) => {
        dispatch(Dataset.setDatasetPage('datasetList'));
        // set the id of the dataset as the latest on added to pulse the row in the list
        dispatch(Dataset.setDatasetAdded(data?.createSugarCrmDataset?.id));
      });
    },
    [createDatasetMutation, dispatch],
  );

  return { isSaving: loading, createDataset };
};
