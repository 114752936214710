import { isNil, noop } from 'lodash';
import { CaretIcon } from '../Icons';
import classnames from 'classnames';
import { TabsStyled, ScrollingNavBox } from './scrolling-nav.styles';
import { useScrollingNavHook } from './scrolling-nav.hook';
import { useSelector } from 'react-redux';

interface IProps {
  activeKey?: string;
  navItems?: any[];
  addItem?: any;
  handleSelect?: (val: string) => void;
}

export const ScrollingNav = ({
  activeKey = '',
  navItems = [],
  addItem = null,
  handleSelect = noop,
}: IProps) => {
  const {
    navRef,
    tabsRef,
    scrollControllerRef,
    lCtrlRef,
    rCtrlRef,
    scrollEnabled,
    getFirstVisibleTab,
    getLastVisibleTab,
    updateDisplay,
  } = useScrollingNavHook({ activeKey });

  const { isMobile } = useSelector((state: any) => ({
    isMobile: state.main.isMobile,
  }));

  const onScroll = i => {
    switch (i) {
      case -1: {
        const last = getLastVisibleTab();
        navRef.current.scrollLeft -= !isNil(last) ? last.offsetWidth : 100;
        break;
      }
      case 1: {
        const first = getFirstVisibleTab();
        navRef.current.scrollLeft += !isNil(first) ? first.offsetWidth : 100;
        break;
      }
    }
    updateDisplay();
  };

  return (
    <ScrollingNavBox
      ref={navRef}
      className={`${scrollEnabled ? 'scrolling' : 'fixed'}`}
    >
      <div
        className={classnames(
          'scroll-controller',
          'sticky',
          'sticky-left',
          !isMobile && scrollEnabled ? 'visible' : 'hidden',
        )}
        ref={scrollControllerRef}
      >
        <button
          className={'control control-left'}
          ref={lCtrlRef}
          onClick={() => onScroll(-1)}
        >
          <CaretIcon />
        </button>
        <button
          className={'control control-right'}
          ref={rCtrlRef}
          onClick={() => onScroll(1)}
        >
          <CaretIcon />
        </button>
      </div>
      <TabsStyled
        ref={tabsRef}
        value={activeKey}
        onChange={(e, key) => {
          handleSelect(key);
          e.stopPropagation();
          e.preventDefault();
        }}
        aria-label='dataset tabs'
      >
        {navItems}
      </TabsStyled>
      {addItem}
    </ScrollingNavBox>
  );
};
