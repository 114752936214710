import { useCallback, useState, SyntheticEvent } from 'react';
import { messages } from '../../i18n';
import { useSelector } from 'react-redux';
import {
  cloneDeep,
  isFunction,
  get,
  isNil,
  forEach,
  isEmpty,
  noop,
} from 'lodash';
import {
  FunnelConfiguration,
  FiscalCalendarSettings,
  DataIngestionSettings,
  DatasetIngestionContextProvider,
} from './panels';
import { DatasetSettingsContent } from './panels/fiscal-calendar/fiscal-calendar.styles';
import {
  IDatasetAnnotationsRequest,
  IDatasetSettings,
  IDatasetSettingsProps,
} from './edit-dataset-settings.interfaces';
import {
  DatasetSection,
  TabStyled,
  TabsStyled,
  TabsWrapper,
} from './edit-dataset-settings.styles';
import { isAppAdmin as isAppAdminSelector } from '../../common/redux/selectors/AccountSelectors';
import {
  PANEL_DATASET_INGESTION,
  PANEL_FISCAL_CALENDAR,
  PANEL_FUNNEL_CONFIGURATION,
  prepareDatasetSettingsRequest,
} from './edit-dataset-settings.util';
import {
  InvisibleButton,
  PrimaryButton,
  SecondaryButton,
  ButtonContainer,
} from '../../ui';
import {
  DatasetSettingsContextProvider,
  useDatasetSettingsContext,
} from './edit-dataset-settings.context';
import { FullScreenPortal } from '../../common/widgets/dialogs/full-screen-portal';
import { useMutation } from '@apollo/client';
import { DatasetQueries } from '../../common/graphql';
import { MutationHookOptions } from '@apollo/client/react/types/types';

interface IProps {
  onClose: () => void;
}

export const DatasetSettingsComponent = ({ onClose }: IProps) => {
  const [activePanel, setActivePanel] = useState<string>(PANEL_FISCAL_CALENDAR);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [skipRefresh, setSkipRefresh] = useState<boolean>(true);
  const {
    dataset,
    datasetSettings,
    setDatasetSettings,
  } = useDatasetSettingsContext();

  const { isAppAdmin } = useSelector((state: any) => ({
    isAppAdmin: isAppAdminSelector(state?.account) as boolean,
  }));

  const [
    mutateDataset,
    { loading: savingInProgress = false } = {},
  ] = useMutation(DatasetQueries.UpdateSugarCrmDatasetCustomAnnotations);

  const updateSugarCrmDataset = useCallback(
    async (annotations, callback = noop) => {
      const onError = err => console.log(`update dataset error`, err);

      const mutationArgs: MutationHookOptions = {
        variables: { request: annotations },
        refetchQueries: ['getSugarCrmDatasetAnnotations', 'getDatasets'],
        awaitRefetchQueries: true,
        onError,
      };

      if (isFunction(callback)) {
        mutationArgs.onCompleted = ({ datasetJson: _datasetJson }) => {
          callback(_datasetJson);
        };
      }

      await mutateDataset(mutationArgs);
    },
    [mutateDataset],
  );

  const doCancel = () => {
    doClose();
  };

  const doClose = () => {
    if (isFunction(onClose)) {
      onClose();
    }
  };

  const doSave = (closeOnSave = false) => {
    if (closeOnSave) {
      callUpdateDatasetSettings(() => doClose());
    } else {
      callUpdateDatasetSettings();
    }
  };

  const callUpdateDatasetSettings = (callback: () => void = undefined) => {
    const datasetAnnotations = {
      ...datasetSettings.datasetAnnotations,
      skipRefresh,
    };

    const updateObj: IDatasetAnnotationsRequest = prepareDatasetSettingsRequest(
      {
        ...datasetSettings,
        datasetAnnotations,
      },
    );

    updateSugarCrmDataset(updateObj, callback);

    setHasChanged(false);
  };

  const onDatasetSettingsChange = (
    savingPanel: string,
    datasetSettingsSaveObj: IDatasetSettings,
  ) => {
    const newDatasetSettings = cloneDeep(datasetSettings);

    const datasetSettingFields = ['annotations', 'datasetAnnotations'];

    let hasSettingsChanged = false;

    forEach(datasetSettingFields, field => {
      if (!isNil(datasetSettingsSaveObj[field])) {
        newDatasetSettings[field] = datasetSettingsSaveObj[field]; // assume data is valid
        hasSettingsChanged = true;
      }
    });

    if (hasSettingsChanged) {
      setHasChanged(hasSettingsChanged);
      setDatasetSettings(newDatasetSettings);
      setSkipRefresh(
        prevState => !(savingPanel === PANEL_DATASET_INGESTION) && prevState,
      );
    }
  };

  const navigateToPanel = (_event: SyntheticEvent, panelId: string) => {
    setActivePanel(panelId);
  };

  const renderPanel = () => {
    switch (activePanel) {
      case PANEL_FISCAL_CALENDAR: {
        return (
          <FiscalCalendarSettings
            isAppAdmin={isAppAdmin}
            onChange={(savObj: IDatasetSettings) =>
              onDatasetSettingsChange(PANEL_FISCAL_CALENDAR, savObj)
            }
          />
        );
      }
      case PANEL_DATASET_INGESTION: {
        return (
          <DatasetIngestionContextProvider
            onChange={savObj =>
              onDatasetSettingsChange(PANEL_DATASET_INGESTION, savObj)
            }
          >
            <DataIngestionSettings />
          </DatasetIngestionContextProvider>
        );
      }
      case PANEL_FUNNEL_CONFIGURATION: {
        return (
          <FunnelConfiguration
            onChange={(savObj: IDatasetSettings) =>
              onDatasetSettingsChange(PANEL_FUNNEL_CONFIGURATION, savObj)
            }
          />
        );
      }
      default: {
        return;
      }
    }
  };

  const hasDatasetMetadata = !isEmpty(get(dataset, 'attributes', []));

  const isSaveDisabled = !isAppAdmin || !hasChanged || savingInProgress;

  return (
    <FullScreenPortal
      className='account-settings'
      titlePanel={messages.editDatasetPanel.datasetSettings}
      buttonGroup={
        <ButtonContainer>
          <InvisibleButton
            disabled={savingInProgress}
            onClick={() => {
              doCancel();
            }}
          >
            {messages.cancel}
          </InvisibleButton>
          <SecondaryButton
            disabled={isSaveDisabled}
            onClick={() => {
              doSave();
            }}
          >
            {savingInProgress
              ? messages.confirmLogout.saving
              : messages.editDatasetPanel.applyChanges}
          </SecondaryButton>
          <PrimaryButton
            disabled={isSaveDisabled}
            onClick={() => {
              doSave(true);
            }}
          >
            {messages.editDatasetPanel.saveChangesAndExit}
          </PrimaryButton>
        </ButtonContainer>
      }
    >
      <div>
        <TabsWrapper>
          <TabsStyled
            value={activePanel}
            onChange={navigateToPanel}
            aria-label='dataset tabs'
          >
            <TabStyled
              label={messages.editDatasetPanel.fiscalCalendar}
              value={PANEL_FISCAL_CALENDAR}
            />
            {isAppAdmin && (
              <TabStyled
                label={messages.editDatasetPanel.configureDataIngestion}
                value={PANEL_DATASET_INGESTION}
              />
            )}
            <TabStyled
              label={messages.editDatasetPanel.funnelConfiguration}
              value={PANEL_FUNNEL_CONFIGURATION}
              disabled={!hasDatasetMetadata}
            />
          </TabsStyled>
        </TabsWrapper>

        <DatasetSection>
          <DatasetSettingsContent>{renderPanel()}</DatasetSettingsContent>
        </DatasetSection>
      </div>
    </FullScreenPortal>
  );
};

export const DatasetSettings = ({
  onClose,
  dataset,
}: IDatasetSettingsProps) => {
  const { isEditingDatasetSettings } = useSelector((state: any) => ({
    isEditingDatasetSettings: state?.dataset?.isEditingDatasetSettings ?? false,
  }));

  if (!(dataset?.id && isEditingDatasetSettings)) {
    return null;
  }

  return (
    <DatasetSettingsContextProvider dataset={dataset}>
      <DatasetSettingsComponent onClose={onClose} />
    </DatasetSettingsContextProvider>
  );
};
