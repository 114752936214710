import _ from 'lodash';
import createHyphenator from 'hyphen';
import hyphenationPatternsEnUs from 'hyphen/patterns/en-us';

const hyphenate = createHyphenator(hyphenationPatternsEnUs);

// wrap a label up to two lines with syllable splitting and ellipsis...
// derived from https://bl.ocks.org/mbostock/7555321

// On average, 2.3 12px tall characters fill 12 horizontal pixels
// Using this approximation saves countless DOM reads/writes
//https://pearsonified.com/characters-per-line/
export function generateWrappedLabels(text, width) {
  const HYPHEN = '\u00AD'; // a 'shy' hyphen
  const labels = [];

  text.each(label => {
    const pixelsPerChar = 12 / 2.3;
    const labelLength = label.length;
    if (labelLength * pixelsPerChar <= width) {
      labels.push([label]);
    } else {
      const h = hyphenate(label);
      let words = h.split(/\s+/).reverse();
      let word = words.pop();
      const syllables = [];
      while (!_.isNil(word)) {
        const sy = word.split(HYPHEN).reverse();
        let s = sy.pop();
        while (s) {
          syllables.push(s);
          syllables.push(HYPHEN);
          s = sy.pop();
        }
        syllables.pop(); // remove the extra shy dash at the end
        syllables.push(' ');
        word = words.pop();
      }
      syllables.pop(); // remove the extra space at the end
      words = syllables.reverse();

      word = words.pop();
      let prevWordSyl = '';
      let lineNumber = 0;
      let line = [];
      const tags = [];
      let estStringWidth = 0;
      while (word) {
        if (word === HYPHEN || word === ' ') {
          prevWordSyl = word;
        }
        if (word !== HYPHEN) {
          line.push(word);
          estStringWidth = line.join('').length * pixelsPerChar;
          if (estStringWidth > width) {
            line.pop();
            if (lineNumber === 0) {
              if (prevWordSyl === HYPHEN) {
                line.push('-');
              }
              tags[lineNumber++] = line.join('');
              line = [word];
            } else if (lineNumber === 1) {
              const ntxt = line.join('');
              tags[lineNumber++] = `${ntxt.substring(0, ntxt.length - 3)}...`;
              labels.push(tags);
              words = [];
            }
          }
        }
        word = words.pop();
      }

      if (line.length > 0 && lineNumber === 1) {
        tags[lineNumber] = line.join('');
        labels.push(tags);
      }
    }
  });
  return labels;
}
