import { createFilterForField, StringFilterSubTypes } from '../Filter';
import { FilterOperators } from '../FilterOperators';

export default field => {
  const filter = createFilterForField(field);
  const NULL = FilterOperators.forFilterType(filter.type).null;
  filter.expression.left.operator = NULL?.key;
  filter.expression.left.operands = [];
  filter.subType = StringFilterSubTypes.SET_CONDITION;
  return filter;
};
