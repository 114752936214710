import { graphql } from '@apollo/client/react/hoc';
import {
  IComparisonMonitorConfig,
  IComparisonPeriodMonitorConfigPayload,
} from '../interfaces';
import { IQuery } from '../../../discovery';
import _ from 'lodash';
import { ICustomMonitorRequest } from '../../../monitors/interfaces';
import { Monitors } from '../../../common/graphql';

export interface IComparisonPeriodMonitorCreationArgs {
  name?: string;
  reportId?: string;
  monitorType?: 'comparisonperiod';
  query: IQuery;
}

function getComparisonPeriodConfig(
  opts: IComparisonMonitorConfig,
): IComparisonPeriodMonitorConfigPayload {
  const {
    id,
    upperThreshold,
    lowerThreshold,
    comparisonPeriodType,
    comparisonPeriodNum,
    titleText,
    bodyText,
    linkText,
    smsText,
    icon,
    inverted,
  } = opts;

  const args: any = {
    type: 'datastructures.comparison.ComparisonPeriodMonitorConfig',
    comparisonPeriodNum,
    comparisonPeriodType,
    inverted,
    labels: {
      targetReport: id,
      titleText,
      bodyText,
      linkText,
      smsText,
      icon,
    },
    upperThreshold,
    lowerThreshold,
  };
  return _.omitBy(args, _.isNil) as any;
}

function getMonitorRequest(
  opts: IComparisonMonitorConfig,
): ICustomMonitorRequest {
  const {
    id,
    name,
    datasetId,
    isDisabled,
    query: { attributeNames, measures, filters, calcs, useFiscalCalendar },
  } = opts;
  let config: string;
  try {
    config = JSON.stringify(getComparisonPeriodConfig(opts));
  } catch (err) {
    config = '';
  }
  return {
    id,
    name,
    datasetId,
    monitorType: 'comparison',
    config,
    isDisabled,
    query: {
      datasetId,
      attributeNames,
      measures,
      filters,
      calcs,
      useFiscalCalendar,
    },
  };
}

export const AddComparisonMonitorMutation = graphql<
  any,
  any,
  any,
  { addComparisonMonitor: (...args: any[]) => any }
>(Monitors.AddCustomMonitor, {
  options: {
    refetchQueries: [],
  },
  props: ({ mutate }) => ({
    async addComparisonMonitor(opts: IComparisonMonitorConfig) {
      const monitorReq = getMonitorRequest(opts);
      await mutate({
        variables: {
          monitorReq,
        },
      });
    },
  }),
});
