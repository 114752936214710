import ServiceLocator from '../../common/ServiceLocator';
import { ComponentTypes } from '../../common/Constants';
import { ComponentClass } from 'react';
import { connect } from 'react-redux';

const UnconnectedVisualizationInsights = (props: any) => {
  const TenantedInsights = ServiceLocator.get('COMPONENT', {
    type: ComponentTypes.INSIGHTS_LIST,
  });
  if (!TenantedInsights) {
    return null;
  }
  return (
    <div className='viz-insights-panel'>
      <TenantedInsights {...props} />
    </div>
  );
};

export const VisualizationInsights: ComponentClass<any> = (connect as any)(
  (state: any) => ({
    isForcedMobile: state.main.isForcedMobile,
  }),
)(UnconnectedVisualizationInsights);
