import _ from 'lodash';
import { Hierarchy, Viz as VizUtils } from '../../../../discovery/VizUtil';
import { FilterOperators } from '../../../../discovery/filter/FilterOperators';
import {
  FilterTypes,
  TimestampFilterSubTypes,
} from '../../../../discovery/filter/Filter';
import DateFilter from '../../../../discovery/filter/exports/DateFilter';
import EqualsFilter from '../../../../discovery/filter/exports/EqualsFilter';
import { TimePeriods } from '../../../../discovery/filter/relative-date-condition';
import { PriorPeriodTypes, PIVOT_TABLE } from '../../../../common/Constants';
import { createPriorPeriodCalcField } from '../../../../discovery/PriorPeriodMenuItem';
import { ChartSpecs } from '../../../../discovery/ChartSpecs';
import {
  getInsightContextFilter,
  TimePeriodMapping,
  TimeAttributeMapping,
} from '../../Common';
import { IVizOptions } from '../../../../discovery/interfaces';

const RelativeDateOperators = FilterOperators.forFilterType(
  FilterTypes.DATE,
  TimestampFilterSubTypes.RELATIVE_DATES,
);

export function getTimePeriodKey(eventData) {
  const periodType = eventData.periodType.toLowerCase();
  // set date filter
  return TimePeriodMapping[periodType];
}

export function getTimeAttributeKey(eventData) {
  const periodType = eventData.periodType.toLowerCase();
  return TimeAttributeMapping[periodType];
}

export function getDateFilterArgs(eventData) {
  const { lastDate } = eventData;
  const periodKey = getTimePeriodKey(eventData);
  // set date filter
  const period = TimePeriods[periodKey];
  const args = [`${eventData.periodNum}`, period.key, 'false'];
  if (lastDate) {
    args.push(lastDate);
  }
  return args;
}

export default ({ monitorEvent: { eventData }, dataset, viz }) => {
  const groups = [];
  const columns = [];
  const measures = [];
  const calcs = [];
  const options: Partial<IVizOptions> = {
    configPanelDetail: null,
    conditionalFormatting: undefined,
  };

  let filters = JSON.parse(_.get(viz, 'options.filters', '{}'));
  // remove any date filters
  _.forEach(filters, (f, name) => {
    if (f.type === FilterTypes.DATE) {
      delete filters[name];
    }
  });

  filters = {
    ...filters,
    ...getInsightContextFilter(eventData, dataset),
  };

  let measureAttribute;
  let hierarchyFields;
  let hierarchyField;
  let sortBy;
  let dateFilterArgs;
  _.forEach(dataset.attributes, a => {
    switch (a.name) {
      case eventData.measure:
        measureAttribute = a;
        measures.push(a);
        break;
      case eventData.segment:
        groups.push(a);
        break;
      case eventData.partitionName:
        filters[a.name] = EqualsFilter(a, eventData.partitionValue);
        break;
      case eventData.dateAttribute:
        dateFilterArgs = dateFilterArgs || getDateFilterArgs(eventData);
        filters[a.name] = DateFilter(
          a,
          dateFilterArgs,
          RelativeDateOperators.past,
        );
        // get available hierarchy fields
        hierarchyFields = Hierarchy.createTimeCalcFields(a);
        // get period hierarchy field
        hierarchyField = _.find(hierarchyFields, {
          timeAttribute: { key: getTimeAttributeKey(eventData) },
        });
        if (!_.isNil(hierarchyField)) {
          columns.push(hierarchyField);
        }
        break;
    }
  });

  // generate prior period calcs
  if (
    !_.isNil(measureAttribute) &&
    !_.isNil(hierarchyField) &&
    !_.isNil(hierarchyFields)
  ) {
    const priorPeriods = _.pick(PriorPeriodTypes, [
      'CHANGE_FROM',
      'PCT_CHANGE_FROM',
    ]);
    _.forEach(priorPeriods, (spec, key) => {
      const calc = createPriorPeriodCalcField(
        measureAttribute,
        spec,
        hierarchyField.timeAttribute,
        hierarchyField,
        hierarchyFields,
      );
      if (!_.isNil(calc)) {
        calcs.push(calc);
        measures.push(calc);
        if (key === 'PCT_CHANGE_FROM') {
          // add conditional formatting
          let colorScale;
          if (eventData.increasesAreGood) {
            colorScale = PIVOT_TABLE.COLOR_SCALES.RWG;
          } else {
            colorScale = PIVOT_TABLE.COLOR_SCALES.GWR;
          }
          options.conditionalFormatting = JSON.stringify([
            {
              name: calc.name,
              colorScale: colorScale.key,
            },
          ]);
          // add sorting spec
          sortBy = {
            direction: 'desc',
            field: calc,
            from: 'end',
          };
        }
      }
    });
  }

  if (_.has(viz.options, 'slicerSelections')) {
    options.slicerSelections = _.get(viz, 'options.slicerSelections', '[]');
  }

  return {
    dataset: { ...dataset },
    chartSpec: ChartSpecs.pivot,
    measures,
    groups,
    axis: columns,
    filters,
    slicers: VizUtils.getSlicers(viz),
    calcFields: calcs,
    options,
    sortBy,
  };
};
