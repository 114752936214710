export interface IInternationalizationPreferences {
  dateFormat?: string;
  timeFormat?: string;
  numGroupSeparator?: string;
  decimal?: string;
}

export enum Appearance {
  System = 'system',
  Light = 'light',
  Dark = 'dark',
}

export interface IUserPreferences {
  pinnedDiscoveries?: string[];
  appearance?: Appearance;
}

export interface IIdmSettings {
  role: string;
  srn: string;
  accountManagementUrl: string;
  administrationUrl?: string;
}

interface IUserInfoBase {
  id: string;
  email: string;
  emailNotifications?: boolean;
  givenName?: string;
  middleName?: string;
  surname?: string;
  fullName?: string;
  title?: string;
  phoneNumber?: string;
  profileImage?: string;
  slackNotifications?: string;
  smsNotifications?: boolean;
  roles: string[];
  i18nPrefs?: IInternationalizationPreferences;
}

export interface IUserInfo extends IUserInfoBase {
  profileImageURL?: string;
  defaultTenantId?: string;
  canImpersonate?: string[];
  status?: string;
  welcomeDeliveryStatus?: string;
  createDate?: number;
  lastModifiedDate?: number;
  lastActivityDate?: number;
  tenant: string;
  preferences?: IUserPreferences;
  timezone?: string;
  userName: string;
  department?: string;
  idm?: IIdmSettings;
  locale?: string;
  appearance?: Appearance;
  pendoAccountId?: string;
  pendoVisitorId?: string;
  isServiceAccountEnabled: boolean;
}

export interface IUpdateProfileRequest extends Omit<IUserInfoBase, 'roles'> {
  roles?: string[];
}
