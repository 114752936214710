import { graphql } from '@apollo/client/react/hoc';
import { DiscoverQueries } from '../../common/graphql';
import { VizQueries } from '../../common/graphql';
import _ from 'lodash';

export const MonitorQuery = graphql(DiscoverQueries.DiscoveriesQuery, {
  props: (store: any) => {
    if (store?.data?.loading) {
      return { discoveriesLoading: true };
    }
    const content = _.map(store?.data?.visualizations, v => {
      const description = _.find(v.options, { key: 'description' });
      const dataset = _.find(store?.data?.datasets, { id: v.datasetId });
      return {
        ...v,
        discoveryType: 'VISUALIZATION',
        description: !_.isNil(description) ? description.value : '',
        dataset,
        datasetName: dataset?.name,
      };
    });
    return {
      content,
      discoveriesLoading: false,
    };
  },
});

export const VizQuery = graphql(VizQueries.GetVisualization, {
  options: (props: { vizId: string }) => ({
    variables: { id: props?.vizId ?? '' },
  }),
  props: ({ data: { visualization, loading } = {} as any }) => {
    if (!loading && visualization) {
      const description = _.find(visualization.options, { key: 'description' });
      const dehydratedVisualization = {
        ...visualization,
        discoveryType: 'VISUALIZATION',
        description: description?.value ?? '',
        dataset: visualization?.dataset,
        datasetName: visualization?.dataset?.name,
      };
      return {
        fetching: loading,
        dehydratedVisualization,
        discoveriesLoading: false,
      };
    } else {
      return { fetching: loading, discoveriesLoading: true };
    }
  },
});
