import { css } from '@emotion/react';

export const biggestMoversStyles = ({
  theme: {
    colors: {
      BiggestMoverTopBullet,
      TableTextErrorColor,
      BiggestMoverBottomBullet,
      Green,
    },
  },
}) => css`
  .segment-bar-container div.bullet-background {
    position: absolute;
    height: 24px;
    opacity: 0.35;
  }
  .segment-bar-container > div {
    min-height: 1em;
  }
  .segment-bar-container div.bullet {
    position: absolute;
    height: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
    opacity: 0.9;
  }
  .segment-bar-container.top div.bullet-background {
    background-color: ${BiggestMoverTopBullet};
  }
  .segment-bar-container.top div.bullet {
    background-color: ${BiggestMoverTopBullet};
  }
  .segment-bar-container.bottom div.bullet-background {
    background-color: ${BiggestMoverBottomBullet};
  }
  .segment-bar-container.bottom div.bullet {
    background-color: ${BiggestMoverBottomBullet};
  }
  .segment-value svg.bottom path {
    fill: ${TableTextErrorColor};
  }
  .segment-value svg.top path {
    fill: ${Green};
  }
  .top-3-insight .difference::before {
    content: '\\A0';
  }
`;
