import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { isNil, isEqual, findIndex, throttle } from 'lodash';
import fastdom from 'fastdom';

export const useScrollingNavHook = ({ activeKey }) => {
  const navRef = useRef(null);
  const tabsRef = useRef(null);
  const scrollControllerRef = useRef(null);
  const lCtrlRef = useRef(null);
  const rCtrlRef = useRef(null);

  const [scrollEnabled, setScrollEnabled] = useState(false);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const onResize = throttle(() => {
    forceUpdate();
  }, 500);

  const measureTabTimeoutRef = useRef(null);
  const updateTabTimeoutRef = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      fastdom.clear(measureTabTimeoutRef.current);
      fastdom.clear(updateTabTimeoutRef.current);
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  const getFirstVisibleTab = useCallback(() => {
    const tabs = tabsRef.current.querySelectorAll('button');
    const { scrollLeft = 0 } = navRef.current || {};

    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];
      if (
        tab.offsetLeft + tab.offsetWidth >
        scrollLeft + scrollControllerRef.current.offsetWidth
      ) {
        return tab;
      }
    }
  }, []);

  const getLastVisibleTab = useCallback(() => {
    const tabs = tabsRef.current.querySelectorAll('button');
    const { scrollLeft = 0, scrollWidth = 0, offsetWidth = 0 } =
      navRef.current || {};
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];
      const scrollArea = scrollWidth - offsetWidth;
      if (tab.offsetLeft >= scrollLeft + scrollArea) {
        return tab;
      }
    }
  }, []);

  const updateDisplay = () => {
    fastdom.measure(() => {
      const node = navRef.current;
      const { scrollLeft = 0, scrollWidth = 0, offsetWidth = 0 } = node || {};
      const scrollStart = (scrollLeft ?? 0) === 0;
      const scrollEnd = Math.round(scrollWidth - scrollLeft) === offsetWidth;
      // Manually update element display based on scroll position
      lCtrlRef.current.disabled = scrollStart;
      rCtrlRef.current.disabled = scrollEnd;
      fastdom.mutate(() => {
        scrollControllerRef.current.setAttribute('blur', !scrollStart);
        const stickyElement = node?.querySelector('.sticky-right');
        if (!isNil(stickyElement)) {
          stickyElement.setAttribute('blur', !scrollEnd);
        }
      });
    });
  };

  const checkScrollEnabled = useCallback(() => {
    fastdom.measure(() => {
      const { scrollWidth = 0, offsetWidth = 0 } = navRef.current || {};
      const isScrollEnabled =
        !isNil(navRef.current) && offsetWidth < scrollWidth;
      if (isScrollEnabled !== scrollEnabled) {
        setScrollEnabled(isScrollEnabled);
      }
    });
  }, [scrollEnabled]);

  useEffect(() => {
    // Scroll to active tab
    const node = navRef?.current;
    const active = node.querySelector('button.Mui-selected');
    if (node) {
      navRef.current.scrollLeft = active.offsetLeft;
    }
    updateDisplay();
  }, []);

  useEffect(() => {
    fastdom.clear(measureTabTimeoutRef.current);
    fastdom.clear(updateTabTimeoutRef.current);
    // Scroll to active tab showing an extra tab to left or right
    measureTabTimeoutRef.current = fastdom.measure(() => {
      try {
        const node = tabsRef.current;
        const tabs = node.querySelectorAll('button');
        const active = node.querySelector('button.Mui-selected');
        const index = findIndex(tabs, active);
        const first = getFirstVisibleTab();
        const last = getLastVisibleTab();

        updateTabTimeoutRef.current = fastdom.mutate(() => {
          if (index === 0 || index === tabs.length - 1) {
            navRef.current.scrollLeft = active?.offsetLeft ?? 0;
          } else if (!isNil(first) && isEqual(active, first)) {
            navRef.current.scrollLeft -= tabs[index - 1].offsetWidth;
          } else if (!isNil(last) && isEqual(active, last)) {
            navRef.current.scrollLeft += tabs[index + 1].offsetWidth;
          }
          updateDisplay();
        });
      } catch (err) {
        console.log('Failed to update tabs', err);
      }
    });
  }, [getFirstVisibleTab, getLastVisibleTab, activeKey]);

  useEffect(() => {
    checkScrollEnabled();
  }, [checkScrollEnabled]);

  return {
    navRef,
    tabsRef,
    scrollControllerRef,
    lCtrlRef,
    rCtrlRef,
    scrollEnabled,
    getFirstVisibleTab,
    getLastVisibleTab,
    updateDisplay,
  };
};
