import { useResizeDetector } from 'react-resize-detector';
import { useCallback, useMemo, useRef, useState, memo, useEffect } from 'react';
import { isNumber, max, kebabCase, isEmpty, last, isFunction } from 'lodash';

import {
  SlicerDropdownToggle,
  SlicerWidgetContainer,
  SlicerTitle,
  MobileBackButton,
  SelectAllItem,
  SlicerFooterMenuItem,
  useStyles,
} from './slicer-widget.styles';
import { useSlicerReducer } from './slicer-widget.reducer';
import { SelectStatus } from '../interfaces';

import { SlicerTooManyItemsDialog } from './slicer-error-dialog';
import { SlicerTooltip } from './slicer-tooltip';
import { ItemCountSlug } from './item-count-slug';
import { SlicerButtonFooter } from './slicer-button-footer';
import { SlicerOptions } from './slicer-options';
import { NoFlex } from '../../../common/emotion';
import {
  DASHLET_WIDTH_OFFSET,
  MOBILE_WIDTH_OFFSET,
  SlicerThemeProvider,
} from './common';
import { MAX_SLICER_WIDTH, MIN_SLICER_WIDTH } from './common';
import { useSelector } from 'react-redux';
import { useSlicerError } from './common/slicer-error.hook';
import { useViewportDimensions } from '../../../common/utilities/dimensions.hook';
import { TimestampSlicerOptions, useStatusString } from '../timestamp-slicer';
import { useTimestampSlicer } from './common/timestamp-slicer.hook';
import { LeftChevron } from '../../../icons';
import { useSlicerMobileBreadcrumbs } from '../timestamp-slicer/common/slicer-mobile-breadcrumbs.hook';
import { isIndeterminateStatus, isStatusValid, joinBreadcrumbs } from '../util';
import { SlicerSelectionColumn } from '../slicer-option';
import { messages } from '../../../i18n';
import {
  SelectDropdown,
  SelectItem,
} from '../../../ui/dropdowns/select-dropdown';
import { useOpenVizSelector } from '../../../common/redux/selectors/viz-selector.hook';
import { SearchInput } from '../../../ui/dropdowns/searchable-dropdown';
import { usePreviousValue } from '../../../common/utilities/state-helpers.hook';

export const SlicerWidget = memo<{
  name;
  onToggle?: (isOpen: boolean, element: HTMLElement, width?: number) => void;
  enabled?: boolean;
  className?;
}>(({ name, onToggle, className, enabled = true }) => {
  const { id: vizId } = useOpenVizSelector();
  const isMobile = useSelector((state: any) => !!state.main.isMobile);
  const isDashletMode = useSelector(
    (state: any) => state.dashlet.isDashletMode,
  );
  const { isTimestampSlicer } = useTimestampSlicer({
    name,
    vizId,
  });
  const {
    loading,
    options,
    reset,
    toggleOption,
    selectAll,
    selectOnly,
    clear,
    updateSearchInput,
    searchInput,
    hasChanged,
    noneSelected,
    isPaged,
    selectStatus,
    save,
    selectTimeFrame,
  } = useSlicerReducer(name, vizId);
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);
  const id = `slicer-dropdown-${kebabCase(name)}`;
  const { width: windowWidth } = useViewportDimensions();
  const [width, setWidth] = useState(0);
  const [resizedWidth, setResizeWidth] = useState(0);
  const [_isOpen, setIsOpen] = useState(false);
  const isOpen = _isOpen && !loading;
  const {
    breadcrumbs,
    pushBreadcrumb,
    popBreadcrumb,
  } = useSlicerMobileBreadcrumbs();
  const isBreadcrumbsNotEmpty = useMemo(() => !isEmpty(breadcrumbs), [
    breadcrumbs,
  ]);
  const { onHideSlicerError, shouldShowSlicerError } = useSlicerError({
    loading,
    isPaged,
    isOpen,
    isTimestampSlicer,
  });

  useResizeDetector({
    targetRef: dropdownRef,
    onResize: (_width: number) => setResizeWidth(_width),
  });

  useEffect(() => {
    // When in mobile, we want all menu items to span the whole viewport,
    // but they should be no wider than 300 px in desktop mode.
    const offset = isDashletMode ? DASHLET_WIDTH_OFFSET : MOBILE_WIDTH_OFFSET;
    const maxWidth =
      isMobile || isDashletMode
        ? max([windowWidth - offset, MAX_SLICER_WIDTH])
        : MAX_SLICER_WIDTH;
    const refWidth = isNumber(resizedWidth)
      ? Math.max(
          Math.min(Math.floor(resizedWidth + 2), maxWidth),
          MIN_SLICER_WIDTH,
        )
      : 0;

    if (
      !(isOpen || isMobile) &&
      isNumber(refWidth) &&
      !isNaN(refWidth) &&
      refWidth - width >= 3
    ) {
      setWidth(refWidth);
    } else if (isMobile || isDashletMode) {
      const offset = isDashletMode ? DASHLET_WIDTH_OFFSET : MOBILE_WIDTH_OFFSET;
      setWidth(max([windowWidth - offset, 0]));
    }
  }, [isOpen, isMobile, isDashletMode, windowWidth, resizedWidth, width]);

  const setOpen = useCallback(
    _open => {
      if (!_open) {
        popBreadcrumb(last(breadcrumbs));
        if (isOpen && dropdownRef.current) {
          // close menu, since we keep it open
          dropdownRef.current.click();
          dropdownRef.current.blur();
        }
        reset();
      }
      setIsOpen(_open);
      if (isFunction(onToggle) && _open !== isOpen) {
        onToggle(_open, containerRef?.current, width);
      }
    },
    [breadcrumbs, isOpen, onToggle, popBreadcrumb, reset, width],
  );

  const hasEnabledChanged = usePreviousValue({ value: enabled });
  useEffect(() => {
    if (hasEnabledChanged && !enabled && isOpen && isFunction(setOpen)) {
      setOpen(false);
    }
  }, [enabled, hasEnabledChanged, isOpen, setOpen]);

  const modalId = `slicer-items-dialog-${kebabCase(name)}`;

  const { timestampStatusString } = useStatusString({
    options,
    isTimestampSlicer,
  });

  const handleToggleDropdown = (val: boolean) => {
    popBreadcrumb(last(breadcrumbs));
    setOpen(val);
  };

  const handleSelectAll = () => {
    if (selectStatus === SelectStatus.NONE) {
      selectAll();
    } else {
      clear();
    }
  };

  const {
    headerSx,
    listSx,
    dropdownWrapperStyle,
    searchMenuItemSx,
  } = useStyles({ isOpen });

  const selectAllLabel = isStatusValid(selectStatus)
    ? messages.deselectAll
    : messages.selectAll;

  const hasNoOptions = options?.length === 0;

  return (
    <SlicerThemeProvider isOpen={isOpen} width={width}>
      <SlicerWidgetContainer ref={containerRef} className={className}>
        <SlicerTooltip
          options={
            isTimestampSlicer
              ? [{ option: timestampStatusString, isSelected: true }]
              : options
          }
          loading={loading}
        >
          <SlicerTooManyItemsDialog
            modalId={modalId}
            onHide={onHideSlicerError}
            shouldShow={shouldShowSlicerError}
            name={name}
          />
          <span id='keeps-tooltip-working'>
            <SelectDropdown
              id={id}
              closeAfterSelect={false}
              ref={dropdownRef}
              headerSx={headerSx}
              listSx={listSx}
              style={dropdownWrapperStyle}
              onToggle={setOpen}
              title={
                <SlicerDropdownToggle>
                  {isMobile && isBreadcrumbsNotEmpty && (
                    <MobileBackButton
                      aria-label={messages.back}
                      onClick={e => {
                        e.stopPropagation();
                        popBreadcrumb(last(breadcrumbs));
                      }}
                    >
                      <LeftChevron size={16} />
                    </MobileBackButton>
                  )}
                  <SlicerTitle className='title'>
                    {isTimestampSlicer && isMobile && isBreadcrumbsNotEmpty
                      ? joinBreadcrumbs(name, breadcrumbs)
                      : name}
                  </SlicerTitle>
                  <NoFlex>
                    {!isOpen && (
                      <ItemCountSlug
                        options={options}
                        timestampStatusString={timestampStatusString}
                      />
                    )}
                  </NoFlex>
                </SlicerDropdownToggle>
              }
            >
              {!isTimestampSlicer && (
                <SearchInput
                  key={'searchbox'}
                  onInput={e =>
                    updateSearchInput(
                      (e?.target as HTMLInputElement)?.value ?? '',
                    )
                  }
                  defaultValue={searchInput}
                  menuItemSx={searchMenuItemSx}
                />
              )}
              {hasNoOptions ? (
                <SelectItem sx={{ fontStyle: 'italic' }}>
                  {messages.searchDropdown.empty}
                </SelectItem>
              ) : (
                [
                  isOpen && !isTimestampSlicer && (
                    <SelectAllItem key={'select-all'}>
                      <SlicerSelectionColumn
                        option={selectAllLabel}
                        selected={isStatusValid(selectStatus)}
                        labelRef={null}
                        onSelect={handleSelectAll}
                        width={'100%'}
                        name={messages.selectAll}
                        indeterminate={isIndeterminateStatus(selectStatus)}
                      />
                    </SelectAllItem>
                  ),
                  isTimestampSlicer ? (
                    <TimestampSlicerOptions
                      slicerErrorVisible={shouldShowSlicerError}
                      options={options}
                      name={name}
                      selectTimeFrame={selectTimeFrame}
                      pushBreadcrumb={pushBreadcrumb}
                      breadcrumbs={breadcrumbs}
                      clear={clear}
                    />
                  ) : (
                    <SlicerOptions
                      slicerErrorVisible={shouldShowSlicerError}
                      options={options}
                      name={name}
                      toggleOption={toggleOption}
                      selectOnly={selectOnly}
                    />
                  ),
                  <SlicerFooterMenuItem key={'button-footer'}>
                    <SlicerButtonFooter
                      name={name}
                      onCancel={() => handleToggleDropdown(false)}
                      onSave={() => {
                        handleToggleDropdown(false);
                        save();
                      }}
                      saveDisabled={!hasChanged || noneSelected}
                    />
                  </SlicerFooterMenuItem>,
                ]
              )}
            </SelectDropdown>
          </span>
        </SlicerTooltip>
      </SlicerWidgetContainer>
    </SlicerThemeProvider>
  );
});
