import { Component } from 'react';
import PropTypes from 'prop-types';

class TrendArrow extends Component {
  getImage() {
    switch (this.props.direction) {
      case 'N':
        return '/assets/images/sdd/trend_up.svg';
      case 'S':
        return '/assets/images/sdd/trend_down.svg';
      default:
        return '';
    }
  }
  render() {
    const trendValueCssClass = `trend-label${
      this.props.direction === 'N' ? ' trend-positive' : ' trend-negative'
    }`;
    return (
      <div className='trend'>
        <img src={this.getImage()} />
        <span className={trendValueCssClass}>{this.props.value}</span>
      </div>
    );
  }
}
TrendArrow.propTypes = {
  direction: PropTypes.string,
  value: PropTypes.number,
};

export default TrendArrow;
