import { createFilterForField, StringFilterSubTypes } from '../Filter';
import { FilterOperators } from '../FilterOperators';
import { isString } from 'lodash';
import { IAnyAttribute } from '../../../datasets';

export default (field: IAnyAttribute, operands) => {
  const filter = createFilterForField(field);
  const { IN_LIST } = FilterOperators.forFilterType(
    filter.type,
    StringFilterSubTypes.SELECT_ITEMS,
  );
  filter.expression.left.operator = IN_LIST?.key;
  filter.expression.left.operands = isString(operands) ? [operands] : operands;
  filter.subType = StringFilterSubTypes.SELECT_ITEMS;
  return filter;
};
