import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useDiscoverTheme } from '../../common/emotion/theme';
import { UserInputDialog } from '../../components/user-input-dialog';

export const StyledUserInputDialog = styled(UserInputDialog)`
  .edit-discovery-dialog.user-input-dialog {
    width: 300px;
  }
  .edit-discovery-dialog textarea.form-control {
    height: 88px;
    resize: vertical;
    min-height: 88px;
    max-height: 510px;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

export const FormLabel = styled.label(() => {
  const {
    colors: { Gray70, RegularFontWeight },
  } = useDiscoverTheme();

  return css`
    color: ${Gray70};
    font-weight: ${RegularFontWeight};
    font-size: 12px;
    margin: 0;
  `;
});
