import _ from 'lodash';
import { FilterOperators } from '../../../../discovery/filter/FilterOperators';
import {
  FilterTypes,
  TimestampFilterSubTypes,
} from '../../../../discovery/filter/Filter';
import DateFilter from '../../../../discovery/filter/exports/DateFilter';
import EqualsFilter from '../../../../discovery/filter/exports/EqualsFilter';
import { TimePeriods } from '../../../../discovery/filter/relative-date-condition';
import { ChartSpecs } from '../../../../discovery/ChartSpecs';
import { getInsightContextFilter, TimePeriodMapping } from '../../Common';
import { Viz as VizUtils } from '../../../../discovery/VizUtil';
import { IVizOptions } from '../../../../discovery/interfaces';

const RelativeDateOperators = FilterOperators.forFilterType(
  FilterTypes.DATE,
  TimestampFilterSubTypes.RELATIVE_DATES,
);

export default ({ monitorEvent: { eventData }, dataset, viz }) => {
  const axis = [];
  const measures = [];
  let filters = JSON.parse(_.get(viz, 'options.filters', '{}'));
  const options: Partial<IVizOptions> = {
    configPanelDetail: null,
  };
  filters = {
    ...filters,
    ...getInsightContextFilter(eventData, dataset),
  };

  let sortBy;
  _.forEach(dataset.attributes, a => {
    switch (a.name) {
      case eventData.measure:
        measures.push(a);
        // add sorting spec
        sortBy = {
          direction: 'desc',
          field: a,
        };
        break;
      case eventData.segment:
        axis.push(a);
        break;
      case eventData.partitionName:
        filters[a.name] = EqualsFilter(a, eventData.partitionValue);
        break;
      case eventData.dateAttribute: {
        const periodType = eventData.periodType.toLowerCase();
        const period = TimePeriods[TimePeriodMapping[periodType]];
        // set date filter for past month or past N months
        if (eventData.periodMonths > -1) {
          // remove any other date filters
          _.forEach(filters, (f, name) => {
            if (f.type === FilterTypes.DATE) {
              delete filters[name];
            }
          });
          const dateFilterArgs = [
            `${eventData.periodMonths}`,
            period.key,
            'false',
          ];
          if (eventData.lastDate) {
            dateFilterArgs.push(eventData.lastDate);
          }
          filters[a.name] = DateFilter(
            a,
            dateFilterArgs,
            RelativeDateOperators.past,
          );
        }
        break;
      }
    }
  });

  if (_.has(viz.options, 'slicerSelections')) {
    options.slicerSelections = _.get(viz, 'options.slicerSelections', '');
  }

  return {
    dataset: { ...dataset },
    chartSpec: ChartSpecs.stack_bar,
    measures,
    axis,
    filters,
    slicers: VizUtils.getSlicers(viz),
    sortBy,
    options,
  };
};
