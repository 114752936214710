import { Children, cloneElement } from 'react';
import { compose, withProps } from 'react-recompose';
import { CommonComponentHoc } from './Components';
import _ from 'lodash';
import {
  ActivityFeedItem,
  ActivityFeedItemBody,
  ActivityFeedItemHeader,
  ActivityWidget,
  PercentChangeCard,
} from './activity';
import { WeightedForecastInsightComponent } from './WeightedForecastComponent';
import ServiceLocator from '../../common/ServiceLocator';
import { ComponentTypes } from '../../common/Constants';
import { messages } from '../../i18n';

const UnconnectedDaysInStageComponent = ({
  feedItem,
  monitorEvent,
  referenceId,
  payload,
  last,
  visualizations,
  up,
  period,
  date,
  attrValue,
  attrName,
  pct,
  name,
  barData,
  condensed,
  className,
  history,
  onHeightChanged,
  children,
}) => {
  const linkedContent = visualizations
    ? visualizations.find(
        viz =>
          viz.name === name && viz.creator === 'discover-admin@sugarcrm.com',
      )
    : null;
  function openConnectedContent() {
    history.push(`/event/${monitorEvent.id}`);
  }

  const { stage } = payload;

  let segment = '';

  if (payload.attribute !== 'ALL') {
    segment = messages.formatString(
      messages.daysInStageComponent.segment,
      payload.attributeName || payload.attribute,
      payload.attributeValue,
    );
  }

  // Get the specific children that we know we need
  const kids = Children.toArray(children).reduce((acc, child) => {
    acc[child.type.role] = cloneElement(child, {
      onHeightChanged,
    });
    return acc;
  }, {});

  // Support overriding of the default header by tenanted modules
  const renderHeader = () => {
    if (!_.isNil(kids) && !_.isNil(kids.ActivityFeedItemHeader)) {
      return kids.ActivityFeedItemHeader;
    } else {
      return (
        <ActivityFeedItemHeader
          {...{ indicatorIcon: up ? 'up' : 'down', feedItem }}
        >
          {messages.formatString(messages.daysInStageComponent.header, {
            name: linkedContent ? (
              <a onClick={() => openConnectedContent()}>{name}</a>
            ) : (
              name
            ),
            stage: _.capitalize(stage),
            segment,
            increasedOrDecreased: up
              ? messages.daysInStageComponent.increased
              : messages.daysInStageComponent.decreased,
            pct,
          })}
        </ActivityFeedItemHeader>
      );
    }
  };

  // Support overriding of the default body by tenanted modules
  const renderBody = () => {
    if (!_.isNil(kids) && !_.isNil(kids.ActivityFeedItemBody)) {
      return kids.ActivityFeedItemBody;
    } else {
      return (
        <ActivityFeedItemBody monitorEvent={monitorEvent} feedItem={feedItem}>
          {messages.formatString(messages.daysInStageComponent.body, {
            name: <strong>{monitorEvent.monitor.name}</strong>,
            stage,
            segment,
            increasedOrDecreased: up
              ? messages.daysInStageComponent.increased
              : messages.daysInStageComponent.decreased,
            pct,
            currentValue: _.round(payload.currentValue, 1),
            previousValue: _.round(payload.previousValue, 1),
          })}
        </ActivityFeedItemBody>
      );
    }
  };

  // Support overriding of the default widget by tenanted modules
  const renderWidget = () => {
    if (!_.isNil(kids) && !_.isNil(kids.ActivityWidget)) {
      return kids.ActivityWidget;
    } else {
      let title = messages.formatString(
        messages.daysInStageComponent.title,
        stage,
      );
      if (condensed && attrName) {
        title += ` (${attrName}=${attrValue})`;
      }
      return (
        <ActivityWidget>
          <PercentChangeCard
            {...{
              up,
              attrValue,
              attrName,
              period,
              pct,
              date,
              payload,
              barData,
              condensed,
            }}
            title={title}
          />
        </ActivityWidget>
      );
    }
  };

  return (
    <div key={monitorEvent.id}>
      <ActivityFeedItem
        {...{
          referenceId,
          monitorEvent,
          feedItem,
          onHeightChanged,
          last,
          condensed,
          className,
        }}
        inverted
      >
        {renderHeader()}
        {renderBody()}
        {renderWidget()}
      </ActivityFeedItem>
    </div>
  );
};

const daysInStageSuportsFunc = feedItem => {
  return (
    feedItem.referenceType === 'MonitorEvent' &&
    ['daysinstage'].includes(feedItem.monitorEvent.eventType)
  );
};

// Two derived components are registered, one for activity feed and the other for insight card view
const DaysInStageComponent = compose(
  withProps(props => {
    return {
      monitorEvent: props.feedItem.monitorEvent,
      referenceId: props.feedItem.referenceId,
      negativeIsPositive: true,
    };
  }),
  CommonComponentHoc,
)(UnconnectedDaysInStageComponent);

DaysInStageComponent.supports = daysInStageSuportsFunc;

const DaysInStageInsightComponent = props => {
  // use the weighted forecast insight component, just flip it
  const insightComponents = ServiceLocator.getAll('COMPONENT', {
    type: ComponentTypes.INSIGHTS,
  }).filter(c => _.isFunction(c.supports));
  let InsightComp = insightComponents.find(comp =>
    comp.supports({
      referenceType: 'MonitorEvent',
      monitorEvent: { eventType: 'weightedforecast' },
    }),
  );
  if (_.isNil(InsightComp)) {
    InsightComp = WeightedForecastInsightComponent;
  }

  return <InsightComp {...props} inverted />;
};
DaysInStageInsightComponent.supports = daysInStageSuportsFunc;

export {
  DaysInStageComponent,
  DaysInStageInsightComponent,
  daysInStageSuportsFunc,
};
