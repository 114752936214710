import { css } from '@emotion/react';
import { font } from '../../common/emotion/mixins';

export const slackNotificationConfigStyles = ({
  theme: {
    colors: { MediumFontWeight },
  },
}) => css`
  .slack .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .slack a &:disabled {
    opacity: 0.5;
  }
  .channel {
    ${font({ weight: MediumFontWeight })}
  }
`;
