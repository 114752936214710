import gql from 'graphql-tag';
import { visualizationFragment, visualizationFragmentName } from './Fragments';

const Graphql = {
  VizQuery: gql`
    query VizQuery(
      $id: String!
      $measures: [QueryMeasureInput!]!
      $attributeNames: [String!]!
      $filters: [QueryFilterInput!]!
      $calcs: [QueryCalculationInput!]
      $subtotals: [QuerySubtotalInput!]
      $limit: Int
      $offset: Int
      $useFiscalCalendar: Boolean = false
      $calcDynamicValues: [QueryDynamicValueInput!] = []
      $bypassCache: Boolean = false
    ) {
      executeQuery(
        query: {
          datasetId: $id
          attributeNames: $attributeNames
          measures: $measures
          filters: $filters
          calcs: $calcs
          sorts: []
          subtotals: $subtotals
          limit: $limit
          offset: $offset
          useFiscalCalendar: $useFiscalCalendar
          calcDynamicValues: $calcDynamicValues
          refresh: $bypassCache
        }
      ) {
        columnNames
        columnInfo {
          columnName
          attributeName
          columnType
          parentName
          aggregationName
        }
        results
      }
    }
  `,
  AnalyzeQuery: gql`
    query AnalyzeQuery(
      $id: String!
      $measures: [QueryMeasureInput!]!
      $attributeNames: [String!]!
      $filters: [QueryFilterInput!]!
      $calcs: [QueryCalculationInput!]
      $subtotals: [QuerySubtotalInput!]
      $limit: Int
      $offset: Int
      $useFiscalCalendar: Boolean = false
    ) {
      analyze(
        query: {
          datasetId: $id
          attributeNames: $attributeNames
          measures: $measures
          filters: $filters
          calcs: $calcs
          sorts: []
          subtotals: $subtotals
          limit: $limit
          offset: $offset
          useFiscalCalendar: $useFiscalCalendar
        }
      ) {
        type
        message
        attributeNames
      }
    }
  `,
  InsightSubscription: gql`
    subscription events($subscriptionId: String!) {
      InsightEvent(subscriptionId: $subscriptionId) {
        subscriptionId
        ... on SubscriptionQueryRegistered {
          apolloId
          id
        }
        ... on InsightGenerationFinished {
          id
        }
        ... on InsightCreated {
          id
          insight {
            insightType
            payload
            id
            score
            scoreDetail
            version
            excluded
          }
        }
        ... on InsightGenerationError {
          id
          message
        }
        ... on InsightGenerationFinished {
          id
        }
        ... on MonitorEventReceived {
          id
          monitorEvent {
            id
            eventType
            payload
            startTimestamp
            endTimestamp
            score
            feedback {
              id
              anomalyId
              monitorId
              value
              creator
              createdOn
              applyTimestamp
            }
            monitor {
              id
              name
              config
            }
          }
        }
      }
    }
  `,
  GenerateInsights: gql`
    mutation(
      $subscriptionId: String!
      $sourceName: String!
      $sourceId: String!
      $monitorEventId: String
      $id: String!
      $measures: [QueryMeasureInput!]!
      $attributeNames: [String!]!
      $filters: [QueryFilterInput!]!
      $calcs: [QueryCalculationInput!]
      $lastDate: String
      $lastLevel: String
      $useFiscalCalendar: Boolean = false
    ) {
      generateInsights(
        subscriptionId: $subscriptionId
        sourceId: $sourceId
        sourceName: $sourceName
        monitorEventId: $monitorEventId
        lastDate: $lastDate
        lastLevel: $lastLevel
        query: {
          datasetId: $id
          attributeNames: $attributeNames
          measures: $measures
          filters: $filters
          calcs: $calcs
          sorts: []
          useFiscalCalendar: $useFiscalCalendar
        }
      )
    }
  `,
  ValidateVizQuery: gql`
    query validate(
      $id: String!
      $measures: [QueryMeasureInput!]!
      $attributeNames: [String!]!
      $filters: [QueryFilterInput!]!
      $calcs: [QueryCalculationInput!]
      $useFiscalCalendar: Boolean = false
    ) {
      validate(
        query: {
          datasetId: $id
          attributeNames: $attributeNames
          measures: $measures
          filters: $filters
          calcs: $calcs
          sorts: []
          useFiscalCalendar: $useFiscalCalendar
        }
      ) {
        name
        row
        column
        text
      }
    }
  `,

  GetVisualization: gql`
    query getVisualization($id: String!) {
      visualization(id: $id) {
        ...${visualizationFragmentName}
      }
    }
    ${visualizationFragment}
  `,

  GetVisualizationVersion: gql`
    query getVisualization($id: String!, $version: Int!) {
      visualization(id: $id, version: $version) {
        ...${visualizationFragmentName}
      }
    }
    ${visualizationFragment}
  `,

  PreprocessQuery: gql`
    query preprocessQuery(
      $id: String!
      $measures: [QueryMeasureInput!]!
      $attributeNames: [String!]!
      $filters: [QueryFilterInput!]!
      $calcs: [QueryCalculationInput!]
      $useFiscalCalendar: Boolean = false
    ) {
      preprocess(
        query: {
          datasetId: $id
          attributeNames: $attributeNames
          measures: $measures
          filters: $filters
          calcs: $calcs
          sorts: []
          useFiscalCalendar: $useFiscalCalendar
        }
      ) {
        validate {
          name
          row
          column
          text
          type
        }
        columns {
          name
          attributeType
          defaultAggregation
          aggregateMeasure
          formatType
          ordinalAttribute
        }
      }
    }
  `,
  NewDatasetPreprocessQuery: gql`
    query newDatasetPreprocess(
      $datasetAttributes: [AttributeInput!]!
      $measures: [QueryMeasureInput!]!
      $attributeNames: [String!]!
      $filters: [QueryFilterInput!]!
      $calcs: [QueryCalculationInput!]
      $useFiscalCalendar: Boolean = false
    ) {
      newDatasetPreprocess(
        attributes: $datasetAttributes
        query: {
          datasetId: "NONE"
          attributeNames: $attributeNames
          measures: $measures
          filters: $filters
          calcs: $calcs
          sorts: []
          useFiscalCalendar: $useFiscalCalendar
        }
      ) {
        validate {
          name
          row
          column
          text
          type
        }
        columns {
          name
          attributeType
          defaultAggregation
          aggregateMeasure
          formatType
          ordinalAttribute
        }
      }
    }
  `,
  ForecastVizQuery: gql`
    query forecastViz($forecastInput: ForecastInput!) {
      forecastViz(forecast: $forecastInput) {
        columnNames
        columnInfo {
          parentName
          aggregationName
        }
        results
        errors {
          name
          row
          column
          text
          type
        }
      }
    }
  `,
};

export default Graphql;
