import _ from 'lodash';
import moment from 'moment';
import { Hierarchy } from '../discovery/VizUtil';
import {
  DATA_TYPE_FORMAT,
  DATA_FORMATTER,
  CUSTOM_FORMAT_ANNOTATION,
} from '../common/Constants';
import DateFilter from '../discovery/filter/exports/DateFilter';
import { FilterOperators } from '../discovery/filter/FilterOperators';
import {
  FilterTypes,
  TimestampFilterSubTypes,
} from '../discovery/filter/Filter';
import { formatDefaultLocale } from 'd3';
import JSON5 from 'json5';

const timeAttributeToRelativeDatePeriod = {
  EXACT_DATE: 'DAYS',
  MINUTE: 'MINUTES',
  HOUR: 'HOURS',
  DAY: 'DAYS',
  DATE: 'DAYS',
  WEEK: 'CALENDAR_WEEKS',
  MONTH: 'CALENDAR_MONTHS',
  QTR: 'CALENDAR_QUARTERS',
  YEAR: 'CALENDAR_YEARS',
};

const filterTypes = FilterOperators.forFilterType(
  FilterTypes.DATE,
  TimestampFilterSubTypes.RELATIVE_DATES,
);

export const gatherMonitorSettings = monitorEvent => {
  const monitorConfig = JSON.parse(monitorEvent.monitor.config);
  const measureName =
    monitorConfig.measureName ||
    (monitorConfig.query.measures.length > 0 &&
      monitorConfig.query.measures[0].attributeName);
  return {
    valueFormatter: _.defaultTo(
      DATA_TYPE_FORMAT.getDefaultFormatterForType(monitorConfig.valueFormatter),
      DATA_TYPE_FORMAT.getDefaultFormatterForType('NUMBER'),
    ),
    labels: _.merge(
      {
        cardName: measureName,
        comparisonWindowLabel: 'Period',
      },
      monitorConfig.labels,
    ),
    comparisonWindowUnit: _.defaultTo(monitorConfig.window_unit, 'DAY'),
    comparisonWindow: _.defaultTo(monitorConfig.window_size, 7),
    measureName,
  };
};

export const getRelativeDateFilterForMonitorEvent = (monitorEvent, viz) => {
  let payload;
  let config;
  try {
    payload = JSON.parse(monitorEvent.payload);
    config = JSON.parse(monitorEvent.monitor.config);
  } catch (e) {
    console.error('Error parsing monitor event', e);
    throw new Error('Could not parse monitor event. See console');
  }
  // get the monitor event date, strangely as the 'index' property
  const datesInEvent = payload.chartData.map(row => row.value[0]);
  const endDate = moment(_.max(datesInEvent));

  // Report XAxis attribute. Must be a TIME_CALC
  const axisAttributes = viz.layout.XAXIS.filter(attr => {
    return attr.attributeType === 'TIME_CALC';
  });

  const axisAttribute = _.last(
    _.sortBy(axisAttributes, attr => {
      return Object.keys(Hierarchy.TIME_ATTRIBUTES).indexOf(
        attr.timeAttribute.key,
      );
    }),
  );

  if (!axisAttribute) {
    console.log(
      'Cannot find Time Calc in the XAxis of the referenced GADm Viz',
    );
    return {};
  }

  // resolve the date filter and attribute. position in filter list not known, search for first which is a date
  const dateFilter = config.query.filters.find(filter => {
    const attr = viz.dataset.attributes.find(
      a => a.name === filter.attributeName,
    );
    return (
      attr &&
      (attr.attributeType === 'TIMESTAMP' || attr.attributeType === 'DATE')
    );
  });

  if (!dateFilter) {
    console.log('Cannot find a Date Filter in the GADM config');
    return {};
  }

  // Given the XAxis attribute, find a matching Relative Date Unit
  const timeCalc = Hierarchy.TIME_ATTRIBUTES[axisAttribute.timeAttribute.key];
  const newPeriodForFilterBasedOnXAxisGranularity =
    timeAttributeToRelativeDatePeriod[timeCalc.key];
  if (!newPeriodForFilterBasedOnXAxisGranularity) {
    console.log('Granularity not supported by relative date filter');
    return {};
  }

  // Offset is 6 before anchor unless the granularity is at the day level
  const newRange = timeCalc.key === 'DATE' ? 14 : 6;

  // filter needs to be under the XAxis Calc base field
  const dateAttribute = axisAttribute.parentField;

  return {
    [dateAttribute.name]: DateFilter(
      dateAttribute,
      [
        /* num */ `${newRange}`,
        /* interval */ newPeriodForFilterBasedOnXAxisGranularity,
        /* include partial */ 'true',
        /* anchor */ endDate.format('YYYY-MM-DDTHH:mm:ssZ'),
      ],
      filterTypes.thisAndPast,
    ),
  };
};

export const getDatasetRelatedInfo = (dataset, measureName, dataFormatters) => {
  // resolve measure
  const measureAttribute = dataset?.attributes.find(
    a => a.name === measureName,
  );

  const currencySymbol = _.find(_.get(dataset, 'annotations', []), {
    key: 'DEFAULT_CURRENCY_SYMBOL',
  })?.value;
  if (!_.isUndefined(currencySymbol)) {
    formatDefaultLocale({
      decimal: '.',
      thousands: ',',
      grouping: [3],
      currency: [currencySymbol, ''],
    });
  }

  let datasetFormatter = DATA_FORMATTER.NUMBER;
  if (measureAttribute && !_.isEmpty(measureAttribute.formatType)) {
    datasetFormatter = DATA_TYPE_FORMAT.getFormatterByName(
      measureAttribute.formatType,
    );

    // if it is still empty, try to find it by key
    if (_.isNil(datasetFormatter)) {
      datasetFormatter =
        DATA_FORMATTER[measureAttribute.formatType.toUpperCase()];
      datasetFormatter = _.isNil(datasetFormatter)
        ? DATA_FORMATTER.NUMBER
        : datasetFormatter;
    }
  }

  // favor formatters passed as props, fall back to one defined in the dataset attribute
  const valueFormatter = measureName
    ? _.get(dataFormatters, measureName, datasetFormatter)
    : DATA_FORMATTER.NUMBER;

  let increasesAreGood = true;
  let customFormatter = {};
  if (!_.isNil(measureAttribute)) {
    const iag = measureAttribute.annotations.find(
      a => a.key === 'INCREASES_ARE_GOOD',
    );
    increasesAreGood = _.isNil(iag) ? true : !(iag.value === 'false');

    const cf = measureAttribute.annotations.find(
      c => c.key === CUSTOM_FORMAT_ANNOTATION,
    );
    customFormatter = _.isNil(cf) ? {} : JSON5.parse(cf.value);
  }
  const inverted = !increasesAreGood;
  return { valueFormatter, inverted, customFormatter };
};
