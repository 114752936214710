import styled from '@emotion/styled';
import { css } from '@emotion/react';
import ToggleButton from '@mui/material/ToggleButton';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import { useDiscoverTheme } from '../../../../../common/emotion/theme';

export const StyledToggleButton = styled(ToggleButton)(() => {
  const {
    darkMode,
    colors: {
      Gray50,
      Gray60,
      Gray70,
      ContentText,
      PivotWhite,
      MediumBorder,
      DefaultButtonFillColor,
      ContentBackground,
    },
  } = useDiscoverTheme();

  return css`
    width: 100%;
    padding: 0 6px 0 10px;
    height: 32px;
    font-weight: inherit;
    display: flex;
    justify-content: space-between;
    text-transform: initial;
    color: ${ContentText};
    border-radius: 2px;
    border-color: ${MediumBorder};
    background-color: ${DefaultButtonFillColor};

    &.Mui-selected {
      background-color: ${darkMode ? ContentBackground : PivotWhite};
      color: ${darkMode && Gray60};
    }

    .sicon {
      color: ${darkMode ? Gray50 : Gray70};
    }
  `;
});

export const StyledList = styled(List)<{ open: boolean }>(({ open }) => {
  const {
    colors: { ContentBackground, MediumBorder },
  } = useDiscoverTheme();

  return css`
    border-radius: 2px;
    width: 100%;
    display: ${open ? 'block' : 'none'};
    z-index: 1;
    position: absolute;
    border: 1px solid ${MediumBorder};
    background-color: ${ContentBackground};
    max-height: 30vh;
    overflow-y: scroll;
    font-size: 12px;
  `;
});

export const StyledListItemButton = styled(ListItemButton)(() => {
  const {
    colors: { DropdownOptionHoverBackground, LightBlue },
  } = useDiscoverTheme();

  return css`
    &:hover {
      background-color: ${DropdownOptionHoverBackground};
    }
    &.Mui-selected {
      background-color: ${LightBlue};
      pointer-events: none;
    }
  `;
});
