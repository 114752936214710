import _ from 'lodash';
import { SugarIconSize, SugarIconType } from '../common/sugar-icon.types';

export const getIconSize = ({
  icon,
  iconSize,
  size,
  sm,
  md,
  lg,
  xl,
}: {
  icon: SugarIconType;
  iconSize?: SugarIconSize;
  size?: number | string;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
}) => {
  if (!_.isNil(size)) {
    return size;
  }
  if (!iconSize) {
    if (_.compact([sm, md, lg, xl]).length > 1) {
      throw new Error(
        `'sm', 'md', 'lg' and 'xl' options are mutually exclusive, please use only one`,
      );
    }
    if (sm) {
      iconSize = 'sm';
    } else if (md) {
      iconSize = 'md';
    } else if (lg) {
      iconSize = 'lg';
    } else if (xl) {
      iconSize = 'xl';
    }
  }
  if (!iconSize) {
    const suffix = _.last(_.split(icon, '-'));
    switch (suffix) {
      case 'lg':
        iconSize = 'md';
        break;
      case 'xl':
        iconSize = 'lg';
        break;
      default:
        iconSize = 'sm';
        break;
    }
  }
  switch (iconSize) {
    case 'xl':
      return 32;
    case 'lg':
      return 24;
    case 'md':
      return 16;
    case 'sm':
      return 12;
    default:
      return 16;
  }
};
