import { toUpper } from 'lodash';
import { Types } from '../../common/Constants';
import { FieldTypeIcon } from '../../icons';

export const AttributeTypeIcon = props => {
  switch (toUpper(props.dataType)) {
    case Types.TIMESTAMP:
      return (
        <FieldTypeIcon
          fieldType={Types.TIMESTAMP}
          isCalculation={props.isCalc}
        />
      );
    case Types.NUMBER:
      return (
        <FieldTypeIcon fieldType={Types.NUMBER} isCalculation={props.isCalc} />
      );
    case Types.STRING:
      return (
        <FieldTypeIcon fieldType={Types.STRING} isCalculation={props.isCalc} />
      );
    case Types.BOOLEAN:
      return (
        <FieldTypeIcon fieldType={Types.BOOLEAN} isCalculation={props.isCalc} />
      );
    default:
      return (
        <FieldTypeIcon fieldType={Types.NUMBER} isCalculation={props.isCalc} />
      );
  }
};
