import { Component } from 'react';
import { DragSource } from 'react-dnd';
import { DnDTypes } from '../../common/Constants';
import { compose } from 'react-recompose';
import { getEmptyImage } from 'react-dnd-html5-backend';
import _ from 'lodash';
import SimpleFieldPill from '../SimpleFieldPill';
import { escapeFieldName } from './CalcUtils';

export const buildFieldText = (
  field,
  aggFunctionName,
  functionName,
  functionArg,
) => {
  const agg =
    aggFunctionName && aggFunctionName !== 'NONE' ? `${aggFunctionName}(` : '';
  const aggEnd = aggFunctionName && aggFunctionName !== 'NONE' ? ')' : '';

  const func = functionName ? `${functionName}(` : '';
  const funcEnd = functionName ? ')' : '';
  const funcArg = functionArg ? `"${functionArg}", ` : '';
  const fieldName = _.isNil(field.parentField)
    ? field.name
    : field.parentField.name;

  // if this is a time attribute, wrap the function in it's default aggregation function too
  return `${agg}${func}${funcArg}[${escapeFieldName(
    fieldName,
  )}]${funcEnd}${aggEnd}`;
};

export const CalcFieldDragPreview = props => {
  const { field, aggFunctionName, functionName, functionArg } = props;
  const agg =
    aggFunctionName && aggFunctionName !== 'NONE' ? `${aggFunctionName}(` : '';
  const aggEnd = aggFunctionName && aggFunctionName !== 'NONE' ? ')' : '';
  const func = functionName ? `${functionName}(` : '';
  const funcEnd = functionName ? ')' : '';
  const funcArg = functionArg ? `"${functionArg}", ` : '';
  const fieldName = _.isNil(field.parentField)
    ? field.name
    : field.parentField.name;
  return (
    <div className='drag-calc-field drag-preview'>
      {agg}
      {func}
      {funcArg}
      <span className='calc-field-syntax'>[{escapeFieldName(fieldName)}]</span>
      {funcEnd}
      {aggEnd}
    </div>
  );
};

class CalcField extends Component {
  componentDidMount() {
    // hide the default drag preview
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  render() {
    const { field, connectDragSource, isDragging, isScrolling } = this.props;
    let iconDataType = field.attributeType || field.columnType;
    if (!_.isEmpty(field.children)) {
      iconDataType = 'HIERARCHY';
    }
    return connectDragSource(
      <div className={isDragging ? 'calc-field dragging' : 'calc-field'}>
        <SimpleFieldPill
          field={field}
          isDragging={isDragging}
          iconDataType={iconDataType}
          isScrolling={isScrolling}
        />
      </div>,
      { dropEffect: 'move' },
    );
  }
}

const fieldSource = {
  beginDrag(props) {
    return {
      field: props.field,
      functionName: props.functionName,
      aggFunctionName: props.aggFunctionName,
      functionArg: props.functionArg,
    };
  },
};
const collect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

export default compose(DragSource(DnDTypes.CALC_FIELD, fieldSource, collect))(
  CalcField,
);
