import { css } from '@emotion/react';

export const bootstrapOverridesMobileStyles = ({
  theme: {
    colors: { ContentText },
  },
}) => css`
  .form-group-sm .form-control {
    /* Safari mobile will zoom in on inputs on focus with text smaller than 16px.*/
    font-size: 16px;
    height: 40px;
    border-radius: 2px;
    /* remove the top shadow on the input element*/
    -webkit-appearance: none;
  }

  input {
    color: ${ContentText};
  }
`;
