import { css } from '@emotion/react';
import { font } from '../../common/emotion/mixins';

export const mobileLibraryStyles = ({ mobileMaxWidth }) => css`
  .library {
    flex: 1;
  }
  .library-header {
    ${font({ size: '20px' })}
    padding: 16px;
    margin: 0px;
  }

  @media (max-width: ${mobileMaxWidth}) {
    .skeleton-list-loader {
      padding: 16px;

      & > div {
        height: 3rem;
      }
    }
  }
`;
