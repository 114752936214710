import _ from 'lodash';
import {
  FIELD_SEPARATOR,
  FIELD_SEPARATOR_SYMBOL,
  handleEmptyString,
  Types,
  FIELD_ORDINAL_SUFFIX,
} from '../../common/Constants';
import moment from '../../common/Moment';

const ChartUtils = {
  formatValue: (
    dataFormatters,
    dataCustomFormatters,
    i18nPrefs,
    fieldName,
    value,
    small = false,
  ) => {
    const formatter = dataFormatters[fieldName];
    const customFormatter = dataCustomFormatters[fieldName];
    let val = value;
    if (formatter) {
      val = small
        ? formatter.formatSmall(val, i18nPrefs, customFormatter)
        : formatter.format(val, i18nPrefs, customFormatter);
    }
    return val;
  },
  getX: (
    d,
    layout,
    valueName = 'VALUES',
    groupName = 'COLUMNS',
    barMeasureOffset = 0,
  ) => {
    if (_.isEmpty(d[groupName])) {
      // use the value columns as our COLUMNS
      if (_.isUndefined(layout) || d[valueName].length === 0) {
        return join(Object.keys(d[valueName]));
      } else {
        return join(
          Object.keys(d.VALUES).map(v => {
            const field = ChartUtils.getValueField(
              v,
              layout,
              valueName,
              barMeasureOffset,
            );
            return field.name;
          }),
        );
      }
    } else {
      let cols = join(Object.values(d[groupName]));
      if (
        !_.isUndefined(layout) &&
        !_.isUndefined(layout[valueName]) &&
        layout[valueName].length === 1 &&
        valueName !== groupName &&
        layout[groupName].length > 0
      ) {
        cols = join(
          Object.values(d[groupName]).filter(
            x => !_.isEqual(layout[valueName][0].name, x.value),
          ),
        );
      }
      return cols;
    }
  },
  getX0: d => {
    return join(Object.values(d.XAXIS));
  },
  getY: d => {
    return d.VALUES[Object.keys(d.VALUES)[0]];
  },
  findShelfByFieldName: (fieldName, layout) => {
    for (const [shelfId] of _.toPairs(layout)) {
      if (layout[shelfId]) {
        const found = layout[shelfId].find(f => f.name === fieldName);
        if (found) {
          return shelfId;
        }
      }
    }
  },
  getValueField: (fieldName, layout, valueShelfName = 'VALUES') => {
    return Object.values(layout[valueShelfName]).find(
      f => f.name === fieldName,
    );
  },
  valueLookup: (field, currentRow, columns, idx) => {
    if (isTimeType(field.attributeType)) {
      const ordinalIdx = _.findIndex(columns, {
        attributeName: createOrdinalName(field.name),
      });
      if (ordinalIdx > -1) {
        return currentRow[ordinalIdx];
      }
    }
    return currentRow[idx];
  },
  offsetMonthOrdinalForFiscalCalendar: (
    inYearPreFilters,
    fiscalCalendarInfo,
  ) => {
    const offset =
      _.parseInt(
        moment
          .parseZone(fiscalCalendarInfo.fiscalCalendarStartDate)
          .format('MM'),
      ) - 1;
    return _.map(inYearPreFilters, f => {
      if (f.attribute.timeAttribute?.key === 'MONTH') {
        let fiscalAdjustedMonth = _.parseInt(f.value);
        if (_.isNaN(fiscalAdjustedMonth)) {
          fiscalAdjustedMonth = 1;
        }

        f.value = `${((fiscalAdjustedMonth + offset - 1) % 12) + 1}`;
      }
      return f;
    });
  },
};
export default ChartUtils;

export const isTimeType = val => {
  return _.includes([Types.TIMESTAMP, Types.TIME_CALC], val);
};

export const createOrdinalName = commonName => {
  return `${commonName} ${FIELD_ORDINAL_SUFFIX}`;
};

export const getValuesFromColumnNamed = columnName => {
  return a => {
    return a[columnName];
  };
};

export const getTypesFromShelf = (
  types,
  /* BaseChartSpec */ layoutShelf /* layoutShelf: viz.layout.XAXIS */,
) => {
  const searchTypes = _.isArray(types) ? types : [types];
  return (layoutShelf ?? []).filter(a =>
    _.includes(searchTypes, a.attributeType),
  );
};

export const getDateTypesFromShelfAsOrdinals = (
  layoutShelf /* layoutShelf: viz.layout.XAXIS */,
) => {
  const searchTypes = [Types.TIME_CALC];
  return getTypesFromShelf(searchTypes, layoutShelf)
    .filter(a => requiresOrdinal(a))
    .map(getValuesFromColumnNamed('name'))
    .map(createOrdinalName);
};

export const getHardCodedOrdinalsFromShelfAsOrdinals = (
  layoutShelf /* layoutShelf: viz.layout.XAXIS */,
) => {
  return (layoutShelf ?? [])
    .filter(a => !!(a.ordinalAttribute && a.ordinalAttribute.length > 0))
    .map(a => a.ordinalAttribute);
};

export const getLegendSortDirection = querySort => {
  const values = _.values(querySort);
  const column = _.find(values, { shelfName: 'Columns' });
  return !column ? 'asc' : column.direction;
};

export const requiresOrdinal = attrib => {
  return (
    attrib.attributeType === Types.TIME_CALC &&
    (attrib.timeAttribute.key === 'MONTH' ||
      attrib.timeAttribute.key === 'DATE' ||
      attrib.timeAttribute.key === 'EXACT_DATE' ||
      attrib.timeAttribute.key === 'WEEK' ||
      attrib.timeAttribute.key === 'WEEK_IN_QTR')
  );
};

/**
 * Joins chart data based on field types
 */
export const join = data => {
  if (!_.isArray(data) || _.isEmpty(data)) {
    return '';
  } else if (data.length > 0 && !_.isObject(data[0])) {
    // nothing to compare
    return data.map(d => handleEmptyString(d)).join(FIELD_SEPARATOR);
  } else {
    const accum = [];
    let i = 0;
    while (i < data.length) {
      const value = handleEmptyString(data[i].value);
      accum.push(value);
      if (i !== data.length - 1) {
        // Add separator if adjacent field types are not time related
        if (
          !_.includes(data[i].type, 'TIME') ||
          !_.includes(data[i + 1].type, 'TIME')
        ) {
          accum.push(FIELD_SEPARATOR_SYMBOL);
        }
      }
      i++;
    }
    return accum.join(' ');
  }
};
