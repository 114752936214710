import { FallBackMessage } from '../default-error-fallback';
import { FullScreenFallBackStyled } from './fullscreen.styles';
import { MediumRed } from '../../../common/emotion/theme/colors.styles';

// used for root of React, so doesn't depend on any Providers
export const FullScreenFallBack = ({ isDashletMode = false }) => (
  <FullScreenFallBackStyled isDashletMode={isDashletMode}>
    <FallBackMessage iconColor={MediumRed} />
  </FullScreenFallBackStyled>
);
