import { useCallback } from 'react';
import {
  IRuntimeFilterPanelProps,
  IRuntimeFilters,
} from './runtime-filter-panel.interfaces';
import { useVizOptionSelector } from '../../../common/redux/selectors/viz-selector.hook';
import { messages } from '../../../i18n';
import { isEmpty, isObject, kebabCase, map } from 'lodash';
import {
  StackedFilter,
  StackedFilterAreaTitle,
  StackedFilterContainer,
  StackedFilterDelete,
} from '../active-filter-panel';
import { useDispatch } from 'react-redux';
import DiscoverActions from '../../../common/redux/actions/DiscoverActions';
import {
  RuntimeFilterSection,
  RuntimeFilterText,
  Title,
  FieldTypeIcon,
} from './runtime-filter-panel.styles';

export const RuntimeFilterPanel = ({
  discoveryId,
}: IRuntimeFilterPanelProps) => {
  const runtimeFilters = useVizOptionSelector<IRuntimeFilters>({
    discoveryId,
    option: 'runtimeFilters',
    defaultValue: {},
  });

  const dispatch = useDispatch();

  const handleRemoveRuntimeFilter = useCallback(
    fieldName =>
      dispatch(DiscoverActions.removeRuntimeFilter({ discoveryId, fieldName })),
    [dispatch, discoveryId],
  );

  return (
    <RuntimeFilterSection aria-label={messages.formatting.runtimeFilters}>
      <StackedFilterAreaTitle>
        {messages.formatting.runtimeFilters}
      </StackedFilterAreaTitle>
      <StackedFilterContainer>
        {!isObject(runtimeFilters) || isEmpty(runtimeFilters) ? (
          <div>
            <Title>{messages.formatting.noRuntimeFilters}</Title>
          </div>
        ) : (
          map(runtimeFilters, ({ name, attributeType }) => (
            <StackedFilter id={kebabCase(name)}>
              <FieldTypeIcon fieldType={attributeType} isCalculation={false} />
              <RuntimeFilterText>{name}</RuntimeFilterText>
              <StackedFilterDelete
                onClick={() => handleRemoveRuntimeFilter(name)}
                aria-controls={kebabCase(name)}
              />
            </StackedFilter>
          ))
        )}
      </StackedFilterContainer>
    </RuntimeFilterSection>
  );
};
