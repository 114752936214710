import { ThemeProvider } from '@emotion/react';
import { useCallback, memo } from 'react';
import {
  IDiscoverEmotionTheme,
  useDiscoverTheme,
} from '../../../../common/emotion/theme/discover-emotion-theme-provider.component';
import { DefaultFontName } from '../../../../components/ui/fonts';

export const MAX_SLICER_WIDTH = 300;
export const MIN_SLICER_WIDTH = 180;
export const SLICER_DROPDOWN_HEIGHT_MOBILE = 30;
export const SLICER_DROPDOWN_HEIGHT = 32;
export const SLICER_MAX_ITEMS = 10;
export const SCROLL_ROW_HEIGHT = 28;
export const SELECT_ALL_ITEM_HEIGHT = 36;
export const SCROLL_CONTAINER_MAX_HEIGHT =
  SCROLL_ROW_HEIGHT * SLICER_MAX_ITEMS - SELECT_ALL_ITEM_HEIGHT;
export const SLICER_MENU_MAX_HEIGHT = SCROLL_CONTAINER_MAX_HEIGHT + 100;
export const DASHLET_WIDTH_OFFSET = 36;
export const MOBILE_WIDTH_OFFSET = 20;

export const BORDER_RADIUS = '8px';

export interface ISlicerWidgetTheme extends IDiscoverEmotionTheme {
  width: number;
  listHeight: number;
  isOpen: boolean;
  slicerDropdownToggleHeight: number;
  slicerToggleFontFamily: string;
}

export interface ISlicerWidgetThemeProp {
  theme?: ISlicerWidgetTheme;
}

export const SlicerThemeProvider = memo<{
  width: number;
  listHeight?: number;
  isOpen: boolean;
  toggleHeight?: number;
  children;
}>(
  ({
    width,
    listHeight = SCROLL_CONTAINER_MAX_HEIGHT,
    children,
    isOpen,
    toggleHeight,
  }) => {
    const theme = useCallback(
      ancestorTheme => {
        const { isMobile } = ancestorTheme;
        const suggestedToggleHeight =
          isMobile && !isOpen
            ? SLICER_DROPDOWN_HEIGHT_MOBILE
            : SLICER_DROPDOWN_HEIGHT;
        const slicerToggleFontFamily = DefaultFontName;
        return {
          ...ancestorTheme,
          width,
          listHeight,
          isOpen,
          slicerDropdownToggleHeight: toggleHeight ?? suggestedToggleHeight,
          slicerToggleFontFamily,
        };
      },
      [isOpen, width, listHeight, toggleHeight],
    );
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
  },
);

export const useSlicerTheme = () => {
  const theme = useDiscoverTheme() as ISlicerWidgetTheme;
  return theme;
};
