import gql from 'graphql-tag';

const AccountQueries = {
  CurrentUserQuery: gql`
    query {
      currentUser {
        id
        email
        fullName
        profileImage
        roles
        tenant
        preferences {
          pinnedDiscoveries
        }
        timezone
        userName
        i18nPrefs {
          dateFormat
          timeFormat
          numGroupSeparator
          decimal
        }
        idm {
          role
          accountManagementUrl
          administrationUrl
        }
        locale
        appearance
        pendoAccountId
        pendoVisitorId
        isServiceAccountEnabled
      }
    }
  `,
  AccountQuery: gql`
    query {
      currentUser {
        id
        email
        emailNotifications
        givenName
        middleName
        surname
        fullName
        title
        phoneNumber
        profileImage
        slackNotifications
        smsNotifications
        roles
        tenant
        i18nPrefs {
          dateFormat
          timeFormat
          numGroupSeparator
          decimal
        }
        preferences {
          pinnedDiscoveries
        }
        timezone
        idm {
          role
          srn
          accountManagementUrl
          administrationUrl
        }
      }
    }
  `,
  UpdateAccountMutation: gql`
    mutation updateUser($user: UpdateProfileRequest!) {
      updateUser(user: $user) {
        id
        email
        emailNotifications
        givenName
        middleName
        surname
        fullName
        title
        phoneNumber
        profileImage
        slackNotifications
        smsNotifications
        roles
        tenant
        i18nPrefs {
          dateFormat
          timeFormat
          numGroupSeparator
          decimal
        }
        idm {
          role
          srn
          accountManagementUrl
          administrationUrl
        }
      }
    }
  `,
  UpdateUserPreferences: gql`
    mutation($userPreferences: UpdateUserPreferencesRequest!) {
      updateUserPreferences(userPreferences: $userPreferences) {
        pinnedDiscoveries
      }
    }
  `,
  LogoutOtherSessions: gql`
    mutation {
      logoutOtherSessions
    }
  `,
};
export default AccountQueries;
