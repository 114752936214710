import { FunctionComponent, ReactNode } from 'react';
import styled from '@emotion/styled';
import { IProps as IVizLegendProps, VizLegend } from '../charts/viz-legend';
import { css } from '@emotion/react';
import { RuntimeFilterPanel as RuntimeFilterPanelUnstyled } from '../../discovery/filter/runtime-filter-panel';
import { merge } from 'lodash';
import { useDiscoverTheme } from '../../common/emotion';
import { StackedFilterContainer } from '../filter/active-filter-panel';

export const PanelResizeHandle = styled.div`
  position: absolute;
  top: 0px;
  left: -8px;
  width: 16px;
  height: 100%;
  cursor: col-resize;
` as FunctionComponent<{ ref?: any }>;

export const VizPanelContainer = styled.div<{ legendPanelWidth: number }>`
  border-left: 1px solid ${props => props.theme?.colors?.PanelBorder};
  width: ${props => props?.legendPanelWidth ?? 0}px;
`;

// same max-height as .viz-detail
export const VizPanelContent = styled(StackedFilterContainer)((props: any) => {
  let gridTemplate = '';

  if (props.numSections == 2) {
    gridTemplate = `
    grid-template-rows: [panel-top] 1fr [panel-middle] 1fr [filter-bottom] 0 [panel-bottom];
    `;
  } else if (props.numSections == 3) {
    gridTemplate = `
    grid-template-rows: [panel-top] 1fr [panel-middle] 0.5fr [filter-bottom] 0.5fr [panel-bottom];
    `;
  }
  return css`
    border-top: 1px solid ${props.theme?.colors?.PanelBorder};
    border-left: 1px solid ${props.theme?.colors?.PanelBorder};
    background-color: ${props.theme?.colors.ContentBackground};
    position: relative;
    width: 100%;
    height: 100%;
    gap: 0.5rem;
    ${gridTemplate}
  `;
}) as FunctionComponent<{
  ref?: any;
  legendPanelWidth?: number;
  numSections: number;
  children?: ReactNode;
  as?: string;
}>;

export const VizFilterPanel = styled.aside`
  overflow-y: auto;
  overflow-x: hidden;
  grid-row: ${(props: { position: number }) => props.position ?? 1};
  grid-column: 1;
`;

export const VizLegendInPanel = (
  props: IVizLegendProps & { hasBelowPanel?: boolean },
) => {
  const { colors: { PanelBorder } = {} as any } = useDiscoverTheme();

  const baseStyle: any = {
    gridRow: 1,
    gridColumn: 1,
  };

  if (props.hasBelowPanel) {
    baseStyle.borderBottom = `1px solid ${PanelBorder}`;
  }

  return (
    <VizLegend
      {...props}
      panelStyle={merge(baseStyle, props.panelStyle ?? {})}
    />
  );
};

export const RuntimeFilterPanel = styled(RuntimeFilterPanelUnstyled)`
  grid-row: ${(props: { position: number }) => props.position ?? 1};
`;
