import { connect } from 'react-redux';
import { compose, lifecycle } from 'react-recompose';
import Discover from '../../common/redux/actions/DiscoverActions';

const mapPlaceholderStateToProps = () => {
  return {};
};

const mapPlaceholderDispatchToProps = dispatch => {
  return {
    clearVizLegendData: id => {
      dispatch(Discover.setVizLegendData(id, []));
    },
  };
};

export const clearLegendWrapper = compose(
  (connect as any)(mapPlaceholderStateToProps, mapPlaceholderDispatchToProps),
  lifecycle({
    componentDidMount() {
      (this.props as any).clearVizLegendData((this.props as any).vizId);
    },
  }),
);
