import { css } from '@emotion/react';
import { font } from '../../common/emotion/mixins';

export const reportDetailStyles = ({
  theme: {
    colors: { LightFontWeight, Strokes, ContentBackground },
  },
}) => css`
  .report-detail input[type='text'] {
    ${font({ size: '12px', weight: LightFontWeight })}
    background-color: ${ContentBackground};
    border-color: ${Strokes};
    box-shadow: none;
  }
  .report-detail input[type='text']:disabled {
    font-size: 12px;
    cursor: default;
  }
  .report-detail .hover-blur {
    position: absolute;
    width: 16%;
    background-image: linear-gradient(
      to right,
      ClearWhite,
      ${ContentBackground} 50%
    );
    right: 1px;
    top: 3px;
    opacity: 0.8;
    border-radius: 0px 2px 2px 0px;
  }
`;
