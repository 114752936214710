import { messages } from '../i18n';
const VizChartError = ({ error }) => {
  console.error(error);
  return (
    <div className={'viz-chart-error'}>
      <div className={'viz-chart-error-message'}>
        {messages.somethingWentWrong}
      </div>
    </div>
  );
};

export default VizChartError;
