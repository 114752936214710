import { useState, useEffect } from 'react';

export const useWindowResizeListener = () => {
  const getWindowDimensions = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [windowSize, setWindowSize] = useState(getWindowDimensions());
  useEffect(() => {
    const resizeListener = () => {
      setWindowSize(getWindowDimensions());
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  return windowSize;
};
