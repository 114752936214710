import { Component, Children, cloneElement } from 'react';
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import shortid from 'shortid';

/**
 * This Table component just wraps the react-bootstrap-table (BootstrapTable) to allow for a common
 * location to add custom functionality.
 * Refer to https://github.com/AllenFang/react-bootstrap-table for documentation.
 */
class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isResizing: false,
      activeResizeColumn: null,
    };
    this.openHeaders = [];
    this.onMouseUp = () => {
      if (this.state.isResizing) {
        this.setResizing(false);
      }
    };
  }

  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);
    // get the resize indicator dom node, stash it for later
    this.indicator = el.querySelector('.resize-indicator');
    this.scrollContainer = el.querySelector('.corvana-bs-table');

    // set up a global mouseup listener to handle mouseup outside the bounds of the table itself
    window.addEventListener('mouseup', this.onMouseUp);

    // grab references to the actual dom elements we might need to resize for later
    this.headerCols = el.querySelectorAll(
      '.react-bs-container-header>table>colgroup>col',
    );
    this.cellCols = el.querySelectorAll(
      '.react-bs-container-body>table>colgroup>col',
    );
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onMouseUp);
  }

  onMouseMove(event) {
    // find out where the mouse is now
    const x = event.clientX - event.currentTarget.offsetLeft;
    // where was it before this event?
    this.startingX = this.resizeX;
    this.resizeX = x;

    if (this.state.isResizing) {
      // don't allow to resize beyond the minWidth defined on the column
      const minWidth = this.getMinColWidth();
      const maxWidth = this.getMaxColWidth();
      this.minX =
        this.activeResizeColumnNode.getBoundingClientRect().left + minWidth;
      this.maxX =
        this.activeResizeColumnNode.getBoundingClientRect().left + maxWidth;
      // this._logMouseMoveInfo(event)

      if ((this.minX && this.minX > x) || (this.maxX && this.maxX < x)) {
        // prevent resizing smaller than the min width
        // also prevent resizing too much where the adjacent column goes beyond it's min width
        return;
      }

      const newWidth = this.determineNewWidth();
      // move the resize indicator
      if (newWidth.left >= minWidth && newWidth.right <= maxWidth) {
        this.doResizeColumn(newWidth);
        this.indicator.style.left = `${
          x >= this.minX ? (x <= this.maxX ? x : this.maxX) : this.minX
        }px`;
      }
    }
  }

  getMinColWidth() {
    return parseInt(this.activeResizeColumn.props.minWidth);
  }
  getMaxColWidth() {
    const col = this.activeResizeColumnNode;
    const width = parseInt(this.headerCols[col.cellIndex].style.width);
    const adjacentWidth = parseInt(
      this.headerCols[col.cellIndex + 1].style.width,
    );
    const minAdjacent = this.adjacentResizeColumn
      ? parseInt(this.adjacentResizeColumn.props.minWidth)
      : 0;
    return width + adjacentWidth - minAdjacent;
  }

  _logMouseMoveInfo(event) {
    console.log(`event.clientX = ${event.clientX}`);
    console.log(
      `event.currentTarget.offsetLeft = ${event.currentTarget.offsetLeft}`,
    );
    console.log(
      `event.currentTarget.scrollLeft = ${event.currentTarget.scrollLeft}`,
    );
    console.log(
      `event.currentTarget.scrollWidth = ${event.currentTarget.scrollWidth}`,
    );
    console.log(`this.minX = ${this.minX}`);
    console.log(`this.startingScrollX = ${this.startingScrollX}`);
    console.log(
      `this.scrollContainer.scrollLeft = ${this.scrollContainer.scrollLeft}`,
    );
  }

  determineNewWidth() {
    if (this.activeResizeColumnNode) {
      // determine how wide to make the column now
      const col = this.activeResizeColumnNode;
      const rect = col.getBoundingClientRect();
      const howMuchTheMouseMoved = this.startingX - this.resizeX;
      const howFarHorizontallyAreWeScrolled =
        this.startingScrollX - this.scrollContainer.scrollLeft;
      const left =
        rect.width - howMuchTheMouseMoved - howFarHorizontallyAreWeScrolled;
      let right = 0;
      if (this.adjacentResizeColumn) {
        const adjacentWidth =
          col.cellIndex < this.headerCols.length
            ? parseInt(this.headerCols[col.cellIndex + 1].style.width)
            : 0;
        right = adjacentWidth + rect.width - left;
      }
      // update the previous scroll location to enable proper calculation the next time
      this.startingScrollX = this.scrollContainer.scrollLeft;
      return { left, right };
    }
    return 0;
  }
  doResizeColumn(newWidth) {
    if (this.activeResizeColumnNode) {
      const col = this.activeResizeColumnNode;
      this.headerCols[col.cellIndex].style.width = `${newWidth.left}px`;
      this.cellCols[col.cellIndex].style.width = `${newWidth.left}px`;
      if (newWidth.right) {
        this.headerCols[col.cellIndex + 1].style.width = `${newWidth.right}px`;
        this.cellCols[col.cellIndex + 1].style.width = `${newWidth.right}px`;
      }
    }
  }
  setResizing(isResizing, column) {
    this.setState({ isResizing });
    if (column) {
      // get the other column that will be affected by the resize (the one to the right)
      let resizeColumnIndex = 0;
      this.props.children.find((element, index) => {
        if (element.props.dataField === column.props.dataField) {
          resizeColumnIndex = index;
        }
      });

      this.activeResizeColumn = column;
      this.adjacentResizeColumn =
        resizeColumnIndex < this.props.children.length
          ? this.props.children[resizeColumnIndex + 1]
          : null;
      this.activeResizeColumnNode = ReactDOM.findDOMNode(
        this.activeResizeColumn,
      );
      if (this.activeResizeColumnNode) {
        const col = this.activeResizeColumnNode;
        const rect = col.getBoundingClientRect();
        console.log(`rect.right = ${rect.right}`);
        console.log(`this.resizeX = ${this.resizeX}`);

        this.indicator.style.left = `${this.resizeX}px`;
        const minWidth = this.getMinColWidth();
        // set the leftmost possible x position of the column based on the min width
        this.minX = col.getBoundingClientRect().left + minWidth;

        // set the rightmost possible x position of the column based on the adjacent column's min-width
        if (this.adjacentResizeColumn) {
          const maxWidth = this.getMaxColWidth();
          this.maxX = rect.left + maxWidth;
        }
      }
    }
    this.startingScrollX = this.scrollContainer.scrollLeft;
  }

  handleColumnSort(direction, dataField) {
    this.bootstrapTable.handleSort(direction, dataField);
  }

  /**
   * To allow the header dropdown menus to be visible, we need the containing div to be 'overflow: visible'
   * However, this causes the horizontal scrolling of the table body & header to get out of sync.
   * So, to work around this problem we need to set the overflow to visible only when the dropdown is open and back to
   * hidden when it is closed
   * @param isOpen
   */
  handleHeaderDropdownToggle(isOpen) {
    if (isOpen) {
      this.openHeaders.push(1);
    } else {
      this.openHeaders.pop();
    }
    const el = ReactDOM.findDOMNode(this);
    const header = el.querySelector('.react-bs-container-header');
    const body = el.querySelector('.react-bs-container-body');
    // only flip it back off if there are no open headers
    if (
      header &&
      body &&
      (isOpen || (!isOpen && this.openHeaders.length === 0))
    ) {
      header.scrollLeft = body.scrollLeft;
      header.style.overflow = isOpen ? 'visible' : 'hidden';
      // can't set scroll position of an overflow visible element, force it with absolute positioning
      header.style.position = isOpen ? 'absolute' : 'initial';
      header.style.left = isOpen ? `${0 - body.scrollLeft}px` : '0px';
      body.style.position = isOpen ? 'absolute' : 'initial';
      body.style.top = isOpen ? `${header.clientHeight}px` : 0;
    }
  }

  pulseRow(id) {
    const el = ReactDOM.findDOMNode(this);
    const storeIdx = this.bootstrapTable.store.data.findIndex(r => r.id === id);
    if (storeIdx > -1) {
      const trIdx = storeIdx + 1;
      const tr = el.querySelector(
        `.corvana-bs-table-row:nth-of-type(${trIdx})`,
      );
      if (tr) {
        tr.scrollIntoView();
        tr.classList.add('pulse');
        // remove it after so :hover state will be able to change the background.
        setTimeout(() => {
          tr.classList.remove('pulse');
        }, 1500);
      }
    }
  }

  // eslint-disable-next-line lodash/prefer-constant
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const id = this.props.id ? this.props.id : shortid.generate();
    const childrenWithProps = Children.map(this.props.children, child => {
      return cloneElement(child, {
        setResizing: (value, column, event) =>
          this.setResizing(value, column, event),
        handleSort: (direction, dataField) =>
          this.handleColumnSort(direction, dataField),
        handleHeaderDropdownToggle: isOpen =>
          this.handleHeaderDropdownToggle(isOpen),
        tableId: id,
      });
    });

    const resizeIndicatorClasses = this.state.isResizing
      ? 'resize-indicator'
      : 'resize-indicator hidden';
    const wrapperCss = this.state.isResizing
      ? 'resizable-container disable-text-select resize-cursor'
      : 'resizable-container';
    const frozenHeadersClass = this.props.frozenHeaders
      ? ' frozen-headers'
      : '';
    const tableCss =
      (this.props.className
        ? `corvana-bs-table ${this.props.className}`
        : 'corvana-bs-table') + frozenHeadersClass;
    const options = {
      noDataText: 'No matches',
      ...this.props.options,
    };
    return (
      <div
        id={id}
        className={wrapperCss}
        onMouseMove={event => this.onMouseMove(event)}
        onMouseUp={event => this.onMouseUp(event)}
      >
        <BootstrapTable
          ref={table => {
            this.bootstrapTable = table;
          }}
          {...this.props}
          options={options}
          className={tableCss}
          trClassName={
            this.props.trClassName
              ? `corvana-bs-table-row ${this.props.trClassName}`
              : 'corvana-bs-table-row'
          }
        >
          {childrenWithProps}
        </BootstrapTable>
        <div className={resizeIndicatorClasses} />
      </div>
    );
  }
}
Table.propTypes = {
  ...BootstrapTable.propTypes,
  id: PropTypes.string,
  frozenHeaders: PropTypes.bool,
  height: PropTypes.number,
  frozenHeadersRequireHeight: props => {
    if (props.frozenHeaders && props.height === undefined) {
      throw new Error('frozenHeaders require a height property to be set');
    }
  },
};

export default Table;
