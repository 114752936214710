import _ from 'lodash';
import { SugarSecondaryPalette } from '../../../common/emotion/theme/colors.styles';

export enum Color {
  Red = 'Red',
  Pacific = 'Pacific',
  Purple = 'Purple',
  Green = 'Green',
  Coral = 'Coral',
  Orange = 'Orange',
  Teal = 'Teal',
  Ocean = 'Ocean',
  Yellow = 'Yellow',
  Army = 'Army',
  Indigo = 'Indigo', // removed from default colors
  Pink = 'Pink',
}

export enum Brightness {
  Light = 'Light',
  Medium = 'Medium',
  Dark = 'Dark',
}

const defaultColorOrder = [
  Color.Ocean,
  Color.Pacific,
  Color.Teal,
  Color.Green,
  Color.Army,
  Color.Yellow,
  Color.Orange,
  Color.Red,
  Color.Coral,
  Color.Pink,
  Color.Purple,
];

export const Colors = {
  ...SugarSecondaryPalette,
  LightGray1: '#ffffff',
  LightGray2: '#cccccc',
  LightGray3: '#999999',
  DarkGray1: '#666666',
  DarkGray2: '#333833',
  DarkGray3: '#333333',
};

export const getPalette = (brightness: Brightness) =>
  _.map(defaultColorOrder, color => Colors[`${brightness}${color}`]);

export const LightPalette = getPalette(Brightness.Light);
export const MediumPalette = getPalette(Brightness.Medium);
export const DarkPalette = getPalette(Brightness.Dark);

export const LightGrays = [
  Colors.LightGray1,
  Colors.LightGray2,
  Colors.LightGray3,
];

export const DarkGrays = [Colors.DarkGray1, Colors.DarkGray2, Colors.DarkGray3];

export const DefaultColorRows = [
  [Colors.DarkGray1, Colors.LightGray1, ...LightPalette],
  [Colors.DarkGray2, Colors.LightGray2, ...MediumPalette],
  [Colors.DarkGray3, Colors.LightGray3, ...DarkPalette],
];

export const DarkIndicatorColors = [...LightGrays, ...LightPalette];
export const LightIndicatorColors = [
  ...DarkGrays,
  ...MediumPalette,
  ...DarkPalette,
];

export const getIndicatorColor = color => {
  if (_.includes(DarkIndicatorColors, color)) {
    return {
      fill: 'black',
      stroke: 'white',
    };
  } else {
    return {
      fill: 'white',
      stroke: 'black',
    };
  }
};
