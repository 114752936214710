import { useRef, createContext } from 'react';
import { constant, isObject, noop } from 'lodash';

export const WorkerRefContext = createContext<{
  registerWorker: (args: { workerId: string; worker: Worker }) => void;
  getWorker: (workerId: string) => Worker | null;
  setWorkerMeta: (workerId: string, meta: any) => void;
  getWorkerMeta: (workerId: string) => any;
}>({
  registerWorker: constant(null),
  getWorker: constant(null),
  setWorkerMeta: noop,
  getWorkerMeta: constant({}),
});

export const WorkerRefContextProvider = ({ children }) => {
  const workerRefs = useRef<{
    [workerId: string]: {
      worker: Worker;
      meta: any;
    };
  }>({});

  const setWorkerRef = ({
    workerId,
    worker,
    meta = {},
  }: {
    workerId: string;
    worker: Worker;
    meta?: any;
  }) => {
    const { current: currentWorkerRef } = workerRefs;
    currentWorkerRef[workerId] = { worker, meta };

    // create a new object reference
    const newWorkerRef = {};
    Object.assign(newWorkerRef, currentWorkerRef);
    workerRefs.current = newWorkerRef;
  };

  const registerWorker = ({
    workerId,
    worker,
  }: {
    workerId: string;
    worker: Worker;
  }) => {
    if (isObject(worker)) {
      setWorkerRef({
        workerId,
        worker,
      });
    }
  };

  const getWorkerRef = (workerId: string) => {
    return workerRefs.current[workerId] ?? ({} as any);
  };

  const getWorker = (workerId: string) => {
    const { worker = null } = getWorkerRef(workerId);
    return worker;
  };

  const getWorkerMeta = (workerId: string) => {
    const { meta = {} } = getWorkerRef(workerId);
    return meta;
  };

  const setWorkerMeta = (workerId: string, meta) => {
    const worker = getWorker(workerId) as Worker;
    if (isObject(worker)) {
      setWorkerRef({
        workerId,
        worker,
        meta,
      });
    }
  };

  return (
    <WorkerRefContext.Provider
      value={{
        registerWorker,
        getWorker,
        setWorkerMeta,
        getWorkerMeta,
      }}
    >
      {children}
    </WorkerRefContext.Provider>
  );
};
