import MobileLibrary from './component';
import { connect } from 'react-redux';
import { compose, withProps } from 'react-recompose';
import { withRouter } from 'react-router-dom';
import Discover from '../../common/redux/actions/DiscoverActions';
import { sortBy } from 'lodash';
import MainActions from '../../common/redux/actions/MainActions';
import { TABLE_SELECTORS } from '../../common/redux/selectors/TableSelectors';
import { withSkeletonListLoadingPanel } from '../../common/hoc';

const mapStateToProps = (state, ownProps) => {
  return {
    advanced: state.main.advanced,
    search: TABLE_SELECTORS.getSearch(state, ownProps),
    tags: TABLE_SELECTORS.getSearchTags(state, ownProps),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    openDiscovery: discovery => {
      dispatch(Discover.openDiscovery(discovery));
    },
    openVizualization: discovery => {
      dispatch(Discover.openVizualization(discovery, ownProps.history));
    },
    setSearch: search => {
      dispatch(MainActions.setSearch(ownProps.domain, search));
    },
    setSearchTags: tags => {
      dispatch(MainActions.setSearchTags(ownProps.domain, tags));
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props => {
    const sortedList = sortBy(props.content, ['name']);
    return {
      sortedList,
      sortBy: 'name',
      sortDirection: 'ASC',
      domain: 'library',
    };
  }),
  withSkeletonListLoadingPanel(),
)(MobileLibrary);
