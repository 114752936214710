import { ComponentClass } from 'react';
import { compose, pure } from 'react-recompose';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import moment from '../../common/Moment';
import _ from 'lodash';
import { VizRedirect, IReportDetailInfo } from '../../discovery/viz-redirect';
import ChartUtils from '../../discovery/charts/ChartUtils';
import DiscoverQueries from '../graphql/DiscoverQueries';
import DiscoverActions from '../redux/actions/DiscoverActions';
import { CorbotoLoading } from '../loaders';
import InListFilter from '../../discovery/filter/exports/InListFilter';
import EqualsFilter from '../../discovery/filter/exports/EqualsFilter';
import NullFilter from '../../discovery/filter/exports/NullFilter';

import DateFilter from '../../discovery/filter/exports/DateFilter';
import {
  AllowedDashletDrillLinkDynamicFields,
  DynamicCalcValues,
  getFrontendUrl,
  NULL_DISPLAY,
  Types,
} from '../Constants';
import { Moment } from 'moment';
import {
  IFilter,
  IPreFilterOperand,
  IAppliedFilters,
  IFiscalCalendarInfo,
  ITimeCalcAttribute,
  IExpressionSegment,
  IExpression,
} from '../../datasets/interfaces';
import { IDiscovery } from '../../discovery';
import { Hierarchy } from '../../discovery/VizUtil';
import { InTermFilter } from '../../discovery/filter/exports/InTermFilter';
import {
  Condition,
  LogicalOperators,
  StringFilterSubTypes,
  TimestampFilterSubTypes,
} from '../../discovery/filter/Filter';
import {
  decodeExpressionTree,
  insertIntoFilterExpression,
} from '../../discovery/filter/filter.utils';
import {
  IN_LIST,
  timestampOperators,
} from '../../discovery/filter/FilterOperators';
import { withDiscoverRouter } from '../utilities/router.hoc';
import URLs from '../Urls';

const TIME_ORDER = [
  'YEAR',
  'QTR',
  'MONTH',
  'WEEK_IN_QTR',
  'WEEK',
  'DAY',
  'HOUR',
  'MINUTE',
  'SECOND',
  'EXACT_DATE',
  'DATE',
];

const DATE_FILTER_MAPPING = {
  YEAR: 'CALENDAR_YEARS',
  QTR: 'CALENDAR_QUARTERS',
  MONTH: 'CALENDAR_MONTHS',
  WEEK: 'CALENDAR_WEEKS',
  DAY: 'DAYS',
  EXACT_DATE: 'DAYS',
  DATE: 'DAYS',
  HOUR: 'NOT_SUPPORTED',
  MINUTE: 'NOT_SUPPORTED',
  SECOND: 'NOT_SUPPORTED',
};

const getDeepestGranularity = (g1, g2) => {
  const g1index = TIME_ORDER.indexOf(g1);
  const g2index = TIME_ORDER.indexOf(g2);
  return g2index === -1
    ? g1
    : g1index === -1
    ? g2
    : g1index > g2index
    ? g1
    : g2;
};

//
// Take the various date attributes (Year, Qtr, Month, etc) and combine them into a single filter.
//
const translateDateFilter = (
  dateFilter: any,
  attribute: string,
  filters: IAppliedFilters,
  fiscalCalendarInfo?: IFiscalCalendarInfo,
): IFilter => {
  const { useFiscalCalendar, fiscalCalendarYearType, fiscalCalendarStartDate } =
    fiscalCalendarInfo ?? ({} as any);

  const monthOffset =
    useFiscalCalendar && fiscalCalendarStartDate
      ? moment(fiscalCalendarStartDate).month()
      : 0;

  // build up date string
  let year = moment().format('YYYY');
  let month = moment().format('MM');
  let day = '01';

  if (!_.isNil(filters[attribute])) {
    // Extract Anchor from filters if exists to get year and month values
    try {
      if (
        !_.isNil(
          (filters[attribute].expression.left as IExpressionSegment).operands,
        ) &&
        (filters[attribute].expression.left as IExpressionSegment).operands
          .length >= 4
      ) {
        const anchor = moment(
          (filters[attribute].expression.left as IExpressionSegment)
            .operands[3],
        );
        year = anchor.format('YYYY');
        month = anchor.format('MM');
      }
    } catch (e) {
      console.log('issue attempting to extract anchor from previous filter');
    }
  }

  if (!_.isNil(dateFilter.YEAR)) {
    year = dateFilter.YEAR;
    month = '01';
  }

  // determine the month based on month, week and qtr values
  // determine the day based on day and week values
  let dateFilterKey = dateFilter.key;

  if (!_.isNil(dateFilter.DATE)) {
    const m = moment(dateFilter.DATE, 'MM/DD/YYYY');
    // year may also be different
    year = m.format('YYYY');
    month = m.format('MM');
    day = m.format('DD');
  } else if (!_.isNil(dateFilter.EXACT_DATE)) {
    const m = moment(dateFilter.EXACT_DATE, 'MM/DD/YYYY HH:mm A');
    // year may also be different
    year = m.format('YYYY');
    month = m.format('MM');
    day = m.format('DD');
  } else if (!_.isNil(dateFilter.WEEK)) {
    // use ISO week - starts on Monday
    const m = moment(`${year}-${dateFilter.WEEK}`, 'YYYY-[Week] WW');
    // year may also be different
    year = m.format('YYYY');
    month = m.format('MM');
    day = m.format('DD');
    dateFilterKey = 'WEEK';
  } else if (!_.isNil(dateFilter.QTR) && !_.isNil(dateFilter.WEEK_IN_QTR)) {
    // Get the start of the quarter,
    // Then Add N-1 Weeks based on week in qtr
    const firstQtrWeek = moment(
      `${year}-${dateFilter.QTR}`,
      'YYYY-[Q]Q',
    ).isoWeekday(1);
    let weekInQtr = 1;
    try {
      weekInQtr = _.toNumber(dateFilter.WEEK_IN_QTR);
    } catch (e) {
      console.log(`error: ${e}`);
    }
    const qtrWeek = firstQtrWeek.add(weekInQtr - 1, 'week');
    year = qtrWeek.format('YYYY');
    month = qtrWeek.format('MM');
    day = qtrWeek.format('DD');
    dateFilterKey = 'WEEK';
  } else if (!_.isNil(dateFilter.MONTH)) {
    month = moment(dateFilter.MONTH, 'MMM').format('MM');
    if (!_.isNil(dateFilter.DAY)) {
      day = moment(dateFilter.DAY, 'DD').format('DD');
    }
  } else if (!_.isNil(dateFilter.QTR)) {
    month = moment(`${year}-${dateFilter.QTR}`, 'YYYY-[Q]Q').format('MM');
    dateFilterKey = 'QTR';
  } else if (!_.isNil(dateFilter.DAY)) {
    day = moment(dateFilter.DAY, 'DD').format('DD');
  }

  if (dateFilterKey === 'WEEK_IN_QTR') {
    dateFilterKey = 'QTR';
  }

  if (
    dateFilterKey === 'HOUR' ||
    dateFilterKey === 'MINUTE' ||
    dateFilterKey === 'SECOND'
  ) {
    dateFilterKey = 'DAY';
  }

  const useFiscalQuarters = useFiscalCalendar && dateFilterKey === 'QTR';
  const useFiscalMonths = useFiscalCalendar && dateFilterKey === 'MONTH';
  const useFiscalWeeks = useFiscalCalendar && dateFilterKey === 'WEEK';

  const dateString: Moment = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
  if (useFiscalQuarters || useFiscalWeeks) {
    if (fiscalCalendarYearType === 'end') {
      dateString.subtract(1, 'year');
    }
    dateString.add(monthOffset, 'months');
  }
  if (useFiscalMonths) {
    if (
      fiscalCalendarYearType === 'start' &&
      dateString.month() < monthOffset
    ) {
      dateString.subtract(1, 'year');
    } else if (
      fiscalCalendarYearType === 'end' &&
      dateString.month() >= monthOffset
    ) {
      dateString.subtract(1, 'year');
    }
  }
  return DateFilter(dateFilter.parentField, [
    '1',
    DATE_FILTER_MAPPING[dateFilterKey],
    'false',
    dateString.toISOString(true),
  ]);
};

const addQuarterInfoToFilter = (
  weekInQuarterFilter,
  allPrefilterOperands,
): any[] => {
  const quarterFilter = _.find(allPrefilterOperands, [
    'attribute.timeAttribute.key',
    'QTR',
  ]);
  if (_.isNil(quarterFilter)) {
    return [weekInQuarterFilter];
  }

  return [
    {
      ...weekInQuarterFilter,
      value: [...weekInQuarterFilter.value, _.head(quarterFilter.value)],
    },
  ];
};

const isWeekInQuarterFilter = (filter): boolean => {
  return _.isEqual('WEEK_IN_QTR', _.get(filter, 'attribute.timeAttribute.key'));
};

const getHighestGranularityFilters = (preFilterOperands): any[] => {
  const highestLevel = _.max(
    _.map(preFilterOperands, 'attribute.timeAttribute.order'),
  );

  const highestFilter = _.filter(preFilterOperands, [
    'attribute.timeAttribute.order',
    highestLevel,
  ]);

  const filter = _.head(highestFilter);
  return isWeekInQuarterFilter(filter)
    ? addQuarterInfoToFilter(filter, preFilterOperands)
    : highestFilter;
};

const timeCalcFiltersUnderYear = (
  fiscalCalendarInfo: IFiscalCalendarInfo,
  preFilterOperands,
  yearValue?: string | number,
): IFilter[] => {
  // create immutable operands
  let preFiltersWithConvertedMonths = _.cloneDeep(preFilterOperands);

  // should we convert to fiscal week offset?
  if (fiscalCalendarInfo?.useFiscalCalendar) {
    preFiltersWithConvertedMonths = ChartUtils.offsetMonthOrdinalForFiscalCalendar(
      preFiltersWithConvertedMonths,
      fiscalCalendarInfo,
    );
  }

  const _filters = [];
  _.forEach(
    preFiltersWithConvertedMonths,
    ({
      attribute: _nonYearAttribute,
      value: _nonYearValues,
    }: IPreFilterOperand) => {
      const operands = _.cloneDeep(
        _.isArray(_nonYearValues) ? _nonYearValues : [_nonYearValues],
      );

      const isWeekInQuarter = isWeekInQuarterFilter({
        attribute: _nonYearAttribute,
      });
      // Quarter and Month operands have only two operands - last operand is year value
      if ((isWeekInQuarter || operands.length == 1) && !_.isNil(yearValue)) {
        operands.push(yearValue);
      }

      _filters.push(InTermFilter(_nonYearAttribute, operands));
    },
  );

  return _filters;
};

export const UnconnectedReportLinkRedirect = props => {
  let {
    reportDetailInfo,
    reportDetailInfoId,
    reportDetailInfoUpdating,
  }: {
    reportDetailInfo: IReportDetailInfo;
    reportDetailInfoId: string;
    reportDetailInfoUpdating: boolean;
  } = props;
  if (reportDetailInfoId && !reportDetailInfo && !reportDetailInfoUpdating) {
    const { instanceUrl = getFrontendUrl() } = URLs.getQueryParams();
    props.updateReportDetailInfo(reportDetailInfoId, instanceUrl);
    return <></>;
  }
  if (_.isNull(props.visualizations)) {
    return <Redirect to={'/'} />;
  } else if (!props.vizQueryLoading && !reportDetailInfoUpdating) {
    reportDetailInfo =
      reportDetailInfo || props?.location?.state?.reportDetailInfo;
    const { fiscalCalendarInfo } = reportDetailInfo ?? {};
    const { useFiscalCalendar } = fiscalCalendarInfo ?? {};
    let linkedContent: IDiscovery = null;
    if (props.visualizations && !_.isEmpty(reportDetailInfo.linkToReport)) {
      linkedContent = _.find(props.visualizations, {
        id: reportDetailInfo.linkToReport.id,
      });
    }
    if (linkedContent) {
      const {
        slicers,
        slicerSelections,
        filters = {},
        dynamicValues,
        runtimeFilters = {},
        isFromDrillEvent,
      } = reportDetailInfo;
      const dateFilters = {};
      let inTermAttributes = [];
      const filterFields = [];

      //Override the filters with the runtime filters
      _.forEach(runtimeFilters, (val, key) => {
        filters[key] = val;
      });

      const dashletDrillLinkDynamicValues = _.pick(
        dynamicValues,
        AllowedDashletDrillLinkDynamicFields,
      );

      const replaceExpressionDynamicValue = (
        expression: IExpression | IExpressionSegment,
        filter,
      ) => {
        const branch = _.cloneDeep(expression);
        let { type, subType } = filter;
        if (!_.isNil((branch as IExpression)?.left)) {
          const {
            branch: branchLeft,
            type: typeLeft,
            subType: subTypeLeft,
          } = replaceExpressionDynamicValue(
            (branch as IExpression)?.left,
            filter,
          );
          (branch as IExpression).left = branchLeft;
          type = typeLeft;
          subType = subTypeLeft;
        } else if (
          _.includes(
            AllowedDashletDrillLinkDynamicFields,
            _.head((branch as IExpressionSegment)?.operands),
          )
        ) {
          const dynamicValues = dashletDrillLinkDynamicValues[
            _.head((branch as IExpressionSegment)?.operands as string[])
          ] as string;

          (branch as IExpressionSegment).operands = _.isArray(dynamicValues)
            ? dynamicValues
            : [dynamicValues];
          (branch as IExpressionSegment).operator = IN_LIST.key;
          type = Types.STRING;
          subType = StringFilterSubTypes.SELECT_ITEMS;
        }

        return {
          branch,
          type,
          subType,
        };
      };

      const replaceDynamicValue = _filter => {
        const filter = _.cloneDeep(_filter);
        const { expression } = filter;
        const { left: leftExpression, right: rightExpression } = expression;
        const { branch: left, type, subType } = replaceExpressionDynamicValue(
          leftExpression,
          filter,
        );
        const { branch: right } = replaceExpressionDynamicValue(
          rightExpression,
          filter,
        );

        return {
          ..._filter,
          type,
          subType,
          expression: {
            ..._filter?.expression,
            left,
            right,
          },
        };
      };

      let calcDynamicValues = {};

      if (!_.isEmpty(dashletDrillLinkDynamicValues)) {
        // dynamic values, as in calculated fields in the Forecast report, need to be replaced
        for (const _fieldName in filters) {
          filters[_fieldName] = replaceDynamicValue(filters[_fieldName]);
        }
        calcDynamicValues = _.pickBy(
          dashletDrillLinkDynamicValues,
          (value, key) => _.includes(DynamicCalcValues, key),
        );
      }

      let preFilterOperands: IPreFilterOperand[] =
        reportDetailInfo.attributes ?? [];

      const attributeTypeGroups = _.groupBy(
        reportDetailInfo.attributes ?? [],
        _attributeAndValue => {
          return _attributeAndValue?.attribute?.attributeType;
        },
      );

      /**
       * Some notes about attributes in drill linking:
       * - some attributes, like time calcs, are hierarchical in nature and require grouping to make the right filter
       */
      const {
        YEAR,
        QTR,
        MONTH,
        WEEK,
        WEEK_IN_QTR,
        DAY,
        EXACT_DATE,
        DATE,
      } = Hierarchy.TIME_ATTRIBUTES;

      const COARSE_GRANULAR_TIME_TERMS = [YEAR, QTR, MONTH, WEEK_IN_QTR, WEEK];
      const FINE_GRANULAR_TIME_TERMS = [DAY, EXACT_DATE, DATE];

      const hasMoreGranularFieldsThanSupportedInTerm = _.some(
        attributeTypeGroups[Types.TIME_CALC],
        ({ attribute: _attr }) =>
          _.includes(
            _.map(FINE_GRANULAR_TIME_TERMS, 'key'),
            (_attr as ITimeCalcAttribute)?.timeAttribute?.key,
          ),
      );

      if (
        !_.isEmpty(attributeTypeGroups[Types.TIME_CALC]) &&
        !hasMoreGranularFieldsThanSupportedInTerm
      ) {
        // group by non-hierarchical attribute name
        const preFilterTimeCalcGroups: {
          [key: string]: IPreFilterOperand[];
        } = _.groupBy(
          attributeTypeGroups[Types.TIME_CALC],
          ({ attribute: _attr }: IPreFilterOperand) => _attr?.parentField?.name,
        );

        const accountedTimeCalcAttributes = {};

        // Time Calc will be merged later if they share the same parent attribute
        _.forEach(
          _.toPairs(preFilterTimeCalcGroups),
          ([_parentFieldName, preFilterOperandGroups]) => {
            // There is an assumption that fields cannot exist in more than one shelf.
            const inYearPreFilters: IPreFilterOperand[] = _.filter(
              preFilterOperandGroups,
              ({ attribute: _attr }: IPreFilterOperand) =>
                _.some([YEAR], {
                  key: (_attr as ITimeCalcAttribute).timeAttribute.key,
                }),
            );
            const inYearAffectedPreFilters: IPreFilterOperand[] = _.filter(
              preFilterOperandGroups,
              ({ attribute: _attr }: IPreFilterOperand) =>
                _.some(
                  _.reject(COARSE_GRANULAR_TIME_TERMS, { key: YEAR.key }),
                  {
                    key: (_attr as ITimeCalcAttribute).timeAttribute.key,
                  },
                ),
            );

            if (!_.isEmpty(inYearPreFilters)) {
              const { attribute: yearAttribute } = _.head(inYearPreFilters);
              const yearValues = _.flatMap(
                inYearPreFilters as IPreFilterOperand[],
                'value',
              ).filter(Boolean);

              // just make in year filter? why make filters with year operand
              if (!_.isEmpty(inYearAffectedPreFilters)) {
                // there are more granular date terms than year - use yearValues as operands for appropriate filters
                // this more or less creates a cross product of years and year-affected attributes
                _.forEach(yearValues, _yearValue => {
                  inTermAttributes = _.concat(
                    inTermAttributes,
                    timeCalcFiltersUnderYear(
                      reportDetailInfo.fiscalCalendarInfo,
                      getHighestGranularityFilters(inYearAffectedPreFilters),
                      _yearValue,
                    ) ?? [],
                  );
                });
              } else {
                // no year-affected attributes, just make inYear filter
                _.forEach(yearValues, _yearValue => {
                  inTermAttributes = _.concat(inTermAttributes, [
                    InTermFilter(yearAttribute, _yearValue),
                  ]);
                });
              }
            } else {
              // let the filters use default year values
              inTermAttributes = _.concat(
                inTermAttributes,
                timeCalcFiltersUnderYear(
                  reportDetailInfo.fiscalCalendarInfo,
                  inYearAffectedPreFilters,
                ) ?? [],
              );
            }

            _.forEach(
              _.concat(inYearPreFilters, inYearAffectedPreFilters),
              ({ attribute: _attr }) =>
                (accountedTimeCalcAttributes[_attr?.name] = _attr),
            );
          },
        );

        // remove entries that were already accounted for to create InTerm time calc filters
        _.forEach(_.keys(accountedTimeCalcAttributes), _accountedAttrName => {
          preFilterOperands = _.reject(
            preFilterOperands,
            ({ attribute: _attr }: IPreFilterOperand) => {
              return (
                _attr?.name === _accountedAttrName &&
                _.some(COARSE_GRANULAR_TIME_TERMS, {
                  key: (_attr as ITimeCalcAttribute).timeAttribute?.key,
                })
              );
            },
          ) as IPreFilterOperand[];
        });
      }

      // merge string prefilter operands that share the same attribute (e.g. for InList filter)
      if (!_.isEmpty(attributeTypeGroups[Types.STRING])) {
        const stringPrefiltersByAttribute: {
          [key: string]: IPreFilterOperand[];
        } = _.groupBy(
          attributeTypeGroups[Types.STRING] as IPreFilterOperand[],
          'attribute.name',
        );

        // note mergedPrefilters values are not arrays/collections
        const mergedStringPrefilters: {
          [key: string]: IPreFilterOperand;
        } = _.mapValues(
          stringPrefiltersByAttribute,
          (_prefilters: IPreFilterOperand[]) => {
            const _attribute = _.head(_prefilters)?.attribute;
            const _value = _.flatMap(_prefilters, 'value');
            return {
              attribute: _attribute,
              value: _value,
            } as IPreFilterOperand;
          },
        );

        const _updateStringPrefilterOperands = _.flatMap(
          mergedStringPrefilters,
        );
        // remove entries that were just merged
        _.forEach(_.keys(mergedStringPrefilters), _mergedAttrName => {
          preFilterOperands = _.reject(
            preFilterOperands,
            ({ attribute: _attr }: IPreFilterOperand) =>
              _.isEqual(_attr.name, _mergedAttrName),
          ) as IPreFilterOperand[];
        });

        // add new entries of the string prefilters that have merged operands
        preFilterOperands = _.concat(
          preFilterOperands,
          _updateStringPrefilterOperands,
        );
      }

      _.forEach(preFilterOperands, (d: IPreFilterOperand) => {
        switch (d?.attribute?.attributeType) {
          case Types.TIME_CALC: {
            const { DATE } = Hierarchy.TIME_ATTRIBUTES;

            let parentTimeCalcAttribute = d.attribute;
            if (!_.isEmpty(parentTimeCalcAttribute?.parentField)) {
              parentTimeCalcAttribute = parentTimeCalcAttribute?.parentField;
            }

            if (
              _.some([DATE], {
                key: (d.attribute as ITimeCalcAttribute).timeAttribute.key,
              })
            ) {
              let onDates = d.value;

              if (!_.isArray(d.value)) {
                onDates = [d.value];
              }

              // all date operands need to be converted to iso strings
              onDates = _.map(onDates, (_date: string) => {
                try {
                  // On Date should truncate time
                  return moment.utc(_date).format('MM/DD/YYYY');
                } catch {
                  return _date;
                }
              });

              // construct filter with first date
              const onDatesFilter = DateFilter(
                parentTimeCalcAttribute,
                _.slice(onDates, 0, 1),
                timestampOperators.on.key,
              );
              onDatesFilter.subType = TimestampFilterSubTypes.SET_CONDITION;

              // add any additional dates into filter
              _.forEach(_.slice(onDates, 1), (_dateOperand: string) => {
                onDatesFilter.expression = insertIntoFilterExpression(
                  onDatesFilter.expression,
                  new Condition(timestampOperators.on.key, [_dateOperand]),
                  LogicalOperators.OR,
                );
              });

              inTermAttributes.push(onDatesFilter);
            } else {
              // anchor-based code before the introduction of 'InTerm' operator
              let dateFilter: any = {};
              // if parent is already defined
              if (dateFilters[d.attribute?.parentField?.name]) {
                dateFilter = dateFilters[d.attribute?.parentField?.name];
              } else {
                dateFilters[d.attribute?.parentField?.name] = dateFilter;
                dateFilter.parentField = d.attribute?.parentField;
              }
              dateFilter[
                (d.attribute as ITimeCalcAttribute).timeAttribute.key
              ] = d.value;
              dateFilter.key = getDeepestGranularity(
                (d.attribute as ITimeCalcAttribute).timeAttribute.key,
                dateFilter.key,
              );
              dateFilter.attribute = d.attribute;
              filterFields.push(d.attribute.parentField.name);
            }
            break;
          }
          case Types.STRING: {
            if (
              _.isEqual(d.value, [NULL_DISPLAY]) ||
              (d.value as any) === NULL_DISPLAY
            ) {
              filters[d.attribute.name] = NullFilter(d.attribute);
            } else {
              filters[d.attribute.name] = InListFilter(d.attribute, d.value);
            }
            filterFields.push(d.attribute.name);
            break;
          }
          default: {
            if (
              _.isEqual(d.value, [NULL_DISPLAY]) ||
              (d.value as any) === NULL_DISPLAY
            ) {
              filters[d.attribute.name] = NullFilter(d.attribute);
            } else {
              filters[d.attribute.name] = EqualsFilter(d.attribute, d.value);
            }
            filterFields.push(d.attribute.name);
            break;
          }
        }
      });

      const inTermAttributeGroups = _.groupBy(inTermAttributes, _filter => {
        let filterName = _filter.field;
        if (_.isObject(_filter.field)) {
          filterName = (_filter.field as ITimeCalcAttribute).name;
        }
        return filterName;
      });

      // merge filters that share a field with LogicalOperators.OR
      _.forEach(
        _.toPairs(inTermAttributeGroups),
        ([_filterName, unmergedFilters]) => {
          // all InTerm filters are Type DATE and subType SET_CONDITION
          const filter = _.head(unmergedFilters);
          _.forEach(_.tail(unmergedFilters), (_filter: IFilter) => {
            const _unmergedConditions = decodeExpressionTree(
              _filter.expression,
            );
            _.forEach(_unmergedConditions, (_condition: IExpressionSegment) => {
              filter.expression = insertIntoFilterExpression(
                filter.expression,
                _condition,
                LogicalOperators.OR,
              );
            });
          });
          filters[_filterName] = filter;
        },
      );

      for (const attribute in dateFilters) {
        // translate to filter
        filters[attribute] = translateDateFilter(
          dateFilters[attribute],
          attribute,
          filters,
          fiscalCalendarInfo,
        );
      }

      props.openVisualization({
        ...linkedContent,
        canUpdate: linkedContent.canUpdate && !isFromDrillEvent,
      });
      switch (props.linkStrategy) {
        case 'update':
          // only remove filters that are for fields coming in from the source report
          return (
            <VizRedirect
              vizId={linkedContent.id}
              state={{
                filters,
                removeFiltersOnFields: filterFields,
                calcs: reportDetailInfo.calcs,
                metrics: reportDetailInfo.metrics,
                toShelves: reportDetailInfo.toShelves,
                useFiscalCalendar,
                slicers,
                slicerSelections,
                dynamicValues: calcDynamicValues,
              }}
            />
          );
        case 'clear':
          // wipe out all pre-existing filters
          return (
            <VizRedirect
              vizId={linkedContent.id}
              state={{
                filters,
                removeFiltersOnFields: ['__ALL__'],
                calcs: reportDetailInfo.calcs,
                metrics: reportDetailInfo.metrics,
                toShelves: reportDetailInfo.toShelves,
                useFiscalCalendar,
                slicers,
                slicerSelections,
                dynamicValues: calcDynamicValues,
              }}
            />
          );
        default:
          // don't change the filters of the target report at all
          return <VizRedirect vizId={linkedContent.id} />;
      }
    } else {
      return <VizRedirect vizId={''} />;
    }
  } else {
    return <CorbotoLoading />;
  }
};

const VizQuery = graphql(DiscoverQueries.DiscoveriesQuery, {
  props: (result: any) => {
    const { data } = result;
    if (data?.error) {
      return { visualizations: null, vizQueryLoading: false };
    } else if (data?.visualizations) {
      const visualizations = data.visualizations.map(v => ({
        ...v,
        dataset: _.find(data.datasets, { id: v.datasetId }),
        discoveryType: 'VISUALIZATION',
      }));
      return { visualizations, vizQueryLoading: false };
    } else {
      return { vizQueryLoading: true };
    }
  },
});

export {
  getDeepestGranularity,
  translateDateFilter,
  getHighestGranularityFilters,
  timeCalcFiltersUnderYear,
};

export default compose(
  pure,
  withDiscoverRouter,
  VizQuery,
  connect(
    (state: any) => {
      const {
        discover: {
          openDiscoveries,
          displayDiscovery,
          reportDetailInfo,
          reportDetailInfoUpdating,
          linkStrategy,
        },
      } = state;
      const current = _.get(openDiscoveries, [displayDiscovery, 'present']);
      const vizLinkStrategy = _.get(
        current,
        'viz.options.linkStrategy',
        'update',
      );

      return {
        linkStrategy: linkStrategy || vizLinkStrategy,
        reportDetailInfo,
        reportDetailInfoUpdating,
      };
    },
    (dispatch: any) => {
      return {
        updateReportDetailInfo(reportDetailInfoId, instanceUrl) {
          dispatch(
            DiscoverActions.updateReportDetailInfo(
              reportDetailInfoId,
              instanceUrl,
            ),
          );
        },
        openVisualization: (discovery: any) => {
          dispatch(DiscoverActions.openVizualization(discovery));
        },
      };
    },
  ),
)(UnconnectedReportLinkRedirect) as ComponentClass;
