export const TimePeriodMapping = {
  minutes: 'MINUTES',
  hours: 'HOURS',
  days: 'DAYS',
  weeks: 'WEEKS',
  calendar_weeks: 'CALENDAR_WEEKS',
  months: 'MONTHS',
  calendar_months: 'CALENDAR_MONTHS',
  quarters: 'CALENDAR_QUARTERS',
  calendar_quarters: 'CALENDAR_QUARTERS',
  years: 'YEARS',
  calendar_years: 'CALENDAR_YEARS',
};

export const TimeAttributeMapping = {
  minutes: 'MINUTE',
  hours: 'HOUR',
  days: 'DAY',
  calendar_weeks: 'WEEK',
  weeks: 'WEEK',
  calendar_months: 'MONTH',
  months: 'MONTH',
  calendar_quarters: 'QTR',
  quarters: 'QTR',
  calendar_years: 'YEAR',
  years: 'YEAR',
};
