import { IUserInfo } from '../../../account/interfaces';
import { useAccount } from '../../utilities/account';
import {
  convertSugarDateFormat,
  convertSugarTimeFormat,
} from '../../Constants';

export const useI18nPrefs = () => {
  const { currentUser = {} as IUserInfo } = useAccount();
  const { i18nPrefs = {} } = currentUser;

  return i18nPrefs;
};

export const useDateTimeFormats = () => {
  const {
    dateFormat: profileDateFormat,
    timeFormat: profileTimeFormat,
  } = useI18nPrefs();
  const dateFormat = convertSugarDateFormat(profileDateFormat);
  const timeFormat = convertSugarTimeFormat(profileTimeFormat);
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;
  return {
    dateFormat,
    timeFormat,
    dateTimeFormat,
  };
};
