import Main from '../redux/actions/MainActions';
// Object of {tabID : [Queries]}. Queries are executed when tab is activated

function SectionLayout(tabId, tabName, queriesOnActivation) {
  Main.tabQueries[tabId] = queriesOnActivation;

  return WrappedComponent =>
    function Wrapped(props) {
      return (
        <div id={tabId}>
          <WrappedComponent {...props} />
        </div>
      );
    };
}

SectionLayout.mapStateToProps = state => ({
  activeTab: state.main.activeTab,
  activeSubmenuTab: state.main.activeSubmenuTab[state.main.activeTab],
});

export default SectionLayout;
