import { IFilter, ITimeCalcAttribute } from '../../../datasets/interfaces';
import _ from 'lodash';
import {
  TimeAttributeToInOperator,
  timestampOperators,
} from '../FilterOperators';
import { MonthsShort } from '../../VizUtil';
import { TimestampFilterSubTypes } from '../Filter';
import DateFilter from './DateFilter';

export const InTermFilter = (field: ITimeCalcAttribute, operands): IFilter => {
  let timeFilter: IFilter = undefined;

  const timeAttributeKey = _.get(field, 'timeAttribute.key', '');

  // determine inWeek/inMonth/inQuarter/inYear
  // timeAttributeInOperatorKey can be undefined
  const timeAttributeInOperatorKey =
    TimeAttributeToInOperator[timeAttributeKey] ?? undefined;

  // get the correct field/attribute
  let timeAttribute = field;
  if (!_.isEmpty(timeAttribute?.parentField)) {
    timeAttribute = timeAttribute?.parentField;
  }

  if (_.isString(timeAttributeInOperatorKey)) {
    let timeFilterOperands = _.isArray(operands) ? operands : [operands];

    const firstOperand = _.head(timeFilterOperands);
    const secondOperand = _.nth(timeFilterOperands, 1);

    if (timeAttributeInOperatorKey === timestampOperators.inWeek.key) {
      // Keep in mind that this is a 1-based week index
      let weekIndex = _.parseInt(firstOperand);

      // operand could mistakenly be a formatted value
      if (_.isNaN(weekIndex)) {
        weekIndex = 0;
      }

      // default year to ALL
      const yearOperand = _.toString(secondOperand ?? 'ALL');

      timeFilterOperands = [_.toString(weekIndex), yearOperand];
    } else if (timeAttributeInOperatorKey === timestampOperators.inMonth.key) {
      // Keep in mind that this is a 1-based month index
      let monthIndex = _.parseInt(firstOperand);

      // operand could mistakenly be a formatted value
      if (_.isNaN(monthIndex)) {
        monthIndex = _.indexOf(MonthsShort, firstOperand) + 1;
      }

      if (monthIndex < 1 || monthIndex > 12) {
        // something went wrong
        monthIndex = 1;
      }

      // default year to ALL
      const yearOperand = _.toString(secondOperand ?? 'ALL');

      timeFilterOperands = [_.toString(monthIndex), yearOperand];
    } else if (
      timeAttributeInOperatorKey === timestampOperators.inQuarter.key
    ) {
      // parse number from Q1, Q2, etc
      const replace = _.replace(firstOperand, /\D*/, '');
      const _firstOperand = _.toString(
        _.max([1, _.min([_.toInteger(replace), 4])]),
      );

      // default year to ALL
      const yearOperand = _.toString(secondOperand ?? 'ALL');

      timeFilterOperands = [_firstOperand, yearOperand];
    } else if (timeAttributeInOperatorKey === timestampOperators.inYear.key) {
      timeFilterOperands = [_.toString(firstOperand)];
    } else if (
      timeAttributeInOperatorKey === timestampOperators.weekInQuarter.key
    ) {
      const yearOperand = _.toString(_.nth(timeFilterOperands, 2) ?? 'ALL');
      const quarterOperand = _.replace(secondOperand, /\D*/, '');
      //reorder operands to match backend WEEK_IN_QTR filter
      timeFilterOperands = [quarterOperand, firstOperand, yearOperand];
    }

    timeFilter = DateFilter(
      timeAttribute,
      timeFilterOperands,
      timeAttributeInOperatorKey,
    );
    timeFilter.subType = TimestampFilterSubTypes.SET_CONDITION;
  }

  return timeFilter;
};
