import React from 'react';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { useDiscoverTheme } from '../../../common/emotion/theme';

export const FormInput = ({ sx, ...props }: InputBaseProps) => {
  const {
    isDashletMode,
    darkMode,
    colors: {
      ContentText,
      SugarGrayPalette: { Gray30 },
      RegularFontWeight,
      Strokes,
      MediumBlue,
      FormControlShadowColor,
    },
  } = useDiscoverTheme();

  const inputStyles = {
    fontWeight: RegularFontWeight,
    fontStyle: 'inherit',
    fontSize: `14px ${isDashletMode ? '!important' : ''}`,
    color: 'inherit',
    backgroundColor: 'inherit',
    padding: '3px 6px',
    borderRadius: '2px',
    border: `1px solid ${darkMode ? Strokes : Gray30}`,
    boxShadow: `${!darkMode && `inset 0 1px 1px ${FormControlShadowColor}`}`,
    transition: `${!darkMode && 'border linear 0.2s, box-shadow linear 0.2s'}`,
    boxSizing: 'border-box',
    resize: 'none',
    '&:focus': {
      borderColor: `${!darkMode && MediumBlue}`,
      boxShadow: 'none',
      color: ContentText,
    },
  };

  return (
    <InputBase
      {...props}
      sx={{
        ...sx,
        width: '100%',
        input: {
          height: '28px',
          ...inputStyles,
        },
        textarea: {
          minHeight: '64px',
          maxHeight: '250px',
          overflow: 'auto !important',
          ...inputStyles,
        },
      }}
    />
  );
};
