import gql from 'graphql-tag';
const DatasetQueries = {
  DatasetQuery: gql`
    query getDatasets {
      datasets {
        id
        name
        description
        creatorName
        creator
        createdOn
        updatedByName
        updatedOn
        updatedBy
        editMode
        isDeletable
        parentDataset {
          id
          name
        }
        datasetStatusType
        datasetStatusDetail
        attributes {
          index
          name
          description
          attributeType
          defaultAggregation
          hidden
          formatType
          aggregateMeasure
          ordinalAttribute
          annotations {
            key
            value
          }
          calculation
        }
        annotations {
          key
          value
        }
      }
    }
  `,
  DatasetDetailQuery: gql`
    query getDatasets {
      datasets {
        id
        name
        attributes {
          index
          name
          description
          attributeType
          defaultAggregation
          hidden
          aggregateMeasure
          formatType
          ordinalAttribute
          annotations {
            key
            value
          }
          calculation
        }
        annotations {
          key
          value
        }
      }
    }
  `,
  RefreshSugarCrmDatasetMutation: gql`
    mutation refreshSugarCrmDataset($id: String!) {
      refreshSugarCrmDataset(id: $id)
    }
  `,
  GetSugarCrmSchemas: gql`
    query sugarCrmSchemas {
      sugarCrmSchemas {
        datasetId
        modules {
          name
          fields {
            name
            json
          }
          enabled
          visible
        }
        relationships {
          relationshipType
          lhsModule
          rhsModule
          rhsKey
          lhsKey
        }
      }
    }
  `,
  GetSugarCrmDatasetCustomAnnotations: gql`
    query getSugarCrmDatasetAnnotations($id: String!) {
      getSugarCrmDatasetAnnotations(id: $id) {
        datasetJson
        systemName
        objectIdAttrib
        closeDateAttrib
        createdDateAttrib
        success
        errorMessage
      }
    }
  `,
  UpdateSugarCrmDatasetCustomAnnotations: gql`
    mutation updateSugarCrmDatasetAnnotations(
      $request: CustomDatasetAnnotationUpdateRequest!
    ) {
      updateSugarCrmDatasetAnnotations(request: $request) {
        datasetJson
      }
    }
  `,
  DeleteDatasetMutation: gql`
    mutation delete($datasetId: String!) {
      deleteDataset(id: $datasetId) {
        id
      }
    }
  `,
  DatasetDetail: gql`
    query datasetDetail($id: String!) {
      dataset(id: $id) {
        id
        name
        description
        creatorName
        createdOn
        creator
        updatedByName
        updatedOn
        updatedBy
        editMode
        isDeletable
        parentDataset {
          id
          name
        }
        attributes {
          index
          name
          description
          attributeType
          defaultAggregation
          hidden
          aggregateMeasure
          formatType
          ordinalAttribute
          annotations {
            key
            value
          }
          calculation
        }
        annotations {
          key
          value
        }
      }
    }
  `,
  DatasetPreviewQuery: gql`
    query datasetPreview(
      $id: String!
      $fields: [String!]!
      $limit: Int
      $useFiscalCalendar: Boolean = false
    ) {
      executeQuery(
        query: {
          datasetId: $id
          attributeNames: $fields
          measures: []
          filters: []
          sorts: []
          limit: $limit
          useFiscalCalendar: $useFiscalCalendar
        }
      ) {
        columnNames
        columnInfo {
          columnName
          attributeName
          columnType
          parentName
          aggregationName
        }
        results
      }
    }
  `,
  DatasetProfileQuery: gql`
    query datasetProfile($id: String!) {
      datasetProfile(id: $id) {
        id
        attributeProfiles {
          name
          profileInfo {
            name
            value
          }
        }
      }
    }
  `,
  CreateSugarCrmDatasetMutation: gql`
    mutation createSugarCrmDataset($request: SugarCrmDatasetRequest!) {
      createSugarCrmDataset(request: $request) {
        id
      }
    }
  `,
  AggregationInfo: gql`
    query getAggregationInfo {
      aggregateInfo {
        name
        supportedTypes
        functionName
        abbreviation
      }
    }
  `,
  CalcFunctionNames: gql`
    query getCalcFunctionNames {
      calcFunctionNames
    }
  `,
  CalcFunctionInfo: gql`
    query getCalcInfo {
      aggregateInfo {
        name
        supportedTypes
        functionName
        abbreviation
      }
      calcFunctionNames
    }
  `,
  UpdateSugarCrmDatasetAuthMutation: gql`
    mutation($request: SugarCrmDatasetUpdateAuthRequest!) {
      updateSugarCrmAuth(request: $request)
    }
  `,
  GenerateSugarCrmAuthTokensMutation: gql`
    mutation($request: SugarCrmDatasetGenerateAuthTokensRequest!) {
      generateSugarCrmAuthTokens(request: $request) {
        instanceUrl
        username
        accessToken
        refreshToken
        success
        errorMessage
      }
    }
  `,
  UpdateDataset: gql`
    mutation($dataset: DatasetRequest!, $removeAttributeIdxs: [Int!]) {
      updateDataset(
        dataset: $dataset
        removeAttributeIdxs: $removeAttributeIdxs
      ) {
        id
        name
        description
        creatorName
        creator
        createdOn
        creator
        updatedByName
        updatedOn
        updatedBy
        editMode
        isDeletable
        parentDataset {
          id
          name
        }
        attributes {
          index
          name
          description
          attributeType
          defaultAggregation
          hidden
          aggregateMeasure
          formatType
          ordinalAttribute
          annotations {
            key
            value
          }
          calculation
        }
        annotations {
          key
          value
        }
      }
    }
  `,
};
export default DatasetQueries;
