import { FC } from 'react';
import { useDispatch } from 'react-redux';
import Discover from '../../../common/redux/actions/DiscoverActions';
import { messages } from '../../../i18n';
import _ from 'lodash';
import { PrimaryButton } from '../../../ui';
import { IMissingFieldPlaceHolderProps } from './missing-field-placeholder.interfaces';
import { isDashletMode as getIsDashletMode } from '../../../auth';
import { ChartPlaceHolder } from '../chart-placeholder.component';
import { useOpenVizSelector } from '../../../common/redux/selectors/viz-selector.hook';

export const MissingFieldPlaceholder: FC<IMissingFieldPlaceHolderProps> = ({
  vizId,
}) => {
  const dispatch = useDispatch();
  const viz = useOpenVizSelector({ discoveryId: vizId });
  const isDashletMode = getIsDashletMode();
  const { missingFields, missingFilters: filterFields } = viz;
  const removeFields = () => {
    dispatch(Discover.removeMissingFields(vizId, missingFields, filterFields));
    dispatch(Discover.setDiscoveryDirty(vizId, true));
  };
  return !isDashletMode ? (
    <ChartPlaceHolder vizId={vizId} hasError>
      <p>{messages.baseChart.errorsMissingFields}</p>
      <ul>
        {!_.isEmpty(missingFields) &&
          _.map(
            _.filter(missingFields, field => !_.isNil(field.name)),
            f => <li key={f.name}>{f.name}</li>,
          )}
        {!_.isEmpty(filterFields) && _.isEmpty(missingFields) && (
          <li>{messages.baseChart.missingFilters}</li>
        )}
      </ul>
      <PrimaryButton onClick={() => removeFields()}>
        {messages.baseChart.removeMissingFields}
      </PrimaryButton>
    </ChartPlaceHolder>
  ) : (
    <div className={'viz-placeholder'}>
      <span>{messages.baseChart.reportMissingFields}</span>
      <span>{messages.baseChart.openDiscoverToReplace}</span>
    </div>
  );
};
