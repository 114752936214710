import { StyledModalPortal } from './no-access-dialog.styles';
import { ButtonContainer, PrimaryButton } from '../../ui/button';
import { messages } from '../../i18n';
import { useDispatch } from 'react-redux';
import LoginActions from '../../common/redux/actions/LoginActions';

export const NoAccessDialog = ({ noAccess, noDashletEditAccess }) => {
  const dispatch = useDispatch();

  const title = noAccess
    ? messages.account.noAccess
    : messages.account.unauthorized;
  const body = noDashletEditAccess
    ? messages.dashlet.noPermissionCreateEdit
    : messages.formatString(
        messages.account.noAccessErrorDetail,
        messages.nonTranslated.discover,
      );

  const message = window.opener
    ? body
    : messages.formatString(
        messages.account.noAccessPrompt,
        messages.nonTranslated.discover,
      );

  const btnText = window.opener
    ? messages.account.close
    : messages.account.logout;

  const handleClick = () => {
    if (window.opener) {
      window.close();
      return;
    }
    dispatch(LoginActions.logout);
  };

  return (
    <StyledModalPortal
      title={title}
      contentClassName={'no-access-dialog'}
      buttonGroup={
        <ButtonContainer
          style={{
            marginBottom: '0.8rem',
            alignSelf: 'flex-end',
            marginRight: '1rem',
          }}
        >
          <PrimaryButton onClick={handleClick}>{btnText}</PrimaryButton>
        </ButtonContainer>
      }
      zIndex={1051}
    >
      <div className={'no-access-dialog-body'}>{message}</div>
    </StyledModalPortal>
  );
};
